import React, { Component } from "react";
import Modal from "../../Layout/Modal";

const styles = {
  header: {
    fontSize: "22px",
    color: "#012639",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: 25,
    marginBottom: 22,
  },
  bodyHeader: {
    fontSize: "14px",
    color: "#012639",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    width: "150px",
    height: "150px",
    backgroundColor: "#F5F5F5",
    alignItems: "center",
  },
};

class PrintModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: null,
    };
  }

  onMouseEnter = box => {
    this.setState({ hover: box });
  };

  onMouseLeave = () => {
    this.setState({ hover: null });
  };

  render() {
    const { closeModal, openModal, printLabel, printList } = this.props;
    const { hover } = this.state;

    let leftBoxBackgroundColor = null;
    if (hover === "left") {
      leftBoxBackgroundColor = "#E8F8F9";
    }

    let rightBoxBackgroundColor = null;
    if (hover === "right") {
      rightBoxBackgroundColor = "#E8F8F9";
    }

    const leftBoxStyle = {
      ...styles.boxStyle,
      marginLeft: 90,
      backgroundColor: leftBoxBackgroundColor,
    };

    const rightBoxStyle = {
      ...styles.boxStyle,
      marginRight: 90,
      backgroundColor: rightBoxBackgroundColor,
    };

    return (
      <Modal
        open={openModal}
        className="modal--run_detail"
        bodyClass="modal__analysis"
        onClose={closeModal}
        modalBodyInnerStyle={{}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          <img src="../../images/PrintIcon_Modal.svg" alt="Print Icon" />
          <p style={styles.header}>What do you want to print?</p>
          <div
            style={{
              display: "flex",
              flexDirectin: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={leftBoxStyle}
              onMouseEnter={() => this.onMouseEnter("left")}
              onMouseLeave={() => this.onMouseLeave("left")}
              onClick={() => printLabel()}
            >
              <div style={{ height: "72px" }}>
                <img
                  src="../../images/QRIcon_Modal.svg"
                  alt="Print QR Code Icon"
                  style={{ marginTop: "23px" }}
                />
              </div>
              <h2 style={styles.bodyHeader}>QR Labels</h2>
              <p>1" x 1" labels</p>
            </div>
            <div
              style={rightBoxStyle}
              onMouseEnter={() => this.onMouseEnter("right")}
              onMouseLeave={() => this.onMouseLeave("right")}
              onClick={() => printList()}
            >
              <div style={{ height: "72px" }}>
                <img
                  src="../../images/ParameterIcon_Modal.svg"
                  alt="Parameter Icon"
                  style={{ marginTop: "23px" }}
                />
              </div>
              <h2 style={styles.bodyHeader}>Field List</h2>
              <p>8.5" x 11" letter</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PrintModal;
