import * as t from "./actionTypes";

export function intialize(runs, version) {
  return {
    type: t.CHART_INITIALIZE,
    runs,
    version,
  };
}

export function resetChart() {
  return {
    type: t.CHART_RESET,
  };
}

export function updateSingleThresholdRequest(thresholdData) {
  return {
    type: t.UPDATE_SINGLE_THRESHOLD_REQUEST,
    thresholdData,
  };
}

export function updateSingleThresholdSuccess(updatedData) {
  return {
    type: t.UPDATE_SINGLE_THRESHOLD_SUCCESS,
    updatedData,
  };
}

export function updateSingleThresholdFailure() {
  return {
    type: t.UPDATE_SINGLE_THRESHOLD_FAILURE,
  };
}

export function updateLineThreshold(lines) {
  return {
    type: t.UPDATE_LINE_THRESHOLD,
    lines,
  };
}

export function updateCQ(data) {
  return {
    type: t.UPDATE_CQ_VALUES,
    data,
  };
}

export function showSingleTarget(runId, well, channelNum) {
  return {
    type: t.CHART_SHOW_SINGLE_TARGET,
    runId,
    well,
    channelNum,
  };
}

export function hideSingleTarget(runId, well, channelNum) {
  return {
    type: t.CHART_HIDE_SINGLE_TARGET,
    runId,
    well,
    channelNum,
  };
}

export function applyRunFilters(runId, channels, wells, wellChannel) {
  return {
    type: t.CHART_APPLY_FILTERS_RUN,
    runId,
    channels,
    wells,
    wellChannel,
  };
}

export function activateRunBlock(runId) {
  return {
    type: t.CHART_ACTIVATE_RUN_BLOCK,
    runId,
  };
}

export function deactivateRunBlock(runId) {
  return {
    type: t.CHART_DEACTIVATE_RUN_BLOCK,
    runId,
  };
}

export function showRunThresholds(runId) {
  return {
    type: t.CHART_SHOW_RUN_THRESHOLDS,
    runId,
  };
}

export function hideRunThresholds(runId) {
  return {
    type: t.CHART_HIDE_RUN_THRESHOLDS,
    runId,
  };
}

export function closeAnalysisModal() {
  return {
    type: t.CHART_HIDE_ANALYSIS_MODAL,
  };
}

export function openAnalysisModal() {
  return {
    type: t.CHART_SHOW_ANALYSIS_MODAL,
  };
}

export function closeDetailedModal() {
  return {
    type: t.CHART_HIDE_DETAILED_MODAL,
  };
}

export function openDetailedModal(run = null) {
  return {
    type: t.CHART_SHOW_DETAILED_MODAL,
    run,
  };
}

export function closeExportModal() {
  return {
    type: t.CHART_HIDE_EXPORT_MODAL,
  };
}

export function openExportModal() {
  return {
    type: t.CHART_SHOW_EXPORT_MODAL,
  };
}

export function toggleExportReviewModal() {
  return {
    type: t.CHART_TOGGLE_EXPORT_REVIEW_MODAL,
  };
}

export function closePrintModal() {
  return {
    type: t.CHART_HIDE_PRINT_MODAL,
  };
}

export function openPrintModal() {
  return {
    type: t.CHART_SHOW_PRINT_MODAL,
  };
}

export function setPrintIndex(runId) {
  return {
    type: t.SET_PRINT_INDEX,
    runId,
  };
}

export function showRaw() {
  return {
    type: t.SHOW_RAW,
  };
}

export function showBaseline() {
  return {
    type: t.SHOW_BASELINE,
  };
}

export function toggleLog() {
  return {
    type: t.TOGGLE_LOG,
  };
}

export function updateAnalysisMode(mode) {
  return {
    type: t.UPDATE_ANALYSIS_MODE,
    mode,
  };
}

export function showTabularData() {
  return {
    type: t.SHOW_TABULAR_DATA,
  };
}

export function showSingleThresholds() {
  return {
    type: t.SHOW_SINGLE_THRESHOLDS,
  };
}

export function updateMetrics() {
  return {
    type: t.CHART_UPDATE_METRICS,
  };
}

export function updateEndRfuMetrics() {
  return {
    type: t.CHART_UPDATE_END_RFU_METRICS,
  };
}

export function updateAxes() {
  return {
    type: t.CHART_UPDATE_AXES,
  };
}

export function applyMasterFilters(targets, runId = null) {
  return {
    type: t.CHART_APPLY_FILTERS_MASTER,
    targets,
    runId,
  };
}

export function toggleAllRunTargets(active, runId, channel) {
  return {
    type: t.CHART_TOGGLE_ALL_RUN_TARGETS,
    active,
    runId,
    channel,
  };
}

export function toggleChartTutorial(status) {
  return {
    type: t.CHART_TOGGLE_TUTORIAL,
    status,
  };
}

export function updateTabularDataOptions(options) {
  const { order, filter } = options;

  return {
    type: t.CHART_UPDATE_TABULAR_DATA_OPTIONS,
    order,
    filter,
  };
}

export function startBackgroundResetSequence() {
  return {
    type: t.CHART_START_BACKGROUND_RESET_SEQUENCE,
  };
}

export function resetBackgroundValues(fullRunData) {
  return {
    type: t.CHART_RESET_BACKGROUND_VALUES,
    fullRunData,
  };
}

export function updateBackgroundValues(values) {
  return {
    type: t.CHART_UPDATE_BACKGROUND_VALUES,
    values,
  };
}

export function storeReprocessedData(reprocessedData) {
  return {
    type: t.CHART_STORE_REPROCESSED_DATA,
    reprocessedData,
  };
}

export function setReprocessingError(message) {
  return {
    type: t.CHART_SET_REPROCESSING_ERROR,
    message,
  };
}

export function setReviewModeError(message) {
  return {
    type: t.CHART_SET_REVIEW_MODE_ERROR,
    message,
  };
}

export function updateChannelFilters(channelFilters) {
  return {
    type: t.UPDATE_CHANNEL_FILTERS,
    channelFilters,
  };
}

export function updateWellFilters(wellFilters) {
  return {
    type: t.UPDATE_WELL_FILTERS,
    wellFilters,
  };
}

export function closeCyclesDisplayedModal() {
  return {
    type: t.CHART_HIDE_CYCLES_DISPLAYED_MODAL,
  };
}

export function openCyclesDisplayedModal() {
  return {
    type: t.CHART_SHOW_CYCLES_DISPLAYED_MODAL,
  };
}

export function updateCyclesDisplayedLowerBound(lowerBound) {
  return {
    type: t.CHART_UPDATE_CYCLES_DISPLAYED_LOWER_BOUND,
    lowerBound,
  };
}

export function updateCyclesDisplayedUpperBound(upperBound) {
  return {
    type: t.CHART_UPDATE_CYCLES_DISPLAYED_UPPER_BOUND,
    upperBound,
  };
}

export function updateTutorialRunsBlocksOpenState(open) {
  return {
    type: t.TUTORIAL_SET_RUN_BLOCK_OPEN_STATE,
    open,
  };
}

export function updateChartBackgroundValuesError(value) {
  return {
    type: t.CHART_UPDATE_BACKGROUND_VALUES_ERROR,
    value,
  };
}

export const toggleBulkReviewSummaryModal = () => ({
  type: t.TOGGLE_BULK_REVIEW_SUMMARY_MODAL,
});

export function reprocessTargetRequest(targetData) {
  return {
    type: t.CHART_REPROCESS_TARGET_REQUEST,
    targetData,
  };
}

export function reprocessTargetSuccess(reprocessedData) {
  return {
    type: t.CHART_REPROCESS_TARGET_SUCCESS,
    reprocessedData,
  };
}

export function reprocessTargetFailure() {
  return {
    type: t.CHART_REPROCESS_TARGET_FAILURE,
  };
}
