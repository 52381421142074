import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";

import { Button } from "bio-web-components";

import { logoutReq } from "../../Auth/actions";

interface StateProps {}

interface DispatchProps {
  logout: () => void;
}

interface Props extends RouteComponentProps, StateProps, DispatchProps {}

const RightPage: React.FunctionComponent<Props> = (props: Props) => {
  const { logout, history } = props;

  return (
    <div className="page__right page__right--top-padding">
      <Button inverting title="Logout" onClick={() => logout()} />
      <Button
        inverting
        title="Report a Bug"
        onClick={() => history.push("/feedback")}
        buttonStyle={{ marginTop: "10px" }}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch: any) {
  return {
    logout: () => dispatch(logoutReq()),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(RightPage));
