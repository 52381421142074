import React from "react";

import history from "../../store/history";

const BackButton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      onClick={() => history.push("/sample-management")}
    >
      <div style={{ marginTop: "1px" }}>
        <img
          src="../../../images/BackArrowNavy.svg"
          style={{
            marginTop: "6px",
            marginRight: "10px",
            transform: "rotate(90deg) scaleX(-1)",
          }}
          height="8"
          width="13"
          alt="Back Arrow"
        />
      </div>
      <span className="sample-hub__back-button">Back to Sample Management</span>
    </div>
  );
};

export default BackButton;
