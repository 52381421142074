import React, { useState } from "react";
import { Button } from "bio-web-components";

import BioRadFilterModal from "./BioRadFilterModal";
import IncompleteIcon from "../../../../../Utils/Layout/IncompleteIcon";
import { DropdownBox } from "../../../../../Layout/DropdownBox";
import Checkbox from "../../../../../Layout/Checkbox";
import styles from "../styles";

const BioRadBlock = props => {
  const { runBlocks, isSingleRun } = props;
  const [showDownselectModal, setShowDownselectModal] = useState("");

  const renderModal = (runBlock, index, runId) => {
    if (showDownselectModal) {
      return (
        <BioRadFilterModal
          runBlock={runBlock}
          index={index}
          isSingleRun={isSingleRun}
          runId={runId}
          setShowDownselectModal={setShowDownselectModal}
        />
      );
    }

    return null;
  };

  const renderIcon = status => {
    if (status === "incomplete") {
      return <IncompleteIcon style={styles.incompleteIconMargins} />;
    }

    return null;
  };

  const renderRunToggle = (runId, active) => {
    const { toggleRunBlock } = props;

    return (
      <Checkbox
        key={`checkbox run ${runId}`}
        checked={active}
        onClick={() => {
          return toggleRunBlock(active, runId);
        }}
      />
    );
  };

  const setToggleFunction = (runId, active) => {
    if (isSingleRun) {
      return null;
    }

    return renderRunToggle(runId, active);
  };

  return runBlocks.map((runBlock, index) => {
    const { runId, active, status, name, type } = runBlock;

    const icon = renderIcon(status);
    const toggleFunction = setToggleFunction(runId, active);

    let buttonContainerClass = "";
    let containerClass = "";
    if (index === 0) {
      buttonContainerClass = "filter_data-btn";
      containerClass = "runblocks_block";
    }

    return (
      <div className={containerClass} key={`${containerClass}-${runId}`}>
        <DropdownBox
          toggle={toggleFunction}
          heading={name}
          icon={icon}
          preHeading={type}
        >
          <div className={buttonContainerClass}>
            <Button
              inverting
              title="Filter Data"
              contentClass={`complex-data-view-${runId}`}
              onClick={() => {
                return setShowDownselectModal(runId);
              }}
            />
            {renderModal(runBlock, index, runId)}
          </div>
        </DropdownBox>
      </div>
    );
  });
};

export default BioRadBlock;
