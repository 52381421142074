import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import { BaseContainer } from "bio-web-components";

import Container from "../App/components/container";
import ErrorScreen from "./Components/ErrorScreen";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false, hasUser: false, customError: "" };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);

    this.setState({ hasError: true });

    let trimmedError = error.toString();

    // Since "error" will always have a value, this checks whether we set a custom value intended for display
    if (trimmedError && trimmedError.includes("[Biomeme]")) {
      trimmedError = trimmedError.replace("[Biomeme]", "");
      trimmedError = trimmedError.replace("Error: ", "");

      this.setState({ customError: trimmedError });
    }
  }

  render() {
    const { hasError, customError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="AppLevel">
          <BaseContainer>
            <Container>
              <ErrorScreen customError={customError} />
            </Container>
          </BaseContainer>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
