import React from "react";
import { connect } from "react-redux";

import {
  applyMasterFilters as applyMasterFiltersAction,
  updateChannelFilters,
  updateWellFilters,
} from "../../../actions";
import DropdownBox from "../../../../Layout/DropdownBox";

import {
  getAllChannels,
  getWellFilters,
  getTargetMatrix,
} from "../../../selectors";

class SingleRunFilterTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      channels: props.channelFilters,
      wells: props.wellFilters,
      targets: props.targetMatrix,
    };
  }

  // // // // // // // // // //
  // TOGGLE METHODS
  // // // // // // // // // //

  toggleCell(wellIndex, channel) {
    const { targets } = this.state;
    const newTargets = { ...targets };
    newTargets[channel][wellIndex].active =
      !newTargets[channel][wellIndex].active;
    this.setState({ targets: newTargets });
  }

  toggleWells(wellIndex, active) {
    const { targets, wells } = this.state;
    const newTargets = { ...targets };
    const newWells = [...wells];

    const wellToToggle = newWells.find(well => well.label === wellIndex);
    if (wellToToggle) {
      wellToToggle.checked = active;
    }

    Object.keys(newTargets).forEach(newTarget => {
      newTargets[newTarget][wellIndex].active = active;
    });

    this.setState({ targets: newTargets, wells: newWells });
  }

  toggleChannels(channelName, active) {
    const { targets, channels } = this.state;
    const newTargets = { ...targets };
    const newChannels = [...channels];

    const channelToToggle = newChannels.find(
      channel => channel.label.toLowerCase() === channelName.toLowerCase()
    );
    if (channelToToggle) {
      channelToToggle.checked = active;
    }

    newTargets[channelName].forEach((target, index) => {
      newTargets[channelName][index].active = active;
    });

    this.setState({ targets: newTargets, channels: newChannels });
  }

  toggleEverything(active) {
    const { targets, wells, channels } = this.state;

    const newTargets = { ...targets };

    Object.keys(newTargets).forEach(targetChannel => {
      newTargets[targetChannel] = newTargets[targetChannel].map(target => {
        return {
          ...target,
          active,
        };
      });
    });

    const newWells = [...wells].map(well => {
      return {
        ...well,
        active,
      };
    });

    const newChannels = [...channels].map(channel => {
      return {
        ...channel,
        active,
      };
    });

    this.setState({
      targets: newTargets,
      wells: newWells,
      channels: newChannels,
    });
  }

  // // // // // // // // // //
  // SECTION RENDER FUNCTIONS
  // // // // // // // // // //

  renderChannelRow(channel) {
    const { totalWells } = this.props;
    const cells = [];

    totalWells.forEach(well => cells.push(this.renderCell(well, channel)));

    return cells;
  }

  renderCell(wellIndex, channel) {
    const { targetMatrix } = this.props;
    const { targets } = this.state;

    let classname = "";
    if (targets[channel][wellIndex].active) {
      classname = "active";
    }
    if (targetMatrix[channel][wellIndex].hidden) {
      classname = "disabled";
    }

    return (
      <td
        className={classname}
        key={channel + wellIndex}
        onClick={() => this.toggleCell(wellIndex, channel)}
      />
    );
  }

  renderTopRow() {
    const { wells, targets } = this.state;

    let allChecked = true;

    Object.keys(targets).forEach(currentTarget => {
      const result = targets[currentTarget].find(target => !target.active);
      if (result) {
        allChecked = false;
      }
    });

    let allChannelsClass = "";
    if (allChecked) {
      allChannelsClass = "active";
    }

    return (
      <tr key="All Channels">
        <td style={{ border: "none", pointerEvents: "none" }} />
        <td
          className={allChannelsClass}
          onClick={() => {
            this.toggleEverything(!allChecked);
          }}
        >
          <span>All</span>
        </td>
        {wells.map((well, wellIndex) => {
          let wellActive = true;
          Object.keys(targets).forEach(target => {
            if (!targets[target][wellIndex].active) {
              wellActive = false;
            }
          });

          let wellClass = "";
          if (wellActive) {
            wellClass = "active";
          }

          return (
            <td
              key={`td well ${well.label}`}
              className={wellClass}
              onClick={() => this.toggleWells(wellIndex, !wellActive)}
            >
              <span>{well.label + 1}</span>
            </td>
          );
        })}
      </tr>
    );
  }

  render() {
    const { channels, targets } = this.state;
    const { applyMasterFilters } = this.props;

    const dropDownBoxHeading = (
      <span data-tip="Changes made here will apply to all runs.">
        Filter All Runs
      </span>
    );

    return (
      <DropdownBox heading={dropDownBoxHeading} key="Master Controls">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <table className="runtable">
            <tbody>
              {this.renderTopRow()}
              {channels.map(channel => {
                let allChannelsChecked = true;
                const result = targets[channel.label.toLowerCase()].find(
                  target => !target.active
                );
                if (result) {
                  allChannelsChecked = false;
                }

                let channelCircleFill = channel.label;
                if (channel.label.toLowerCase() === "amber") {
                  channelCircleFill = "#f5a623";
                }

                let channelsClassName = "";
                if (allChannelsChecked) {
                  channelsClassName = "active";
                }

                return (
                  <tr key={`tr channel ${channel.label}`}>
                    <td
                      key={`td channel ${channel.label} circle`}
                      style={{
                        border: "none",
                        pointerEvents: "none",
                        marginTop: 5,
                      }}
                    >
                      <svg height="17" width="15">
                        <circle cx="6" cy="10" r="4" fill={channelCircleFill} />
                      </svg>
                    </td>
                    <td
                      key={`td channel ${channel.label}`}
                      className={channelsClassName}
                      onClick={() =>
                        this.toggleChannels(
                          channel.label.toLowerCase(),
                          !allChannelsChecked
                        )
                      }
                    >
                      <span className={channel.label.slice(0, 1).toUpperCase()}>
                        {channel.label.slice(0, 1).toUpperCase()}
                      </span>
                    </td>
                    {this.renderChannelRow(channel.label.toLowerCase())}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <button
              className="btn-modal"
              onClick={() => applyMasterFilters(targets)}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </DropdownBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    channelFilters: getAllChannels(state),
    wellFilters: getWellFilters(state),
    totalWells: getWellFilters(state).map(well => well.label),
    targetMatrix: getTargetMatrix(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyMasterFilters: targets => {
      dispatch(applyMasterFiltersAction(targets));
    },
    updateChannelFilters: channelFilters => {
      dispatch(updateChannelFilters(channelFilters));
    },
    updateWellFilters: wellFilters => {
      dispatch(updateWellFilters(wellFilters));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleRunFilterTable);
