import { CSSProperties } from "react";
import styles from "../../styles";

export const filterByText: CSSProperties = { ...styles.analysisModeText };

export const bulkFilterSection: CSSProperties = {
  display: "flex",
  alignItems: "baseline",
  paddingTop: "8px",
  borderBottom: "1px solid #c9d1d5",
  marginBottom: "5px",
  minHeight: "5rem",
};
