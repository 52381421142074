import React from "react";

import { BugReportFormState } from "../types";
import RadioGroup from "./RadioGroup";

interface Props {
  handleOnChange: (formBundle: BugReportFormState) => void;
  form: BugReportFormState;
}

const BugReportBlockerField: React.FunctionComponent<Props> = (
  props: Props
) => {
  const _renderRadios = () => {
    const { form, handleOnChange } = props;
    const { blocking } = form;

    const BLOCKER_TEXT_NO = "No";
    const BLOCKER_TEXT_WORK_AROUND = "No, I found a workaround";
    const BLOCKER_TEXT_YES = "Yes, I can't move on until this is fixed";

    const radioProps = [
      {
        label: BLOCKER_TEXT_NO,
        onClick: () => handleOnChange({ ...form, blocking: BLOCKER_TEXT_NO }),
        checked: blocking === BLOCKER_TEXT_NO,
      },
      {
        label: BLOCKER_TEXT_WORK_AROUND,
        onClick: () =>
          handleOnChange({ ...form, blocking: BLOCKER_TEXT_WORK_AROUND }),
        checked: blocking === BLOCKER_TEXT_WORK_AROUND,
      },
      {
        label: BLOCKER_TEXT_YES,
        onClick: () => handleOnChange({ ...form, blocking: BLOCKER_TEXT_YES }),
        checked: blocking === BLOCKER_TEXT_YES,
      },
    ];

    return <RadioGroup radioButtonProps={radioProps} />;
  };

  return (
    <div className="bug_report__input_container">
      <h4>
        Does this problem prevent you from finding what you were working on?
      </h4>
      {_renderRadios()}
    </div>
  );
};

export default BugReportBlockerField;
