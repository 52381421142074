import React from "react";
import * as styles from "./styles";

interface Props {
  name: string;
  id: string;
  submissionSuccessful: boolean;
}

const BulkReviewSubmissionEntry: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { name, id, submissionSuccessful } = props;

  let entrySubheadingText = "Failure";
  let entryPreheadingStyling = styles.failureStatusEntryPreheading;
  let entryHeadingStyling = styles.failureStatusEntryHeader;

  if (submissionSuccessful) {
    entrySubheadingText = "Success";
    entryPreheadingStyling = styles.successStatusEntryPreheading;
    entryHeadingStyling = styles.successStatusEntryHeader;
  }

  const renderPreHeading = () => {
    return (
      <h4
        style={{
          ...styles.bulkReviewSubmissionEntryPreheading,
          ...entryPreheadingStyling,
        }}
        className="bulk_review__submission__entry_preheading"
        key={`preHeader-${id}`}
      >
        {entrySubheadingText}
      </h4>
    );
  };

  const renderHeading = () => {
    return (
      <h3
        style={{
          ...styles.bulkSubmissionEntryHeader,
          ...entryHeadingStyling,
        }}
        className="bulk_review__submission__entry_heading"
        key={`header-${id}`}
      >
        {name}
      </h3>
    );
  };

  return (
    <>
      {renderPreHeading()}
      {renderHeading()}
    </>
  );
};

export default BulkReviewSubmissionEntry;
