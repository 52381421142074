import { all, takeLatest, call } from "redux-saga/effects";
import { ApisauceInstance, create } from "apisauce";

import * as t from "./actionTypes";
import { IUserRegistrationRequest } from "./actions";
import { errorResponse } from "./helpers";
import CloudLog from "../Utils/CloudLog";

const getFreshDeskApiInstance = (): ApisauceInstance => {
  return create({
    baseURL: "https://biomeme.freshdesk.com/api/v2/",
    headers: { 'Authorization': `Basic ${process.env.BIO_FRESHDESK_BASE64_API_KEY}` },
  });
}

export function* handleUserRegistrationRequest(
  action: IUserRegistrationRequest
) {
  const { payload, resolve, reject } = action;
  const { firstName, lastName, email, company, teamName, notes } = payload;

  const freshDeskApi = getFreshDeskApiInstance();

  let success = false;
  let responseCode: number | null = null;

  const ticketData = {
    name: `${firstName} ${lastName}`,
    email,
    subject: "New User Registration Request",
    description: `
    <p><span>First Name</span><br>${firstName}<br><br></p>
    <p><span>Last Name</span><br>${lastName}<br><br></p>
    <p><span>Company Name</span><br>${company}<br><br></p>
    <p><span>Team Name</span><br>${teamName}<br><br></p>
    <p><span>Email</span><br>${email}<br><br></p>
    <p><span>Notes</span><br>${notes}<br><br></p>
    `,
    status: 2,
    priority: 1,
  };

  try {
    const { ok, status } = yield call(
      freshDeskApi.post,
      "tickets",
      ticketData
    );

    responseCode = status;

    if (ok) {
      success = true;
    }
  } catch (e) {
    CloudLog.error(`An error occurred reaching registration request endpoint ${e}`);
  } finally {
    if (success) {
      resolve();
    } else {
      reject(errorResponse());
    }
  }
}

export function* watchUserRegistrationRequest() {
  yield takeLatest(t.USER_REGISTRATION_REQUEST, handleUserRegistrationRequest);
}

export default function* () {
  yield all([watchUserRegistrationRequest()]);
}
