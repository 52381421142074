export const getSampleStylingParamters = deactivatedWell => {
  if (deactivatedWell) {
    return {
      wellActive: false,
      fontWeight: 400,
      className: "sample_selector__inactive",
    };
  }

  return {
    wellActive: true,
    fontWeight: 600,
    className: "sample_selector__active",
  };
};

export default {};
