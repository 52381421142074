import { CSSProperties } from "react";

export const bulkReviewTitle: CSSProperties = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "60%",
  marginRight: "10px",
  fontWeight: 600,
};
