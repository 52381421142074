import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  SearchableTable,
  PaginatedSearchableTable,
  Sorting,
} from "bio-web-components";

import {
  getActiveTab,
  getPreSelectedFilters,
  getSelectedRuns,
} from "../../selectors";
import {
  getTableDeviceData,
  getTableDisabledRunIds,
  getTableFilteredRunCount,
  getTableProtocolData,
  getTableRunData,
} from "../../tableSelectors";
import {
  addPreSelectedFilter,
  addSelectedRun,
  removePreSelectedFilter,
  removeSelectedRun,
  selectSingleRun,
} from "../../actions";
import {
  getProtocolsWithIcons,
  getRunsWithIcons,
} from "../../../Utils/helpers";
import { RUNS_PAGE_COUNT } from "../../constants";

interface Props {}

interface StateProps {
  activeTab: string;
  runData: Array<any>;
  protocolData: Array<any>;
  deviceData: Array<any>;
  selectedRuns: Array<any>;
  selectedFilters: Array<any>;
  disabledRunIds: Array<string>;
  runCount: number;
}

interface DispatchProps {
  addRun: (run: any) => void;
  selectRun: (run: any) => void;
  removeRun: (run: any) => void;
  addFilter: (filter: any) => void;
  removeFilter: (filter: any) => void;
}

const Listings = (props: Props & StateProps & DispatchProps) => {
  const runsLabelKeyMap = {
    NAME: "displayName",
    STATUS: "status",
    PROTOCOL: "protocol",
    "TIME OF RUN": "timeOfRun",
    THERMOCYCLER: "bleName",
    LOCATION: "location",
  };

  const runsDefaultSort: Sorting = {
    sortBy: "timeOfRun",
    direction: -1,
  };

  const protocolsLabelKeyMap = {
    NAME: "name",
    RT: "rtTemp",
    DENATURE: "cycleDenature",
    CYCLES: "numberOfCycles",
    ANNEAL: "cycleAnneal",
    EXTENSION: "extension",
  };

  const devicesLabelKeyMap = {
    THERMOCYCLER: "hwNumber",
    "NUMBER OF TESTS": "runCount",
  };

  const {
    activeTab,
    runData,
    deviceData,
    protocolData,
    selectedRuns,
    selectedFilters,
  } = props;

  const _handleRunCheck = (id: string) => {
    const selectedRun = runData.find(run => run.id === id);

    if (selectedRun) {
      const { removeRun, addRun } = props;

      const runIndex = selectedRuns.findIndex(run => run.id === id);
      if (runIndex > -1) {
        removeRun(selectedRun);
      } else {
        addRun(selectedRun);
      }
    }
  };

  const _handleRunSelect = (id: string) => {
    const selectedRun = runData.find(run => run.id === id);

    if (selectedRun) {
      const { selectRun } = props;
      selectRun(selectedRun);
    }
  };

  const _handleFilterCheck = (id: string) => {
    const mergedData = protocolData.concat(deviceData);
    const selectedFilter = mergedData.find(item => item.id === id);

    if (selectedFilter) {
      const { addFilter, removeFilter } = props;

      const filterIndex = selectedFilters.findIndex(filter => filter.id === id);
      if (filterIndex > -1) {
        removeFilter(selectedFilter);
      } else {
        addFilter(selectedFilter);
      }
    }
  };

  const { disabledRunIds } = props;

  return (
    <Fragment>
      {activeTab === "Runs" && (
        <PaginatedSearchableTable
          searchableTableProps={{
            cellClasses: "searchable_table-font",
            data: getRunsWithIcons(runData),
            onRowSelection: _handleRunSelect,
            defaultSort: runsDefaultSort,
            disabledRowIds: disabledRunIds,
            labelKeyMap: runsLabelKeyMap,
            checkboxProps: {
              checkedIds: selectedRuns.map(run => run.id),
              onClick: _handleRunCheck,
            },
          }}
          pageSize={RUNS_PAGE_COUNT}
        />
      )}
      {activeTab === "Protocols" && (
        <SearchableTable
          data={getProtocolsWithIcons(protocolData)}
          labelKeyMap={protocolsLabelKeyMap}
          cellClasses="searchable_table-font"
          checkboxProps={{
            checkedIds: selectedFilters.map(filter => filter.id),
            onClick: _handleFilterCheck,
          }}
        />
      )}
      {activeTab === "Thermocyclers" && (
        <SearchableTable
          data={deviceData}
          labelKeyMap={devicesLabelKeyMap}
          cellClasses="searchable_table-font"
          checkboxProps={{
            checkedIds: selectedFilters.map(filter => filter.id),
            onClick: _handleFilterCheck,
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activeTab: getActiveTab(state),
    runData: getTableRunData(state),
    protocolData: getTableProtocolData(state),
    deviceData: getTableDeviceData(state),
    selectedRuns: getSelectedRuns(state),
    selectedFilters: getPreSelectedFilters(state),
    disabledRunIds: getTableDisabledRunIds(state),
    runCount: getTableFilteredRunCount(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    selectRun: (run: any) => dispatch(selectSingleRun(run)),
    addRun: (run: any) => dispatch(addSelectedRun(run)),
    removeRun: (run: any) => dispatch(removeSelectedRun(run)),
    addFilter: (filter: any) => dispatch(addPreSelectedFilter(filter)),
    removeFilter: (filter: any) => dispatch(removePreSelectedFilter(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Listings);
