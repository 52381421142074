import { CSSProperties } from "react";

export const modalStyle: CSSProperties = {
  width: "500px !important",
};
export const dateInputStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginTop: "30px",
  marginBottom: "30px",
};

export const modalButtonContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "30px",
  marginBottom: "10px",
};

export const cancelButtonStyle: CSSProperties = {
  marginTop: "20px",
  fontWeight: 600,
  cursor: "pointer",
};

export const dateSelectionError: CSSProperties = {
  fontSize: "14px",
  color: "rgba(255, 0, 0, 0.616)",
  fontStyle: "italic",
  marginTop: "10px",
};
