import { ReviewRule } from "js-common";

import * as t from "./actionTypes";
import { Action } from "./actions";
import { RunReview, PreviousReviewUser, RunReviewState } from "./types";
import {
  findAndSetSample,
  findAndSetTarget,
  computeRunResult,
  setAllTargetsCqOverride,
  recomputeAllSampleResults,
  filterAndDeactivateRunBlocks,
  filterAndActivateRunBlocks,
} from "./helpers";

const initialReviewUser: PreviousReviewUser = {
  name: "Incomplete",
  isAdmin: false,
};

const initialReview: RunReview = {
  runId: "",
  protocolId: "",
  reviewerId: "",
  reviewerRole: "user",
  pcrEquipmentId: "",
  ruleId: -1,
  experimentId: null,
  comments: "",
  passed: true,
  failureReason: [],
  samples: [],
};

const initialRules: ReviewRule = {
  id: -1,
  version: 1,
  controlRules: [],
  sampleRules: {
    default: "N/A",
    rules: [],
  },
};

const initialState: RunReviewState = {
  review: initialReview,
  previousReviewUser: initialReviewUser,
  reviewRules: initialRules,
  pendingRuns: [],
  filter: "needsReview",
  loading: false,
  showBulkReviewModal: false,
  bulkReviewRuns: [],
  bulkReviewFilter: "all",
  bulkRunBlocks: [],
  bulkRunSubmissionStatuses: [],
  bulkTabularFailedRunIds: [],
};

export default function (state = initialState, action: Action): any {
  switch (action.type) {
    case t.INITIALIZE_RUN_REVIEW_SUCCESS: {
      const { runReview, reviewLog = [] } = action;

      return {
        ...state,
        review: runReview,
        reviewLog,
      };
    }

    case t.SET_PREVIOUS_REVIEW_USER: {
      const { user } = action;

      return {
        ...state,
        previousReviewUser: user,
      };
    }

    case t.SET_RUN_COMMENTS: {
      const { comments } = action;
      const { review } = state;

      return {
        ...state,
        review: {
          ...review,
          comments: comments.trim(),
        },
      };
    }

    case t.SET_SAMPLE_COMMENTS: {
      const { sampleId, comments } = action;
      const { review } = state;
      const { samples } = review;

      const newSamples = findAndSetSample(
        samples,
        sampleId,
        "comments",
        comments.trim()
      );

      if (newSamples) {
        return {
          ...state,
          review: {
            ...review,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_SAMPLE_TYPE: {
      const { sampleId, sampleType } = action;

      const { review, reviewRules } = state;
      const { samples } = review;

      let newSamples = findAndSetSample(
        samples,
        sampleId,
        "sampleType",
        sampleType,
        reviewRules
      );

      if (newSamples) {
        const { passed, failureReason } = computeRunResult(newSamples);
        newSamples = recomputeAllSampleResults(
          newSamples,
          reviewRules,
          passed,
          failureReason
        );

        return {
          ...state,
          review: {
            ...review,
            passed,
            failureReason,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_SAMPLE_BLANK: {
      const { sampleId, blank } = action;

      const { review, reviewRules } = state;
      const { samples } = review;

      let newSamples = findAndSetSample(
        samples,
        sampleId,
        "blank",
        blank,
        reviewRules
      );

      if (newSamples) {
        const { passed, failureReason } = computeRunResult(newSamples);
        newSamples = recomputeAllSampleResults(
          newSamples,
          reviewRules,
          passed,
          failureReason
        );

        return {
          ...state,
          review: {
            ...review,
            passed,
            failureReason,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_TARGET_CQ: {
      const { cq, sampleId, targetId } = action;
      const { review, reviewRules } = state;
      const { samples } = review;

      let newSamples = findAndSetTarget(
        samples,
        sampleId,
        targetId,
        "overridenCq",
        cq,
        reviewRules
      );

      if (newSamples) {
        const { passed, failureReason } = computeRunResult(newSamples);
        newSamples = recomputeAllSampleResults(
          newSamples,
          reviewRules,
          passed,
          failureReason
        );

        return {
          ...state,
          review: {
            ...review,
            passed,
            failureReason,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_TARGET_OVERRIDE: {
      const { overrideCq, sampleId, targetId } = action;
      const { review, reviewRules } = state;
      const { samples } = review;

      let newSamples = findAndSetTarget(
        samples,
        sampleId,
        targetId,
        "cqOverride",
        overrideCq,
        reviewRules
      );

      if (newSamples) {
        const { passed, failureReason } = computeRunResult(newSamples);
        newSamples = recomputeAllSampleResults(
          newSamples,
          reviewRules,
          passed,
          failureReason
        );

        return {
          ...state,
          review: {
            ...review,
            passed,
            failureReason,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_TARGET_COMMENTS: {
      const { comments, sampleId, targetId } = action;
      const { review } = state;
      const { samples } = review;

      const newSamples = findAndSetTarget(
        samples,
        sampleId,
        targetId,
        "comments",
        comments.trim()
      );

      if (newSamples) {
        return {
          ...state,
          review: {
            ...review,
            samples: newSamples,
          },
        };
      }

      return state;
    }

    case t.SET_REVIEW_RULES: {
      const { rules } = action;

      return {
        ...state,
        reviewRules: rules,
      };
    }

    case t.APPROVE_ALL_TARGETS: {
      const { review, reviewRules } = state;
      const { samples } = review;

      let newSamples = setAllTargetsCqOverride(samples, false, reviewRules);
      const { passed, failureReason } = computeRunResult(newSamples);
      newSamples = recomputeAllSampleResults(
        newSamples,
        reviewRules,
        passed,
        failureReason
      );

      return {
        ...state,
        review: {
          ...review,
          passed,
          failureReason,
          samples: newSamples,
        },
      };
    }

    case t.SET_REVIEW_DASHBOARD_LOADING: {
      const { isLoading } = action;

      return {
        ...state,
        loading: isLoading,
      };
    }

    case t.INITIALIZE_REVIEW_DASHBOARD_SUCCESS: {
      const { runs } = action;

      return {
        ...state,
        pendingRuns: runs,
      };
    }

    case t.SET_ACTIVE_RUN_FILTER: {
      const { filter } = action;

      return {
        ...state,
        filter,
      };
    }

    case t.TOGGLE_BULK_REVIEW_MODAL: {
      const { showBulkReviewModal } = state;

      const newModalState = !showBulkReviewModal;

      return {
        ...state,
        showBulkReviewModal: newModalState,
      };
    }

    case t.INITIALIZE_BULK_REVIEW_SUCCESS: {
      const { runs } = action;

      return {
        ...state,
        bulkReviewRuns: runs,
      };
    }

    case t.BULK_REVIEW_UPDATE_FILTER: {
      const { filter } = action;

      return {
        ...state,
        bulkReviewFilter: filter,
      };
    }

    case t.BULK_REVIEW_SET_RUN_BLOCKS: {
      const { bulkRunBlocks } = action;

      return {
        ...state,
        bulkRunBlocks,
      };
    }

    case t.BULK_REVIEW_DEACTIVATE_RUN_BLOCK: {
      const { runId } = action;
      const newBulkRunBlocks = [...state.bulkRunBlocks];

      return {
        ...state,
        bulkRunBlocks: filterAndDeactivateRunBlocks(newBulkRunBlocks, runId),
      };
    }

    case t.BULK_REVIEW_ACTIVATE_RUN_BLOCK: {
      const { runId } = action;
      const newBulkRunBlocks = [...state.bulkRunBlocks];

      return {
        ...state,
        bulkRunBlocks: filterAndActivateRunBlocks(newBulkRunBlocks, runId),
      };
    }

    case t.BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS: {
      const { bulkApprovedRun } = action;
      const newBulkRunSubmissionStatuses = [
        ...state.bulkRunSubmissionStatuses,
        bulkApprovedRun,
      ];

      return {
        ...state,
        bulkRunSubmissionStatuses: newBulkRunSubmissionStatuses,
      };
    }

    case t.BULK_REVIEW_RESET: {
      return {
        ...state,
        bulkRunBlocks: [],
        bulkRunSubmissionStatuses: [],
        bulkReviewRuns: [],
        bulkTabularFailedRunIds: [],
      };
    }

    case t.BULK_REVIEW_SET_FAILED_RUN_IDS: {
      const { failedRunIds } = action;

      return {
        ...state,
        bulkTabularFailedRunIds: failedRunIds,
      };
    }

    default: {
      return state;
    }
  }
}
