import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Auth from "@aws-amplify/auth";
import { isEmpty } from "lodash";

import DataRoute from "../DataRoute";
import ChartRoute from "../ChartRoute";
import { LoginForm } from "../Auth/components";
import RegistrationForm from "../Registration/Components/RegistrationForm";
import Settings from "../Settings/Components/index";
import Feedback from "../Settings/Subcomponents/BugReport";
import ForgotPassword from "../Auth/components/forgotPassword";
import ResetPassword from "../Auth/components/forgotPasswordStep2";
import ProviderLogin from "../Auth/components/providerLogin";
import LoginError from "../Auth/components/loginError";
import ReviewDashboard from "../RunReview/Components/ReviewDashboard";
import ErrorScreen from "../ErrorBoundary/Components/ErrorScreen";

// SAMPLE MANAGEMENT ROUTES
import SampleHub from "../SampleHub";
import CreateRecord from "../SampleHub/components/CreateRecord";
import EditSampleTemplate from "../SampleHub/components/EditTemplate";
import CreateSampleTemplate from "../SampleHub/components/CreateTemplate";
import { getIDToken, getJWTToken } from "../Auth/selectors";
import { getAwsRedirectDomain, getOauthDomain } from "../Utils/helpers";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const { isAuthenticated } = this.state;

    this.authenticate();

    this.unlisten = history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then()
        .catch(() => {
          if (isAuthenticated) {
            this.setState({ isAuthenticated: false });
          }
        });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  authenticate() {
    const { history, userProfile, accessToken } = this.props;

    Auth.currentAuthenticatedUser()
      .then(() => {
        if (isEmpty(userProfile) || isEmpty(accessToken)) {
          throw Error("No user tokens available.");
        } else {
          this.setState({ loaded: true, isAuthenticated: true });
        }
      })
      .catch(() => history.push("/"));
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userProfile: getIDToken(state),
    accessToken: getJWTToken(state),
  };
}

// eslint-disable-next-line
PrivateRoute = withRouter(connect(mapStateToProps, null)(PrivateRoute));

export default (
  <Switch>
    <Route exact path="/" component={LoginForm} />
    <Route path="/login" component={LoginForm} />
    <Route exact path="/register" component={RegistrationForm} />
    <Route exact path="/error" component={ErrorScreen} />

    <PrivateRoute path="/data" component={DataRoute} />
    <PrivateRoute path="/runs-page" component={ChartRoute} />

    <PrivateRoute path="/sample-management/:id" component={SampleHub} />
    <PrivateRoute exact path="/sample-management" component={SampleHub} />
    <PrivateRoute
      path="/create-sample-layout"
      component={CreateSampleTemplate}
    />
    <PrivateRoute path="/create-sample-qr-code" component={CreateRecord} />
    <PrivateRoute path="/edit-sample-layout" component={EditSampleTemplate} />

    <PrivateRoute path="/settings" component={Settings} />
    <PrivateRoute path="/feedback" component={Feedback} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/provider-login" component={ProviderLogin} />
    <Route path="/login-error" exact component={LoginError} />
    <Route
      path="/saml-login"
      component={() => {
        window.location.href = `https://${getOauthDomain()}/logout?client_id=${
          process.env.BIO_COGNITO_CLIENT_ID
        }&logout_uri=${getAwsRedirectDomain()}`;

        return null;
      }}
    />

    <PrivateRoute path="/review-dashboard" component={ReviewDashboard} />
    <Redirect from="*" to="/" />
  </Switch>
);
