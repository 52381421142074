import React from "react";
import { Input, Row } from "bio-web-components";

const RT = props => {
  const { setValue, values, errors } = props;

  if (!values.showRT) {
    return null;
  }

  return (
    <div className="protocol_creation-box">
      <Row>
        <Input
          value={values.rtTemp}
          onChange={value => setValue("rtTemp", value)}
          error={errors.rtTemp}
          label="Set Temperature (°C)"
        />
        <Input
          value={values.rtTime}
          onChange={value => setValue("rtTime", value)}
          error={errors.rtTime}
          label="Set Time (secs)"
        />
      </Row>
    </div>
  );
};

export default RT;
