import {
  LogicalTargetRuleCollection,
  RecursiveTargetRuleCollection,
  TargetRuleCollection,
} from "js-common";

/**
 * Type guard function to check if generic {ruleCollection: LogicalTargetRuleCollection}
 * is the more specific {TargetRuleCollection}
 *
 * @export
 * @param {LogicalTargetRuleCollection} ruleCollection
 * @return {*}  {ruleCollection is TargetRuleCollection}
 */
export const isTargetRuleCollection = (
  ruleCollection: LogicalTargetRuleCollection
): ruleCollection is TargetRuleCollection => {
  if ((ruleCollection as TargetRuleCollection)?.targetRules) {
    return true;
  }

  return false;
};

/**
 * Type guard function to check if generic {ruleCollection: LogicalTargetRuleCollection}
 * is the more specific RecursiveTargetRuleCollection
 * @export
 * @param {LogicalTargetRuleCollection} ruleCollection
 * @return {*}  {ruleCollection is RecursiveTargetRuleCollection}
 */
export const isRecursiveTargetRuleCollection = (
  ruleCollection: LogicalTargetRuleCollection
): ruleCollection is RecursiveTargetRuleCollection => {
  if (
    (ruleCollection as RecursiveTargetRuleCollection)
      ?.logicalTargetRuleCollections
  ) {
    return true;
  }

  return false;
};
