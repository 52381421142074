export const styles = {
  heading: {
    fontSize: "12px",
    color: "#375464",
  },
  container: {
    marginLeft: -7,
    marginBottom: 20,
  },
};

export default styles;
