import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from "reselect";
import { toPairs, isEmpty } from "lodash";
import { getActiveFolderData } from "../Folders/selectors";
import { getActiveFilters, getSearchQuery } from "../Hub/selectors";

export const isRunDataFetching = state => state.runData.fetched;
export const getRunData = state => state.runData.data;

// only recompute if runData has been initialized
const hasRunData = (prevActiveFolderData, newActiveFolderData) => {
  if (newActiveFolderData.runData) {
    return false;
  }

  return true;
};

const runDataSelector = createSelectorCreator(defaultMemoize, hasRunData);

export const filteredRunData = runDataSelector(
  [getActiveFolderData, getActiveFilters, getSearchQuery],
  (activeFolder, activeFilters) => {
    const protocolIds = [];
    const deviceIds = [];
    const userIds = [];
    activeFilters.forEach(f => {
      if (f.type === "protocol") {
        protocolIds.push(f.value);
      }

      if (f.type === "user") {
        userIds.push(f.value);
      }

      if (f.type === "device") {
        deviceIds.push(f.value);
      }
    });

    const runData = activeFolder.runData || [];
    let filteredData = runData;

    if (protocolIds && protocolIds.length > 0) {
      filteredData = filteredData.filter(
        run => protocolIds.indexOf(run.protocolId) > -1
      );
    }

    if (userIds && userIds.length > 0) {
      filteredData = filteredData.filter(
        run => userIds.indexOf(run.userId) > -1
      );
    }

    if (deviceIds && deviceIds.length > 0) {
      filteredData = filteredData.filter(
        run => deviceIds.indexOf(run.bleName) > -1
      );
    }

    return filteredData;
  }
);

export const getBMRunsLength = createSelector(
  [getActiveFolderData],
  activeFolderData => {
    if (!isEmpty(activeFolderData.runData)) {
      return activeFolderData.runData.length || 0;
    }

    return 0;
  }
);

export const getDeviceData = createSelector(
  [getActiveFolderData],
  activeFolder => {
    const runData = activeFolder.runData || [];
    const deviceData = {};
    runData.forEach(run => {
      const { bleName } = run;
      const prevCount = deviceData[bleName] || 0;
      deviceData[bleName] = prevCount + 1;
    });

    return toPairs(deviceData);
  }
);

export const getDeviceFilterLength = createSelector(
  [getDeviceData],
  devicesList => devicesList.length
);
