import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";

import Modal from "../../../../Layout/Modal";
import Button from "../../../../Layout/Button";
import CyclesDisplayedSlider from "./CyclesDisplayedSlider";
import {
  modalStyle,
  modalButtonContainerStyle,
  cancelButtonStyle,
} from "./styles";

import {
  closeCyclesDisplayedModal,
  updateCyclesDisplayedLowerBound,
  updateCyclesDisplayedUpperBound,
} from "../../../actions";
import {
  isCyclesDisplayedOpen,
  getTargetData,
  getXAxis as selectorGetXAxis,
  getCyclesDisplayedLowerBound as selectorGetCyclesDisplayedLowerBound,
  getCyclesDisplayedUpperBound as selectorGetCyclesDisplayedUpperBound,
} from "../../../selectors";

interface StateProps {
  cyclesDisplayedModalIsOpen: boolean;
  getXAxis: any;
  getTargetData: Object;
  getCyclesDisplayedLowerBound: number;
  getCyclesDisplayedUpperBound: number;
}
interface DispatchProps {
  closeCyclesDisplayedModalBox: () => void;
  updateLowerBound: (value: number) => void;
  updateUpperBound: (value: number) => void;
}

interface Props extends RouteComponentProps<any>, DispatchProps, StateProps {}

const CyclesDisplayedSelector: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    cyclesDisplayedModalIsOpen,
    getCyclesDisplayedLowerBound,
    getCyclesDisplayedUpperBound,
    getXAxis,
    closeCyclesDisplayedModalBox,
  } = props;

  const handleSliderSubmit = () => {
    const { updateLowerBound, updateUpperBound } = props;
    const cyclesDisplayedSlider = document.getElementById(
      "cyclesDisplayedSlider"
    ) as noUiSlider.Instance;

    if (cyclesDisplayedSlider) {
      const values = cyclesDisplayedSlider.noUiSlider.get();

      const lowerBound = parseInt(values[0]);
      const upperBound = parseInt(values[1]);

      updateLowerBound(lowerBound);
      updateUpperBound(upperBound);
    }
    closeCyclesDisplayedModalBox();
  };

  const _renderButtons = () => {
    return (
      <div className="modal__buttons" style={modalButtonContainerStyle}>
        <Button
          title="Apply"
          buttonClass="btn-modal"
          onClick={handleSliderSubmit}
        />
        <div style={cancelButtonStyle} onClick={closeCyclesDisplayedModalBox}>
          Cancel
        </div>
      </div>
    );
  };
  const xAxis = getXAxis;
  const lowerBound = getCyclesDisplayedLowerBound;
  const upperBound = getCyclesDisplayedUpperBound;

  return (
    <Modal
      open={cyclesDisplayedModalIsOpen}
      heading="Cycles Displayed"
      onClose={closeCyclesDisplayedModalBox}
      className="modal--run_detail"
      style={modalStyle}
      bodyClass="modal__analysis"
    >
      <p>Set the range displayed on the graph below:</p>
      <CyclesDisplayedSlider
        xAxis={xAxis}
        lowerBound={lowerBound}
        upperBound={upperBound}
      />
      {_renderButtons()}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    cyclesDisplayedModalIsOpen: isCyclesDisplayedOpen(state),
    getTargetData: getTargetData(state),
    getXAxis: selectorGetXAxis(state),
    getCyclesDisplayedLowerBound: selectorGetCyclesDisplayedLowerBound(state),
    getCyclesDisplayedUpperBound: selectorGetCyclesDisplayedUpperBound(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeCyclesDisplayedModalBox: () => {
      dispatch(closeCyclesDisplayedModal());
    },
    updateLowerBound: (lowerBound: number) => {
      dispatch(updateCyclesDisplayedLowerBound(lowerBound));
    },
    updateUpperBound: (upperBound: number) => {
      dispatch(updateCyclesDisplayedUpperBound(upperBound));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyclesDisplayedSelector);
