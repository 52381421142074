import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { hideChart as hideChartAction } from "../actions";
import { getRootFolders as getRootFoldersAction } from "../../Folders/actions";
import { getShowChart, getHubLoading } from "../selectors";
import { getSidebarDepth } from "../../Sidebar/selectors";
import { classNamesParser } from "../../Utils/layoutUtils";
import TableContainer from "./tableContainer";
import FilterBar from "./filterBar";
import Loader from "../../Layout/Loader";

class ContentContainer extends Component {
  componentDidMount() {
    const { hideChart, getRootFolders } = this.props;

    hideChart();
    getRootFolders();
  }

  render() {
    const { showChart, loading, depth } = this.props;

    const classNames = classNamesParser({
      contentInner: true,
      contentInnerCentered: depth > 0,
      "full-width": showChart,
    });

    return (
      <div className={classNames}>
        <Fragment>
          <Loader show={loading} />
          <FilterBar />
          <TableContainer />
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showChart: getShowChart(state),
    loading: getHubLoading(state),
    depth: getSidebarDepth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideChart: () => dispatch(hideChartAction()),
    getRootFolders: () => dispatch(getRootFoldersAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
