import { createSelector } from "reselect";
import { BulkFullRunData, RunData } from "./types";

export const getFullRunData = (state: any): RunData[] => {
  return state.fullRunData.fullRunData.data;
};

export const getBulkFullRunDataStore = (state: any): BulkFullRunData => {
  return state.fullRunData.bulkFullRunData;
};

export const getBiomeme1BulkFullRunData = createSelector(
  getBulkFullRunDataStore,
  bulkFullRunData => {
    return bulkFullRunData["biomeme-1"];
  }
);

export const getBiomeme2BulkFullRunData = createSelector(
  getBulkFullRunDataStore,
  bulkFullRunData => {
    return bulkFullRunData["biomeme-2"];
  }
);

export const getIspBulkFullRunData = createSelector(
  getBulkFullRunDataStore,
  bulkFullRunData => {
    return bulkFullRunData.isp;
  }
);

export const getAbiBulkFullRunData = createSelector(
  getBulkFullRunDataStore,
  bulkFullRunData => {
    return bulkFullRunData.abi;
  }
);

export const getBioradBulkFullRunData = createSelector(
  getBulkFullRunDataStore,
  bulkFullRunData => {
    return bulkFullRunData.biorad;
  }
);

export const getRunProtocolId = createSelector(
  getFullRunData,
  (runData): string => {
    return runData[0].protocolId;
  }
);

export const getRunDevice = createSelector(
  getFullRunData,
  (runData): string => {
    return runData[0].device;
  }
);

export const getBulkFullRunData = createSelector(
  [
    getBiomeme1BulkFullRunData,
    getBiomeme2BulkFullRunData,
    getIspBulkFullRunData,
    getAbiBulkFullRunData,
    getBioradBulkFullRunData,
  ],
  (
    biomeme1BulkFullRunData,
    biomeme2BulkFullRunData,
    ispBulkFullRunData,
    abiBulkFullRunData,
    bioradBulkFullRunData
  ) => {
    return {
      "biomeme-1": biomeme1BulkFullRunData.data,
      "biomeme-2": biomeme2BulkFullRunData.data,
      isp: ispBulkFullRunData.data,
      abi: abiBulkFullRunData.data,
      biorad: bioradBulkFullRunData.data,
    };
  }
);
