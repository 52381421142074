import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
} from "redux-saga/effects";
import { backendApi } from "../Utils/index";
import { getActiveTeamId } from "../Auth/selectors";

import * as a from "./actions";
import * as t from "./actionTypes";
import history from "../store/history";
import { trimLeadingWhitespace } from "./helpers";
import CloudLog from "../Utils/CloudLog";

export default function* rootSaga() {
  /** *****************************
   *  INITIALIZERS
   ****************************** */
  function* initializeSampleTemplates() {
    const teamId = yield select(getActiveTeamId);

    try {
      const { data, status } = yield call(
        backendApi.get,
        `/samples/templates?detailsVersion=biomeme-2&teamId=${teamId}`
      );

      if (status >= 200 || status < 300) {
        yield put(a.initializeSampleTemplatesSuccess(data));
      } else {
        CloudLog.error(
          `Status ${status} in retrieveTemplates for teamId id ${teamId}`
        );
      }
    } catch (e) {
      CloudLog.error(`Error in retrieveTemplates: ${e}`);
    }
  }

  function* initializeSampleHub(action) {
    const { path = "/sample-management" } = action;

    yield put(a.clearErrors());
    yield put(a.unsetActiveSampleTemplate());
    yield put(a.initializeSampleTemplates());
    yield put(a.retrieveSampleRecords(path));
  }

  function* watchInitializeSampleTemplates() {
    yield takeLatest(t.INITIALIZE_SAMPLE_TEMPLATES, initializeSampleTemplates);
  }

  function* watchInitializeSampleHub() {
    yield takeLatest(t.INITIALIZE_SAMPLE_HUB, initializeSampleHub);
  }

  /** *****************************
   *  TEMPLATE SELECTION
   ****************************** */
  function* setTemplateToEdit() {
    yield put(a.clearErrors());
    history.push("/edit-sample-layout");
  }

  function* watchSetTemplateToEdit() {
    yield takeLatest(t.SET_TEMPLATE_TO_EDIT, setTemplateToEdit);
  }

  /** *****************************
   *  SAMPLE RECORD RETRIEVAL
   ****************************** */
  function* retrieveSampleRecords(action) {
    const { path = "/sample-management" } = action;
    const teamId = yield select(getActiveTeamId);

    try {
      const { data = [], status } = yield call(
        backendApi.get,
        `/samples/records?detailsVersion=biomeme-2&teamId=${teamId}`
      );

      if (status >= 200 || status < 300) {
        yield put(a.retrieveSampleRecordsSuccess(data, path));
      } else {
        CloudLog.error(
          `Status ${status} in retrieveRecords for teamId ${teamId}`
        );
      }
    } catch (e) {
      CloudLog.error(`Error in retrieveRecords: ${e}`);
    }
  }

  function* retrieveRecordsSuccess(action) {
    const { path = "/sample-management" } = action;

    if (
      path.includes("/sample-management/") &&
      path.replace("/sample-management/", "").trim().length > 0
    ) {
      yield put(a.setTableDisplay("record"));
    }

    yield history.push(path);
  }

  function* watchRetrieveRecordsSuccess() {
    yield takeLatest(t.RETRIEVE_SAMPLE_RECORDS_SUCCESS, retrieveRecordsSuccess);
  }

  function* watchRetrieveRecords() {
    yield takeLatest(t.RETRIEVE_SAMPLE_RECORDS, retrieveSampleRecords);
  }

  /** *****************************
   *  SAMPLE RECORD UPLOADING
   ****************************** */
  function* uploadRecords(action) {
    const { records } = action;

    let uploadSuccess = true;

    try {
      for (let i = 0; i < records.length; i += 1) {
        const record = records[i];

        const { status } = yield call(
          backendApi.post,
          `/samples/records?detailsVersion=biomeme-2&teamId=${record.teamId}`,
          {
            id: record.id,
            name: record.name,
            shortName: record.shortName,
            metadata: record.metadata.map(field => {
              if (field.type === "date") {
                return {
                  parameterName: field.parameterName,
                  value: field.value.replace(new RegExp("-", "g"), "/"),
                };
              }

              return {
                parameterName: field.parameterName,
                value: trimLeadingWhitespace(field.value),
              };
            }),
            date: record.date,
            templateId: record.templateId,
          }
        );
        if (status !== 200) {
          CloudLog.error(
            `Status ${status} in uploadRecords for record id ${record.id}`
          );
          uploadSuccess = false;
          yield put(a.recordPostUnsuccessful(record.name));
        }
      }
      if (uploadSuccess) {
        yield put(a.retrieveSampleRecords("sample-management"));
        yield put(a.clearRecordPostErrors());
      } else {
        CloudLog.error(
          "Failed record upload for one or more records in uploadRecords"
        );
      }
    } catch (e) {
      CloudLog.error(`Error in uploadRecords: ${e}`);
    }
  }

  function* watchUploadRecords() {
    yield takeLatest(t.UPLOAD_RECORDS, uploadRecords);
  }

  /** *****************************
   *  SAMPLE TEMPLATE RETRIEVAL
   ****************************** */
  function* retrieveSampleTemplates() {
    const teamId = yield select(getActiveTeamId);

    try {
      const { data, status } = yield call(
        backendApi.get,
        `/samples/templates?detailsVersion=biomeme-2&teamId=${teamId}`
      );

      if (status >= 200 || status < 300) {
        yield put(a.retrieveSampleTemplatesSuccess(data));
      } else {
        CloudLog.error(
          `Status ${status} in retrieveTemplates for teamId id ${teamId}`
        );
      }
    } catch (e) {
      CloudLog.error(`Error in retrieveTemplates: ${e}`);
    }
  }

  function* retrieveTemplatesSuccess() {
    yield history.push("/sample-management");
  }

  function* watchRetrieveTemplatesSuccess() {
    yield takeLatest(
      t.RETRIEVE_SAMPLE_TEMPLATES_SUCCESS,
      retrieveTemplatesSuccess
    );
  }

  function* watchRetrieveTemplates() {
    yield takeLatest(t.RETRIEVE_SAMPLE_TEMPLATES, retrieveSampleTemplates);
  }

  /** *****************************
   *  SAMPLE TEMPLATE UPLOADING
   ****************************** */
  function* uploadTemplates(action) {
    const { template } = action;

    try {
      const formattedMetadata = [];
      template.metadata.forEach(field => {
        const { parameterName, defaultValue } = field;

        formattedMetadata.push({
          ...field,
          parameterName: trimLeadingWhitespace(parameterName),
          defaultValue: trimLeadingWhitespace(defaultValue),
        });
      });

      const { status } = yield call(
        backendApi.post,
        `/samples/templates?detailsVersion=biomeme-2&teamId=${template.teamId}`,
        {
          id: template.id,
          name: template.name,
          metadata: formattedMetadata,
          date: template.date,
        }
      );
      if (status === 200) {
        yield put(a.uploadTemplateSuccess(template));
        yield put(a.retrieveSampleTemplates());
        yield put(a.clearTemplatePostErrors());
      } else {
        yield put(a.templatePostUnsuccessful(template.name));
        CloudLog.error(
          `Status ${status} in uploadTemplates for template id ${template.id}`
        );
      }
    } catch (e) {
      CloudLog.error(`Error in uploadTemplates: ${e}`);
    }
  }

  function* watchUploadTemplate() {
    yield takeLatest(t.UPLOAD_TEMPLATE, uploadTemplates);
  }

  /** *****************************
   *  SAMPLE TEMPLATE EDITING
   ****************************** */
  function* editTemplate(action) {
    const { id, template } = action;

    try {
      const { status } = yield call(
        backendApi.put,
        `/samples/templates/${id}?detailsVersion=biomeme-2&teamId=${template.teamId}`,
        {
          id,
          name: template.name,
          metadata: template.metadata,
        }
      );
      if (status === 200) {
        yield put(a.retrieveSampleTemplates());
        yield put(a.retrieveSampleRecords());
        yield put(a.clearTemplatePostErrors());
      } else {
        yield put(a.templatePostUnsuccessful());
        CloudLog.error(
          `Status ${status} in editSampleTemplate for template id ${id}`
        );
      }
    } catch (e) {
      CloudLog.error(`Error in editTemplate: ${e}`);
    }
  }

  function* watchEditTemplate() {
    yield takeLatest(t.EDIT_TEMPLATE, editTemplate);
  }

  /** *****************************
   *  SAMPLE RECORD DELETION
   ****************************** */
  function* deleteSampleRecord(action) {
    const { id } = action;
    const teamId = yield select(getActiveTeamId);

    try {
      const { status } = yield call(
        backendApi.delete,
        `/samples/records/${id}?detailsVersion=biomeme-2&teamId=${teamId}`
      );

      if (status === 200) {
        CloudLog.info(`Successfully deleted record: ${id} `);
        yield put(a.retrieveSampleRecords());
      } else {
        CloudLog.error(
          `status ${status} in deleteSampleRecord for record id ${id}`
        );
        yield put(a.recordDeleteUnsuccessful(id));
      }
    } catch (e) {
      CloudLog.error(`Error in deleteSampleRecord: ${e}`);
    }
  }

  function* watchDeleteSampleRecord() {
    yield takeEvery(t.DELETE_SAMPLE_RECORD, deleteSampleRecord);
  }

  /** *****************************
   *  SAMPLE TEMPLATE DELETION
   ****************************** */
  function* deleteSampleTemplate(action) {
    const { id } = action;
    const teamId = yield select(getActiveTeamId);

    try {
      const { status } = yield call(
        backendApi.delete,
        `/samples/templates/${id}?detailsVersion=biomeme-2&teamId=${teamId}`
      );
      CloudLog.info(`status in deleteSampleTemplate: ${status}`);
      if (status === 200) {
        yield put(a.initializeSampleHub());
        CloudLog.info(`Successfully deleted template id: ${id}`);
      } else {
        CloudLog.error(
          `Status ${status} in deleteSampleTemplate for template id ${id}`
        );
        yield put(a.templateDeleteUnsuccessful(id));
      }
    } catch (e) {
      CloudLog.error(`Error in deleteSampleTemplate: ${e}`);
    }
  }

  function* watchDeleteSampleTemplate() {
    yield takeEvery(t.DELETE_SAMPLE_TEMPLATE, deleteSampleTemplate);
  }

  /** *****************************
   *  ROOT
   ****************************** */
  yield all([
    watchInitializeSampleHub(),
    watchInitializeSampleTemplates(),
    watchSetTemplateToEdit(),
    watchRetrieveRecordsSuccess(),
    watchRetrieveRecords(),
    watchUploadRecords(),
    watchRetrieveTemplatesSuccess(),
    watchRetrieveTemplates(),
    watchUploadTemplate(),
    watchEditTemplate(),
    watchDeleteSampleRecord(),
    watchDeleteSampleTemplate(),
  ]);
}
