import React, { Component } from "react";
import { connect } from "react-redux";
import { scaleLinear, line, select, axisBottom, axisLeft, scaleLog } from "d3";
import { range as lodashRange } from "lodash";

import {
  getXAxis,
  getYAxis,
  getAxisLabels,
  getChartData,
  getRunBlocks,
  getBackgroundValues,
  isLogScale,
  isReprocessingModeActive,
  isNonindexedXAxis,
  isReviewModeActive,
  getCyclesDisplayedLowerBound,
  getCyclesDisplayedUpperBound,
  getZoomedYAxis,
} from "../selectors";
import Slider from "./chartSlider";
import constants from "./chartConstants";
import {
  redrawBackgroundElements,
  drawReprocessingElements,
  clearChart,
  calculateChartHeightAdjustment,
  drawReviewCutoffLines,
} from "./chartHelpers";
import { round } from "../../Utils/helpers";
import { analysisModes } from "../constants";
import { getCalculatedReviewCutoffStartValue } from "../../RunReview/selectors";
import { checkIfWellContainsLetters } from "../helpers";

// // // // // // // // // // // // // // // // // // // // //
// CHART AXIS VARIABLES
// // // // // // // // // // // // // // // // // // // // //
let paddedYMin = 0;
let paddedYMax = 0;
let paddedXMin = 0;
let paddedXMax = 0;
let x = 0;
let y = 0;

// // // // // // // // // // // // // // // // // // // // //
// CHART COMPONENT
// // // // // // // // // // // // // // // // // // // // //
class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: constants.width,
      height: constants.height,
    };

    // eslint-disable-next-line
    this.props.onRef(this);
  }

  componentDidMount() {
    const { reviewModeIsActive, reprocessingModeIsActive } = this.props;

    let height = constants.fallbackChartHeight;
    const pageLeft = document.getElementById("runs_page__left");

    if (pageLeft) {
      height =
        +pageLeft.clientHeight -
        calculateChartHeightAdjustment(reviewModeIsActive);
    }

    if (reprocessingModeIsActive) {
      height -= 80;
    }

    const width = +this.chart.clientWidth - 70;

    this.setState({ width, height }, () => {
      return this.drawChart();
    });
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { targets } = this.props;

    if (prevProps.targets !== targets) {
      this.drawChart();
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props;

    onRef(undefined);
    window.removeEventListener("resize", this.handleResize);
  }

  // // // // // // // // // // // // // // // // // // // // //
  // RESIZE FUNCTIONALITY
  // // // // // // // // // // // // // // // // // // // // //
  handleResize = e => {
    const { reprocessingModeIsActive, reviewModeIsActive } = this.props;

    let height = constants.fallbackChartHeight;
    const pageLeft = document.getElementById("runs_page__left");

    if (pageLeft) {
      height =
        +pageLeft.clientHeight -
        calculateChartHeightAdjustment(reviewModeIsActive);
    }

    if (
      reprocessingModeIsActive &&
      document.getElementById("slider").noUiSlider
    ) {
      height -= 80;
    }

    if (height < constants.minHeight) {
      height = constants.minHeight;
    }

    if (e.target && this.chart) {
      return this.setState(
        {
          width: +this.chart.clientWidth - 85,
          height,
        },
        () => {
          return this.drawChart();
        }
      );
    }

    if (this.chart) {
      return this.setState({ width: constants.width, height }, () => {
        return this.drawChart();
      });
    }

    return null;
  };

  // // // // // // // // // // // // // // // // // // // // //
  // SLIDER UPDATE WATCHER
  // // // // // // // // // // // // // // // // // // // // //
  handleSliderChange = values => {
    if (values && values.length > 1) {
      redrawBackgroundElements.apply(this, [
        values,
        paddedYMin,
        paddedYMax,
        x,
        y,
      ]);
    }
  };

  // // // // // // // // // // // // // // // // // // // // //
  // LINE HOVER/CLICK METHODS
  // // // // // // // // // // // // // // // // // // // // //
  mouseOver = (id, runName, well, Cq, sampleId) => {
    const { analysisMode } = this.props;

    select(this.node)
      .select(`#L${id}`)
      .style("opacity", "1")
      .style("stroke-width", "3px");

    if (analysisMode[analysisModes.baseline]) {
      this.adjustThresholdStyle(id, true);
    }

    const wellContainersLetters = checkIfWellContainsLetters(well);

    let formattedWell;

    if (wellContainersLetters) {
      formattedWell = well;
    } else {
      formattedWell = well + 1;
    }

    if (runName && (well || well === 0)) {
      document.getElementById("tooltipP1").innerHTML = `${runName}`;
      document.getElementById("tooltipP2").innerHTML = `${formattedWell}`;
      document.getElementById("tooltipP3").innerHTML = `${sampleId || "N/a"}`;
      document.getElementById("tooltipP4").innerHTML = `${round(Cq, 2)}`;

      document.getElementById("tooltip").className = "";
    }
  };

  mouseOut = id => {
    const { analysisMode } = this.props;

    select(this.node)
      .select(`#L${id}`)
      .style("opacity", constants.normalOpacity)
      .style("stroke-width", "1px");

    if (analysisMode[analysisModes.baseline]) {
      this.adjustThresholdStyle(id, false);
    }

    document.getElementById("tooltip").className = "hidden";
  };

  adjustThresholdStyle = (id, isMouseOver) => {
    const thresholdId = `${id}-threshold`;

    let opacity = constants.normalOpacity;
    let strokeWidth = "1px";
    if (isMouseOver) {
      opacity = "1";
      strokeWidth = "3px";
    }

    select(this.node)
      .select(`#L${thresholdId}`)
      .style("opacity", opacity)
      .style("stroke-width", strokeWidth);
  };

  // // // // // // // // // // // // // // // // // // // // //
  // DRAW AND RENDER FUNCTIONALITY
  // // // // // // // // // // // // // // // // // // // // //
  renderReprocessingModeSlider = () => {
    const { reprocessingModeIsActive } = this.props;
    const { width } = this.state;
    const { margin } = constants;

    if (reprocessingModeIsActive) {
      return (
        <Slider
          handleSliderChange={this.handleSliderChange}
          width={width + margin.left + margin.right}
        />
      );
    }

    return null;
  };

  tickIncrementCalculator = (xAxis, range) => {
    const { nonindexedXAxis } = this.props;

    let tickIncrementValue = 5;
    if (xAxis.length >= 100) {
      tickIncrementValue = 10;
    }

    if (nonindexedXAxis) {
      return range.filter(d => {
        return (d + 1) % 1 === 0;
      });
    }

    return range.filter(d => {
      return (d + 1) % tickIncrementValue === 0;
    });
  };

  drawChart = () => {
    const {
      xAxis,
      yAxis,
      logScale,
      targets,
      nonindexedXAxis,
      reprocessingModeIsActive,
      reviewModeIsActive,
      axisLabels,
      reviewModeCutoffStartValue,
      cyclesDisplayedLowerBound,
      cyclesDisplayedUpperBound,
      zoomedYAxis,
    } = this.props;
    const { width, height } = this.state;
    const { margin } = constants;
    const { node } = this;
    clearChart.apply(this);

    // Scaling the chart based on defined width and height

    if (logScale) {
      y = scaleLog().clamp(true).range([height, 0]);
    } else {
      y = scaleLinear().range([height, 0]);
    }

    x = scaleLinear().range([0, width]);

    let incrementBy = 1;
    let xDomainRangeInclusive;

    if (nonindexedXAxis) {
      paddedXMin = Math.min(...xAxis.data);
      paddedXMax = Math.max(...xAxis.data);

      incrementBy = (paddedXMax - paddedXMin) / (xAxis.data.length - 1);
      xDomainRangeInclusive = xAxis.data;
    } else {
      if (
        cyclesDisplayedLowerBound === -1 &&
        cyclesDisplayedUpperBound === -1
      ) {
        paddedXMin = -1;
        paddedXMax = xAxis.length;
      } else if (
        cyclesDisplayedLowerBound === 0 &&
        cyclesDisplayedUpperBound !== -1
      ) {
        paddedXMin = -1;
        paddedXMax = cyclesDisplayedUpperBound;
      } else {
        paddedXMin = cyclesDisplayedLowerBound - 1;
        paddedXMax = cyclesDisplayedUpperBound;
      }

      xDomainRangeInclusive = lodashRange(paddedXMin, paddedXMax, incrementBy);
    }

    if (cyclesDisplayedLowerBound === -1 && cyclesDisplayedUpperBound === -1) {
      paddedYMax = yAxis.max + (yAxis.max - yAxis.min) * 0.1;
      paddedYMin = yAxis.min - (yAxis.max - yAxis.min) * 0.1;
    } else {
      paddedYMax = zoomedYAxis.max + (zoomedYAxis.max - zoomedYAxis.min) * 0.1;
      paddedYMin = zoomedYAxis.min - (zoomedYAxis.max - zoomedYAxis.min) * 0.1;
    }

    if (nonindexedXAxis) {
      const difference = (paddedXMax - paddedXMin) * 0.1;

      if (difference < 1) {
        paddedXMin -= difference;
        paddedXMax += difference;
      } else {
        paddedXMin -= 1;
        paddedXMax += 1;
      }
    } else if (paddedXMax % 5 !== 0) {
      paddedXMax = Math.ceil(paddedXMax / 5) * 5;
    }

    x.domain([paddedXMin, paddedXMax]);

    if (logScale && paddedYMin <= 0) {
      paddedYMin = 1;
    }

    y.domain([paddedYMin, paddedYMax]);

    // Append x-axis to the chart
    select(node)
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", `translate(0, ${height})`)
      .call(
        axisBottom(x)
          // Add +1 to all the (d) values to make the x-axis appear to be one-indexed.
          .tickValues(
            this.tickIncrementCalculator(xAxis, xDomainRangeInclusive)
          )
          .tickFormat(d => {
            let format = d + 1;
            if (nonindexedXAxis) {
              format = d;
            }

            return format;
          })
          .tickSize(-height)
      );

    let yAxisCall = axisLeft(y);
    if (logScale) {
      yAxisCall = axisLeft(y).ticks(15, ",");
    }

    // Append y-axis to the chart
    select(node).append("g").attr("class", "yaxis").call(yAxisCall);

    // Append vertical gridlines to the chart
    select(this.node)
      .select(".xaxis")
      .selectAll("line")
      .style("opacity", 0.5)
      .attr("stroke", "#ddd");

    // Handle drawing all reprocessing elements
    if (reprocessingModeIsActive) {
      drawReprocessingElements.apply(this, [
        paddedYMin,
        paddedYMax,
        paddedXMin,
        paddedXMax,
        xAxis.length,
        x,
        y,
      ]);
    }

    // Handle drawing review mode background elements
    if (reviewModeIsActive && reviewModeCutoffStartValue !== null) {
      drawReviewCutoffLines.apply(this, [
        reviewModeCutoffStartValue,
        xAxis.length,
        paddedYMin,
        paddedYMax,
        x,
        y,
      ]);
    }

    // Used to create a line that's being appended to the chart.
    // .x => each index correlates to a cycle
    // .y => returns a singular data point for each cycle

    targets.forEach(target => {
      let { color } = target;

      if (color === "amber") {
        color = "#f5a623";
      }

      const valueline = line()
        .x(d => {
          return x(d.x);
        })
        .y(d => {
          return y(d.y);
        });

      const pl = select(node)
        .append("g")
        .attr("class", "gline")
        .attr("key", `g-${target.id}`)
        .selectAll("path")
        .data([target.data])
        .enter();

      if (target.orientation === "horizontal") {
        let strokeDashArray = "5,5";
        if (target.style === "solid") {
          strokeDashArray = "0";
        }

        pl.data([target.data])
          .append("path")
          .attr("class", "line")
          .style("fill", "none")
          .style("stroke", color)
          .style("stroke-width", "1px")
          .style("stroke-dasharray", strokeDashArray)
          .style("opacity", constants.normalOpacity)
          .attr("id", `L${target.id}`)
          .attr("key", `${target.id}`)
          .attr("d", valueline)
          .on("mouseover", () => {
            return this.mouseOver(
              target.id,
              target.runName,
              target.well,
              target.CQ,
              target.sampleId
            );
          })
          .on("mouseout", () => {
            return this.mouseOut(target.id);
          });
      }
    });

    // Text label for the x-axis
    select(node)
      .append("text")
      .attr("transform", `translate(${width / 2}, ${height + margin.top + 20})`)
      .style("text-anchor", "middle")
      .text(axisLabels.x);

    // Text label for the y-axis
    select(node)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left - 10)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(axisLabels.y);
  };

  render() {
    const { margin, padding } = constants;
    const { width, height } = this.state;

    return (
      <div
        // Pulling this out into a function breaks the graph
        // eslint-disable-next-line
        ref={node => (this.chart = node)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div id="tooltip" className="hidden">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: 600, paddingRight: 50 }}>RUN NAME</span>
            <span id="tooltipP1">Run Name</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: 600, paddingRight: 50 }}>
              WELL NUMBER
            </span>
            <span id="tooltipP2">Well Number</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: 600, paddingRight: 50 }}>SAMPLE ID</span>
            <span id="tooltipP3">Sample ID</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: 600, paddingRight: 50 }}>Cq</span>
            <span id="tooltipP4">Cq</span>
          </div>
        </div>
        <svg
          id="chart-svg"
          // Pulling this out into a function breaks the graph
          // eslint-disable-next-line
          ref={node => (this.node = node)}
          width={width + margin.left + margin.right}
          height={height + margin.top + margin.bottom}
          style={{
            paddingLeft: padding.left,
            paddingTop: padding.top,
            overflow: "visible",
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        />
        {this.renderReprocessingModeSlider()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    xAxis: getXAxis(state),
    yAxis: getYAxis(state),
    logScale: isLogScale(state),
    nonindexedXAxis: isNonindexedXAxis(state),
    axisLabels: getAxisLabels(state),
    targets: getChartData(state),
    runCount: getRunBlocks(state).length,
    backgroundValues: getBackgroundValues(state),
    reprocessingModeIsActive: isReprocessingModeActive(state),
    reviewModeIsActive: isReviewModeActive(state),
    reviewModeCutoffStartValue: getCalculatedReviewCutoffStartValue(state),
    cyclesDisplayedLowerBound: getCyclesDisplayedLowerBound(state),
    cyclesDisplayedUpperBound: getCyclesDisplayedUpperBound(state),
    zoomedYAxis: getZoomedYAxis(state),
  };
};

export default connect(mapStateToProps, null)(Chart);
