import * as t from "./actionTypes";

export function getProtoData() {
  return {
    type: t.GET_DATA_REQ,
  };
}

export function getProtoError(e) {
  return {
    type: t.GET_DATA_ERROR,
    error: e,
  };
}

export function getProtoSuccess(payload) {
  return {
    type: t.GET_DATA_SUCCESS,
    payload,
  };
}

export function reset() {
  return {
    type: t.RESET,
  };
}

export function protocolsReceived(protocols) {
  return {
    type: t.PROTOCOLS_RECEIVED,
    protocols,
  };
}

export function createProtocol(detailsVersion, protocolData, resolve, reject) {
  return {
    type: t.CREATE_PROTOCOL,
    detailsVersion,
    protocolData,
    resolve,
    reject,
  };
}

export function getExternalUploadProtocolsRequest(detailsVersion) {
  return {
    type: t.GET_EXTERNAL_UPLOAD_PROTOCOLS_REQUEST,
    detailsVersion,
  };
}

export function getExternalUploadProtocolsSuccess(protocols) {
  return {
    type: t.GET_EXTERNAL_UPLOAD_PROTOCOLS_SUCCESS,
    protocols,
  };
}
