import { all, call, put, select, takeLatest } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";

import * as t from "./actionTypes";
import * as hubTypes from "../Hub/actionTypes";
import * as sidebarTypes from "../Sidebar/actionTypes";
import * as runsPageTypes from "../RunsPage/actionTypes";
import * as settingsTypes from "../Settings/actionTypes";
import { FEATURE_POST } from "../App/actionTypes";

import * as actions from "./actions";
import { resetSidebar } from "../Sidebar/actions";
import { reset as resetFolders, getRootFolders } from "../Folders/actions";
import { resetSelectedRuns } from "../Hub/actions";

import { backendApi } from "../Utils";
import history from "../store/history";
import AWSConfig from "../Utils/aws-config";
import { getProvider } from "./selectors";
import { getOauthDomain } from "../Utils/helpers";
import { refreshSessionAsync } from "./helpers";
import CloudLog from "../Utils/CloudLog";

function buildProviderURL(provider) {
  const redirectDomain = `${AWSConfig.redirectDomain}login`;

  const url = `https://${getOauthDomain()}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${redirectDomain}&response_type=code&client_id=${
    process.env.BIO_COGNITO_CLIENT_ID
  }&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`;

  return url;
}

function* navigateSignedInUser() {
  history.push("/data");

  yield put({ type: FEATURE_POST, feature: "login" });
}

function* setUserDetails() {
  const user = yield call([Auth, Auth.currentAuthenticatedUser]);

  if (
    user &&
    user.signInUserSession &&
    user.signInUserSession.idToken &&
    user.signInUserSession.idToken.payload &&
    user.signInUserSession.idToken.payload.email
  ) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.signInUserSession.idToken.payload.email,
      });
    });
  }

  yield put({ type: t.USER_LOGIN_SUCCESS, user });
}

function* setTeam() {
  const teams = yield call(backendApi.get, `/teams`, {
    type: "1,2,3",
  });

  const allTeams = teams.data;
  const activeTeam = teams.data[0];

  if (!activeTeam) {
    throw Error("You are not assigned to a team. Please contact support.");
  }

  const selectedTeamDetailsFromLocalStorage =
    JSON.parse(localStorage.getItem("selectedTeam")) || null;

  let selectedTeam;
  if (selectedTeamDetailsFromLocalStorage) {
    selectedTeam = teams.data.find(
      team => team.id === selectedTeamDetailsFromLocalStorage.id
    );
  }

  if (selectedTeam) {
    yield put(actions.setActiveTeam(selectedTeam));
  } else {
    yield put(actions.setActiveTeam(activeTeam));
  }

  yield put(actions.setTeams(allTeams));
}

function* setToken() {
  const session = yield call([Auth, Auth.currentSession]);
  const jwt = session.idToken.jwtToken;
  backendApi.setHeader("Authorization", jwt);

  yield put(actions.setToken(jwt));
  yield put(actions.setTokenTime(Date.now()));
}

function* handleAuthenticationError(e, reject) {
  let errorMessage = e;
  if (e.message) {
    errorMessage = e.message;
  }

  const error = {
    message: errorMessage,
    contact: "support@biomeme.com",
    contactName: "Support",
  };

  if (error.message.includes("team")) {
    try {
      const attemptedProviderDomain = localStorage.getItem("providerDomain");
      localStorage.removeItem("providerDomain");

      const { ok, data } = yield call(
        backendApi.get,
        `/admin/identities/contact?provider=${attemptedProviderDomain}`
      );

      if (ok && data) {
        error.message = data.message;
        error.contact = data.contactEmail;
      }
    } catch (err) {
      CloudLog.error(`Error retrieving provider contact ${err}`);
    } finally {
      yield put({ type: t.USER_LOGIN_ERR, payload: error });
      yield call(history.push, "/login-error");
    }
  } else if (reject) {
    reject(error.message);
  }

  CloudLog.error(`Error in Authentication Flow: ${e}`);
}

export function* samlAuthorization() {
  try {
    yield put(actions.setSamlProcessing(true));
    yield call(setToken);
    yield call(setTeam);
    yield call(setUserDetails);
    yield call(navigateSignedInUser);
  } catch (e) {
    yield call(handleAuthenticationError, e);
  } finally {
    yield put(actions.setSamlProcessing(false));
  }
}

export function* watchSamlUserLoginReq() {
  yield takeLatest(t.AUTHENTICATE_SAML_USER, samlAuthorization);
}

export function* authorize(action) {
  const {
    payload: { email, password },
    // eslint-disable-next-line
    resolve,
    reject,
  } = action;
  try {
    yield call([Auth, Auth.signIn], email, password);

    yield call(setToken);
    yield call(setTeam);
    yield call(setUserDetails);
    yield call(navigateSignedInUser);
  } catch (e) {
    yield call(handleAuthenticationError, e, reject);
  }
}

export function* watchUserLoginReq() {
  yield takeLatest(t.USER_LOGIN_REQ, authorize);
}

export function* logout() {
  const provider = yield select(getProvider);

  try {
    history.push("/");

    yield put({ type: FEATURE_POST, feature: "logout" });
    yield call([Auth, Auth.signOut]);
    yield put({ type: t.USER_LOGOUT_SUCCESS });
    yield put({ type: hubTypes.HUB_RESET });
    yield put({ type: sidebarTypes.RESET_SIDEBAR });
    yield put({ type: runsPageTypes.CHART_RESET });

    if (provider) {
      history.push("/saml-logout");
    }
  } catch (e) {
    CloudLog.error(`Error in logout: ${e}`);
    yield put({ type: t.USER_LOGOUT_ERROR, payload: e });
  }
}

export function* watchUserLogoutReq() {
  yield takeLatest(t.USER_LOGOUT_REQ, logout);
}

function* handleVerificationRequest(action) {
  try {
    const send = yield call(backendApi.put, "/users/forgot", action.user);
    if (send.status === 200) {
      yield put({
        type: t.REQUEST_VERIFICATION_CODE_SUCCESS,
      });
    } else {
      yield put({
        type: t.REQUEST_VERIFICATION_CODE_ERROR,
        err: send.data.message || send.problem,
      });
    }
  } catch (err) {
    yield put({ type: t.REQUEST_VERIFICATION_CODE_ERROR, err });
  }
}

function* watchVerificationCodeRequest() {
  yield takeLatest(t.REQUEST_VERIFICATION_CODE, handleVerificationRequest);
}

function* handlePasswordResetRequest(action) {
  try {
    const send = yield call(backendApi.put, "/users/confirm", action.user);
    if (send.status === 200) {
      yield put({
        type: t.RESET_PASSWORD_SUCCESS,
      });
    } else {
      yield put({
        type: t.RESET_PASSWORD_ERROR,
        err: send.data.message || send.problem,
      });
    }
  } catch (err) {
    yield put({ type: t.RESET_PASSWORD_ERROR, err });
  }
}

function* watchResetPasswordRequest() {
  yield takeLatest(t.RESET_PASSWORD, handlePasswordResetRequest);
}

function* handleActiveTeamSet() {
  yield put(resetSelectedRuns());
  yield put(resetFolders());
  yield put(resetSidebar());
  yield put(getRootFolders());
}

function* watchActiveTeamSet() {
  yield takeLatest(t.SET_ACTIVE_TEAM_ID, handleActiveTeamSet);
}

function* handleProviderLoginReq(action) {
  const { provider, resolve, reject } = action;

  yield put(actions.setProvider(provider));

  let error = null;
  try {
    const { status } = yield call(
      backendApi.get,
      `admin/identities?provider=${provider}`
    );

    if (status === 401) {
      error = "Invalid organization domain provided";
    } else if (status === 400) {
      error =
        "Too many requests made. Please wait several minutes before retrying.";
    } else if (!status || status === 500) {
      error = "An error occurred processing your request. Try again?";
    }
  } catch (e) {
    error = "An error occurred processing your request. Try again?";
  } finally {
    if (error) {
      reject(error);
    } else {
      resolve();
      localStorage.setItem("providerDomain", provider);
      window.location.href = buildProviderURL(provider);
    }
  }
}

function* watchProviderLoginReq() {
  yield takeLatest(t.USER_PROVIDER_LOGIN_REQ, handleProviderLoginReq);
}

function* handleUpdateUserDetailsSuccess() {
  const user = yield call([Auth, Auth.currentAuthenticatedUser]);

  try {
    yield call(refreshSessionAsync, user);
    yield put({ type: t.USER_REFRESH_SESSION_SUCCESS, user });
  } catch (err) {
    CloudLog.error(`An error occurred refreshing the user session: ${err}`);
  }
}

function* watchUpdateUserDetailsSuccess() {
  yield takeLatest(
    settingsTypes.UPDATE_USER_DETAILS_SUCCESS,
    handleUpdateUserDetailsSuccess
  );
}

export default function* rootSaga() {
  yield all([
    watchUserLoginReq(),
    watchUserLogoutReq(),
    watchSamlUserLoginReq(),
    watchVerificationCodeRequest(),
    watchResetPasswordRequest(),
    watchActiveTeamSet(),
    watchProviderLoginReq(),
    watchUpdateUserDetailsSuccess(),
  ]);
}
