import React, { Fragment } from "react";

const IsothermalProtocolTable = props => {
  const { data } = props;

  let reverseTranscriptionTime = null;
  if (data.rtTime) {
    reverseTranscriptionTime = data.rtTime;
  }

  let rtTempDisplayValue = "No RT";
  if (data.rtTemp) {
    rtTempDisplayValue = `${data.rtTemp} C`;
  }

  let rtTimeDisplayValue = null;
  if (reverseTranscriptionTime) {
    rtTimeDisplayValue = (
      <div style={{ paddingLeft: "5px" }}>{reverseTranscriptionTime} sec</div>
    );
  }

  return (
    <Fragment>
      <tr className="detailed-dropdown__row-darkened">
        <th>Name</th>
        <td>{data.protocolName}</td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>RT</th>
        <td>
          {rtTempDisplayValue}
          {rtTimeDisplayValue}
        </td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Initial Denature</th>
        <td>
          {data.firstDenatureTemp} C
          <div style={{ paddingLeft: "5px" }}>{data.firstDenatureTime} sec</div>
        </td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Cycles</th>
        <td>{data.cycles}</td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Isothermal</th>
        <td>
          {data.isothermalTemp} C
          <div style={{ paddingLeft: "5px" }}>
            {data.isothermalInterval} samples / min
          </div>
        </td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Duration</th>
        <td>{data.duration}</td>
      </tr>
    </Fragment>
  );
};

export default IsothermalProtocolTable;
