export const generateFilename = (runs, prefix) => {
  if (runs.length < 1) {
    return "runs.xlsx";
  }
  const runCount = runs.length;

  let filename = "";
  if (prefix) {
    filename = prefix;
  }

  if (runCount > 3) {
    for (let i = 0; i < 3; i += 1) {
      filename += `${runs[i].name.replace(/[/:*?"<>|]/g, "-")}_`;
    }

    filename = `${filename.slice(0, -1)} +${runCount - 3} more.xlsx`;
  } else {
    runs.forEach(run => {
      filename += `${run.name.replace(/[/:*?"<>|]/g, "-")}_`;
    });

    filename = `${filename.slice(0, -1)}.xlsx`;
  }

  return filename;
};
