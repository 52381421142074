import { mean, uniqBy } from "lodash";

import { round, average, standardDeviation, median } from "../Utils/helpers";
import {
  desiredChannelOrder,
  analysisModes,
  CHANNEL_MASKS,
  BIORAD_WELL_IDENTIFIERS,
  UNSPECIFIED_SAMPLE_TEXT,
  formattedZero,
} from "./constants";
import { runTypes, CHANNELS } from "../Hub/constants";
import { isDetailsVersionTwoOrGreater } from "../FullRunData/helpers";

const xyDataAssembler = (target, lowerBound, upperBound) => {
  const { values, meltTemperatures = null } = target;

  if (meltTemperatures && meltTemperatures.length > 0) {
    return values.map((value, index) => {
      return {
        x: meltTemperatures[index],
        y: value,
      };
    });
  }
  if (lowerBound === -1 && upperBound === -1) {
    return values.map((value, index) => {
      return {
        x: index,
        y: value,
      };
    });
  }
  if (lowerBound === 0 && upperBound !== -1) {
    return values.slice(0, upperBound).map((value, index) => {
      return {
        x: index,
        y: value,
      };
    });
  }

  return values.slice(lowerBound - 1, upperBound).map((value, index) => {
    return {
      x: index + lowerBound - 1,
      y: value,
    };
  });
};

const meltThresholdAssembler = (target, threshold, lowerBound, upperBound) => {
  const { values, meltTemperatures = null } = target;

  if (meltTemperatures && meltTemperatures.length > 0) {
    return meltTemperatures.map(value => {
      return {
        x: value,
        y: threshold,
      };
    });
  }
  if (lowerBound === -1 && upperBound === -1) {
    return values.map((value, index) => {
      return {
        x: index,
        y: threshold,
      };
    });
  }
  if (lowerBound === 0 && upperBound !== -1) {
    return values.slice(0, upperBound).map((value, index) => {
      return {
        x: index,
        y: threshold,
      };
    });
  }

  return values.slice(lowerBound - 1, upperBound).map((value, index) => {
    return {
      x: index + lowerBound - 1,
      y: threshold,
    };
  });
};

export const getChannelFromIndex = (index, type) => {
  let channel = "";
  switch (index) {
    case 0:
      channel = "G";
      break;
    case 1:
      if (type === "two3") {
        channel = "R";
      } else {
        channel = "O";
      }
      break;
    case 2:
      channel = "R";
      break;
    default:
      break;
  }

  return channel;
};

export const buildBaselineChart = (
  targets,
  runBlocks,
  lowerBound,
  upperBound
) => {
  const chartLines = [];

  targets.forEach(target => {
    const runBlockWithTarget = runBlocks.find(block => {
      return block.runId === target.runId;
    });

    chartLines.push({
      data: xyDataAssembler(target, lowerBound, upperBound),
      color: target.color,
      style: "solid",
      id: target.id,
      runName: runBlockWithTarget.name,
      orientation: "horizontal",
      well: target.well,
      sampleId: target.sampleId,
      CQ: target.CQ,
    });

    if (runBlockWithTarget.threshold) {
      chartLines.push({
        data: meltThresholdAssembler(
          target,
          target.threshold,
          lowerBound,
          upperBound
        ),
        color: target.color,
        style: "dashed",
        id: `${target.id}-threshold`,
        runName: runBlockWithTarget.name,
        orientation: "horizontal",
        well: target.well,
        sampleId: target.sampleId,
        CQ: target.CQ,
      });
    }
  });

  return chartLines;
};

export const buildPrintChart = targets => {
  const chartLines = [];

  targets.forEach(target => {
    chartLines.push({
      data: target.values,
      color: target.color,
      style: "solid",
      id: target.id,
    });
  });

  return chartLines;
};

export const buildRawChart = (targets, lowerBound, upperBound) => {
  const chartLines = [];

  targets.forEach(target => {
    chartLines.push({
      data: xyDataAssembler(target, lowerBound, upperBound),
      color: target.color,
      style: "solid",
      id: target.id,
      orientation: "horizontal",
      well: target.well,
      runName: target.runName,
      sampleId: target.sampleId,
      CQ: target.CQ,
    });
  });

  return chartLines;
};

export const buildSingleThresholdChart = (targets, lowerBound, upperBound) => {
  const chartLines = [];

  targets.forEach(target => {
    chartLines.push({
      data: xyDataAssembler(target, lowerBound, upperBound),
      color: target.color,
      style: "solid",
      id: target.id,
      orientation: "horizontal",
      well: target.well,
      runName: target.runName,
      sampleId: target.sampleId,
      CQ: target.CQ,
    });
  });

  const greenThresholdTarget = targets.find(target => {
    return target.channel === "green" && target.showLine;
  });

  if (greenThresholdTarget) {
    const { singleThreshold } = greenThresholdTarget;

    chartLines.push({
      data: meltThresholdAssembler(
        greenThresholdTarget,
        singleThreshold,
        lowerBound,
        upperBound
      ),
      style: "dashed",
      id: "single-threshold-green",
      color: "green",
      orientation: "horizontal",
    });
  }

  const redThresholdTarget = targets.find(target => {
    return target.channel === "red" && target.showLine;
  });

  if (redThresholdTarget) {
    const { singleThreshold } = redThresholdTarget;

    chartLines.push({
      data: meltThresholdAssembler(
        redThresholdTarget,
        singleThreshold,
        lowerBound,
        upperBound
      ),
      style: "dashed",
      id: "single-threshold-red",
      color: "red",
      orientation: "horizontal",
    });
  }

  const amberThresholdTarget = targets.find(target => {
    return target.channel === "amber" && target.showLine;
  });

  if (amberThresholdTarget) {
    const { singleThreshold } = amberThresholdTarget;

    chartLines.push({
      data: meltThresholdAssembler(
        amberThresholdTarget,
        singleThreshold,
        lowerBound,
        upperBound
      ),
      style: "dashed",
      id: "single-threshold-amber",
      color: "amber",
      orientation: "horizontal",
    });
  }

  return chartLines;
};

export const singleThresholdCalculator = state => {
  let newData = [...state];

  let greenThresholds = newData
    .filter(target => {
      return (
        target.channel &&
        typeof target.channel === "string" &&
        target.channel.toLowerCase() === "green" &&
        target.showLine
      );
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.threshold;
    });

  if (greenThresholds.length === 0) {
    greenThresholds = newData
      .filter(target => {
        return target.channel.toLowerCase() === "green" && target.showLine;
      })
      .map(target => {
        return target.threshold;
      });
  }

  let amberThresholds = newData
    .filter(target => {
      return (
        target.channel &&
        typeof target.channel === "string" &&
        target.channel.toLowerCase() === "amber" &&
        target.showLine
      );
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.threshold;
    });

  if (amberThresholds.length === 0) {
    amberThresholds = newData
      .filter(target => {
        return target.channel.toLowerCase() === "amber" && target.showLine;
      })
      .map(target => {
        return target.threshold;
      });
  }

  let redThresholds = newData
    .filter(target => {
      return (
        target.channel &&
        typeof target.channel === "string" &&
        target.channel.toLowerCase() === "red" &&
        target.showLine
      );
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.threshold;
    });

  if (redThresholds.length === 0) {
    redThresholds = newData
      .filter(target => {
        return target.channel.toLowerCase() === "red" && target.showLine;
      })
      .map(target => {
        return target.threshold;
      });
  }

  const greenSingleThreshold = round(average(greenThresholds), 2);
  const amberSingleThreshold = round(average(amberThresholds), 2);
  const redSingleThreshold = round(average(redThresholds), 2);

  newData = [...state].map(target => {
    let singleThreshold = 0;

    if (target.channel === "green") {
      singleThreshold = greenSingleThreshold;
    } else if (target.channel === "amber") {
      singleThreshold = amberSingleThreshold;
    } else if (target.channel === "red") {
      singleThreshold = redSingleThreshold;
    }

    return {
      ...target,
      singleThreshold,
      values: [...target.baselineValues],
    };
  });

  return newData;
};

export const calculateBackgroundValues = runs => {
  const runLengths = [];
  const leftMins = [];
  const rightMins = [];

  runs.forEach(run => {
    const runLeftVals = [];
    const runRightVals = [];

    const runLength = Math.max(
      ...run.targets.map(target => {
        return target.rawData.length;
      })
    );

    if (runLength > 0) {
      runLengths.push(runLength);

      run.targets.forEach(target => {
        runLeftVals.push(target.leftVal);
        runRightVals.push(target.rightVal);
      });

      leftMins.push(Math.min(...runLeftVals));
      rightMins.push(Math.min(...runRightVals));
    }
  });

  const leftVal = Math.ceil(mean(leftMins));
  let rightVal = Math.ceil(mean(rightMins));

  const shortestRunLength = Math.min(...runLengths);
  if (rightVal > shortestRunLength) {
    rightVal = shortestRunLength;
  }

  return {
    leftVal,
    rightVal,
    originalLeftVal: leftVal,
    originalRightVal: rightVal,
  };
};

export const extractSampleNames = (run, version) => {
  if (isDetailsVersionTwoOrGreater(version)) {
    const targets = [...run.targets].sort((x, y) => {
      return (
        desiredChannelOrder.indexOf(x.emissionColor) -
        desiredChannelOrder.indexOf(y.emissionColor)
      );
    });

    const filteredTargets = uniqBy(targets, "wellNumber").sort((x, y) => {
      return x.wellNumber - y.wellNumber;
    });

    return filteredTargets.map(target => {
      const { sampleId, shortTargetName, sampleRecordId, wellNumber } = target;

      let sampleName = UNSPECIFIED_SAMPLE_TEXT;

      if (sampleId) {
        sampleName = sampleId;
      } else if (shortTargetName) {
        sampleName = shortTargetName;
      }

      if (sampleRecordId) {
        return {
          wellNumber,
          sampleId: sampleName,
          sampleRecordId,
        };
      }

      return { wellNumber, sampleId: sampleName };
    });
  }

  const { details = {} } = run;
  const { wellData = [] } = details;

  const wells = [...wellData].sort((x, y) => {
    return x.wellNumber - y.wellNumber;
  });

  return wells.map(well => {
    if (well.sampleName) {
      return { sampleId: well.sampleName };
    }

    return { wellNumber: well.wellNum, sampleId: UNSPECIFIED_SAMPLE_TEXT };
  });
};

const intervalToSamplesPerMin = interval => {
  switch (interval) {
    case 30 * 1000:
      return 2;
    case 20 * 1000:
      return 3;
    case 15 * 1000:
      return 4;
    default:
      return 2;
  }
};

export const assembleDetailedData = (run = {}, protocol = {}, version) => {
  const { versions = {} } = run;
  const detailedData = {};

  detailedData.locationString = run.locationString;
  detailedData.duration = run.duration;
  detailedData.rtTemp = protocol.rtTemp;

  let newRtTime = null;
  if (protocol.rtTime) {
    newRtTime = protocol.rtTime;
  }

  detailedData.rtTime = newRtTime;

  if (isDetailsVersionTwoOrGreater(version)) {
    if (run.result && run.result.overallResult) {
      detailedData.resultString = run.result.overallResult.result;
    } else {
      detailedData.resultString = "";
    }

    const { versions = {} } = run;
    const detectorFw = versions.detectorFw || "";

    detailedData.latitude = run.latitude;
    detailedData.longitude = run.longitude;
    detailedData.serialNumber = versions.serialNumber;
    detailedData.bleName = run.versions.bleName;
    detailedData.firmwareVersion = detectorFw.replace("detector-", "v");
    detailedData.appVersion = versions.appVersion;
    detailedData.protocolName = protocol.name;
    detailedData.firstDenatureTemp = protocol.firstDenatureTemp;
    detailedData.firstDenatureTime = protocol.firstDenatureTime;
    detailedData.cycles = protocol.cycles;
    detailedData.cycleDenatureTemp = protocol.denature;
    detailedData.cycleDenatureTime = protocol.denatureTime;
    detailedData.cycleAnnealingTemp = protocol.anneal;
    detailedData.cycleAnnealingTime = protocol.annealTime;
    detailedData.cycleExtensionTemp = protocol.extensionTemp;

    let newCycleExtensionTime = null;
    if (protocol.extensionTime) {
      newCycleExtensionTime = protocol.extensionTime;
    }

    detailedData.cycleExtensionTime = newCycleExtensionTime;

    if (run.runType === runTypes.isothermal) {
      let newIsothermalInterval = null;
      if (protocol.isothermalInterval) {
        newIsothermalInterval = intervalToSamplesPerMin(
          protocol.isothermalInterval
        );
      }

      detailedData.isothermalTemp = protocol.isothermalTemp || null;
      detailedData.isothermalInterval = newIsothermalInterval;
    }

    if (run.runType === runTypes.melt) {
      detailedData.meltDuration = protocol.meltDuration;
      detailedData.meltIncrement = protocol.meltIncrement;
      detailedData.meltTempFinal = protocol.meltTempFinal;
      detailedData.meltTempStart = protocol.meltTempStart;

      const { targets = [] } = run;
      const [firstTarget = {}] = targets;

      let newSteps = null;
      if (firstTarget.meltTemperatures) {
        newSteps = firstTarget.meltTemperatures.length;
      }

      detailedData.steps = newSteps;
    }
  } else {
    const { gpsCoordinates = {}, systemInfo = {} } = run;

    detailedData.resultString = run.resultString;
    detailedData.latitude = gpsCoordinates.Latitude;
    detailedData.longitude = gpsCoordinates.Longitude;
    detailedData.serialNumber = systemInfo.serialNumber;
    detailedData.firmwareVersion = systemInfo.firmwareVersion;
    detailedData.appVersion = systemInfo.appVersion;
    detailedData.protocolName = protocol.name;
    detailedData.firstDenatureTemp = protocol.firstDenatureTemp;
    detailedData.firstDenatureTime = protocol.firstDenatureTime;
    detailedData.cycles = protocol.numberOfCycles;
    detailedData.cycleDenatureTemp = protocol.cycleDenatureTemp;
    detailedData.cycleDenatureTime = protocol.cycleDenatureTime;
    detailedData.cycleAnnealingTemp = protocol.cycleAnnealingTemp;
    detailedData.cycleAnnealingTime = protocol.cycleAnnealingTime;
    detailedData.cycleExtensionTemp = protocol.cycleExtensionTemp;
    detailedData.cycleExtensionTime = protocol.cycleExtensionTime;
    detailedData.bleName = "N/A";
  }

  return detailedData;
};

export const metricsRounder = number => {
  if (number) {
    return number.toFixed(2);
  }

  return formattedZero;
};

export const hasMultipleCrossings = target => {
  const { baselineValues, threshold } = target;

  let leftVal = 0;
  if (target.leftVal) {
    const { leftVal: targetLeftVal } = target;

    leftVal = targetLeftVal;
  }
  const baselineValuesAfterLeftVal = baselineValues.slice(
    Math.max(0, leftVal - 1)
  );

  let positiveCrossings = 0;
  let negativeCrossings = 0;
  const dataCount = baselineValuesAfterLeftVal.length;

  for (let i = 0; i < dataCount; i += 1) {
    if (
      baselineValuesAfterLeftVal[i] < threshold &&
      baselineValuesAfterLeftVal[i + 1] >= threshold
    ) {
      positiveCrossings += 1;
    } else if (
      baselineValuesAfterLeftVal[i] > threshold &&
      baselineValuesAfterLeftVal[i + 1 <= threshold] &&
      positiveCrossings > 0
    ) {
      negativeCrossings += 1;
    }
  }

  if (positiveCrossings > 1 || negativeCrossings > 0) {
    return true;
  }

  return false;
};

const linesPresentMinimum = colorTarget => {
  if (colorTarget.length > 0) {
    return metricsRounder(Math.min(...colorTarget));
  }

  return formattedZero;
};
const linesPresentMaximum = colorTarget => {
  if (colorTarget.length > 0) {
    return metricsRounder(Math.max(...colorTarget));
  }

  return formattedZero;
};

export const calculateMetrics = data => {
  const greenCqs = data
    .filter(target => {
      return target.channel === "green" && target.showLine;
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.CQ;
    });
  const redCqs = data
    .filter(target => {
      return target.channel === "red" && target.showLine;
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.CQ;
    });
  const amberCqs = data
    .filter(target => {
      return target.channel === "amber" && target.showLine;
    })
    .filter(target => {
      return target.CQ > 0;
    })
    .map(target => {
      return target.CQ;
    });

  const metrics = {
    green: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "green";
        })
      ),
      min: linesPresentMinimum(greenCqs),
      max: linesPresentMaximum(greenCqs),
      average: metricsRounder(average(greenCqs)),
      standardDeviation: metricsRounder(standardDeviation(greenCqs)),
      median: metricsRounder(median(greenCqs)),
    },
    red: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "red";
        })
      ),
      min: linesPresentMinimum(redCqs),
      max: linesPresentMaximum(redCqs),
      average: metricsRounder(average(redCqs)),
      standardDeviation: metricsRounder(standardDeviation(redCqs)),
      median: metricsRounder(median(redCqs)),
    },
    amber: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "amber";
        })
      ),
      min: linesPresentMinimum(amberCqs),
      max: linesPresentMaximum(amberCqs),
      average: metricsRounder(average(amberCqs)),
      standardDeviation: metricsRounder(standardDeviation(amberCqs)),
      median: metricsRounder(median(amberCqs)),
    },
  };

  return metrics;
};

export const calculateEndRfuMetrics = data => {
  const greenEndRfu = data
    .filter(target => {
      return target.channel === "green" && target.showLine;
    })
    .filter(target => {
      return target.endRfu > 0;
    })
    .map(target => {
      return target.endRfu;
    });
  const redEndRfu = data
    .filter(target => {
      return target.channel === "red" && target.showLine;
    })
    .filter(target => {
      return target.endRfu > 0;
    })
    .map(target => {
      return target.endRfu;
    });
  const amberEndRfu = data
    .filter(target => {
      return target.channel === "amber" && target.showLine;
    })
    .filter(target => {
      return target.endRfu > 0;
    })
    .map(target => {
      return target.endRfu;
    });

  const endRfuMetrics = {
    green: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "green";
        })
      ),
      min: linesPresentMinimum(greenEndRfu),
      max: linesPresentMaximum(greenEndRfu),
      average: metricsRounder(average(greenEndRfu)),
      standardDeviation: metricsRounder(standardDeviation(greenEndRfu)),
      median: metricsRounder(median(greenEndRfu)),
    },
    red: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "red";
        })
      ),
      min: linesPresentMinimum(redEndRfu),
      max: linesPresentMaximum(redEndRfu),
      average: metricsRounder(average(redEndRfu)),
      standardDeviation: metricsRounder(standardDeviation(redEndRfu)),
      median: metricsRounder(median(redEndRfu)),
    },
    amber: {
      enabled: Boolean(
        data.find(d => {
          return d.channel === "amber";
        })
      ),
      min: linesPresentMinimum(amberEndRfu),
      max: linesPresentMaximum(amberEndRfu),
      average: metricsRounder(average(amberEndRfu)),
      standardDeviation: metricsRounder(standardDeviation(amberEndRfu)),
      median: metricsRounder(median(amberEndRfu)),
    },
  };

  return endRfuMetrics;
};

export const formatAnalysisModeForPostFeature = mode => {
  switch (mode) {
    case analysisModes.baseline: {
      return "multi threshold chart";
    }
    case analysisModes.singleThreshold: {
      return "single threshold chart";
    }
    case analysisModes.reprocessing: {
      return "reprocessing chart";
    }
    case analysisModes.review: {
      return "review chart";
    }
    default:
      return null;
  }
};

const generateGraphOverlaySteps = () => {
  return [
    {
      element: ".runs_page__graph_overlay",
      intro:
        "Welcome to the Biomeme Cloud tutorial! These tips will help you familiarize yourself with the Cloud's features and help make your experience seamless.",
    },
    {
      element: ".runs_page__graph_overlay",
      intro: `Navigate through the tutorial using the back and next buttons below.

      The exit button will close the tutorial, but you can access it later using the question mark icon in the top right of the page.`,
    },
    {
      element: ".runs_page__graph_overlay",
      intro: `Note: Values on the x-Axis previously started at 0 (e.g. 0 to 44) but now start at 1 to align with the format of other biological tools.`,
    },
  ];
};

const generateRunBlocksSteps = (
  analysisMode,
  chartType,
  runTypeIsMelt,
  runBlocks
) => {
  const steps = [
    {
      element: ".runblocks_block",
      intro:
        "Here you can manage specific runs. You can use the checkbox next to a run name to activate or deactivate that run entirely, or use the grid for more granular control.",
    },
    {
      element: ".detailed_data_runblock",
      intro: "'Detailed Data' will display additional details about a run.",
    },
  ];

  if (!analysisMode.review) {
    if (chartType["single-threshold"]) {
      steps.unshift({
        element: ".thresholds_block",
        intro:
          "This section displays, and allows you to alter, single thresholds. These values are calculated and apply across all runs.",
      });
    }

    if (analysisMode.baseline) {
      steps.push({
        element: ".toggle_threshold_runblock",
        intro:
          "Clicking here allows you to toggle whether a run's threshold lines are displayed on the chart.",
      });
    }

    steps.unshift({
      element: ".metrics_block",
      intro:
        "Cq metrics are calculated using all lines actively displayed on the chart. Note: Zero Cqs were previously included in these calculations but are now excluded.",
    });

    if (runBlocks.length > 1) {
      steps.unshift({
        element: ".master_controls",
        intro:
          "You can use this section to apply changes to all of your runs simultaneously.",
      });
    }
  }

  if (runTypeIsMelt) {
    steps.push({
      element: ".print_mode_runblock",
      intro:
        "'Print Run' will open a printer-friendly view of your run in its original state.",
    });
  }

  return steps;
};

const generateGraphButtonsSteps = (
  runIsExternalUpload,
  reviewAnalysisModeIsActive
) => {
  const steps = [
    {
      element: ".graph__buttons",
      intro: "These buttons can be used to manage your chart.",
    },
    {
      element: ".analysis_section",
      intro:
        "Your current analysis mode is displayed here. You can change it by clicking on the arrow to the right. Please note that this will cause the chart to reload and any changes you've made will be lost.",
    },
    {
      element: ".baseline_mode",
      intro:
        "Baseline is the default view and populates the chart with processed data.",
    },
  ];

  if (!runIsExternalUpload) {
    steps.push({
      element: ".raw_mode",
      intro: "Raw data view populates the chart with unprocessed data.",
    });
  }

  if (!reviewAnalysisModeIsActive) {
    steps.push({
      element: ".tabular_mode",
      intro:
        "Use this mode to view your data in a tabular format. When the 'multi threshold' analysis mode is enabled, you can also adjust line thresholds here.",
    });
  }

  return steps;
};

const generateRunsPageButtons = () => {
  const steps = [
    {
      element: ".detailed_data_button",
      intro: "Click here to view additional information about your run(s).",
    },
    {
      element: ".download_button",
      intro:
        "Click here to expand a menu with several download options. 'Download Data' will allow you to export your data as an Excel sheet, with options for detailed and summary data. 'Download Chart' will download an image of your chart as it currently appears on the screen.",
    },
  ];

  return steps;
};

const generateReprocessingSteps = isReprocessing => {
  const reprocessingSteps = [
    {
      element: ".analysis_section",
      intro:
        "Reprocessing mode allows you to manually select background indices and re-baseline the data.",
    },
    {
      element: ".reprocessing-slider",
      intro:
        "You can select your background indices for baselining by sliding these values.",
    },
    {
      element: ".Accept",
      intro:
        "Apply the new background indices by clicking Accept. Your data will be re-processed, leading to new Cq values.",
    },
    {
      element: ".Reset",
      intro: "Reset all curves to original reprocessed data.",
    },
  ];

  let steps = [];
  if (isReprocessing) {
    steps = reprocessingSteps;
  }

  return steps;
};

const generateNavigationSteps = analysisMode => {
  const steps = [
    {
      element: ".back",
      intro: "Click here to exit the chart and return to the home screen.",
    },
  ];

  if (!analysisMode.review) {
    steps.push({
      element: ".folder_back",
      intro:
        "Clicking here will return you to the run selection screen for this folder.",
    });
  }

  return steps;
};

export const organizeTutorialSteps = (
  runTypeIsMelt,
  chartType,
  runBlocks,
  analysisMode,
  runIsExternalUpload,
  reviewAnalysisModeIsActive
) => {
  const graphOverlaySteps = generateGraphOverlaySteps();
  const runBlocksSteps = generateRunBlocksSteps(
    analysisMode,
    chartType,
    runTypeIsMelt,
    runBlocks
  );
  const graphButtonsSteps = generateGraphButtonsSteps(
    runIsExternalUpload,
    reviewAnalysisModeIsActive
  );
  const runsPageButtonsSteps = generateRunsPageButtons();
  const reprocessingSteps = generateReprocessingSteps(
    analysisMode[analysisModes.reprocessing]
  );
  const navigationSteps = generateNavigationSteps(analysisMode);

  const steps = [
    ...graphOverlaySteps,
    ...runBlocksSteps,
    ...graphButtonsSteps,
    ...runsPageButtonsSteps,
    ...reprocessingSteps,
    ...navigationSteps,
    {
      element: "runs_page__container",
      intro:
        "Congratulations! You've completed the tutorial. If you ever find yourself unsure about what something does, hover your mouse over it to reveal a hint.",
    },
  ];

  return steps;
};

export const checkActiveChannels = colorMask => {
  const channelActiveStatus = {
    green: false,
    amber: false,
    red: false,
  };

  if ((colorMask & CHANNEL_MASKS.GREEN) !== 0) {
    channelActiveStatus.green = true;
  }

  if ((colorMask & CHANNEL_MASKS.AMBER) !== 0) {
    channelActiveStatus.amber = true;
  }

  if ((colorMask & CHANNEL_MASKS.RED) !== 0) {
    channelActiveStatus.red = true;
  }

  return channelActiveStatus;
};

export const generateAssociatedWellsBioRad = (well, primaryWellIdentifier) => {
  const { numbers, letters } = BIORAD_WELL_IDENTIFIERS;

  if (primaryWellIdentifier === "wellNumbers") {
    return letters.map(wellLetter => {
      return `${wellLetter}${well}`;
    });
  }

  return numbers.map(wellNumber => {
    return `${well}${wellNumber}`;
  });
};

export const filterChannels = (targetMatrix, colorFilter) => {
  let filteredTargetMatrix = targetMatrix;

  if (colorFilter.all === false) {
    filteredTargetMatrix = targetMatrix;

    CHANNELS.values.forEach(color => {
      if (colorFilter[color] === false) {
        filteredTargetMatrix[color] = filteredTargetMatrix[color].map(
          target => {
            return { ...target, active: false };
          }
        );
      }
    });
  }

  return filteredTargetMatrix;
};

export const reformTargetMatrix = (targetMatrix, colorFilter) => {
  let newTargetMatrix = {};
  CHANNELS.values.forEach(channel => {
    newTargetMatrix[channel] = [...targetMatrix];
  });

  if (colorFilter) {
    newTargetMatrix = filterChannels(newTargetMatrix, colorFilter);
  }

  return newTargetMatrix;
};

export const parseClassName = (className, additions, runIndex) => {
  let newClassName = className;

  if (runIndex === 0) {
    additions.forEach(addition => {
      newClassName += ` ${addition}`;
    });
  }

  return newClassName;
};

export const checkIfWellContainsLetters = well => {
  return /[a-zA-Z]+/.test(well);
};

export const calculateBackgroundCoexcitation = (currentRun, currentTarget) => {
  const { coexcitation } = currentTarget;

  let percentage = null;
  let referenceData = null;

  if (coexcitation) {
    const { level = null, sourceExcitationColor = null } = coexcitation;

    percentage = level;

    const coexcitationSourceTarget = currentRun.targets.find(target => {
      return (
        target.wellNumber === currentTarget.wellNumber &&
        target.excitationColor === sourceExcitationColor
      );
    });

    referenceData = null;
    if (coexcitationSourceTarget) {
      referenceData = coexcitationSourceTarget.rawData;
    }
  }

  return { percentage, referenceData };
};

export const calculateBackgroundReprocessThreshold = associatedChartTarget => {
  let reprocessThreshold = 0;
  const { singleThreshold } = associatedChartTarget;

  if (singleThreshold) {
    reprocessThreshold = singleThreshold;
  }

  return reprocessThreshold;
};

export const sortTabularDataByWell = tabularData => {
  return tabularData.sort((a, b) => {
    if (a.well.toUpperCase() < b.well.toUpperCase()) {
      return -1;
    }
    if (a.well.toUpperCase() > b.well.toUpperCase()) {
      return 1;
    }

    return 0;
  });
};

export const filterTabularDataByRunName = (a, b) => {
  if (a.run.toUpperCase() < b.run.toUpperCase()) {
    return -1;
  }
  if (a.run.toUpperCase() > b.run.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataByChannelAndWell = (a, b) => {
  if (a.well.toUpperCase() < b.well.toUpperCase()) {
    return -1;
  }
  if (a.well.toUpperCase() > b.well.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataBySampleId = (a, b) => {
  if (a.sampleId.toUpperCase() < b.sampleId.toUpperCase()) {
    return -1;
  }
  if (a.sampleId.toUpperCase() > b.sampleId.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataByTarget = (a, b) => {
  if (a.target.toUpperCase() < b.target.toUpperCase()) {
    return -1;
  }
  if (a.target.toUpperCase() > b.target.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataBySampleType = (a, b) => {
  if (a.sampleType.toUpperCase() < b.sampleType.toUpperCase()) {
    return -1;
  }
  if (a.sampleType.toUpperCase() > b.sampleType.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataBySampleAmount = (a, b) => {
  if (a.sampleAmount.toUpperCase() < b.sampleAmount.toUpperCase()) {
    return -1;
  }
  if (a.sampleAmount.toUpperCase() > b.sampleAmount.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const filterTabularDataDefault = (a, b) => {
  if (a.run.toUpperCase() < b.run.toUpperCase()) {
    return -1;
  }
  if (a.run.toUpperCase() > b.run.toUpperCase()) {
    return 1;
  }

  return 0;
};

export const sortTabularData = (wellSortedTabularData, filter) => {
  return wellSortedTabularData.sort((a, b) => {
    switch (filter) {
      case "RUN NAME": {
        return filterTabularDataByRunName(a, b);
      }
      case "CHANNEL & WELL": {
        return filterTabularDataByChannelAndWell(a, b);
      }
      case "Cq": {
        return a.CQ - b.CQ;
      }
      case "END RFU": {
        return a.endRfu - b.endRfu;
      }
      case "SAMPLE ID": {
        return filterTabularDataBySampleId(a, b);
      }
      case "TARGET": {
        return filterTabularDataByTarget(a, b);
      }
      case "THRESHOLD": {
        return a.threshold - b.threshold;
      }
      case "SQ": {
        return a.SQ - b.SQ;
      }
      case "LEFT VALUE": {
        return a.leftVal - b.leftVal;
      }
      case "RIGHT VALUE": {
        return a.rightVal - b.rightVal;
      }
      case "SAMPLE TYPE": {
        return filterTabularDataBySampleType(a, b);
      }
      case "SAMPLE AMOUNT": {
        return filterTabularDataBySampleAmount(a, b);
      }
      default: {
        return filterTabularDataDefault(a, b);
      }
    }
  });
};

export const computeBiomeme1TargetNameAndSampleId = (
  fullRunData,
  target,
  channel
) => {
  let sampleId = "";
  let targetName = "";

  const associatedRun =
    fullRunData.find(run => {
      return run.id === target.runId;
    }) || {};
  const { details = {} } = associatedRun;
  const { wellData = [] } = details;

  const { well } = target;
  if (wellData[well]) {
    const { sampleName } = wellData[well];
    sampleId = sampleName;
  }

  const { protocol } = associatedRun;
  const { details: protocolDetails = {} } = protocol;
  const { protocolWellData = {} } = protocolDetails;
  const { wellData: protocolWellArray = [] } = protocolWellData;
  const protocolWell = protocolWellArray[target.well] || {};
  const protocolTargets = protocolWell.targets || [];

  const associatedTarget = protocolTargets.find(protocolTarget => {
    return protocolTarget.fluorophoreName === channel;
  });

  if (associatedTarget) {
    const { shortTargetName } = associatedTarget;
    targetName = shortTargetName;
  }

  return [targetName, sampleId];
};

export const computeSQForTabularData = target => {
  let SQ = null;
  const { SQ: targetSQ } = target;

  if (typeof target.SQ === "number") {
    SQ = target.SQ.toFixed(2);
  } else if (typeof target.SQ === "string") {
    SQ = targetSQ;
  } else {
    SQ = null;
  }

  return SQ;
};

export const computeTabularData = (
  activeTargets,
  analysisModeSettings,
  version,
  fullRunData,
  activeRunBlocks
) => {
  const result = activeTargets.map(target => {
    const {
      singleThreshold: analysisSingleThreshold,
      reprocessing: analysisReprocessing,
    } = analysisModes;
    const {
      singleThreshold: targetSingleThreshold,
      threshold: targetThreshold,
    } = target;

    let threshold = targetThreshold;
    if (
      analysisModeSettings[analysisSingleThreshold] ||
      analysisModeSettings[analysisReprocessing]
    ) {
      threshold = targetSingleThreshold.toFixed(6);
    }

    const channel =
      target.channel.charAt(0).toUpperCase() + target.channel.slice(1);

    let SQ = computeSQForTabularData(target);

    let targetName = "N/A";
    let sampleId = "";

    if (version === "biomeme-1") {
      [targetName, sampleId] = computeBiomeme1TargetNameAndSampleId(
        fullRunData,
        target,
        channel
      );
    } else {
      if (target.sampleId) {
        const { sampleId: targetSampleId } = target;
        sampleId = targetSampleId;
      }

      const { targetName: targetTargetName } = target;

      targetName = targetTargetName;
    }

    let parsedWell;

    const wellContainsLetters = checkIfWellContainsLetters(target.well);

    if (wellContainsLetters) {
      parsedWell = `${channel} ${target.well}`;
    } else {
      parsedWell = `${channel} ${target.well + 1}`;
    }

    const {
      runId,
      CQ: targetCQ,
      sourceStartingQuantity,
      leftVal: targetLeftVal,
      rightVal: targetRightVal,
      sampleType: targetSampleType,
      sampleAmount: targetSampleAmount,
      sampleUnits: targetSampleUnits,
      endRfu: targetEndRfu,
    } = target;

    let CQ = null;
    if (targetCQ) {
      CQ = targetCQ.toFixed(2);
    }

    if (sourceStartingQuantity) {
      SQ = sourceStartingQuantity;
    }

    let leftVal = null;
    if (targetLeftVal || targetLeftVal === 0) {
      leftVal = targetLeftVal;
    }

    let rightVal = null;
    if (targetRightVal || targetRightVal === 0) {
      rightVal = targetRightVal;
    }

    let sampleType = null;
    if (targetSampleType) {
      sampleType = targetSampleType;
    }

    let sampleAmount = null;
    if (targetSampleAmount) {
      sampleAmount = `${targetSampleAmount + targetSampleUnits}`;
    }

    let endRfu = null;
    if (targetEndRfu) {
      endRfu = targetEndRfu.toFixed(2);
    }

    return {
      run: activeRunBlocks[runId],
      CQ,
      threshold,
      channel: target.channel,
      well: parsedWell,
      target: targetName,
      targetId: target.id,
      SQ,
      sampleId,
      leftVal,
      rightVal,
      sampleType,
      sampleAmount,
      endRfu,
    };
  });

  return result;
};
