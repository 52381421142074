import { SupportLog, SupportLogVersions } from "js-common";

import {
  BugReportFormState,
  BugReportErrorState,
  UserMessageBundle,
} from "./types";
import {
  BROWSER_USED_ERROR,
  OPERATING_SYSTEM_ERROR,
  PROBLEM_DESCRIPTION_ERROR,
} from "./constants";

export const validateBugReportForm = (
  form: BugReportFormState
): BugReportErrorState => {
  let errors: BugReportErrorState = {
    problemDescriptionError: "",
    operatingSystemError: "",
    browserUsedError: "",
  };

  const { problemDescription, operatingSystem, browserUsed } = form;

  if (!problemDescription) {
    errors = {
      ...errors,
      problemDescriptionError: PROBLEM_DESCRIPTION_ERROR,
    };
  }

  if (!operatingSystem) {
    errors = {
      ...errors,
      operatingSystemError: OPERATING_SYSTEM_ERROR,
    };
  }

  if (!browserUsed) {
    errors = {
      ...errors,
      browserUsedError: BROWSER_USED_ERROR,
    };
  }

  return errors;
};

const formatUserMessage = (userMessageBundle: UserMessageBundle): string => {
  const { problemDescription, expectedResult, stepsToReproduce, blocking } =
    userMessageBundle;

  let message = "";
  if (problemDescription) {
    message += `Problem Description: ${problemDescription}\n`;
  }

  if (expectedResult) {
    message += `\tExpected Result: ${expectedResult}\n`;
  }

  if (stepsToReproduce) {
    message += `\tSteps to Reproduce: ${stepsToReproduce}\n`;
  }

  if (blocking) {
    message += `\tBlocker Description: ${blocking}\n`;
  }

  return message;
};

export const formatBugReport = (
  form: BugReportFormState,
  userId: string,
  appVersion: string,
  firstName: string,
  lastName: string,
  emailAddress: string
): SupportLog => {
  const {
    blocking,
    browserUsed,
    operatingSystem,
    stepsToReproduce,
    problemDescription,
    expectedResult,
  } = form;

  const BLOCKER_TEXT_YES = "Yes, I can't move on until this is fixed";
  let isBlocking = false;
  if (blocking === BLOCKER_TEXT_YES) {
    isBlocking = true;
  }

  const systemInfo = `${operatingSystem} ${browserUsed}`;

  const versions: SupportLogVersions = {
    appVersion,
    platform: systemInfo,
    device: null,
    phoneImei: "N/A",
  };

  const userMessageBundle: UserMessageBundle = {
    problemDescription,
    expectedResult,
    stepsToReproduce,
    blocking,
  };

  const bugReport: SupportLog = {
    userId,
    versions,
    name: `${firstName} ${lastName}`,
    emailAddress,
    blocking: isBlocking,
    category: "Portal",
    success: true,
    userMessage: formatUserMessage(userMessageBundle),
    reportType: "portal",
  };

  return bugReport;
};
