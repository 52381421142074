export async function refreshSessionAsync(user) {
  return new Promise((resolve, reject) => {
    user.refreshSession(user.signInUserSession.refreshToken, err => {
      if (err) {
        reject(err);
      }

      resolve();
    });
  });
}
