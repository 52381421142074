import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import BulkRunBlockEntry from "../BulkRunBlockEntry";
import {
  getBulkFailedRunIds,
  getBulkReviewRunBlocks,
} from "../../../../../../RunReview/selectors";
import { BulkRunBlock } from "../../../../../types";
import * as styles from "./styles";
import {
  activateBulkRunBlock,
  deactivateBulkRunBlock,
} from "../../../../../../RunReview/actions";

interface StateProps {
  runBlocks: BulkRunBlock[];
  failedRunIds: string[];
}

interface DispatchProps {
  toggleRunBlock: (active: boolean, runId: string) => void;
}

type Props = StateProps & DispatchProps;

export const BulkRunBlocks: React.FunctionComponent<Props> = (props: Props) => {
  const { runBlocks, failedRunIds, toggleRunBlock } = props;

  const renderRunBlock = () => {
    return runBlocks.map((runBlock: BulkRunBlock, index: number) => {
      const { runId } = runBlock;

      let className = "";
      if (index === 0) {
        className = "bulk_runblocks_block";
      }

      return (
        <div
          key={runId}
          className={className}
          style={styles.bulkRunBlocksBlock}
        >
          <BulkRunBlockEntry
            runBlock={runBlock}
            key={`bulkRunTable-${runId}`}
            toggleRunBlock={toggleRunBlock}
            failed={failedRunIds.includes(runId)}
          />
        </div>
      );
    });
  };

  return <>{renderRunBlock()}</>;
};

function mapStateToProps(state: any) {
  return {
    runBlocks: getBulkReviewRunBlocks(state),
    failedRunIds: getBulkFailedRunIds(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleRunBlock: (active: boolean, runId: string) => {
      if (active) {
        dispatch(deactivateBulkRunBlock(runId));
      } else {
        dispatch(activateBulkRunBlock(runId));
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkRunBlocks);
