import React from "react";

const EditIconButton = props => (
  <svg width={14} height={14} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-337-332h800V70h-800z" />
      <rect
        x={1}
        y={1}
        width={178}
        height={38}
        rx={10}
        transform="translate(-27 -13)"
        stroke="#012639"
        strokeWidth={2}
      />
      <path
        d="M10.582 4.998l-6.208 6.24-2.452-2.465 6.207-6.24 2.453 2.465zm2.518-3.58l-1.094-1.1a1.082 1.082 0 00-1.533 0L9.425 1.372l2.453 2.465L13.1 2.608a.843.843 0 000-1.19zM.344 13.05l2.733-.666L.627 9.92l-.62 2.797a.28.28 0 00.337.334z"
        className="sample-hub__invertable-button"
      />
    </g>
  </svg>
);

export default EditIconButton;
