import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateSingleThresholdRequest as actionUpdateSingleThresholds,
  updateCQ,
} from "../../../actions";
import { getSingleThresholds } from "../../../selectors";
import { round } from "../../../../Utils/helpers";

class ThresholdPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      green: null,
      amber: null,
      red: null,
      error: {
        showError: false,
        message: "",
      },
    };
  }

  componentDidMount() {
    const { singleThresholds } = this.props;

    const mockState = {
      ...this.state,
      green: null,
      amber: null,
      red: null,
    };
    singleThresholds.forEach(threshold => {
      const { channel } = threshold;
      mockState[channel] = round(threshold.threshold, 2);
    });

    this.setState({
      ...mockState,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { singleThresholds: newThresholds } = newProps;

    newThresholds.forEach(threshold => {
      this.updateLocalThresholdValues(
        threshold.channel,
        round(threshold.threshold, 2)
      );
    });
  }

  updateLocalThresholdValues(channel, value) {
    if (channel === "green") {
      this.setState({ green: value });
    } else if (channel === "amber") {
      this.setState({ amber: value });
    } else if (channel === "red") {
      this.setState({ red: value });
    }
  }

  applySingleThresholds() {
    const { green, amber, red } = this.state;
    const { updateSingleThresholds } = this.props;

    const invalidThresholds = [];
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(green)) {
      invalidThresholds.push("Green");
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(amber)) {
      invalidThresholds.push("Amber");
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(red)) {
      invalidThresholds.push("Red");
    }

    if (invalidThresholds.length === 0) {
      updateSingleThresholds({
        green,
        amber,
        red,
      });
      this.setState({
        ...this.state,
        error: {
          showError: false,
          message: "",
        },
      });
    } else {
      this.setState(
        {
          ...this.state,
          error: {
            showError: true,
            message: `Invalid value for ${invalidThresholds.join(
              ", "
            )} threshold(s)`,
          },
          shake: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ shake: false });
          }, 401);
        }
      );
    }
  }

  renderAvailableThresholds() {
    const { singleThresholds } = this.props;

    return singleThresholds.map(threshold => {
      const { channel } = threshold;
      let value = null;
      const { [channel]: stateChannel } = this.state;
      if (stateChannel) {
        value = stateChannel;
      }

      let circleFill = channel;
      if (channel === "amber") {
        circleFill = "#f5a623";
      }

      return (
        <div
          className="metrics__threshold-menu-st-rows"
          key={`singleThreshold-${channel}`}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              width: "60px",
            }}
          >
            <svg height="20" width="20">
              <circle cx="6" cy="10" r="4" fill={circleFill} />
            </svg>
            {channel.toUpperCase()}
          </div>
          <div>
            <input
              className="metrics__st-input"
              step="any"
              type="string"
              value={value || ""}
              onChange={e =>
                this.updateLocalThresholdValues(channel, e.target.value)
              }
              onBlur={e =>
                this.updateLocalThresholdValues(
                  channel,
                  round(e.target.value, 2)
                )
              }
            />
          </div>
        </div>
      );
    });
  }

  renderError() {
    const { error, shake } = this.state;
    const { showError, message } = error;

    if (!showError) {
      return null;
    }

    let messageClass = "";
    if (shake) {
      messageClass = "metrics__shake";
    }

    return (
      <span style={{ width: "200px" }} className={messageClass}>
        {message}
      </span>
    );
  }

  render() {
    return (
      <div className="metrics__st-menu">
        <div style={{ marginBottom: "15px" }}>
          {this.renderAvailableThresholds()}
        </div>
        {this.renderError()}
        <span
          className="btn-modal"
          style={{ width: "200px" }}
          onClick={() => this.applySingleThresholds()}
        >
          Apply Thresholds
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    singleThresholds: getSingleThresholds(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSingleThresholds: thresholds => {
      dispatch(actionUpdateSingleThresholds(thresholds));
    },
    updateCQ: channel => {
      dispatch(updateCQ(channel));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThresholdPanel);
