import { CSSProperties } from "react";

export const modalStyle: CSSProperties = {
  width: "400px !important",
};

export const cyclesDisplayedSelectorContainer: CSSProperties = {
  marginTop: "20px",
  position: "relative",
};

export const cyclesDisplayedAxisContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignContent: "center",
  background: "none",
  width: "410px",
};

export const cyclesXAxisStyle: CSSProperties = {
  fontWeight: 600,
  width: "25px",
  textAlign: "center",
  zIndex: 10,
  pointerEvents: "none",
};

export const modalButtonContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "30px",
  marginBottom: "10px",
};

export const cancelButtonStyle: CSSProperties = {
  marginTop: "20px",
  fontWeight: 600,
  cursor: "pointer",
};

export const cyclesDisplayedButtonStyle: CSSProperties = {
  height: "40px",
  width: "200px",
  boxSizing: "border-box",
  borderRadius: "11px",
  cursor: "pointer",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#c8d0d4",
};
export const cyclesDisplayedButtonContentStyle: CSSProperties = {
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: "14px",
  textAlign: "center",
};
export const cyclesDisplayedNumberStyle: CSSProperties = {
  fontWeight: 400,
};
export const disabledCyclesDisplayedButtonStyle: CSSProperties = {
  height: "40px",
  width: "200px",
  boxSizing: "border-box",
  borderRadius: "11px",
  cursor: "pointer",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#c8d0d4",
  opacity: 0.5,
  pointerEvents: "none",
};
