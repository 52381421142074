import React, { Fragment } from "react";
import { Modal, Button } from "bio-web-components";

interface Props {
  closeModal: () => void;
  announcementsModalIsOpen: boolean;
  activeAnnouncements: string | null;
}

const AnnouncementsModal: React.FunctionComponent<Props> = (props: Props) => {
  const { closeModal, announcementsModalIsOpen, activeAnnouncements } = props;

  const _renderAnnouncementsText = () => {
    return (
      <p style={{ paddingTop: 10, paddingBottom: 10, maxWidth: "50vw" }}>
        {activeAnnouncements}
      </p>
    );
  };

  const _renderButton = () => {
    return (
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Button buttonClass="btn-modal" title="OK" onClick={closeModal} />
      </div>
    );
  };

  const heading = <span className="modal__heading">Alert</span>;

  return (
    <Modal
      heading={heading}
      open={announcementsModalIsOpen}
      onClose={closeModal}
    >
      <Fragment>
        {_renderAnnouncementsText()}
        {_renderButton()}
      </Fragment>
    </Modal>
  );
};

export default AnnouncementsModal;
