import React from "react";
import { connect } from "react-redux";

import { toggleBulkReviewSummaryModal } from "../../../../actions";
import Button from "../../../../../Layout/Button";
import SummaryDataIcon from "./SummaryDataIcon";

interface DispatchProps {
  openBulkReviewSummary: () => void;
}

type Props = DispatchProps;

export const SummaryButton: React.FunctionComponent<Props> = (props: Props) => {
  const { openBulkReviewSummary } = props;

  const wrappedDetailedDataIcon = () => {
    return <SummaryDataIcon width={15} height={15} />;
  };

  return (
    <Button
      onClick={() => {
        openBulkReviewSummary();
      }}
      icon={wrappedDetailedDataIcon}
      title="Summary"
      buttonClass="bulk_review_summary_button"
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openBulkReviewSummary: () => {
      dispatch(toggleBulkReviewSummaryModal());
    },
  };
};

export default connect(null, mapDispatchToProps)(SummaryButton);
