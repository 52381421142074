import { CSSProperties } from "react";

export const bulkReviewSidebarEntryHeadingText: CSSProperties = {
  fontSize: "16px",
  fontWeight: 600,
  color: "#012639",
  letterSpacing: "0",
  lineHeight: "1",
  fontFamily: "'Roboto', sans-serif",
  overflowWrap: "anywhere",
};

export const bulkReviewSidebarEntryHeading: CSSProperties = {
  padding: "0 0 15px 0",
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "nowrap",
};

export const bulkReviewSidebarEntryPreheading: CSSProperties = {
  fontSize: "12px",
  color: "#2b4a5a",
  letterSpacing: "-0.25px",
  lineHeight: "12px",
  paddingBottom: "10px",
};

export const bulkReviewSidebarEntryErrorMessage: CSSProperties = {
  ...bulkReviewSidebarEntryPreheading,
  color: "rgba(255, 0, 0, 0.616)",
};

export const failedRunHeading: CSSProperties = {
  opacity: "0.5",
  pointerEvents: "none",
};
