/* eslint-disable no-console */
import { serializeError } from "serialize-error";

/**
 * Logger Module to handle logging throughout the portal
 *
 * @export
 * @default
 * @class Logger
 */
export default class CloudLog {
  private static _serializeDataIfObject(dataToLog: any) {
    try {
      if (typeof dataToLog === "object") {
        return JSON.stringify(serializeError(dataToLog));
      }

      return dataToLog;
    } catch (err) {
      return dataToLog;
    }
  }

  private static _formatForLog(dataToFormat: any) {
    return `[${Date.now().toString()}]: ${dataToFormat}\n`;
  }

  /**
   * @param {*} message
   * @memberof Logger
   */
  static log(message: any) {
    const convertedMessage = this._serializeDataIfObject(message);
    const formattedMessage = `[LOG] ${this._formatForLog(convertedMessage)}`;

    console.log(formattedMessage);
  }

  /**
   * @param {*} message
   * @memberof Logger
   */
  static info(message: any) {
    const convertedMessage = this._serializeDataIfObject(message);
    const formattedMessage = `[INFO] ${this._formatForLog(convertedMessage)}`;

    console.info(formattedMessage);
  }

  /**
   * @param {*} message
   * @memberof Logger
   */
  static warn(message: any) {
    const convertedMessage = this._serializeDataIfObject(message);
    const formattedMessage = `[WARN] ${this._formatForLog(convertedMessage)}`;

    console.warn(formattedMessage);
  }

  /**
   * @param {*} message
   * @memberof Logger
   */
  static error(message: any) {
    const convertedMessage = this._serializeDataIfObject(message);
    const formattedMessage = `[ERROR] ${this._formatForLog(convertedMessage)}`;

    console.error(formattedMessage);
  }
}
