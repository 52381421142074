import React from "react";
import { RouteComponentProps } from "react-router-dom";

import DropdownBox from "../../../../Layout/DropdownBox";
import { Metrics } from "../../../types";

interface Props extends RouteComponentProps<any> {
  metrics: Metrics;
  metricsLabel: string;
}

const CqMetrics: React.FunctionComponent<any> = (props: Props) => {
  const { metrics, metricsLabel } = props;
  const { green, amber, red } = metrics;

  const showGreen = green && green.enabled;
  const showAmber = amber && amber.enabled;
  const showRed = red && red.enabled;

  const _renderMinMetrics = () => {
    const minMetrics = [];

    if (showGreen) {
      minMetrics.push(<div key="green-min">{green.min}</div>);
    }

    if (showAmber) {
      minMetrics.push(<div key="amber-min">{amber.min}</div>);
    }

    if (showRed) {
      minMetrics.push(<div key="red-min">{red.min}</div>);
    }

    return minMetrics;
  };

  const _renderMaxMetrics = () => {
    const maxMetrics = [];

    if (showGreen) {
      maxMetrics.push(<div key="green-max">{green.max}</div>);
    }

    if (showAmber) {
      maxMetrics.push(<div key="amber-max">{amber.max}</div>);
    }

    if (showRed) {
      maxMetrics.push(<div key="red-max">{red.max}</div>);
    }

    return maxMetrics;
  };

  const _renderAvgMetrics = () => {
    const avgMetrics = [];

    if (showGreen) {
      avgMetrics.push(<div key="green-avg">{green.average}</div>);
    }

    if (showAmber) {
      avgMetrics.push(<div key="amber-avg">{amber.average}</div>);
    }

    if (showRed) {
      avgMetrics.push(<div key="red-avg">{red.average}</div>);
    }

    return avgMetrics;
  };

  const _renderStdMetrics = () => {
    const stdMetrics = [];

    if (showGreen) {
      stdMetrics.push(<div key="green-std">{green.standardDeviation}</div>);
    }

    if (showAmber) {
      stdMetrics.push(<div key="amber-std">{amber.standardDeviation}</div>);
    }

    if (showRed) {
      stdMetrics.push(<div key="red-std">{red.standardDeviation}</div>);
    }

    return stdMetrics;
  };

  const _renderHeading = () => {
    const metricsBlockDataTipText = `${metricsLabel} metrics are calculated using only those lines that are actively displayed on the chart with nonzero ${metricsLabel}s.`;

    return (
      <span className="metrics_block" data-tip={metricsBlockDataTipText}>
        {metricsLabel} Metrics
      </span>
    );
  };

  const _renderGreenHeader = () => {
    if (showGreen) {
      return (
        <div className="metrics__channel-name">
          <svg height="20" width="20">
            <circle cx="6" cy="10" r="4" fill="green" />
          </svg>
          GREEN
        </div>
      );
    }

    return null;
  };

  const _renderAmberHeader = () => {
    if (showAmber) {
      return (
        <div className="metrics__channel-name">
          <svg height="20" width="20">
            <circle cx="6" cy="10" r="4" fill="#f5a623" />
          </svg>
          AMBER
        </div>
      );
    }

    return null;
  };

  const _renderRedHeader = () => {
    if (showRed) {
      return (
        <div className="metrics__channel-name">
          <svg height="20" width="20">
            <circle cx="6" cy="10" r="4" fill="red" />
          </svg>
          RED
        </div>
      );
    }

    return null;
  };

  return (
    <div className="cq-metrics-container">
      <DropdownBox heading={_renderHeading()} borderOpened>
        <div className="metrics__condensed">
          <div className="metrics__column-header">
            {_renderGreenHeader()}
            {_renderAmberHeader()}
            {_renderRedHeader()}
          </div>
          <div className="metrics__column">
            <div className="metrics__header">MIN</div>
            {_renderMinMetrics()}
          </div>
          <div className="metrics__column">
            <div className="metrics__header">MAX</div>
            {_renderMaxMetrics()}
          </div>
          <div className="metrics__column">
            <div className="metrics__header">AVG</div>
            {_renderAvgMetrics()}
          </div>
          <div className="metrics__column">
            <div className="metrics__header">STD</div>
            {_renderStdMetrics()}
          </div>
        </div>
      </DropdownBox>
    </div>
  );
};

export default CqMetrics;
