import { CSSProperties } from "react";
import {
  failureStatus,
  successStatus,
} from "../BulkReviewSubmissionIndicator/styles";
import { bulkReviewSidebarEntryHeading, bulkReviewSidebarEntryHeadingText, bulkReviewSidebarEntryPreheading } from "../BulkRunBlockEntry/styles";

export const failureStatusEntryPreheading: CSSProperties = {
  ...failureStatus,
};

export const successStatusEntryPreheading: CSSProperties = {
  ...successStatus,
};

export const failureStatusEntryHeader: CSSProperties = {
  ...failureStatus,
};

export const successStatusEntryHeader: CSSProperties = {
  ...successStatus,
};

export const bulkReviewSubmissionEntryHeadingText: CSSProperties = {
  ...bulkReviewSidebarEntryHeadingText,
};

export const bulkReviewSubmissionEntryHeading: CSSProperties = {
  ...bulkReviewSidebarEntryHeading,
};

export const bulkSubmissionEntryHeader: CSSProperties = {
  ...bulkReviewSubmissionEntryHeading,
  ...bulkReviewSidebarEntryHeadingText,
};

export const bulkReviewSubmissionEntryPreheading: CSSProperties = {
  ...bulkReviewSidebarEntryPreheading,
};