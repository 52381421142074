import { createSelector } from "reselect";

import {
  ControlRule,
  SampleRules,
  ReviewRule,
  RawRunReview,
  RawSampleReview,
  RawTargetReview,
  RawReviewSummaryTargetData,
  RawRunReviewSampleReview,
  RawRunReviewSummary,
} from "js-common";
import { generateTimeString } from "js-common/lib/helpers";

import {
  getTabularDataFilter,
  getTabularDataOrder,
  getTargetData,
} from "../RunsPage/selectors";
import {
  RunReview,
  SampleReview,
  ResultIcon,
  PendingRun,
  ReviewDashboardFilter,
  BulkReviewRun,
  BulkSamples,
  BulkReviewSubmissionStatus,
  MapBulkSampleTargetsReturn,
  CreateBulkTabularDataTargetsReturn,
  ConstructMappedBulkFullRunDataTargetsReturn,
  GetBulkReviewTabularDataReturn,
  CombinedBulkTargetsReturn,
} from "./types";
import { BulkReviewFilter, BulkRunBlock } from "../RunsPage/types";
import {
  collectUpperBoundValuesFromSampleRule,
  combineSampleTargetsWithBulkFullRunData,
  constructMappedBulkFullRunDataTargets,
  createBulkTabularDataTargets,
  filterBulkControlNegativeSamples,
  filterBulkControlPositiveSamples,
  filterBulkInconclusiveSamples,
  filterBulkNegativeSamples,
  filterBulkNoTemplateControlSamples,
  filterBulkPositiveSamples,
  formatBulkSamples,
  getMatchingReview,
  mapBulkSampleTargets,
} from "./helpers";
import { getBulkFullRunData } from "../FullRunData/selectors";
import { sortTabularData } from "../RunsPage/helpers";
import { BulkFullRunDataByDetailsVersion } from "../FullRunData/types";

export const getRunReview = (state: any): RunReview => {
  return state.runReview.review;
};

export const getReviewRules = (state: any): ReviewRule => {
  return state.runReview.reviewRules;
};
export const getReviewControlRules = (state: any): Array<ControlRule> => {
  return state.runReview.reviewRules.controlRules;
};
export const getReviewSampleRules = (state: any): SampleRules => {
  return state.runReview.reviewRules.sampleRules;
};

export const getPreviousReviewerIsAdmin = (state: any): boolean => {
  return state.runReview.previousReviewUser.isAdmin;
};
export const getPreviousReviewerName = (state: any): string => {
  return state.runReview.previousReviewUser.name;
};

export const getReviewLog = (state: any): Array<RawRunReview> => {
  return state.runReview.reviewLog;
};

export const getReviewLogLength = createSelector(
  getReviewLog,
  (reviews): number => {
    if (Array.isArray(reviews)) {
      return reviews.length;
    }

    return 0;
  }
);

export const getReviewExportData = createSelector(
  [getRunReview, getReviewLog],
  (runReview, reviewLog): Array<RawRunReviewSummary> => {
    const reviewSummaryOutput: Array<RawRunReviewSummary> = [];
    if (runReview && reviewLog.length !== 0) {
      const latestSubmittedReview = reviewLog[reviewLog.length - 1];
      const { samples: logSamples } = latestSubmittedReview;
      const { samples: runReviewSamples } = runReview;
      const updatedSamples: Array<RawRunReviewSampleReview> = [];

      logSamples.forEach((logSample: RawSampleReview) => {
        const { targets: logTargets } = logSample;

        const runReviewSample = runReviewSamples.find(runSample => {
          return runSample.sampleName === logSample.sampleName;
        });

        if (runReviewSample) {
          const { targets: runReviewTargets } = runReviewSample;
          const updatedTargets: Array<RawReviewSummaryTargetData> = [];

          logTargets.forEach((logTarget: RawTargetReview) => {
            const runReviewTarget = runReviewTargets.find(runTarget => {
              return runTarget.targetId === logTarget.targetId;
            });

            if (runReviewTarget) {
              updatedTargets.push({
                ...logTarget,
                targetName: runReviewTarget.name,
              });
            }
          });

          updatedSamples.push({
            ...logSample,
            targets: updatedTargets,
          });
        }
      });

      reviewSummaryOutput.push({
        ...latestSubmittedReview,
        samples: updatedSamples,
      });
    }

    return reviewSummaryOutput;
  }
);

export const getReviewDashboardIsLoading = (state: any): boolean => {
  return state.runReview.loading;
};

export const getPendingRuns = (state: any): Array<PendingRun> => {
  return state.runReview.pendingRuns;
};
export const getPendingRunCount = (state: any): number => {
  return state.runReview.pendingRuns.length;
};

export const getBulkReviewRuns = (state: any): BulkReviewRun[] => {
  return state.runReview.bulkReviewRuns;
};

export const getActiveRunFilter = (state: any): ReviewDashboardFilter => {
  return state.runReview.filter;
};

export const getBulkReviewSubmissionStatus = (
  state: any
): BulkReviewSubmissionStatus[] => {
  return state.runReview.bulkRunSubmissionStatuses;
};

export const getControlNames = createSelector(
  getReviewControlRules,
  controlRules => {
    return controlRules.map(controlRule => {
      return controlRule.control;
    });
  }
);

export const getNeedsReviewCount = createSelector(
  getPendingRuns,
  (runs): number => {
    return runs.filter(run => {
      return run.needsReview;
    }).length;
  }
);

export const getNeedsApprovalCount = createSelector(
  getPendingRuns,
  (runs): number => {
    return runs.filter(run => {
      return run.needsApproval;
    }).length;
  }
);

export const getNeedsApprovalPositiveCount = createSelector(
  getPendingRuns,
  (runs): number => {
    return runs.filter(run => {
      return run.needsApproval && run.containsPositiveSample;
    }).length;
  }
);

export const getRecentlyReviewedAndApprovedCount = createSelector(
  getPendingRuns,
  (runs): number => {
    return runs.filter(run => {
      return run.recentlyReviewedAndApproved;
    }).length;
  }
);

export const getFormattedPendingRuns = createSelector(
  getPendingRuns,
  (runs): Array<PendingRun> => {
    return runs.map(run => {
      let date = run.reviewDate;
      if (date) {
        date = generateTimeString(date);
      }

      return {
        ...run,
        date: generateTimeString(run.date),
        reviewDate: date,
      };
    });
  }
);

export const getFilteredPendingRuns = createSelector(
  [getFormattedPendingRuns, getActiveRunFilter],
  (runs, filter): Array<PendingRun> => {
    let filtered;

    if (filter === "needsApprovalPositive") {
      filtered = runs.filter(run => {
        return run.needsApproval && run.containsPositiveSample;
      });
    } else {
      filtered = runs.filter(run => {
        return run[filter];
      });
    }

    return filtered.map(run => {
      let { user } = run;
      if (
        filter === "needsApproval" ||
        filter === "recentlyReviewedAndApproved" ||
        filter === "needsApprovalPositive"
      ) {
        user = run.reviewUser;
      }

      return {
        ...run,
        user,
      };
    });
  }
);

export const getAllTargetsConfirmed = createSelector(
  getRunReview,
  (runReview): boolean => {
    const { samples } = runReview;

    let allConfirmed = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const sample of samples) {
      const { targets } = sample;

      // eslint-disable-next-line no-restricted-syntax
      for (const target of targets) {
        const { cqOverride } = target;

        if (cqOverride === null) {
          allConfirmed = false;
          break;
        }
      }

      if (!allConfirmed) {
        break;
      }
    }

    return allConfirmed;
  }
);

export const getAllTargetsApproved = createSelector(
  getRunReview,
  (runReview): boolean => {
    const { samples } = runReview;

    let allApproved = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const sample of samples) {
      const { targets } = sample;

      // eslint-disable-next-line no-restricted-syntax
      for (const target of targets) {
        const { cqOverride } = target;

        if (cqOverride === null || cqOverride) {
          allApproved = false;
          break;
        }
      }

      if (!allApproved) {
        break;
      }
    }

    return allApproved;
  }
);

export const getActiveSamples = createSelector(
  [getTargetData, getRunReview],
  (targetData, runReview): Array<SampleReview> => {
    const { samples } = runReview;

    return samples.filter(sample => {
      return targetData.find((target: any) => {
        return sample.wellNumber.indexOf(target.well) > -1 && target.showLine;
      });
    });
  }
);

export const getSampleResultIcons = createSelector(
  getReviewRules,
  (reviewRules): Array<ResultIcon> => {
    const { sampleRules } = reviewRules;
    const { rules } = sampleRules;

    return rules.map(rule => {
      return { result: rule.result, icon: rule.icon };
    });
  }
);

export const getCalculatedReviewCutoffStartValue = createSelector(
  getReviewRules,
  rules => {
    if (!rules || !rules.sampleRules) {
      return null;
    }

    const { sampleRules } = rules;
    const arrayOfUpperBoundValues: number[] = [];

    const positiveSampleRules = sampleRules.rules.filter(sampleRule => {
      return sampleRule.result.toLowerCase() === "positive";
    });

    positiveSampleRules.forEach(positiveSampleRule => {
      const { logicalTargetRuleCollection } = positiveSampleRule;

      arrayOfUpperBoundValues.push(
        ...collectUpperBoundValuesFromSampleRule(logicalTargetRuleCollection)
      );
    });

    if (arrayOfUpperBoundValues.length > 0) {
      return Math.min(...arrayOfUpperBoundValues);
    }

    return null;
  }
);

export const bulkReviewModalIsOpen = (state: any): boolean => {
  return state.runReview.showBulkReviewModal;
};

export const getBulkReviewFilter = (state: any): BulkReviewFilter => {
  return state.runReview.bulkReviewFilter;
};

export const getBulkReviewRunBlocks = (state: any): BulkRunBlock[] => {
  return state.runReview.bulkRunBlocks;
};

export const getBulkFailedRunIds = (state: any): string[] => {
  return state.runReview.bulkTabularFailedRunIds;
};

export const getActiveBulkReviewRunBlocks = createSelector(
  getBulkReviewRunBlocks,
  (bulkReviewRunBlocks: BulkRunBlock[]): BulkRunBlock[] => {
    return bulkReviewRunBlocks.filter(bulkReviewRunBlock => {
      return bulkReviewRunBlock.active;
    });
  }
);

export const getActiveBulkReviewRunBlocksIds = createSelector(
  getActiveBulkReviewRunBlocks,
  (activeBulkReviewRunBlocks: BulkRunBlock[]): string[] => {
    return activeBulkReviewRunBlocks.map(activeBulkReviewRunBlock => {
      return activeBulkReviewRunBlock.runId;
    });
  }
);

export const getBulkReviewSamples = createSelector(
  getBulkReviewRuns,
  (bulkReviewRuns: BulkReviewRun[]): BulkSamples[] => {
    let bulkSampleArray: BulkSamples[] = [];

    bulkReviewRuns.forEach(bulkReviewRun => {
      const { runReviewId: topLevelRunReviewId, existingReviewData } =
        bulkReviewRun;

      const matchedReview = getMatchingReview(
        topLevelRunReviewId,
        existingReviewData
      );

      if (matchedReview) {
        const bulkSamples = formatBulkSamples(matchedReview);

        bulkSampleArray = [...bulkSampleArray, ...bulkSamples];
      }
    });

    return bulkSampleArray;
  }
);

export const filteredBulkReviewSamples = createSelector(
  [getBulkReviewFilter, getBulkReviewSamples],
  (
    bulkReviewFilter: BulkReviewFilter,
    bulkReviewSamples: BulkSamples[]
  ): BulkSamples[] => {
    switch (bulkReviewFilter) {
      case "positive":
        return filterBulkPositiveSamples(bulkReviewSamples);

      case "negative":
        return filterBulkNegativeSamples(bulkReviewSamples);

      case "inconclusive":
        return filterBulkInconclusiveSamples(bulkReviewSamples);

      case "controlPositive":
        return filterBulkControlPositiveSamples(bulkReviewSamples);

      case "controlNegative":
        return filterBulkControlNegativeSamples(bulkReviewSamples);

      case "noTemplateControl":
        return filterBulkNoTemplateControlSamples(bulkReviewSamples);

      default:
        return bulkReviewSamples;
    }
  }
);

export const getBulkReviewTabularData = createSelector(
  [
    filteredBulkReviewSamples,
    getBulkFullRunData,
    getTabularDataFilter,
    getTabularDataOrder,
  ],
  (
    filteredBulkSamples: BulkSamples[],
    bulkFullRunData,
    tabularDataFilter,
    tabularDataOrder
  ): GetBulkReviewTabularDataReturn => {
    const {
      mappedBulkSampleTargets,
      failedRunIds: mapBulkSampleTargetsFailedRunIds,
    }: MapBulkSampleTargetsReturn = mapBulkSampleTargets(filteredBulkSamples);

    const {
      mappedBulkFullRunDataTargets,
      failedRunIds: mappedBulkFullRunDataTargetsFailedRunIds,
    }: ConstructMappedBulkFullRunDataTargetsReturn =
      constructMappedBulkFullRunDataTargets(bulkFullRunData);

    const {
      combinedBulkTargets: combinedTargets,
      failedRunIds: combinedBulkTargetsFailedRunIds,
    }: CombinedBulkTargetsReturn = combineSampleTargetsWithBulkFullRunData(
      mappedBulkSampleTargets,
      mappedBulkFullRunDataTargets
    );

    const {
      tabularDataTargets,
      failedRunIds: tabularDataTargetsFailedRunIds,
    }: CreateBulkTabularDataTargetsReturn =
      createBulkTabularDataTargets(combinedTargets);

    const failedRunIds = [
      ...mapBulkSampleTargetsFailedRunIds,
      ...mappedBulkFullRunDataTargetsFailedRunIds,
      ...combinedBulkTargetsFailedRunIds,
      ...tabularDataTargetsFailedRunIds,
    ];
    const dedupedFailedRunIds = [...new Set(failedRunIds)];

    let sortedTabularData = sortTabularData(
      tabularDataTargets,
      tabularDataFilter
    );
    if (tabularDataOrder === 1) {
      sortedTabularData = sortedTabularData.reverse();
    }

    return {
      tabularData: sortedTabularData,
      failedRunIds: dedupedFailedRunIds,
    };
  }
);

export const getActiveBulkReviewRuns = createSelector(
  [getBulkReviewRuns, getActiveBulkReviewRunBlocksIds],
  (
    bulkReviewRuns: BulkReviewRun[],
    activeBulkRunBlocksIds: string[]
  ): BulkReviewRun[] => {
    return bulkReviewRuns.filter(bulkReviewRun => {
      return activeBulkRunBlocksIds.includes(bulkReviewRun.id);
    });
  }
);

export const getActiveBulkFullRunData = createSelector(
  [getBulkFullRunData, getActiveBulkReviewRunBlocksIds],
  (
    bulkFullRunData,
    activeBulkRunReviewIds
  ): BulkFullRunDataByDetailsVersion => {
    const filterById = (runData: any, ids = activeBulkRunReviewIds) => {
      return runData.filter((run: any) => {
        return ids.includes(run.id);
      });
    };

    return {
      "biomeme-1": filterById(bulkFullRunData["biomeme-1"]),
      "biomeme-2": filterById(bulkFullRunData["biomeme-2"]),
      isp: filterById(bulkFullRunData.isp),
      abi: filterById(bulkFullRunData.abi),
      biorad: filterById(bulkFullRunData.biorad),
    };
  }
);
