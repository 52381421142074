import { getAwsRedirectDomain, getOauthDomain } from "./helpers";

const redirectDomain = getAwsRedirectDomain();
const oauthDomain = getOauthDomain();

export default {
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.BIO_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.BIO_COGNITO_CLIENT_ID,
    oauth: {
      domain: oauthDomain,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${redirectDomain}login`,
      redirectSignOut: redirectDomain,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "BiomemeAPIGatewayDev",
        endpoint: "https://dev-api.biomeme.com",
      },
      {
        name: "BiomemeAPIGatewayProd",
        endpoint: "https://api.biomeme.com",
      },
    ],
  },
  redirectDomain,
};
