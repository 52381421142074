import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../../Layout/Modal";
import {
  getBulkReviewRuns,
  getBulkReviewSamples,
  getBulkReviewTabularData,
} from "../../../../RunReview/selectors";
import {
  BulkReviewRun,
  BulkSamples,
  GetBulkReviewTabularDataReturn,
} from "../../../../RunReview/types";
import { toggleBulkReviewSummaryModal as toggleBulkReviewSummaryModalAction } from "../../../actions";
import { bulkReviewSummaryModalIsOpen as bulkReviewSummaryModalIsOpenState } from "../../../selectors";
import {
  INITIAL_AVERAGES,
  INITIAL_TOTALS,
  INITIAL_TOTAL_FAILURES,
} from "./constants";
import { averageCalculations, totalCalculations } from "./helpers";
import BulkReviewSummaryModalAverages from "./Subcomponents/BulkReviewSummaryModalAverages";
import BulkReviewSummaryModalTotalFailures from "./Subcomponents/BulkReviewSummaryModalTotalFailures";
import BulkReviewSummaryModalTotals from "./Subcomponents/BulkReviewSummaryModalTotals";

interface StateProps {
  bulkReviewSummaryModalIsOpen: boolean;
  bulkReviewRuns: BulkReviewRun[];
  bulkReviewSamples: BulkSamples[];
  bulkReviewTabularData: GetBulkReviewTabularDataReturn;
}

interface DispatchProps {
  toggleBulkReviewSummaryModal: () => void;
}

type Props = StateProps & DispatchProps;

export const BulkReviewSummaryModal: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    bulkReviewSummaryModalIsOpen,
    toggleBulkReviewSummaryModal,
    bulkReviewSamples,
    bulkReviewTabularData,
  } = props;
  const { tabularData } = bulkReviewTabularData;

  const [totals, setTotals] = useState(INITIAL_TOTALS);
  const [failures, setFailures] = useState(INITIAL_TOTAL_FAILURES);
  const [averages, setAverages] = useState(INITIAL_AVERAGES);

  useEffect(() => {
    const { calculatedTotals, calculatedFailures } =
      totalCalculations(bulkReviewSamples);

    setTotals(calculatedTotals);
    setFailures(calculatedFailures);

    const calculatedAverages = averageCalculations(tabularData);

    setAverages(calculatedAverages);
  }, [bulkReviewSamples, bulkReviewTabularData]);

  const _renderTotals = () => {
    const { samples, positiveControls, negativeControls, noTemplateControls } =
      totals;

    return (
      <BulkReviewSummaryModalTotals
        samples={samples}
        positiveControls={positiveControls}
        negativeControls={negativeControls}
        noTemplateControls={noTemplateControls}
      />
    );
  };

  const _renderTotalFailures = () => {
    const {
      inconclusive,
      positiveControlFailures,
      negativeControlFailures,
      noTemplateControlFailures,
    } = failures;

    return (
      <BulkReviewSummaryModalTotalFailures
        inconclusive={inconclusive}
        positiveControlFailures={positiveControlFailures}
        negativeControlFailures={negativeControlFailures}
        noTemplateControlFailures={noTemplateControlFailures}
      />
    );
  };

  const _renderAverages = () => {
    const {
      averageMS2,
      averagePositiveControlOrf1ab,
      averagePositiveControlMS2,
      averagePositiveControlS,
    } = averages;

    return (
      <BulkReviewSummaryModalAverages
        averageMS2={averageMS2}
        averagePositiveControlOrf1ab={averagePositiveControlOrf1ab}
        averagePositiveControlMS2={averagePositiveControlMS2}
        averagePositiveControlS={averagePositiveControlS}
      />
    );
  };

  const _renderSummaryContent = () => {
    let content = (
      <label className="app_error">Error: No bulk review summary data.</label>
    );

    if (bulkReviewSamples.length > 0) {
      content = (
        <>
          <div
            className="bulk-review-summary__content-container"
            key="bulk-review-summary-contentDiv"
          >
            {_renderTotals()}
            {_renderTotalFailures()}
          </div>
          {_renderAverages()}
        </>
      );
    }

    return content;
  };

  const _renderBulkReviewModal = () => {
    let modal = null;

    if (bulkReviewSummaryModalIsOpen) {
      modal = (
        <Modal
          open={bulkReviewSummaryModalIsOpen}
          heading="Summary"
          onClose={toggleBulkReviewSummaryModal}
          className="modal--bulk_review"
        >
          <div className="bulk-review-summary">{_renderSummaryContent()}</div>
        </Modal>
      );
    }

    return modal;
  };

  return _renderBulkReviewModal();
};

const mapStateToProps = (state: any) => {
  return {
    bulkReviewSummaryModalIsOpen: bulkReviewSummaryModalIsOpenState(state),
    bulkReviewRuns: getBulkReviewRuns(state),
    bulkReviewSamples: getBulkReviewSamples(state),
    bulkReviewTabularData: getBulkReviewTabularData(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleBulkReviewSummaryModal: () => {
      dispatch(toggleBulkReviewSummaryModalAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkReviewSummaryModal);
