import { createSelector } from "reselect";
import { generateTimeString } from "js-common/lib/helpers";

import { sortByDate } from "../Utils/helpers";
import { sortSampleTabularData } from "./helpers";

export const getTableDisplay = state => state.sampleHub.tableDisplay;
export const getSortBy = state => state.sampleHub.sortBy;
export const getActiveTemplate = state => state.sampleHub.activeTemplate;
export const getActiveRecord = state => state.sampleHub.activeRecord;
export const getToPrint = state => state.sampleHub.toPrint;
export const getToDelete = state => state.sampleHub.toDelete;
export const getTemplateToEditId = state => state.sampleHub.templateToEdit;
export const getTemplates = state => state.sampleHub.templates;
export const getRecords = state => state.sampleHub.records;
export const getShowTutorial = state => state.sampleHub.showSampleHubTutorial;
export const getTemplatePostErrors = state =>
  state.sampleHub.templatePostErrors;
export const getRecordPostErrors = state => state.sampleHub.recordPostErrors;

export const getTemplateToEdit = createSelector(
  [getTemplates, getTemplateToEditId],
  (templates, idOfTemplateToEdit) => {
    return templates.find(template => template.id === idOfTemplateToEdit);
  }
);
export const getTemplatesDateFormatted = createSelector(
  [getTemplates],
  templates => {
    if (templates) {
      const sortedTemplates = sortByDate(templates);

      const updatedTemplates = [];
      sortedTemplates.forEach(template => {
        const updatedTemplate = { ...template };
        updatedTemplate.date = generateTimeString(template.date);
        updatedTemplates.push(updatedTemplate);
      });

      return updatedTemplates;
    }

    return templates;
  }
);

export const getRecordsDateFormatted = createSelector([getRecords], records => {
  if (records) {
    const sortedRecords = sortByDate(records);
    const updatedRecords = [];
    sortedRecords.forEach(record => {
      const updatedRecord = { ...record };
      updatedRecord.date = generateTimeString(record.date);
      updatedRecords.push(updatedRecord);
    });

    return updatedRecords;
  }

  return records;
});

export const getSampleTabularData = createSelector(
  [
    getRecordsDateFormatted,
    getTemplatesDateFormatted,
    getTableDisplay,
    getSortBy,
  ],
  (records, templates, display, sortBy) => {
    if (display === "record") {
      const { record } = sortBy;
      const sortField = Object.keys(record)[0];
      const direction = record[sortField];

      return sortSampleTabularData(records, sortField, direction);
    }
    const { template } = sortBy;
    const sortField = Object.keys(template)[0];
    const direction = template[sortField];

    return sortSampleTabularData(templates, sortField, direction);
  }
);
