import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import Content from "../../App/components/content";
import Sidebar from "../../Sidebar";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";
import { isAppUpdateModalOpen } from "../../Sidebar/selectors";
import { hideAppUpdateModal } from "../../Sidebar/actions";
import BugReportForm from "./BugReportForm";

interface StateProps {
  openModal: boolean;
}

interface DispatchProps {
  closeModal: () => void;
}

interface Props extends StateProps, DispatchProps {}

const Feedback: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className="fluidContainers">
      <Sidebar />
      <Content>
        <AppUpdateModal {...props} />
        <div className="page">
          <div className="page__container">
            <div className="page__left">
              <BugReportForm />
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    openModal: isAppUpdateModalOpen(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    closeModal: () => {
      dispatch(hideAppUpdateModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
