import React, { Component } from "react";

import { classNamesParser } from "../Utils/layoutUtils";

interface Props {
  heading: string;
  preHeading: string;
  opened: boolean;
  icons: boolean | number | string | object;
  borderOpened: boolean;
  statusIcon: boolean | number | string | object;
}

interface State {
  open: boolean | null;
}

class DropdownBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { open: null };
  }

  getClassNames() {
    const { borderOpened } = this.props;

    return classNamesParser({
      "detailed-dropdown": true,
      "detailed-dropdown--open": this.isDropDownOpen(),
      "detailed-dropdown--bordered": this.isDropDownOpen() && borderOpened,
    });
  }

  toggleOpen() {
    const { open } = this.state;
    const { opened } = this.props;

    if (open === null) {
      this.setState({ open: !opened });
    } else {
      this.setState({ open: !open });
    }
  }

  isDropDownOpen() {
    const { open } = this.state;
    const { opened } = this.props;

    if (open === null) {
      return opened;
    }

    return open;
  }

  renderPreHeading() {
    const { preHeading } = this.props;

    if (!preHeading) {
      return null;
    }

    return (
      <h4
        className="detailed-dropdown__preHeading section-header"
        key={preHeading}
      >
        {preHeading.toUpperCase()}
      </h4>
    );
  }

  renderIcons() {
    const { icons } = this.props;

    if (!icons) {
      return null;
    }

    return icons;
  }

  renderHeader() {
    const { heading, statusIcon } = this.props;

    return (
      <div
        className="detailed-dropdown__header"
        style={{ width: "600px" }}
        onClick={() => this.toggleOpen()}
        key={heading}
      >
        {this.renderPreHeading()}
        <h3 className="detailed-dropdown__heading" style={{ display: "flex" }}>
          {this.renderIcons()}
          {statusIcon}
          {heading}
        </h3>
      </div>
    );
  }

  renderBody() {
    const { children, heading } = this.props;

    return (
      <div className="detailed-dropdown__body" key={`${heading}-body`}>
        {children}
      </div>
    );
  }

  render() {
    const { heading } = this.props;

    return (
      <div className={this.getClassNames()} key={heading}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

export default DropdownBox;
