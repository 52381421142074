import React from "react";

interface Props {
  width: number;
  height: number;
}

const SummaryDataIcon: React.FunctionComponent<Props> = (props: Props) => {
  const { width, height } = props;

  const fill = "black";

  return (
    <svg
      width={width || 19}
      height={height || 24}
      fill={fill}
      className="bulk-review__invertible-button sample-hub__invertable-button"
    >
      <path d="M11.149 12.845V3.741c0-.348-.14-.685-.387-.929l-2.3-2.3a1.315 1.315 0 0 0-.929-.387H1.938C1.212.125.622.714.622 1.441v11.404c0 .726.589 1.316 1.316 1.316h7.895c.726 0 1.316-.589 1.316-1.316zM9.833 4.95v7.895H1.938V1.441h4.386v2.851c0 .365.293.658.658.658h2.851zM7.64 1.548l2.086 2.086H7.64V1.548zm.877 5.376v.768a.33.33 0 0 1-.329.329H3.583a.33.33 0 0 1-.329-.329v-.768a.33.33 0 0 1 .329-.329h4.605a.33.33 0 0 1 .329.329zm-.329 1.974H3.583a.33.33 0 0 0-.329.329v.768a.33.33 0 0 0 .329.329h4.605a.33.33 0 0 0 .329-.329v-.768a.33.33 0 0 0-.329-.329z" />
    </svg>
  );
};

export default SummaryDataIcon;
