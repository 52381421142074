import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { getVerificationCode } from "../selectors";
import { requestVerificationCode } from "../actions";
import Icon from "../../Utils/Layout/Icon";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ errorMessage: "" });
  }

  handleEmailSubmit = () => {
    const { requestCode, history } = this.props;
    const { email } = this.state;

    if (!email) {
      this.setState({ errorMessage: "Please enter an email address." });
    } else {
      const user = {
        email: email.trim(),
      };

      requestCode(user);
      history.push("/reset-password");
    }
  };

  renderEmailForm = () => {
    return (
      <Fragment>
        <div className="password_form__email">
          <p className="password__prompt">
            Already have a verification code?{" "}
            <Link to="/reset-password" style={{ color: "#1cbcc3" }}>
              Click here!
            </Link>
          </p>
          <p className="password__instructions">
            Please enter your email address below and check your inbox for a
            verification code. (It may take a minute to arrive.) If you don't
            see it, check your spam folder.
          </p>
          <p className="password_form__label">Email</p>
          <div className="password_form__input_wraper">
            <Icon name="user" className="password_form__icon" />
            <input
              key="forgot_password_email"
              className="password_form__input"
              type="text"
              placeholder="Email"
              onChange={e => this.setState({ email: e.target.value })}
            />
          </div>
          {this.renderErrorMessages()}
        </div>
        <div className="password_form__submit_row">
          <button
            className="password_form__submit_button"
            onClick={() => {
              this.setState({ errorMessage: "" });

              return this.handleEmailSubmit();
            }}
          >
            Request Verification Code
          </button>
        </div>
      </Fragment>
    );
  };

  renderErrorMessages() {
    const { errorMessage } = this.state;
    const { requestError } = this.props;

    let errorMessageBlock = null;
    let requestErrorBlock = null;

    if (errorMessage) {
      errorMessageBlock = (
        <p className="password__validation-warning">{errorMessage}</p>
      );
    }

    if (requestError) {
      requestErrorBlock = (
        <p className="password__validation-warning">{requestError}</p>
      );
    }

    return (
      <div style={{ marginTop: "10px" }}>
        {errorMessageBlock}
        {requestErrorBlock}
      </div>
    );
  }

  render() {
    return (
      <div className="password">
        <div className="password__title">
          <h1>Reset Password</h1>
        </div>
        {this.renderEmailForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    requestError: getVerificationCode(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestCode: user => dispatch(requestVerificationCode(user)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
