import React, { Component } from "react";
import { connect } from "react-redux";
import { scaleLinear, line, select, axisBottom, axisLeft } from "d3";

import constants from "../chartConstants";
import {
  getPrintViewXAxis,
  getPrintViewYAxis,
  getPrintChartData,
  getAxisLabels,
} from "../../selectors";
import { clearChart } from "../chartHelpers";

class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 950,
      height: 400,
    };
  }

  componentDidMount() {
    this.drawChart();
  }

  drawChart = () => {
    const { margin } = constants;
    const { xAxis, yAxis, axisLabels, targets } = this.props;
    const { yMax, yMin } = yAxis;
    const { width, height } = this.state;

    // eslint-disable-next-line
    const node = this.node;
    clearChart.apply(this);

    const x = scaleLinear().range([0, width]);
    const y = scaleLinear().range([height, 0]);
    x.domain([0, xAxis]);
    y.domain([yMin, yMax]);

    const valueline = line()
      .x((d, i) => x(i))
      .y(d => y(d));

    targets.forEach(target => {
      let { color } = target;
      if (color === "amber") {
        color = "#f5a623";
      }

      const pl = select(node)
        .append("g")
        .attr("class", "gline")
        .attr("key", `g-${target.id}`)
        .selectAll("path")
        .data([target.data])
        .enter();

      pl.data([target.data])
        .append("path")
        .attr("class", "line")
        .style("fill", "none")
        .style("stroke", color)
        .style("stroke-width", "1px")
        .attr("id", `${target.id}`)
        .attr("key", `${target.id}`)
        .attr("d", valueline);
    });

    select(node)
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", `translate(0,${height})`)
      .call(axisBottom(x.nice()).tickSize(-height));

    select(this.node).select(".xaxis").selectAll("line").attr("stroke", "#ddd");

    select(node).append("g").attr("class", "yaxis").call(axisLeft(y));

    select(node)
      .append("text")
      .attr("transform", `translate(${width / 2},${height + margin.top + 20})`)
      .style("text-anchor", "middle")
      .text(axisLabels.x);

    select(node)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left - 10)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(axisLabels.y);
  };

  render() {
    const { margin, padding } = constants;
    const { width, height } = this.state;

    return (
      <svg
        id="print-view-chart"
        // eslint-disable-next-line
        ref={node => (this.node = node)}
        width={width + margin.top + margin.bottom}
        height={height + margin.top + margin.bottom}
        style={{
          paddingLeft: padding.left,
          paddingTop: padding.top,
          overflow: "visible",
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    xAxis: getPrintViewXAxis(state),
    yAxis: getPrintViewYAxis(state),
    axisLabels: getAxisLabels(state),
    targets: getPrintChartData(state),
  };
}

export default connect(mapStateToProps, null)(Chart);
