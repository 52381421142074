import { StringIndexedObject } from "bio-web-components";
import { values } from "lodash";

import JSZip from "jszip";
import { IBioradChannelSelection } from "./types";

export const encodeDataAsForm = (data: StringIndexedObject) => {
  const form = new FormData();

  Object.keys(data).forEach(field => {
    form.append(field, data[field]);
  });

  return form;
};

export const handleFileZipping = (files: File[]) => {
  const zipper = new JSZip();

  files.forEach(file => {
    const { name } = file;

    zipper.file(name, file);
  });

  return zipper.generateAsync({
    type: "blob",
  });
};

export const getAbiFileSelectionError = (files: File[]): string | null => {
  const RESULTS_FILE_TEXT = "Results";
  const AMPLIFICATION_DATA_FILE_TEXT = "Amplification Data";

  let error: string | null = null;

  if (files.length < 1) {
    error = "Please select your ABI file exports to continue";
  } else {
    const resultsFile = files.find(file =>
      file.name.includes(RESULTS_FILE_TEXT)
    );
    const amplificationDataFile = files.find(file =>
      file.name.includes(AMPLIFICATION_DATA_FILE_TEXT)
    );

    if (!resultsFile || !amplificationDataFile) {
      error =
        "Please provide both the Amplification Data and Results file exports to continue";
    }
  }

  return error;
};

export const getBioradFileSelectionError = (
  files: File[],
  channelSelection: IBioradChannelSelection
): string | null => {
  let error: string | null = null;

  if (files.length < 1) {
    error = "Please select your BioRad file exports to continue";
  } else if (values(channelSelection).filter(channel => channel).length < 1) {
    error =
      "Please map at least one channel to a BioRad export file to continue";
  }

  return error;
};
