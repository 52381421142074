import {
  all,
  put,
  call,
  takeLatest,
  select,
  race,
  take,
} from "redux-saga/effects";
import { cloneDeep } from "lodash";

import { determinePeakTemperature } from "bm-algorithm-js/lib/calculate";
import * as t from "./actionTypes";
import { UPDATE_PATHS } from "../Hub/actionTypes";
import { hideChart, showChart } from "../Hub/actions";
import {
  updateMetrics,
  updateEndRfuMetrics,
  updateAxes,
  showSingleThresholds,
  resetBackgroundValues,
  storeReprocessedData,
  updateChartBackgroundValuesError,
  startBackgroundResetSequence,
  reprocessTargetSuccess,
  reprocessTargetFailure,
  reprocessTargetRequest,
  updateSingleThresholdFailure,
  updateSingleThresholdSuccess,
} from "./actions";
import { getFullRunData } from "../FullRunData/selectors";
import { backendApi } from "../Utils";
import {
  getActiveTargetData,
  getInactiveTargetData,
  getTargetData,
} from "./selectors";
import {
  calculateBackgroundCoexcitation,
  calculateBackgroundReprocessThreshold,
} from "./helpers";

export function* callOnUpdatePaths() {
  yield put(hideChart());
}

export function* watchForUpdatePaths() {
  yield takeLatest([UPDATE_PATHS], callOnUpdatePaths);
}

export function* callOnChartUpdate() {
  yield put(updateMetrics());
  yield put(updateEndRfuMetrics());
  yield put(updateAxes());
}

export function* watchForChartUpdate() {
  yield takeLatest(
    [
      t.CHART_SHOW_SINGLE_TARGET,
      t.CHART_HIDE_SINGLE_TARGET,
      t.CHART_TOGGLE_ALL_RUN_TARGETS,
      t.CHART_ACTIVATE_RUN_BLOCK,
      t.CHART_DEACTIVATE_RUN_BLOCK,
      t.CHART_SHOW_RUN_THRESHOLDS,
      t.CHART_HIDE_RUN_THRESHOLDS,
      t.UPDATE_SINGLE_THRESHOLD_SUCCESS,
      t.UPDATE_LINE_THRESHOLD,
      t.SHOW_SINGLE_THRESHOLDS,
      t.CHART_APPLY_FILTERS_RUN,
      t.CHART_APPLY_FILTERS_MASTER,
      t.SHOW_BASELINE,
      t.SHOW_RAW,
      t.CHART_STORE_REPROCESSED_DATA,
    ],
    callOnChartUpdate
  );
}

export function* callOnBackgroundValueChange(action) {
  yield put(hideChart());

  const { values } = action;

  const fullRunData = yield select(getFullRunData);
  const inactiveTargets = yield select(getInactiveTargetData);
  const activeTargets = yield select(getActiveTargetData);
  const clonedFullRunData = cloneDeep(fullRunData);

  let reprocessingFailure = false;

  for (let i = 0; i < clonedFullRunData.length; i += 1) {
    if (!reprocessingFailure) {
      for (let j = 0; j < clonedFullRunData[i].targets.length; j += 1) {
        // if target is active, reprocess it
        const associatedChartTarget = activeTargets.find(x => {
          return (
            x.runId === clonedFullRunData[i].targets[j].runId &&
            x.channel === clonedFullRunData[i].targets[j].emissionColor &&
            x.well === clonedFullRunData[i].targets[j].wellNumber
          );
        });

        if (associatedChartTarget) {
          const { percentage, referenceData } = yield call(
            calculateBackgroundCoexcitation,
            clonedFullRunData[i],
            clonedFullRunData[i].targets[j]
          );

          const reprocessThreshold = yield call(
            calculateBackgroundReprocessThreshold,
            associatedChartTarget
          );

          yield put(
            reprocessTargetRequest({
              rawData: JSON.stringify(clonedFullRunData[i].targets[j].rawData),
              leftVal: values[0] - 1,
              rightVal: values[1] - 1,
              threshold: reprocessThreshold,
              percentage,
              referenceData,
            })
          );

          const { targetReprocessSuccess } = yield race({
            targetReprocessSuccess: take(t.CHART_REPROCESS_TARGET_SUCCESS),
            targetReprocessFailure: take(t.CHART_REPROCESS_TARGET_FAILURE),
          });

          if (targetReprocessSuccess) {
            const { reprocessedData } = targetReprocessSuccess;

            clonedFullRunData[i].targets[j] = {
              ...clonedFullRunData[i].targets[j],
              ...reprocessedData,
            };

            if (clonedFullRunData[i].targets[j].CQ) {
              clonedFullRunData[i].targets[j].CQ += 1;
            }
          } else {
            yield put(updateChartBackgroundValuesError(true));
            yield put(startBackgroundResetSequence());
            reprocessingFailure = true;
            break;
          }
        } else {
          const associatedInactiveTarget = inactiveTargets.find(x => {
            return (
              x.runId === clonedFullRunData[i].targets[j].runId &&
              x.channel === clonedFullRunData[i].targets[j].emissionColor &&
              x.well === clonedFullRunData[i].targets[j].wellNumber
            );
          });

          if (associatedInactiveTarget) {
            clonedFullRunData[i].targets[j] = {
              ...clonedFullRunData[i].targets[j],
              ...associatedInactiveTarget,
            };
          }
        }
      }
    } else {
      break;
    }
  }

  if (!reprocessingFailure) {
    yield put(updateChartBackgroundValuesError(false));
    yield put(storeReprocessedData(clonedFullRunData));
  }

  yield put(showChart());
}

export function* watchForBackgroundValueChange() {
  yield takeLatest(
    t.CHART_UPDATE_BACKGROUND_VALUES,
    callOnBackgroundValueChange
  );
}

export function* callOnBackgroundReset() {
  const fullRunData = yield select(getFullRunData);

  yield put(resetBackgroundValues(fullRunData));
  yield put(showSingleThresholds());
}

export function* watchForBackgroundReset() {
  yield takeLatest(
    t.CHART_START_BACKGROUND_RESET_SEQUENCE,
    callOnBackgroundReset
  );
}

export function* handleReprocessTargetRequest(action) {
  const { rawData, leftVal, rightVal, threshold, percentage, referenceData } =
    action.targetData;

  try {
    const { data, status } = yield call(backendApi.post, "/algo/reprocess", {
      rawData,
      leftVal,
      rightVal,
      threshold,
      percentage,
      referenceData,
    });

    if (status === 200) {
      yield put(reprocessTargetSuccess(data));
    }
  } catch (e) {
    console.error(e);

    yield put(reprocessTargetFailure());
  }
}

export function* watchReprocessTargetRequest() {
  yield takeLatest(
    t.CHART_REPROCESS_TARGET_REQUEST,
    handleReprocessTargetRequest
  );
}

export function* handleUpdateSingleThresholdRequest(action) {
  const { thresholdData } = action;

  yield put(hideChart());

  const runsPageTargetData = yield select(getTargetData);
  const fullRunDataList = yield select(getFullRunData);

  try {
    const updatedRunsPageTargetData = [];
    for (let i = 0; i < runsPageTargetData.length; i += 1) {
      const initialTarget = runsPageTargetData[i];
      const updatedTarget = { ...initialTarget };

      if (
        initialTarget.singleThreshold !== thresholdData[initialTarget.channel]
      ) {
        updatedTarget.singleThreshold = +thresholdData[initialTarget.channel];

        if (
          updatedTarget.meltTemperatures &&
          updatedTarget.meltTemperatures.length > 1
        ) {
          updatedTarget.CQ = determinePeakTemperature(
            updatedTarget.baselineValues,
            updatedTarget.meltTemperatures
          );
        } else {
          const fullRunData = fullRunDataList.find(run => {
            return run.id === updatedTarget.runId;
          });
          const fullRunTarget = fullRunData.targets.find(target => {
            return target.id === updatedTarget.targetId;
          });

          const { percentage, referenceData } = yield call(
            calculateBackgroundCoexcitation,
            fullRunData,
            fullRunTarget
          );
          const leftVal = updatedTarget.leftVal ?? fullRunTarget.backgroundLeft;
          const rightVal =
            updatedTarget.rightVal ?? fullRunTarget.backgroundRight;

          yield put(
            reprocessTargetRequest({
              rawData: JSON.stringify(fullRunTarget.rawData),
              leftVal,
              rightVal,
              threshold: updatedTarget.singleThreshold,
              percentage,
              referenceData,
            })
          );

          const { targetReprocessSuccess } = yield race({
            targetReprocessSuccess: take(t.CHART_REPROCESS_TARGET_SUCCESS),
            targetReprocessFailure: take(t.CHART_REPROCESS_TARGET_FAILURE),
          });

          if (targetReprocessSuccess) {
            const { reprocessedData } = targetReprocessSuccess;

            updatedTarget.baselineValues = reprocessedData.baselineData;
            updatedTarget.CQ = reprocessedData.CQ;
            updatedTarget.threshold = reprocessedData.threshold;
            updatedTarget.endRfu = reprocessedData.endRfu;
          }
        }
      }

      updatedRunsPageTargetData.push(updatedTarget);
    }

    yield put(updateSingleThresholdSuccess(updatedRunsPageTargetData));
  } catch (e) {
    console.error(e);

    yield put(updateSingleThresholdFailure());
  }

  yield put(showChart());
}
export function* watchUpdateSingleThresholdRequest() {
  yield takeLatest(
    t.UPDATE_SINGLE_THRESHOLD_REQUEST,
    handleUpdateSingleThresholdRequest
  );
}

export default function* rootSaga() {
  yield all([
    watchForUpdatePaths(),
    watchForChartUpdate(),
    watchForBackgroundReset(),
    watchForBackgroundValueChange(),
    watchReprocessTargetRequest(),
    watchUpdateSingleThresholdRequest(),
  ]);
}
