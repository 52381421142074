import { head, uniqWith, uniq } from "lodash";

import * as t from "./actionTypes";

const initState = {
  retrieved: false,
  data: {},
  folders: [],
  availableFolders: [],
  activeFolderData: {},
  loading: false,
  showAddFolderModal: false,
};

function reducer(state = initState, action) {
  const { type } = action;

  switch (type) {
    case t.RESET_FOLDERS: {
      return initState;
    }

    case t.GET_FOLDERS_ERR: {
      return {
        ...state,
        loading: false,
      };
    }

    case t.GET_ROOT_FOLDERS: {
      return {
        ...state,
        loading: true,
      };
    }

    case t.GET_ROOT_SUCCESS: {
      let { folders } = action;

      if (!Array.isArray(folders)) {
        folders = [];
      }

      return {
        ...state,
        folders,
        loading: false,
      };
    }

    case t.GET_SELECTED_REQ: {
      const { folderId } = action;

      const originalFolder = head(
        state.folders.filter(obj => obj.id === folderId)
      );

      return {
        ...state,
        activeFolderData: originalFolder,
        loading: true,
      };
    }

    case t.GET_SELECTED_SUCCESS: {
      const { data, folderId } = action;
      const { activeFolderData = {} } = state;

      let prevRunData = null;
      let prevId = null;

      if (activeFolderData && activeFolderData.runData) {
        prevRunData = activeFolderData.runData;
      }

      if (activeFolderData && activeFolderData.id) {
        prevId = activeFolderData.id;
      }

      const childFolders = data.filter(obj => obj.kind === "folder");

      let newData = [...data];

      if (prevId === folderId && prevRunData) {
        newData = newData.concat([...prevRunData]);
      }

      newData = newData.filter(obj => obj.kind === "run");

      const newProtocolIds = uniq(
        newData.map(obj => {
          const { protocolId } = obj;

          return protocolId;
        })
      );

      const newDeviceIds = uniq(
        newData.map(obj => {
          const { device } = obj;

          return device;
        })
      );

      const originalFolder = head(
        state.folders.filter(obj => obj.id === folderId)
      );

      const updatedFolder = {
        ...originalFolder,
        runData: newData,
        childFolders,
        deviceIds: newDeviceIds,
        protocolIds: newProtocolIds,
      };

      return {
        ...state,
        folders: uniqWith(
          [...state.folders, updatedFolder, ...childFolders],
          (a, b) => a.id === b.id
        ),
        activeFolderData: updatedFolder,
        loading: false,
      };
    }

    case t.SHOW_ADD_FOLDER_MODAL: {
      return {
        ...state,
        showAddFolderModal: true,
      };
    }

    case t.HIDE_ADD_FOLDER_MODAL:
      return {
        ...state,
        showAddFolderModal: false,
      };

    case t.POST_FOLDER:
      return {
        ...state,
      };

    default: {
      return state;
    }
  }
}

export default reducer;
