import {
  BulkReviewControlsFilter,
  BulkReviewSamplesFilter,
} from "../../../types";
import { BulkReviewFilterOptionsMap } from "./types";

export const BULK_REVIEW_FILTER_OPTIONS: BulkReviewFilterOptionsMap = {
  positive: "Positive",
  negative: "Negative",
  inconclusive: "Inconclusive",
  controlPositive: "Positive Control",
  controlNegative: "Negative Control",
  noTemplateControl: "No Template Control",
};

export const SAMPLES_FILTER_OPTIONS: BulkReviewSamplesFilter[] = [
  "positive",
  "negative",
  "inconclusive",
];

export const CONTROLS_FILTER_OPTIONS: BulkReviewControlsFilter[] = [
  "controlPositive",
  "controlNegative",
  "noTemplateControl",
];
