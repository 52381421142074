import React from "react";
import { connect } from "react-redux";
import { isRawChartActive, isTabularDataActive } from "../../../selectors";
import AnalysisModeSection from "../AnalysisModeSection";
import BaselineButton from "../BaselineButton";
import LogViewToggle from "../LogViewToggle";
import RawButton from "../RawButton";
import TabularDataButton from "../TabularDataButton";

const GraphButtons = props => {
  const { rawChartIsActive, tabularDataIsActive } = props;

  const logToggleAbsenceBottomPadding = "20px";
  const logToggleAbsenceTopPadding = "18px";

  let graphButtonsStyle = {};

  if (rawChartIsActive || tabularDataIsActive) {
    graphButtonsStyle = {
      paddingTop: logToggleAbsenceTopPadding,
      paddingBottom: logToggleAbsenceBottomPadding,
    };
  }

  return (
    <div className="graph__buttons" style={graphButtonsStyle}>
      <AnalysisModeSection />
      <BaselineButton />
      <RawButton />
      <TabularDataButton />
      <LogViewToggle />
    </div>
  );
};

const mapStateToProps = state => ({
  tabularDataIsActive: isTabularDataActive(state),
  rawChartIsActive: isRawChartActive(state),
});

export default connect(mapStateToProps, null)(GraphButtons);
