import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Input, Button } from "bio-web-components";

import { UserDetails } from "../types";
import { IdToken } from "../../Models/Auth/index";
import SubscriptionBanner from "./SubscriptionBanner";
import { getJWTToken, getIDToken } from "../../Auth/selectors";
import * as selectors from "../selectors";
import { updateUserDetails, resetUserDetails } from "../actions";
import { version } from "../../Utils/git-version";

interface StateProps {
  userProfile: IdToken;
  updatedUserDetails: UserDetails;
  userUpdated: boolean;
  updateError: string;
  accessToken: string;
}

interface DispatchProps {
  updateUserDetails: (userDetails: UserDetails) => void;
  resetUserDetails: () => void;
}

interface Props extends StateProps, DispatchProps {}

const LeftPage: React.FunctionComponent<Props> = (props: Props) => {
  const [firstName, updateFirstName] = useState("");
  const [lastName, updateLastName] = useState("");
  const [previousPassword, updatePreviousPassword] = useState("");
  const [newPassword, updateNewPassword] = useState("");
  const [confirmNewPassword, updateConfirmNewPassword] = useState("");
  const [errorMessage, updateErrorMessage] = useState("");

  useEffect(() => {
    // Component Will Mount
    updateErrorMessage("");

    // Component Will Unmount
    return () => {
      const { resetUserDetails: unmountResetUserDetails } = props;
      unmountResetUserDetails();
    };

    // Empty array to behave like a class willMount/willUnmount
  }, []);

  const { userProfile, updatedUserDetails, userUpdated, updateError } = props;

  const updateBundler = () => {
    const { updateUserDetails: bundlerUpdateUserDetails, accessToken } = props;

    // FORM VALIDATION
    if (newPassword && (!confirmNewPassword || !previousPassword)) {
      return updateErrorMessage("Please complete all password fields.");
    }

    if (newPassword !== confirmNewPassword) {
      return updateErrorMessage("New password fields don't match.");
    }

    const passwordValidator =
      /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\][{}()_+-=|'])[\w!@#$%^&*\][{}()_+\-=|']{8,}$/;

    if (newPassword && !passwordValidator.test(newPassword)) {
      return updateErrorMessage(
        "Your password does not meet the requirements. Please ensure that it consists of at least eight characters, including one number and one uppercase, one lowercase, and one special character."
      );
    }

    // ONLY PROCEED IF SOMETHING HAS CHANGED
    if (!firstName && !lastName && !newPassword) {
      return updateErrorMessage("You haven't updated any information.");
    }

    let newFirstName = null;
    if (firstName) {
      newFirstName = firstName.trim();
    }

    let newLastName = null;
    if (lastName) {
      newLastName = lastName.trim();
    }

    let newerPassword = null;
    if (newPassword) {
      newerPassword = newPassword.trim();
    }

    let newPreviousPassword = null;
    if (previousPassword) {
      newPreviousPassword = previousPassword.trim();
    }

    const userDetails = {
      firstName: newFirstName,
      lastName: newLastName,
      newPassword: newerPassword,
      previousPassword: newPreviousPassword,
      accessToken,
    };

    bundlerUpdateUserDetails(userDetails);

    return updateErrorMessage("");
  };

  let currentPasswordInputClassname = "settings__input-field";
  if ((newPassword || confirmNewPassword) && !previousPassword) {
    currentPasswordInputClassname = "settings__input-field-invalid";
  }

  let newPasswordInputClassname = "settings__input-field";
  if (confirmNewPassword && !newPassword) {
    newPasswordInputClassname = "settings__input-field-invalid";
  }

  let confirmNewPasswordInputClassname = "settings__input-field";
  if (newPassword && !confirmNewPassword) {
    confirmNewPasswordInputClassname = "settings__input-field-invalid";
  }

  let errorMessageJSX = null;
  let userUpdateSuccessJSX = null;
  let userUpdateFailureJSX = null;

  if (userUpdated) {
    errorMessageJSX = null;
    userUpdateFailureJSX = null;
    userUpdateSuccessJSX = (
      <div className="settings__success-notification">Update Complete!</div>
    );
  }

  if (!userUpdated && updateError) {
    errorMessageJSX = null;
    userUpdateSuccessJSX = null;
    userUpdateFailureJSX = (
      <div className="settings__failure-notification">{`Update Failed: ${updateError}`}</div>
    );
  }

  if (errorMessage) {
    userUpdateSuccessJSX = null;
    userUpdateFailureJSX = null;
    errorMessageJSX = (
      <div className="settings__validation-warning">{errorMessage}</div>
    );
  }

  const _renderSettingsHeader = () => {
    return (
      <>
        <div className="settings__header">Settings</div>
        <h4 className="settings__header-text">
          Update your profile details and change your password.
        </h4>
      </>
    );
  };

  const _renderNameSection = () => {
    return (
      <div>
        <div className="settings__input-row">
          <div className="settings__input-segment">
            <Input
              label="First Name"
              inputClasses="settings__input-field"
              value={
                firstName ||
                updatedUserDetails.firstName ||
                userProfile.given_name
              }
              onChange={(value: string) => updateFirstName(value)}
              containerClasses="settings__input-container"
            />
          </div>
          <div className="settings__input-segment">
            <Input
              label="Last Name"
              inputClasses="settings__input-field"
              value={
                lastName ||
                updatedUserDetails.lastName ||
                userProfile.family_name
              }
              onChange={(value: string) => updateLastName(value)}
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderPasswordHeader = () => {
    return (
      <div className="settings__password-section">
        <hr className="settings__horizontal-rule" />
        <h4 className="settings__password-heading">
          <div className="settings__password-row">
            <div className="settings__section-label">Change your password</div>
          </div>
        </h4>
      </div>
    );
  };

  const _renderPasswordFooter = () => {
    return (
      <p className="settings__password-footer">
        {`Must contain at least eight characters, including one number and
        one uppercase, one lowercase, and one of the following characters: ! @ # $ % ^ & * ( ) _ + - = [ ] { } | '`}
      </p>
    );
  };

  const _renderPasswordSection = () => {
    return (
      <>
        {_renderPasswordHeader()}
        <div>
          <div className="settings__input-row">
            <div className="settings__input-segment">
              <Input
                label="Current Password"
                type="password"
                inputClasses={currentPasswordInputClassname}
                containerClasses="settings__input-container"
                value={previousPassword}
                onChange={(value: string) => updatePreviousPassword(value)}
              />
            </div>
          </div>
          <div className="settings__input-row">
            <div className="settings__input-segment">
              <Input
                label="New Password"
                type="password"
                inputClasses={newPasswordInputClassname}
                value={newPassword}
                onChange={(value: string) => updateNewPassword(value)}
                containerClasses="settings__input-container"
              />
            </div>
            <div className="settings__input-segment">
              <Input
                label="Confirm New Password"
                type="password"
                inputClasses={confirmNewPasswordInputClassname}
                value={confirmNewPassword}
                onChange={(value: string) => updateConfirmNewPassword(value)}
              />
            </div>
          </div>
        </div>
        {_renderPasswordFooter()}
      </>
    );
  };

  return (
    <div className="page__left">
      {_renderSettingsHeader()}
      <SubscriptionBanner />
      <hr className="settings__horizontal-rule" />
      {_renderNameSection()}
      {_renderPasswordSection()}
      <hr className="settings__horizontal-rule" />
      <div>
        {errorMessageJSX}
        {userUpdateSuccessJSX}
        {userUpdateFailureJSX}
      </div>
      <Button
        inverting
        title="Submit"
        buttonClass="still-questions"
        onClick={() => updateBundler()}
      />
      <div className="settings__version-text">{version}</div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userProfile: getIDToken(state),
    updatedUserDetails: selectors.getUserDetails(state),
    userUpdated: selectors.getUserUpdatedStatus(state),
    updateError: selectors.getSettingsError(state),
    accessToken: getJWTToken(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateUserDetails: (userDetails: UserDetails) =>
      dispatch(updateUserDetails(userDetails)),
    resetUserDetails: () => dispatch(resetUserDetails()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPage);
