import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import * as a from "../../actions";
import { openAnalysisModal as openAnalysisModalAction } from "../../../RunsPage/actions";

import { MAX_RUNS, MAX_ISOTHERMAL_RUNS } from "../../constants";
import { tooltipDelay, analysisModes } from "../../../RunsPage/constants";

import { getSidebarDepth } from "../../../Sidebar/selectors";
import {
  getActiveTab,
  getSelectedRuns,
  getPreSelectedFilters,
  isRunTypeMelt,
  isRunTypeIsothermal,
} from "../../selectors";

import Button from "../../../Layout/Button";

const itemMargins = { marginLeft: "30px" };
const itemNameMargins = { marginLeft: "-15px", marginRight: "-5px" };

export class SelectedRuns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedMorePopup: false,
    };

    this.type = "";
  }

  getTitle() {
    const { activeTab, selectedRuns, runTypeIsIsothermal } = this.props;

    let maximumRunCount = MAX_RUNS;

    if (runTypeIsIsothermal) {
      maximumRunCount = MAX_ISOTHERMAL_RUNS;
    }

    switch (activeTab) {
      case "Runs": {
        return (
          <div className="selectedRunsText">
            <span>SELECTED RUNS</span>
            <span>
              {selectedRuns.length}/{maximumRunCount}
            </span>
          </div>
        );
      }
      case "Protocols": {
        return "SELECTED FILTERS";
      }
      case "Users": {
        return "SELECTED FILTERS";
      }
      case "Thermocyclers": {
        return "SELECTED FILTERS";
      }
      default: {
        return "SELECTED";
      }
    }
  }

  getButtonText() {
    const { activeTab } = this.props;

    switch (activeTab) {
      case "Runs": {
        return "Chart View";
      }
      case "Protocols": {
        return "Apply Filters";
      }
      case "Users": {
        return "Apply Filters";
      }
      case "Thermocyclers": {
        return "Apply Filters";
      }
      default: {
        return "Chart View";
      }
    }
  }

  getButtonClass() {
    const { activeTab } = this.props;

    return activeTab !== "All Data" ? "filter" : null;
  }

  getListItems() {
    const { selectedRuns, removeRun } = this.props;
    const runs = selectedRuns.map(r => Object.assign({}, r));

    runs.forEach((r, i) => {
      let name = r.name.trim();
      if (name.length > 10) {
        name = this.nameCharLimiter(name);
      }
      runs[i].clippedName = name;
    });

    const maxPreviewNumber = this.getMaxPreviewNumber(runs);
    const additionalList = runs.slice(maxPreviewNumber);
    const fullListRuns = runs.slice(0, maxPreviewNumber);

    const items = this.getItems(fullListRuns, removeRun);

    if (additionalList.length > 0) {
      items.push(this.getAdditionalListRun(additionalList, removeRun));
    }

    return items;
  }

  getMaxPreviewNumber(items, buttonWidth = 200) {
    const maxWidth = 550 - buttonWidth;
    const itemPadding = 45;

    let bufferedWidth = 0;
    let maxRange = 0;
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      let itemWidth = 0;

      if (item.clippedName) {
        itemWidth = this.charWidthCalculator(item.clippedName) + itemWidth;
      }

      if (item.clippedDisplay) {
        itemWidth = this.charWidthCalculator(item.clippedDisplay) + itemWidth;
      }

      itemWidth += itemPadding;

      if (bufferedWidth + itemWidth < maxWidth) {
        bufferedWidth += itemWidth;
      } else {
        break;
      }
      maxRange += 1;
    }
    return maxRange;
  }

  getAdditionalListRun(additionalList, removeRun) {
    const { openedMorePopup } = this.state;

    return (
      <li className={this.getMorePopupClassName()} key="additional">
        <em onClick={() => this.toggleMorePopup()}>
          {additionalList.length} more...
        </em>
        <ul
          className="additionalListStyling"
          style={{
            display: openedMorePopup ? "flex" : "none",
          }}
        >
          {this.getItems(additionalList, removeRun)}
        </ul>
      </li>
    );
  }

  getItems = (runs, removeRun) => {
    return runs.map((run, i) => {
      const { id } = run;

      return (
        <li key={id} style={i === 0 ? {} : itemMargins}>
          <ReactTooltip delayShow={tooltipDelay / 2} />
          <p
            className="selected_item"
            data-tip={run.name}
            style={itemNameMargins}
          >
            {run.clippedName}
          </p>
          <span onClick={() => removeRun(run)} />
        </li>
      );
    });
  };

  getFilterListItems() {
    const { activeFilters, removeFilter } = this.props;
    const filters = activeFilters.map(f => Object.assign({}, f));

    filters.forEach((r, i) => {
      let display = r.display.trim();
      if (display.length > 10) {
        display = this.nameCharLimiter(display);
      }
      filters[i].clippedDisplay = display;
    });

    const maxPreviewNumber = this.getMaxPreviewNumber(filters, 200);
    const additionalList = filters.slice(maxPreviewNumber);
    const fullListRuns = filters.slice(0, maxPreviewNumber);

    const items = this.getFilterItems(fullListRuns, removeFilter);

    if (additionalList.length > 0) {
      items.push(this.getAdditionalListFilter(additionalList, removeFilter));
    }

    return items;
  }

  getAdditionalListFilter(additionalList, removeFilter) {
    const { openedMorePopup } = this.state;

    return (
      <li className={this.getMorePopupClassName()} key="additional">
        <em className="more" onClick={() => this.toggleMorePopup()}>
          {additionalList.length} more ...
        </em>
        <ul
          className="additionalListStyling"
          style={{
            display: openedMorePopup ? "flex" : "none",
          }}
        >
          {this.getFilterItems(additionalList, removeFilter)}
        </ul>
      </li>
    );
  }

  getMorePopupClassName() {
    const { openedMorePopup } = this.state;

    let morePopupClassName = "more";
    if (openedMorePopup) {
      morePopupClassName += " show";
    }
    return morePopupClassName;
  }

  handleChartNavigation = () => {
    const {
      runTypeIsMelt,
      openAnalysisModal,
      selectMultipleRuns,
      applyFilters,
    } = this.props;

    if (this.type === "data") {
      if (runTypeIsMelt) {
        selectMultipleRuns(analysisModes.singleThreshold);
      } else openAnalysisModal();
    } else applyFilters();
  };

  getFilterItems = (filters, removeFilter) => {
    return filters.map((filter, i) => {
      const { display, id } = filter;
      return (
        <li key={`${display}${id}`} style={i === 0 ? {} : itemMargins}>
          <ReactTooltip delayShow={tooltipDelay / 2} />
          <p style={itemNameMargins} data-tip={filter.display}>
            {filter.clippedDisplay}
          </p>
          <span onClick={() => removeFilter(filter)} />
        </li>
      );
    });
  };

  charWidthCalculator = name => {
    let char = 0;

    for (let i = 0; i < name.length; i += 1) {
      if (name[i] === ".") {
        char += 2;
      } else {
        char += 7;
      }
    }

    return char;
  };

  nameCharLimiter = itemName => {
    const name = itemName.split("");

    name.splice(7);
    name.push(".", ".", ".");

    return name.join("");
  };

  showBar() {
    const { selectedRuns, activeFilters } = this.props;
    let show = false;

    if (this.type === "data") {
      show = selectedRuns && selectedRuns.length !== 0;
    } else if (this.type === "filter") {
      show = activeFilters && activeFilters.length !== 0;
    }

    return show;
  }

  toggleMorePopup() {
    const { openedMorePopup } = this.state;

    this.setState({
      openedMorePopup: !openedMorePopup,
    });
  }

  renderList() {
    let list;

    if (this.type === "data") {
      list = this.getListItems();
    } else if (this.type === "filter") {
      list = this.getFilterListItems();
    }

    return <ul>{list}</ul>;
  }

  renderFooterData() {
    const { depth } = this.props;

    const centered = { margin: "0 auto" };
    const notCentered = {
      marginLeft: "405px",
      width: "1285px",
      maxWidth: "1285px",
    };

    return (
      <div
        className="filterSelectedRuns"
        style={depth > 0 ? centered : notCentered}
      >
        <div className="inner">
          <span>{this.getTitle()}</span>
          {this.renderList()}
          <Button
            title={this.getButtonText()}
            onClick={this.handleChartNavigation}
          />
        </div>
      </div>
    );
  }

  render() {
    const { location } = this.props;
    const { pathname } = location;

    if (!pathname.includes("/data")) {
      return null;
    }

    const { activeTab } = this.props;

    switch (activeTab) {
      case "Runs": {
        this.type = "data";
        break;
      }
      case "Protocols": {
        this.type = "filter";
        break;
      }
      case "Users": {
        this.type = "filter";
        break;
      }
      case "Thermocyclers": {
        this.type = "filter";
        break;
      }
      default:
    }

    return this.showBar() ? (
      <div className="footerDataContainer">
        <div style={{ width: "100%" }}>{this.renderFooterData()}</div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    activeTab: getActiveTab(state),
    selectedRuns: getSelectedRuns(state),
    activeFilters: getPreSelectedFilters(state),
    depth: getSidebarDepth(state),
    runTypeIsMelt: isRunTypeMelt(state),
    runTypeIsIsothermal: isRunTypeIsothermal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeRun: run => {
      dispatch(a.removeSelectedRun(run));
    },
    selectMultipleRuns: mode => {
      dispatch(a.selectMultipleRuns(mode));
    },
    removeFilter: filter => {
      dispatch(a.removePreSelectedFilter(filter));
    },
    applyFilters: () => {
      dispatch(a.applyFilters());
      dispatch(a.changeActiveTab("Runs"));
    },
    openAnalysisModal: () => {
      dispatch(openAnalysisModalAction());
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectedRuns)
);
