import React, { useEffect, Fragment } from "react";

import { FileInput } from "bio-web-components";

import { ChannelSelectionProps, FileProps } from "../types";

import BioradChannelSelectors from "./BioradChannelSelectors";
import { getBioradFileSelectionError } from "../helpers";

interface Props {
  fileProps: FileProps;
  channelSelectionProps: ChannelSelectionProps;
  setFileValidationError: (error: string | null) => void;
}

const BioradFileSelector = (props: Props) => {
  const FILE_ACCEPT = ".csv";

  const { fileProps, channelSelectionProps } = props;
  const { channelSelection, setChannelSelection } = channelSelectionProps;
  const { files } = fileProps;

  const { setFileValidationError } = props;

  useEffect(() => {
    setFileValidationError(
      getBioradFileSelectionError(files, channelSelection)
    );

    return () => setFileValidationError(null);
  }, [files, channelSelection]);

  useEffect(() => {
    setChannelSelection({});
  }, [files]);

  const handleFileSelection = (files: File[]) => {
    const { setFileValidationError } = props;
    const { setFiles } = fileProps;

    setFiles(files);
    setFileValidationError(
      getBioradFileSelectionError(files, channelSelection)
    );
  };

  const handleSetChannel = (value: string, channel: string) => {
    setChannelSelection({ ...channelSelection, [channel]: value });
  };

  const _renderChannelSelectors = () => {
    let fileSelector = null;
    if (files.length > 0) {
      fileSelector = (
        <BioradChannelSelectors
          files={files}
          setChannel={handleSetChannel}
          channelSelections={channelSelection}
        />
      );
    }

    return fileSelector;
  };

  return (
    <Fragment>
      <div className="upload_run_modal-upload-box">
        <span className="archive_form_upload-help-text">
          In the CFX Maestro software, access the Export menu. Click 'Export all
          Data Sheets' followed by 'CSV (*.csv)' Download the files to your
          machine and upload all of them here.
        </span>
        <FileInput
          onChange={handleFileSelection}
          accept={FILE_ACCEPT}
          multiple
        />
      </div>
      {_renderChannelSelectors()}
    </Fragment>
  );
};

export default BioradFileSelector;
