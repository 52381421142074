import React, { Component } from "react";
import QRCode from "qrcode";
import CloudLog from "../../Utils/CloudLog";

class QRCodeGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qrCodeURL: "",
    };
  }

  componentDidMount() {
    const { qrText } = this.props;

    if (qrText) {
      this.generateQR(qrText);
    }
  }

  componentDidUpdate(prevProps) {
    const { qrText } = this.props;

    if (prevProps.qrText !== qrText) {
      this.generateQR(qrText);
    }
  }

  generateQR = async text => {
    const { width = 200 } = this.props;

    if (text) {
      try {
        const url = await QRCode.toDataURL(text, {
          width,
          margin: 1,
        });
        this.setState({ qrCodeURL: url });
      } catch (err) {
        CloudLog.error(`Error in generateQR: ${err}`);
      }
    }
  };

  render() {
    const { qrCodeURL } = this.state;

    let qrCodeJSX = null;
    if (qrCodeURL) {
      qrCodeJSX = <img src={qrCodeURL} alt="Generated QR Code" />;
    }

    return <div>{qrCodeJSX}</div>;
  }
}

export default QRCodeGenerator;
