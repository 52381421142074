import * as t from "./actionTypes";

export function getFullRunData() {
  return {
    type: t.GET_FULL_RUN_REQ,
  };
}

export function getFullRunDataBulkReview() {
  return {
    type: t.GET_FULL_RUN_REQ_BULK_REVIEW,
  };
}

export function getFullRunError(e) {
  return {
    type: t.GET_FULL_RUN_ERROR,
    error: e,
  };
}

export function requestAdditionalDataForBulkReviewError(e) {
  return {
    type: t.GET_FULL_RUN_BULK_REVIEW_ERROR,
    error: e,
  };
}

export function getFullRunSuccess(payload) {
  return {
    type: t.GET_FULL_RUN_SUCCESS,
    payload,
  };
}

export function requestAdditionalDataForBulkReviewSuccess(
  payload,
  detailsVersion
) {
  return {
    type: t.GET_FULL_RUN_BULK_REVIEW_SUCCESS,
    payload,
    detailsVersion,
  };
}

export function validateSampleIdsSuccess(payload) {
  return {
    type: t.VALIDATE_SAMPLE_IDS_SUCCESS,
    payload,
  };
}

export function reset() {
  return {
    type: t.RESET,
  };
}
