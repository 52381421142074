import {
  INCONCLUSIVE,
  NEGATIVE_CONTROL,
  NO_TEMPLATE_CONTROL,
  POSITIVE_CONTROL,
} from "../../../../RunReview/constants";
import {
  BulkSamples,
  BulkTabularDataTarget,
} from "../../../../RunReview/types";
import { average } from "../../../../Utils/helpers";
import { INITIAL_TOTALS, INITIAL_TOTAL_FAILURES } from "./constants";
import { CombinedSummaryTotals, SummaryAverages } from "./types";

/**
 * Function for calculating the totals data
 * for populating the bulk review summary modals
 *
 * @param {BulkSamples[]} samples Sample data for bulk review
 * @return {CombinedSummaryTotals} { calculatedTotals, calculatedFailures }
 */
export const totalCalculations = (
  samples: BulkSamples[]
): CombinedSummaryTotals => {
  const calculatedTotals = { ...INITIAL_TOTALS };
  const calculatedFailures = { ...INITIAL_TOTAL_FAILURES };

  calculatedTotals.samples = samples.length;

  samples.forEach((sample: BulkSamples) => {
    const { sampleType, failureReason, overallResult } = sample;
    if (sampleType === POSITIVE_CONTROL) {
      calculatedTotals.positiveControls += 1;

      if (failureReason === `${POSITIVE_CONTROL} Control Failure`) {
        calculatedFailures.positiveControlFailures += 1;
      }
    }

    if (sampleType === NEGATIVE_CONTROL) {
      calculatedTotals.negativeControls += 1;

      if (failureReason === `${NEGATIVE_CONTROL} Control Failure`) {
        calculatedFailures.negativeControlFailures += 1;
      }
    }

    if (sampleType === NO_TEMPLATE_CONTROL) {
      calculatedTotals.noTemplateControls += 1;

      if (failureReason === `${NO_TEMPLATE_CONTROL} Control Failure`) {
        calculatedFailures.noTemplateControlFailures += 1;
      }
    }
    
    if (overallResult.toLowerCase() === INCONCLUSIVE) {
      calculatedFailures.inconclusive += 1;
    }
  });

  return { calculatedTotals, calculatedFailures };
};

/**
 * Function for calculating average statistics for the bulk review modal summary
 *
 * @param {BulkTabularDataTarget[]} targets Target data for bulk review
 * @return {SummaryAverages}
 */
export const averageCalculations = (
  targets: BulkTabularDataTarget[]
): SummaryAverages => {
  const ms2Values: number[] = [];
  const positiveControlOrf1abValues: number[] = [];
  const positiveControlMs2Values: number[] = [];
  const positiveControlSpikeValues: number[] = [];

  targets.forEach((target: BulkTabularDataTarget) => {
    const { CQ: cqString, channel, sampleType } = target;

    const cq = Number(cqString);

    if (cq) {
      if (channel === "green" && sampleType === POSITIVE_CONTROL) {
        positiveControlOrf1abValues.push(cq);
      }
      if (channel === "amber") {
        if (!sampleType) {
          ms2Values.push(cq);
        }
        if (sampleType === POSITIVE_CONTROL) {
          positiveControlMs2Values.push(cq);
        }
      }
      if (channel === "red" && sampleType === POSITIVE_CONTROL) {
        positiveControlSpikeValues.push(cq);
      }
    }
  });

  const calculatedAverages = {
    averageMS2: +average(ms2Values).toFixed(4),
    averagePositiveControlOrf1ab: +average(positiveControlOrf1abValues).toFixed(
      4
    ),
    averagePositiveControlMS2: +average(positiveControlMs2Values).toFixed(4),
    averagePositiveControlS: +average(positiveControlSpikeValues).toFixed(4),
  };

  return calculatedAverages;
};
