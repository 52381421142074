import React from "react";
import { connect } from "react-redux";

import { getFilterBarData } from "../../tableSelectors";
import StandardFilterList from "./standardFilterList";

export class FilterDropDown extends React.Component {
  getFilterList() {
    const { availableFilters } = this.props;

    return availableFilters.map(filter => {
      return (
        <StandardFilterList
          key={filter.name}
          name={filter.name}
          filters={filter.filters}
          cols={filter.cols}
        />
      );
    });
  }

  render() {
    return (
      <div className="filterDropDown">
        <div className="inner">
          <div className="filterLists">{this.getFilterList()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    availableFilters: getFilterBarData(state),
  };
}

export default connect(mapStateToProps)(FilterDropDown);
