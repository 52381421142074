import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";

import {
  setTemplateToEdit,
  setActiveSampleTemplate,
  setActiveSampleRecord,
  setToPrint,
  setToDelete,
} from "../actions";
import { getTableDisplay, getTemplates } from "../selectors";
import { headerKeyMap } from "../constants";
import { tooltipDelay } from "../../RunsPage/constants";
import Record from "./Record";
import Template from "./Template";

class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  triggerTemplateEdit = id => {
    const { putTemplateToEdit } = this.props;

    putTemplateToEdit(id);
  };

  triggerCreateRecord = id => {
    const { history, putActiveSampleTemplate } = this.props;

    putActiveSampleTemplate(id);
    history.push("/create-sample-qr-code");
  };

  handleRowHover = mouseEnter => {
    this.setState({
      hover: mouseEnter,
    });
  };

  handlePrintClick = id => {
    const { putToPrint, triggerPrint } = this.props;

    putToPrint(id);
    triggerPrint();
  };

  handleDuplicateClick = record => {
    const {
      putActiveSampleRecord,
      putActiveSampleTemplate,
      templates,
      history,
    } = this.props;

    putActiveSampleRecord(record);
    const recordTemplate = templates.find(
      template => record.templateName === template.name
    );
    putActiveSampleTemplate(recordTemplate.id);

    history.push("/create-sample-qr-code?duplicate=true");
  };

  handleDeleteClick = (id, type) => {
    const { triggerDelete, putToDelete } = this.props;

    putToDelete(id, type);
    triggerDelete();
  };

  _renderToolTip(index, key) {
    const { active, sampleInfo, fields } = this.props;

    let fontWeightValue = 400;
    if (key === "name") {
      fontWeightValue = 600;
    }

    let transformValue = null;
    if (active) {
      transformValue = "rotate(180deg) scaleX(-1)";
    }

    let tooltip = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontWeight: fontWeightValue }}>{sampleInfo[key]}</span>
        <img
          src="../../images/BackArrowNavy.svg"
          alt=""
          style={{
            transform: transformValue,
            marginRight: 20,
          }}
        />
      </div>
    );

    if (index < fields.length - 1) {
      tooltip = (
        <span style={{ fontWeight: fontWeightValue }}>{sampleInfo[key]}</span>
      );
    }

    return tooltip;
  }

  _renderRowColumns() {
    const { fields, sampleInfo, tableDisplay } = this.props;

    return fields.map((field, index) => {
      const key = headerKeyMap[field];

      let colSpan = "1";
      if (tableDisplay === "template" && key === "name") {
        colSpan = "2";
      }

      return (
        <td
          key={`${sampleInfo.id}-row-${field}-column`}
          style={{
            paddingLeft: "39px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          colSpan={colSpan}
          data-tip={sampleInfo[key]}
          data-for={`${sampleInfo.id}-row-${field}-column`}
        >
          <ReactTooltip
            id={`${sampleInfo.id}-row-${field}-column`}
            delayShow={tooltipDelay}
          />
          {this._renderToolTip(index, key)}
        </td>
      );
    });
  }

  _renderActiveRowContent() {
    const { sampleInfo, tableDisplay, active, templates } = this.props;

    let rowContent = (
      <Template
        template={sampleInfo}
        handleDelete={this.handleDeleteClick}
        handleEdit={this.triggerTemplateEdit}
        handleCreateRecord={this.triggerCreateRecord}
      />
    );

    if (tableDisplay === "record") {
      rowContent = (
        <Record
          record={sampleInfo}
          template={templates.find(
            template => template.name === sampleInfo.templateName
          )}
          handleDelete={this.handleDeleteClick}
          printClick={this.handlePrintClick}
          duplicateClick={this.handleDuplicateClick}
        />
      );
    }

    let tableRow = (
      <tr>
        <td>
          <div style={{ width: "800px" }}>{rowContent}</div>
        </td>
      </tr>
    );

    if (!active) {
      tableRow = null;
    }

    return tableRow;
  }

  render() {
    const { darkened, handleRowClick, sampleInfo, active, tableDisplay } =
      this.props;
    const { hover } = this.state;

    let className = "sampleTableRow";
    if (darkened) {
      className = "darkenedRow sampleTableRow";
    }

    let backgroundColorValue = null;
    if (active || hover) {
      backgroundColorValue = "#E8F8F9";
    }

    const rowStyle = {
      backgroundColor: backgroundColorValue,
    };

    return (
      <Fragment>
        <tr
          id={`${tableDisplay}_row_${sampleInfo.id}`}
          className={className}
          onClick={() => handleRowClick(sampleInfo.id)}
          style={rowStyle}
          onMouseEnter={() => this.handleRowHover(true)}
          onMouseLeave={() => this.handleRowHover(false)}
        >
          {this._renderRowColumns()}
        </tr>
        {this._renderActiveRowContent()}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    putTemplateToEdit: template => {
      dispatch(setTemplateToEdit(template));
    },
    putActiveSampleTemplate: template => {
      dispatch(setActiveSampleTemplate(template));
    },
    putActiveSampleRecord: record => {
      dispatch(setActiveSampleRecord(record));
    },
    putToPrint: id => {
      dispatch(setToPrint(id));
    },
    putToDelete: (id, type) => {
      dispatch(setToDelete(id, type));
    },
  };
}

function mapStateToProps(state) {
  return {
    tableDisplay: getTableDisplay(state),
    templates: getTemplates(state),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableRow)
);
