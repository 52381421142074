import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import CqMetrics from "./CqMetrics";
import EndRfuMetrics from "./EndRfuMetrics";

import { getEndRfuMetrics, getMetrics } from "../../../selectors";
import { getMetricsLabel } from "../../../../Hub/selectors";
import { Metrics } from "../../../types";

interface StateProps {
  metrics: Metrics;
  metricsLabel: string;
  endRfuMetrics: Metrics;
}

interface Props extends RouteComponentProps<any>, StateProps {}

const MetricsBlock: React.FunctionComponent<any> = (props: Props) => {
  const { metrics, metricsLabel, endRfuMetrics } = props;

  return (
    <div className="metrics__block">
      <CqMetrics metrics={metrics} metricsLabel={metricsLabel} />
      <EndRfuMetrics endRfuMetrics={endRfuMetrics} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    metrics: getMetrics(state),
    metricsLabel: getMetricsLabel(state),
    endRfuMetrics: getEndRfuMetrics(state),
  };
};

export default connect(mapStateToProps, null)(MetricsBlock);
