import React from "react";

interface Props {
  inconclusive: number;
  positiveControlFailures: number;
  negativeControlFailures: number;
  noTemplateControlFailures: number;
}

const BulkReviewSummaryModalTotalFailures: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    inconclusive,
    positiveControlFailures,
    negativeControlFailures,
    noTemplateControlFailures,
  } = props;

  return (
    <div className="bulk-review-summary__total-failures-container">
      <span className="section-header" id="total-failures-header">
        TOTAL FAILURES
      </span>
      <table>
        <tbody>
          <tr
            className="bulk-review-summary__row-darkened"
            id="inconclusive-row"
          >
            <th>Inconclusive</th>
            <td>{inconclusive}</td>
          </tr>
          <tr
            className="bulk-review-summary__row"
            id="positive-control-failures-row"
          >
            <th>Positive Control Failures</th>
            <td>{positiveControlFailures}</td>
          </tr>
          <tr
            className="bulk-review-summary__row-darkened"
            id="negative-control-failures-row"
          >
            <th>Negative Control Failures</th>
            <td>{negativeControlFailures}</td>
          </tr>
          <tr className="bulk-review-summary__row" id="ntc-failures-row">
            <th>No Template Control Failures</th>
            <td>{noTemplateControlFailures}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BulkReviewSummaryModalTotalFailures;
