import * as t from "./actionTypes";

export function reset() {
  return {
    type: t.LOGO_RESET,
  };
}

export function postFeature(feature) {
  return {
    type: t.FEATURE_POST,
    feature,
  };
}

export function setAppIsMobile(isMobile) {
  return {
    type: t.SET_APP_IS_MOBILE,
    isMobile,
  };
}

export function closeAnnouncementsModal() {
  return {
    type: t.HIDE_ANNOUNCEMENTS_MODAL,
  };
}

export function openAnnouncementsModal(data) {
  return {
    type: t.SHOW_ANNOUNCEMENTS_MODAL,
    data,
  };
}

export function checkForActiveAnnouncements() {
  return {
    type: t.CHECK_ACTIVE_ANNOUNCEMENTS,
  };
}

export function superReset() {
  return {
    type: t.SUPER_RESET,
  };
}
