import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { Button } from "bio-web-components";
import { getBulkReviewSubmissionStatus } from "../../../../../../RunReview/selectors";
import { BulkOverallSubmissionResult } from "../../../../../types";
import history from "../../../../../../store/history";
import { BulkReviewSubmissionStatus } from "../../../../../../RunReview/types";
import * as styles from "./styles";
import BulkReviewSubmissionEntry from "../BulkReviewSubmissionEntry";
import { superReset } from "../../../../../../App/actions";
import { reset as fullRunDataReset } from "../../../../../../FullRunData/actions";
import { bulkReviewReset } from "../../../../../../RunReview/actions";

interface OwnProps {
  overallSubmissionResult: BulkOverallSubmissionResult;
}

interface DispatchProps {
  resetApp: () => void;
}

interface StateProps {
  submissionStatuses: BulkReviewSubmissionStatus[];
}

type Props = StateProps & DispatchProps & OwnProps;

export const BulkReviewSubmissionIndicator: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { overallSubmissionResult } = props;

  const renderSubmissionStatus = () => {
    const { submissionStatuses } = props;

    let submissionStatus = null;

    if (submissionStatuses) {
      submissionStatus = submissionStatuses.map(status => {
        const { submissionSuccessful, name, id } = status;

        return (
          <div key={id} style={styles.bulkSubmissionBlock}>
            <BulkReviewSubmissionEntry
              name={name}
              id={id}
              submissionSuccessful={submissionSuccessful}
            />
          </div>
        );
      });
    }

    return (
      <div
        className="bulk_review__sidebar_submission_entries"
        style={styles.submissionStatuses}
      >
        <h1 style={styles.submissionHeader}>Submission Status</h1>
        {submissionStatus}
      </div>
    );
  };

  const renderSubmissionStatusOverall = () => {
    const { resetApp } = props;
    const { message, result } = overallSubmissionResult;

    let overallStatusMessage = <p style={styles.overallResult} />;
    let isDisabled = true;

    if (result === "reject") {
      overallStatusMessage = <p style={styles.overallFailure}>{message}</p>;
      isDisabled = false;
    } else if (overallSubmissionResult.result === "resolve") {
      overallStatusMessage = <p style={styles.overallSuccess}>{message}</p>;
      isDisabled = false;
    }

    return (
      <div
        className="bulk_review__sidebar_submission_overall"
        style={styles.submissionOverallContainer}
      >
        {overallStatusMessage}
        <Button
          title="Back to Review Dashboard"
          onClick={() => {
            resetApp();
            history.push("review-dashboard");
          }}
          inverting
          disabled={isDisabled}
        />
      </div>
    );
  };

  return (
    <>
      {renderSubmissionStatus()}
      {renderSubmissionStatusOverall()}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    submissionStatuses: getBulkReviewSubmissionStatus(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    resetApp: () => {
      dispatch(superReset());
      dispatch(bulkReviewReset());
      dispatch(fullRunDataReset());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkReviewSubmissionIndicator);
