import React, { Component } from "react";
import { connect } from "react-redux";
import { uniq } from "lodash";

import { extractSampleNames } from "../../helpers";
import { closePrintModal as actionClosePrintModal } from "../../actions";
import {
  getWellFilters,
  getChannelNames,
  isPrintModalActive,
  getRunBlocks,
  getTargetData,
} from "../../selectors";
import { getActiveTeamVersion } from "../../../Auth/selectors";
import { getFullRunData } from "../../../FullRunData/selectors";

import Chart from "./printViewChart";
import Modal from "../../../Layout/Modal";
import { UNSPECIFIED_SAMPLE_TEXT } from "../../constants";

class PrintModal extends Component {
  componentDidMount() {
    const { closePrintModal } = this.props;
    setTimeout(() => {
      window.print();
    }, 500);
    window.addEventListener("afterprint", closePrintModal);
  }

  componentWillUnmount() {
    const { closePrintModal } = this.props;
    window.removeEventListener("afterprint", closePrintModal);
  }

  _renderSampleNames(model) {
    const { test, version } = this.props;
    const sampleNames = extractSampleNames(test, version);

    let flexWrapStyle = "wrap";
    let widthStyle = "90%";
    if (model) {
      flexWrapStyle = "nowrap";
      widthStyle = "100%";
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: "23px",
          flexWrap: flexWrapStyle,
          width: widthStyle,
        }}
      >
        {uniq(sampleNames).map(sample => {
          let sampleColor = "black";
          if (sample === UNSPECIFIED_SAMPLE_TEXT) {
            sampleColor = "#03283B";
          }
          const { sampleId, wellNumber } = sample;

          return (
            <div
              key={`div well ${wellNumber} sample ${sampleId}`}
              style={{
                paddingRight: "20px",
                color: sampleColor,
              }}
            >
              {sample.sampleId}
            </div>
          );
        })}
      </div>
    );
  }

  _renderChannelRow(channel) {
    const { totalWells } = this.props;
    const cells = [];

    totalWells.forEach((well, i) => {
      cells.push(this._renderCell(i, channel));
    });

    return cells;
  }

  _renderCell(wellIndex, channel) {
    const { targets } = this.props;
    const targetData = targets.find(
      target => target.well === wellIndex && target.channel === channel
    );

    if (targetData) {
      let cqValue = 0;
      if (targetData.CQ) {
        cqValue = targetData.CQ.toFixed(2);
      }

      return (
        <td key={`td well ${wellIndex} channel ${channel}`} className="">
          <span className="target-name">{targetData.targetName}</span>
          <span className="cq">Cq: {cqValue}</span>
          <span className="cq">{targetData.sampleId}</span>
        </td>
      );
    }

    return (
      <td className="">
        <span className="target-name"> </span>
        <span className="cq"> </span>
      </td>
    );
  }

  _renderChannels() {
    const { totalChannels } = this.props;

    const model = totalChannels.length < 3;

    let paddingRightContainer = "0";
    let tableClassName = "printThree9Runtable";
    let paddingRightTd = "30px";
    if (model) {
      paddingRightContainer = "30px";
      tableClassName = "printRuntable";
      paddingRightTd = "0";
    }

    return (
      <div style={{ paddingRight: paddingRightContainer }}>
        <table className={tableClassName}>
          <tbody>
            {totalChannels.map(channel => (
              <tr key={`tr ${channel}`}>
                <td
                  className="well-data"
                  key={`td ${channel}`}
                  style={{ paddingRight: paddingRightTd }}
                >
                  <span className={channel.slice(0, 1).toUpperCase()}>
                    {channel.slice(0, 1).toUpperCase()}
                  </span>
                </td>
                {this._renderChannelRow(channel)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  _renderSamples() {
    const { totalChannels, test } = this.props;

    const { protocol } = test;

    const model = totalChannels.length < 3;

    let paddingRightStyle = "30px";
    let paddingTopProtocolName = "33px";
    let paddingBottomProtocolName = "33px";
    let marginTopSamples = "-2px";
    let paddingTopSamples = "10px";
    let paddingBottomSamples = "30px";
    if (model) {
      paddingRightStyle = "0";
      paddingTopProtocolName = "0";
      paddingBottomProtocolName = "50px";
      marginTopSamples = "0px";
      paddingTopSamples = "6px";
      paddingBottomSamples = "20px";
    }

    return (
      <div className="print__flex" style={{ flexDirection: "column" }}>
        <div style={{ paddingRight: paddingRightStyle }}>
          <div
            className="print__protocol"
            style={{
              fontSize: "24px",
              marginTop: "-2px",
              paddingBottom: "30px",
              paddingTop: paddingTopProtocolName,
              paddingRight: paddingRightStyle,
            }}
          >
            PROTOCOL NAME
          </div>
          <div
            style={{
              fontSize: "23px",
              paddingBottom: paddingBottomProtocolName,
            }}
          >
            {protocol.name}
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: "24px",
              marginTop: marginTopSamples,
              paddingTop: paddingTopSamples,
              paddingBottom: paddingBottomSamples,
            }}
          >
            SAMPLES
          </div>
          {this._renderSampleNames(model)}
        </div>
      </div>
    );
  }

  _renderChannelSamples() {
    const { totalChannels } = this.props;
    const model = totalChannels.length < 3;

    let flexDirectionStyle = "column";
    if (model) {
      flexDirectionStyle = "row";
    }

    return (
      <div style={{ display: "flex", flexDirection: flexDirectionStyle }}>
        {this._renderChannels()}
        {this._renderSamples()}
      </div>
    );
  }

  _renderNotes() {
    const { test, totalChannels, version } = this.props;

    const { details = {} } = test;

    const model = totalChannels.length < 3;

    let { notes } = test;

    if (version !== "biomeme-2") {
      const { notes: detailsNotes } = details;

      notes = detailsNotes;
    }

    let paddingTopStyle = "20px";
    if (model) {
      paddingTopStyle = "10px";
    }

    return (
      <>
        <div
          style={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            paddingTop: paddingTopStyle,
          }}
        >
          <div style={{ marginTop: "9px" }}>
            <img
              src="../../../../images/notes.svg"
              height="20"
              width="20"
              alt=""
            />
          </div>
          <div
            style={{ paddingLeft: "7px", paddingTop: "1px", fontSize: "24px" }}
          >
            NOTES
          </div>
        </div>
        <div>{notes}</div>
      </>
    );
  }

  render() {
    const { test, show, runBlock, closePrintModal, version } = this.props;

    const { date, details = {} } = test;

    let { locationString } = test;

    if (version !== "biomeme-2") {
      const { locationString: detailsLocationString } = details;

      locationString = detailsLocationString;
    }

    return (
      <Modal
        open={show}
        onClose={() => closePrintModal()}
        className="modal--print_chart"
        bodyClass="modal__print"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "21px",
            paddingBottom: "10px",
          }}
        >
          <div>{runBlock.type.toUpperCase()}</div>
          <div style={{ fontSize: "21px" }}>{date.split("T")[0]}</div>
        </div>
        <div style={{ fontSize: "35px" }}>{test.name}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            paddingTop: "10px",
            paddingBottom: "20px",
          }}
        >
          <div style={{ marginTop: "4px" }}>
            <img
              src="../../../../images/location.svg"
              height="15"
              width="10"
              alt=""
            />
          </div>
          <div style={{ fontSize: "21px", paddingLeft: "2px" }}>
            {locationString}
          </div>
        </div>

        <div
          id="singleRunChart"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Chart />
        </div>
        {this._renderChannelSamples()}
        {this._renderNotes()}
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    totalChannels: getChannelNames(state),
    totalWells: getWellFilters(state).map(well => well.label),
    test: getFullRunData(state).find(run => run.id === ownProps.runId),
    runBlock: getRunBlocks(state).find(
      runBlock => runBlock.runId === ownProps.runId
    ),
    show: isPrintModalActive(state),
    targets: getTargetData(state).filter(
      target => target.runId === ownProps.runId
    ),
    version: getActiveTeamVersion(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closePrintModal: () => {
      dispatch(actionClosePrintModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintModal);
