import React from "react";
import TabRow from "./tabRow";

function FilterBarHeader() {
  return (
    <div className="filterBarHeader">
      <div className="inner">
        <TabRow />
      </div>
    </div>
  );
}

export default FilterBarHeader;
