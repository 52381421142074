import React from "react";
import { connect } from "react-redux";

import { getFullRunData } from "../../../FullRunData/selectors";
import { isSelectedRunBioRad } from "../../../Hub/selectors";
import { isReviewModeActive } from "../../selectors";

import SampleSelector from "./SampleSelector";
import BioRadSampleSelector from "./SampleSelector/BioRadSampleSelector";
import FilterTable from "./MasterControls";
import RunBlockControls from "./RunBlockControls";
import MetricsBlock from "./Metrics";
import Thresholds from "./Thresholds";

const ChartControls = props => {
  const renderSampleSelector = () => {
    const { selectedRunIsBiorad, reviewModeIsActive } = props;

    if (selectedRunIsBiorad) {
      return (
        <div className="sample_selector">
          <BioRadSampleSelector />
        </div>
      );
    }

    if (reviewModeIsActive) {
      return (
        <div className="sample_selector">
          <SampleSelector />
        </div>
      );
    }

    return null;
  };

  const renderMasterControls = () => {
    const { selectedRuns } = props;

    if (selectedRuns.length > 1) {
      return (
        <div className="master_controls">
          <FilterTable />
        </div>
      );
    }

    return null;
  };

  const renderMetrics = () => {
    const { reviewModeIsActive } = props;

    if (reviewModeIsActive) {
      return null;
    }

    return (
      <div className="metrics_block">
        <MetricsBlock />
      </div>
    );
  };

  const renderThresholds = () => (
    <div className="thresholds_block">
      <Thresholds />
    </div>
  );

  const renderRunBlockControls = () => <RunBlockControls />;

  return (
    <div className="c" style={{ marginLeft: "20px" }}>
      {renderSampleSelector()}
      {renderMasterControls()}
      {renderMetrics()}
      {renderThresholds()}
      {renderRunBlockControls()}
    </div>
  );
};

const mapStateToProps = state => ({
  selectedRuns: getFullRunData(state),
  selectedRunIsBiorad: isSelectedRunBioRad(state),
  reviewModeIsActive: isReviewModeActive(state),
});

export default connect(mapStateToProps, null)(ChartControls);
