import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button } from "bio-web-components";
import * as styles from "./styles";
import BulkRunBlocks from "./subcomponents/BulkRunBlocks";
import { bulkReviewApproveAllRequest } from "../../../../RunReview/actions";
import { BulkOverallSubmissionResult } from "../../../types";
import { defaultBulkOverallSubmissionResult } from "./constants";
import BulkReviewSubmissionIndicator from "./subcomponents/BulkReviewSubmissionIndicator";
import { getActiveBulkReviewRunBlocksIds } from "../../../../RunReview/selectors";

interface StateProps {
  activeRunBlockIds: string[];
}
interface DispatchProps {
  bulkApproveAllRunReviews: (
    resolve: (message: string) => void,
    reject: (message: string) => void
  ) => void;
}

type Props = StateProps & DispatchProps;

export const BulkSidebar: React.FunctionComponent<Props> = (props: Props) => {
  const [overallSubmissionResult, setOverallSubmissionResult] =
    useState<BulkOverallSubmissionResult>(defaultBulkOverallSubmissionResult);

  const [wasBulkReviewSubmitted, setWasBulkReviewSubmitted] =
    useState<boolean>(false);

  const resolve = (message: string) => {
    setOverallSubmissionResult({ message, result: "resolve" });
  };

  const reject = (message: string) => {
    setOverallSubmissionResult({ message, result: "reject" });
  };

  const handleApproveAllClick = () => {
    const { bulkApproveAllRunReviews } = props;

    setWasBulkReviewSubmitted(true);
    bulkApproveAllRunReviews(resolve, reject);
  };

  const renderReviewApproval = () => {
    const { activeRunBlockIds } = props;

    return (
      <>
        <div
          className="bulk_review__sidebar_bulk_run_blocks"
          style={styles.bulkReviewRunBlocksContainer}
        >
          <BulkRunBlocks />
        </div>
        <div
          className="bulk_review__sidebar_approve_button"
          style={styles.bulkReviewSidebarApproveContainer}
        >
          <Button
            title="Approve Selected Review(s)"
            onClick={() => {
              return handleApproveAllClick();
            }}
            inverting
            data-tip="Approves all selected reviews."
            disabled={activeRunBlockIds.length < 1}
          />
        </div>
      </>
    );
  };

  const renderSideBar = () => {
    let sidebar = renderReviewApproval();

    if (wasBulkReviewSubmitted) {
      sidebar = (
        <BulkReviewSubmissionIndicator
          overallSubmissionResult={overallSubmissionResult}
        />
      );
    }

    return sidebar;
  };

  return (
    <div className="bulk_review__sidebar" style={styles.bulkReviewSidebar}>
      {renderSideBar()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activeRunBlockIds: getActiveBulkReviewRunBlocksIds(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    bulkApproveAllRunReviews: (
      resolve: (message: string) => void,
      reject: (message: string) => void
    ) => {
      return dispatch(bulkReviewApproveAllRequest(resolve, reject));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkSidebar);
