import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  setSortBy,
  resetTabularData as resetTabularDataAction,
} from "../actions";
import {
  getToPrint,
  getToDelete,
  getSampleTabularData,
  getSortBy,
  getTableDisplay,
} from "../selectors";
import { headerKeyMap } from "../constants";
import { styles } from "../styles";
import TableRow from "../subcomponents/TableRow";
import DeleteModal from "../subcomponents/DeleteModal";
import PrintModal from "../subcomponents/PrintModal";
import PrintListView from "../subcomponents/PrintListView";
import PrintLabelView from "../subcomponents/PrintLabelView";

class SampleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDeleteModal: false,
      displayPrintItem: null,
      uuidDisplay: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { pathname: path } = location;

    let uuidToExpand = "";
    if (path.includes("/sample-management/")) {
      uuidToExpand = path.replace("/sample-management/", "").toUpperCase();
    }

    if (uuidToExpand.trim().length > 0) {
      this.setState({ ...this.state, uuidDisplay: uuidToExpand }, () => {
        const element = document.getElementById(uuidToExpand);
        element.scrollIntoView();
      });
    }
  }

  componentWillUnmount() {
    const { resetTabularData } = this.props;

    resetTabularData();
  }

  triggerDelete = () => {
    this.setState({
      displayDeleteModal: true,
    });
  };

  triggerPrint = () => {
    this.setState({
      ...this.state,
      displayPrintItem: "modal",
    });
  };

  printLabel = () => {
    this.setState({
      ...this.state,
      displayPrintItem: "label",
    });
  };

  printList = () => {
    this.setState({
      ...this.state,
      displayPrintItem: "list",
    });
  };

  sort = field => {
    const { putSortBy } = this.props;

    putSortBy(headerKeyMap[field]);
  };

  handleRowClick = id => {
    const { uuidDisplay } = this.state;

    let idDisplay = null;
    if (id !== uuidDisplay) {
      idDisplay = id;
    }

    this.setState({
      ...this.state,
      uuidDisplay: idDisplay,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      displayDeleteModal: false,
      displayPrintItem: null,
    });
  };

  calculateHeaderClassName = field => {
    const { tableDisplay, sortBy } = this.props;
    const activeSortObject = sortBy[tableDisplay];
    const sortField = Object.keys(activeSortObject)[0];

    if (headerKeyMap[field] === sortField) {
      if (activeSortObject[sortField] === 0) {
        return "sort-down";
      }

      return "sort-up";
    }

    return "";
  };

  renderHeaders = () => {
    const { fields } = this.props;

    return (
      <thead>
        <tr>
          {fields.map(field => (
            <Fragment key={field}>
              <th>
                <span
                  className={this.calculateHeaderClassName(field)}
                  onClick={() => this.sort(field)}
                >
                  {field}
                </span>
              </th>
              {field === "LAYOUT NAME" && <th />}
            </Fragment>
          ))}
        </tr>
      </thead>
    );
  };

  renderModals = () => {
    const { displayDeleteModal, displayPrintItem } = this.state;
    const { toDelete, toPrint } = this.props;

    let deleteName = null;
    let deleteId = null;
    let deleteType = null;
    if (toDelete) {
      deleteName = toDelete.name;
      deleteId = toDelete.id;
      deleteType = toDelete.itemType;
    }

    return (
      <Fragment>
        {displayDeleteModal && (
          <DeleteModal
            openModal={displayDeleteModal}
            closeModal={this.closeModal}
            name={deleteName}
            id={deleteId}
            type={deleteType}
          />
        )}
        {displayPrintItem === "modal" && (
          <PrintModal
            openModal={displayPrintItem === "modal"}
            closeModal={this.closeModal}
            onPrint={this.displayPrintView}
            printLabel={this.printLabel}
            printList={this.printList}
          />
        )}

        {displayPrintItem === "list" && (
          <PrintListView
            openModal={displayPrintItem === "list"}
            record={toPrint}
            closePrintModal={this.closeModal}
          />
        )}
        {displayPrintItem === "label" && (
          <PrintLabelView
            openModal={displayPrintItem === "label"}
            record={toPrint}
            closePrintModal={this.closeModal}
          />
        )}
      </Fragment>
    );
  };

  renderTableRow(sampleInfo, i) {
    const { fields } = this.props;
    const { uuidDisplay } = this.state;

    return (
      <TableRow
        key={`table_row_${i}`}
        darkened={i % 2 === 0}
        sampleInfo={sampleInfo}
        fields={fields}
        active={uuidDisplay === sampleInfo.id}
        handleRowClick={this.handleRowClick}
        triggerDelete={this.triggerDelete}
        triggerPrint={this.triggerPrint}
      />
    );
  }

  render() {
    const { tabularData } = this.props;

    return (
      <div style={styles.container}>
        {this.renderModals()}
        <table className="sample-hub__table">
          {this.renderHeaders()}
          <tbody>
            {tabularData.map((record, i) => {
              return this.renderTableRow(record, i);
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    putSortBy: field => {
      dispatch(setSortBy(field));
    },
    resetTabularData: () => {
      dispatch(resetTabularDataAction());
    },
  };
}

function mapStateToProps(state) {
  return {
    toDelete: getToDelete(state),
    toPrint: getToPrint(state),
    tabularData: getSampleTabularData(state),
    sortBy: getSortBy(state),
    tableDisplay: getTableDisplay(state),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SampleView)
);
