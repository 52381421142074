import React from "react";
import { connect } from "react-redux";

import { getActiveTeamId } from "../../Auth/selectors";
import { getTemplateToEdit } from "../selectors";
import { editTemplate as actionEditTemplate } from "../actions";

import SampleLayoutTable from "./Table";

import Sidebar from "../../Sidebar";
import { Content } from "../../DataRoute/components/content";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";
import BackButton from "../subcomponents/BackButton";

const EditSampleTemplate = props => {
  const renderTable = () => {
    const { templateToEdit, activeTeamId } = props;

    const updateTemplate = template => {
      const { editTemplate } = props;

      editTemplate(templateToEdit.id, template);
    };

    return (
      <div className="page">
        <div
          className="page__container"
          style={{ minWidth: "1000px", height: "100%" }}
        >
          <div className="page__left">
            <BackButton />
            <p
              style={{
                fontSize: "48px",
                fontWeight: "600",
                fontFamily: "Roboto",
                marginTop: "15px",
              }}
            >
              Edit Sample Layout
            </p>
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                color: "black",
              }}
            >
              {null}
            </div>
            <hr
              className="settings__horizontal-rule"
              style={{ minWidth: "1000px" }}
            />
            <SampleLayoutTable
              initialTemplateData={templateToEdit}
              uploadTemplate={updateTemplate}
              activeTeamId={activeTeamId}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fluidContainers">
      <AppUpdateModal />
      <Sidebar />
      <Content>{renderTable()}</Content>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    templateToEdit: getTemplateToEdit(state),
    activeTeamId: getActiveTeamId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editTemplate: (id, template) => {
      dispatch(actionEditTemplate(id, template));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSampleTemplate);
