export const GET_FULL_RUN_REQ = "fullRunData/GET";
export const GET_FULL_RUN_REQ_BULK_REVIEW = "fullRunData/GET_BULK_REVIEW";
export const GET_FULL_RUN_SUCCESS = "fullRunData/GET_SUCCESS";
export const GET_FULL_RUN_BULK_REVIEW_SUCCESS =
  "fullRunData/GET_FULL_RUN_BULK_REVIEW_SUCCESS";
export const VALIDATE_SAMPLE_IDS_SUCCESS =
  "fullRunData/VALIDATE_SAMPLE_IDS_SUCCESS";
export const GET_FULL_RUN_ERROR = "fullRunData/GET_ERROR";
export const GET_FULL_RUN_BULK_REVIEW_ERROR =
  "fullRunData/GET_FULL_RUN_BULK_REVIEW_ERROR";
export const RESET = "fullRunData/RESET";
