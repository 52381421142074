import React, { Component } from "react";
import { Button } from "bio-web-components";

import { BIORAD_WELL_IDENTIFIERS } from "../../../../constants";
import { reformTargetMatrix } from "../../../../helpers";
import styles from "../styles";

class BioRadTable extends Component {
  handleApply = () => {
    const { targetMatrix, applyMasterFilters, runId, colorFilter } = this.props;

    const reformedTargetMatrix = reformTargetMatrix(targetMatrix, colorFilter);

    applyMasterFilters(reformedTargetMatrix, runId);
  };

  // eslint-disable-next-line consistent-return
  handleCellToggle = (targetData, well) => {
    const { targetMatrix, setTargetMatrix } = this.props;

    const indexOfTargetMatrixElement = targetMatrix.findIndex(
      element => element.well === well
    );

    if (indexOfTargetMatrixElement < 0) {
      return null;
    }

    const newTargetMatrix = [...targetMatrix];

    const newTargetElement = {
      ...targetMatrix[indexOfTargetMatrixElement],
      active: !targetData.active,
    };

    newTargetMatrix.splice(indexOfTargetMatrixElement, 1, newTargetElement);

    setTargetMatrix(newTargetMatrix);
  };

  handleWellToggle = (associatedWells, enabled) => {
    const { targetMatrix, setTargetMatrix } = this.props;

    const newTargetMatrix = targetMatrix.map(element => {
      if (associatedWells.includes(element.well)) {
        return {
          ...element,
          active: !enabled,
        };
      }

      return element;
    });

    setTargetMatrix(newTargetMatrix);
  };

  handleAllToggle = allTargetsAreActive => {
    const { targetMatrix, setTargetMatrix } = this.props;

    const newTargetMatrix = targetMatrix.map(element => {
      return {
        ...element,
        active: !allTargetsAreActive,
      };
    });

    setTargetMatrix(newTargetMatrix);
  };

  renderChannelRow(associatedWells) {
    return associatedWells.map(well => this.renderCell(well));
  }

  renderCell(well) {
    const { targetMatrix } = this.props;

    const targetData = targetMatrix.find(element => element.well === well);

    const key = `td-masterTable-well-${targetData.well}`;

    if (!targetData || targetData.hidden) {
      return <td className="disabled" key={`${key}-disabled`} />;
    }

    let className = "";

    if (targetData.active) {
      className = "active";
    }

    return (
      <td
        key={key}
        id={`td-masterTable-well-${targetData.well}`}
        onClick={() => this.handleCellToggle(targetData, well)}
        className={className}
      />
    );
  }

  renderWellFilters = filterType => {
    const { targetMatrix } = this.props;
    const { numbers: wellNumbers, letters: wellLetters } =
      BIORAD_WELL_IDENTIFIERS;

    const filterTypeIsRow = filterType === "row";

    let primaryIdentifiers;
    let secondaryIdentifiers;

    if (filterTypeIsRow) {
      primaryIdentifiers = wellNumbers;
      secondaryIdentifiers = wellLetters;
    } else {
      primaryIdentifiers = wellLetters;
      secondaryIdentifiers = wellNumbers;
    }

    return primaryIdentifiers.map(primaryIdentifier => {
      const associatedWells = secondaryIdentifiers.map(secondaryIdentifier => {
        if (filterTypeIsRow) {
          return `${secondaryIdentifier}${primaryIdentifier}`;
        }

        return `${primaryIdentifier}${secondaryIdentifier}`;
      });

      let tdClassName = "active";
      let spanClassName = "";

      const associatedElementThatIsNotHidden = targetMatrix.find(
        element => associatedWells.includes(element.well) && !element.hidden
      );

      if (!associatedElementThatIsNotHidden) {
        tdClassName = "disabled";
        spanClassName = "disabled";
      } else {
        const associatedElementThatIsInactive = targetMatrix.find(
          element => associatedWells.includes(element.well) && !element.active
        );

        if (associatedElementThatIsInactive) {
          tdClassName = "";
        }
      }

      const enabled = tdClassName === "active";
      const keyBase = `${filterType}-${primaryIdentifier}`;

      if (filterTypeIsRow) {
        return (
          <td
            key={`td-masterFilter-number-${primaryIdentifier}`}
            className={tdClassName}
            onClick={() => this.handleWellToggle(associatedWells, enabled)}
          >
            <span
              id={`td-masterFilter-number-${primaryIdentifier}`}
              className={spanClassName}
            >
              {primaryIdentifier}
            </span>
          </td>
        );
      }

      return (
        <tr key={`tr-${keyBase}`}>
          <td
            key={`td-masterFilter-letter-${primaryIdentifier}`}
            className={tdClassName}
            onClick={() => this.handleWellToggle(associatedWells, enabled)}
          >
            <span
              id={`td-masterFilter-letter-${primaryIdentifier}`}
              className={spanClassName}
            >
              {primaryIdentifier}
            </span>
          </td>
          {this.renderChannelRow(associatedWells)}
        </tr>
      );
    });
  };

  renderTopRow() {
    const { targetMatrix } = this.props;

    const inactiveTarget = targetMatrix.find(
      element => !element.hidden && !element.active
    );

    let allTargetsAreActive = true;
    if (inactiveTarget) {
      allTargetsAreActive = false;
    }

    let allButtonClassName = "";
    if (allTargetsAreActive) {
      allButtonClassName = "active";
    }

    return (
      <tr key="All Channels">
        <td
          className={allButtonClassName}
          onClick={() => this.handleAllToggle(allTargetsAreActive)}
        >
          <span id="td-masterFilter-All">All</span>
        </td>
        {this.renderWellFilters("row")}
      </tr>
    );
  }

  renderApplyButton() {
    return (
      <Button
        contentClass="masterFilter__apply-button"
        inverting
        title="Apply"
        onClick={this.handleApply}
      />
    );
  }

  render() {
    const { key, runId } = this.props;

    return (
      <div style={styles.runTableContainer} key={key}>
        <table
          className="runtable"
          id={`master-table-${runId}`}
          style={{ width: "100%", height: "auto" }}
        >
          <tbody>
            {this.renderTopRow()}
            {this.renderWellFilters("column")}
          </tbody>
        </table>
        {this.renderApplyButton()}
      </div>
    );
  }
}

export default BioRadTable;
