import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getRootFolders } from "../../../Folders/actions";
import {
  selectFolder as selectFolderAction,
  resetSidebar as resetSidebarAction,
} from "../../../Sidebar/actions";
import { getCurrentPaths, getShowChart } from "../../selectors";
import { getSidebarDepth } from "../../../Sidebar/selectors";
import { getParentFolder } from "../../../Folders/selectors";

export class BreadCrumbs extends React.Component {
  _selectOption = f => {
    const { selectFolder } = this.props;

    selectFolder(f);
  };

  _renderBack = () => {
    const { resetSidebar } = this.props;

    return (
      <Link
        to="/data"
        className="back"
        data-tip="Exit the chart and return to the homescreen."
        onClick={() => resetSidebar()}
      >
        Home
      </Link>
    );
  };

  _renderFolderCrumb = (folder, i) => {
    const { name } = folder;

    let folderClass = "";
    if (i === 0) {
      folderClass = "folder_back";
    }

    return (
      <React.Fragment key={folder.id}>
        {">"}
        <li onClick={() => this._selectOption(folder)}>
          <Link to="/data" data-place="bottom" className={folderClass}>
            {name}
          </Link>
        </li>
      </React.Fragment>
    );
  };

  _renderDashboardCrumb = () => (
    <React.Fragment key="review-dashboard">
      {">"}
      <li>
        <Link to="/review-dashboard" data-place="bottom" className="">
          Review Dashboard
        </Link>
      </li>
    </React.Fragment>
  );

  _renderBulkReviewCrumb = () => (
    <React.Fragment key="bulk-review">
      {">"}
      <li>
        <Link to="/review-dashboard" data-place="bottom" className="">
          BulkReview
        </Link>
      </li>
    </React.Fragment>
  );

  render() {
    const { paths = [], chartIsDisplayed } = this.props;

    let breadcrumbs = (
      <div className="breadcrumbs" style={{ marginBottom: "40px" }} />
    );

    if (paths.length > 0) {
      breadcrumbs = (
        <div className="breadcrumbs">
          {this._renderBack()}
          <ul>
            {paths.map((item, i) => {
              if (item.type === "folder" || item.kind === "folder") {
                return this._renderFolderCrumb(item, i);
              }

              if (item.type === "bulkReview") {
                return this._renderBulkReviewCrumb();
              }

              return this._renderDashboardCrumb();
            })}
          </ul>
        </div>
      );
    } else if (chartIsDisplayed) {
      breadcrumbs = <div className="breadcrumbs">{this._renderBack()}</div>;
    }

    return breadcrumbs;
  }
}

function mapStateToProps(state) {
  return {
    paths: getCurrentPaths(state),
    parentFolder: getParentFolder(state),
    depth: getSidebarDepth(state),
    chartIsDisplayed: getShowChart(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectFolder: folder => dispatch(selectFolderAction(folder)),
    resetSidebar: () => {
      dispatch(resetSidebarAction());
      dispatch(getRootFolders());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs);
