import { createSelector } from "reselect";
import { head, values, isEmpty } from "lodash";

export const allFolderObjects = state => values(state.folders.data);
export const getFolders = state => state.folders.data;
export const getFoldersArray = state => state.folders.folders;
export const getActiveFolderData = state => state.folders.activeFolderData;

export const getActiveFolderId = state => {
  const { activeFolderData } = state.folders;

  if (activeFolderData && activeFolderData.id) {
    return activeFolderData.id;
  }

  return "";
};

export const getFoldersLoading = state => state.folders.loading;

export const getAvailibleFolders = createSelector(
  [getActiveFolderData, getFoldersArray],
  (activeFolder, folders) => {
    if (isEmpty(activeFolder)) {
      let parsedFolders = folders;

      if (!Array.isArray(parsedFolders)) {
        parsedFolders = [];
      }

      return parsedFolders.filter(f => f.depth === 0);
    }

    return activeFolder.childFolders || [];
  }
);

export const getTopFolders = createSelector([allFolderObjects], folders =>
  folders.filter(f => f.depth === 0)
);

export const getParentFolder = createSelector(
  [getActiveFolderData, getFoldersArray],
  (activeFolder, foldersArray) => {
    if (isEmpty(activeFolder) || isEmpty(activeFolder.parentId)) {
      return {};
    }
    const parentFolder = head(
      foldersArray.filter(f => f.id === activeFolder.parentId)
    );

    return parentFolder || {};
  }
);

export const getShowAddFolderModal = state => state.folders.showAddFolderModal;
