import React, { Fragment } from "react";
import { RadioButton, Row } from "bio-web-components";

const RTToggle = props => {
  const { setValue, values } = props;
  const { showRT } = values;
  let styleModifier = "protocol_creation-inactive";
  if (showRT) {
    styleModifier = "";
  }

  return (
    <Fragment>
      <h3 className={`protocol_creation-label ${styleModifier}`}>
        Reverse Transcription
      </h3>
      <Row>
        <RadioButton
          label="RT Step"
          checked={values.showRT}
          onClick={() => setValue("showRT", !values.showRT)}
        />
      </Row>
    </Fragment>
  );
};

export default RTToggle;
