import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
  getActiveTeamName,
  getActiveTeamId,
  getTeams,
  getIDToken,
  canUserAccessReview,
} from "../Auth/selectors";
import history from "../store/history";

import { setActiveTeam, logoutReq } from "../Auth/actions";
import { getRootFolders } from "../Folders/actions";
import TeamMenu from "./components/TeamMenu";
import {
  showAppUpdateModal as showAppUpdateModalAction,
  hideAppUpdateModal,
} from "./actions";
import { initializeSampleHub } from "../SampleHub/actions";
import { postFeature } from "../App/actions";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTeamSelector: false,
    };

    this.teamMenuTimer = null;
  }

  componentWillUnmount = () => {
    clearTimeout(this.teamMenuTimer);
  };

  openTeamMenu = () => this.setState({ showTeamSelector: true });

  closeTeamMenu = () => this.setState({ showTeamSelector: false });

  handleSidebarClick = e => {
    const { showTeamSelector } = this.state;

    if (showTeamSelector && e.target.className !== "teamSelectorMenuDiv") {
      this.closeTeamMenu();
    }
  };

  handleSampleHubClick = () => {
    const {
      initializeSampleHub: clickInitializeSampleHub,
      postFeature: clickPostFeature,
    } = this.props;
    clickPostFeature("sample management");
    clickInitializeSampleHub();
  };

  teamMenuMouseLeave = () => {
    this.teamMenuTimer = setTimeout(() => {
      this.closeTeamMenu();
    }, 1000);
  };

  teamMenuMouseEnter = () => {
    clearTimeout(this.teamMenuTimer);
  };

  activeTeamSelector = team => {
    const { setActiveTeam: selectorSetActiveTeam, activeTeam } = this.props;

    if (team.id === activeTeam) {
      this.closeTeamMenu();
    }
    selectorSetActiveTeam(team);
    this.closeTeamMenu();
  };

  renderTeamSelector() {
    const { teams, activeTeam } = this.props;
    const { showTeamSelector } = this.state;

    if (showTeamSelector) {
      return (
        <div
          role="menuitem"
          tabIndex="0"
          onMouseEnter={this.teamMenuMouseEnter}
          onMouseLeave={this.teamMenuMouseLeave}
          onBlur={this.closeTeamMenu}
          className="teamSelectorMenuDiv"
        >
          <TeamMenu
            teams={teams}
            activeTeam={activeTeam}
            teamSelector={this.activeTeamSelector}
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      activeTeamName,
      userDetails,
      teams,
      showAppUpdateModal,
      canAccessReview,
      location,
      logout,
    } = this.props;
    const { showTeamSelector } = this.state;
    const { pathname } = location;

    const linkStyle = link => {
      if (pathname.includes(link)) {
        return { color: "#2AD2C9" };
      }

      return { color: "black" };
    };

    const teamSelectorStyle = hasTeams => {
      if (hasTeams) {
        return { transform: "rotate(180deg) scaleX(-1)" };
      }

      return {};
    };

    const renderChangeTeam = () => {
      if (teams && teams.length > 1) {
        return (
          <Fragment>
            <div
              className="changeTeam"
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "25px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ showTeamSelector: !showTeamSelector })
              }
            >
              Change Team
              <img
                src="../../../../../images/BackArrowNavy.svg"
                style={teamSelectorStyle(showTeamSelector)}
                height="20"
                width="13"
                onClick={this.openTeamMenu}
                alt=""
              />
            </div>
            {this.renderTeamSelector()}
          </Fragment>
        );
      }

      return null;
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="a_s__box-shadow"
        onClick={this.handleSidebarClick}
      >
        <div
          className="account_sidebar"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="account_sidebar__top">
              <div className="account_sidebar__user">
                <div className="account_sidebar__utils">
                  <img
                    src="/images/bio-avatar.png"
                    className="account_sidebar__user-avatar"
                    alt=""
                    width="45px"
                    height="45px"
                  />
                  <span
                    className="account_sidebar__logout"
                    onClick={() => logout()}
                  >
                    LOGOUT
                  </span>
                </div>
                <div className="account_sidebar__user-name">
                  {`${userDetails.given_name} ${userDetails.family_name}`}
                </div>
                <div className="account_sidebar__user-team">
                  {activeTeamName}
                </div>
              </div>
            </div>
            <div className="account_sidebar__bottom">
              {renderChangeTeam()}
              <Link
                to="/data"
                className="sidebar__menu-item"
                style={linkStyle("/data")}
              >
                Folders and Runs
              </Link>
              <div
                className="sidebar__menu-item"
                style={linkStyle("sample")}
                onClick={() => this.handleSampleHubClick()}
              >
                Sample Management
              </div>
              {canAccessReview && (
                <div
                  className="sidebar__menu-item"
                  style={linkStyle("/review-dashboard")}
                  onClick={() => history.push("/review-dashboard")}
                >
                  Review Dashboard
                </div>
              )}
              <Link
                to="/settings"
                className="sidebar__menu-item"
                style={linkStyle("/settings")}
              >
                Settings
              </Link>
              <Link
                to="/feedback"
                className="sidebar__menu-item"
                style={linkStyle("/feedback")}
              >
                Support
              </Link>
            </div>
          </div>
          <div
            className="app_block"
            onClick={() => {
              showAppUpdateModal();
            }}
          >
            <div className="app_block__text-container">
              <div>
                <div className="app_block__header">Is your app updated?</div>
                <div className="app_block__download-text">
                  Get the newest version{" "}
                  <i
                    style={{ color: "#2AD2C9" }}
                    onClick={() => showAppUpdateModal()}
                  >
                    here
                  </i>
                </div>
                <div className="app_block__download-subtext">
                  <img src="../../images/download.svg" height="16" alt="" />
                  <span className="app_block_name">
                    Biomeme <span style={{ fontWeight: 700 }}>Go</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="account_sidebar_dummy" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: getIDToken(state),
    activeTeamName: getActiveTeamName(state),
    activeTeam: getActiveTeamId(state),
    teams: getTeams(state),
    canAccessReview: canUserAccessReview(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveTeam: team => {
      dispatch(setActiveTeam(team));
    },
    getRootFolders: () => {
      dispatch(getRootFolders());
    },
    showAppUpdateModal: () => {
      dispatch(showAppUpdateModalAction());
    },
    hideAppUpdateModal: () => {
      dispatch(hideAppUpdateModal());
    },
    logout: () => dispatch(logoutReq()),
    initializeSampleHub: () => {
      dispatch(initializeSampleHub());
    },
    postFeature: feature => dispatch(postFeature(feature)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
