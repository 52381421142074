import React, { Component } from "react";
import { connect } from "react-redux";
import { generateUuid } from "js-common/lib/helpers";
import Modal from "../../../Layout/Modal";
import {
  addFolder as actionAddFolder,
  closeAddFolderModal as actionAddFolderModal,
} from "../../actions";
import { getActiveFolderData, getShowAddFolderModal } from "../../selectors";
import Button from "../../../Layout/Button";
import AddFolderIcon from "../../../Utils/Layout/AddFolderIcon";
import { getActiveTeamId } from "../../../Auth/selectors";

class AddFolderModal extends Component {
  styles = {
    modalHeader: {
      textAlign: "center",
    },
    modalIcon: {
      marginBottom: 20,
    },
    modalTitle: {
      height: 30,
      marginBottom: 30,
    },
    modalInnerBody: {
      paddingTop: 40,
    },
    modalButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "30px",
      marginBottom: "10px",
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      folderName: "",
    };
  }

  handleInputChange = event => {
    this.setState({ folderName: event.target.value });
  };

  handleModalClose = () => {
    const { closeAddFolderModal } = this.props;
    this.setState({ folderName: "" });
    closeAddFolderModal();
  };

  render() {
    const { isAddFolderModalShown, teamId, currentFolder, addFolder } =
      this.props;
    const { folderName } = this.state;
    const {
      modalHeader,
      modalIcon,
      modalTitle,
      modalInnerBody,
      container,
      modalButtons,
    } = this.styles;

    const modalHeading = (
      <div style={modalHeader}>
        <AddFolderIcon style={modalIcon} />
        <div style={modalTitle}>Add Folder</div>
      </div>
    );

    return (
      <Modal
        open={isAddFolderModalShown}
        heading={modalHeading}
        onClose={() => this.handleModalClose()}
        className="modal--run_detail"
        bodyClass="modal__body modal__folder"
        modalBodyInnerStyle={modalInnerBody}
      >
        <div style={container}>
          <p className="add-folder__label">FOLDER NAME</p>
          <input
            value={folderName}
            className="add-folder__input"
            onChange={this.handleInputChange}
          />
        </div>
        <div className="modal__buttons" style={modalButtons}>
          <Button
            title="Confirm"
            onClick={() => {
              addFolder(teamId, generateUuid(), folderName, currentFolder);
              this.handleModalClose();
            }}
            buttonClass="btn-modal"
          />
          <span
            style={{ marginTop: "20px", fontWeight: "600", cursor: "pointer" }}
            onClick={() => this.handleModalClose()}
          >
            Cancel
          </span>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    teamId: getActiveTeamId(state),
    currentFolder: getActiveFolderData(state),
    isAddFolderModalShown: getShowAddFolderModal(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addFolder: (teamId, folderId, folderName, parentId) => {
      dispatch(actionAddFolder(teamId, folderId, folderName, parentId));
    },
    closeAddFolderModal: () => {
      dispatch(actionAddFolderModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFolderModal);
