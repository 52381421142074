export const INITIALIZE_RUN_REVIEW_REQUEST =
  "runReview/INITIALIZE_RUN_REVIEW_REQUEST";
export type T_INITIALIZE_RUN_REVIEW_REQUEST =
  typeof INITIALIZE_RUN_REVIEW_REQUEST;

export const INITIALIZE_RUN_REVIEW_SUCCESS =
  "runReview/INITIALIZE_RUN_REVIEW_SUCCESS";
export type T_INITIALIZE_RUN_REVIEW_SUCCESS =
  typeof INITIALIZE_RUN_REVIEW_SUCCESS;

export const SET_PREVIOUS_REVIEW_USER = "runReview/SET_PREVIOUS_REVIEW_USER";
export type T_SET_PREVIOUS_REVIEW_USER = typeof SET_PREVIOUS_REVIEW_USER;

export const SET_RUN_COMMENTS = "runReview/SET_RUN_COMMENTS";
export type T_SET_RUN_COMMENTS = typeof SET_RUN_COMMENTS;

export const SET_SAMPLE_REVIEW = "runReview/SET_SAMPLE_REVIEW";
export type T_SET_SAMPLE_REVIEW = typeof SET_SAMPLE_REVIEW;

export const SET_TARGET_REVIEW = "runReview/SET_TARGET_REVIEW";
export type T_SET_TARGET_REVIEW = typeof SET_TARGET_REVIEW;

export const SUBMIT_RUN_REVIEW_REQUEST = "runReview/SUBMIT_RUN_REVIEW_REQUEST";
export type T_SUBMIT_RUN_REVIEW_REQUEST = typeof SUBMIT_RUN_REVIEW_REQUEST;

export const SET_SAMPLE_COMMENTS = "runReview/SET_SAMPLE_COMMENTS";
export type T_SET_SAMPLE_COMMENTS = typeof SET_SAMPLE_COMMENTS;

export const SET_SAMPLE_TYPE = "runReview/SET_SAMPLE_TYPE";
export type T_SET_SAMPLE_TYPE = typeof SET_SAMPLE_TYPE;

export const SET_SAMPLE_BLANK = "runReview/SET_SAMPLE_BLANK";
export type T_SET_SAMPLE_BLANK = typeof SET_SAMPLE_BLANK;

export const SET_TARGET_CQ = "runReview/SET_TARGET_CQ";
export type T_SET_TARGET_CQ = "runReview/SET_TARGET_CQ";

export const SET_TARGET_OVERRIDE = "runReview/SET_TARGET_OVERRIDE";
export type T_SET_TARGET_OVERRIDE = typeof SET_TARGET_OVERRIDE;

export const SET_TARGET_COMMENTS = "runReview/SET_TARGET_COMMENTS";
export type T_SET_TARGET_COMMENTS = typeof SET_TARGET_COMMENTS;

export const SET_REVIEW_RULES = "runReview/SET_REVIEW_RULES";
export type T_SET_REVIEW_RULES = typeof SET_REVIEW_RULES;

export const APPROVE_ALL_TARGETS = "runReview/APPROVE_ALL_TARGETS";
export type T_APPROVE_ALL_TARGETS = "runReview/APPROVE_ALL_TARGETS";

export const EXPORT_QA_LOG = "runReview/EXPORT_QA_LOG";
export type T_EXPORT_QA_LOG = typeof EXPORT_QA_LOG;

export const EXPORT_ALL_QA_LOG = "runReview/EXPORT_ALL_QA_LOG";
export type T_EXPORT_ALL_QA_LOG = typeof EXPORT_ALL_QA_LOG;

export const INITIALIZE_REVIEW_DASHBOARD_REQUEST =
  "runReview/INITIALIZE_REVIEW_DASHBOARD_REQUEST";
export type T_INITIALIZE_REVIEW_DASHBOARD_REQUEST =
  typeof INITIALIZE_REVIEW_DASHBOARD_REQUEST;

export const SET_REVIEW_DASHBOARD_LOADING =
  "runReview/SET_REVIEW_DASHBOARD_LOADING";
export type T_SET_REVIEW_DASHBOARD_LOADING =
  "runReview/SET_REVIEW_DASHBOARD_LOADING";

export const INITIALIZE_REVIEW_DASHBOARD_SUCCESS =
  "runReview/INITIALIZE_REVIEW_DASHBOARD_SUCCESS";
export type T_INITIALIZE_REVIEW_DASHBOARD_SUCCESS =
  typeof INITIALIZE_REVIEW_DASHBOARD_SUCCESS;

export const SET_ACTIVE_RUN_FILTER = "runReview/SET_ACTIVE_RUN_FILTER";
export type T_SET_ACTIVE_RUN_FILTER = typeof SET_ACTIVE_RUN_FILTER;

export const EXPORT_REVIEW_SUMMARY = "runReview/EXPORT_REVIEW_SUMMARY";
export type T_EXPORT_REVIEW_SUMMARY = typeof EXPORT_REVIEW_SUMMARY;

export const TOGGLE_BULK_REVIEW_MODAL = "runReview/TOGGLE_BULK_REVIEW_MODAL";
export type T_TOGGLE_BULK_REVIEW_MODAL = typeof TOGGLE_BULK_REVIEW_MODAL;

export const INITIALIZE_BULK_REVIEW_REQUEST =
  "runReview/INITIALIZE_BULK_REVIEW_REQUEST";
export type T_INITIALIZE_BULK_REVIEW_REQUEST =
  typeof INITIALIZE_BULK_REVIEW_REQUEST;

export const INITIALIZE_BULK_REVIEW_SUCCESS =
  "runReview/INITIALIZE_BULK_REVIEW_SUCCESS";
export type T_INITIALIZE_BULK_REVIEW_SUCCESS =
  typeof INITIALIZE_BULK_REVIEW_SUCCESS;

export const BULK_REVIEW_UPDATE_FILTER = "chart/BULK_REVIEW_UPDATE_FILTER";
export type T_BULK_REVIEW_UPDATE_FILTER = typeof BULK_REVIEW_UPDATE_FILTER;

export const BULK_REVIEW_SET_RUN_BLOCKS = "chart/BULK_REVIEW_SET_RUN_BLOCKS";
export type T_BULK_REVIEW_SET_RUN_BLOCKS = typeof BULK_REVIEW_SET_RUN_BLOCKS;

export const BULK_REVIEW_DEACTIVATE_RUN_BLOCK =
  "chart/BULK_REVIEW_DEACTIVATE_RUN_BLOCK";
export type T_BULK_REVIEW_DEACTIVATE_RUN_BLOCK =
  typeof BULK_REVIEW_DEACTIVATE_RUN_BLOCK;

export const BULK_REVIEW_ACTIVATE_RUN_BLOCK =
  "chart/BULK_REVIEW_ACTIVATE_RUN_BLOCK";
export type T_BULK_REVIEW_ACTIVATE_RUN_BLOCK =
  typeof BULK_REVIEW_ACTIVATE_RUN_BLOCK;

export const BULK_REVIEW_APPROVE_ALL_REQUEST =
  "chart/BULK_REVIEW_APPROVE_ALL_REQUEST";
export type T_BULK_REVIEW_APPROVE_ALL_REQUEST =
  typeof BULK_REVIEW_APPROVE_ALL_REQUEST;

export const BULK_REVIEW_APPROVE_ALL_SUCCESS =
  "chart/BULK_REVIEW_APPROVE_ALL_SUCCESS";
export type T_BULK_REVIEW_APPROVE_ALL_SUCCESS =
  typeof BULK_REVIEW_APPROVE_ALL_SUCCESS;

export const BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS =
  "chart/BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS";
export type T_BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS =
  typeof BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS;

export const BULK_REVIEW_RESET = "chart/BULK_REVIEW_RESET";
export type T_BULK_REVIEW_RESET = typeof BULK_REVIEW_RESET;

export const BULK_REVIEW_SET_FAILED_RUN_IDS =
  "chart/BULK_REVIEW_SET_FAILED_RUN_IDS";
export type T_BULK_REVIEW_SET_FAILED_RUN_IDS =
  typeof BULK_REVIEW_SET_FAILED_RUN_IDS;
