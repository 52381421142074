import React from "react";
import { connect } from "react-redux";

import {
  openExportModal as actionOpenExportModal,
  closeDetailedModal as actionCloseDetailedModal,
} from "../../actions";
import { getRunBlocks, getShowDetailedModal } from "../../selectors";
import { getFullRunData } from "../../../FullRunData/selectors";

import RunSection from "./runSection";
import Modal from "../../../Layout/Modal";
import Icon from "../../../Utils/Layout/Icon";
import Button from "../../../Layout/Button";
import { isSelectedRunExternalUpload } from "../../../Hub/selectors";

const styles = {
  downloadDataButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
};

export const DetailedData = props => {
  const {
    runs,
    isDetailedModalShown,
    closeDetailedModal,
    openExportModal,
    runBlocks,
  } = props;

  const wrappedDownloadIcon = () => <Icon name="export" marginRight />;

  const renderDownloadDataButton = () => {
    const { runIsExternalUpload } = props;

    if (runIsExternalUpload) {
      return null;
    }

    return (
      <div className="detailed-dropdown__download">
        <Button
          onClick={() => {
            openExportModal();
            closeDetailedModal();
          }}
          buttonStyle={styles.downloadDataButton}
          icon={wrappedDownloadIcon}
          title="Download Data"
        />
      </div>
    );
  };

  return (
    <Modal
      open={isDetailedModalShown}
      heading="Detailed Data View"
      onClose={() => closeDetailedModal()}
      className="modal--run_detail"
    >
      <div
        style={{
          maxHeight: "70vh",
          marginBottom: "17px",
        }}
      >
        {runs.map(run => {
          const runBlock = runBlocks.find(block => block.runId === run.id);

          return (
            <RunSection
              key={run.id}
              run={Object.assign({}, run)}
              runBlock={runBlock}
            />
          );
        })}
        {renderDownloadDataButton()}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    runs: getFullRunData(state),
    runBlocks: getRunBlocks(state),
    isDetailedModalShown: getShowDetailedModal(state),
    runIsExternalUpload: isSelectedRunExternalUpload(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openExportModal: () => {
      dispatch(actionOpenExportModal());
    },
    closeDetailedModal: () => {
      dispatch(actionCloseDetailedModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedData);
