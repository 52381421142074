export const HUB_FILTER_BAR_SHOW = "hub/FILTER_BAR_SHOW";
export const HUB_FILTER_BAR_HIDE = "hub/FILTER_BAR_HIDE";
export const HUB_FILTER_BAR_CHANGE_ACTIVE_TAB =
  "hub/FILTER_BAR_CHANGE_ACTIVE_TAB";
export const HUB_FILTER_BAR_ACTIVE_FILTER_REMOVE =
  "hub/FILTER_BAR_CHANGE_ACTIVE_FILTER_REMOVE";
export const HUB_FILTER_BAR_ACTIVE_FILTER_ADD =
  "hub/FILTER_BAR_CHANGE_ACTIVE_FILTER_ADD";
export const HUB_FILTER_BAR_PRESELECTED_FILTER_REMOVE =
  "hub/FILTER_BAR_CHANGE_PRESELECTED_FILTER_REMOVE";
export const HUB_FILTER_BAR_PRESELECTED_FILTER_ADD =
  "hub/FILTER_BAR_CHANGE_PRESELECTED_FILTER_ADD";

export const HUB_RESET = "hub/RESET_HUB";
export const RESET_SELECTED_RUNS = "hub/RESET_SELECTED_RUNS";
export const UPDATE_PATHS = "hub/UPDATE_PATHS";
export const APPLY_FILTERS = "hub/APPLY_FILTERS";
export const CANCEL_FILTER = "hub/CANCEL_FILTER";

export const HUB_SELECT_SINGLE_RUN = "hub/SELECT_SINGLE_RUN";
export const HUB_SELECT_MULTIPLE_RUNS = "hub/SELECT_MULTIPLE_RUNS";
export const HUB_GET_ADDITIONAL_DATA = "hub/GET_ADDITIONAL_DATA";
export const HUB_SELECTED_RUN_REMOVE = "hub/REMOVE_SELECTED_RUN";
export const HUB_SELECTED_RUN_ADD = "hub/ADD_SELECTED_RUN";
export const BULK_REVIEW_SELECTED_RUN_ADD = "hub/BULK_REVIEW_SELECTED_RUN_ADD";

export const SHOW_CHART = "hub/SHOW_CHART";
export const HIDE_CHART = "hub/HIDE_CHART";

export const SHOW_SEARCH = "hub/SHOW_SEARCH";
export const HIDE_SEARCH = "hub/HIDE_SEARCH";
export const HANDLE_SEARCH = "hub/HANDLE_SEARCH";

export const START_LOADING = "hub/START_LOADING";
export const STOP_LOADING = "hub/STOP_LOADING";
