import React from "react";

const MeltIcon = props => {
  const { opacity } = props;

  return (
    <svg
      width={30}
      height={15}
      style={{
        marginRight: "10px",
        marginTop: "1px",
        opacity,
      }}
      {...props}
      className="melt-run-icon run-icon"
      data-tip="Melt"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h28v14H0z" fill="#9ECA3A" fillRule="nonzero" />
        <path
          d="M10.479 10.201H9.201l-.45-3.843-1.512 3.141h-.981l-1.53-3.141-.45 3.843H3L3.765 4h1.062l1.917 4.068L8.643 4h1.08l.756 6.201zm2.43-1.107h2.718v1.107h-3.969V4h3.888v1.107h-2.637v1.476h2.313v1.125h-2.313v1.386zm3.915 1.107V4h1.251v5.094h2.349v1.107h-3.6zM25.455 4v1.107h-1.71v5.094h-1.251V5.107h-1.71V4h4.671z"
          fill="#012639"
        />
      </g>
    </svg>
  );
};

export default MeltIcon;
