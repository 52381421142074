import React from "react";

interface Props {
  samples: number;
  positiveControls: number;
  negativeControls: number;
  noTemplateControls: number;
}

const BulkReviewSummaryModalTotals: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { samples, positiveControls, negativeControls, noTemplateControls } =
    props;

  return (
    <div>
      <span className="section-header" id="totals-header">
        TOTALS
      </span>
      <table>
        <tbody>
          <tr className="bulk-review-summary__row-darkened" id="samples-row">
            <th>Samples</th>
            <td>{samples}</td>
          </tr>
          <tr className="bulk-review-summary__row" id="positive-controls-row">
            <th>Positive Controls</th>
            <td>{positiveControls}</td>
          </tr>
          <tr
            className="bulk-review-summary__row-darkened"
            id="negative-controls-row"
          >
            <th>Negative Controls</th>
            <td>{negativeControls}</td>
          </tr>
          <tr className="bulk-review-summary__row" id="ntc-row">
            <th>No Template Controls</th>
            <td>{noTemplateControls}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BulkReviewSummaryModalTotals;
