import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Content, Column, Container } from "bio-web-components";

import { getResponsiveClassname } from "../helpers";
import { getAppIsMobile } from "../../App/selectors";
import RegistrationFields from "../Subcomponents/RegistrationFields";

interface Props extends RouteComponentProps {
  isMobile: boolean;
}

const RegistrationForm: React.FunctionComponent<Props> = (props: Props) => {
  const { isMobile } = props;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "4960967",
          formId: "cbb4171e-f4ae-42a7-8548-7c3230cadbdf",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <Content>
      <Container classes="register_container">
        <Column>
          <h1 className={getResponsiveClassname(isMobile, "register_header")}>
            New User Registration
          </h1>
          <p className={getResponsiveClassname(isMobile, "register_subtitle")}>
            To register or create your Biomeme Go Account, please submit the
            following information. A Customer Success representative will get
            back to you within 1 business day with your login credentials.
          </p>
          <RegistrationFields isMobile={isMobile} />
        </Column>
      </Container>
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isMobile: getAppIsMobile(state),
  };
};

export default connect(mapStateToProps, null)(RegistrationForm);
