export const CHART_INITIALIZE = "chart/INITIALIZE";
export const CHART_RESET = "chart/RESET_CHART";

export const UPDATE_SINGLE_THRESHOLD_REQUEST =
  "chart/UPDATE_SINGLE_THRESHOLD_REQUEST";
export const UPDATE_SINGLE_THRESHOLD_SUCCESS =
  "chart/UPDATE_SINGLE_THRESHOLD_SUCCESS";
export const UPDATE_SINGLE_THRESHOLD_FAILURE =
  "chart/UPDATE_SINGLE_THRESHOLD_FAILURE";

export const UPDATE_LINE_THRESHOLD = "chart/UPDATE_LINE_THRESHOLD";

export const CHART_SHOW_SINGLE_TARGET = "chart/SHOW_SINGLE_TARGET";
export const CHART_HIDE_SINGLE_TARGET = "chart/HIDE_SINGLE_TARGET";
export const CHART_TOGGLE_ALL_RUN_TARGETS = "chart/TOGGLE_ALL_RUN_TARGETS";

export const CHART_ACTIVATE_RUN_BLOCK = "chart/ACTIVATE_RUN_BLOCK";
export const CHART_DEACTIVATE_RUN_BLOCK = "chart/DEACTIVATE_RUN_BLOCK";

export const CHART_SHOW_RUN_THRESHOLDS = "chart/SHOW_RUN_THRESHOLDS";
export const CHART_HIDE_RUN_THRESHOLDS = "chart/HIDE_RUN_THRESHOLDS";

export const CHART_APPLY_FILTERS_RUN = "chart/APPLY_FILTERS_RUN";
export const CHART_APPLY_FILTERS_MASTER = "chart/APPLY_FILTERS_MASTER";

export const CHART_SHOW_DETAILED_MODAL = "chart/SHOW_RUN_DETAILED_VIEW";
export const CHART_HIDE_DETAILED_MODAL = "chart/HIDE_RUN_DETAILED_VIEW";

export const CHART_SHOW_EXPORT_MODAL = "chart/SHOW_RUN_EXPORT_VIEW";
export const CHART_HIDE_EXPORT_MODAL = "chart/HIDE_RUN_EXPORT_VIEW";

export const CHART_TOGGLE_EXPORT_REVIEW_MODAL =
  "chart/TOGGLE_EXPORT_REVIEW_MODAL";

export const CHART_SHOW_ANALYSIS_MODAL = "chart/SHOW_ANALYSIS_MODAL";
export const CHART_HIDE_ANALYSIS_MODAL = "chart/HIDE_ANALYSIS_MODAL";

export const CHART_TOGGLE_TUTORIAL = "chart/TOGGLE_TUTORIAL";

export const SET_PRINT_INDEX = "chart/SET_PRINT_INDEX";
export const CHART_SHOW_PRINT_MODAL = "chart/SHOW_RUN_PRINT_VIEW";
export const CHART_HIDE_PRINT_MODAL = "chart/HIDE_RUN_PRINT_VIEW";

export const CHART_UPDATE_METRICS = "chart/UPDATE_METRICS";
export const CHART_UPDATE_END_RFU_METRICS = "chart/UPDATE_END_RFU_METRICS";
export const CHART_UPDATE_AXES = "chart/UPDATE_AXES";
export const UPDATE_CQ_VALUES = "chart/UPDATE_CQ_VALUES";

export const SHOW_RAW = "chart/SHOW_RAW";
export const SHOW_BASELINE = "chart/SHOW_BASELINE";
export const TOGGLE_LOG = "chart/TOGGLE_LOG";
export const SHOW_SINGLE_THRESHOLDS = "chart/SHOW_SINGLE_THRESHOLDS";
export const SHOW_TABULAR_DATA = "chart/SHOW_TABULAR_DATA";
export const UPDATE_ANALYSIS_MODE = "chart/UPDATE_ANALYSIS_MODE";

export const CHART_UPDATE_TABULAR_DATA_OPTIONS =
  "chart/CHART_UPDATE_TABULAR_DATA_ORDER";

export const CHART_START_BACKGROUND_RESET_SEQUENCE =
  "chart/CHART_START_BACKGROUND_RESET_SEQUENCE";
export const CHART_RESET_BACKGROUND_VALUES =
  "chart/CHART_RESET_BACKGROUND_VALUES";
export const CHART_UPDATE_BACKGROUND_VALUES =
  "chart/CHART_UPDATE_BACKGROUND_VALUES";
export const CHART_STORE_REPROCESSED_DATA =
  "chart/CHART_STORE_REPROCESSED_DATA";
export const CHART_UPDATE_TARGET_BACKGROUND_VALUES =
  "chart/CHART_UPDATE_TARGET_BACKGROUND_VALUES";
export const CHART_UPDATE_BACKGROUND_VALUES_ERROR =
  "chart/CHART_UPDATE_BACKGROUND_VALUES_ERROR";
export const CHART_STORE_REPROCESSED_TARGET =
  "chart/CHART_STORE_REPROCESSED_TARGET";

export const CHART_SET_REPROCESSING_ERROR =
  "chart/CHART_SET_REPROCESSING_ERROR";

export const CHART_SET_REVIEW_MODE_ERROR = "chart/SET_REVIEW_MODE_ERROR";

export const UPDATE_WELL_FILTERS = "chart/UPDATE_WELL_FILTERS";
export const UPDATE_CHANNEL_FILTERS = "chart/UPDATE_CHANNEL_FILTERS";

export const CHART_SHOW_CYCLES_DISPLAYED_MODAL =
  "chart/SHOW_CYCLES_DISPLAYED_MODAL";
export const CHART_HIDE_CYCLES_DISPLAYED_MODAL =
  "chart/HIDE_CYCLES_DISPLAYED_MODAL";

export const CHART_UPDATE_CYCLES_DISPLAYED_LOWER_BOUND =
  "chart/CHART_UPDATE_CYCLES_DISPLAYED_LOWER_BOUND";
export const CHART_UPDATE_CYCLES_DISPLAYED_UPPER_BOUND =
  "chart/CHART_UPDATE_CYCLES_DISPLAYED_UPPER_BOUND";

export const TUTORIAL_SET_RUN_BLOCK_OPEN_STATE =
  "chart/TUTORIAL_SET_RUN_BLOCK_OPEN_STATE";

export const TOGGLE_BULK_REVIEW_SUMMARY_MODAL =
  "runReview/TOGGLE_BULK_REVIEW_SUMMARY_MODAL";

export const CHART_REPROCESS_TARGET_REQUEST =
  "runReview/REPROCESS_TARGET_REQUEST";
export const CHART_REPROCESS_TARGET_SUCCESS =
  "runReview/REPROCESS_TARGET_SUCCESS";
export const CHART_REPROCESS_TARGET_FAILURE =
  "runReview/REPROCESS_TARGET_FAILURE";
