import React from "react";

import { RadioButton } from "bio-web-components";

import { BugReportFormState } from "../types";

interface RadioButtonProps {
  label: string;
  onClick: (form: BugReportFormState) => void;
  checked: boolean;
}

interface Props {
  radioButtonProps: RadioButtonProps[];
}

const RadioGroup: React.FunctionComponent<Props> = (props: Props) => {
  const { radioButtonProps } = props;

  return (
    <div className="bug_report__radio_group">
      {radioButtonProps.map(radio => {
        const { onClick, label, checked } = radio;

        return (
          <RadioButton
            onClick={onClick}
            label={label}
            checked={checked}
            key={label}
          />
        );
      })}
    </div>
  );
};

export default RadioGroup;
