import React from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";

import { shortenText } from "../helpers";
import {
  deleteSampleTemplate as deleteSampleTemplateAction,
  deleteSampleRecord as deleteSampleRecordAction,
} from "../actions";
import Modal from "../../Layout/Modal";
import CloudLog from "../../Utils/CloudLog";

const styles = {
  header: {
    fontSize: "24px",
    color: "#012639",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: 26,
  },
};

const DeleteModal = props => {
  const handleDelete = () => {
    const { id, type, deleteSampleTemplate, deleteSampleRecord, closeModal } =
      props;

    if (type === "template") {
      deleteSampleTemplate(id);
    } else if (type === "record") {
      deleteSampleRecord(id);
    } else {
      CloudLog.error(`Type ${type} is not template or record`);
    }

    closeModal();
  };

  const { closeModal, openModal, name } = props;

  return (
    <Modal
      open={openModal}
      className="modal--run_detail"
      bodyClass="modal__analysis"
      onClose={() => closeModal()}
      modalBodyInnerStyle={{}}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 40,
          marginBottom: 60,
        }}
      >
        <img src="../../images/DeleteIcon_Modal.svg" alt="Delete Icon" />
        <p style={styles.header}>{`Delete ${shortenText(name, 16)}`}</p>
        <p>This action cannot be undone.</p>
        <button
          style={{ marginTop: 27 }}
          className="btn-modal"
          onClick={debounce(() => handleDelete(), 1000)}
        >
          Delete
        </button>
        <p
          style={{
            marginTop: 23,
            fontSize: "14px",
            fontWeight: "600",
            fontFamily: "Roboto",
            cursor: "pointer",
          }}
          onClick={closeModal}
        >
          Cancel
        </p>
      </div>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    deleteSampleTemplate: id => {
      dispatch(deleteSampleTemplateAction(id));
    },
    deleteSampleRecord: id => {
      dispatch(deleteSampleRecordAction(id));
    },
  };
}

export default connect(null, mapDispatchToProps)(DeleteModal);
