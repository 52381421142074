import React, { Component } from "react";
import { keys } from "lodash";

import Select from "../../Layout/Select";
import Checkbox from "../../Layout/Checkbox";
import { desiredFieldOrder } from "../constants";

class CreateSampleRow extends Component {
  constructor(props) {
    super(props);

    this.initialState = props.data;
    this.state = this.initialState;
  }

  handleFieldUpdate = (target, value) => {
    this.setState({ [target]: value });
  };

  handleOptionalUpdate = () => {
    const { optional } = this.state;
    this.setState({ optional: !optional }, this.handleRowUpdate);
  };

  handleSelectUpdate = (target, value) => {
    this.setState({ [target]: value });
  };

  handleRowUpdate = () => {
    const { id, update } = this.props;

    update(id, this.state);
  };

  handleDelete = () => {
    const { id, deleteRow } = this.props;

    if (id !== 0) {
      deleteRow(id);
    }
  };

  generateTableDataFields = () => {
    const fields = keys(this.state);
    const { id } = this.props;

    fields.sort(
      (x, y) => desiredFieldOrder.indexOf(x) - desiredFieldOrder.indexOf(y)
    );

    return fields.map(field => {
      const { [field]: fieldOnState } = this.state;

      if (field === "optional") {
        return (
          <td key={`checkbox${field}`} className="sample-hub__cell--checkbox">
            <Checkbox
              onClick={() => this.handleOptionalUpdate()}
              checked={fieldOnState}
              label="Yes"
            />
          </td>
        );
      }

      if (field === "type") {
        const items = [
          { value: "text", display: "Text" },
          { value: "location", display: "Location" },
          { value: "date", display: "Date (yyyy/mm/dd)" },
          { value: "number", display: "Number" },
        ];

        return (
          <td key={`select${field}`} className="sample-hub__cell--checkbox">
            <Select
              items={items}
              value={fieldOnState}
              onChange={e => this.handleSelectUpdate(field, e.target.value)}
              width={200}
              height={35}
            />
          </td>
        );
      }

      if (field === "delete") {
        let deleteOpacity = 1;
        if (id === 0) {
          deleteOpacity = 0.5;
        }

        return (
          <td
            key={`delete${field}`}
            className="sample-hub__cell--checkbox"
            onClick={this.handleDelete}
            style={{ opacity: deleteOpacity }}
          >
            <span className="sample-hub__delete">DELETE</span>
          </td>
        );
      }

      return (
        <td key={`field${field}`} className="sample-hub__cell">
          <input
            onChange={e => this.handleFieldUpdate(field, e.target.value)}
            value={fieldOnState}
          />
        </td>
      );
    });
  };

  render() {
    const { id } = this.props;

    return (
      <tr
        key={`row${id}`}
        className="sample-hub__row"
        onBlur={this.handleRowUpdate}
      >
        {this.generateTableDataFields()}
      </tr>
    );
  }
}

export default CreateSampleRow;
