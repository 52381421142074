import { DetailsVersion } from "js-common";

import * as t from "./actionTypes";
import { IBioradChannelSelection } from "./types";

export interface IExternalUploadRequestOptions {
  detailsVersion: DetailsVersion;
  protocolId: string;
  colorMap?: IBioradChannelSelection;
}

export interface IExternalUploadRequest {
  type: t.T_EXTERNAL_UPLOAD_REQUEST;
  files: Array<File>;
  resolve: any;
  reject: any;
  options: IExternalUploadRequestOptions;
}

export function externalUploadRequest(
  files: Array<File>,
  resolve: any,
  reject: any,
  options: IExternalUploadRequestOptions
): IExternalUploadRequest {
  return {
    type: t.EXTERNAL_UPLOAD_REQUEST,
    files,
    resolve,
    reject,
    options,
  };
}
