import { BulkReviewFilter, BulkReviewSamplesFilter } from "../../../types";
import * as styles from "./styles";

/**
 * Determines if button style should be active or not
 *
 * @export
 * @param {BulkReviewFilter[]} dropDownOptions
 * @param {BulkReviewFilter} currentFilter
 * @return {React.CSSProperties}  {React.CSSProperties}
 */
export const determineButtonStyle = (
  dropDownOptions: BulkReviewFilter[],
  currentFilter: BulkReviewFilter
): React.CSSProperties => {
  let style = styles.button;

  if (dropDownOptions.includes(currentFilter as BulkReviewSamplesFilter)) {
    style = styles.buttonActive;
  }

  return style;
};

/**
 * Determines if the dropdown style should be active or not
 *
 * @export
 * @param {BulkReviewFilter} option
 * @param {BulkReviewFilter} currentFilter
 * @return {React.CSSProperties}  {React.CSSProperties}
 */
export const determineDropDownButtonStyle = (
  option: BulkReviewFilter,
  currentFilter: BulkReviewFilter
): React.CSSProperties => {
  let style = styles.bulkFilterDropDownButton;

  if (option === currentFilter) {
    style = styles.bulkFilterDropDownButtonActive;
  }

  return style;
};
