import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { createMultiRunWorkBook } from "excel-sheet-creator";
import { saveAs } from "file-saver";

import Modal from "../../../Layout/Modal";
import Checkbox from "../../../Layout/Checkbox";

import { closeExportModal as actionCloseExportModal } from "../../actions";
import { postFeature as actionPostFeature } from "../../../App/actions";
import {
  getRunDataForExport,
  getRunBlocks,
  getShowExportModal,
} from "../../selectors";
import {
  getActiveTeamVersion,
  getActiveTeamName,
} from "../../../Auth/selectors";
import { tooltipDelay } from "../../constants";
import { generateFilename } from "./helpers";
import { BLOB_TYPE } from "../../../RunReview/constants";
import CloudLog from "../../../Utils/CloudLog";

export class ExportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      runLabels: props.runBlocks.map(run => {
        return {
          name: run.name,
          preLabel: run.type,
          id: run.runId,
          checked: true,
        };
      }),
    };
  }

  countDup = array => {
    const counts = {};
    array.forEach(x => {
      counts[x] = (counts[x] || 0) + 1;
    });

    return counts;
  };

  s2ab = s => {
    if (typeof ArrayBuffer !== "undefined") {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; i += 1) {
        view[i] = s.charCodeAt(i) & 0xff;
      }

      return buf;
    }
    const buf = new Array(s.length);
    for (let i = 0; i !== s.length; i += 1) {
      buf[i] = s.charCodeAt(i) & 0xff;
    }

    return buf;
  };

  _renderAllRunsCheckbox = () => {
    const { runLabels } = this.state;
    const { runs } = this.props;

    const checked = !(
      runLabels.filter(run => {
        return run.checked === true;
      }).length < runs.length
    );

    const onClick = () => {
      // if all runs are off, turn them all on..
      const newStatus =
        runLabels.filter(run => {
          return run.checked === true;
        }).length < runs.length;

      const newRunLabels = runLabels.map(run => {
        return {
          ...run,
          checked: newStatus,
        };
      });

      this.setState({
        runLabels: newRunLabels,
      });
    };

    return (
      <Checkbox
        key="All Runs"
        label="All Runs"
        large
        checked={checked}
        onClick={onClick}
      />
    );
  };

  _renderDetailedButton = () => {
    const { runs, version, teamName, postFeature } = this.props;
    const { runLabels } = this.state;

    const onClick = () => {
      postFeature("detailed data export");

      const downloadIds = runLabels
        .filter(run => {
          const { checked } = run;

          return checked === true;
        })
        .map(run => {
          const { id } = run;

          return id;
        });

      if (downloadIds.length <= 0) {
        return;
      }

      const filteredRuns = runs.filter(run => {
        const { id } = run;

        return downloadIds.indexOf(id) >= 0;
      });

      const download = filteredRuns.map(run => {
        return {
          ...run,
          teamName,
        };
      });

      let parsingSetting;
      if (version === "biomeme-2") {
        parsingSetting = "portal-three9";
      }
      if (version === "biomeme-1") {
        parsingSetting = "portal-two3";
      }

      const wbout = createMultiRunWorkBook(download, parsingSetting, {
        type: "binary",
      });

      const fname = generateFilename(download);

      try {
        saveAs(
          new Blob([this.s2ab(wbout)], {
            type: BLOB_TYPE,
          }),
          fname
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          CloudLog.error(`Error in ExportModal: \n ${e} \n ${wbout}`);
        }
      }
    };

    return (
      <button
        className="btn-modal btn-modal--download"
        data-tip="Detailed data contains all RFU values, protocol information, etc., with each run having its own sheet."
        onClick={onClick}
      >
        Detailed
      </button>
    );
  };

  _renderSummaryButton = () => {
    const { runs, version, postFeature } = this.props;
    const { runLabels } = this.state;

    const onClick = () => {
      postFeature("summary data export");

      const downloadIds = runLabels
        .filter(run => {
          const { checked } = run;

          return checked === true;
        })
        .map(run => {
          const { id } = run;

          return id;
        });

      if (downloadIds.length <= 0) {
        return;
      }

      const download = runs.filter(run => {
        const { id } = run;

        return downloadIds.indexOf(id) >= 0;
      });

      let parsingSetting;
      if (version === "biomeme-2") {
        parsingSetting = "portal-three9";
      } else if (version === "biomeme-1") {
        parsingSetting = "portal-two3";
      }

      const wbout = createMultiRunWorkBook(download, parsingSetting, {
        cqOnly: true,
        type: "binary",
      });

      const fname = generateFilename(download, "Summary of ");

      try {
        saveAs(
          new Blob([this.s2ab(wbout)], {
            type: BLOB_TYPE,
          }),
          fname
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          CloudLog.error(
            `Error in ExportModal (Summary Download): \n ${e} \n ${wbout}`
          );
        }
      }
    };

    return (
      <button
        className="btn-modal btn-modal--download"
        data-tip="Summary data is similar in content to the tabular data view."
        style={{ marginLeft: "5px" }}
        onClick={onClick}
      >
        Summary
      </button>
    );
  };

  _renderRunLabelsCheckboxes = () => {
    const { runLabels } = this.state;

    return runLabels.map((run, index) => {
      const { name, preLabel, checked } = run;

      const onClick = () => {
        this.setState({
          runLabels: [
            ...runLabels.slice(0, index),
            {
              ...run,
              checked: !checked,
            },
            ...runLabels.slice(index + 1),
          ],
        });
      };

      return (
        <Checkbox
          key={name}
          label={name}
          preLabel={preLabel}
          large
          border
          checked={checked}
          onClick={onClick}
        />
      );
    });
  };

  render() {
    const { show, closeExportModal } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Modal
        open={show}
        heading="Download Data"
        onClose={() => {
          return closeExportModal();
        }}
        className="modal--export"
      >
        <p>Run data will be exported in XLSX format as individual sheets.</p>
        {this._renderAllRunsCheckbox()}
        <div>
          {this._renderRunLabelsCheckboxes()}
          <div className="modal__buttons">
            {this._renderDetailedButton()}
            {this._renderSummaryButton()}
          </div>
        </div>
        <ReactTooltip delayShow={tooltipDelay} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    runs: getRunDataForExport(state),
    runBlocks: getRunBlocks(state),
    show: getShowExportModal(state),
    version: getActiveTeamVersion(state),
    teamName: getActiveTeamName(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeExportModal: () => {
      dispatch(actionCloseExportModal());
    },
    postFeature: feature => {
      return dispatch(actionPostFeature(feature));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
