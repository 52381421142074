import React from "react";

import Content from "../../App/components/content";
import Sidebar from "../../Sidebar";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";
import LeftPage from "../Subcomponents/LeftPage";
import RightPage from "../Subcomponents/RightPage";

interface StateProps {}

interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Settings: React.FunctionComponent<Props> = () => {
  return (
    <div className="fluidContainers">
      <AppUpdateModal />
      <Sidebar />
      <Content>
        <div className="page">
          <div className="page__container">
            <LeftPage />
            <RightPage />
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Settings;
