import "@babel/polyfill/noConflict";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import history from "./store/history";
import configureStore from "./store/configureStore";
import App from "./App";

require("../scss/base.scss");

Sentry.init({
  dsn: "https://779dab94f58f4e0987f2f4f7b0137560@o368661.ingest.sentry.io/5223317",
  beforeSend(event) {
    if (process.env.NODE_ENV !== "production") {
      return null;
    }

    return event;
  },
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("app")
);
