import React, { Fragment } from "react";
import { connect } from "react-redux";

import SelectedRuns from "../../Hub/components/filterBar/selectedRuns";
import { getSidebarDepth } from "../../Sidebar/selectors";

const centeredStyling = {
  alignItems: "center",
  margin: "0 auto",
  width: "100vw",
};

export function Content({ children, depth }) {
  let styleValue = {};
  if (depth > 0) {
    styleValue = centeredStyling;
  }

  return (
    <Fragment>
      <div id="content" style={styleValue}>
        <div id="content">{children}</div>
      </div>
      <SelectedRuns />
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    depth: getSidebarDepth(state),
  };
}

export default connect(mapStateToProps)(Content);
