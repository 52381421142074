export const BLANK = "Blank Sample";
export const INVALID_SAMPLE = "Invalid";
export const PENDING = "Pending Cq Approval";
export const BLOB_TYPE = "application/octet-stream";

export const NEGATIVE = "negative";
export const POSITIVE = "positive";
export const INCONCLUSIVE = "inconclusive";
export const POSITIVE_CONTROL = "PC";
export const NEGATIVE_CONTROL = "NC";
export const NO_TEMPLATE_CONTROL = "NTC";

export const REVIEW_DASHBOARD_PATH = {
  name: "Review Dashboard",
  depth: 0,
  type: "Dashboard",
};

export const BULK_REVIEW_PATH = {
  name: "Bulk Review",
  depth: 1,
  type: "bulkReview",
};
