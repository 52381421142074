import React, { Component, Fragment } from "react";
import { generateUuid } from "js-common/lib/helpers";
import { connect } from "react-redux";

import CreateSampleRow from "./Row";
import {
  rowData,
  tableHeaders,
  MAX_PARAMETERS,
  metadataTypeMap,
  dateValidationRegex,
} from "../constants";
import { clearErrors as clearErrorsAction } from "../actions";
import { getTemplatePostErrors } from "../selectors";
import Button from "../../Layout/Button";

class SampleLayoutTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateName: "",
      rows: [],
      error: "",
    };
  }

  componentDidMount() {
    this.populateInitialRows();
  }

  buttonClassNameParser = () => {
    const { rows } = this.state;

    const maxNumberOfParametersAdded = rows.length >= MAX_PARAMETERS;

    if (maxNumberOfParametersAdded) {
      return "btn sample-hub__button--inactive";
    }

    return "btn sample-hub__button";
  };

  populateInitialRows = () => {
    const { initialTemplateData = undefined } = this.props;

    if (initialTemplateData) {
      const { name } = initialTemplateData;

      this.setState({
        templateName: name,
        rows: initialTemplateData.metadata,
      });
    } else {
      this.addRow();
    }
  };

  addRow = () => {
    const { rows } = this.state;
    const { clearErrors } = this.props;

    clearErrors();

    const maxNumberOfParametersAdded = rows.length >= MAX_PARAMETERS;

    if (maxNumberOfParametersAdded) {
      return null;
    }

    this.setState({ rows: [...rows, { ...rowData }], error: "" });

    return null;
  };

  deleteRow = id => {
    const { rows } = this.state;

    if (rows.length < 2) {
      return;
    }

    const updatedRows = [...rows];
    updatedRows.splice(id, 1);

    this.setState({
      rows: updatedRows,
      error: "",
    });
  };

  updateRowValues = (id, data) => {
    const { rows } = this.state;

    const newRows = [...rows];
    newRows[id] = data;

    this.setState({ rows: newRows, error: "" });
  };

  updateTemplateName = name => {
    this.setState({
      templateName: name,
      error: "",
    });
  };

  handleSubmit = () => {
    const { templateName, rows } = this.state;
    const { uploadTemplate, activeTeamId } = this.props;

    let error = false;
    const nonEmptyRows = [];

    if (!templateName) {
      error = true;

      return this.setState({ error: "Please enter a layout name." });
    }

    rows.forEach(row => {
      if (!row.parameterName) {
        error = true;

        return this.setState({
          error: "Please ensure that each row has a field name.",
        });
      }

      const mappedDefaultValueType = metadataTypeMap[row.type];

      if (row.defaultValue) {
        if (mappedDefaultValueType === "number") {
          if (Number.isNaN(+row.defaultValue)) {
            error = true;

            return this.setState({
              error: `Please ensure default value for ${row.parameterName} matches the type you selected.`,
            });
          }
        } else if (mappedDefaultValueType === "date") {
          if (dateValidationRegex.test(row.defaultValue)) {
            // empty
          } else {
            error = true;

            return this.setState({
              error: `Please ensure default value for ${row.parameterName} matches the type you selected.`,
            });
          }
        } else if (
          mappedDefaultValueType === "text" ||
          mappedDefaultValueType === "location"
        ) {
          if (Number.isNaN(+row.defaultValue)) {
            // empty
          } else {
            error = true;

            return this.setState({
              error: `Please ensure default value for ${row.parameterName} matches the type you selected.`,
            });
          }
        }
      }

      const cleanRow = { ...row };
      delete cleanRow.delete;
      nonEmptyRows.push(cleanRow);

      return undefined;
    });

    if (error) {
      return undefined;
    }

    if (nonEmptyRows.length > 0) {
      const template = {
        id: generateUuid(),
        name: templateName,
        metadata: rows,
        teamId: activeTeamId,
        date: Date.now(),
      };

      uploadTemplate(template);
    }

    return undefined;
  };

  renderTemplateNameSection = () => {
    const { templateName } = this.state;

    return (
      <table className="sample-hub__input-table" style={{ marginTop: "40px" }}>
        <tbody>
          <tr className="sample-hub__row">
            <th>Layout Name</th>
          </tr>
          <tr className="sample-hub__row">
            <td className="sample-hub__cell">
              <input
                onChange={e => this.updateTemplateName(e.target.value)}
                value={templateName}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderTableHeaders = () => {
    return tableHeaders.map(header => <th key={header}>{header}</th>);
  };

  renderTableRows = () => {
    const { rows } = this.state;

    return rows.map((row, index) => (
      <CreateSampleRow
        key={Math.random()}
        id={index}
        data={row}
        update={this.updateRowValues}
        deleteRow={this.deleteRow}
      />
    ));
  };

  renderErrorText = () => {
    const { error } = this.state;
    const { submitError } = this.props;

    let isSubmitError = submitError;
    if (error) {
      isSubmitError = null;
    }

    return (
      <div
        style={{
          height: "20px",
          marginBottom: "10px",
          color: "red",
          fontWeight: 600,
        }}
      >
        {error}
        {isSubmitError}
      </div>
    );
  };

  renderButtons = () => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          title="Add Row"
          onClick={this.addRow}
          buttonClass={this.buttonClassNameParser()}
        />
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this.renderTemplateNameSection()}
        <table className="sample-hub__input-table">
          <tbody>
            <tr className="sample-hub__row">{this.renderTableHeaders()}</tr>
            {this.renderTableRows()}
          </tbody>
        </table>
        <div className="sample-hub__button-container">
          {this.renderErrorText()}
          {this.renderButtons()}
        </div>
        <hr
          className="settings__horizontal-rule"
          style={{ minWidth: "1000px", marginTop: 20, marginBottom: 20 }}
        />
        <Button
          title="Submit"
          buttonClass="btn sample-hub__button"
          onClick={this.handleSubmit}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    submitError: getTemplatePostErrors(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearErrors: () => dispatch(clearErrorsAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleLayoutTable);
