import React from "react";

interface Props {
  items: any[];
  onChange: (e: any) => void;
  width: number;
  height: number;
  value: any;
}

interface State {
  focus: boolean;
}

class Select extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  render() {
    const { width, height, onChange, value, items } = this.props;
    const { focus } = this.state;

    let borderRightStyle = "1px solid #dedede";
    if (focus) {
      borderRightStyle = "1px solid #95B7EE";
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width,
          height,
        }}
      >
        <div
          style={{
            width: width - 20,
            height,
            overflow: "hidden",
            borderRight: borderRightStyle,
            backgroundColor: "#f8f8f8",
            justifyContent: "flex-start",
          }}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
        >
          <select
            onChange={(e: any) => onChange(e)}
            className="sample-hub__select"
            id="sample-hub__selector"
            style={{
              width,
              height,
              backgroundColor: "#f8f8f8",
              border: "1px solid #dedede",
              appearance: "none",
            }}
            value={value}
          >
            {items.map(item => (
              <option
                value={item.value}
                key={`select option ${item.value}`}
                style={{ width: width - 20 }}
              >
                {item.display}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default Select;
