import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getShowChart } from "../../selectors";
import { getSidebarDepth } from "../../../Sidebar/selectors";
import BreadCrumbs from "./breadCrumbs";
import Title from "./title";
import UploadRunModal from "../uploadRunModal";
import Export from "./export";
import Button from "../../../Layout/Button";
import AddIconButton from "../../../Utils/Layout/AddIconButton";

import { openAddFolderModal as openAddFolderModalAction } from "../../../Folders/actions";
import { canUserAccessRunUploading } from "../../../Auth/selectors";

export function ContentHeader({
  showChart,
  location,
  depth,
  openAddFolderModal,
  archiveModalShow,
  canUploadRun,
}) {
  const [uploadRunModalOpen, setUploadRunModalOpen] = useState(false);

  const addIcon = () => <AddIconButton style={{ marginRight: 10 }} />;

  const _renderUploadRunButton = () => {
    if (canUploadRun) {
      return (
        <Button
          buttonClass="upload-external-run-button"
          title="Upload Run"
          onClick={() => setUploadRunModalOpen(true)}
          icon={addIcon}
          buttonStyle={{
            width: 150,
            marginRight: 10,
            alignSelf: "center",
          }}
        />
      );
    }

    return null;
  };

  let contentHeaderStyleValue = {};
  if (depth > 0) {
    contentHeaderStyleValue = {
      width: "100vw",
      background: "linear-gradient(180deg, #FEFEFE 0%, #FBFBFB 100%)",
    };
  }

  let breadCrumbsClass = "inner";
  if (depth > 0 && !showChart) {
    breadCrumbsClass = "inner centered";
  }

  let displayButtons = null;
  if (location.pathname.includes("/data") && depth > 0) {
    displayButtons = (
      <div>
        {_renderUploadRunButton()}
        <Button
          title="Add Folder"
          onClick={openAddFolderModal}
          buttonClass=""
          icon={addIcon}
          buttonStyle={{ width: 150, alignSelf: "center" }}
        />
      </div>
    );
  }

  let displayExport = null;
  if (showChart && location.pathname.includes("/runs-page")) {
    displayExport = <Export />;
  }

  return (
    <div className="content-header" style={contentHeaderStyleValue}>
      <div className={breadCrumbsClass}>
        <BreadCrumbs />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "-26px",
          }}
        >
          <UploadRunModal
            open={uploadRunModalOpen}
            onClose={() => setUploadRunModalOpen(false)}
          />

          <Title />
          {displayButtons}
          {displayExport}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    showChart: getShowChart(state),
    depth: getSidebarDepth(state),
    canUploadRun: canUserAccessRunUploading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openAddFolderModal: () => {
      dispatch(openAddFolderModalAction());
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentHeader)
);
