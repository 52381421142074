import { createSelector } from "reselect";

import { runTypes, BIORAD_DETAILS_VERSION } from "./constants";
import { checkIfDetailsVersionIsExternalRun } from "./helpers";

export const getCurrentPaths = state => state.hub.paths;
export const getActiveTab = state => state.hub.activeTab;
export const getActiveFilters = state => state.hub.activeFilters;
export const getSelectedRuns = state => state.hub.selectedRuns;
export const getShowChart = state => state.hub.showChart;
export const getHubLoading = state => state.hub.loading;
export const getPreSelectedFilters = state => state.hub.preSelectedFilters;
export const getShowSearch = state => state.hub.showSearch;
export const getShowedFilterBar = state => state.hub.showedFilterBar;
export const getSearchQuery = state => state.hub.searchQuery;
export const getRunSelectionType = state => state.hub.runSelectionType;
export const getRunSelectionDetailsVersion = state =>
  state.hub.runSelectionDetailsVersion;

export const getSelectedProtocolId = state => {
  const { selectedRuns } = state.hub;

  let id = null;
  if (state.hub.selectedRuns[0]) {
    const { protocolId } = selectedRuns[0];

    id = protocolId.toLowerCase();
  }

  return id;
};

export const getRunsTablePage = state => state.hub.page;

export const isSelectedRunExternalUpload = createSelector(
  [getRunSelectionDetailsVersion],
  runSelectionDetailsVersion =>
    checkIfDetailsVersionIsExternalRun(runSelectionDetailsVersion)
);

export const isSelectedRunBioRad = createSelector(
  [getRunSelectionDetailsVersion],
  runSelectionDetailsVersion =>
    runSelectionDetailsVersion &&
    runSelectionDetailsVersion.toLowerCase() === BIORAD_DETAILS_VERSION
);

export const isRunTypeMelt = state =>
  state.hub.runSelectionType === runTypes.melt;
export const isRunTypeIsothermal = state =>
  state.hub.runSelectionType === runTypes.isothermal;

export const getMetricsLabel = createSelector(
  [getRunSelectionType],
  runSelectionType => {
    if (runSelectionType === runTypes.melt) {
      return "Peak";
    }

    return "Cq";
  }
);
