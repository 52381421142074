import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";

import { createQRText, shortenText } from "../helpers";
import { tooltipDelay } from "../../RunsPage/constants";
import QRCodeGenerator from "./QRCodeGenerator";
import Button from "../../Layout/Button";
import PrintIconButton from "../../Utils/Layout/PrintIconButton";
import DuplicateIconButton from "../../Utils/Layout/DuplicateIconButton";

const Record = props => {
  const {
    record,
    handleDelete = null,
    printClick = null,
    duplicateClick = null,
  } = props;

  const { id, shortName, metadata = [] } = record;

  const fieldsPerColumn = Math.ceil(metadata.length / 2);

  const column1Fields = metadata.slice(0, fieldsPerColumn);
  const column2Fields = metadata.slice(fieldsPerColumn, metadata.length);

  const renderColumnFields = column => {
    return column.map(field => {
      return (
        <div key={field}>
          <span
            className="sample-hub__column-field-name"
            data-tip={field.parameterName}
          >
            {field.parameterName}
          </span>
          <p data-tip={field.value}>
            {shortenText(field.value || "\u00a0", 20)}
          </p>
        </div>
      );
    });
  };

  const renderPrintButton = () => {
    const wrappedIcon = () => <PrintIconButton style={{ marginRight: 10 }} />;

    return (
      <Button
        buttonStyle={{ marginBottom: 10, width: 180 }}
        onClick={() => printClick(id)}
        icon={wrappedIcon}
        title="Print"
      />
    );
  };

  const renderDuplicateButton = () => {
    const { template } = props;
    const wrappedIcon = () => (
      <DuplicateIconButton style={{ marginRight: 10 }} />
    );

    let dataTip = "Cannot be duplicated, layout has been deleted";
    if (template) {
      dataTip = "";
    }

    return (
      <Fragment>
        <ReactTooltip delayShow={tooltipDelay / 2} />
        <span data-tip={dataTip}>
          <Button
            buttonStyle={{
              marginBottom: 28,
              width: 180,
            }}
            onClick={() => duplicateClick(record)}
            icon={wrappedIcon}
            title="Duplicate"
            disabled={!template}
            id="sample-hub__duplicate-button"
          />
        </span>
      </Fragment>
    );
  };

  const renderDeleteButton = () => {
    return (
      <span
        className="sample-hub__delete"
        onClick={() => handleDelete(id, "record")}
      >
        Delete
      </span>
    );
  };

  return (
    <div className="sample-hub__table-view" id={id}>
      <ReactTooltip delayShow={tooltipDelay / 2} />
      <div className="sample-hub__record">
        <div
          className="sample-hub__record-left"
          style={{ marginTop: 42, marginBottom: 42, marginLeft: 42 }}
        >
          <QRCodeGenerator
            qrText={createQRText(id, shortName)}
            width={112}
            height={112}
          />
          <span style={{ marginBottom: 30, fontWeight: 600 }}>{shortName}</span>
          {renderPrintButton()}
          {renderDuplicateButton()}
          {renderDeleteButton()}
        </div>
        <div
          className="sample-hub__record-right"
          style={{ marginTop: 42, marginBottom: 42, marginLeft: 42 }}
        >
          <div className="sample-hub__record-column">
            {renderColumnFields(column1Fields)}
          </div>
          <div className="sample-hub__record-column">
            {renderColumnFields(column2Fields)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Record;
