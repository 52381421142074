import React, { Component } from "react";
import { connect } from "react-redux";

import * as a from "../../../../actions";
import { getWellFilters, getTargetsByRun } from "../../../../selectors";
import { CHANNELS } from "../../../../../Hub/constants";
import { BIORAD_WELL_IDENTIFIERS } from "../../../../constants";
import styles from "../styles";

class BioRadTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      runWellFilters: props.wellFilters,
    };
  }

  determineClassName = wellFilter => {
    const { targetsByRun, channel } = this.props;

    let tdClassName = "active";

    const targetNotHiddenOrShown = targetsByRun.find(
      target =>
        target.well === wellFilter.label &&
        !target.showLine &&
        !target.hideTarget &&
        target.channel === channel
    );

    if (targetNotHiddenOrShown) {
      tdClassName = "";
    } else if (
      !targetsByRun.find(
        target => target.channel === channel && !target.hideTarget
      )
    ) {
      tdClassName = "disabled";
    }

    return tdClassName;
  };

  handleWellFilterSelection(enabled, associatedWells) {
    const { runWellFilters } = this.state;
    const { applyRunFilters, runBlock, channel } = this.props;
    const { runId } = runBlock;

    const updatedRunWellFilters = runWellFilters.map(wellFilter => {
      if (associatedWells.includes(wellFilter.label)) {
        return {
          ...wellFilter,
          checked: !enabled,
        };
      }

      const className = this.determineClassName(wellFilter);
      const checked = className === "active";

      return {
        ...wellFilter,
        checked,
      };
    });

    this.setState({
      runWellFilters: updatedRunWellFilters,
    });

    applyRunFilters(runId, [], updatedRunWellFilters, channel);
  }

  renderChannelRow(associatedWells) {
    const { runId } = this.props;

    return associatedWells.map(well => this.renderCell(well, runId));
  }

  renderCell(well, runId) {
    const { channel, targetsByRun, toggleSingleTarget } = this.props;
    const targetData = targetsByRun.find(
      target => target.well === well && target.channel === channel
    );

    if (!targetData) {
      return <td key={`td-well-disabled-${well}`} className="disabled" />;
    }

    let className = "";

    if (targetData.hideTarget) {
      className = "disabled";
    } else if (targetData.showLine) {
      className = "active";
    }

    const keyTD = `td-well-${targetData.channel}-${targetData.well}`;

    const channelIndex = CHANNELS.values.indexOf(channel);

    return (
      <td
        key={keyTD}
        id={keyTD}
        onClick={() =>
          toggleSingleTarget(targetData.showLine, runId, well, channelIndex)
        }
        className={className}
      />
    );
  }

  renderWellFilters(filterType) {
    const { runBlock, targetsByRun, channel } = this.props;
    const { runId, availableWells } = runBlock;

    const { numbers: wellNumbers, letters: wellLetters } =
      BIORAD_WELL_IDENTIFIERS;

    const filterTypeIsRow = filterType === "row";

    let primaryIdentifiers;
    let secondaryIdentifiers;

    if (filterTypeIsRow) {
      primaryIdentifiers = wellNumbers;
      secondaryIdentifiers = wellLetters;
    } else {
      primaryIdentifiers = wellLetters;
      secondaryIdentifiers = wellNumbers;
    }

    return primaryIdentifiers.map(primaryIdentifier => {
      const associatedWells = secondaryIdentifiers.map(secondaryIdentifier => {
        if (filterTypeIsRow) {
          return `${secondaryIdentifier}${primaryIdentifier}`;
        }

        return `${primaryIdentifier}${secondaryIdentifier}`;
      });

      let wellNotInAvailableWells = false;
      const matchedWell = associatedWells.find(associatedWell =>
        availableWells.includes(associatedWell)
      );

      if (!matchedWell) {
        wellNotInAvailableWells = true;
      }

      let tdClassName = "active";
      let spanClassName = "";

      if (wellNotInAvailableWells) {
        tdClassName = "disabled";
        spanClassName = "disabled";
      } else {
        const targetNotHiddenOrShown = targetsByRun.find(
          target =>
            associatedWells.includes(target.well) &&
            !target.showLine &&
            !target.hideTarget &&
            target.channel === channel
        );

        if (targetNotHiddenOrShown) {
          tdClassName = "";
        } else if (
          !targetsByRun.find(
            target => target.channel === channel && !target.hideTarget
          )
        ) {
          tdClassName = "disabled";
        }
      }

      const enabled = tdClassName === "active";

      const keyTD = `td-wellNumberFilter-${channel}-${runId}-${primaryIdentifier}`;
      const keyTR = `tr-wellLetter-${channel}-${primaryIdentifier}-${runId}`;

      if (filterTypeIsRow) {
        return (
          <td
            key={keyTD}
            className={tdClassName}
            onClick={() =>
              this.handleWellFilterSelection(enabled, associatedWells)
            }
          >
            <span id={keyTD} className={spanClassName}>
              {primaryIdentifier}
            </span>
          </td>
        );
      }

      return (
        <tr key={keyTR}>
          <td
            key={keyTD}
            className={tdClassName}
            onClick={() =>
              this.handleWellFilterSelection(enabled, associatedWells)
            }
          >
            <span id={keyTD} className={spanClassName}>
              {primaryIdentifier}
            </span>
          </td>
          {this.renderChannelRow(associatedWells)}
        </tr>
      );
    });
  }

  renderTopRow() {
    const { runWellFilters } = this.state;
    const { runBlock, targetsByRun, toggleAllRunTargets, channel } = this.props;
    const { runId } = runBlock;

    const inactiveTarget = targetsByRun.find(
      target =>
        target.channel === channel && !target.showLine && !target.hideTarget
    );

    let allTargetsAreActive = true;
    if (inactiveTarget) {
      allTargetsAreActive = false;
    }

    let allButtonClassName = "";
    if (allTargetsAreActive) {
      allButtonClassName = "active";
    }

    return (
      <tr key="All Channels">
        <td
          key="td-All-Channels"
          className={allButtonClassName}
          onClick={() => {
            this.setState({
              runWellFilters: runWellFilters.map(well => {
                return {
                  ...well,
                  checked: !allTargetsAreActive,
                };
              }),
            });
            toggleAllRunTargets(!allTargetsAreActive, runId, channel);
          }}
        >
          <span id={`${runId}-${channel}-All`}>All</span>
        </td>
        {this.renderWellFilters("row")}
      </tr>
    );
  }

  render() {
    const { runId, channel, tableKey } = this.props;
    return (
      <div style={styles.runTableContainer}>
        <table
          className="runtable"
          id={`${channel}-table-${runId}`}
          style={{ width: "auto", height: "auto" }}
          key={tableKey}
        >
          <tbody>
            {this.renderTopRow()}
            {this.renderWellFilters("column")}
          </tbody>
        </table>
        <div style={{ height: 40 }} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    targetsByRun: getTargetsByRun(state, ownProps),
    wellFilters: getWellFilters(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSingleTarget: (active, runId, well, channelNum) => {
      if (active) {
        dispatch(a.hideSingleTarget(runId, well, channelNum));
      } else {
        dispatch(a.showSingleTarget(runId, well, channelNum));
      }
    },
    toggleAllRunTargets: (active, runId, channel) =>
      dispatch(a.toggleAllRunTargets(active, runId, channel)),
    applyRunFilters: (runId, channels, wells, wellChannel) => {
      dispatch(a.applyRunFilters(runId, channels, wells, wellChannel));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BioRadTable);
