import React from "react";
import { connect } from "react-redux";

import { Modal } from "bio-web-components";

import { getSelectedFolder } from "../../../Sidebar/selectors";
import { selectFolder } from "../../../Sidebar/actions";

import ArchiveForm from "../../../ExternalUpload/Components/ArchiveForm";
import { Dispatch } from "redux";

interface Props {
  open: boolean;

  onClose: () => void;
}

interface StateProps {
  activeFolder: any;
}

interface DispatchProps {
  selectFolderRequest: (folder: any) => void;
}

const UploadRunModal = (props: Props & StateProps & DispatchProps) => {
  const UPLOAD_REDIRECT_DELAY = 1000;

  const { open, onClose } = props;

  const handleUploadSuccess = (callback: Function) =>
    setTimeout(() => {
      const { activeFolder, selectFolderRequest } = props;
      selectFolderRequest(activeFolder);

      callback();
      onClose();
    }, UPLOAD_REDIRECT_DELAY);

  return (
    <Modal heading={null} open={open} onClose={onClose}>
      <div className="upload_run_modal">
        <ArchiveForm onUploadSuccess={handleUploadSuccess} />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  activeFolder: getSelectedFolder(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectFolderRequest: (folder: any) => dispatch(selectFolder(folder)),
});

export default connect<StateProps, DispatchProps, Props, any>(
  mapStateToProps,
  mapDispatchToProps
)(UploadRunModal);
