import * as t from "./actionTypes";
import {
  defaultBulkFullRunDataObject,
  defaultFullRunDataBucket,
} from "./constants";
import {
  FullRunDataBucket,
  FullRunDataState,
} from "./types";

const initState: FullRunDataState = {
  fullRunData: defaultFullRunDataBucket as FullRunDataBucket,
  bulkFullRunData: defaultBulkFullRunDataObject,
};

function reducer(state = initState, action: any): FullRunDataState {
  const { type, payload } = action;
  switch (type) {
    case t.GET_FULL_RUN_SUCCESS: {
      return { ...state, fullRunData: { ...state.fullRunData, data: payload } };
    }

    case t.GET_FULL_RUN_BULK_REVIEW_SUCCESS: {
      const { detailsVersion } = action;

      return {
        ...state,
        bulkFullRunData: {
          ...state.bulkFullRunData,
          [detailsVersion]: { data: payload, fetched: true },
        },
      };
    }
    case t.VALIDATE_SAMPLE_IDS_SUCCESS: {
      return { ...state, fullRunData: { data: payload, fetched: true } };
    }
    case t.GET_FULL_RUN_BULK_REVIEW_ERROR: {
      return state;
    }
    case t.RESET: {
      return initState;
    }
    default: {
      return state;
    }
  }
}

export default reducer;
