import { FormState } from "./types";
import { EMAIL_VALIDATION_REGEX } from "./constants";

const REQUIRED_KEYS = ["email", "firstName", "lastName", "teamName"];

export function validateForm(fields: FormState): {
  isValid: boolean;
  errors: FormState;
} {
  const errors: FormState = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    teamName: "",
    notes: "",
  };

  Object.keys(fields).forEach(key => {
    if (REQUIRED_KEYS.indexOf(key) !== -1 && fields[key].length < 1) {
      errors[key] = "Field is required";
    }
  });

  if (fields.email.length > 0 && !EMAIL_VALIDATION_REGEX.test(fields.email)) {
    errors.email = "Invalid email format";
  }

  let isValid = true;
  Object.keys(errors).forEach(key => {
    if (errors[key].length > 0) {
      isValid = false;
    }
  });

  return { isValid, errors };
}

export function getResponsiveClassname(
  isMobile: boolean,
  baseClassname: string
): string {
  if (isMobile) {
    return `${baseClassname}_mobile`;
  }

  return baseClassname;
}

export const errorResponse = (): string => {
  return "An unexpected error occurred. Please try again or contact Biomeme support.";
};
