import React, { Component } from "react";
import { connect } from "react-redux";
import noUiSlider from "nouislider";

import {
  startBackgroundResetSequence,
  updateBackgroundValues as actionUpdateBackgroundValues,
} from "../actions";
import {
  getActiveTargetData,
  getShortestLengthOfActiveRuns,
  getBackgroundReprocessingError,
} from "../selectors";

class Slider extends Component {
  componentDidMount() {
    this.createSlider();

    document
      .getElementById("slider")
      .noUiSlider.on("update", this.handleSliderChange);
  }

  createSlider = () => {
    noUiSlider.create(document.getElementById("slider"), {
      start: [5, 6], // initial starting values for the handles
      connect: true,
      step: 1, // handles increment by this amount
      tooltips: true, // display current values at each handle
      range: {
        min: 0,
        max: 100,
      },
      width: "100%",
    });
  };

  handleSliderSubmit = () => {
    const { shortestLengthOfActiveRuns, updateBackgroundValues } = this.props;

    const values = document.getElementById("slider").noUiSlider.get();

    if (values[1] > shortestLengthOfActiveRuns) {
      values[1] = shortestLengthOfActiveRuns;
    }

    updateBackgroundValues(values);
  };

  handleSliderReset = () => {
    const { resetBackground } = this.props;
    resetBackground();
  };

  handleSliderChange = values => {
    const { handleSliderChange } = this.props;

    if (values) {
      handleSliderChange(values);
    } else {
      const getValues = document.getElementById("slider").noUiSlider.get();
      if (getValues) {
        handleSliderChange(getValues);
      }
    }
  };

  renderBackgroundValuesReprocessingError = () => {
    const { backgroundReprocessingError } = this.props;

    let error = null;

    if (backgroundReprocessingError) {
      error = (
        <p
          style={{
            marginTop: "5px",
            fontSize: "12pt",
            color: "rgba(255, 0, 0, 0.616)",
            fontStyle: "italic",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          There was an error reprocessing, please try again.
        </p>
      );
    }

    return error;
  };

  render() {
    const { width, activeTargets } = this.props;
    const chartPadding = 70;

    let displayStyle = "none";
    if (activeTargets.length > 0) {
      displayStyle = "flex";
    }

    return (
      <div
        style={{
          display: displayStyle,
          flexDirection: "column",
        }}
      >
        <div
          id="slider"
          className="reprocessing-slider"
          style={{
            width: width - chartPadding,
            marginLeft: "60px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "27px",
            marginBottom: "5px",
          }}
        >
          <button
            className="btn-data detailed_data_button Accept"
            style={{ marginRight: "24px" }}
            onClick={this.handleSliderSubmit}
            data-tip="Reprocess the selected data with the current left value and right value."
          >
            Apply Background
          </button>
          <button
            className="btn-data detailed_data_button Reset"
            onClick={this.handleSliderReset}
            data-tip="Reset all curves to original reprocessed data."
          >
            Reset Values
          </button>
        </div>
        {this.renderBackgroundValuesReprocessingError()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeTargets: getActiveTargetData(state),
    shortestLengthOfActiveRuns: getShortestLengthOfActiveRuns(state),
    backgroundReprocessingError: getBackgroundReprocessingError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetBackground: () => {
      dispatch(startBackgroundResetSequence());
    },
    updateBackgroundValues: values => {
      dispatch(actionUpdateBackgroundValues(values));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
