import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";

import { tooltipDelay } from "../../RunsPage/constants";

const IncompleteIcon = props => {
  const { opacity } = props;

  return (
    <Fragment>
      <ReactTooltip delayShow={tooltipDelay / 2} />
      <div
        className="incomplete-run-icon"
        data-tip="Run was stopped before completion."
        style={{
          display: "flex",
          alignItems: "center",
          opacity,
        }}
      >
        <svg width={18} height={15} {...props}>
          <g fill="#FAAE17" fillRule="evenodd">
            <path d="M8.302 9.994h1.172l.414-4.687s-.296-.26-1-.26-1 .26-1 .26l.414 4.687zM7.888 12.008a1 1 0 1 0 2 0 1 1 0 0 0-2 0z" />
            <path d="M15.092 13.951H2.684c-1.123 0-.567-.96-.567-.96L8.718 1.614s.062-.099.17-.099c.107 0 .169.099.169.1l6.608 11.39c.06.11.476.947-.573.947m2.12-.898L10.018.649A1.304 1.304 0 0 0 8.887 0c-.465 0-.896.247-1.129.649L.562 13.053a1.294 1.294 0 0 0 0 1.298c.233.401.664.649 1.13.649h14.392c.465 0 .896-.248 1.129-.65a1.29 1.29 0 0 0 0-1.297" />
          </g>
        </svg>
      </div>
    </Fragment>
  );
};

export default IncompleteIcon;
