import React from "react";
import { connect } from "react-redux";

import * as a from "../../../actions";
import {
  getAllChannels,
  getWellFilters,
  getTargetsByRun,
  isBaselineChartActive,
} from "../../../selectors";
import { isRunTypeMelt } from "../../../../Hub/selectors";
import DropdownBox from "../../../../Layout/DropdownBox";
import Checkbox from "../../../../Layout/Checkbox";
import IncompleteIcon from "../../../../Utils/Layout/IncompleteIcon";
import styles from "./styles";
import RunBlockButtons from "./RunBlockButtons";

class RunBlockTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      runChannelFilters: props.channelFilters,
      runWellFilters: props.wellFilters,
    };
  }

  handleWellFilterSelection(well, wellIndex) {
    const { applyRunFilters, runBlock } = this.props;
    const { runWellFilters } = this.state;

    const { runId } = runBlock;
    const { label, checked } = well;

    const updatedWell = {
      label,
      checked: !checked,
    };

    this.setState({
      runWellFilters: [
        ...runWellFilters.slice(0, wellIndex),
        updatedWell,
        ...runWellFilters.slice(wellIndex + 1),
      ],
    });

    applyRunFilters(runId, [], [updatedWell]);
  }

  handleChannelFilterSelection(channel, channelIndex) {
    const { applyRunFilters, runBlock } = this.props;
    const { runChannelFilters } = this.state;
    const { label, checked } = channel;
    const { runId } = runBlock;

    const updatedChannel = {
      label,
      checked: !checked,
    };

    this.setState({
      runChannelFilters: [
        ...runChannelFilters.slice(0, channelIndex),
        updatedChannel,
        ...runChannelFilters.slice(channelIndex + 1),
      ],
    });

    applyRunFilters(runId, [updatedChannel], []);
  }

  renderRunToggle() {
    const { runBlock, toggleRunBlock } = this.props;

    return (
      <Checkbox
        key={`checkbox run ${runBlock.runId}`}
        checked={runBlock.active}
        onClick={() => toggleRunBlock(runBlock.active, runBlock.runId)}
      />
    );
  }

  renderChannelRow(channel) {
    const { runBlock, totalWells } = this.props;

    return totalWells.map((well, i) =>
      this.renderCell(i, channel, runBlock.runId)
    );
  }

  renderCell(wellIndex, channel, runId) {
    const { targetsByRun, toggleSingleTarget } = this.props;

    const targetData = targetsByRun.find(
      target => target.well === wellIndex && target.channel === channel
    );

    if (!targetData) {
      return null;
    }

    let className = "";

    if (targetData.hideTarget) {
      className = "disabled";
    } else if (targetData.showLine) {
      className = "active";
    }

    const key = `td-well-${targetData.channel}-${targetData.well}`;

    return (
      <td
        key={key}
        onClick={() =>
          toggleSingleTarget(
            targetData.showLine,
            runId,
            wellIndex,
            targetData.channelNumber
          )
        }
        className={className}
      />
    );
  }

  renderWellFilters() {
    const { runBlock, targetsByRun } = this.props;
    const { runWellFilters } = this.state;
    const { runId, availableWells } = runBlock;

    return runWellFilters.map((well, wellIndex) => {
      const { label } = well;
      const wellNotInAvailableWells = availableWells.indexOf(wellIndex) < 0;

      let tdClassName = "active";
      let spanClassName = "";

      if (wellNotInAvailableWells) {
        tdClassName = "disabled";
        spanClassName = "disabled";
      } else {
        const targetNotHiddenOrShown = targetsByRun.find(
          target =>
            target.well === well.label && !target.showLine && !target.hideTarget
        );

        if (targetNotHiddenOrShown) {
          tdClassName = "";
        }
      }

      return (
        <td
          key={`td-wellFilter-${runId}-${label}`}
          className={tdClassName}
          onClick={() => this.handleWellFilterSelection(well, wellIndex)}
        >
          <span className={spanClassName}>{label + 1}</span>
        </td>
      );
    });
  }

  renderTopRow() {
    const { runBlock, targetsByRun, toggleAllRunTargets } = this.props;
    const { runId } = runBlock;

    const inactiveTarget = targetsByRun.find(
      target => !target.showLine && !target.hideTarget
    );

    let allTargetsAreActive = true;
    if (inactiveTarget) {
      allTargetsAreActive = false;
    }

    let allButtonClassName = "";
    if (allTargetsAreActive) {
      allButtonClassName = "active";
    }

    return (
      <tr key="All Channels">
        <td style={{ border: "none", pointerEvents: "none" }} />
        <td
          className={allButtonClassName}
          onClick={() => toggleAllRunTargets(!allTargetsAreActive, runId)}
        >
          <span>All</span>
        </td>
        {this.renderWellFilters()}
      </tr>
    );
  }

  renderChannelFilters() {
    const { runBlock, targetsByRun } = this.props;
    const { runChannelFilters } = this.state;
    const { name } = runBlock;

    return runChannelFilters.map((channel, channelIndex) => {
      const { label } = channel;
      const lowercasedChannelLabel = label.toLowerCase();

      let cellClassName = "active";
      let circleFill = lowercasedChannelLabel;

      if (circleFill === "amber") {
        circleFill = "#f5a623";
      }

      const associatedTargetThatIsNotHidden = targetsByRun.find(
        target =>
          target.channel.toLowerCase() === lowercasedChannelLabel &&
          !target.hideTarget
      );

      if (!associatedTargetThatIsNotHidden) {
        cellClassName = "";
      } else {
        const targetThatIsNotHiddenButAlsoNotShown = targetsByRun.find(
          target =>
            target.channel.toLowerCase() === lowercasedChannelLabel &&
            !target.hideTarget &&
            !target.showLine
        );

        if (targetThatIsNotHiddenButAlsoNotShown) {
          cellClassName = "";
        }
      }

      const keyBase = `channel-${name}-${label}`;

      return (
        <tr key={`tr-${keyBase}`}>
          <td key={`tr-${keyBase}-circle`} style={styles.channelCircle}>
            <svg height="17" width="15">
              <circle cx="6" cy="10" r="4" fill={circleFill} />
            </svg>
          </td>

          <td
            key={`td-${keyBase}`}
            className={cellClassName}
            onClick={() =>
              this.handleChannelFilterSelection(channel, channelIndex)
            }
          >
            <span className={label.slice(0, 1).toUpperCase()}>
              {label.slice(0, 1).toUpperCase()}
            </span>
          </td>
          {this.renderChannelRow(lowercasedChannelLabel)}
        </tr>
      );
    });
  }

  render() {
    const {
      runBlock,
      isSingleRun,
      isBaselineActive,
      singleThresholdsEnabled,
      toggleRunThreshold,
      openDetailedModal,
      runTypeIsMelt,
      opened,
    } = this.props;
    const { status, name, type } = runBlock;

    let icon = null;
    if (status === "incomplete") {
      icon = <IncompleteIcon style={styles.incompleteIconMargins} />;
    }

    let toggleFunction = this.renderRunToggle();
    if (isSingleRun) {
      toggleFunction = null;
    }

    return (
      <DropdownBox
        toggle={toggleFunction}
        heading={name}
        icon={icon}
        key={name}
        preHeading={type}
        opened={opened}
      >
        <div style={styles.runTableContainer}>
          <table className="runtable">
            <tbody>
              {this.renderTopRow()}
              {this.renderChannelFilters()}
            </tbody>
          </table>
          <RunBlockButtons
            runBlock={runBlock}
            isBaselineActive={isBaselineActive}
            singleThresholdsEnabled={singleThresholdsEnabled}
            runTypeIsMelt={runTypeIsMelt}
            toggleRunThreshold={toggleRunThreshold}
            openDetailedModal={openDetailedModal}
          />
        </div>
      </DropdownBox>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    targetsByRun: getTargetsByRun(state, ownProps),
    channelFilters: getAllChannels(state),
    wellFilters: getWellFilters(state),
    totalWells: getWellFilters(state).map(well => well.label),
    isBaselineActive: isBaselineChartActive(state),
    runTypeIsMelt: isRunTypeMelt(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSingleTarget: (active, runId, well, channelNum) => {
      if (active) {
        dispatch(a.hideSingleTarget(runId, well, channelNum));
      } else {
        dispatch(a.showSingleTarget(runId, well, channelNum));
      }
    },
    toggleAllRunTargets: (active, runId) =>
      dispatch(a.toggleAllRunTargets(active, runId)),
    applyRunFilters: (runId, channels, wells) => {
      dispatch(a.applyRunFilters(runId, channels, wells));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RunBlockTable);
