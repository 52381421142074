import React, { useEffect } from "react";

import { DetailsVersion } from "js-common";
import { Select } from "bio-web-components";

import { EXTERNAL_DETAILS_VERSION_OPTIONS } from "../constants";
import {
  ChannelSelectionProps,
  DetailsVersionProps,
  FileProps,
  FileValidationProps,
} from "../types";

import BioradFileSelector from "../Subcomponents/BioradFileSelector";
import AbiFileSelector from "../Subcomponents/AbiFileSelector";
import ArchiveFormHeader from "../Subcomponents/ArchiveFormHeader";

interface Props {
  fileProps: FileProps;
  detailsVersionProps: DetailsVersionProps;
  channelSelectionProps: ChannelSelectionProps;
  fileValidationProps: FileValidationProps;
}

const ArchiveFormFileSelector = (props: Props) => {
  const {
    detailsVersionProps,
    fileProps,
    channelSelectionProps,
    fileValidationProps,
  } = props;
  const { detailsVersion, setDetailsVersion } = detailsVersionProps;
  const { setFiles } = fileProps;

  useEffect(() => {
    setFiles([]);
  }, [detailsVersion]);

  const _renderFileSelector = () => {
    let fileSelector: JSX.Element | null = null;

    if (detailsVersion) {
      const { setFileValidationError } = fileValidationProps;

      if (detailsVersion === "biorad") {
        fileSelector = (
          <BioradFileSelector
            fileProps={fileProps}
            channelSelectionProps={channelSelectionProps}
            setFileValidationError={setFileValidationError}
          />
        );
      }

      if (detailsVersion === "abi") {
        fileSelector = (
          <AbiFileSelector
            fileProps={fileProps}
            setFileValidationError={setFileValidationError}
          />
        );
      }
    }

    return fileSelector;
  };

  const _renderFileValidationError = () => {
    const { fileValidationError } = fileValidationProps;

    if (fileValidationError) {
      return (
        <div className="archive_form-file-error app_error">
          {fileValidationError}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ArchiveFormHeader title="Upload Run" />
      <div className="archive_form_file-selection-container">
        <div className="archive_form-file-selection-container">
          <Select
            label="RUN SOURCE"
            options={EXTERNAL_DETAILS_VERSION_OPTIONS}
            value={detailsVersion}
            onChange={(version: DetailsVersion) => setDetailsVersion(version)}
          />
        </div>
        {_renderFileSelector()}
        {_renderFileValidationError()}
      </div>
    </>
  );
};

export default ArchiveFormFileSelector;
