import React, { Fragment } from "react";
import { RadioButton, Input, Row } from "bio-web-components";

const CyclingParameters = props => {
  const { setValue, values, errors } = props;

  const renderCyclingDenature = () => {
    return (
      <Fragment>
        <div className="protocol_creation-section">
          <h3 className="protocol_creation-label">Cycling Denature</h3>
          <div className="protocol_creation-box">
            <Row>
              <Input
                value={values.denatureTemp}
                onChange={value => setValue("denatureTemp", value)}
                error={errors.denatureTemp}
                label="Set Temperature (°C)"
              />
              <Input
                value={values.denatureTime}
                error={errors.denatureTime}
                onChange={value => setValue("denatureTime", value)}
                label="Set Time (secs)"
              />
            </Row>
          </div>
        </div>
        <div className="protocol_creation-section">
          <h3 className="protocol_creation-label">Cycling Anneal</h3>
          <div className="protocol_creation-box">
            <Row>
              <Input
                value={values.annealTemp}
                onChange={value => setValue("annealTemp", value)}
                error={errors.annealTemp}
                label="Set Temperature (°C)"
              />
              <Input
                value={values.annealTime}
                onChange={value => setValue("annealTime", value)}
                error={errors.annealTime}
                label="Set Time (secs)"
              />
              <Input
                value={values.cycles}
                onChange={value => setValue("cycles", value)}
                error={errors.cycles}
                label="Cycles"
              />
            </Row>
          </div>
        </div>
      </Fragment>
    );
  };

  const _renderExtensionToggle = () => {
    const { showExtension } = values;
    let styleModifier = "protocol_creation-inactive";
    if (showExtension) {
      styleModifier = "";
    }

    return (
      <Fragment>
        <h3 className={`protocol_creation-label ${styleModifier}`}>
          Extension
        </h3>
        <Row>
          <RadioButton
            label="Extension"
            checked={values.showExtension}
            onClick={() => setValue("showExtension", !values.showExtension)}
          />
        </Row>
      </Fragment>
    );
  };

  const _renderExtensionValues = () => {
    if (values.showExtension) {
      return (
        <div className="protocol_creation-box">
          <Row>
            <Input
              value={values.extensionTemp}
              onChange={value => setValue("extensionTemp", value)}
              error={errors.extensionTemp}
              label="Set Temperature (°C)"
            />
            <Input
              value={values.extensionTime}
              onChange={value => setValue("extensionTime", value)}
              error={errors.extensionTime}
              label="Set Time (secs)"
            />
          </Row>
        </div>
      );
    }

    return null;
  };

  const _renderExtension = () => {
    return (
      <div className="protocol_creation-section">
        {_renderExtensionToggle()}
        {_renderExtensionValues()}
      </div>
    );
  };

  return (
    <Fragment>
      {renderCyclingDenature()}
      {_renderExtension()}
    </Fragment>
  );
};

export default CyclingParameters;
