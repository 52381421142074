import React from "react";

const AddFolderIcon = props => (
  <svg width={60} height={60} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-690.000000, -242.000000)">
        <g transform="translate(690.000000, 242.000000)">
          <g fill="#29D2C9" fillRule="nonzero">
            <g>
              <path d="M29.5081967,55.2574292 C43.6322002,55.2574292 55.0819672,43.8076621 55.0819672,29.6836587 C55.0819672,15.5596553 43.6322002,4.1098882 29.5081967,4.1098882 C15.3841933,4.1098882 3.93442623,15.5596553 3.93442623,29.6836587 C3.93442623,43.8076621 15.3841933,55.2574292 29.5081967,55.2574292 Z M29.5081967,59.1918554 C13.2112697,59.1918554 0,45.9805857 0,29.6836587 C0,13.3867316 13.2112697,0.175461971 29.5081967,0.175461971 C45.8051238,0.175461971 59.0163934,13.3867316 59.0163934,29.6836587 C59.0163934,45.9805857 45.8051238,59.1918554 29.5081967,59.1918554 Z" />
            </g>
          </g>
          <path
            d="M18,18.038208 L18,37 L43,37 L43,20.0537109 L27.7099018,20.0537109 L24.1526508,18.038208 L18,18.038208 Z"
            stroke="#012639"
            strokeWidth="2"
          />
          <g transform="translate(22.000000, 29.000000)">
            <circle
              stroke="#012639"
              strokeWidth="2"
              fill="#FFFFFF"
              cx="8"
              cy="8"
              r="8"
            />
            <g
              transform="translate(4.000000, 4.000000)"
              fill="#012639"
              fillRule="nonzero"
            >
              <g>
                <g>
                  <rect x="3.6" y="0" width="1.8" height="8" />
                  <rect
                    transform="translate(4.500000, 4.000000) rotate(90.000000) translate(-4.500000, -4.000000) "
                    x="3.6"
                    y="0"
                    width="1.8"
                    height="8"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AddFolderIcon;
