import React from "react";
import { connect } from "react-redux";
import { uniqBy } from "lodash";

import { applyMasterFilters } from "../../../actions";
import { getActiveTeamVersion } from "../../../../Auth/selectors";
import { getTargetData, getBioRadTargetMatrix } from "../../../selectors";
import { getFullRunData } from "../../../../FullRunData/selectors";
import { extractSampleNames, reformTargetMatrix } from "../../../helpers";
import { getSampleStylingParamters } from "./helpers";
import { UNSPECIFIED_SAMPLE_TEXT } from "../../../constants";
import DropdownBox from "../../../../Layout/DropdownBox";

const BioRadSampleSelector = props => {
  const {
    runs,
    runTargets,
    detailsVersion,
    updateMasterFilters,
    targetMatrix,
  } = props;
  const [run] = runs;

  const deactivatedTargets = runTargets.filter(
    target => !target.hideTarget && !target.showLine
  );

  const allWellsAreActive = deactivatedTargets.length < 1;

  const setActiveState = (
    newTargetWell,
    associatedWells,
    previousActiveState
  ) => {
    const associatedWellsIncludesNewTargetWell =
      associatedWells.includes(newTargetWell);

    if (
      previousActiveState &&
      allWellsAreActive &&
      associatedWellsIncludesNewTargetWell
    ) {
      return true;
    }

    if (previousActiveState && !allWellsAreActive) {
      return true;
    }

    if (associatedWellsIncludesNewTargetWell) {
      return true;
    }

    return false;
  };

  const createArrayOfAssociatedWells = (
    sampleId,
    sampleIdWasUnspecified,
    wellNumber
  ) => {
    const associatedWells = [];

    if (sampleIdWasUnspecified) {
      return [wellNumber];
    }

    runTargets.forEach(runTarget => {
      if (runTarget.sampleId === sampleId) {
        associatedWells.push(runTarget.well);
      }
    });

    return associatedWells;
  };

  const handleSampleSelection = (
    sampleId,
    sampleIdWasUnspecified,
    wellNumber,
    previousActiveState,
    runId
  ) => {
    const associatedWells = createArrayOfAssociatedWells(
      sampleId,
      sampleIdWasUnspecified,
      wellNumber
    );

    const newTargets = targetMatrix.map(newTarget => {
      const active = setActiveState(
        newTarget.well,
        associatedWells,
        previousActiveState
      );

      return {
        ...newTarget,
        active,
      };
    });

    const reformedTargetMatrix = reformTargetMatrix(newTargets);
    updateMasterFilters(reformedTargetMatrix, runId);
  };

  const getUniqueSampleNames = () => {
    const sampleNames = extractSampleNames(run, detailsVersion).map(sample => {
      const { wellNumber, sampleId } = sample;

      if (sampleId === UNSPECIFIED_SAMPLE_TEXT) {
        return {
          ...sample,
          sampleId: `Well ${wellNumber}`,
          formerlyUnspecified: true,
        };
      }

      return sample;
    });

    return uniqBy(sampleNames, "sampleId");
  };

  const formatSampleNames = () =>
    getUniqueSampleNames().map(sample => {
      const { id: runId } = run;
      const { wellNumber, sampleId, formerlyUnspecified = null } = sample;

      const deactivatedWell = deactivatedTargets.find(
        target => target.well === wellNumber
      );

      const { wellActive, fontWeight, className } =
        getSampleStylingParamters(deactivatedWell);

      return (
        <div
          className="sample_selector_grid_cell"
          key={`${runId} ${sampleId}-div`}
        >
          <span
            key={`${runId} ${sampleId}-span`}
            id={`sample_selector-${sampleId}`}
            className={className}
            style={{ fontWeight }}
            onClick={() =>
              handleSampleSelection(
                sampleId,
                formerlyUnspecified,
                wellNumber,
                wellActive,
                runId
              )
            }
          >
            {sampleId}
          </span>
        </div>
      );
    });

  const renderHeading = () => (
    <span
      className="sample_selector_block"
      data-tip="Select a sample to view in the chart."
    >
      Sample Selector
    </span>
  );

  return (
    <DropdownBox heading={renderHeading()} borderOpened>
      <div style={{ columnCount: 3 }}>{formatSampleNames()}</div>
    </DropdownBox>
  );
};

const mapStateToProps = state => ({
  runs: getFullRunData(state),
  detailsVersion: getActiveTeamVersion(state),
  targetMatrix: getBioRadTargetMatrix(state),
  runTargets: getTargetData(state),
});

const mapDispatchToProps = dispatch => ({
  updateMasterFilters: (targets, runId) => {
    dispatch(applyMasterFilters(targets, runId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BioRadSampleSelector);
