import React, { useState } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

import { Input, Button } from "bio-web-components";

import { userProviderLoginReq } from "../actions";

const fieldsInitialState = {
  domain: "",
};

const ProviderLogin = props => {
  const [fields, setFields] = useState(fieldsInitialState);
  const [error, setError] = useState(null);

  const _formIsValid = () => {
    let valid = true;
    setError(null);

    const emptyField = Object.keys(fields).find(key => fields[key].length < 1);

    if (emptyField) {
      valid = false;
      setError("Please complete all fields");
    }

    return valid;
  };

  const _handleSubmit = debounce(async () => {
    if (_formIsValid()) {
      await new Promise((resolve, reject) => {
        const { providerLogin } = props;
        const provider = `${fields.domain.trim()}`;

        providerLogin(provider, resolve, reject);
      }).catch(errorMessage => setError(errorMessage));
    }
  }, 500);

  const _renderHeader = () => {
    return (
      <React.Fragment>
        <div className="provider_login__title">
          <h1>Login with Your</h1>
          <h1>Organization Credentials</h1>
        </div>
      </React.Fragment>
    );
  };

  const _renderProviderForm = () => {
    return (
      <div className="provider_login__form_container">
        <p className="provider_login__details">Domain</p>
        <Input
          placeholder="Organization Domain"
          value={fields.domain}
          onChange={value => setFields({ ...fields, domain: value.trim() })}
        />
      </div>
    );
  };

  const _renderProviderButtons = () => {
    return (
      <div className="provider_login__buttons_container">
        <Button title="Continue" onClick={() => _handleSubmit()} inverting />
        <Link to="/" className="provider_login__return_home">
          Return to Home
        </Link>
      </div>
    );
  };

  const _renderErrorMessage = () => {
    return (
      <div className="provider_login__message">
        <span className="app_error">{error}</span>
      </div>
    );
  };

  return (
    <div className="provider_login__container">
      {_renderHeader()}
      {_renderProviderForm()}
      {_renderProviderButtons()}
      {error && _renderErrorMessage()}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    providerLogin: (provider, resolve, reject) =>
      dispatch(userProviderLoginReq(provider, resolve, reject)),
  };
};

export default connect(null, mapDispatchToProps)(ProviderLogin);
