import { isEmpty } from "lodash";

import { colors, BIORAD_WELL_IDENTIFIERS } from "../constants";
import { checkActiveChannels } from "../helpers";
import {
  runTypes,
  BIORAD_DETAILS_VERSION,
  ABI_DETAILS_VERSION,
} from "../../Hub/constants";
import CloudLog from "../../Utils/CloudLog";

function generateChartID(runId, wellNum, channelNum, tag = "") {
  /**
   * Generate the ID used to access values in D3 charts
   * @param { int }    runId     integer based on location in wellMatrix top level array
   * @param { int }    wellNum     integer based on location in second level of wellMatrix
   * @param { int }    channelNum   integer based on the channel this target is in, green = 0, red = 1,
   * @param { string } tag           supplied value to add to the end of ID, used for accessing raw and threshold columns in chart
   * @return { string } formatted string to be used as the first index in D3 column arrays
   */
  if (tag) {
    return `${runId}-${wellNum}-${channelNum}-${tag}`;
  }

  return `${runId}-${wellNum}-${channelNum}`;
}

function getChartColor(channelIndex) {
  return colors[channelIndex];
}

/** Function to take list of runRuns and return format for D3 charts */

export function createChartData(data) {
  /**
   * @param {Array[Obj]} data List of all runRuns to compare
   */

  // The object that will be used to set runs page state. Contains data for chart and filters
  const chartData = {
    data: [], // Array of all targets to display
    xAxis: {},
    yAxis: {},

    // Array for each run, contains data to if the run is actively being shown on the chart, if thresholds are being displayed, and the type of run this is (Qual, Quant, Std. Curve)
  };

  const xAxisLengths = [];
  const yAxisMinimums = [];
  const yAxisMaximums = [];
  try {
    for (let runIndex = 0; runIndex < data.length; runIndex += 1) {
      const run = data[runIndex];
      const { targets, protocol } = run;
      const { colorMask } = protocol;

      const channelActiveStatus = checkActiveChannels(colorMask);

      const isGreenActive = channelActiveStatus.green;
      const isAmberActive = channelActiveStatus.amber;
      const isRedActive = channelActiveStatus.red;

      targets.forEach(target => {
        let { baselineData } = target;

        if (baselineData.length < 1) {
          baselineData = target.rawData;
        }

        xAxisLengths.push(baselineData.length);
        yAxisMaximums.push(Math.max(...baselineData));
        yAxisMinimums.push(Math.min(...baselineData));

        const {
          runId,
          endRfu,
          wellNumber: wellIndex,
          emissionColor: channel,
          leftVal: targetLeftVal,
          rightVal: targetRightVal,
          sampleType: targetSampleType,
          sampleAmount: targetSampleAmount,
          sampleUnits: targetSampleUnits,
          sourceStartingQuantity: targetSourceStartingQuantity,
          id: targetId,
        } = target;
        let channelIndex = 0;
        let hideTarget = false;

        if (channel === "green") {
          channelIndex = 0;
          if (target.hidden || !isGreenActive) {
            hideTarget = true;
          }
        } else if (channel === "red") {
          channelIndex = 1;
          if (target.hidden || !isRedActive) {
            hideTarget = true;
          }
        } else if (channel === "amber") {
          channelIndex = 2;
          if (target.hidden || !isAmberActive) {
            hideTarget = true;
          }
        }

        let SQ = null;
        if (typeof target.SQ === "number") {
          SQ = target.SQ.toFixed(2);
        }

        let leftVal = null;
        if (targetLeftVal || targetLeftVal === 0) {
          leftVal = targetLeftVal;
        }

        let rightVal = null;
        if (targetRightVal || targetRightVal === 0) {
          rightVal = targetRightVal;
        }

        let sampleType = null;
        if (targetSampleType) {
          sampleType = targetSampleType;
        }

        let sampleAmount = null;
        if (targetSampleAmount) {
          sampleAmount = targetSampleAmount;
        }

        let sampleUnits = null;
        if (targetSampleUnits) {
          sampleUnits = targetSampleUnits;
        }

        let sourceStartingQuantity = null;
        if (targetSourceStartingQuantity) {
          sourceStartingQuantity = targetSourceStartingQuantity.toFixed(2);
        }

        const targetData = {
          id: generateChartID(runId, wellIndex, channelIndex),
          targetId,
          values: baselineData,
          baselineValues: baselineData,
          rawValues: target.rawData,
          showLine: !hideTarget,
          isControlEnabled: !hideTarget,
          color: getChartColor(channelIndex),
          hideTarget,
          runId,
          runName: run.name,
          well: wellIndex,
          channel,
          channelNumber: channelIndex,
          sampleId: target.sampleId || "",
          CQ: target.CQ || null,
          endRfu: endRfu || null,
          targetName: target.abbreviation,
          SQ,
          threshold: target.threshold,
          singleThreshold: null,
          leftVal,
          rightVal,
          meltTemperatures: target.meltTemperatures || null,
          sampleType,
          sampleAmount,
          sampleUnits,
          sourceStartingQuantity,
        };

        chartData.data.push(targetData);
      });
    }
  } catch (e) {
    CloudLog.error(`Error in createChartData v2: ${e}`);
  }

  chartData.yAxis.min = Math.min(...yAxisMinimums);
  chartData.yAxis.max = Math.max(...yAxisMaximums);
  chartData.xAxis.length = Math.max(...xAxisLengths);

  return chartData;
}

const determineRunType = run => {
  if (run.runType === runTypes.melt) {
    return "Melt Run";
  }

  if (run.runType === runTypes.isothermal) {
    return "Isothermal Run";
  }

  if (isEmpty(run.protocol.slope)) {
    return "Qualitative Run";
  }

  return "Quantitative Run";
};

export function createRunBlocks(data) {
  /**
   * Generate the ID used to access values in D3 Chart
   * @param { Array }    data     Array of run objects. Each run will create a Run Block object
   * @return { Array } Array of objects for each run block to generate
   */

  const runBlocks = [];
  data.forEach(run => {
    const wellNumbers = [];
    let shortestTargetLength = [];

    run.targets.forEach(a => {
      if (wellNumbers.indexOf(a.wellNumber) < 0) {
        wellNumbers.push(a.wellNumber);
      }

      shortestTargetLength.push(a.baselineData.length);
    });

    shortestTargetLength = Math.min(...shortestTargetLength);

    runBlocks.push({
      runId: run.id,
      active: true,
      threshold: false,
      type: determineRunType(run),
      name: run.name,
      availableWells: wellNumbers,
      status: run.status,
      shortestTargetLength,
      colorMask: run.colorMask,
    });
  });

  return runBlocks;
}

const createBioRadWellsArray = () => {
  const wells = [];

  const { numbers: wellNumbers, letters: wellLetters } =
    BIORAD_WELL_IDENTIFIERS;

  wellLetters.forEach(wellLetter => {
    wellNumbers.forEach(wellNumber => {
      wells.push({
        label: `${wellLetter}${wellNumber}`,
        checked: true,
        wellLetter,
        wellNumber,
      });
    });
  });

  return wells;
};

export function createFilterObjects(data) {
  const [run = {}] = data;
  const { detailsVersion } = run;

  const channels = [
    { label: "Green", checked: true },
    { label: "Amber", checked: true },
    { label: "Red", checked: true },
  ];

  let wells = [];

  if (
    detailsVersion === BIORAD_DETAILS_VERSION ||
    detailsVersion === ABI_DETAILS_VERSION
  ) {
    wells = createBioRadWellsArray();
  } else {
    wells = [
      { label: 0, checked: true },
      { label: 1, checked: true },
      { label: 2, checked: true },
      { label: 3, checked: true },
      { label: 4, checked: true },
      { label: 5, checked: true },
      { label: 6, checked: true },
      { label: 7, checked: true },
      { label: 8, checked: true },
    ];
  }

  return {
    channels,
    wells,
  };
}
