import * as t from "./actionTypes";

const initState = {
  data: {},
  fetched: false,
  activeFolderRunData: [],
};

function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case t.GET_RUN_SUCCESS: {
      return { ...state, data: payload, fetched: true };
    }
    case t.GET_RUN_REQ: {
      return state;
    }
    case t.GET_RUN_ERROR: {
      return state;
    }
    case t.RESET: {
      return initState;
    }
    case t.SET_ACTIVE_FOLDER_DATA: {
      return { ...state, activeFolderRunData: action.data };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
