import { all, takeLatest, put, call, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";

import { LOGO_RESET, FEATURE_POST } from "./actionTypes";
import { getRootFolders, reset as folderReset } from "../Folders/actions";
import { resetSidebar } from "../Sidebar/actions";
import { hubReset } from "../Hub/actions";
import { reset as runDataReset } from "../RunData/actions";
import { backendApi, announcementsClient } from "../Utils";
import { getActiveTeamId, getAwsUser } from "../Auth/selectors";
import { hasSeenAnnouncements } from "./selectors";
import history from "../store/history";
import * as t from "./actionTypes";
import { openAnnouncementsModal } from "./actions";
import CloudLog from "../Utils/CloudLog";

export function* reset() {
  try {
    const user = yield call([Auth, Auth.currentUserInfo]);
    yield put(resetSidebar());
    yield put(hubReset());
    if (user === null) {
      history.push("/");
    } else {
      yield put(getRootFolders());
      history.push("/data");
    }
  } catch (e) {
    CloudLog.error(`Error in reset: ${e}`);
  }
}

export function* watchReset() {
  yield takeLatest(LOGO_RESET, reset);
}

export function* postFeature(action) {
  const user = yield select(getAwsUser);

  const featureData = {
    feature: action.feature,
    userId: user.attributes.sub,
    teamId: yield select(getActiveTeamId),
  };

  const { ok, problem } = yield call(
    backendApi.post,
    "app/feature?detailsVersion=biomeme-2",
    featureData
  );

  if (!ok) {
    CloudLog.error(`Post feature problem: ${problem}`);
  }
}

export function* watchFeaturePost() {
  yield takeLatest(FEATURE_POST, postFeature);
}

function* handleAnnouncements() {
  try {
    const _hasSeenAnnouncements = yield select(hasSeenAnnouncements);

    if (!_hasSeenAnnouncements) {
      const { data, status } = yield call(
        announcementsClient.get,
        `announcement.txt`
      );

      if (status === 200 && typeof data === "string" && data.length > 0) {
        yield put(openAnnouncementsModal(data));
      }
    }
  } catch (e) {
    CloudLog.error(
      `An unexpected error has occurred in the handleAnnouncements function: ${e}`
    );
  }
}

function* watchAnnouncements() {
  yield takeLatest(t.CHECK_ACTIVE_ANNOUNCEMENTS, handleAnnouncements);
}

function* handleSuperReset() {
  yield put(hubReset());
  yield put(runDataReset());
  yield put(folderReset());
  yield put(resetSidebar());
}

function* watchSuperReset() {
  yield takeLatest(t.SUPER_RESET, handleSuperReset);
}

export default function* rootSaga() {
  yield all([
    watchReset(),
    watchFeaturePost(),
    watchAnnouncements(),
    watchSuperReset(),
  ]);
}
