/* eslint-disable import/no-self-import */
import { uniq, chunk } from "lodash";

import {
  transformCq,
  transformIntercept,
} from "bm-algorithm-js/lib/processors";

import { DetailsVersion, ProtocolTarget, Run } from "js-common";
import { round } from "../Utils/helpers";

import {
  ParsedThree9RunData,
  RawThree9RunData,
  ParsedRunTarget,
  RunTargetWithDetails,
  FlattenedTargetData,
  ParsedProtocol,
  ProtocolWithDetails,
  FlattenedProtocolData,
  RunDetails,
  ParsedDetailsData,
  RawThree9TopLevelData,
  ParsedTopLevelData,
  ProtocolDetails,
} from "../Models/Three9";
import { EXTERNAL_DETAILS_VERSIONS } from "../ExternalUpload/constants";
import {
  AWSQueryByteInfo,
  BulkRunIdsByDetailsVersion,
  BulkRunsQueriesByDetailsVersion,
  BulkSelectedRunsByDetailsVersion,
  RunData,
} from "./types";
import { AWS_QUERY_BYTE_LIMIT } from "../Utils/constants";
import * as localFn from "./helpers";

// The following function addresses a problem with runs from app versions 2.0.0 through
// 2.0.3 where meltTemperature arrays were saved as objects
// Formerly located in RunsPage helpers, relocated to eliminate dependency cycle
export const checkTypeOfMeltTemperaturesAndConvertToArrayIfNecessary = (
  meltTemperatures: any
): any => {
  if (meltTemperatures && !Array.isArray(meltTemperatures)) {
    return Object.keys(meltTemperatures).map(key => {
      return meltTemperatures[key];
    });
  }

  return meltTemperatures;
};

const topLevelRunParser = (run: RawThree9TopLevelData) => {
  // Add checks for detailsVersion when it is added to the run object in the future.
  const parsedTopLevel: ParsedTopLevelData = {
    ...run,
  };

  delete parsedTopLevel.depth;
  delete parsedTopLevel.location;
  delete parsedTopLevel.type;

  return parsedTopLevel;
};

const detailsParser = (details: RunDetails) => {
  // Add checks for detailsVersion when it is added to the details object in the future.
  const parsedDetails: ParsedDetailsData = {
    ...details,
  };

  delete parsedDetails.id;
  delete parsedDetails.complete;
  delete parsedDetails.filepath;
  delete parsedDetails.isHidden;
  delete parsedDetails.seqNumber;
  delete parsedDetails.synced;

  return parsedDetails;
};

const millisecondsToSeconds = (protocol: ParsedProtocol) => {
  const {
    denatureTime,
    annealTime,
    firstDenatureTime,
    rtTime = null,
    extensionTime = null,
    meltDuration = null,
  } = protocol;

  const newDenatureTime = denatureTime / 1000;
  const newAnnealTime = annealTime / 1000;
  const newFirstDenatureTime = firstDenatureTime / 1000;

  let newRtTime = 0;
  if (rtTime) {
    newRtTime = rtTime / 1000;
  }

  let newMeltDuration;
  if (meltDuration) {
    newMeltDuration = meltDuration / 1000;
  }

  let newExtensionTime = 0;
  if (extensionTime) {
    newExtensionTime = extensionTime / 1000;
  }

  return {
    ...protocol,
    denatureTime: newDenatureTime,
    annealTime: newAnnealTime,
    firstDenatureTime: newFirstDenatureTime,
    rtTime: newRtTime,
    meltDuration: newMeltDuration,
    extensionTime: newExtensionTime,
  };
};

const protocolParser = (protocol: ProtocolWithDetails) => {
  // Add checks for detailsVersion when it is added to the protocol object in the future.
  const { details, ...rest } = protocol;

  let flattenedProtocol: FlattenedProtocolData = {
    ...details,
    ...rest,
  };

  delete flattenedProtocol.red;
  delete flattenedProtocol.blue;
  delete flattenedProtocol.green;
  delete flattenedProtocol.amber;
  delete flattenedProtocol.type;
  delete flattenedProtocol.synced;
  delete flattenedProtocol.isHidden;
  delete flattenedProtocol.wellMask;
  delete flattenedProtocol.timestamp;
  delete flattenedProtocol.overshoot;
  delete flattenedProtocol.overshootTime;
  delete flattenedProtocol.undershoot;
  delete flattenedProtocol.undershootTime;

  const { intercept } = flattenedProtocol;

  if (intercept) {
    let newIntercept = { ...intercept };

    Object.keys(intercept).forEach(channel => {
      newIntercept = {
        ...newIntercept,
        [channel]: transformIntercept(intercept[channel]),
      };
    });

    flattenedProtocol = {
      ...flattenedProtocol,
      intercept: newIntercept,
    };
  }

  if (flattenedProtocol.id) {
    flattenedProtocol.id = flattenedProtocol.id.toLowerCase();
  }

  let parsedProtocol: ParsedProtocol = flattenedProtocol;
  parsedProtocol = millisecondsToSeconds(parsedProtocol);

  return parsedProtocol;
};

const targetsParser = (
  targets: RunTargetWithDetails[],
  showSampleType: boolean,
  protocol: ProtocolDetails
) =>
  // Add checks for detailsVersion when it is added to the target objects in the future.
  {
    return targets.map(target => {
      const { details, ...rest } = target;
      const flattenedTarget: FlattenedTargetData = {
        ...rest,
        ...details,
        id: target.id,
        runId: target.runId,
      };
      const { targets: protocolTargets } = protocol;

      let protocolTarget: ProtocolTarget | undefined;
      if (protocolTargets) {
        protocolTarget = protocolTargets.find((x: ProtocolTarget) => {
          return (
            x.emissionColor === flattenedTarget.emissionColor &&
            x.wellNumber === flattenedTarget.wellNumber
          );
        });
      }

      if (protocolTarget && protocolTarget.coexcitation) {
        flattenedTarget.coexcitation = protocolTarget.coexcitation;
      }

      delete flattenedTarget.well;
      delete flattenedTarget.group;
      delete flattenedTarget.notes;
      delete flattenedTarget.strip;
      delete flattenedTarget.groupAbbreviation;
      delete flattenedTarget.processedData;
      delete flattenedTarget.date;
      delete flattenedTarget.startingQuanity;
      delete flattenedTarget.startingQuantity;
      delete flattenedTarget.calculation_method;

      flattenedTarget.threshold = round(flattenedTarget.threshold, 2);

      if (flattenedTarget.peak || flattenedTarget.peak === 0) {
        flattenedTarget.CQ = flattenedTarget.peak;
      } else if (flattenedTarget.cq) {
        flattenedTarget.CQ = transformCq(flattenedTarget.cq);
        delete flattenedTarget.cq;
      } else if (flattenedTarget.CQ) {
        flattenedTarget.CQ = transformCq(flattenedTarget.CQ);
        delete flattenedTarget.cq;
      } else {
        flattenedTarget.CQ = 0;
        delete flattenedTarget.cq;
      }

      if (flattenedTarget.sq || flattenedTarget.sq === 0) {
        flattenedTarget.SQ = flattenedTarget.sq;
        delete flattenedTarget.sq;
      } else if (showSampleType) {
        flattenedTarget.SQ = flattenedTarget.sourceStartingQuantity;
      } else {
        flattenedTarget.SQ = null;
        delete flattenedTarget.sq;
      }

      if (flattenedTarget.endRfu === null) {
        flattenedTarget.endRfu = target.endRfu;
      }

      flattenedTarget.meltTemperatures =
        checkTypeOfMeltTemperaturesAndConvertToArrayIfNecessary(
          flattenedTarget.meltTemperatures
        );

      const parsedTarget: ParsedRunTarget = flattenedTarget;

      return parsedTarget;
    });
  };

export const three9DataParser = (
  run: RawThree9RunData
): ParsedThree9RunData => {
  const { protocol, details, targets, ...rest } = run;
  const { showSampleType, details: protocolDetails } = protocol;

  const parsedRun: ParsedThree9RunData = {
    ...topLevelRunParser({ ...rest }),
    ...detailsParser({ ...details }),
    protocol: protocolParser({ ...protocol }),
    targets: targetsParser(
      [...targets],
      showSampleType || false,
      protocolDetails
    ),
  };

  return parsedRun;
};

interface SampleName {
  sampleId: string;
  sampleRecordId?: string;
}

export const extractSampleRecordIds = (sampleNames: SampleName[]): string[] => {
  const sampleRecordIds: string[] = [];

  sampleNames.forEach(sampleName => {
    const { sampleRecordId } = sampleName;
    if (sampleRecordId) {
      sampleRecordIds.push(sampleRecordId);
    }
  });

  return uniq(sampleRecordIds);
};

export const isDetailsVersionTwoOrGreater = (
  detailsVersion: string
): boolean => {
  if (
    detailsVersion === "biomeme-2" ||
    EXTERNAL_DETAILS_VERSIONS.includes(detailsVersion)
  ) {
    return true;
  }

  return false;
};

export const formatStringArrayForQuery = (stringArray: string[]): string => {
  return stringArray.reduce((curId: string, prevString: string, i: number) => {
    if (i < stringArray.length) {
      if (curId !== "") {
        return `${prevString},${curId}`;
      }

      return prevString;
    }

    return prevString + curId;
  }, "");
};

export const isWithinAWSQueryByteLimit = (query: string): AWSQueryByteInfo => {
  const byteSize = new TextEncoder().encode(query).length;

  let queryInfo = { isWithinByteLimit: true, byteSize, chunksNeeded: 1 };

  if (byteSize >= AWS_QUERY_BYTE_LIMIT) {
    queryInfo = {
      ...queryInfo,
      isWithinByteLimit: false,
      chunksNeeded: Math.ceil(byteSize / AWS_QUERY_BYTE_LIMIT),
    };
  }

  return queryInfo;
};

export const createFullRunDataRunsQuery = (runIds: string[]): string[] => {
  const runIdString = localFn.formatStringArrayForQuery(runIds);
  let queryReturn: string[] = [runIdString];

  const byteLimitInfo = localFn.isWithinAWSQueryByteLimit(runIdString);

  const { isWithinByteLimit: initialQueryIsWithinByteLimit } = byteLimitInfo;

  if (!initialQueryIsWithinByteLimit) {
    const { chunksNeeded: initialQueryChunksNeeded } = byteLimitInfo;

    const chunkSize = runIds.length / initialQueryChunksNeeded;
    const chunks = chunk(runIds, chunkSize);

    queryReturn = chunks.map((queryChunk: string[]) => {
      const stringChunk = localFn.formatStringArrayForQuery(queryChunk);
      const byteSizeCheck = localFn.isWithinAWSQueryByteLimit(stringChunk);

      const reservedQueryBuffer = 200;
      const maxByteSize = AWS_QUERY_BYTE_LIMIT - reservedQueryBuffer;
      if (
        !byteSizeCheck.isWithinByteLimit ||
        byteSizeCheck.byteSize >= maxByteSize
      ) {
        throw new Error(
          `Chunked FullRunData Query is above AWS Query String Byte limit!\n ByteSize: ${byteSizeCheck.byteSize}\n String: ${stringChunk}`
        );
      }

      return stringChunk;
    });
  }

  return queryReturn;
};

export const mapProtocolToFullRunData = (
  runData: RunData[],
  protocolId: string,
  protocolReq: { data: any }
): RunData[] => {
  return runData.map(run => {
    let newRun = run;

    const { protocolId: runProtocolId } = run;
    if (runProtocolId === protocolId) {
      const { data } = protocolReq;
      newRun = {
        ...run,
        protocol: data,
      };
    }

    return newRun;
  });
};

export const constructReprocessingModeBatches = (
  runData: ParsedThree9RunData[]
): string[][] => {
  let runIdsFirstBatch: string[] = [];
  const runIdsSecondBatch: string[] = [];

  if (runData.length > 5) {
    runData.forEach((run: ParsedThree9RunData, i: number) => {
      if (i < 5) {
        runIdsFirstBatch.push(run.id);
      } else {
        runIdsSecondBatch.push(run.id);
      }
    });
  } else {
    runIdsFirstBatch = runData.map((run: ParsedThree9RunData) => {
      return run.id;
    });
  }

  return [runIdsFirstBatch, runIdsSecondBatch];
};

export const filterByDetailsVersion = (
  selectedRuns: RunData[],
  filter: DetailsVersion
): RunData[] => {
  return selectedRuns.filter(run => {
    return run.detailsVersion === filter;
  });
};

export const getRunIdsByDetailsVersion = (
  selectedRunsByDetailsVersion: BulkSelectedRunsByDetailsVersion
): BulkRunIdsByDetailsVersion => {
  return {
    "biomeme-1": selectedRunsByDetailsVersion["biomeme-1"].map((r: Run) => {
      return r.id;
    }),
    "biomeme-2": selectedRunsByDetailsVersion["biomeme-2"].map((r: Run) => {
      return r.id;
    }),
    isp: selectedRunsByDetailsVersion.isp.map((r: Run) => {
      return r.id;
    }),
    biorad: selectedRunsByDetailsVersion.biorad.map((r: Run) => {
      return r.id;
    }),
    abi: selectedRunsByDetailsVersion.abi.map((r: Run) => {
      return r.id;
    }),
  };
};

export const createRunsQueriesByDetailsVersions = (
  runIdsByDetailsVersion: BulkRunIdsByDetailsVersion
): BulkRunsQueriesByDetailsVersion => {
  return {
    "biomeme-1": createFullRunDataRunsQuery(
      runIdsByDetailsVersion["biomeme-1"]
    ),
    "biomeme-2": createFullRunDataRunsQuery(
      runIdsByDetailsVersion["biomeme-2"]
    ),
    isp: createFullRunDataRunsQuery(runIdsByDetailsVersion.isp),
    biorad: createFullRunDataRunsQuery(runIdsByDetailsVersion.biorad),
    abi: createFullRunDataRunsQuery(runIdsByDetailsVersion.abi),
  };
};
