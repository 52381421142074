import CloudLog from "../../Utils/CloudLog";
import { colors } from "../constants";

function generateChartID(runId, wellNum, channelNum, tag = "") {
  /**
   * Generate the ID used to access values in D3 charts
   * @param { int }    runId     integer based on location in wellMatrix top level array
   * @param { int }    wellNum     integer based on location in second level of wellMatrix
   * @param { int }    channelNum   integer based on the channel this target is in, green = 0, red = 1,
   * @param { string } tag           supplied value to add to the end of ID, used for accessing raw and threshold columns in chart
   * @return { string } formatted string to be used as the first index in D3 column arrays
   */
  if (tag) {
    return `${runId}-${wellNum}-${channelNum}-${tag}`;
  }

  return `${runId}-${wellNum}-${channelNum}`;
}

function getChartColor(channelIndex) {
  return colors[channelIndex];
}

/** Function to take list of runRuns and return format for D3 charts */

export function createChartData(data) {
  /**
   * @param {Array[Obj]} data List of all runRuns to compare
   */

  // The object that will be used to set runs page state. Contains data for chart and filters
  const chartData = {
    data: [], // Array of all targets to display
    xAxis: {},
    yAxis: {},

    // Array for each run, contains data to if the run is actively being shown on the chart, if thresholds are being displayed, and the type of run this is (Qual, Quant, Std. Curve)
  };

  const xAxisLengths = [];
  const yAxisMinimums = [];
  const yAxisMaximums = [];
  try {
    for (let runIndex = 0; runIndex < data.length; runIndex += 1) {
      const run = data[runIndex];
      const { details = {} } = run;
      const { wellData = [] } = details;

      for (let wellIndex = 0; wellIndex < wellData.length; wellIndex += 1) {
        const well = wellData[wellIndex];

        for (
          let channelIndex = 0;
          channelIndex < well.targets.length;
          channelIndex += 1
        ) {
          const target = well.targets[channelIndex];

          xAxisLengths.push(target.baselineData.length);
          yAxisMaximums.push(Math.max(...target.baselineData));
          yAxisMinimums.push(Math.min(...target.baselineData));

          let channel = "";
          if (channelIndex === 0) {
            channel = "green";
          } else if (channelIndex === 1) {
            channel = "red";
          }

          const targetData = {
            id: generateChartID(run.id, wellIndex, channelIndex),
            values: target.baselineData,
            baselineValues: target.baselineData,
            rawValues: target.rawData,
            showLine: true,
            isControlEnabled: true,
            color: getChartColor(channelIndex),
            runId: run.id,
            runName: run.name,
            well: wellIndex,
            channel,
            channelNumber: channelIndex,
            CQ: target.CQ,
            targetName: target.shortTargetName,
            SQ: null, // Need a function to parse this out if we have a quant or standard curve
            threshold: target.threshold,
            singleThreshold: null,
            sampleId: target.sampleId || "",
            endRfu: target.endRfu,
          };

          chartData.data.push(targetData);
        }
      }
    }
  } catch (e) {
    CloudLog.error(`Error in createChartData: ${e}`);
  }

  chartData.yAxis.min = Math.min(...yAxisMinimums);
  chartData.yAxis.max = Math.max(...yAxisMaximums);
  chartData.xAxis.length = Math.max(...xAxisLengths) - 1;

  return chartData;
}

const determineRunDetails = run => {
  if (run.details.isQuant) {
    return "Quantitative Run (Standard Curve)";
  }

  if (run.details.BMStandardCurveId === "") {
    return "Qualitative Run";
  }

  return "Standard Curve";
};

export function createRunBlocks(data) {
  /**
   * Generate the ID used to access values in D3 Chart
   * @param { Array }    data     Array of run objects. Each run will create a Run Block object
   * @return { Array } Array of objects for each run block to generate
   */

  const runBlocks = [];

  data.forEach(run => {
    runBlocks.push({
      runId: run.id,
      active: true,
      threshold: false,
      type: determineRunDetails(run),
      name: run.name,
      availableWells: [0, 1, 2],
      colorMask: run.colorMask,
    });
  });

  return runBlocks;
}

export function createFilterObjects(data) {
  const run = data[0] || {};
  const { details = {} } = run;
  const { wellData = [] } = details;
  const totalWells = wellData.length;
  const targets = wellData[0].targets || [];

  const totalChannels = targets.map(target =>
    target.fluorophoreName.toLowerCase()
  );

  const channels = totalChannels.map(channel => {
    return {
      label: channel.slice(0, 1).toUpperCase() + channel.slice(1),
      checked: true,
    };
  });

  const wells = [];
  for (let i = 0; i < totalWells; i += 1) {
    wells.push({
      label: i,
      checked: true,
    });
  }

  return {
    channels,
    wells,
  };
}
