/**
 * A utility to create a formatted filename sting to
 * use when outputting a spreadsheet fo the review data.
 *
 * @param review A sting of the run name
 * @param prefix A string to indicate if the output is a summary or a detailed file
 */
export const generateFilename = (review: string, prefix: string) => {
  const FILE_NAME_REGEX = /[/:*?"<>|]/g;
  const filename = `${prefix} ${review.replace(FILE_NAME_REGEX, "-")}.xlsx`;

  return filename;
};
