import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Checkmark, Input, Row, ToggleSwitch } from "bio-web-components";

import { RunReview } from "../types";
import { approveAllTargets, setRunComments } from "../actions";
import {
  getAllTargetsApproved,
  getPreviousReviewerIsAdmin,
  getPreviousReviewerName,
  getRunReview,
} from "../selectors";

interface StateProps {
  review: RunReview;
  previousReviewer: string;
  previousReviewerIsAdmin: boolean;
  allTargetsApproved: boolean;
}

interface DispatchProps {
  setComments: (comments: string) => void;
  approveAll: () => void;
}

const RunReviewHeader = (
  props: StateProps & DispatchProps
): React.ReactElement => {
  const { review } = props;

  const _renderApproveAllSwitch = (): React.ReactElement => {
    const { allTargetsApproved, approveAll } = props;

    return (
      <ToggleSwitch
        className="toggle_slider_position"
        onClick={approveAll}
        label="Approve All Targets"
        checked={allTargetsApproved}
        disabled={allTargetsApproved}
      />
    );
  };

  const _renderFailureReason = (): React.ReactElement | null => {
    const { passed, failureReason } = review;

    if (!passed && failureReason) {
      return (
        <Input
          containerClasses="review_fit_input"
          inputClasses="review_list_item"
          label="FAILURE REASON"
          value={failureReason.join(", ")}
          disabled
        />
      );
    }

    return null;
  };

  const { previousReviewer, previousReviewerIsAdmin, setComments } = props;

  const { comments, passed } = review;

  let passedStatus = "Failed";
  if (passed) {
    passedStatus = "Passed";
  }

  return (
    <Row>
      <Input
        containerClasses="review_small_input"
        inputClasses="review_list_item"
        label="RUN PASSED"
        value={passedStatus}
        disabled
      />
      <Input
        label="REVIEWED BY"
        containerClasses="review_fit_input"
        inputClasses="review_list_item"
        value={previousReviewer}
        iconLeft={
          previousReviewerIsAdmin && <Checkmark width={18} height={18} />
        }
        disabled
      />
      {_renderFailureReason()}
      <Input
        label="COMMENTS"
        defaultValue={comments}
        onBlur={(value: string) => setComments(value)}
      />
      {_renderApproveAllSwitch()}
    </Row>
  );
};

const mapStateToProps = (state: any) => {
  return {
    review: getRunReview(state),
    previousReviewer: getPreviousReviewerName(state),
    previousReviewerIsAdmin: getPreviousReviewerIsAdmin(state),
    allTargetsApproved: getAllTargetsApproved(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setComments: (comments: string) => dispatch(setRunComments(comments)),
    approveAll: () => dispatch(approveAllTargets()),
  };
};

export default connect<StateProps, DispatchProps, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(RunReviewHeader);
