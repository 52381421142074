import { all, takeLatest, put, call, select } from "redux-saga/effects";

import * as actions from "./actions";
import { getFullRunData } from "../FullRunData/actions";
import { setReviewRules } from "../RunReview/actions";
import { updateAnalysisMode, setReviewModeError } from "../RunsPage/actions";
import * as folderTypes from "../Folders/actionTypes";
import { HUB_SELECT_SINGLE_RUN, HUB_SELECT_MULTIPLE_RUNS } from "./actionTypes";
import { RESET_SIDEBAR, FOLDER_SELECTED } from "../Sidebar/actionTypes";
import {
  getRunSelectionType,
  getSelectedRuns,
  isSelectedRunExternalUpload,
} from "./selectors";
import { determineSingleRunDefaultAnalysisMode } from "./helpers";
import { analysisModes } from "../RunsPage/constants";
import { backendApi } from "../Utils/index";
import history from "../store/history";
import { getActiveTeamId } from "../Auth/selectors";
import { isBulkReviewModeActive } from "../RunsPage/selectors";

export function* callOnReset() {
  const bulkReviewModeIsActive = yield select(isBulkReviewModeActive);

  if (bulkReviewModeIsActive) {
    yield put(actions.resetSelectedRuns());
  }

  yield put(actions.hubReset());
}

export function* watchFolderReset() {
  yield takeLatest(RESET_SIDEBAR, callOnReset);
}

export function* callOnSelected(action) {
  const { selectedFolder, folder } = action;
  const f = folder || selectedFolder;
  yield put(actions.updatePath(f));
}

export function* callStartLoading() {
  yield put(actions.startLoading());
}

export function* watchFolderSelected() {
  yield takeLatest([FOLDER_SELECTED], callOnSelected);
}

export function* watchStartLoading() {
  yield takeLatest(
    [folderTypes.GET_SELECTED_REQ, folderTypes.GET_ROOT_FOLDERS],
    callStartLoading
  );
}

export function* callOnSingleRunSelect() {
  const runType = yield select(getRunSelectionType);
  const runIsExternalUpload = yield select(isSelectedRunExternalUpload);

  const analysisMode = determineSingleRunDefaultAnalysisMode(
    runType,
    runIsExternalUpload
  );

  try {
    yield put(updateAnalysisMode(analysisMode));
  } catch (e) {
    yield put(actions.hideChart());
  }
}

export function* callOnMultiRunSelect(action) {
  try {
    yield put(actions.hideChart());
    yield put(updateAnalysisMode(action.mode));
  } catch (e) {
    yield put(actions.hideChart());
  }
}

function* checkRunsForReviewEligibility() {
  const [selectedRun] = yield select(getSelectedRuns);
  const teamId = yield select(getActiveTeamId);

  const { status, protocolId } = selectedRun;

  // RUN STATUS VALIDATION

  // Flag an error if the run is incomplete
  if (status === "incomplete") {
    return "Incomplete runs are not eligible for review.";
  }

  // PROTOCOL VALIDATION

  // Flag an error if the protocol is missing a rules object
  const rulesCall = yield call(
    backendApi.get,
    `/review/rule?teamId=${teamId}&protocolId=${protocolId}`
  );

  if (!rulesCall.data || !rulesCall.data.rules) {
    return "Only runs associated with protocols that contain valid rule objects are eligible for review.";
  }

  const ruleData = rulesCall.data;
  const parsedRules = JSON.parse(ruleData.rules);
  const rules = {
    id: ruleData.id,
    version: ruleData.version,
    controlRules: parsedRules.controlRules,
    sampleRules: parsedRules.sampleRules,
  };

  yield put(setReviewRules(rules));

  return "";
}

function* handleRunSelection(action) {
  const { mode } = action;

  if (mode && mode === analysisModes.review) {
    const error = yield call(checkRunsForReviewEligibility);

    if (error) {
      yield put(setReviewModeError(error));
    }
  }

  yield put(getFullRunData());

  // Call specialized run selection functions based on type:
  if (action.type === HUB_SELECT_SINGLE_RUN) {
    yield call(callOnSingleRunSelect, action);
  } else if (action.type === HUB_SELECT_MULTIPLE_RUNS) {
    yield call(callOnMultiRunSelect, action);
  }

  // Push the user to the chart view
  history.push("/runs-page");
}

export function* watchForRunSelect() {
  yield takeLatest(
    [HUB_SELECT_SINGLE_RUN, HUB_SELECT_MULTIPLE_RUNS],
    handleRunSelection
  );
}

export function* callStopLoading() {
  yield put(actions.stopLoading());
}

export function* watchStopLoading() {
  yield takeLatest(
    [
      folderTypes.GET_FOLDERS_SUCCESS,
      folderTypes.GET_ROOT_SUCCESS,
      folderTypes.GET_SELECTED_SUCCESS,
    ],
    callStopLoading
  );
}

export default function* rootSaga() {
  yield all([
    watchFolderSelected(),
    watchFolderReset(),
    watchForRunSelect(),
    watchStartLoading(),
    watchStopLoading(),
  ]);
}
