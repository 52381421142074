/** *****************************
 *  TEMPLATE SELECTION
 ****************************** */
export const SET_ACTIVE_SAMPLE_TEMPLATE =
  "sampleHub/SET_ACTIVE_SAMPLE_TEMPLATE";
export const UNSET_ACTIVE_SAMPLE_TEMPLATE =
  "sampleHub/UNSET_ACTIVE_SAMPLE_TEMPLATE";
export const SET_TEMPLATE_TO_EDIT = "sampleHub/SET_TEMPLATE_TO_EDIT";

/** *****************************
 *  INITIALIZERS
 ****************************** */
export const INITIALIZE_SAMPLE_HUB = "sampleHub/INITIALIZE_SAMPLE_HUB";
export const INITIALIZE_SAMPLE_TEMPLATES =
  "sampleHub/INITIALIZE_SAMPLE_TEMPLATES";
export const INITIALIZE_SAMPLE_TEMPLATES_SUCCESS =
  "sampleHub/INITIALIZE_SAMPLE_TEMPLATES_SUCCESS";

/** *****************************
 *  SAMPLE LAYOUTS
 ****************************** */
export const UPLOAD_TEMPLATE = "sampleHub/UPLOAD_TEMPLATE";
export const UPLOAD_TEMPLATE_SUCCESS = "sampleHub/UPLOAD_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE = "sampleHub/EDIT_TEMPLATE";
export const DELETE_SAMPLE_TEMPLATE = "sampleHub/DELETE_SAMPLE_TEMPLATE";

export const RETRIEVE_SAMPLE_TEMPLATES = "sampleHub/RETRIEVE_SAMPLE_TEMPLATES";
export const RETRIEVE_SAMPLE_TEMPLATES_SUCCESS =
  "sampleHub/RETRIEVE_SAMPLE_TEMPLATES_SUCCESS";

/** *****************************
 *  SAMPLE RECORDS
 ****************************** */
export const UPLOAD_RECORDS = "sampleHub/UPLOAD_RECORDS";
export const DELETE_SAMPLE_RECORD = "sampleHub/DELETE_SAMPLE_RECORD";

export const RETRIEVE_SAMPLE_RECORDS = "sampleHub/RETRIEVE_SAMPLE_RECORDS";
export const RETRIEVE_SAMPLE_RECORDS_SUCCESS =
  "sampleHub/RETRIEVE_SAMPLE_RECORDS_SUCCESS";

export const SET_ACTIVE_SAMPLE_RECORD = "sampleHub/SET_ACTIVE_SAMPLE_RECORD";

/** *****************************
 *  TABULAR DATA MANAGEMENT
 ****************************** */
export const SET_TO_PRINT = "sampleHub/SET_TO_PRINT";
export const SET_TO_DELETE = "sampleHub/SET_TO_DELETE";

export const SET_TABLE_DISPLAY = "sampleHub/SET_TABLE_DISPLAY";
export const SET_SORT_BY = "sampleHub/SET_SORT_BY";

export const RESET_TABULAR_DATA = "sampleHub/RESET_TABULAR_DATA";

/** *****************************
 *  ERROR HANDLING
 ****************************** */
export const RECORD_DELETE_UNSUCCESSFUL =
  "sampleHub/RECORD_DELETE_UNSUCCESSFUL";
export const RECORD_POST_UNSUCCESSFUL = "sampleHub/RECORD_POST_UNSUCCESSFUL";
export const CLEAR_RECORD_POST_ERRORS = "sampleHub/CLEAR_RECORD_POST_ERRORS";
export const CLEAR_RECORD_DELETE_ERRORS =
  "sampleHub/CLEAR_RECORD_DELETE_ERRORS";

export const TEMPLATE_DELETE_UNSUCCESSFUL =
  "sampleHub/TEMPLATE_DELETE_UNSUCCESSFUL";
export const TEMPLATE_POST_UNSUCCESSFUL =
  "sampleHub/TEMPLATE_POST_UNSUCCESSFUL";
export const CLEAR_TEMPLATE_POST_ERRORS =
  "sampleHub/CLEAR_TEMPLATE_POST_ERRORS";
export const CLEAR_TEMPLATE_DELETE_ERRORS =
  "sampleHub/CLEAR_TEMPLATE_DELETE_ERRORS";

export const CLEAR_ERRORS = "sampleHub/CLEAR_ERRORS";
