import React from "react";

const QuantitativeIcon = props => {
  const { opacity } = props;

  return (
    <svg
      width={30}
      height={15}
      style={{
        marginRight: "10px",
        marginTop: "1px",
        opacity,
      }}
      {...props}
      className="quantitative-run-icon run-icon"
      data-tip="Quantitative"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h28v14H0z" fill="#2A9FD2" fillRule="nonzero" />
        <path
          d="M11.422 7.036a3.055 3.055 0 0 1-1.29 2.502l.576.946-.852.516-.627-1.032a3.295 3.295 0 0 1-.826.112c-1.685 0-3.01-1.367-3.01-3.044S6.718 4 8.403 4a3.02 3.02 0 0 1 3.019 3.036zM8.403 8.953c.069 0 .138 0 .198-.008l-.507-.843.842-.516.594.972c.43-.344.688-.895.688-1.522 0-1.058-.757-1.91-1.815-1.91-1.066 0-1.814.852-1.814 1.91 0 1.066.748 1.917 1.814 1.917zM15.73 4.07h1.195v5.925H15.85L13.7 6.279v3.715h-1.195V4.069h1.11L15.73 7.74V4.069zm6.553 0v1.058h-1.634v4.867h-1.196V5.127H17.82V4.069h4.463z"
          fill="#012639"
        />
      </g>
    </svg>
  );
};

export default QuantitativeIcon;
