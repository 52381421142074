import React from "react";
import { connect } from "react-redux";

import { Content } from "./components/index";
import Sidebar from "../Sidebar/index";
import ProjectFolders from "../Sidebar/components/projectFolders";
import ContentHeader from "../Hub/components/contentHeader";
import ContentContainer from "../Hub/components/ContentContainer";
import { getSidebarDepth } from "../Sidebar/selectors";
import AppUpdateModal from "./components/AppUpdateModal";
import AddFolderModal from "../Folders/components/AddFolderModal";

export function DataRoute({ children, depth }) {
  let displaySidebar = null;
  if (depth < 1) {
    displaySidebar = <Sidebar />;
  }

  return (
    <div className="fluidContainers">
      <AppUpdateModal />
      {displaySidebar}
      <Content>
        <ContentHeader />
        <AddFolderModal />
        <ProjectFolders />
        <ContentContainer />
        {children}
      </Content>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    depth: getSidebarDepth(state),
  };
}

export default connect(mapStateToProps)(DataRoute);
