import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Input, TableRow, TableCell, RadioButton } from "bio-web-components";

import { setTargetCq, setTargetComments, setTargetOverride } from "../actions";
import { TargetReview } from "../types";

interface DispatchProps {
  setOverride: (
    sampleId: string,
    targetId: string,
    overrideCq: boolean | null
  ) => void;
  setCq: (sampleId: string, targetId: string, cq: number) => void;
  setComments: (sampleId: string, targetId: string, comments: string) => void;
}

interface Props {
  sampleId: string;
  target: TargetReview;
  darkened: boolean;
  sampleBlank: boolean;
}

const ReviewTarget = (props: Props & DispatchProps) => {
  const { target, sampleId, sampleBlank, setCq, setComments, setOverride } =
    props;
  const { name, targetId, cq, cqOverride, overridenCq, comments } = target;

  const _renderOverrideCqSection = (): React.ReactNode => {
    const _reviewInput = () => {
      let htmlElement = <label className="review_text">N/A</label>;
      if (cqOverride) {
        htmlElement = (
          <Input
            containerClasses="review_input"
            type="number"
            defaultValue={overridenCq}
            onBlur={(value: number) => setCq(sampleId, targetId, value)}
          />
        );
      }

      return htmlElement;
    };

    // Prevents either radio button from being pre-selected before user reviews the CQ
    let yesRadioValue = false;
    let noRadioValue = false;
    if (cqOverride !== null) {
      yesRadioValue = !cqOverride;
      noRadioValue = cqOverride;
    }

    return (
      <React.Fragment>
        <TableCell className="review_column review_radio_buttons">
          <div className="review_approve_section">
            <RadioButton
              label="Yes"
              onClick={() => setOverride(sampleId, targetId, false)}
              checked={yesRadioValue}
              disabled={sampleBlank}
            />
            <RadioButton
              label="No"
              onClick={() => setOverride(sampleId, targetId, true)}
              checked={noRadioValue}
              disabled={sampleBlank}
            />
          </div>
        </TableCell>
        <TableCell className="review_column">{_reviewInput()}</TableCell>
      </React.Fragment>
    );
  };

  const { darkened } = props;

  return (
    <TableRow darkened={darkened}>
      <TableCell className="review_column">
        <label className="review_text">{name}</label>
      </TableCell>
      <TableCell className="review_column">
        <label className="review_text">{cq}</label>
      </TableCell>
      {_renderOverrideCqSection()}
      <TableCell className="review_column">
        <Input
          defaultValue={comments}
          onBlur={(value: string) => setComments(sampleId, targetId, value)}
        />
      </TableCell>
    </TableRow>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setOverride: (
      sampleId: string,
      targetId: string,
      overrideCq: boolean | null
    ) => dispatch(setTargetOverride(sampleId, targetId, overrideCq)),
    setCq: (sampleId: string, targetId: string, cq: number) =>
      dispatch(setTargetCq(sampleId, targetId, cq)),
    setComments: (sampleId: string, targetId: string, comments: string) =>
      dispatch(setTargetComments(sampleId, targetId, comments)),
  };
};

export default connect<{}, DispatchProps, Props, {}>(
  null,
  mapDispatchToProps
)(ReviewTarget);
