import { CSSProperties } from "react";

export const bulkReviewSidebar: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export const bulkReviewSidebarApproveContainer: CSSProperties = {
  alignSelf: "center",
  marginTop: "25px",
};

export const bulkReviewRunBlocksContainer: CSSProperties = {
  overflowY: "auto",
  minHeight: "50vh",
  maxHeight: "55vh",
};


