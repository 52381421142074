import React, { useEffect } from "react";

import { FileInput } from "bio-web-components";

import { getAbiFileSelectionError } from "../helpers";
import { FileProps } from "../types";

interface Props {
  fileProps: FileProps;
  setFileValidationError: (error: string | null) => void;
}

const AbiFileSelector = (props: Props) => {
  const FILE_ACCEPT = ".xlsx";

  const { fileProps, setFileValidationError } = props;
  const { files, setFiles } = fileProps;

  useEffect(() => {
    setFileValidationError(getAbiFileSelectionError(files));

    return () => setFileValidationError(null);
  }, [files]);

  const handleFileSelection = (files: File[]) => {
    setFiles(files);
    setFileValidationError(getAbiFileSelectionError(files));
  };

  return (
    <div className="upload_run_modal-upload-box">
      <span className="archive_form_upload-help-text">
        In the Quant Studio Software, access the Export menu. Under 'Content',
        select only 'Amplification Data' and 'Results'. Under 'Options', select
        'Split the above content items into individual files'. Under 'File
        Type', select (*.xlsx), and click 'Export'.
      </span>
      <FileInput onChange={handleFileSelection} accept={FILE_ACCEPT} multiple />
    </div>
  );
};

export default AbiFileSelector;
