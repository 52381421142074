import * as t from "./actionTypes";

export function hubReset() {
  return {
    type: t.HUB_RESET,
  };
}

export function resetSelectedRuns() {
  return {
    type: t.RESET_SELECTED_RUNS,
  };
}

export function updatePath(folder) {
  return {
    type: t.UPDATE_PATHS,
    folder,
  };
}

export function applyFilters() {
  return {
    type: t.APPLY_FILTERS,
  };
}

export function cancelFilter() {
  return {
    type: t.CANCEL_FILTER,
  };
}

export function filterBarShow() {
  return {
    type: t.HUB_FILTER_BAR_SHOW,
  };
}

export function filterBarHide() {
  return {
    type: t.HUB_FILTER_BAR_HIDE,
  };
}

export function changeActiveTab(tab) {
  return {
    type: t.HUB_FILTER_BAR_CHANGE_ACTIVE_TAB,
    tab,
  };
}

export function removeActiveFilter(filter) {
  return {
    type: t.HUB_FILTER_BAR_ACTIVE_FILTER_REMOVE,
    filter,
  };
}

export function addActiveFilter(filter) {
  return {
    type: t.HUB_FILTER_BAR_ACTIVE_FILTER_ADD,
    filter,
  };
}

export function removePreSelectedFilter(filter) {
  return {
    type: t.HUB_FILTER_BAR_PRESELECTED_FILTER_REMOVE,
    filter,
  };
}

export function addPreSelectedFilter(filter) {
  return {
    type: t.HUB_FILTER_BAR_PRESELECTED_FILTER_ADD,
    filter,
  };
}

export function selectSingleRun(run) {
  return {
    type: t.HUB_SELECT_SINGLE_RUN,
    run,
  };
}

export function selectMultipleRuns(mode) {
  return {
    type: t.HUB_SELECT_MULTIPLE_RUNS,
    mode,
  };
}

export function removeSelectedRun(run) {
  return {
    type: t.HUB_SELECTED_RUN_REMOVE,
    run,
  };
}

export function addSelectedRun(run) {
  return {
    type: t.HUB_SELECTED_RUN_ADD,
    run,
  };
}

export function addSelectedRunBulkReview(run) {
  return {
    type: t.BULK_REVIEW_SELECTED_RUN_ADD,
    run,
  };
}

export function showChart() {
  return {
    type: t.SHOW_CHART,
  };
}

export function hideChart() {
  return {
    type: t.HIDE_CHART,
  };
}

export function showSearch() {
  return {
    type: t.SHOW_SEARCH,
  };
}

export function hideSearch() {
  return {
    type: t.HIDE_SEARCH,
  };
}

export function startLoading() {
  return {
    type: t.START_LOADING,
  };
}

export function stopLoading() {
  return {
    type: t.STOP_LOADING,
  };
}

export function handleSearch(query) {
  return {
    type: t.HANDLE_SEARCH,
    query,
  };
}
