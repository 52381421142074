import React from "react";

interface Props {
  averageMS2: number;
  averagePositiveControlOrf1ab: number;
  averagePositiveControlMS2: number;
  averagePositiveControlS: number;
}

const BulkReviewSummaryModalAverages: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    averageMS2,
    averagePositiveControlOrf1ab,
    averagePositiveControlMS2,
    averagePositiveControlS,
  } = props;

  return (
    <div className="bulk-review-summary__average-section">
      <span className="section-header" id="averages-header">
        AVERAGES
      </span>
      <table>
        <tbody>
          <tr
            className="bulk-review-summary__row-darkened__average"
            id="average-ms2-row"
          >
            <th>Average MS2 in Patient Samples</th>
            <td>{averageMS2}</td>
          </tr>
          <tr
            className="bulk-review-summary__row__average"
            id="average-orf1ab-cq-row"
          >
            <th>Positive Control Cq - Orf1ab</th>
            <td>{averagePositiveControlOrf1ab}</td>
          </tr>
          <tr
            className="bulk-review-summary__row-darkened__average"
            id="average-ms2-cq-row"
          >
            <th>Positive Control Cq - MS2</th>
            <td>{averagePositiveControlMS2}</td>
          </tr>
          <tr
            className="bulk-review-summary__row__average"
            id="average-s-cq-row"
          >
            <th>Positive Control Cq - S</th>
            <td>{averagePositiveControlS}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BulkReviewSummaryModalAverages;
