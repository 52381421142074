import React, { Fragment } from "react";

import { LARGE_NAME_THRESHOLD } from "../../../constants";

const DefaultProtocolTable = props => {
  const { data } = props;

  let reverseTranscriptionTime = null;
  if (data.rtTime) {
    reverseTranscriptionTime = data.rtTime;
  }

  let extensionTime = null;
  if (data.cycleExtensionTime) {
    extensionTime = data.cycleExtensionTime;
  }

  let rtTempDisplayValue = "No RT";
  if (data.rtTemp) {
    rtTempDisplayValue = `${data.rtTemp} C`;
  }

  let rtTimeDisplayValue = null;
  if (reverseTranscriptionTime) {
    rtTimeDisplayValue = (
      <div style={{ paddingLeft: "5px" }}>{reverseTranscriptionTime} sec</div>
    );
  }

  let extensionTempDisplayValue = "No Extension";
  if (data.cycleExtensionTemp) {
    extensionTempDisplayValue = `${data.cycleExtensionTemp} C`;
  }

  let extensionTimeDisplayValue = null;
  if (extensionTime) {
    extensionTimeDisplayValue = (
      <div style={{ paddingLeft: "5px" }}>{extensionTime} sec</div>
    );
  }

  const {
    protocolName,
    firstDenatureTemp,
    firstDenatureTime,
    cycles,
    cycleDenatureTime,
    cycleDenatureTemp,
    cycleAnnealingTemp,
    cycleAnnealingTime,
    duration,
  } = data;

  let nameStyle = {};

  if (protocolName.length >= LARGE_NAME_THRESHOLD) {
    nameStyle = {
      paddingLeft: "10px",
      overflowWrap: "anywhere",
    };
  }

  return (
    <Fragment>
      <tr className="detailed-dropdown__row-darkened">
        <th>Name</th>
        <td style={nameStyle}>{protocolName}</td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>RT</th>
        <td>
          {rtTempDisplayValue}
          {rtTimeDisplayValue}
        </td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Initial Denature</th>
        <td>
          {firstDenatureTemp} C
          <div style={{ paddingLeft: "5px" }}>{firstDenatureTime} sec</div>
        </td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Cycles</th>
        <td>{cycles}</td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Cycling Denature</th>
        <td>
          {cycleDenatureTemp} C
          <div style={{ paddingLeft: "5px" }}>{cycleDenatureTime} sec</div>
        </td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Anneal</th>
        <td>
          {cycleAnnealingTemp} C
          <div style={{ paddingLeft: "5px" }}>{cycleAnnealingTime} sec</div>
        </td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Extension</th>
        <td>
          {extensionTempDisplayValue}
          {extensionTimeDisplayValue}
        </td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Duration</th>
        <td>{duration}</td>
      </tr>
    </Fragment>
  );
};

export default DefaultProtocolTable;
