import React from "react";
import Checkbox from "../../../../../../Layout/Checkbox";
import { BulkRunBlock, RunTypes } from "../../../../../types";
import { formattedRunTypeMap } from "./constants";
import * as styles from "./styles";

interface Props {
  runBlock: BulkRunBlock;
  failed: boolean;
  toggleRunBlock: (active: boolean, runId: string) => void;
}

const BulkRunBlockEntry: React.FunctionComponent<Props> = (props: Props) => {
  const { runBlock, failed, toggleRunBlock } = props;
  const { name, type } = runBlock;

  const renderRunToggle = () => {
    let checked = runBlock.active;
    if (failed) {
      checked = false;
    }

    return (
      <Checkbox
        key={`checkbox run ${runBlock.runId}`}
        checked={checked}
        onClick={() => {
          return toggleRunBlock(runBlock.active, runBlock.runId);
        }}
        label=""
        disabled={failed}
      />
    );
  };

  const renderPreHeading = () => {
    if (!type) {
      return null;
    }

    let failedStyle = {};
    if (failed) {
      failedStyle = styles.failedRunHeading;
    }

    return (
      <h4
        style={{
          ...styles.bulkReviewSidebarEntryPreheading,
          ...failedStyle,
        }}
        className="bulk_review__sidebar__entry_preheading"
      >
        {formattedRunTypeMap[type as RunTypes]}
      </h4>
    );
  };

  const renderErrorWarning = () => {
    if (!failed) {
      return null;
    }

    return (
      <h4
        style={styles.bulkReviewSidebarEntryErrorMessage}
        className="bulk_review__sidebar__entry_postheading"
      >
        Run cannot be processed, please contact support.
      </h4>
    );
  };

  const renderHeader = () => {
    let failedStyle = {};
    if (failed) {
      failedStyle = styles.failedRunHeading;
    }

    return (
      <h3
        style={{
          ...styles.bulkReviewSidebarEntryHeading,
          ...styles.bulkReviewSidebarEntryHeadingText,
          ...failedStyle,
        }}
        className="bulk_review__sidebar__entry_heading"
      >
        {renderRunToggle()} {name}
      </h3>
    );
  };

  return (
    <>
      {renderPreHeading()}
      {renderHeader()}
      {renderErrorWarning()}
    </>
  );
};

export default BulkRunBlockEntry;
