import React from "react";
import NewWindow from "react-new-window";

import QRCodeGenerator from "./QRCodeGenerator";
import { createQRText } from "../helpers";

const PrintLabelView = ({ record, closePrintModal }) => (
  <NewWindow onUnload={closePrintModal} onBlock={closePrintModal}>
    <body className="sample-hub__print-body">
      <div
        style={{
          position: "absolute",
          top: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <QRCodeGenerator
          qrText={createQRText(record.id, record.shortName)}
          width={25}
        />
        <p style={{ fontWeight: "900" }}>{record.shortName}</p>
      </div>
    </body>
  </NewWindow>
);

export default PrintLabelView;
