import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";

import {
  cyclesDisplayedButtonStyle,
  cyclesDisplayedButtonContentStyle,
  cyclesDisplayedNumberStyle,
  disabledCyclesDisplayedButtonStyle,
} from "./styles";
import { openCyclesDisplayedModal } from "../../../actions";
import {
  getXAxis,
  isCyclesDisplayedOpen,
  getCyclesDisplayedLowerBound,
  getCyclesDisplayedUpperBound,
} from "../../../selectors";

interface StateProps {
  cyclesDisplayedModalIsOpen: boolean;
  getXAxis: any;
  lowerBound: number;
  upperBound: number;
}
interface DispatchProps {
  openCyclesDisplayedModalBox: () => void;
}

interface Props extends RouteComponentProps<any>, DispatchProps, StateProps {}

const CyclesDisplayedButton: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    cyclesDisplayedModalIsOpen,
    // eslint-disable-next-line no-shadow
    getXAxis,
    openCyclesDisplayedModalBox,
    lowerBound,
    upperBound,
  } = props;

  let cyclesDisplayedModalIconStyle = {};
  if (cyclesDisplayedModalIsOpen) {
    cyclesDisplayedModalIconStyle = {
      transform: "rotate(180deg) scaleX(-1)",
    };
  }

  const displayBounds = () => {
    const { length } = getXAxis;
    if (length <= 5) {
      return " - ";
    }
    if (lowerBound === -1 && upperBound === -1) {
      return `0 - ${length}`;
    }

    return `${lowerBound} - ${upperBound}`;
  };

  const _renderCyclesDisplayedButton = () => {
    const { length } = getXAxis;
    const bounds = displayBounds();

    let style = cyclesDisplayedButtonStyle;
    if (length <= 5) {
      style = disabledCyclesDisplayedButtonStyle;
    }

    return (
      <div style={style} onClick={openCyclesDisplayedModalBox}>
        <div style={cyclesDisplayedButtonContentStyle}>
          <span>Cycles Displayed </span>
          <span style={cyclesDisplayedNumberStyle}>{`${bounds}`}</span>
          <img
            className="cycles-displayed-modal-icon"
            src="../../../../images/BackArrowNavy.svg"
            style={cyclesDisplayedModalIconStyle}
            alt=""
            height="20"
            width="13"
          />
        </div>
      </div>
    );
  };

  return <div className="cycles_section">{_renderCyclesDisplayedButton()}</div>;
};

const mapStateToProps = (state: any) => ({
  cyclesDisplayedModalIsOpen: isCyclesDisplayedOpen(state),
  getXAxis: getXAxis(state),
  lowerBound: getCyclesDisplayedLowerBound(state),
  upperBound: getCyclesDisplayedUpperBound(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCyclesDisplayedModalBox: () => {
    dispatch(openCyclesDisplayedModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CyclesDisplayedButton);
