import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { generateUuid } from "js-common/lib/helpers";
import { debounce } from "lodash";

import Sidebar from "../../Sidebar";
import { Content } from "../../DataRoute/components/content";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";

import {
  setActiveSampleTemplate as actionSetActiveSampleTemplate,
  unsetActiveSampleTemplate as actionUnsetActiveSampleTemplate,
  uploadRecords as actionUploadRecords,
  clearErrors as actionClearErrors,
  setTableDisplay,
} from "../actions";
import {
  getActiveTemplate,
  getTemplatesDateFormatted,
  getRecordPostErrors,
  getActiveRecord,
} from "../selectors";
import { getActiveTeamId, getIDToken } from "../../Auth/selectors";

import { metadataTypeMap, placeholderTypeMap } from "../constants";
import { createQRText, shortenText } from "../helpers";
import QRCodeGenerator from "../subcomponents/QRCodeGenerator";
import Select from "../../Layout/Select";
import { tooltipDelay } from "../../RunsPage/constants";
import BackButton from "../subcomponents/BackButton";
import Button from "../../Layout/Button";

class CreateRecord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      records: [],
      error: "",
    };
  }

  componentDidMount = () => {
    const {
      templates,
      activeTemplate,
      setActiveSampleTemplate,
      putTableDisplay,
      history,
    } = this.props;

    if (!activeTemplate.id) {
      setActiveSampleTemplate(templates[0].id);
    } else {
      const { location } = history;
      const { search } = location;

      if (search.includes("duplicate")) {
        const { activeRecord, activeTeamId } = this.props;
        const { metadata: templateMetadata } = activeTemplate;
        const { metadata: recordMetadata } = activeRecord;

        const mergedMetadata = [];
        templateMetadata.forEach(templateField => {
          const { parameterName: templateFieldName } = templateField;
          const recordInstance = recordMetadata.find(
            recordField => recordField.parameterName === templateFieldName
          );

          if (recordInstance) {
            mergedMetadata.push(recordInstance);
          } else {
            mergedMetadata.push({
              ...templateField,
              value: templateField.defaultValue,
            });
          }
        });

        const duplicatedRecord = {
          id: generateUuid(),
          teamId: activeTeamId,
          metadata: mergedMetadata,
          date: Date.now(),
          name: `${activeRecord.name} - COPY`,
          shortName: `${activeRecord.shortName} - COPY`,
          templateName: activeTemplate.name,
          templateId: activeTemplate.id,
          username: activeRecord.user,
        };
        this.setState({ records: [duplicatedRecord] });
      } else {
        this.addRecord();
      }
    }

    putTableDisplay("record");
  };

  componentDidUpdate(prevProps) {
    const { activeTemplate } = this.props;

    if (
      activeTemplate &&
      activeTemplate.id &&
      prevProps.activeTemplate.id !== activeTemplate.id
    ) {
      this.addRecord();
    }
  }

  componentWillUnmount = () => {
    const { unsetActiveSampleTemplate } = this.props;

    unsetActiveSampleTemplate();
  };

  // eslint-disable-next-line class-methods-use-this
  getFormattedDate(date) {
    if (date) {
      const dateArr = date.split("-");

      return `${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`;
    }

    return "yyyy/mm/dd";
  }

  addRecord = () => {
    const { activeTeamId, activeTemplate, userDetails, clearErrors } =
      this.props;
    const { records } = this.state;
    const { metadata } = activeTemplate;

    clearErrors();

    if (activeTemplate) {
      const newRecord = {
        id: generateUuid(),
        teamId: activeTeamId,
        metadata: [],
        date: Date.now(),
        name: "",
        shortName: "",
        templateName: activeTemplate.name,
        templateId: activeTemplate.id,
        username: `${userDetails.given_name} ${userDetails.family_name}`,
      };

      newRecord.metadata = metadata.map(field => ({
        ...field,
        value: field.defaultValue,
      }));

      this.setState({ records: [...records, newRecord], error: "" });
    }
  };

  deleteRecord = id => {
    const { records } = this.state;

    if (records.length < 2) {
      return;
    }

    const updatedRecords = [...records];
    const index = updatedRecords.findIndex(record => record.id === id);
    updatedRecords.splice(index, 1);

    this.setState({
      records: updatedRecords,
      error: "",
    });
  };

  handleFieldUpdate = (id, parameterName, value) => {
    const { records } = this.state;

    const recordIndex = records.findIndex(record => record.id === id);
    const metadataIndex = records[recordIndex].metadata.findIndex(
      field => field.parameterName === parameterName
    );

    const newRecords = [...records];
    const newMetadata = [...newRecords[recordIndex].metadata];
    newMetadata[metadataIndex] = {
      ...newMetadata[metadataIndex],
      value,
    };

    newRecords[recordIndex] = {
      ...newRecords[recordIndex],
      metadata: newMetadata,
    };

    this.setState({ records: newRecords });
  };

  handleRecordDataUpdate = (id, field, value) => {
    const { records } = this.state;

    const newRecords = [...records];
    const recordIndex = records.findIndex(record => record.id === id);

    newRecords[recordIndex] = {
      ...newRecords[recordIndex],
      [field]: value,
    };

    this.setState({
      records: newRecords,
      error: "",
    });
  };

  handleRecordSubmit = () => {
    const { uploadRecords } = this.props;
    const { records } = this.state;
    let aRequiredFieldIsEmpty = false;

    for (let i = 0; i < records.length; i += 1) {
      const record = records[i];

      aRequiredFieldIsEmpty = record.metadata.some(
        field => !field.optional && !field.value
      );

      if (!record.name || !record.shortName) {
        aRequiredFieldIsEmpty = true;
      }

      if (aRequiredFieldIsEmpty) {
        break;
      }
    }

    if (aRequiredFieldIsEmpty) {
      this.setState({ error: "Please complete all required fields." });
    } else {
      uploadRecords(records);
    }
  };

  renderTemplateSelectionDropdown = () => {
    const {
      templates,
      activeTemplate = { id: "" },
      setActiveSampleTemplate,
    } = this.props;

    if (!templates || templates.length < 1) {
      return null;
    }

    return (
      <div
        className="sample-hub__cell"
        style={{ marginTop: 25, marginBottom: 25 }}
        key="template-dropdown"
      >
        <p>Select Sample Template</p>
        <Select
          value={activeTemplate.id}
          items={templates.map(template => {
            return {
              value: template.id,
              display: shortenText(template.name, 20),
            };
          })}
          onChange={e => {
            setActiveSampleTemplate(e.target.value);
            this.setState({ records: [] }, () => this.addRecord());
          }}
          width={200}
          height={35}
        />
      </div>
    );
  };

  renderInputs = (record, recordIndex) => {
    const { records } = this.state;
    const { metadata } = record;

    const metadataFields = metadata.map((field, fieldIndex) => {
      let parameterNameToDisplay = field.parameterName;
      if (field.optional) {
        parameterNameToDisplay += " (optional)";
      }

      let inputType = metadataTypeMap[metadata[fieldIndex].type];
      const placeholder = placeholderTypeMap[inputType];

      let { value } = metadata[fieldIndex];
      let formattedDate;
      let inputClass;
      if (inputType === "date") {
        inputClass = "sample-hub__date-picker";
        if (value.includes("/")) {
          value = value.split("/").join("-");
        }
        formattedDate = this.getFormattedDate(value);
      }

      if (inputType === "location") {
        inputType = "text";
      }

      return (
        <div
          className="sample-hub__cell"
          style={{ marginTop: 5 }}
          key={`${record.id}-${field.parameterName}`}
        >
          <p
            style={{
              width: "40%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {parameterNameToDisplay}
          </p>
          <input
            className={inputClass}
            data-date={formattedDate}
            style={{ width: 200, height: 35 }}
            placeholder={placeholder}
            value={value}
            type={inputType}
            disabled={false}
            onChange={e =>
              this.handleFieldUpdate(
                record.id,
                field.parameterName,
                e.target.value,
                e.target.type
              )
            }
          />
        </div>
      );
    });

    let deleteRecordOpacity = 1;
    if (records.length < 2) {
      deleteRecordOpacity = 0.5;
    }

    return (
      <div
        className="sample-hub__record-container"
        style={{ marginBottom: 25 }}
        key={record.id}
      >
        <ReactTooltip delayShow={tooltipDelay} />
        <div
          className="sample-hub__input-row"
          style={{ justifyContent: "space-between", marginBottom: -15 }}
        >
          <h2>QR Code {recordIndex + 1}</h2>
          <div
            className="sample-hub__delete"
            style={{
              paddingRight: "40px",
              opacity: deleteRecordOpacity,
            }}
            onClick={() => this.deleteRecord(record.id)}
          >
            Delete
          </div>
        </div>
        <div className="sample-hub__record-form">
          <div>{metadataFields}</div>
          <hr
            className="settings__horizontal-rule"
            style={{ minWidth: "1000px" }}
          />
          <div
            className="sample-hub__input-row"
            style={{ justifyContent: "space-between", marginRight: "200px" }}
          >
            <div>
              <div className="sample-hub__cell">
                <p data-tip="A descriptive name used to help keep your data organized.">
                  Name
                </p>
                <input
                  style={{ width: 200, height: 35 }}
                  value={records[recordIndex].name}
                  onChange={e =>
                    this.handleRecordDataUpdate(
                      record.id,
                      "name",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="sample-hub__cell">
                <p data-tip="Short Name will appear in the Biomeme mobile app when this QR code is used. (20 Character Limit)">
                  Short Name
                </p>
                <input
                  style={{ width: 200, height: 35 }}
                  value={records[recordIndex].shortName}
                  maxLength="20"
                  onChange={e =>
                    this.handleRecordDataUpdate(
                      record.id,
                      "shortName",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <QRCodeGenerator
              qrText={createQRText(record.id, record.shortName)}
            />
          </div>
        </div>
      </div>
    );
  };

  renderRecords = () => {
    const { records } = this.state;

    return records.map((record, recordIndex) =>
      this.renderInputs(record, recordIndex)
    );
  };

  renderErrorText = () => {
    const { error } = this.state;
    const { submitError } = this.props;

    let isSubmitError = submitError;
    if (error) {
      isSubmitError = null;
    }

    return (
      <div
        key="error-text"
        style={{
          height: "20px",
          marginBottom: "10px",
          color: "red",
          fontWeight: 600,
        }}
        id="create-record__error-text"
      >
        {error}
        {isSubmitError}
      </div>
    );
  };

  renderButtons = () => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          title="Add QR Code"
          onClick={this.addRecord}
          buttonClass="btn sample-hub__button"
        />
        <Button
          title="Finish and Submit"
          onClick={debounce(() => this.handleRecordSubmit(), 1000)}
          buttonClass="btn sample-hub__button"
        />
      </div>
    );
  };

  render() {
    return (
      <div className="fluidContainers">
        <AppUpdateModal />
        <Sidebar />
        <Content>
          <div className="page">
            <div
              className="page__container"
              style={{ minWidth: "1000px", height: "100%" }}
            >
              <div className="sample-hub__record-container">
                <BackButton />
                <h1>Create Sample QR Code</h1>
                <hr
                  className="settings__horizontal-rule"
                  style={{ minWidth: "1000px" }}
                />
                {this.renderTemplateSelectionDropdown()}
                {this.renderRecords()}
              </div>
            </div>

            <div className="sample-hub__button-container">
              {this.renderErrorText()}
              {this.renderButtons()}
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeTeamId: getActiveTeamId(state),
    activeTemplate: getActiveTemplate(state),
    activeRecord: getActiveRecord(state),
    templates: getTemplatesDateFormatted(state),
    userDetails: getIDToken(state),
    submitError: getRecordPostErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveSampleTemplate: team => {
      dispatch(actionSetActiveSampleTemplate(team));
    },
    unsetActiveSampleTemplate: () => {
      dispatch(actionUnsetActiveSampleTemplate());
    },
    uploadRecords: records => {
      dispatch(actionUploadRecords(records));
    },
    clearErrors: () => {
      dispatch(actionClearErrors());
    },
    putTableDisplay: display => {
      dispatch(setTableDisplay(display));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecord);
