import { all, call, put, takeLatest, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";

import { backendApi } from "../Utils";
import {
  protocolsReceived,
  getExternalUploadProtocolsSuccess,
} from "./actions";
import { getActiveFolderData } from "../Folders/selectors";
import { GET_SELECTED_SUCCESS } from "../Folders/actionTypes";
import { getActiveTeamId, getActiveTeamVersion } from "../Auth/selectors";
import {
  CREATE_PROTOCOL,
  GET_EXTERNAL_UPLOAD_PROTOCOLS_REQUEST,
} from "./actionTypes";
import { isDetailsVersionTwoOrGreater } from "../FullRunData/helpers";
import { ABI_DETAILS_VERSION, BIORAD_DETAILS_VERSION } from "../Hub/constants";
import CloudLog from "../Utils/CloudLog";

function* handleGetProtocolsForDetailsVersion(
  apiEndpoint,
  teamId,
  selection,
  detailsVersion
) {
  const { ok, status, data, problem } = yield call(
    backendApi.get,
    apiEndpoint,
    {
      teamId,
      selection,
      detailsVersion,
    }
  );

  if (ok) {
    const protocols = data || [];
    
    return protocols;
  }

  throw new Error(
    `Failed to retrieve ${detailsVersion} protocols ${status}/${problem}`
  );
}

function* handleCollectExternalProtocols(apiEndpoint, teamId, selection) {
  const bioradProtocols = yield call(
    handleGetProtocolsForDetailsVersion,
    apiEndpoint,
    teamId,
    selection,
    BIORAD_DETAILS_VERSION
  );
  const abiProtocols = yield call(
    handleGetProtocolsForDetailsVersion,
    apiEndpoint,
    teamId,
    selection,
    ABI_DETAILS_VERSION
  );

  return [...bioradProtocols, ...abiProtocols];
}

export function* getFolderProtocols() {
  const { protocolIds } = yield select(getActiveFolderData);
  const teamId = yield select(getActiveTeamId);
  const detailsVersion = yield select(getActiveTeamVersion);
  const session = yield call([Auth, Auth.currentSession]);
  const jwt = session.idToken.jwtToken;
  backendApi.setHeader("Authorization", jwt);

  const queryString = protocolIds.reduce((prev, curr, i) => {
    if (i < protocolIds.length && i > 0) {
      return `${prev},${curr}`;
    }

    return prev + curr;
  }, "");

  let data = [];

  const summaryEndPoint = `/protocols/summary`;

  const teamProtocols = yield call(
    handleGetProtocolsForDetailsVersion,
    summaryEndPoint,
    teamId,
    queryString,
    detailsVersion
  );

  data = [...teamProtocols];

  if (isDetailsVersionTwoOrGreater(detailsVersion)) {
    const externalProtocols = yield call(
      handleCollectExternalProtocols,
      summaryEndPoint,
      teamId,
      queryString
    );

    data = [...data, ...externalProtocols];
  }

  yield put(protocolsReceived(data));
}

export function* watchFolderSelected() {
  yield takeLatest(GET_SELECTED_SUCCESS, getFolderProtocols);
}

export function* handleCreateProtocol(action) {
  const { detailsVersion, protocolData, resolve, reject } = action;
  const teamId = yield select(getActiveTeamId);

  const { ok } = yield call(
    backendApi.post,
    `/protocols?teamId=${teamId}&detailsVersion=${detailsVersion}`,
    {
      id: protocolData.id,
      name: protocolData.name,
      details: JSON.stringify(protocolData),
    }
  );

  if (ok) {
    resolve("Successfully created protocol!");
  } else {
    reject("An error occurred creating protocol. Try again?");
  }
}

export function* watchCreateProtocol() {
  yield takeLatest(CREATE_PROTOCOL, handleCreateProtocol);
}

function* handleGetSharedProtocols(detailsVersion) {
  const { ok, data, status, problem } = yield call(
    backendApi.get,
    "/protocols/shared",
    {
      detailsVersion,
    }
  );

  if (ok && data) {
    return data;
  }

  throw new Error(
    `Failed to retrieve shared protocols with ${status}/${problem}`
  );
}

function* handleGetTeamProtocols(detailsVersion, teamId) {
  const { ok, data, status, problem } = yield call(
    backendApi.get,
    "/protocols",
    {
      detailsVersion,
      teamId,
    }
  );

  if (ok && data) {
    return data;
  }

  throw new Error(
    `Failed to retrieve team protocols with ${status}/${problem}`
  );
}

export function* handleGetExternalUploadProtocols(action) {
  const teamId = yield select(getActiveTeamId);
  const { detailsVersion } = action;

  let externalUploadProtocols = [];

  try {
    const sharedProtocols = yield call(
      handleGetSharedProtocols,
      detailsVersion
    );
    const teamProtocols = yield call(
      handleGetTeamProtocols,
      detailsVersion,
      teamId
    );
    externalUploadProtocols = [...sharedProtocols, ...teamProtocols];
  } catch (e) {
    CloudLog.error(`Error in handleGetExternalUploadProtocols: ${e}`);
  }

  yield put(getExternalUploadProtocolsSuccess(externalUploadProtocols));
}

export function* watchGetExternalUploadProtocolsRequest() {
  yield takeLatest(
    GET_EXTERNAL_UPLOAD_PROTOCOLS_REQUEST,
    handleGetExternalUploadProtocols
  );
}

export default function* rootSaga() {
  yield all([
    watchFolderSelected(),
    watchCreateProtocol(),
    watchGetExternalUploadProtocolsRequest(),
  ]);
}
