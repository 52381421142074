import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Auth from "@aws-amplify/auth";

import Icon from "../../Utils/Layout/Icon";
import Button from "../../Layout/Button";
import Loader from "../../Layout/Loader";

import { getSamlProcessing } from "../selectors";
import { loginUser, authenticateSamlUser } from "../actions";
import CloudLog from "../../Utils/CloudLog";

export class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      shake: false,
    };
  }

  componentDidMount() {
    this.setState({ errorMessage: "" });

    const { location } = this.props;
    const { pathname, search } = location;

    if (
      pathname.includes("login") &&
      search.includes("code") &&
      localStorage.getItem("amplify-redirected-from-hosted-ui")
    ) {
      Auth.currentAuthenticatedUser()
        .then(this.handleSamlAuthentication)
        .catch(() => {
          CloudLog.error("No current authenticated user");
        });
    }
  }

  formatErrorMessage = error => {
    if (error.includes("User does not exist")) {
      return "User does not exist.";
    }

    if (error.includes("Username cannot be empty")) {
      return "Username cannot be empty.";
    }

    return "Incorrect username or password.";
  };

  handleError = error => {
    const cleanError = this.formatErrorMessage(error);

    this.setState(
      {
        errorMessage: cleanError,
        shake: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ shake: false });
        }, 401);
      }
    );
  };

  handlePreSubmitTasks = () => {
    this.setState({ errorMessage: "" });
    Cookies.set("biomemeCookieConsent", true);
  };

  handleSamlAuthentication = () => {
    const { samlUserAuthentication } = this.props;

    this.handlePreSubmitTasks();

    samlUserAuthentication();
  };

  handleSubmit = () => {
    const { login } = this.props;
    const { email, password } = this.state;

    this.handlePreSubmitTasks();

    return new Promise((resolve, reject) => {
      const credentials = {
        email: email.trim(),
        password: password.trim(),
      };

      login(credentials, resolve, reject);
    }).catch(error => this.handleError(error));
  };

  handleFocus = event => event.target.select();

  handleProviderLogin = () => {
    const { history } = this.props;
    history.push("/provider-login");
  };

  handleEnter = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    const { samlIsProcessing } = this.props;
    const { email, password, errorMessage, shake } = this.state;

    let shakeClass = null;
    if (shake) {
      shakeClass = "login_form__shake";
    }

    return (
      <Fragment>
        <div className="login_form" onKeyPress={this.handleEnter}>
          {errorMessage && (
            <span style={{ width: "200px" }} className={shakeClass}>
              {errorMessage}
            </span>
          )}
          <div className="login_form__email">
            <p className="login_form__label">Email</p>
            <div className="login_form__input_wraper">
              <Icon name="user" className="login_form__icon" />
              <input
                className="login_form__input"
                placeholder="Email"
                value={email}
                onFocus={this.handleFocus}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </div>
          </div>

          <div className="login_form__password">
            <p className="login_form__label">Password</p>
            <div className="login_form__input_wraper">
              <Icon name="password" className="login_form__icon" />
              <input
                type="password"
                className="login_form__input"
                placeholder="Password"
                value={password}
                onFocus={this.handleFocus}
                onChange={e => this.setState({ password: e.target.value })}
              />
            </div>
          </div>
          <div className="login_form__submit_row">
            <Button
              title="Login"
              onClick={() => this.handleSubmit()}
              //  buttonStyle={{ width: "300px" }}
            />
          </div>
          <div className="login__bottom_row">
            <span className="login__bottom_row__text">
              <Link to="/forgot-password" className="login__bottom_row__link">
                Forgot password?
              </Link>
              <Link to="/register" className="login__bottom_row__link">
                Create an Account
              </Link>
            </span>
          </div>
          <div className="login__provider_container">
            <hr />
            <h1>Login with Your</h1>
            <h1 style={{ marginBottom: "20px" }}>Organization Credentials</h1>
            <Button
              title="Login with Credentials"
              onClick={() => this.handleProviderLogin()}
              //  buttonStyle={{ width: "300px" }}
            />
            <p style={{ marginTop: "20px" }}>
              This will briefly take you to an external site.
            </p>
            <p> You will return after logging in.</p>
          </div>

          {/* <div className="login__request">
            <span>Need a Biomeme Cloud account?</span>
            <span className="login__request-text">
              <a
                href="https://biomeme.typeform.com/to/kVE2Qm"
                className="login__request_link"
              >
                Request an Account
              </a>
            </span>
          </div> */}
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept Cookies"
          cookieName="biomemeCookieConsent"
          sameSite="strict"
          style={{ background: "#2B373B" }}
          buttonStyle={{ background: "#1cbcc3", color: "#FFFFFF" }}
          acceptOnScroll
        >
          By continuing to browse, or by clicking Accept Cookies, you agree to
          the storing of cookies on your device to enhance your experience.
        </CookieConsent>
        <Loader show={samlIsProcessing} />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    samlIsProcessing: getSamlProcessing(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (credentials, resolve, reject) =>
      dispatch(loginUser(credentials, resolve, reject)),
    samlUserAuthentication: () => dispatch(authenticateSamlUser()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
