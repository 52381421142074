import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { debounce } from "lodash";
import { BaseContainer } from "bio-web-components";

import { setAppIsMobile, reset } from "./actions";
import { logoutReq, logoutUserSuccess } from "../Auth/actions";
import { toggleChartTutorial } from "../RunsPage/actions";

import { getLoggedIn } from "../Auth/selectors";
import { isBulkReviewModeActive as isBulkReviewModeActiveSelector } from "../RunsPage/selectors";

import { Container } from "./components/index";
import CloudLog from "../Utils/CloudLog";
import routes from "../store/routes";

const RootContainer = props => {
  const handleWindowClose = async () => {
    const { logoutSuccess } = props;

    Auth.signOut()
      .then()
      .catch(err => {
        CloudLog.error(`Error in handleWindowClose: ${err}`);
      });

    logoutSuccess();
  };

  const debouncedHandleWindowResize = debounce(() => {
    const { setIsMobile } = props;

    if (window.innerWidth <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, 500);

  useEffect(() => {
    debouncedHandleWindowResize();
    window.addEventListener("resize", debouncedHandleWindowResize);

    if (!window.Cypress) {
      window.addEventListener("unload", () => {
        handleWindowClose();
      });
    }

    return () => {
      if (!window.Cypress) {
        window.removeEventListener("unload", () => {
          handleWindowClose();
        });
      }
      window.removeEventListener("resize", debouncedHandleWindowResize);
    };
  }, []);

  const renderRunsMenu = () => {
    const { toggleTutorial, location, bulkReviewIsActive } = props;

    if (location.pathname.includes("/runs-page") && !bulkReviewIsActive) {
      return (
        <span
          data-tip="Click here to start an introduction to the Biomeme Cloud."
          data-place="bottom"
          className="header_link log"
          onClick={() => {
            toggleTutorial(true);
          }}
        >
          ?
        </span>
      );
    }

    return null;
  };

  const renderMenu = () => {
    const { logout, loggedIn } = props;

    if (loggedIn) {
      return (
        <div className="top-menu">
          {renderRunsMenu()}
          <Link className="header_link" to="/settings">
            Settings
          </Link>
          <span className="header_link logout" onClick={logout}>
            Logout
          </span>
        </div>
      );
    }

    return null;
  };

  const { logoReset } = props;

  return (
    <BaseContainer headerMenu={renderMenu()} logoOnClick={logoReset}>
      <Container>{routes}</Container>
    </BaseContainer>
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: getLoggedIn(state),
    bulkReviewIsActive: isBulkReviewModeActiveSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsMobile: isMobile => {
      return dispatch(setAppIsMobile(isMobile));
    },
    logout: () => {
      return dispatch(logoutReq());
    },
    logoutSuccess: () => {
      return dispatch(logoutUserSuccess());
    },
    toggleTutorial: status => {
      return dispatch(toggleChartTutorial(status));
    },
    logoReset: () => {
      return dispatch(reset());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootContainer)
);
