import React from "react";
import { connect } from "react-redux";

import Sidebar from "../../Sidebar";
import { Content } from "../../DataRoute/components/content";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";

import SampleLayoutTable from "./Table";

import { uploadTemplate as actionUploadTemplate } from "../actions";
import { getActiveTeamId } from "../../Auth/selectors";
import BackButton from "../subcomponents/BackButton";

const CreateSampleTemplate = props => {
  const renderTable = () => {
    const { uploadTemplate, activeTeamId } = props;

    return (
      <div className="page">
        <div
          className="page__container"
          style={{ minWidth: "1000px", height: "100%" }}
        >
          <div className="sample-hub__header-div">
            <BackButton />
            <h1>Create Sample Layout</h1>
            <hr
              className="settings__horizontal-rule"
              style={{ minWidth: "1000px" }}
            />
            <SampleLayoutTable
              uploadTemplate={uploadTemplate}
              activeTeamId={activeTeamId}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fluidContainers">
      <AppUpdateModal />
      <Sidebar />
      <Content>{renderTable()}</Content>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    activeTeamId: getActiveTeamId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadTemplate: template => {
      dispatch(actionUploadTemplate(template));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSampleTemplate);
