import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { generateTimeString } from "js-common/lib/helpers";

import { extractSampleNames, assembleDetailedData } from "../../helpers";
import { getActiveTeamVersion } from "../../../Auth/selectors";
import DropdownBox from "../../../Layout/DetailedDataDropdownBox";

import IsothermalIcon from "../../../Utils/Layout/IsothermalIcon";
import QuantitativeIcon from "../../../Utils/Layout/QuantitativeIcon";
import MeltIcon from "../../../Utils/Layout/MeltIcon";
import PCRIcon from "../../../Utils/Layout/PCRIcon";
import IncompleteIcon from "../../../Utils/Layout/IncompleteIcon";

import DefaultProtocolTable from "./ProtocolTable/Default";
import MeltProtocolTable from "./ProtocolTable/Melt";
import IsothermalProtocolTable from "./ProtocolTable/Isothermal";
import { initializeSampleHub as actionInitializeSampleHub } from "../../../SampleHub/actions";
import { runTypes } from "../../../Hub/constants";
import { isDetailsVersionTwoOrGreater } from "../../../FullRunData/helpers";
import { UNSPECIFIED_SAMPLE_TEXT } from "../../constants";

export class RunSection extends Component {
  renderNotes = () => {
    const { run } = this.props;
    const { notes, name } = run;

    if (!notes) {
      return null;
    }

    return (
      <div className="notes" key={`${name}-notes`}>
        <h4>NOTES</h4>
        <p>{notes}</p>
      </div>
    );
  };

  renderProtocolDetails(data) {
    const { run } = this.props;
    const { runType } = run;

    let rowsToRender = <DefaultProtocolTable data={data} />;

    if (runType === runTypes.isothermal) {
      rowsToRender = <IsothermalProtocolTable data={data} />;
    } else if (runType === runTypes.melt) {
      rowsToRender = <MeltProtocolTable data={data} />;
    }

    return (
      <div style={{ paddingLeft: "20px" }} key={`${run.name}-protocolDetails`}>
        <span className="section-header">PROTOCOL</span>
        <table>
          <tbody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {rowsToRender}
          </tbody>
        </table>
      </div>
    );
  }

  renderSampleSection(sample) {
    const { run, initializeSampleHub } = this.props;
    const { sampleRecordId, sampleId } = sample;
    const { name } = run;

    let sampleInformationToRender = sampleId;
    let sampleTextColor = "black";

    if (sample === UNSPECIFIED_SAMPLE_TEXT) {
      sampleTextColor = "#03283B";
    }

    if (sampleRecordId) {
      sampleInformationToRender = (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() =>
            initializeSampleHub(`/sample-management/${sampleRecordId}`)
          }
        >
          {sampleId}
        </span>
      );
    }

    return (
      <Fragment key={`${name}-sampleSection`}>
        <div style={{ marginRight: "3px" }}>
          <img
            src="../../../../images/eyedropper.svg"
            alt=""
            height="12"
            width="12"
          />
        </div>
        <div
          style={{
            color: sampleTextColor,
            marginRight: "10px",
          }}
        >
          {sampleInformationToRender}
        </div>
      </Fragment>
    );
  }

  renderSampleCells(sampleRow) {
    const { run } = this.props;

    return sampleRow.map((sample, sampleIndex) => {
      const cellKey = `${run.name}-${sampleIndex}-td`;

      return (
        <td style={{ width: "200px", justifyContent: "center" }} key={cellKey}>
          {this.renderSampleSection(sample)}
        </td>
      );
    });
  }

  renderSampleRows(sampleRows) {
    const { run } = this.props;

    return sampleRows.map((sampleRow, rowIndex) => {
      const rowKey = `${run.name}-${rowIndex}-sampleRow-tr`;

      let rowClassName = "detailed-dropdown__row";
      if (rowIndex % 2 === 0) {
        rowClassName = "detailed-dropdown__row-darkened";
      }

      return (
        <tr className={rowClassName} style={{ width: "600px" }} key={rowKey}>
          {this.renderSampleCells(sampleRow)}
        </tr>
      );
    });
  }

  renderSamples() {
    const { run, version } = this.props;
    const { name } = run;

    const sampleNames = extractSampleNames(run, version);

    const sampleRows = [];
    let currentRow = [];

    sampleNames.forEach((sample, index) => {
      if (index > 0 && index % 3 === 0) {
        sampleRows.push(currentRow);
        currentRow = [];
      }

      currentRow.push(sample);
    });

    sampleRows.push(currentRow);

    return (
      <div key={`${name}-sampleRender`}>
        <span className="section-header">SAMPLES</span>
        <div>
          <table style={{ width: "600px" }}>
            <tbody>{this.renderSampleRows(sampleRows)}</tbody>
          </table>
        </div>
      </div>
    );
  }

  renderContent(data) {
    const { run } = this.props;
    const { name, date } = run;

    return (
      <Fragment key={name}>
        <div
          style={{ display: "flex", flexDirection: "row" }}
          key={`${name}-contentDiv`}
        >
          <div>
            <span className="section-header">RUN DATA</span>
            <table>
              <tbody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <tr className="detailed-dropdown__row-darkened">
                  <th>Date & Time</th>
                  <td>{generateTimeString(date)}</td>
                </tr>
                <tr className="detailed-dropdown__row">
                  <th>Results</th>
                  <td>{data.resultString}</td>
                </tr>
                <tr className="detailed-dropdown__row-darkened">
                  <th>Location</th>
                  <td>{data.locationString}</td>
                </tr>
                <tr className="detailed-dropdown__row">
                  <th>Latitude</th>
                  <td>{data.latitude}</td>
                </tr>
                <tr className="detailed-dropdown__row-darkened">
                  <th>Longitude</th>
                  <td>{data.longitude}</td>
                </tr>
                <tr className="detailed-dropdown__row">
                  <th>Serial Number</th>
                  <td>{data.serialNumber}</td>
                </tr>
                <tr className="detailed-dropdown__row-darkened">
                  <th>BLE Name</th>
                  <td>{data.bleName}</td>
                </tr>
                <tr className="detailed-dropdown__row">
                  <th>Firmware Version</th>
                  <td>{data.firmwareVersion}</td>
                </tr>
                <tr className="detailed-dropdown__row-darkened">
                  <th>Software Version</th>
                  <td>{data.appVersion}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: "10px" }} />
          {this.renderProtocolDetails(data)}
        </div>
        <div className="detailed-dropdown__sample-section">
          {this.renderSamples()}
        </div>
        {this.renderNotes()}
      </Fragment>
    );
  }

  render() {
    const { run = {}, runBlock, version } = this.props;
    const { details: runDetails = {}, protocol = {}, name: runName } = run;
    const { details: protocolDetails, name: protocolName } = protocol;
    const { type, active } = runBlock;

    let runToAssemble = runDetails;
    let protocolToAssemble = protocolDetails;

    if (isDetailsVersionTwoOrGreater(version)) {
      runToAssemble = run;
      protocolToAssemble = protocol;
    }

    const data = assembleDetailedData(
      runToAssemble,
      { name: protocolName, ...protocolToAssemble },
      version
    );

    const { status, runType } = data;

    let statusIcon = null;
    if (status === "incomplete") {
      statusIcon = <IncompleteIcon style={{ marginRight: "10px" }} />;
    }

    const icons = {
      isothermal: <IsothermalIcon opacity={1} />,
      quantitative: <QuantitativeIcon opacity={1} />,
      melt: <MeltIcon opacity={1} />,
      pcr: <PCRIcon opacity={1} />,
    };

    let typeIcon = icons.pcr;
    if (icons[runType]) {
      typeIcon = icons[runType];
    }

    return (
      <DropdownBox
        heading={runName}
        preHeading={type}
        opened={active}
        statusIcon={statusIcon}
        typeIcon={typeIcon}
      >
        {this.renderContent(data)}
      </DropdownBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    version: getActiveTeamVersion(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initializeSampleHub: path => {
      dispatch(actionInitializeSampleHub(path));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RunSection);
