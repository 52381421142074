import { Subscription } from "js-common";

import { UserDetails } from "./types";
import * as t from "./actionTypes";
import { Action } from "./actions";

interface State {
  user: UserDetails | {};
  subscriptions: Subscription[] | null;
  updated: boolean;
  error: string;
}

const initialState: State = {
  user: {},
  subscriptions: null,
  updated: false,
  error: "",
};

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case t.UPDATE_USER_DETAILS_SUCCESS: {
      const { user: stateUser } = state;
      const { user: actionUser } = action;

      return {
        ...state,
        user: Object.assign(stateUser, actionUser),
        updated: true,
      };
    }

    case t.UPDATE_USER_DETAILS_ERROR: {
      const { error } = action;

      return {
        ...state,
        updated: false,
        error,
      };
    }

    case t.RESET_USER_DETAILS: {
      return {
        ...initialState,
      };
    }

    case t.USER_SUBSCRIPTION_DETAILS_SUCCESS: {
      const { subscriptions } = action;

      return {
        ...state,
        subscriptions,
      };
    }

    default: {
      return state;
    }
  }
}
