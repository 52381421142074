import React, { Fragment } from "react";

import { Input } from "bio-web-components";

import { ProtocolValueAndErrorProps } from "../types";

import CyclingParameters from "./CyclingParameters";
import InitialDenature from "./InitialDenature";
import RT from "./RT";
import RTToggle from "./RTToggle";
import ActiveChannelSelector from "./ActiveChannelSelector";

interface Props {
  protocolCreationProps: ProtocolValueAndErrorProps;
  setColorMask: (colorMask: number) => void;
}

const ProtocolFields = (props: Props) => {
  const { protocolCreationProps, setColorMask } = props;
  const { setFieldValue, values, errors } = protocolCreationProps;

  const _renderRT = () => {
    return (
      <div className="protocol_creation-section">
        <RTToggle setValue={setFieldValue} values={values} errors={errors} />
        <RT setValue={setFieldValue} values={values} errors={errors} />
      </div>
    );
  };

  const _renderNameSection = () => {
    return (
      <div className="protocol_creation-section">
        <h3 className="protocol_creation-label">Protocol Name</h3>
        <Input
          containerClasses="protocol_creation-name"
          value={values.name}
          placeholder="Enter protocol name"
          onChange={(value: string | number) => setFieldValue("name", value)}
          error={errors.name}
        />
      </div>
    );
  };

  return (
    <Fragment>
      {_renderNameSection()}
      <ActiveChannelSelector setColorMask={setColorMask} errors={errors} />
      {_renderRT()}
      <InitialDenature
        setValue={setFieldValue}
        values={values}
        errors={errors}
      />
      <CyclingParameters
        setValue={setFieldValue}
        values={values}
        errors={errors}
      />
    </Fragment>
  );
};

export default ProtocolFields;
