import React from "react";
import { connect } from "react-redux";

import { ToggleSwitch } from "bio-web-components";
import { toggleLog } from "../../../actions";
import {
  isTabularDataActive,
  isRawChartActive,
  isLogScale,
} from "../../../selectors";
import { isRunTypeMelt } from "../../../../Hub/selectors";

const LogViewToggle = props => {
  const {
    toggleLogView,
    runTypeIsMelt,
    isLog,
    rawChartIsActive,
    tabularDataIsActive,
  } = props;

  let toggleSwitch = null;

  if (
    (runTypeIsMelt && isLog) ||
    (rawChartIsActive && isLog) ||
    (tabularDataIsActive && isLog)
  ) {
    toggleLogView();
  }

  if (!rawChartIsActive && !tabularDataIsActive) {
    toggleSwitch = (
      <ToggleSwitch
        onClick={() => toggleLogView()}
        label="Log view"
        checked={isLog}
        className="toggle_slider_position"
      />
    );
  }

  return toggleSwitch;
};

const mapStateToProps = state => ({
  tabularDataIsActive: isTabularDataActive(state),
  rawChartIsActive: isRawChartActive(state),
  runTypeIsMelt: isRunTypeMelt(state),
  isLog: isLogScale(state),
});

const mapDispatchToProps = dispatch => ({
  toggleLogView: () => {
    dispatch(toggleLog());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LogViewToggle);
