import React, { useState } from "react";
import { connect } from "react-redux";

import { DetailsVersion } from "js-common";

import { canUserAccessExternalProtocolCreation } from "../../Auth/selectors";
import {
  ChannelSelectionProps,
  ProtocolProps,
  T_ArchiveProtocolMethod,
} from "../types";

import ProtocolSelector from "../Subcomponents/ProtocolSelector";
import ProtocolCreator from "../../ProtocolData/CreateProtocol";
import ProtocolModeSelection from "../Subcomponents/ProtocolModeSelection";
import ArchiveFormHeader from "../Subcomponents/ArchiveFormHeader";

interface Props {
  protocolProps: ProtocolProps;
  channelSelectionProps: ChannelSelectionProps;
  detailsVersion: DetailsVersion | "";

  setProcessing: (processing: boolean) => void;
  onBack: () => void;
}

interface StateProps {
  canCreateProtocol: boolean;
}

const ArchiveFormProtocolSelector = (props: Props & StateProps) => {
  const { canCreateProtocol, protocolProps } = props;
  const { protocolId, setProtocolId } = protocolProps;

  const [protocolMode, setProtocolMode] =
    useState<T_ArchiveProtocolMethod>("select");

  const _renderHeader = () => {
    const { onBack } = props;

    let header = "Select Protocol";
    if (protocolMode === "create") {
      header = "Create Protocol";
    }

    return <ArchiveFormHeader title={header} onBack={onBack} />;
  };

  const _renderModeSelector = () => {
    if (canCreateProtocol) {
      return (
        <ProtocolModeSelection
          protocolModeProps={{ protocolMode, setProtocolMode }}
        />
      );
    }

    return null;
  };

  const _renderProtocolContent = () => {
    const { detailsVersion } = props;

    if (protocolMode === "select") {
      return (
        <ProtocolSelector
          detailsVersion={detailsVersion}
          setProtocolId={setProtocolId}
          protocolId={protocolId}
        />
      );
    }

    return (
      <ProtocolCreator
        detailsVersion={detailsVersion}
        onSuccess={setProtocolId}
      />
    );
  };

  return (
    <div>
      {_renderHeader()}
      {_renderModeSelector()}
      {_renderProtocolContent()}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  canCreateProtocol: canUserAccessExternalProtocolCreation(state),
});

export default connect<StateProps, void, Props, any>(mapStateToProps)(
  ArchiveFormProtocolSelector
);
