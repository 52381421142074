import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";
import Auth from "@aws-amplify/auth";

import { SupportLog, User } from "js-common";

import { backendApi } from "../Utils/index";
import { version as appVersion } from "../Utils/git-version";

import * as t from "./actionTypes";
import {
  IUpdateUserDetails,
  userSubscriptionDetailsSuccess,
  ISubmitBugReportRequest,
} from "./actions";
import {
  getAwsUser,
  getUserId,
  getCurrentUserEmail,
  getIDToken,
} from "../Auth/selectors";
import { formatBugReport } from "./helpers";
import { BugIdToken, SessionInformation, SettingsUser } from "./types";
import CloudLog from "../Utils/CloudLog";

function* sendUserDetails(action: IUpdateUserDetails) {
  const { userDetails } = action;
  const { firstName, lastName, accessToken, newPassword, previousPassword } =
    userDetails;

  const session: SessionInformation = yield call([Auth, Auth.currentSession]);
  const jwt = session.idToken.jwtToken;
  backendApi.setHeader("Authorization", jwt);
  if (firstName || lastName) {
    const user = {
      firstName,
      lastName,
      accessToken,
    };

    try {
      const send: SettingsUser = yield call(backendApi.put, "/users", user);

      const { status } = send;
      if (status === 200) {
        yield put({
          type: t.UPDATE_USER_DETAILS_SUCCESS,
          updated: true,
          user: userDetails,
        });
      } else {
        const { data, problem } = send;
        const { message } = data;

        yield put({
          type: t.UPDATE_USER_DETAILS_ERROR,
          error: message || problem,
        });
      }
    } catch (error) {
      yield put({ type: t.UPDATE_USER_DETAILS_ERROR, error });
    }
  }

  if (newPassword) {
    const user = {
      newPassword,
      previousPassword,
      accessToken,
    };

    try {
      const send: SettingsUser = yield call(
        backendApi.put,
        "/users/password",
        user
      );

      const { status } = send;
      if (status === 200) {
        yield put({
          type: t.UPDATE_USER_DETAILS_SUCCESS,
          updated: true,
          user: userDetails,
        });
      } else {
        const { problem, data } = send;
        const { message } = data;

        yield put({
          type: t.UPDATE_USER_DETAILS_ERROR,
          error: message || problem,
        });
      }
    } catch (error) {
      yield put({ type: t.UPDATE_USER_DETAILS_ERROR, error });
    }
  }
}

function* watchUpdateUser() {
  yield takeEvery(t.UPDATE_USER_DETAILS_REQ, sendUserDetails);
}

function* handleUserSubscriptionDetailsReq() {
  const user: User = yield select(getAwsUser);
  const { username: id } = user;

  try {
    const { ok, data } = yield call(
      backendApi.get,
      `/users/subscriptions/${id}`
    );

    if (ok && data) {
      const { message } = data;

      yield put(userSubscriptionDetailsSuccess(message));
    }
  } catch (err) {
    CloudLog.error(`Failed to retrieve user subscription data ${err}`);
  }
}

function* watchUserSubscriptionDetailsReq() {
  yield takeLatest(
    t.USER_SUBSCRIPTION_DETAILS_REQ,
    handleUserSubscriptionDetailsReq
  );
}

function* handleBugReportSubmit(action: ISubmitBugReportRequest) {
  const { form, resolve, reject } = action;

  const idToken: BugIdToken = yield select(getIDToken);
  const userId: string = yield select(getUserId);
  const emailAddress: string = yield select(getCurrentUserEmail);
  const { given_name: firstName, family_name: lastName } = idToken;

  const bugReport: SupportLog = formatBugReport(
    form,
    userId,
    appVersion,
    firstName,
    lastName,
    emailAddress
  );
  const { versions } = bugReport;

  let success = false;
  try {
    const { ok } = yield call(
      backendApi.post,
      "/app/support?detailsVersion=biomeme-2",
      { ...bugReport, versions: JSON.stringify(versions) }
    );

    if (ok) {
      success = true;
    }
  } catch (e) {
    CloudLog.error(`Error submitting Bug Report: ${e}`);
  } finally {
    if (success) {
      resolve("Successfully submitted Bug Report!");
    } else {
      reject(
        "An error occurred and your Bug Report could not be processed. Try again?"
      );
    }
  }
}

function* watchSubmitBugReport() {
  yield takeLatest(t.SUBMIT_BUG_REPORT_REQUEST, handleBugReportSubmit);
}

export default function* rootSaga() {
  yield all([
    watchUpdateUser(),
    watchUserSubscriptionDetailsReq(),
    watchSubmitBugReport(),
  ]);
}
