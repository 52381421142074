import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { classNamesParser } from "../../../Utils/layoutUtils";
import { getActiveFilters, getShowedFilterBar } from "../../selectors";
import {
  filterBarHide as filterBarHideAction,
  filterBarShow as filterBarShowAction,
} from "../../actions";

export class DropDownSelect extends React.Component {
  getCssClass() {
    const { showDropDown } = this.props;

    return classNamesParser({
      dropDownSelect: true,
      isOpened: showDropDown,
    });
  }

  toggleFilterBar() {
    const { showDropDown, filterBarHide, filterBarShow } = this.props;

    if (showDropDown) {
      filterBarHide();
    } else {
      filterBarShow();
    }
  }

  render() {
    const { filterCount } = this.props;

    if (filterCount <= 0) {
      return null;
    }

    return (
      <Link
        to="/data"
        className={this.getCssClass()}
        data-count={filterCount}
        onClick={() => this.toggleFilterBar()}
      >
        Active Filters
      </Link>
    );
  }
}

function mapStateToProps(state) {
  return {
    showDropDown: getShowedFilterBar(state),
    filterCount: getActiveFilters(state).length,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterBarHide: () => {
      dispatch(filterBarHideAction());
    },
    filterBarShow: () => {
      dispatch(filterBarShowAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownSelect);
