const styles = {
  analysisSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "20px",
    paddingRight: "20px",
    borderRight: "1px solid #E1E1E1",
  },
  analysisModeText: { marginLeft: "0", fontWeight: 600 },
  lowerButtonSection: {
    marginBottom: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default styles;
