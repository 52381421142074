import React from "react";
import { classNamesParser } from "../../../Utils/layoutUtils";

class Tab extends React.Component {
  getCssClass() {
    const { active } = this.props;

    return classNamesParser({
      active,
    });
  }

  render() {
    const { index, name, filterCount, active } = this.props;

    return index === 0 ? (
      <span className="heading active" data-count={filterCount}>
        {name}
      </span>
    ) : (
      <a
        className={this.getCssClass()}
        style={{
          display: "flex",
          flexDirection: "row",
          cursor: active ? "" : "pointer",
          pointerEvents: active ? "none" : "all",
        }}
        data-count={filterCount}
      >
        {name}
        <sup className={`tab-count${active ? "" : " tab-count__inactive"}`}>
          {filterCount}
        </sup>
      </a>
    );
  }
}

export default Tab;
