import React from "react";
import { connect } from "react-redux";

import {
  areSingleThresholdsEnabled,
  getChartType,
  getAnalysisMode,
} from "../../../selectors";
import SingleThresholdPanel from "./SingleThresholdPanel";
import DropdownBox from "../../../../Layout/DropdownBox";
import { analysisModes } from "../../../constants";

const Metrics = ({ chartType, analysisMode }) => {
  if (
    (analysisMode[analysisModes.reprocessing] ||
      analysisMode[analysisModes.singleThreshold]) &&
    !chartType.raw
  ) {
    return (
      <DropdownBox
        heading={
          // eslint-disable-next-line
          <span data-tip="The fields below can be used to adjust channel-based single thresholds.">
            Edit Thresholds
          </span>
        }
        borderOpened
      >
        <SingleThresholdPanel />
      </DropdownBox>
    );
  }

  return null;
};

function mapStateToProps(state) {
  return {
    analysisMode: getAnalysisMode(state),
    singleThresholdsEnabled: areSingleThresholdsEnabled(state),
    chartType: getChartType(state),
  };
}

export default connect(mapStateToProps, null)(Metrics);
