import React from "react";
import { connect } from "react-redux";

import { getOpenedFolder, getSelectedFolder } from "../../../Sidebar/selectors";
import { getIDToken, getActiveTeamName } from "../../../Auth/selectors";
import { getShowChart } from "../../selectors";

export function Title(props) {
  const { openedFolder, selected, userDetails, showChart, teamName } = props;

  let title = `Welcome back, ${userDetails.given_name}.`;

  if (openedFolder) {
    title = openedFolder.name;
  } else if (selected) {
    title = selected.name;
  } else if (showChart) {
    title = teamName;
  }

  let displayFolder = (
    <img
      src="../../../../images/folder.svg"
      height="25"
      width="25"
      style={{
        marginBottom: "-1px",
        marginRight: "20px",
        marginLeft: "4px",
      }}
      alt=""
    />
  );

  if (showChart || (!openedFolder && !selected)) {
    displayFolder = null;
  }

  return (
    <h1
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "60%",
        marginRight: "10px",
        fontWeight: 600,
      }}
    >
      {displayFolder}
      {title}
    </h1>
  );
}

function mapStateToProps(state) {
  return {
    openedFolder: getOpenedFolder(state),
    selected: getSelectedFolder(state),
    userDetails: getIDToken(state),
    showChart: getShowChart(state),
    teamName: getActiveTeamName(state),
  };
}

export default connect(mapStateToProps)(Title);
