import React from "react";
import { connect } from "react-redux";
import Modal from "../../../Layout/Modal";
import { isAppUpdateModalOpen } from "../../../Sidebar/selectors";
import { hideAppUpdateModal } from "../../../Sidebar/actions";

const styles = {
  container: {
    overflowY: "hidden",
    marginBottom: "17px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 500,
  },
  columnLeft: {
    display: "flex",
    flexDirection: "column",
    order: 1,
    justifyContent: "left",
  },
  columnCenter: {
    display: "flex",
    flexDirection: "column",
    order: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
    order: 3,
    justifyContent: "right",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  header: {
    fontSize: "24px",
    color: "#012639",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
    marginTop: 50,
    marginBottom: 20,
  },
  warningContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  warningRed: {
    marginTop: "20px",
    color: "red",
    textAlign: "center",
    fontStyle: "italic",
    fontWeight: "500",
    display: "flex",
    width: 350,
  },
  snapShot: {
    width: "100%",
    height: "auto",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textContainer1: {
    alignItems: "center",
    justifyContent: "center",
    width: 260,
    marginBottom: 12,
  },
  textContainer2: {
    alignItems: "center",
    justifyContent: "center",
    width: 260,
  },
  subHeader: {
    fontSize: "18px",
    color: "#012639",
    fontWeight: "800",
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    marginTop: 30,
  },
  body: {
    fontSize: "14px",
    color: "#012639",
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    marginTop: 20,
  },
  bodyItalic: {
    fontSize: "14px",
    color: "#012639",
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    fontStyle: "italic",
  },
  leftArrow: {
    width: 10,
    marginTop: 250,
    marginLeft: 20,
  },
  leftArrowOne: {
    width: 10,
    marginTop: 250,
    marginLeft: 20,
    opacity: 0.25,
  },
  rightArrow: {
    width: 10,
    marginTop: 250,
    marginRight: 20,
  },
  rightArrowTwo: {
    width: 10,
    marginTop: 250,
    marginRight: 20,
    opacity: 0.25,
  },
  opacity: {
    opacity: 0.25,
  },
  dotContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 20,
    marginTop: 10,
  },
  dotLeft: {
    width: 10,
    marginRight: 5,
    order: 1,
  },
  dotRight: {
    width: 10,
    marginLeft: 5,
    order: 2,
  },
  qr: {
    width: 150,
    height: 150,
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export class AppUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayScreen: 1,
    };

    this.updateScreen = this.updateScreen.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.renderScreenOne = this.renderScreenOne.bind(this);
    this.renderScreenTwo = this.renderScreenTwo.bind(this);
  }

  updateScreen() {
    const { displayScreen } = this.state;

    if (displayScreen === 1) {
      this.setState({ displayScreen: 2 });
    } else {
      this.setState({ displayScreen: 1 });
    }
  }

  resetModal() {
    this.setState({ displayScreen: 1 });
  }

  renderScreenOne() {
    const { closeModal, openModal } = this.props;

    return (
      <Modal
        open={openModal}
        className="modal--run_detail"
        bodyClass="modal__analysis"
        onClose={() => {
          this.resetModal();
          closeModal();
        }}
        modalBodyInnerStyle={{ padding: 0 }}
      >
        <div style={styles.container}>
          <div style={styles.columnLeft}>
            <img
              src="../../../../images/Arrow_L.svg"
              style={styles.leftArrowOne}
              alt=""
            />
          </div>
          <div style={styles.columnCenter}>
            <h2 style={styles.header}>How to update your app</h2>
            <img
              src="../../../../images/playstore.svg"
              style={styles.qr}
              alt=""
            />
            <div style={styles.textContainer1}>
              <p style={styles.subHeader}>
                1. Scan the above QR code using the phone's camera app
              </p>
              <p style={styles.body}>
                Note: CAT S60 will not automatically detect QR codes in the
                camera. Alternatively access the update by launching the App
                Store and searching for{" "}
                <i style={styles.bodyItalic}>biomeme.</i>
              </p>
            </div>
            <div style={styles.dotContainer}>
              <img
                src="../../../../images/CircleDot_Active.svg"
                style={styles.dotLeft}
                alt=""
              />
              <img
                src="../../../../images/CircleDot_Inactive.svg"
                style={styles.dotRight}
                alt=""
              />
            </div>
          </div>
          <div style={styles.columnRight}>
            <img
              src="../../../../images/Arrow_R.svg"
              style={styles.rightArrow}
              onClick={() => this.updateScreen()}
              alt=""
            />
          </div>
        </div>
      </Modal>
    );
  }

  renderScreenTwo() {
    const { closeModal, openModal } = this.props;

    return (
      <Modal
        open={openModal}
        className="modal--run_detail"
        bodyClass="modal__analysis"
        onClose={() => {
          this.resetModal();
          closeModal();
        }}
        modalBodyInnerStyle={{ padding: 0 }}
      >
        <div style={styles.container}>
          <div style={styles.columnLeft}>
            <img
              src="../../../../images/Arrow_L.svg"
              style={styles.leftArrow}
              onClick={() => this.updateScreen()}
              alt=""
            />
          </div>
          <div style={styles.columnCenter}>
            <h2 style={styles.header}>How to update your app</h2>
            <img
              src="../../../../images/InstallScreen@2x.png"
              style={styles.snapShot}
              alt=""
            />
            <div style={styles.textContainer2}>
              <p style={styles.subHeader}>2. Select Install or Update</p>
              <p style={styles.body}>
                Wait for the download to complete and reopen the{" "}
                <i style={styles.bodyItalic}>Biomeme Go </i>
                <i style={styles.body}>app.</i>
              </p>
            </div>
            <div
              className="modal__buttons"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "10px",
              }}
            >
              <button
                className="btn-modal"
                onClick={() => {
                  this.resetModal();
                  closeModal();
                }}
              >
                Done
              </button>
            </div>
            <div style={styles.dotContainer}>
              <img
                src="../../../../images/CircleDot_Active.svg"
                style={styles.dotRight}
                alt=""
              />
              <img
                src="../../../../images/CircleDot_Inactive.svg"
                style={styles.dotLeft}
                alt=""
              />
            </div>
          </div>
          <div style={styles.columnRight}>
            <img
              src="../../../../images/Arrow_R.svg"
              style={styles.rightArrowTwo}
              alt=""
            />
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { displayScreen } = this.state;

    if (displayScreen === 1) {
      return this.renderScreenOne();
    }

    return this.renderScreenTwo();
  }
}

function mapStateToProps(state) {
  return {
    openModal: isAppUpdateModalOpen(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(hideAppUpdateModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppUpdateModal);
