import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";

import Modal from "../../../../Layout/Modal";
import { isAnalysisModalOpen } from "../../../selectors";
import { getFullRunData } from "../../../../FullRunData/selectors";
import {
  getSelectedRuns,
  isSelectedRunExternalUpload,
} from "../../../../Hub/selectors";
import {
  getActiveTeamVersion,
  canUserAccessReview,
} from "../../../../Auth/selectors";
import { closeAnalysisModal } from "../../../actions";
import { selectMultipleRuns } from "../../../../Hub/actions";
import { postFeature } from "../../../../App/actions";
import {
  tooltipDelay,
  analysisModes,
  hiddenForExternalRuns,
} from "../../../constants";
import Button from "../../../../Layout/Button";

const styles = {
  container: {
    overflowY: "auto",
    marginBottom: "17px",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px",
  },
  warning: {
    marginTop: "10px",
    marginBottom: "10px",
    color: "red",
  },
  warningContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "left",
  },
  modalButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "10px",
  },
};

class AnalysisModeSelector extends Component {
  constructor(props) {
    super(props);

    let initialSelectedMode = analysisModes.baseline;
    if (props.runIsExternalUpload) {
      initialSelectedMode = analysisModes.singleThreshold;
    }

    this.state = {
      mode: initialSelectedMode,
    };
  }

  handleModeChange = () => {
    const { closeModal, continueWithMultipleRuns, featurePost } = this.props;
    const { mode } = this.state;

    continueWithMultipleRuns(mode);

    let chartMode = null;

    switch (mode) {
      case analysisModes.baseline: {
        chartMode = "multi threshold chart";
        break;
      }
      case analysisModes.singleThreshold: {
        chartMode = "single threshold chart";
        break;
      }
      case analysisModes.reprocessing: {
        chartMode = "reprocessing chart";
        break;
      }
      case analysisModes.review: {
        chartMode = "review chart";
        break;
      }
      default:
        chartMode = null;
    }

    featurePost(chartMode);
    closeModal();
  };

  _renderAnalysisOptions = () => {
    const { runIsExternalUpload, selectedRunCount, canReview, version } =
      this.props;
    const { mode } = this.state;

    let analysisOptions = [
      {
        title: "Single Threshold",
        value: "single-threshold",
        tooltip: "This mode enables one threshold per fluorophore.",
      },
      {
        title: "Multi Threshold",
        value: "baseline",
        tooltip:
          "This mode enables one threshold for each well, in each fluorophore.",
      },
    ];

    if (version === "biomeme-2") {
      analysisOptions.push({
        title: "Reprocessing",
        value: "reprocessing",
        tooltip: "This mode enables background selection.",
      });
    }

    if (version === "biomeme-2" && canReview) {
      analysisOptions.push({
        title: "Review",
        value: "review",
        tooltip: "This mode enables run reviewing and target verification.",
        disabled: selectedRunCount.length > 1,
      });
    }

    if (runIsExternalUpload) {
      analysisOptions = analysisOptions.filter(option => {
        if (hiddenForExternalRuns.includes(option.value)) {
          return false;
        }

        return true;
      });
    }

    return analysisOptions.map(option => {
      let opacityStyle = 1;
      if (option.disabled) {
        opacityStyle = 0.5;
      }

      return (
        <div style={styles.input} data-tip={option.tooltip} key={option.value}>
          <label htmlFor={option.value}>
            <input
              type="radio"
              name="Analysis Mode"
              value={option.value}
              id={option.value}
              checked={mode === option.value}
              style={{
                marginRight: "10px",
                marginLeft: "3px",
              }}
              readOnly
              disabled={option.disabled}
            />
            <span style={{ fontWeight: 600, opacity: opacityStyle }}>
              {option.title}
            </span>
          </label>
        </div>
      );
    });
  };

  _renderWarningMessages = () => {
    const { location, canReview, selectedRunCount } = this.props;
    const { mode } = this.state;

    const hub = location.pathname.includes("/data");

    return (
      <>
        {canReview && selectedRunCount.length > 1 && (
          <div style={styles.warning}>
            Reviewing is disabled, only one run may be reviewed at a time.
          </div>
        )}
        {!hub && (
          <div style={styles.warning}>
            Switching your Analysis Mode will reload the chart, deleting any
            changes you've made.
          </div>
        )}
        {mode === "reprocessing" && (
          <div style={styles.warningContainer}>
            <div style={styles.warning}>
              Your data will be reprocessed and will not match the original
              data.
            </div>
          </div>
        )}
      </>
    );
  };

  _renderButtons = () => {
    const { closeModal, location } = this.props;
    const { mode } = this.state;

    const hub = location.pathname.includes("/data");

    let title = "Apply";
    if (hub) {
      title = "Continue to Chart View";
    }

    return (
      <div className="modal__buttons" style={styles.modalButtons}>
        <Button
          title={title}
          disabled={!mode}
          onClick={this.handleModeChange}
          buttonClass="btn-modal"
        />
        <span
          style={{ marginTop: "20px", fontWeight: "600", cursor: "pointer" }}
          onClick={() => closeModal()}
        >
          Cancel
        </span>
      </div>
    );
  };

  render() {
    const { closeModal, analysisModalIsOpen } = this.props;

    if (!analysisModalIsOpen) {
      return null;
    }

    return (
      <Modal
        open={analysisModalIsOpen}
        heading="Select Analysis Mode"
        onClose={() => closeModal()}
        className="modal--run_detail"
        style={{ width: "400px !important" }}
        bodyClass="modal__analysis"
      >
        <div style={styles.container}>
          <form
            onChange={e => this.setState({ mode: e.target.value })}
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "16px",
              marginTop: "10px",
            }}
          >
            {this._renderAnalysisOptions()}
          </form>
          {this._renderWarningMessages()}
        </div>
        {this._renderButtons()}
        <ReactTooltip delayShow={tooltipDelay} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    analysisModalIsOpen: isAnalysisModalOpen(state),
    version: getActiveTeamVersion(state),
    runData: getFullRunData(state),
    canReview: canUserAccessReview(state),
    selectedRunCount: getSelectedRuns(state),
    runIsExternalUpload: isSelectedRunExternalUpload(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeAnalysisModal());
    },
    continueWithMultipleRuns: mode => {
      dispatch(selectMultipleRuns(mode));
    },
    featurePost: feature => {
      dispatch(postFeature(feature));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisModeSelector)
);
