import { CSSProperties } from "react";

export const bulkReviewContainer: CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  borderTop: "1px solid #c9d1d5",
};

export const bulkReviewRight: CSSProperties = {
  flexBasis: "auto",
  flexGrow: 1,
  paddingTop: "10px",
  paddingLeft: "20px",
  borderLeft: "1px solid #c9d1d5",
  overflow: "hidden",
};

export const bulkReviewLeft: CSSProperties = {
  paddingLeft: "35px",
  paddingTop: "10px",
  paddingRight: "35px",
  marginTop: "-10px",
  minHeight: "480px",
  overflowY: "auto",
  overflowX: "hidden",
  height: "calc(100vh - 197px)",
  flexBasis: "425px",
};
