export const LOGO_RESET = "app/reset_logo";
export const FEATURE_POST = "FEATURE_POST";

export const SET_APP_IS_MOBILE = "app/SET_APP_IS_MOBILE";

export const HIDE_ANNOUNCEMENTS_MODAL = "app/HIDE_ANNOUNCEMENTS_MODAL";
export const SHOW_ANNOUNCEMENTS_MODAL = "app/SHOW_ANNOUNCEMENTS_MODAL";
export const CHECK_ACTIVE_ANNOUNCEMENTS = "app/CHECK_ACTIVE_ANNOUNCEMENTS";

export const SUPER_RESET = "app/SUPER_RESET";
