import React from "react";
import { connect } from "react-redux";
import { findIndex } from "lodash";

import { classNamesParser } from "../../../Utils/layoutUtils";
import { addActiveFilter, removeActiveFilter } from "../../actions";
import { getActiveFilters } from "../../selectors";

class StandardFilterList extends React.Component {
  getCssClass() {
    const { cols } = this.props;
    return classNamesParser({
      filterList: true,
      doubleCols: cols === 2,
    });
  }

  getList() {
    const { filters } = this.props;

    let activeFiltersNum = 0;

    const activeFilters = filters.map(filter => {
      if (!this.activeClassForFilter(filter)) {
        return null;
      }
      activeFiltersNum += 1;

      return (
        <li key={filter.value}>
          {filter.display}
          <span onClick={() => this.clickFilter(filter)} />
        </li>
      );
    });

    if (activeFiltersNum === 0) {
      return (
        <li className="italic">No filters applied within this section.</li>
      );
    }
    return activeFilters;
  }

  clickFilter(filter) {
    const { removeFilter, addFilter } = this.props;
    if (this.isFilterActive(filter)) {
      removeFilter(filter);
    } else {
      addFilter(filter);
    }
  }

  activeClassForFilter(filter) {
    return this.isFilterActive(filter) ? "active" : "";
  }

  isFilterActive(filter) {
    const { activeFilters } = this.props;
    const index = findIndex(
      activeFilters,
      activeFilter => activeFilter.value === filter.value
    );

    return index !== -1;
  }

  render() {
    const { name } = this.props;
    return (
      <div className={this.getCssClass()}>
        <h3>{name}</h3>
        <ul>{this.getList()}</ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeFilters: getActiveFilters(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addFilter: filter => {
      dispatch(addActiveFilter(filter));
    },
    removeFilter: filter => {
      dispatch(removeActiveFilter(filter));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardFilterList);
