import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";

import { Button, Divider } from "bio-web-components";

import { submitRunReviewRequest } from "../actions";
import {
  getPreviousReviewerIsAdmin,
  getAllTargetsConfirmed,
  getActiveSamples,
} from "../selectors";
import { getActiveTeamFlags } from "../../Auth/selectors";
import { hasPermission } from "../../Utils/helpers";
import { SampleReview } from "../types";

import ReviewSample from "../Subcomponents/ReviewSample";
import RunReviewFormHeader from "../Subcomponents/RunReviewFormHeader";

interface StateProps {
  activeSamples: Array<SampleReview>;
  previousReviewerIsAdmin: boolean;
  isReviewAdmin: boolean;
  allTargetsConfirmed: boolean;
}

interface DispatchProps {
  submitReview: (resolve: any, reject: any) => void;
}

interface Props {}

const RunReviewForm = (props: Props & StateProps & DispatchProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [processingReview, setProcessingReview] = useState(false);

  const _handleSubmitReview = debounce(async (): Promise<void> => {
    setProcessingReview(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    await new Promise<string>((resolve, reject) => {
      const { submitReview } = props;

      submitReview(resolve, reject);
    })
      .then(successString => setSuccessMessage(successString))
      .catch(errorString => setErrorMessage(errorString));

    setProcessingReview(false);
  }, 500);

  const _renderSubmitButton = (): React.ReactNode => {
    const { previousReviewerIsAdmin, isReviewAdmin, allTargetsConfirmed } =
      props;

    const adminRestricted = previousReviewerIsAdmin && !isReviewAdmin;

    if (adminRestricted || !allTargetsConfirmed) {
      let message = "Please confirm all targets.";
      if (adminRestricted) {
        message = "Review has been approved. Please contact an Admin to edit.";
      }

      return (
        <React.Fragment>
          <ReactTooltip />
          <span data-tip={message}>
            <Button title="Submit Review" onClick={() => null} disabled />
          </span>
        </React.Fragment>
      );
    }

    return (
      <Button
        title="Submit Review"
        onClick={_handleSubmitReview}
        disabled={processingReview}
        inverting
      />
    );
  };

  const _renderMessageSection = (): React.ReactNode => {
    return (
      <React.Fragment>
        {successMessage && (
          <label className="app_success">{successMessage}</label>
        )}
        {errorMessage && <label className="app_error">{errorMessage}</label>}
      </React.Fragment>
    );
  };

  const { activeSamples } = props;

  return (
    <React.Fragment>
      <Divider />
      <div className="run_review_container">
        <RunReviewFormHeader />
        {activeSamples.map(sample => (
          <ReviewSample key={sample.sampleName} sample={sample} />
        ))}
        <div className="run_review_footer">
          {_renderSubmitButton()}
          {_renderMessageSection()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activeSamples: getActiveSamples(state),
    previousReviewerIsAdmin: getPreviousReviewerIsAdmin(state),
    isReviewAdmin: hasPermission(
      getActiveTeamFlags(state),
      "accessReviewAdmin"
    ),
    allTargetsConfirmed: getAllTargetsConfirmed(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    submitReview: (resolve: any, reject: any) =>
      dispatch(submitRunReviewRequest(resolve, reject)),
  };
};

export default connect<StateProps, DispatchProps, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(RunReviewForm);
