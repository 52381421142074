import React from "react";
import { classNamesParser } from "../layoutUtils";

interface Props {
  name: string;
  className?: string;
  marginRight?: boolean;
}

class Icon extends React.Component<Props> {
  getClassNames() {
    const { marginRight, name, className } = this.props;

    return `${classNamesParser({
      ico: true,
      "ico--margin-right": marginRight,
    })} ico--${name} ${className}`;
  }

  render() {
    return <i className={this.getClassNames()} />;
  }
}

export default Icon;
