import { CSSProperties } from "react";

export const button: CSSProperties = {
  fontWeight: 400,
  fontSize: "14px",
  letterSpacing: 0,
  color: "#7c8f99",
  marginLeft: "20px",
  background: "none",
};

export const buttonActive: CSSProperties = {
  ...button,
  color: "#012639",
  fontWeight: 600,
};

export const buttonDropdownContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginRight: "20px",
  paddingRight: "20px",
};

export const bulkFilterDropDown: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minWidth: "6rem",
};

export const bulkFilterDropDownButton: CSSProperties = {
  ...button,
};

export const bulkFilterDropDownButtonActive: CSSProperties = {
  ...buttonActive,
};

export const bulkFilterButtonFlex = {
  display: "flex",
};
