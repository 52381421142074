import * as t from "./actionTypes";

export function getFolderReq() {
  return {
    type: t.GET_FOLDERS_REQ,
  };
}

export function getFolderError(recoveryRoute) {
  return {
    type: t.GET_FOLDERS_ERR,
    recoveryRoute,
  };
}

export function getFolderSuccess(payload) {
  return {
    type: t.GET_FOLDERS_SUCCESS,
    payload,
  };
}

export function reset() {
  return {
    type: t.RESET_FOLDERS,
  };
}

export function getRootFolders() {
  return {
    type: t.GET_ROOT_FOLDERS,
  };
}

export function getRootSuccess(data = []) {
  return {
    type: t.GET_ROOT_SUCCESS,
    folders: data || [],
  };
}

export function getSelectedSuccess(folderId, data) {
  return {
    type: t.GET_SELECTED_SUCCESS,
    data,
    folderId,
  };
}

export function getSelectedFolderReq(folderId) {
  return {
    type: t.GET_SELECTED_REQ,
    folderId,
  };
}

export function getPaginatedChildrenReq(id, urlList) {
  return {
    type: t.GET_PAGINATED_CHILDREN_REQ,
    id,
    urlList,
  };
}

export function getPaginatedChildrenSuccess(data) {
  return {
    type: t.GET_PAGINATED_CHILDREN_SUCCESS,
    data,
  };
}

export function openAddFolderModal() {
  return {
    type: t.SHOW_ADD_FOLDER_MODAL,
  };
}

export function closeAddFolderModal() {
  return {
    type: t.HIDE_ADD_FOLDER_MODAL,
  };
}

export function addFolder(teamId, folderId, folderName, parentFolder) {
  return {
    type: t.POST_FOLDER,
    teamId,
    folderId,
    folderName,
    parentFolder,
  };
}
