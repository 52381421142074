import React from "react";
import { connect } from "react-redux";

import { showRaw } from "../../../actions";
import { isTabularDataActive, isRawChartActive } from "../../../selectors";
import Icon from "../../../../Utils/Layout/Icon";
import { isSelectedRunExternalUpload } from "../../../../Hub/selectors";

const RawButton = props => {
  const {
    runIsExternalUpload,
    rawChartIsActive,
    tabularDataIsActive,
    showRawChart,
  } = props;

  if (runIsExternalUpload) {
    return null;
  }

  let rawButtonClassName = "btn-inline raw_mode";

  if (rawChartIsActive && !tabularDataIsActive) {
    rawButtonClassName = "btn-inline active raw_mode";
  }

  return (
    <button
      className={rawButtonClassName}
      data-tip="Populate the chart with unprocessed data. Thresholds are not displayed while in this mode."
      onClick={showRawChart}
    >
      <Icon name="raw" />
      Raw
    </button>
  );
};

const mapStateToProps = state => ({
  tabularDataIsActive: isTabularDataActive(state),
  rawChartIsActive: isRawChartActive(state),
  runIsExternalUpload: isSelectedRunExternalUpload(state),
});

const mapDispatchToProps = dispatch => ({
  showRawChart: () => {
    dispatch(showRaw());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RawButton);
