import { isEqual, uniqBy, uniqWith } from "lodash";

import * as t from "./actionTypes";

const initState = {
  fetched: false,
  data: {},
  protocols: [],
  externalUploadProtocols: [],
};

function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case t.GET_DATA_SUCCESS: {
      return { ...state, data: payload, fetched: true };
    }

    case t.GET_DATA_REQ: {
      return state;
    }

    case t.GET_DATA_ERROR: {
      return state;
    }

    case t.RESET: {
      return initState;
    }

    case t.PROTOCOLS_RECEIVED: {
      const { protocols } = action;

      return {
        ...state,
        protocols: uniqWith([...protocols, ...state.protocols], isEqual),
      };
    }

    case t.GET_EXTERNAL_UPLOAD_PROTOCOLS_SUCCESS: {
      const { protocols } = action;

      const uniqueProtocols = uniqBy(protocols, "id");

      return {
        ...state,
        externalUploadProtocols: uniqueProtocols,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
