import { all } from "redux-saga/effects";

import authSaga from "../Auth/sagas";
import folderSaga from "../Folders/sagas";
import hubSaga from "../Hub/sagas";
import runDataSaga from "../RunData/sagas";
import protocolDataSaga from "../ProtocolData/sagas";
import runsPageSaga from "../RunsPage/sagas";
import fullRunDataSaga from "../FullRunData/sagas";
import sampleHubSaga from "../SampleHub/sagas";
import settingsSaga from "../Settings/sagas";
import logoSaga from "../App/sagas";
import registrationSaga from "../Registration/sagas";
import reviewSaga from "../RunReview/sagas";
import externalUploadSaga from "../ExternalUpload/sagas";

export default function* rootSaga() {
  yield all([
    authSaga(),
    folderSaga(),
    hubSaga(),
    runDataSaga(),
    protocolDataSaga(),
    runsPageSaga(),
    sampleHubSaga(),
    settingsSaga(),
    logoSaga(),
    fullRunDataSaga(),
    registrationSaga(),
    reviewSaga(),
    externalUploadSaga(),
  ]);
}
