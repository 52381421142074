import * as t from "./actionTypes";

/** *****************************
 *  TEMPLATE SELECTION
 ****************************** */
export function setActiveSampleTemplate(id) {
  return {
    type: t.SET_ACTIVE_SAMPLE_TEMPLATE,
    id,
  };
}

export function unsetActiveSampleTemplate() {
  return {
    type: t.UNSET_ACTIVE_SAMPLE_TEMPLATE,
  };
}

export function setTemplateToEdit(id) {
  return {
    type: t.SET_TEMPLATE_TO_EDIT,
    id,
  };
}

/** *****************************
 *  INITIALIZERS
 ****************************** */
export function initializeSampleHub(path = "/sample-management") {
  return {
    type: t.INITIALIZE_SAMPLE_HUB,
    path,
  };
}

export function initializeSampleTemplates() {
  return {
    type: t.INITIALIZE_SAMPLE_TEMPLATES,
  };
}

export function initializeSampleTemplatesSuccess(templates) {
  return {
    type: t.INITIALIZE_SAMPLE_TEMPLATES_SUCCESS,
    templates,
  };
}

/** *****************************
 *  SAMPLE LAYOUTS
 ****************************** */
export function uploadTemplate(template) {
  return {
    type: t.UPLOAD_TEMPLATE,
    template,
  };
}

export function uploadTemplateSuccess(template) {
  return {
    type: t.UPLOAD_TEMPLATE_SUCCESS,
    template,
  };
}

export function editTemplate(id, template) {
  return {
    type: t.EDIT_TEMPLATE,
    id,
    template,
  };
}

export function retrieveSampleTemplates() {
  return {
    type: t.RETRIEVE_SAMPLE_TEMPLATES,
  };
}

export function deleteSampleTemplate(id) {
  return {
    type: t.DELETE_SAMPLE_TEMPLATE,
    id,
  };
}

/** *****************************
 *  SAMPLE RECORDS
 ****************************** */
export function uploadRecords(records) {
  return {
    type: t.UPLOAD_RECORDS,
    records,
  };
}

export function deleteSampleRecord(id) {
  return {
    type: t.DELETE_SAMPLE_RECORD,
    id,
  };
}

export function setActiveSampleRecord(record) {
  return {
    type: t.SET_ACTIVE_SAMPLE_RECORD,
    record,
  };
}

export function retrieveSampleRecords(path) {
  return {
    type: t.RETRIEVE_SAMPLE_RECORDS,
    path,
  };
}

export function retrieveSampleRecordsSuccess(records, path) {
  return {
    type: t.RETRIEVE_SAMPLE_RECORDS_SUCCESS,
    records,
    path,
  };
}

/** *****************************
 *  TABULAR DATA MANAGEMENT
 ****************************** */
export function setToPrint(id) {
  return {
    type: t.SET_TO_PRINT,
    id,
  };
}

export function setToDelete(id, itemType) {
  return {
    type: t.SET_TO_DELETE,
    id,
    itemType,
  };
}

export function setTableDisplay(display) {
  return {
    type: t.SET_TABLE_DISPLAY,
    display,
  };
}

export function setSortBy(field) {
  return {
    type: t.SET_SORT_BY,
    field,
  };
}

export function resetTabularData() {
  return {
    type: t.RESET_TABULAR_DATA,
  };
}

/** *****************************
 *  ERROR HANDLING
 ****************************** */
export function recordDeleteUnsuccessful(name) {
  return {
    type: t.RECORD_DELETE_UNSUCCESSFUL,
    name,
  };
}

export function recordPostUnsuccessful(name) {
  return {
    type: t.RECORD_POST_UNSUCCESSFUL,
    name,
  };
}

export function clearRecordPostErrors() {
  return {
    type: t.CLEAR_RECORD_POST_ERRORS,
  };
}

export function clearRecordDeleteErrors() {
  return {
    type: t.CLEAR_RECORD_DELETE_ERRORS,
  };
}

export function templateDeleteUnsuccessful(name) {
  return {
    type: t.TEMPLATE_DELETE_UNSUCCESSFUL,
    name,
  };
}

export function templatePostUnsuccessful(name) {
  return {
    type: t.TEMPLATE_POST_UNSUCCESSFUL,
    name,
  };
}

export function clearTemplatePostErrors() {
  return {
    type: t.CLEAR_TEMPLATE_POST_ERRORS,
  };
}

export function clearTemplateDeleteErrors() {
  return {
    type: t.CLEAR_TEMPLATE_DELETE_ERRORS,
  };
}

export function retrieveSampleTemplatesSuccess(templates) {
  return {
    type: t.RETRIEVE_SAMPLE_TEMPLATES_SUCCESS,
    templates,
  };
}

export function clearErrors() {
  return {
    type: t.CLEAR_ERRORS,
  };
}
