import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Subscription } from "js-common";

import { getUserSubscriptionDetails } from "../selectors";
import { userSubscriptionDetailsReq } from "../actions";

interface StateProps {
  subscriptionDetails: Subscription[];
}

interface DispatchProps {
  requestSubscriptionDetails: () => void;
}

interface Props extends StateProps, DispatchProps {}

const SubscriptionBanner: React.FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    const { requestSubscriptionDetails } = props;

    requestSubscriptionDetails();
  }, []);

  const { subscriptionDetails } = props;
  let banner = null;

  if (subscriptionDetails) {
    const subscriptionsByExpiration = subscriptionDetails.sort((a, b) => {
      let result = 0;
      if (a.expirationDate > b.expirationDate) {
        result = 1;
      }
      if (a.expirationDate < b.expirationDate) {
        result = -1;
      }

      return result;
    });

    const latestExpiringSubscription = subscriptionsByExpiration[0];
    const formattedSubscriptionExpiration =
      latestExpiringSubscription.expirationDate
        .split("T")[0]
        .split("-")
        .join("/");
    const newDate = new Date(formattedSubscriptionExpiration);

    const expired = newDate.getTime() < Date.now();

    let computedBackgroundColor = "";
    let computedSubscriptionMessage = "Your subscription will expire on ";
    if (expired) {
      computedBackgroundColor = "#ffcfcf";
      computedSubscriptionMessage = "Your subscription expired on ";
    }

    banner = (
      <div
        className="settings__subscription-container"
        style={{ backgroundColor: computedBackgroundColor }}
      >
        <div className="settings__subscription-header">
          <h4 className="settings__input-labels">Portal Subscription</h4>
          <a
            className="settings__input-labels settings__subscription-link"
            href="https://shop.biomeme.com/collections/devices/products/biomeme-cloud"
            target="_blank"
            rel="noopener noreferrer"
          >
            Renew
          </a>
        </div>
        <p className="settings__header-text">
          {computedSubscriptionMessage}
          {formattedSubscriptionExpiration}
        </p>
      </div>
    );
  }

  return banner;
};

function mapStateToProps(state: any) {
  return {
    subscriptionDetails: getUserSubscriptionDetails(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    requestSubscriptionDetails: () => dispatch(userSubscriptionDetailsReq()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionBanner);
