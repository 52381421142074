import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import {
  isPrintModalActive,
  isTabularDataActive,
  getPrintIndex,
  getAnalysisMode,
  isReviewModeActive,
  isReprocessingModeActive,
  getTutorialRunsBlocksOpenState,
} from "../selectors";
import { isRunTypeIsothermal, isRunTypeMelt } from "../../Hub/selectors";
import { tooltipDelay } from "../constants";
import Chart from "./chart";
import TabularData from "./ChartControls/TabularData";
import ChartControls from "./ChartControls";
import DetailedData from "./DetailedData";
import ExportModal from "./ExportModal";
import ExportReviewModal from "./ExportReviewModal";
import AnalysisModeSelector from "./ChartControls/AnalysisModeSelector";
import PrintModal from "./PrintModal";
import ErrorModal from "./ErrorModal";
import styles from "./styles";
import ReviewModeErrorModal from "./ReviewModeErrorModal";
import RunReviewForm from "../../RunReview/Components/RunReviewForm";
import CyclesDisplayedButton from "./ChartControls/CyclesDisplayedSelector/CyclesDisplayedButton";
import CyclesDisplayedSelector from "./ChartControls/CyclesDisplayedSelector";
import GraphButtons from "./ChartControls/GraphButtons";
import Tutorial from "./Tutorial";

class RunsContainer extends Component {
  renderPrintModal = () => {
    const { printModalIsActive, printIndex } = this.props;

    if (printModalIsActive) {
      return <PrintModal runId={printIndex} />;
    }

    return null;
  };

  renderReviewForm = () => {
    const { reviewAnalysisModeIsActive } = this.props;

    if (reviewAnalysisModeIsActive) {
      return <RunReviewForm />;
    }

    return null;
  };

  renderCyclesDisplayedSection = () => {
    const { analysisModeIsReprocessing, runTypeIsIsothermal, runTypeIsMelt } =
      this.props;
    if (analysisModeIsReprocessing || runTypeIsIsothermal || runTypeIsMelt) {
      return null;
    }

    return (
      <div className="graph__lower_buttons" style={styles.lowerButtonSection}>
        <CyclesDisplayedButton />
      </div>
    );
  };

  renderCyclesDisplayedSelector = () => {
    const { analysisModeIsReprocessing, runTypeIsIsothermal, runTypeIsMelt } =
      this.props;
    if (analysisModeIsReprocessing || runTypeIsIsothermal || runTypeIsMelt) {
      return null;
    }

    return <CyclesDisplayedSelector />;
  };

  renderChartContent = () => {
    const { tabularDataIsActive, analysisMode } = this.props;

    let chartContent = (
      <>
        <ReactTooltip delayShow={tooltipDelay} html />
        <Chart
          onRef={ref => {
            this.child = ref;
          }}
          analysisMode={analysisMode}
        />
        {this.renderCyclesDisplayedSection()}
        {this.renderReviewForm()}
      </>
    );

    if (tabularDataIsActive) {
      chartContent = <TabularData />;
    }

    return <div className="runs_page__graph_overlay">{chartContent}</div>;
  };

  render() {
    return (
      <div className="runs_page">
        <DetailedData />
        <ExportModal />
        <ExportReviewModal />
        <ErrorModal />
        <ReviewModeErrorModal />
        <AnalysisModeSelector />
        {this.renderCyclesDisplayedSelector()}
        {this.renderPrintModal()}
        <div className="runs_page__container">
          <div className="runs_page__left" id="runs_page__left">
            <ChartControls />
          </div>
          <div className="runs_page__right">
            <GraphButtons />
            {this.renderChartContent()}
            <Tutorial />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    printModalIsActive: isPrintModalActive(state),
    tabularDataIsActive: isTabularDataActive(state),
    analysisMode: getAnalysisMode(state),
    printIndex: getPrintIndex(state),
    runTypeIsMelt: isRunTypeMelt(state),
    runTypeIsIsothermal: isRunTypeIsothermal(state),
    reviewAnalysisModeIsActive: isReviewModeActive(state),
    analysisModeIsReprocessing: isReprocessingModeActive(state),
    tutorialRunBlockOpenState: getTutorialRunsBlocksOpenState(state),
  };
}

export default connect(mapStateToProps, null)(RunsContainer);
