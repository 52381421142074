import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, debounce } from "lodash";
import { Dispatch } from "redux";

import { generateUuid, protocolCreationHelpers } from "js-common/lib/helpers";
import { Button, Divider } from "bio-web-components";

import { ProtocolValidationErrors, ProtocolValues } from "../types";
import {
  trimInitialProtocolValues,
  trimFinalProtocolValues,
  validateProtocolValues,
} from "./helpers";
import { createProtocol } from "../actions";

import ProtocolFields from "./ProtocolFields";
import Loader from "../../Layout/Loader";

interface Props {
  detailsVersion: string;
  onSuccess?: (protocolId: string) => void;
}

interface DispatchProps {
  protocolCreate: (
    detailsVersion: string,
    values: any,
    resolve: any,
    reject: any
  ) => void;
}

const initialErrors: ProtocolValidationErrors = {
  name: "Name is required",
};

const ProtocolCreator = (props: Props & DispatchProps) => {
  const CREATE_DELAY = 250;

  const [errors, setErrors] = useState<ProtocolValidationErrors>(initialErrors);
  const [createSuccessMessage, setCreateSuccessMessage] = useState<
    string | null
  >(null);
  const [createErrorMessage, setCreateErrorMessage] = useState<string | null>(
    null
  );
  const [values, setValues] = useState<ProtocolValues>({
    ...trimInitialProtocolValues(),
  });
  const [colorMask, setColorMask] = useState(7);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const validationErrors = validateProtocolValues(values, colorMask);
    setErrors(validationErrors);
  }, [values, colorMask]);

  const setFieldValue = (field: string, value: number | string) => {
    const newValues = { ...values, [field]: value };

    setValues(newValues);
  };

  const _handleSuccess = (protocolId: string) => {
    const { onSuccess } = props;
    if (onSuccess) {
      onSuccess(protocolId);
    }
  };

  const handleSubmit = debounce(async () => {
    const { formatValuesToProtocol } = protocolCreationHelpers;
    const { detailsVersion, protocolCreate } = props;

    setCreateErrorMessage(null);
    setCreateSuccessMessage(null);
    setProcessing(true);

    const protocolId = generateUuid();

    let success = true;

    await new Promise<string>((resolve, reject) => {
      const formattedValues = formatValuesToProtocol(values);

      const finalValues = {
        ...trimFinalProtocolValues(formattedValues),
        colorMask,
        id: protocolId,
      };

      protocolCreate(detailsVersion, finalValues, resolve, reject);
    })
      .then(successMessage => setCreateSuccessMessage(successMessage))
      .catch(errorMessage => {
        setCreateErrorMessage(errorMessage);
        success = false;
      });

    if (success) {
      _handleSuccess(protocolId);
    }

    setProcessing(false);
  }, CREATE_DELAY);

  const _renderSubmitSection = () => {
    const disabled = !isEmpty(errors) || processing;

    return (
      <div className="protocol_creation-submit">
        <Button
          inverting
          title="Create Protocol"
          onClick={handleSubmit}
          disabled={disabled}
        />
      </div>
    );
  };

  const _renderMessageSection = () => {
    if (createErrorMessage || createSuccessMessage) {
      return (
        <div className="protocol_creation-messages">
          <p className="app_error centeredContainer">{createErrorMessage}</p>
          <p className="app_success centeredContainer">
            {createSuccessMessage}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="protocol_creation-inputs">
        <ProtocolFields
          protocolCreationProps={{ setFieldValue, values, errors }}
          setColorMask={setColorMask}
        />
        <Divider />
        {_renderSubmitSection()}
        {_renderMessageSection()}
        <Loader show={processing} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    protocolCreate: (
      detailsVersion: string,
      values: any,
      resolve: any,
      reject: any
    ) => {
      dispatch(createProtocol(detailsVersion, values, resolve, reject));
    },
  };
};

export default connect<void, DispatchProps, Props, any>(
  undefined,
  mapDispatchToProps
)(ProtocolCreator);
