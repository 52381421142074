import React from "react";

const DuplicateIconButton = props => {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" {...props}>
      <title>Duplicate Icon</title>
      <path
        d="M1.882 16H1V5.412h4.412V1H16v10.588h-4.412V16H1.882zm0-.882h8.824V6.294H1.882v8.824zM15.118 1.882H6.294v3.53h5.294v5.294h3.53V1.882z"
        stroke="#012639"
        strokeWidth={0.5}
        fill="#012639"
        fillRule="evenodd"
        className="sample-hub__invertable-button"
      />
    </svg>
  );
};

export default DuplicateIconButton;
