import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Table,
  TableRow,
  Input,
  MagnifyingGlass,
  TableCell,
} from "bio-web-components";

import * as actions from "../actions";
import { handleSearch } from "../../Hub/actions";
import { getSidebarDepth, getOpenedFolder } from "../selectors";
import {
  getAvailibleFolders,
  getFoldersLoading,
} from "../../Folders/selectors";
import { getSearchQuery } from "../../Hub/selectors";

export class ProjectFolders extends Component {
  selectOption = f => {
    const { selectFolder, history } = this.props;
    selectFolder(f);
    history.push("/data");
  };

  render() {
    const { list, loading, searchQuery, setQuery, depth } = this.props;

    let filteredList = [...list];
    let message = null;

    if (searchQuery) {
      filteredList = filteredList.filter(folder =>
        folder.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    const projectList = filteredList
      .sort((a, b) => {
        let value = 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          value = -1;
        }

        return value;
      })
      .map((f, i) => (
        <TableRow
          className="folder_row folders_row"
          key={f.id}
          darkened={i % 2 === 0}
          onClick={() => this.selectOption(f)}
        >
          <TableCell style={{ display: "flex", padding: "0px" }}>
            <img
              src="../../../images/folder.svg"
              height="20"
              width="13"
              style={{ marginBottom: "-4px" }}
              alt=""
            />
            <div style={{ marginLeft: "10px" }}>{f.name}</div>
          </TableCell>
        </TableRow>
      ));

    if (loading) {
      message = <p key="LoadingMessage">Please wait...</p>;
    } else if (!loading && projectList.length < 1) {
      message = (
        <p
          style={{ fontFamily: "Source Sans Pro", fontStyle: "italic" }}
          key="NoFoldersMessage"
        >
          No subfolders available.
        </p>
      );
    }

    let tableContainerClass = "tableContainer";
    let innerStyle = {};

    if (depth > 0) {
      tableContainerClass = "tableContainer centered";
      innerStyle = { marginLeft: "46px" };
    }

    return (
      <div
        className={tableContainerClass}
        style={{ marginTop: "8px", marginBottom: "20px", width: "976px" }}
      >
        <div className="inner" style={innerStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p className="heading">Folders</p>
            <Input
              placeholder="Search"
              onChange={setQuery}
              value={searchQuery}
              iconLeft={<MagnifyingGlass />}
            />
          </div>
          {message || (
            <Table className="folder_table" headers={[]}>
              {projectList}
            </Table>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    list: getAvailibleFolders(state),
    openedFolder: getOpenedFolder(state),
    loading: getFoldersLoading(state),
    searchQuery: getSearchQuery(state),
    depth: getSidebarDepth(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectFolder: folder => dispatch(actions.selectFolder(folder)),
    setQuery: query => dispatch(handleSearch(query)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectFolders)
);
