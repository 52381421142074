import React from "react";

const DeleteIconButton = props => (
  <svg width={14} height={17} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-572-331h800V71h-800z" />
      <g transform="translate(-52 -12)">
        <rect
          stroke="#012639"
          strokeWidth={2}
          x={1}
          y={1}
          width={178}
          height={38}
          rx={10}
        />
        <text
          fontFamily="RobotoMedium, Roboto"
          fontSize={14}
          fontWeight={500}
          fill="#012639"
        >
          <tspan x={77.993} y={19}>
            "Delete"
          </tspan>
        </text>
      </g>
      <g className="sample-hub__invertable-button" fillRule="nonzero">
        <path d="M12.264 2.002H9.996V.527A.527.527 0 009.461 0c-.029 0-.048.01-.058.02A.073.073 0 009.365 0H3.96a.519.519 0 00-.526.527v1.475H1.158C.507 2.002 0 2.509 0 3.16v1.896h1.004v10.794c0 .651.498 1.149 1.148 1.149h9.117c.65 0 1.157-.498 1.157-1.15V5.058h.995V3.161c0-.652-.507-1.16-1.157-1.16zm-7.778-.949h4.449v.949H4.486v-.949zm6.878 14.798c0 .067-.028.095-.095.095H2.152c-.067 0-.095-.028-.095-.095V5.057h9.307v10.794zm.995-11.848H1.062v-.842c0-.067.029-.106.095-.106h11.107c.066 0 .095.039.095.106v.842z" />
        <path d="M8.947 6.263h1v8.947h-1zM6.263 6.263h1v8.947h-1zM3.579 6.263h1v8.947h-1z" />
      </g>
    </g>
  </svg>
);

export default DeleteIconButton;
