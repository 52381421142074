import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { updateBulkReviewFilter } from "../../../../RunReview/actions";
import { getBulkReviewFilter } from "../../../../RunReview/selectors";

import { BulkReviewFilter, BulkReviewFilterCategory } from "../../../types";
import {
  BULK_REVIEW_FILTER_OPTIONS,
  CONTROLS_FILTER_OPTIONS,
  SAMPLES_FILTER_OPTIONS,
} from "./constants";
import { determineButtonStyle, determineDropDownButtonStyle } from "./helpers";
import * as styles from "./styles";

interface DispatchProps {
  updateFilter: (filter: BulkReviewFilter) => void;
}

interface StateProps {
  currentFilter: BulkReviewFilter;
}

type Props = StateProps & DispatchProps;

export const BulkFilter: React.FunctionComponent<Props> = (props: Props) => {
  const { updateFilter, currentFilter } = props;

  const [activeDropdown, setActiveDropdown] =
    useState<BulkReviewFilterCategory>("all");
  const [previousDropdown, setPreviousDropdown] =
    useState<BulkReviewFilterCategory | null>(null);

  const handleFilterDropdownEvent = (
    e: React.MouseEvent<HTMLElement>,
    filterCategory: BulkReviewFilterCategory
  ) => {
    e.preventDefault();

    const isAllDoubleClick =
      filterCategory === "all" && previousDropdown === "all";

    if (isAllDoubleClick) {
      setPreviousDropdown(null);
      setActiveDropdown(filterCategory);
    } else {
      const isDoubleClick = activeDropdown === previousDropdown;

      if (isDoubleClick) {
        setPreviousDropdown(filterCategory);
        setActiveDropdown("all");
      } else {
        setPreviousDropdown(activeDropdown);
        setActiveDropdown(filterCategory);
      }
    }
  };

  const handleFilterClick = (
    e: React.MouseEvent<HTMLElement>,
    option: BulkReviewFilter
  ) => {
    e.preventDefault();

    updateFilter(option);
  };

  const renderAllFilter = () => {
    return (
      <div style={styles.buttonDropdownContainer}>
        <button
          onClick={e => {
            handleFilterDropdownEvent(e, "all");
            handleFilterClick(e, "all");
          }}
          aria-label="All Filter"
          style={determineButtonStyle(["all"], currentFilter)}
        >
          All
        </button>
      </div>
    );
  };

  const renderDropdown = (dropdownOptions: BulkReviewFilter[]) => {
    return dropdownOptions.map((option: BulkReviewFilter) => {
      return (
        <button
          aria-label={BULK_REVIEW_FILTER_OPTIONS[option]}
          onClick={e => {
            return handleFilterClick(e, option);
          }}
          style={determineDropDownButtonStyle(option, currentFilter)}
          key={option}
        >
          {BULK_REVIEW_FILTER_OPTIONS[option]}
        </button>
      );
    });
  };

  const renderSamplesFilter = () => {
    let dropDown = null;
    if (
      activeDropdown === "samples" ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (SAMPLES_FILTER_OPTIONS as any).includes(currentFilter)
    ) {
      dropDown = renderDropdown(SAMPLES_FILTER_OPTIONS);
    }

    return (
      <div style={styles.buttonDropdownContainer}>
        <button
          onClick={e => {
            return handleFilterDropdownEvent(e, "samples");
          }}
          aria-label="Samples Filter Dropdown Trigger"
          style={{
            ...determineButtonStyle(SAMPLES_FILTER_OPTIONS, currentFilter),
            ...styles.bulkFilterButtonFlex,
          }}
        >
          Samples
          <div style={styles.bulkFilterDropDown}>{dropDown}</div>
        </button>
      </div>
    );
  };

  const renderControlsFilter = () => {
    let dropDown = null;
    if (
      activeDropdown === "controls" ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (CONTROLS_FILTER_OPTIONS as any).includes(currentFilter)
    ) {
      dropDown = renderDropdown(CONTROLS_FILTER_OPTIONS);
    }

    return (
      <div style={styles.buttonDropdownContainer}>
        <button
          onClick={e => {
            return handleFilterDropdownEvent(e, "controls");
          }}
          aria-label="Controls Filter Dropdown Trigger"
          style={{
            ...determineButtonStyle(CONTROLS_FILTER_OPTIONS, currentFilter),
            ...styles.bulkFilterButtonFlex,
          }}
        >
          Controls
          <div style={styles.bulkFilterDropDown}>{dropDown}</div>
        </button>
      </div>
    );
  };

  return (
    <>
      {renderAllFilter()}
      {renderSamplesFilter()}
      {renderControlsFilter()}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    currentFilter: getBulkReviewFilter(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateFilter: (filter: BulkReviewFilter) => {
      dispatch(updateBulkReviewFilter(filter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkFilter);
