import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSvgAsPng } from "save-svg-as-png";
import ReactTooltip from "react-tooltip";

import { exportQALog as exportQALogAction } from "../../../RunReview/actions";
import { getReviewLogLength } from "../../../RunReview/selectors";
import {
  openExportModal as openExportModalAction,
  openDetailedModal as openDetailedModalAction,
  toggleExportReviewModal as toggleExportReviewModalAction,
} from "../../../RunsPage/actions";
import {
  isTabularDataActive,
  getAnalysisMode,
} from "../../../RunsPage/selectors";
import { tooltipDelay } from "../../../RunsPage/constants";
import DetailedDataIcon from "./DetailedDataIcon";
import Icon from "../../../Utils/Layout/Icon";
import Button from "../../../Layout/Button";
import { isSelectedRunExternalUpload } from "../../selectors";

class exportComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleClick);
  }

  _openDownloadMenu = () => {
    this.setState({ toggled: true });
    document.addEventListener("mousedown", this._handleClick);
  };

  _closeDownloadMenu = () => {
    this.setState({ toggled: false });
    document.removeEventListener("mousedown", this._handleClick);
  };

  _handleClick = e => {
    const { openExportModal, toggleExportReviewModal } = this.props;
    const { className } = e.target;

    if (className === "download_export") {
      openExportModal();
      this.setState({ toggled: false });
    } else if (className === "download_chart") {
      saveSvgAsPng(document.getElementById("chart-svg"), "chart.png", {
        backgroundColor: "white",
        canvag: true,
        width: document.getElementById("chart-svg").width.baseVal.value + 120,
        top: -5,
        left: -60,
        fonts: [
          {
            url: "https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmhduz8A.woff2",
            format: "woff2",
            text: `@font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 300;
          src:url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmhduz8A.woff2) format('woff2');
        }`,
          },
        ],
      });
      this.setState({ toggled: false });
    } else if (className === "download_review") {
      toggleExportReviewModal();
      this.setState({ toggled: false });
    } else if (className !== "download_menu") {
      this._closeDownloadMenu();
    }
  };

  _renderDownloadDataButton = () => {
    const { runIsExternalUpload } = this.props;

    if (runIsExternalUpload) {
      return null;
    }

    return (
      <div
        className="download_export"
        data-tip="Download an Excel sheet that contains your data."
        id="download-name"
      >
        Download Data
      </div>
    );
  };

  _renderDownloadChartButton = () => {
    const { tabularDataIsActive } = this.props;

    if (tabularDataIsActive) {
      return null;
    }

    return (
      <div
        className="download_chart"
        data-tip="Download a .png file of your chart as it currently appears."
        style={{ whiteSpace: "nowrap" }}
      >
        Download Chart
      </div>
    );
  };

  _renderDownloadReviewButton = () => {
    const { analysisMode, reviewLogLength } = this.props;
    const isReviewMode = analysisMode.review;

    if (!isReviewMode || reviewLogLength < 1) {
      return null;
    }

    return (
      <div
        className="download_review"
        data-tip="Download an Excel sheet that contains your review data."
        id="download-name"
      >
        Download Review
      </div>
    );
  };

  _renderDownloadDropdown = () => {
    const { toggled } = this.state;

    const wrappedDownloadIcon = () => {
      return <Icon name="export" marginRight />;
    };

    const downloadDataButton = this._renderDownloadDataButton();
    const downloadChartButton = this._renderDownloadChartButton();
    const downloadReviewButton = this._renderDownloadReviewButton();

    if (
      downloadDataButton === null &&
      downloadChartButton === null &&
      downloadReviewButton === null
    ) {
      return null;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          onClick={this._openDownloadMenu}
          data-tip="Click here to reveal a menu of various download and export options."
          icon={wrappedDownloadIcon}
          title="Download"
          buttonClass="download_button"
        />
        {toggled && (
          <div>
            <div className="download_menu" onBlur={this._closeDownloadMenu}>
              <ReactTooltip delayShow={tooltipDelay} />
              {downloadDataButton}
              {downloadChartButton}
              {downloadReviewButton}
            </div>
          </div>
        )}
      </div>
    );
  };

  _renderButtons = () => {
    const { openDetailedModal, analysisMode, reviewLogLength, exportQALog } =
      this.props;
    const isReviewMode = analysisMode.review;

    const wrappedDetailedDataIcon = () => {
      return (
        <DetailedDataIcon className="marginRight" width={15} height={15} />
      );
    };
    const wrappedDownloadIcon = () => {
      return <Icon name="export" marginRight />;
    };

    return (
      <>
        <Button
          onClick={() => {
            return openDetailedModal();
          }}
          icon={wrappedDetailedDataIcon}
          title="Detailed Data"
          buttonClass="detailed_data_button"
        />{" "}
        {isReviewMode && (
          <>
            <span data-tip="Generates a QA log for all reviews associated with this run">
              <Button
                onClick={exportQALog}
                icon={wrappedDownloadIcon}
                title="Export Run QA Log"
                disabled={reviewLogLength < 1}
              />
            </span>
            <ReactTooltip delayShow={tooltipDelay / 2} />
          </>
        )}
      </>
    );
  };

  render() {
    return (
      <div className="button-menu">
        {this._renderButtons()}
        {this._renderDownloadDropdown()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tabularDataIsActive: isTabularDataActive(state),
    analysisMode: getAnalysisMode(state),
    reviewLogLength: getReviewLogLength(state),
    runIsExternalUpload: isSelectedRunExternalUpload(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openExportModal: () => {
      dispatch(openExportModalAction());
    },
    toggleExportReviewModal: () => {
      dispatch(toggleExportReviewModalAction());
    },
    openDetailedModal: () => {
      dispatch(openDetailedModalAction());
    },
    exportQALog: () => {
      dispatch(exportQALogAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(exportComponent);
