import * as t from "./actionTypes";

const initState = {
  isFetching: false,
  loggedIn: false,
  error: "",
  organization: {},
  activeTeamId: "",
  activeTeamName: "",
  activeTeamVersion: "",
  activeTeamFlags: {},
  teams: [],
  token: "",
  user: null,
  tokenTime: 0,
  resetPasswordError: "",
  resetPasswordSuccess: false,
  provider: "",
  samlProcessing: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    // LOGIN SECTION
    case t.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.user,
        error: "",
        loggedIn: true,
        isFetching: false,
      };
    }
    case t.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }
    case t.SET_TOKEN_TIME: {
      return {
        ...state,
        tokenTime: action.time,
      };
    }
    case t.USER_LOGIN_ERR: {
      return {
        ...initState,
        error: action.payload,
      };
    }
    case t.USER_LOGIN_REQ: {
      return state;
    }

    // REGISTER SECTION
    case t.USER_REGISTER_REQ: {
      return { ...state, userInfo: action.userInfo };
    }
    case t.USER_REGISTER_SUCCESS: {
      return { ...state };
    }
    case t.USER_REGISTER_ERROR: {
      return { ...state, error: action.error, loggedIn: false, userInfo: null };
    }

    // LOGOUT SECTION
    case t.USER_LOGOUT_REQ: {
      return { ...state, isFetching: true, error: "" };
    }
    case t.USER_LOGOUT_ERROR: {
      return { ...state, isFetching: false, error: action.error };
    }
    case t.USER_LOGOUT_SUCCESS: {
      return {
        ...initState,
        tokenTime: action.tokenTime,
      };
    }
    case t.SET_ORG: {
      return { ...state, organization: action.payload };
    }

    case t.SET_ACTIVE_TEAM_ID: {
      const mostRecentlySelectedTeamDetails = {
        id: action.teamId,
        name: action.name,
        type: action.version,
        flags: action.flags,
      };

      localStorage.setItem(
        "selectedTeam",
        JSON.stringify(mostRecentlySelectedTeamDetails)
      );

      return {
        ...state,
        activeTeamId: action.teamId,
        activeTeamName: action.name,
        activeTeamVersion: action.version,
        activeTeamFlags: action.flags,
      };
    }
    case t.SET_TEAMS: {
      return { ...state, teams: action.teams };
    }
    case t.SET_PROVIDER: {
      return { ...state, provider: action.provider };
    }
    case t.SET_SAML_PROCESSING: {
      return { ...state, samlProcessing: action.isProcessing };
    }
    case t.REQUEST_VERIFICATION_CODE_SUCCESS: {
      return { ...state, resetPasswordError: "" };
    }
    case t.REQUEST_VERIFICATION_CODE_ERROR: {
      return { ...state, resetPasswordError: action.err || action };
    }
    case t.RESET_PASSWORD_SUCCESS: {
      return { ...state, resetPasswordError: "", resetPasswordSuccess: true };
    }
    case t.RESET_PASSWORD_ERROR: {
      return { ...state, resetPasswordError: action.err || action };
    }

    // UPDATE USER SECTION
    case t.USER_REFRESH_SESSION_SUCCESS: {
      return {
        ...state,
        user: action.user,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
