import React from "react";
import { connect } from "react-redux";

import { getCurrentAnalysisMode } from "../../../selectors";
import styles from "../../styles";
import AnalysisModeButton from "../AnalysisModeButton";

const AnalysisModeSection = props => {
  const { currentAnalysisMode } = props;

  return (
    <div className="analysis_section" style={styles.analysisSection}>
      <p
        className="analysis_text analysis_mode"
        data-tip="Use the selector arrow to the right to change your analysis mode."
        style={styles.analysisModeText}
      >
        Analysis Mode:
      </p>
      <p className="analysis_text current_analysis_mode">
        {currentAnalysisMode}
      </p>
      <AnalysisModeButton />
    </div>
  );
};

const mapStateToProps = state => ({
  currentAnalysisMode: getCurrentAnalysisMode(state),
});

export default connect(mapStateToProps, null)(AnalysisModeSection);
