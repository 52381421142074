import React from "react";

import * as styles from "./styles";
import BulkFilter from "../BulkFilter";

const BulkFilterSection: React.FunctionComponent = () => {
  return (
    <div style={styles.bulkFilterSection}>
      <p
        data-tip="Use the options to the right to filter down the table"
        style={styles.filterByText}
      >
        Filter By:
      </p>
      <BulkFilter />
    </div>
  );
};

export default BulkFilterSection;
