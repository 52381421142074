import React from "react";

import { UploadIcon } from "bio-web-components";

interface Props {
  title: string;

  onBack?: () => void;
}

const ArchiveFormHeader = (props: Props) => {
  const { title } = props;

  const _renderBackButton = () => {
    const { onBack } = props;

    if (onBack) {
      return (
        <div className="archive_form-back-btn" onClick={onBack}>
          {"<"}
        </div>
      );
    }
  };

  return (
    <div className="archive_form-header">
      <UploadIcon />
      <div className="archive_form-title-container">
        {_renderBackButton()}
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default ArchiveFormHeader;
