import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Tooltip from "react-tooltip";

import { Button, Error } from "bio-web-components";

import {
  exportAllQALog,
  initializeReviewDashboardRequest as initializeReviewDashboardRequestAction,
  toggleBulkReviewModal as toggleBulkReviewModalAction,
} from "../actions";
import { getReviewDashboardIsLoading } from "../selectors";

import { updateAnalysisMode } from "../../RunsPage/actions";
import { analysisModes, tooltipDelay } from "../../RunsPage/constants";

import PendingRunTable from "../Subcomponents/PendingRunTable";
import Sidebar from "../../Sidebar";
import Content from "../../App/components/content";
import Loader from "../../Layout/Loader";
import Icon from "../../Utils/Layout/Icon";
import Modal from "../../Layout/Modal";
import { isUserReviewAdmin } from "../../Auth/selectors";
import BulkReviewModal from "../Subcomponents/BulkReviewModal";

interface StateProps {
  loading: boolean;
  isAdmin: boolean;
}

interface DispatchProps {
  setReviewMode: () => void;
  exportAll: (resolve: any, reject: any) => void;
  initializeReviewDashboardRequest: () => void;
  openBulkReviewSelectModal: () => void;
}

type Props = StateProps & DispatchProps;

export const ReviewDashboard: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [showExportErrorModal, setShowExportErrorModal] =
    useState<boolean>(false);
  const [exportErrorModalMessage, setExportErrorModalMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    const { setReviewMode, initializeReviewDashboardRequest } = props;

    initializeReviewDashboardRequest();
    setReviewMode();
  }, []);

  const _handleExportAll = async () => {
    await new Promise((resolve, reject) => {
      const { exportAll } = props;

      exportAll(resolve, reject);
    }).catch((error: string) => {
      setExportErrorModalMessage(error);
      setShowExportErrorModal(true);
    });
  };

  const _handleBulkReview = () => {
    const { openBulkReviewSelectModal } = props;
    openBulkReviewSelectModal();
  };

  const _renderBulkReviewButton = () => {
    const { isAdmin } = props;

    let bulkReviewElement = null;
    if (isAdmin) {
      bulkReviewElement = (
        <span
          className="sample-hub_button-data-tip-span"
          data-tip="Bulk review runs for a specific date"
        >
          <Button title="Bulk Review" onClick={_handleBulkReview} inverting />
        </span>
      );
    }

    return bulkReviewElement;
  };

  const _renderHeader = () => {
    return (
      <div className="flex__space-between">
        <div>
          <h2 style={{ fontSize: "36px" }}>Review Dashboard</h2>
          <p className="sample-hub__header-text">
            View and process your runs pending review, pending approval and
            those recently reviewed.
          </p>
        </div>
        <div className="sample-hub_dashboard-buttons">
          <span
            className="sample-hub_button-data-tip-span"
            data-tip="Generates a QA log for all reviews associated with this team"
          >
            <Button
              title="Export Team QA Log"
              icon={<Icon name="export" />}
              onClick={_handleExportAll}
              inverting
            />
          </span>
          {_renderBulkReviewButton()}
          <Tooltip delayShow={tooltipDelay / 2} />
        </div>
      </div>
    );
  };

  const _renderExportErrorModal = () => {
    return (
      <Modal
        heading="Failed to Export QA Log"
        open={showExportErrorModal}
        onClose={() => {
          return setShowExportErrorModal(false);
        }}
      >
        <div className="flex__absolute-center review-dashboard__export-error_container">
          <Error width={125} height={125} />
          <h3 className="review-dashboard__export-error">
            {exportErrorModalMessage}
          </h3>
        </div>
      </Modal>
    );
  };

  const _renderContent = () => {
    const { loading } = props;

    if (loading) {
      return <div className="foreground"><Loader show /></div>;
    }

    return (
      <div className="page">
        <div className="page__container">
          {_renderHeader()}
          <PendingRunTable />
        </div>
        <BulkReviewModal />
        {_renderExportErrorModal()}
      </div>
    );
  };

  return (
    <div className="fluidContainers">
      <Sidebar />
      <Content>{_renderContent()}</Content>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: getReviewDashboardIsLoading(state),
    isAdmin: isUserReviewAdmin(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setReviewMode: () => {
      return dispatch(updateAnalysisMode(analysisModes.review));
    },
    exportAll: (resolve: any, reject: any) => {
      return dispatch(exportAllQALog(resolve, reject));
    },
    initializeReviewDashboardRequest: () => {
      return dispatch(initializeReviewDashboardRequestAction());
    },
    openBulkReviewSelectModal: () => {
      return dispatch(toggleBulkReviewModalAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDashboard);
