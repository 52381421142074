import React from "react";
import { withRouter } from "react-router";

const TeamMenu = ({ teams, teamSelector, activeTeam, history }) => {
  const teamList = teams
    .sort((a, b) => {
      let value = 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        value = -1;
      }

      return value;
    })
    .map(t => {
      let teamMenu = (
        <div
          key={t.id}
          className="team-menu-rows"
          onClick={() => {
            teamSelector(t);
            history.push("/data");
          }}
          id="team-name"
        >
          {t.name}
        </div>
      );

      if (t.id === activeTeam) {
        teamMenu = (
          <div
            key={t.id}
            className="team-menu-rows"
            onClick={() => teamSelector(t)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="team-name">{t.name}</div>
              <div
                style={{
                  color: "#2AD2C9",
                  marginRight: "10px",
                }}
              >
                &#x2714;
              </div>
            </div>
          </div>
        );
      }

      return teamMenu;
    });

  return (
    <div className="team-menu">
      <p>SELECT TEAM</p>
      {teamList}
    </div>
  );
};

export default withRouter(TeamMenu);
