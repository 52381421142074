import { FormattedProtocolInfo } from "js-common";
import { protocolCreationHelpers } from "js-common/lib/helpers";
import { protocolCreationConstants } from "js-common/lib/constants";

import { ProtocolValidationErrors, ProtocolValues } from "../types";

export const validateProtocolValues = (
  protocol: ProtocolValues,
  colorMask: number
): ProtocolValidationErrors => {
  const { validateProtocol } = protocolCreationHelpers;
  let errors: ProtocolValidationErrors = validateProtocol(protocol);

  if (colorMask < 1) {
    errors = {
      ...errors,
      colorMask: "At leat one channel must be active",
    };
  }

  return errors;
};

export const trimInitialProtocolValues = (): ProtocolValues => {
  const { INITIAL_PROTOCOL_VALUES } = protocolCreationConstants;

  const {
    firstDenatureTemp,
    firstDenatureTime,
    denatureTemp,
    denatureTime,
    annealTemp,
    annealTime,
    rtTemp,
    rtTime,
    extensionTemp,
    extensionTime,
    cycles,
    name,
    greenActive,
    amberActive,
    redActive,
    greenTargetName,
    amberTargetName,
    redTargetName,
  } = INITIAL_PROTOCOL_VALUES;

  return {
    firstDenatureTemp,
    firstDenatureTime,
    denatureTemp,
    denatureTime,
    annealTemp,
    annealTime,
    rtTemp,
    rtTime,
    extensionTemp,
    extensionTime,
    cycles,
    name,
    greenActive,
    amberActive,
    redActive,
    greenTargetName,
    amberTargetName,
    redTargetName,
  };
};

export const trimFinalProtocolValues = (
  protocolData: FormattedProtocolInfo
) => {
  const { ONE_SECOND_MS } = protocolCreationConstants;

  const {
    firstDenatureTemp,
    firstDenatureTime,
    denatureTemp,
    denatureTime,
    annealTemp,
    annealTime,
    rtTemp,
    rtTime,
    extensionTemp,
    extensionTime,
    cycles,
    name,
  } = protocolData;

  return {
    firstDenatureTemp,
    firstDenatureTime: firstDenatureTime * ONE_SECOND_MS,
    denature: denatureTemp,
    denatureTime: denatureTime * ONE_SECOND_MS,
    anneal: annealTemp,
    annealTime: annealTime * ONE_SECOND_MS,
    rtTemp,
    rtTime: rtTime * ONE_SECOND_MS,
    extensionTemp,
    extensionTime: extensionTime * ONE_SECOND_MS,
    cycles,
    name,
  };
};
