import { all, call, put, takeLatest, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";
import { includes } from "lodash";

import { DetailsVersion, Run, TargetData } from "js-common";
import {
  initializeRunReviewRequest,
  setReviewDashboardLoading,
  setBulkReviewRunBlocks,
} from "../RunReview/actions";
import {
  showSingleThresholds,
  setReprocessingError,
  intialize,
} from "../RunsPage/actions";

import {
  getFullRunSuccess,
  getFullRunError,
  validateSampleIdsSuccess,
  requestAdditionalDataForBulkReviewError,
  requestAdditionalDataForBulkReviewSuccess,
} from "./actions";
import { showChart, updatePath } from "../Hub/actions";
import {
  GET_FULL_RUN_REQ,
  GET_FULL_RUN_REQ_BULK_REVIEW,
  GET_FULL_RUN_SUCCESS,
} from "./actionTypes";

import { getActiveTeamId, getActiveTeamVersion } from "../Auth/selectors";
import {
  areSingleThresholdsEnabled,
  isReprocessingModeActive,
  isReviewModeActive,
} from "../RunsPage/selectors";
import { getSelectedRuns } from "../Hub/selectors";

import { backendApi } from "../Utils";
import {
  three9DataParser,
  extractSampleRecordIds,
  isDetailsVersionTwoOrGreater,
  createFullRunDataRunsQuery,
  mapProtocolToFullRunData,
  constructReprocessingModeBatches,
  filterByDetailsVersion,
  getRunIdsByDetailsVersion,
  createRunsQueriesByDetailsVersions,
} from "./helpers";
import { extractSampleNames } from "../RunsPage/helpers";

import { RawThree9RunData, ParsedThree9RunData } from "../Models/Three9";
import CloudLog from "../Utils/CloudLog";
import {
  BulkRunIdsByDetailsVersion,
  BulkRunsQueriesByDetailsVersion,
  BulkSelectedRunsByDetailsVersion,
  RunData,
} from "./types";
import history from "../store/history";
import { BulkRunBlock } from "../RunsPage/types";
import {
  BULK_REVIEW_PATH,
  REVIEW_DASHBOARD_PATH,
} from "../RunReview/constants";

export function* requestAdditionalDataForBulkReview(): unknown {
  const selectedRuns = yield select(getSelectedRuns);
  const teamId = yield select(getActiveTeamId);
  const session = yield call([Auth, Auth.currentSession]);
  const jwt = session.idToken.jwtToken;
  backendApi.setHeader("Authorization", jwt);

  const selectedRunsByDetailsVersion: BulkSelectedRunsByDetailsVersion = {
    "biomeme-1": yield call(filterByDetailsVersion, selectedRuns, "biomeme-1"),
    "biomeme-2": yield call(filterByDetailsVersion, selectedRuns, "biomeme-2"),
    isp: yield call(filterByDetailsVersion, selectedRuns, "isp"),
    biorad: yield call(filterByDetailsVersion, selectedRuns, "biorad"),
    abi: yield call(filterByDetailsVersion, selectedRuns, "abi"),
  };

  const runIdsByDetailsVersion: BulkRunIdsByDetailsVersion = yield call(
    getRunIdsByDetailsVersion,
    selectedRunsByDetailsVersion
  );

  const runsQueriesByDetailsVersions: BulkRunsQueriesByDetailsVersion =
    yield call(createRunsQueriesByDetailsVersions, runIdsByDetailsVersion);

  const runsEndpoint = `/runs`;
  const bulkRunBlocks: BulkRunBlock[] = [];

  for (
    let i = 0;
    i < Object.keys(runsQueriesByDetailsVersions).length;
    i += 1
  ) {
    const currentDetailsVersion: DetailsVersion = Object.keys(
      runsQueriesByDetailsVersions
    )[i] as DetailsVersion;

    const currentRunIdBucket = runIdsByDetailsVersion[currentDetailsVersion];
    const isRunIdBucketEmpty = currentRunIdBucket.length === 0;

    if (!isRunIdBucketEmpty) {
      const runsQuery = runsQueriesByDetailsVersions[currentDetailsVersion];

      try {
        let runData: RunData[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const selection of runsQuery) {
          const { data } = yield call(backendApi.get, runsEndpoint, {
            teamId,
            selection,
            detailsVersion: currentDetailsVersion,
          });

          const { links } = data;
          const { next: pageTwoUrl } = links;

          let allPagesOfRunData: RunData[] = [];

          const useResults = isDetailsVersionTwoOrGreater(
            currentDetailsVersion
          );

          if (useResults) {
            allPagesOfRunData = [...allPagesOfRunData, ...data.results];
          } else {
            allPagesOfRunData = [...allPagesOfRunData, ...data];
          }

          if (pageTwoUrl) {
            let nextPageUrl = pageTwoUrl;

            while (nextPageUrl) {
              const { data: nextPageData } = yield call(
                backendApi.get,
                nextPageUrl
              );
              const { links: nextPageLinks } = nextPageData;

              if (useResults) {
                allPagesOfRunData = [
                  ...allPagesOfRunData,
                  ...nextPageData.results,
                ];
              } else {
                allPagesOfRunData = [...allPagesOfRunData, ...nextPageData];
              }

              nextPageUrl = nextPageLinks.next;
            }
          }

          runData = [...runData, ...allPagesOfRunData];
        }

        if (runData) {
          const protocolIds = [
            ...new Set(
              runData.map((item: RunData) => {
                return item.protocolId;
              })
            ),
          ];

          // eslint-disable-next-line no-restricted-syntax
          for (const protocolId of protocolIds) {
            const protocolEndpoint = `/protocols/${protocolId}`;
            const protocolReq = yield call(backendApi.get, protocolEndpoint, {
              teamId,
              currentDetailsVersion,
            });

            runData = mapProtocolToFullRunData(
              runData,
              protocolId as string,
              protocolReq
            );
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const run of runData) {
            const { details, id: runId, name, runType: type = "" } = run;

            run.standardData = { details: null };
            if (details.BMStandardCurveId) {
              const standardEndpoint = `/standards/${run.details.BMStandardCurveId}`;
              const standardReq = yield call(backendApi.get, standardEndpoint, {
                protocolId: run.protocolId,
                teamId,
                currentDetailsVersion,
              });
              run.standardData = standardReq.data || { details: null };
            }

            bulkRunBlocks.push({
              runId,
              name,
              type,
              active: false,
            });
          }

          yield put(
            requestAdditionalDataForBulkReviewSuccess(
              runData,
              currentDetailsVersion
            )
          );
        }
      } catch (e) {
        CloudLog.error(`ERROR GETTING BULK REVIEW DATA: ${e}`);
        yield put(requestAdditionalDataForBulkReviewError(e));
      }
    }
  }

  yield put(setBulkReviewRunBlocks(bulkRunBlocks));

  yield put(updatePath(REVIEW_DASHBOARD_PATH));
  yield put(updatePath(BULK_REVIEW_PATH));
  yield put(setReviewDashboardLoading(false));
  history.push("runs-page");
}

export function* watchRequestAdditionalDataForBulkReview(): any {
  yield takeLatest(
    [GET_FULL_RUN_REQ_BULK_REVIEW],
    requestAdditionalDataForBulkReview
  );
}

export function* callOnGetAdditionalData(): any {
  const selectedRuns = yield select(getSelectedRuns);
  const teamId = yield select(getActiveTeamId);
  const session = yield call([Auth, Auth.currentSession]);
  const jwt = session.idToken.jwtToken;
  backendApi.setHeader("Authorization", jwt);

  const [firstSelectedRun] = selectedRuns;
  const { detailsVersion: firstSelectedDetailsVersion } = firstSelectedRun;

  let detailsVersion = "";

  if (firstSelectedRun && firstSelectedDetailsVersion) {
    detailsVersion = firstSelectedDetailsVersion;
  } else {
    detailsVersion = yield select(getActiveTeamVersion);
  }

  try {
    let formattedRunData;

    const runIds = selectedRuns.map((r: Run) => {
      return r.id;
    });

    const runsQuery = createFullRunDataRunsQuery(runIds);
    const runsEndpoint = `/runs`;
    let runData: RunData[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const selection of runsQuery) {
      const runsReq = yield call(backendApi.get, runsEndpoint, {
        teamId,
        selection,
        detailsVersion,
      });

      if (isDetailsVersionTwoOrGreater(detailsVersion)) {
        runData = [...runData, ...runsReq.data.results];
      } else {
        runData = [...runData, ...runsReq.data];
      }
    }

    const protocolIds = [
      ...new Set(
        runData.map((item: RunData) => {
          return item.protocolId;
        })
      ),
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const protocolId of protocolIds) {
      const protocolEndpoint = `/protocols/${protocolId}`;
      const protocolReq = yield call(backendApi.get, protocolEndpoint, {
        teamId,
        detailsVersion,
      });

      runData = mapProtocolToFullRunData(
        runData,
        protocolId as string,
        protocolReq
      );
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const run of runData) {
      const { details } = run;
      const { BMStandardCurveId = "" } = details;

      run.standardData = { details: null };
      if (BMStandardCurveId) {
        const standardEndpoint = `/standards/${BMStandardCurveId}`;
        const standardReq = yield call(backendApi.get, standardEndpoint, {
          protocolId: run.protocolId,
          teamId,
          detailsVersion,
        });
        run.standardData = standardReq.data || { details: null };
      }
    }

    formattedRunData = runData;

    if (isDetailsVersionTwoOrGreater(detailsVersion)) {
      const reprocessingModeIsActive = yield select(isReprocessingModeActive);

      const parsedRunData: ParsedThree9RunData[] = runData.map(run => {
        return three9DataParser(run as unknown as RawThree9RunData);
      });

      formattedRunData = parsedRunData;

      if (reprocessingModeIsActive) {
        let runsWithErrors = ``;

        const [runIdsFirstBatch, runIdsSecondBatch] =
          constructReprocessingModeBatches(parsedRunData);

        try {
          const reprocessedTargetData: TargetData[] = [];

          if (runIdsSecondBatch.length > 0) {
            const [first, second] = yield all([
              call(
                backendApi.get,
                `/algo/results?teamId=${teamId}&runIds=${runIdsFirstBatch}`
              ),
              call(
                backendApi.get,
                `/algo/results?teamId=${teamId}&runIds=${runIdsSecondBatch}`
              ),
            ]);

            let overallStatus = null;

            if (first.status >= 400) {
              const { status } = first;
              overallStatus = status;

              if (status === 405) {
                runsWithErrors += first.data.message;
              }
            }

            if (second.status >= 400) {
              const { status } = second;

              if (overallStatus < status) {
                overallStatus = status;
              }

              if (status === 405) {
                if (runsWithErrors) {
                  runsWithErrors = `${runsWithErrors}, ${second.data.message}`;
                } else {
                  runsWithErrors += second.data.message;
                }
              }
            }

            if (overallStatus) {
              throw overallStatus;
            } else {
              reprocessedTargetData.push(...first.data, ...second.data);
            }
          } else {
            const reprocessingEndpoint = `/algo/results?teamId=${teamId}&runIds=${runIdsFirstBatch}`;
            const { data, status } = yield call(
              backendApi.get,
              reprocessingEndpoint
            );

            if (status === 400) {
              throw new Error("400");
            } else if (status === 405) {
              runsWithErrors += data.message;
              throw new Error("405");
            }

            reprocessedTargetData.push(...data);
          }

          formattedRunData = parsedRunData.map((run: ParsedThree9RunData) => {
            const newTargets = run.targets.map(target => {
              const associatedTarget = reprocessedTargetData.find(
                (processedTarget: any) => {
                  if (target.wellNumber === undefined) {
                    return false;
                  }

                  return (
                    processedTarget.runId === target.runId &&
                    processedTarget.color === target.emissionColor &&
                    processedTarget.well === +target.wellNumber
                  );
                }
              );

              return {
                ...target,
                ...associatedTarget,
              };
            });

            return {
              ...run,
              targets: [...newTargets],
            };
          });
        } catch (e) {
          const genericMessage = `An error has occurred during reprocessing. You will be redirected to
          the run selection screen. If you need help, please contact Biomeme
          support.`;

          if (e === 405) {
            const cannotReprocessMessage = `Run(s) ${runsWithErrors} cannot be reprocessed.`;
            yield put(setReprocessingError(cannotReprocessMessage));
          } else {
            yield put(setReprocessingError(genericMessage));
          }
        }
      }
    }

    yield put(getFullRunSuccess(formattedRunData));
    yield put(intialize(formattedRunData, detailsVersion));

    // SET CHART WITH APPROPRIATE CHART TYPE
    const singleThresholdsEnabled = yield select(areSingleThresholdsEnabled);
    if (singleThresholdsEnabled) {
      yield put(showSingleThresholds());
    }

    const reviewModeActive = yield select(isReviewModeActive);
    if (reviewModeActive) {
      yield put(initializeRunReviewRequest(runIds[0]));
    }

    if (!reviewModeActive) {
      yield put(showChart());
    }
  } catch (e) {
    CloudLog.error(`ERROR GETTING CHART DATA: ${e}`);
    yield put(getFullRunError(e));
  }
}

export function* watchForGetAdditionalData(): any {
  yield takeLatest([GET_FULL_RUN_REQ], callOnGetAdditionalData);
}

export function* validateSampleRecordIds(action: {
  type: any;
  payload: any;
}): any {
  const { payload: fullRunData } = action;
  const teamId: string = yield select(getActiveTeamId);
  const version: string = yield select(getActiveTeamVersion);

  const sampleRecordIds: string[] = [];

  // grab all unique sampleRecordIds for active runs
  fullRunData.forEach((run: RunData) => {
    const sampleNames = extractSampleNames(run, version);
    const ids = extractSampleRecordIds(sampleNames);

    sampleRecordIds.push(...ids);
  });

  // pull out sampleRecordIds active team cannot access
  const totalSampleRecordIds = sampleRecordIds.length;
  const inaccessibleSampleRecordIds: string[] = [];
  for (let i = 0; i < totalSampleRecordIds; i += 1) {
    const recordId = sampleRecordIds[i];

    const { status } = yield call(
      backendApi.get,
      `/samples/records/${recordId}?detailsVersion=biomeme-2&teamId=${teamId}`
    );

    if (status !== 200) {
      inaccessibleSampleRecordIds.push(recordId);
    }

    // modify original fullRunData to exclude inaccessible sampleRecordIds
    fullRunData.forEach((run: RunData) => {
      const { targets } = run;

      targets.forEach(target => {
        const { sampleRecordId: id } = target;

        if (id && includes(inaccessibleSampleRecordIds, id)) {
          // eslint-disable-next-line no-param-reassign
          delete target.sampleRecordId;
        }
      });
    });

    yield put(validateSampleIdsSuccess(fullRunData));
  }
}

export function* watchGetFullRunDataSuccess(): any {
  yield takeLatest(GET_FULL_RUN_SUCCESS, validateSampleRecordIds);
}

export default function* rootSaga(): any {
  yield all([
    watchForGetAdditionalData(),
    watchGetFullRunDataSuccess(),
    watchRequestAdditionalDataForBulkReview(),
  ]);
}
