import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Select } from "bio-web-components";

import { getExternalUploadProtocolsRequest } from "../../ProtocolData/actions";
import { getExternalUploadProtocols } from "../../ProtocolData/selectors";

interface StateProps {
  externalUploadProtocols: Array<any>;
}

interface DispatchProps {
  retrieveExternalUploadProtocols: (detailsVersion: string) => void;
}

interface Props {
  detailsVersion: string;
  protocolId: string;
  setProtocolId: (id: string) => void;
}

const ProtocolSelector = (props: Props & StateProps & DispatchProps) => {
  const { detailsVersion } = props;

  useEffect(() => {
    const { retrieveExternalUploadProtocols } = props;

    retrieveExternalUploadProtocols(detailsVersion);
  }, [detailsVersion]);

  const { externalUploadProtocols, protocolId, setProtocolId } = props;

  if (externalUploadProtocols.length > 0) {
    return (
      <div className="protocol_selection-container">
        <Select
          value={protocolId}
          options={externalUploadProtocols.map(protocol => ({
            value: protocol.id,
            label: protocol.name,
          }))}
          onChange={(value: string) => setProtocolId(value)}
        />
      </div>
    );
  }

  return (
    <div className="protocol_selection-container">
      <span>No protocols available</span>
    </div>
  );
};

const mapStateToProps = (state: any): StateProps => {
  return {
    externalUploadProtocols: getExternalUploadProtocols(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    retrieveExternalUploadProtocols: (detailsVersion: string) =>
      dispatch(getExternalUploadProtocolsRequest(detailsVersion)),
  };
};

export default connect<StateProps, DispatchProps, Props, any>(
  mapStateToProps,
  mapDispatchToProps
)(ProtocolSelector);
