import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { changeActiveTab } from "../../actions";
import { getActiveTab } from "../../selectors";
import { getProtocolListLength } from "../../../ProtocolData/selectors";
import {
  getBMRunsLength,
  getDeviceFilterLength,
} from "../../../RunData/selectors";
import { setActiveRunFilter } from "../../../RunReview/actions";
import {
  getPendingRunCount,
  getActiveRunFilter,
  getNeedsReviewCount,
  getNeedsApprovalCount,
  getRecentlyReviewedAndApprovedCount,
  getNeedsApprovalPositiveCount,
} from "../../../RunReview/selectors";
import Tab from "./tab";
import DropdownSelect from "./dropDownSelect";
import { getTableFilteredRunCount } from "../../tableSelectors";

export class TabRow extends React.Component {
  handleHubTabClick = tab => {
    const { changeHubTab } = this.props;
    const { name } = tab;

    changeHubTab(name);
  };

  handleReviewTabClick = tab => {
    const { changeReviewTab } = this.props;
    const { filter } = tab;

    changeReviewTab(filter);
  };

  renderTab = (tab, active) => {
    const { location = {} } = this.props;
    const { pathname = "" } = location;
    const isReviewDashboard = pathname.includes("/review-dashboard");

    return (
      <div
        className="tab-row"
        style={{ display: "flex", alignItems: "center" }}
        key={tab.name}
        onClick={() =>
          isReviewDashboard
            ? this.handleReviewTabClick(tab)
            : this.handleHubTabClick(tab)
        }
      >
        <Tab
          name={tab.name}
          filterCount={tab.filterCount}
          active={
            isReviewDashboard ? tab.filter === active : tab.name === active
          }
        />
      </div>
    );
  };

  renderFilterTabs = (tabs, active) => {
    return (
      <div className="tab-row-filter-tabs">
        <div className="tab-row-label">Filter By</div>
        {tabs.map(tab => {
          return this.renderTab(tab, active);
        })}
        <DropdownSelect />
      </div>
    );
  };

  render() {
    const {
      hubRunCount,
      dashboardRunCount,
      hubTabs,
      dashboardTabs,
      location = {},
      hubActiveTab,
      dashboardActiveTab,
    } = this.props;
    const { pathname = "" } = location;

    let runCount = hubRunCount;
    let tabs = hubTabs;
    let active = hubActiveTab;
    if (pathname.includes("/review-dashboard")) {
      runCount = dashboardRunCount;
      tabs = dashboardTabs;
      active = dashboardActiveTab;
    }

    return (
      <div
        className="tabRows"
        style={{
          minWidth: "880px",
          maxWidth: "880px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #D9D9D9",
        }}
      >
        <div
          className="tab-row"
          style={{ display: "flex", alignItems: "center" }}
          key="Runs Filter"
        >
          <Tab name="Runs" index={0} />
        </div>
        {runCount > 0 && this.renderFilterTabs(tabs, active)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const hubRunCount = getBMRunsLength(state);
  const dashboardRunCount = getPendingRunCount(state);
  return {
    hubTabs: [
      {
        name: "Runs",
        filterCount: getTableFilteredRunCount(state),
      },
      {
        name: "Protocols",
        filterCount: getProtocolListLength(state),
      },
      {
        name: "Thermocyclers",
        filterCount: getDeviceFilterLength(state),
      },
    ],
    dashboardTabs: [
      {
        name: "Review",
        filter: "needsReview",
        filterCount: getNeedsReviewCount(state),
      },
      {
        name: "Approve",
        filter: "needsApproval",
        filterCount: getNeedsApprovalCount(state),
      },
      {
        name: "Attention",
        filter: "needsApprovalPositive",
        filterCount: getNeedsApprovalPositiveCount(state),
      },
      {
        name: "Recent",
        filter: "recentlyReviewedAndApproved",
        filterCount: getRecentlyReviewedAndApprovedCount(state),
      },
    ],
    hubActiveTab: getActiveTab(state),
    dashboardActiveTab: getActiveRunFilter(state),
    hubRunCount,
    dashboardRunCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeHubTab: tab => {
      dispatch(changeActiveTab(tab));
    },
    changeReviewTab: tab => {
      dispatch(setActiveRunFilter(tab));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabRow));
