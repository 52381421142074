export const GET_DATA_REQ = "protocolData/GET";
export const GET_DATA_SUCCESS = "protocolData/GET_SUCCESS";
export const GET_DATA_ERROR = "protocolData/GET_ERROR";
export const PROTOCOLS_RECEIVED = "protocolData/RECEIVED";

export const RESET = "protocolData/RESET";

export const CREATE_PROTOCOL = "protocolData/CREATE_PROTOCOL";

export const GET_EXTERNAL_UPLOAD_PROTOCOLS_REQUEST =
  "protocolData/GET_EXTERNAL_UPLOAD_PROTOCOLS_REQUEST";
export const GET_EXTERNAL_UPLOAD_PROTOCOLS_SUCCESS =
  "protocolData/GET_EXTERNAL_UPLOAD_PROTOCOLS_SUCCESS";
