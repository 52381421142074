export const MAX_RUNS = 10;
export const MAX_ISOTHERMAL_RUNS = 5;
export const MAX_EXTERNAL_RUNS = 1;

export const BIORAD_DETAILS_VERSION = "biorad";
export const ABI_DETAILS_VERSION = "abi";

export const RUNS_PAGE_COUNT = 20;

export const EXTERNAL_DETAILS_VERSIONS = {
  values: [BIORAD_DETAILS_VERSION, ABI_DETAILS_VERSION],
  display: ["BioRad", "ABI"],
};

export const CHANNELS = {
  values: ["green", "amber", "red"],
  display: ["Green", "Amber", "Red"],
};

export const runTypes = {
  melt: "melt",
  qualitative: "qualitative",
  quantitative: "quantitative",
  isothermal: "isothermal",
};
