import { all, put, takeLatest } from "redux-saga/effects";
import { RESET_SIDEBAR } from "../Sidebar/actionTypes";
import { reset } from "./actions";

export function* callOnReset() {
  yield put(reset());
}
export function* watchResetToTopLevel() {
  yield takeLatest(RESET_SIDEBAR, callOnReset);
}

export default function* rootSaga() {
  yield all([watchResetToTopLevel()]);
}
