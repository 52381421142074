import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { Dispatch } from "redux";
import { updateTabularDataOptions as updateTabularDataOptionsAction } from "../../../actions";
import { getTabularDataFilter, getTabularDataOrder } from "../../../selectors";
import { tooltipDelay } from "../../../constants";
import { isRunTypeMelt } from "../../../../Hub/selectors";
import constants from "../../chartConstants";
import {
  BulkTabularDataTarget,
  GetBulkReviewTabularDataReturn,
} from "../../../../RunReview/types";
import { computeStyles, sortTabularData } from "./helpers";
import { BulkTabularDataStyles, TabularDataOptions } from "./types";
import { getBulkReviewTabularData } from "../../../../RunReview/selectors";
import { setBulkTabularFailedRunIds } from "../../../../RunReview/actions";
import { noTargets } from "./styles";

interface DispatchProps {
  updateTabularDataOptions: (options: TabularDataOptions) => void;
  setFailedRunIds: (failedRunIds: string[]) => void;
}

interface StateProps {
  bulkTabularData: GetBulkReviewTabularDataReturn;
  filter: string;
  order: number;
  runTypeIsMelt: boolean;
}

type Props = DispatchProps & StateProps;

export const BulkTabularData: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { bulkTabularData, order } = props;
  const { tabularData, failedRunIds } = bulkTabularData;

  useEffect(() => {
    if (failedRunIds) {
      const { setFailedRunIds } = props;

      setFailedRunIds(failedRunIds);
    }
  }, []);

  const sqData = tabularData.filter(tabData => {
    return tabData.SQ;
  });
  const showSqData = sqData.length > 0;
  const showSampleType =
    tabularData.filter(tabData => {
      return tabData.sampleType;
    }).length > 0;

  let fields = [
    "RUN NAME",
    "CHANNEL & WELL",
    "OVERALL RESULT",
    "Cq",
    "SAMPLE ID",
    "TARGET",
    "COMMENTS",
  ];

  if (showSqData) {
    fields = [
      ...fields.slice(0, constants.tabularDataCqColumnIndex),
      "SQ",
      ...fields.slice(constants.tabularDataCqColumnIndex),
    ];
  }

  if (showSampleType) {
    let sliceIndex = constants.tabularDataCqColumnIndex;
    if (showSqData) {
      sliceIndex = constants.tabularDataCqColumnIndex + 1;
    }

    fields = [
      ...fields.slice(0, sliceIndex),
      "SAMPLE TYPE",
      ...fields.slice(sliceIndex),
    ];
  }

  const renderSqDataElement = (
    data: BulkTabularDataTarget,
    styles: BulkTabularDataStyles
  ) => {
    let sqDataElement = null;
    if (showSqData) {
      sqDataElement = <td style={styles.data}>{data.SQ || "N/A"}</td>;
    }

    return sqDataElement;
  };

  const renderSampleTypeElement = (
    data: BulkTabularDataTarget,
    styles: BulkTabularDataStyles
  ) => {
    const { sampleType } = data;
    const { data: dataStyles } = styles;

    let sampleTypeElement = null;
    if (showSampleType) {
      sampleTypeElement = (
        <>
          <td style={dataStyles}>{sampleType || "N/A"}</td>
        </>
      );
    }

    return sampleTypeElement;
  };

  const computeHeadings = (styles: BulkTabularDataStyles) => {
    const { runTypeIsMelt } = props;

    return fields.map(field => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fieldToRender: any = field;
      const { filter, updateTabularDataOptions } = props;

      if (field === "SQ") {
        fieldToRender = (
          <span
            data-multiline
            data-tip="Note: Starting Quantity (SQ) values are computed from the original Cq values at a fixed threshold.<br /> Changing the threshold or reprocessing the curve will NOT update the computed SQ value."
            style={{ color: "red" }}
          >
            SQ
          </span>
        );
      } else if (field === "Cq" && runTypeIsMelt) {
        fieldToRender = "PEAK";
      }

      let visibility = "hidden";
      if (filter === field) {
        visibility = "visible";
      }

      return (
        <td style={styles.heading} key={field}>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              return sortTabularData(
                field,
                filter,
                updateTabularDataOptions,
                order
              );
            }}
          >
            {fieldToRender}
            <img
              src="../../../../../images/BackArrowNavy.svg"
              alt=""
              style={
                {
                  ...styles.arrow,
                  visibility,
                } as React.CSSProperties
              }
              height="20"
              width="13"
            />
          </div>
        </td>
      );
    });
  };

  const styles = computeStyles(order);

  const renderTabularDataRow = (
    data: BulkTabularDataTarget,
    backgroundColor: string
  ) => {
    return (
      <tr
        style={{
          backgroundColor,
          lineHeight: "40px",
        }}
        key={data.targetId}
      >
        <td style={styles.data}>{data.run}</td>
        <td style={styles.data}>{data.well}</td>
        <td style={styles.data}>{data.overallResult}</td>
        <td style={styles.data}>{data.CQ || 0}</td>
        {renderSqDataElement(data, styles)}
        {renderSampleTypeElement(data, styles)}
        <td style={styles.data}>{data.sampleId || "N/A"}</td>
        <td style={styles.data}>{data.target}</td>
        <td style={{ ...styles.data, width: "25vw", lineHeight: "normal" }}>
          {data.comments}
        </td>
      </tr>
    );
  };

  const renderTabularData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tabularDataJSX: any = (
      <div style={noTargets}>
        There are no targets that match the current filter.
      </div>
    );

    if (tabularData.length > 0) {
      tabularDataJSX = tabularData.map((data, index) => {
        const even = index % 2 === 0;

        let backgroundColor = "";
        if (even) {
          backgroundColor = "#F9F9F9";
        }

        return renderTabularDataRow(data, backgroundColor);
      });
    }

    return tabularDataJSX;
  };

  return (
    <div className="tabular_data" style={styles.container}>
      <ReactTooltip delayShow={tooltipDelay} />
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ lineHeight: "40px" }}>{computeHeadings(styles)}</tr>
          {renderTabularData()}
        </tbody>
      </table>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapStateToProps(state: any) {
  return {
    bulkTabularData: getBulkReviewTabularData(state),
    filter: getTabularDataFilter(state),
    order: getTabularDataOrder(state),
    runTypeIsMelt: isRunTypeMelt(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateTabularDataOptions: (options: TabularDataOptions) => {
      return dispatch(updateTabularDataOptionsAction(options));
    },
    setFailedRunIds: (failedRunIds: string[]) => {
      return dispatch(setBulkTabularFailedRunIds(failedRunIds));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkTabularData);
