import React from "react";

const IsothermalIcon = props => {
  const { opacity } = props;

  return (
    <svg
      width={30}
      height={15}
      style={{
        marginRight: "10px",
        marginTop: "1px",
        opacity,
      }}
      {...props}
      className="isothermal-run-icon run-icon"
      data-tip="Isothermal"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h28v14H0z" fill="#D6E7E4" fillRule="nonzero" />
        <path
          d="M7 10.282V4.081h1.251v6.201H7zm4.644.081c-1.323 0-2.232-.81-2.232-1.908h1.251c.009.45.441.819 1.017.819.558 0 .927-.288.927-.729 0-.342-.252-.585-.783-.747l-.756-.216c-1.359-.36-1.602-1.179-1.602-1.737C9.466 4.738 10.42 4 11.608 4c1.17 0 2.07.711 2.07 1.89h-1.251c0-.441-.306-.783-.846-.783-.486 0-.855.297-.855.711 0 .153.045.477.693.657l.693.198c.936.261 1.755.756 1.755 1.809 0 1.233-1.053 1.881-2.223 1.881zm6.129 0c-1.764 0-3.15-1.422-3.15-3.177 0-1.764 1.386-3.186 3.15-3.186a3.163 3.163 0 0 1 3.159 3.186 3.161 3.161 0 0 1-3.159 3.177zm0-1.17c1.107 0 1.899-.891 1.899-2.007s-.792-2.007-1.899-2.007c-1.116 0-1.899.891-1.899 2.007s.783 2.007 1.899 2.007z"
          fill="#012639"
        />
      </g>
    </svg>
  );
};

export default IsothermalIcon;
