import {
  ReviewRule,
  RawRunReview,
  RawSampleReview,
  RawTargetReview,
  ReviewUserRole,
  DetailsVersion,
  TargetRule,
  LogicalTargetRuleCollection,
  TargetRuleCollection,
} from "js-common";

import {
  SampleReview,
  TargetReview,
  RunReview,
  BaseSample,
  BaseTarget,
  PendingRun,
  ExistingReviewData,
  BulkSamples,
  MappedBulkSampleTarget,
  MappedBulkFullRunDataTarget,
  CombinedBulkTarget,
  BulkTabularDataTarget,
  MapBulkSampleTargetsReturn,
  MapBulkFullRunDataReturn,
  CreateBulkTabularDataTargetsReturn,
  ConstructMappedBulkFullRunDataTargetsReturn,
  CombinedBulkTargetsReturn,
} from "./types";
import {
  BLANK,
  INCONCLUSIVE,
  INVALID_SAMPLE,
  NEGATIVE,
  NEGATIVE_CONTROL,
  NO_TEMPLATE_CONTROL,
  PENDING,
  POSITIVE,
  POSITIVE_CONTROL,
} from "./constants";
// eslint-disable-next-line import/no-self-import
import * as localFn from "./helpers";
import {
  AbiRunDataTarget,
  Biomeme2RunDataTarget,
  BioradRunDataTarget,
  BulkFullRunDataByDetailsVersion,
  BulkFullRunDataTargetArrayTypes,
  BulkFullRunDataTargetTypes,
  BulkFullRunDataTargetTypesRunDataExcluded,
  BulkFullRunDataTypes,
  BulkFullRunTypes,
  RunData,
  RunDataTarget,
  WellNumbers,
} from "../FullRunData/types";
import { BulkRunBlock } from "../RunsPage/types";
import { checkIfWellContainsLetters } from "../RunsPage/helpers";
import CloudLog from "../Utils/CloudLog";
import {
  isRecursiveTargetRuleCollection,
  isTargetRuleCollection,
} from "./typeGuards";

/** ****************************************************
 * LOCAL HELPERS
 **************************************************** */

/**
 * Utility to grab a targets name from full run data
 *
 * @param target The raw unnamed target review data from backend
 * @param fullRunData The full run data associated with the currently active run
 */
export function getTargetName(
  target: RawTargetReview,
  fullRunData: RunData
): string {
  const fullRunTarget = fullRunData.targets.find(fullTarget => {
    return fullTarget.id === target.targetId;
  });

  if (fullRunTarget) {
    return fullRunTarget.name;
  }

  return "N/A";
}

/**
 * Utility to grab the original, non-overridden Cq value associated with a TargetReview
 * if it's Cq has been overridden by a previous reviewer
 *
 * @param target The raw target data from backend
 * @param fullRunData The full run data associated with the currently active run
 */
export function getOriginalCq(
  target: RawTargetReview,
  fullRunData: RunData
): number {
  const { targetId } = target;

  const originalTarget = fullRunData.targets.find(runTarget => {
    return runTarget.id === targetId;
  });

  if (originalTarget) {
    return originalTarget.CQ;
  }

  return 0;
}

/**
 * Utility to grab the well number of a sample from fullRunData on state
 *
 * @param sample The sample of interest
 * @param fullRunData The full run data from state
 */
export function getWellNumber(
  sample: SampleReview | RawSampleReview | BaseSample,
  fullRunData: RunData
): Array<number> {
  const sampleTargets = fullRunData.targets.filter(target => {
    return target.sampleId === sample.sampleName;
  });

  if (sampleTargets.length > 0) {
    const wells = sampleTargets
      .map(sampleTarget => {
        return sampleTarget.wellNumber;
      })
      .sort((a, b) => {
        return a - b;
      });

    return [...new Set(wells)];
  }

  return [-1];
}

/**
 * Utility to copy samples into a new object after a target/sample has been manipulated
 *
 * @param samples The array of all sample reviews
 * @param newSample The recently updated sample
 * @param newSampleIndex The index in samples of the recently updated sample
 */
export function copySamples(
  samples: Array<SampleReview>,
  newSample: SampleReview,
  newSampleIndex: number
): Array<SampleReview> {
  const newSamples = [...samples];

  newSamples[newSampleIndex] = newSample;

  return newSamples;
}

/**
 * Utility to parse the raw target data from backend into an array of
 * TargetReview instances
 *
 * @param targetReviews The raw target review data returned from backend
 * @param fullRunData The full run data associated with the currently active run
 */
export function parseRawTargetReviews(
  targetReviews: Array<RawTargetReview>,
  fullRunData: RunData
): Array<TargetReview> {
  return targetReviews.map(target => {
    const overriden = Boolean(target.cqOverride);

    let originalCq = target.cq;
    if (overriden) {
      originalCq = localFn.getOriginalCq(target, fullRunData);
    }

    return {
      targetId: target.targetId,
      name: localFn.getTargetName(target, fullRunData),
      cqOverride: overriden,
      overridenCq: target.cq,
      cq: originalCq,
      comments: target.comments,
    };
  });
}

/**
 * Utility to evaluate if a single target rule is satisfied
 *
 * @export
 * @param {TargetRule} targetRule
 * @param {TargetReview} [activeSampleTarget]
 * @return {*}  {boolean}
 */
export function satisfiesIndividualTargetRule(
  targetRule: TargetRule,
  activeSampleTarget?: TargetReview
): boolean {
  const { constraints } = targetRule;
  const { lower, upper, detected } = constraints;

  if (activeSampleTarget) {
    const { cq, overridenCq, cqOverride } = activeSampleTarget;

    let activeCqValue = cq;
    if (cqOverride) {
      activeCqValue = overridenCq;
    }

    if (detected !== undefined) {
      if (detected === 0 && activeCqValue > 0) {
        return false;
      }

      if (detected === 1 && activeCqValue <= 0) {
        return false;
      }
    }

    if (lower !== undefined && activeCqValue < lower) {
      return false;
    }

    if (upper !== undefined && activeCqValue > upper) {
      return false;
    }
  }

  return true;
}

/**
 * Utility to evaluate if any target rules are satisfied when
 * the rule collection used the 'OR' logicalOperator
 *
 * @export
 * @param {TargetRule[]} targetRules
 * @param {TargetReview[]} targets
 * @return {*}  {boolean}
 */
export function satisfiesAnyTargetRules(
  targetRules: TargetRule[],
  targets: TargetReview[]
): boolean {
  const rulesSatisfied = targetRules.some(targetRule => {
    const activeSampleTargets = targets.filter(target => {
      return target.name === targetRule.target;
    });

    return activeSampleTargets.some(activeSampleTarget => {
      return localFn.satisfiesIndividualTargetRule(
        targetRule,
        activeSampleTarget
      );
    });
  });

  return rulesSatisfied;
}

/**
 * Utility to evaluate if any target rules are satisfied when
 * the rule collection used the 'AND' logicalOperator
 *
 * @export
 * @param {TargetRule[]} targetRules
 * @param {TargetReview[]} targets
 * @return {*}  {boolean}
 */
export function satisfiesAllTargetRules(
  targetRules: TargetRule[],
  targets: TargetReview[]
): boolean {
  const rulesSatisfied = targetRules.every(targetRule => {
    const activeSampleTargets = targets.filter(target => {
      return target.name === targetRule.target;
    });

    return activeSampleTargets.every(activeSampleTarget => {
      return localFn.satisfiesIndividualTargetRule(
        targetRule,
        activeSampleTarget
      );
    });
  });

  return rulesSatisfied;
}
/**
 * A utility to determine if a sample conforms to a specific rule collection
 * First checks detected rules, then bounds
 *
 * @export
 * @param {LogicalTargetRuleCollection} logicalTargetRuleCollection
 * @param {SampleReview} sample
 * @return {*}  {boolean}
 */
export function satisfiesRuleCollection(
  logicalTargetRuleCollection: LogicalTargetRuleCollection,
  sample: SampleReview
): boolean {
  const { targets } = sample;
  let satisfied = false;

  if (isTargetRuleCollection(logicalTargetRuleCollection)) {
    const { logicalOperator, targetRules } = logicalTargetRuleCollection;

    if (logicalOperator === "AND") {
      satisfied = localFn.satisfiesAllTargetRules(targetRules, targets);
    }
    if (logicalOperator === "OR") {
      satisfied = localFn.satisfiesAnyTargetRules(targetRules, targets);
    }
  }

  if (isRecursiveTargetRuleCollection(logicalTargetRuleCollection)) {
    const { logicalOperator, logicalTargetRuleCollections } =
      logicalTargetRuleCollection;

    if (logicalOperator === "AND") {
      satisfied = logicalTargetRuleCollections.every(ruleCollection => {
        return localFn.satisfiesRuleCollection(ruleCollection, sample);
      });
    }
    if (logicalOperator === "OR") {
      satisfied = logicalTargetRuleCollections.some(ruleCollection => {
        return localFn.satisfiesRuleCollection(ruleCollection, sample);
      });
    }
  }

  return satisfied;
}

/**
 * Utility to compute a sample's overallResult, passed and failureReason fields based off of provided review rules
 *
 * @param sample The sample to have its result computed
 * @param reviewRules The associated review rules
 */
export function computeSampleResult(
  sample: SampleReview,
  reviewRules: ReviewRule,
  runPassed = true,
  runFailureReason: string[] = []
): { overallResult: string; passed: boolean; failureReason: string | null } {
  const { sampleType } = sample;

  const result = {
    overallResult: sample.overallResult,
    passed: sample.passed,
    failureReason: sample.failureReason,
  };

  if (sampleType) {
    const { controlRules } = reviewRules;

    const activeControlRule = controlRules.find(controlRule => {
      return controlRule.control === sampleType;
    });

    if (activeControlRule) {
      const { logicalTargetRuleCollection } = activeControlRule;

      if (
        localFn.satisfiesRuleCollection(logicalTargetRuleCollection, sample)
      ) {
        result.overallResult = "Passed";
        result.passed = true;
        result.failureReason = null;
      } else {
        result.overallResult = "Failed";
        result.passed = false;
        result.failureReason = `${sampleType} Control Failure`;
      }
    }
  } else {
    const { sampleRules } = reviewRules;
    const { rules } = sampleRules;

    if (runPassed) {
      result.overallResult = sampleRules.default;
      result.failureReason = null;
      result.passed = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const sampleRule of rules) {
        const { logicalTargetRuleCollection } = sampleRule;

        if (
          localFn.satisfiesRuleCollection(logicalTargetRuleCollection, sample)
        ) {
          result.overallResult = sampleRule.result;
          break;
        }
      }
    } else {
      result.overallResult = INVALID_SAMPLE;
      result.failureReason = runFailureReason.join(", ");
      result.passed = true;
    }
  }

  return result;
}

export function recomputeAllSampleResults(
  samples: Array<SampleReview>,
  reviewRules: ReviewRule,
  runPassed: boolean,
  runFailureReason: string[]
): Array<SampleReview> {
  return samples.map(sample => {
    let newSample = { ...sample };

    if (!sample.blank && localFn.allSampleCqsApproved(sample)) {
      const { passed, overallResult, failureReason } =
        localFn.computeSampleResult(
          sample,
          reviewRules,
          runPassed,
          runFailureReason
        );

      newSample = {
        ...sample,
        passed,
        overallResult,
        failureReason,
      };
    }

    return newSample;
  });
}

/**
 * Utility to parse the raw sample data from backend into an array of
 * SampleReview instances
 *
 * @param sampleReviews The raw sample review data returned from backend
 * @param fullRunData The full run data associated with the currently active run
 */
export function parseRawSampleReviews(
  sampleReviews: Array<RawSampleReview>,
  rules: ReviewRule,
  fullRunData: RunData
): Array<SampleReview> {
  return sampleReviews
    .map(sample => {
      const parsedSample: SampleReview = {
        sampleId: sample.sampleId,
        sampleName: sample.sampleName,
        wellNumber: localFn.getWellNumber(sample, fullRunData),
        sampleType: sample.sampleType,
        blank: sample.overallResult.includes(BLANK),
        passed: Boolean(sample.passed),
        comments: sample.comments,
        overallResult: sample.overallResult,
        failureReason: sample.failureReason,
        targets: localFn.parseRawTargetReviews(sample.targets, fullRunData),
      };

      const { passed, failureReason } = localFn.computeSampleResult(
        parsedSample,
        rules
      );
      parsedSample.passed = passed;
      parsedSample.failureReason = failureReason;

      return parsedSample;
    })
    .sort((a, b) => {
      let result = -1;
      if (a.wellNumber > b.wellNumber) {
        result = 1;
      }

      return result;
    });
}

export function getRunDataWellNumbers(
  targets: RunDataTarget[],
  sampleId: string
): WellNumbers {
  const matchingTargets = targets.filter(target => {
    return target.sampleId === sampleId;
  });

  return matchingTargets.map(target => {
    return target.wellNumber;
  });
}

export function getBulkWellNumbersFromTargets(
  targets: Biomeme2RunDataTarget[] | AbiRunDataTarget[] | BioradRunDataTarget[],
  sampleId: string
): WellNumbers {
  const targetsDetails = targets.map(target => {
    return target.details;
  });
  const matchingTargetsDetails = targetsDetails.filter(targetsDetail => {
    return targetsDetail.sampleId === sampleId;
  });

  return matchingTargetsDetails.map(matchingTargetsDetail => {
    return matchingTargetsDetail.wellNumber;
  });
}

export function getBulkWellNumbers(
  detailsVersion: DetailsVersion,
  sampleId: string,
  targets: BulkFullRunDataTargetArrayTypes
): WellNumbers {
  let wellNumber: WellNumbers = [];

  if (detailsVersion === "biomeme-1" || detailsVersion === "isp") {
    wellNumber = localFn.getRunDataWellNumbers(
      targets as unknown as RunDataTarget[],
      sampleId
    );
  } else {
    wellNumber = localFn.getBulkWellNumbersFromTargets(
      targets as unknown as
        | Biomeme2RunDataTarget[]
        | AbiRunDataTarget[]
        | BioradRunDataTarget[],
      sampleId
    );
  }

  if (wellNumber.length > 0) {
    wellNumber.sort((a: any, b: any) => {
      return a - b;
    });

    return [...new Set(wellNumber)];
  }

  return [-1];
}

export function getBulkOriginalCq(
  target: RawTargetReview,
  fullRunDataTargets: BulkFullRunDataTargetArrayTypes,
  detailsVersion: DetailsVersion
): number {
  const { targetId } = target;

  let originalTarget = null;

  if (detailsVersion === "biomeme-1" || detailsVersion === "isp") {
    originalTarget = (fullRunDataTargets as unknown as RunDataTarget[]).find(
      runTarget => {
        return runTarget.id === targetId;
      }
    );
  }

  if (originalTarget) {
    return originalTarget.CQ;
  }

  return 0;
}

export function getBulkTargetName(
  target: RawTargetReview,
  fullRunDataTargets: BulkFullRunDataTargetArrayTypes,
  detailsVersion: DetailsVersion
): string {
  let fullRunTarget = null;

  fullRunTarget = (fullRunDataTargets as any).find(
    (fullTarget: BulkFullRunDataTargetTypes) => {
      return fullTarget.id === target.targetId;
    }
  );

  if (fullRunTarget) {
    if (detailsVersion === "biomeme-1" || detailsVersion === "isp") {
      return fullRunTarget.name;
    }

    return fullRunTarget.details.name;
  }

  return "N/A";
}

export function parseRawBulkTargetReviews(
  detailsVersion: DetailsVersion,
  targetReviews: Array<RawTargetReview>,
  bulkFullRunDataTargets: BulkFullRunDataTargetArrayTypes
): Array<TargetReview> {
  return targetReviews.map(target => {
    const overriden = Boolean(target.cqOverride);

    let originalCq = target.cq;
    if (overriden) {
      originalCq = localFn.getBulkOriginalCq(
        target,
        bulkFullRunDataTargets,
        detailsVersion
      );
    }

    const name = localFn.getBulkTargetName(
      target,
      bulkFullRunDataTargets,
      detailsVersion
    );

    return {
      targetId: target.targetId,
      name,
      cqOverride: overriden,
      overridenCq: target.cq,
      cq: originalCq,
      comments: target.comments,
    };
  });
}

export function parseRawBulkSampleReviews(
  sampleReviews: Array<RawSampleReview>,
  rules: ReviewRule,
  bulkFullRunData: BulkFullRunTypes,
  detailsVersion: DetailsVersion
): Array<SampleReview> {
  return sampleReviews
    .map(sample => {
      const {
        sampleId,
        sampleName,
        sampleType,
        comments,
        overallResult,
        failureReason: sampleFailureReason,
        passed: samplePassed,
        targets: sampleTargets,
      } = sample;
      const { targets } = bulkFullRunData;

      const wellNumber: WellNumbers = localFn.getBulkWellNumbers(
        detailsVersion,
        sampleId,
        targets as unknown as BulkFullRunDataTargetArrayTypes
      );

      const parsedTargets = localFn.parseRawBulkTargetReviews(
        detailsVersion,
        sampleTargets,
        targets as unknown as BulkFullRunDataTargetArrayTypes
      );

      const parsedSample: SampleReview = {
        sampleId,
        sampleName,
        wellNumber,
        sampleType,
        blank: overallResult.includes(BLANK),
        passed: Boolean(samplePassed),
        comments,
        overallResult,
        failureReason: sampleFailureReason,
        targets: parsedTargets,
      };

      const { passed, failureReason } = localFn.computeSampleResult(
        parsedSample,
        rules
      );
      parsedSample.passed = passed;
      parsedSample.failureReason = failureReason;

      return parsedSample;
    })
    .sort((a, b) => {
      let result = -1;
      if (a.wellNumber > b.wellNumber) {
        result = 1;
      }

      return result;
    });
}

/**
 * Utility to verify that all Cqs on a given sample review have been approved
 *
 * @param sample The SampleReview instance to verify Cqs for
 */
export function allSampleCqsApproved(sample: SampleReview) {
  return (
    sample.targets.find(target => {
      return target.cqOverride === null;
    }) === undefined
  );
}

/**
 * Utility to copy, and update overallResult, passed and failureReason on
 * a newly updated sample if all of its Cqs have been approved
 *
 * @param sample The newly update SampleReview
 * @param reviewRules The review rules associated with the active protocol
 */
export function updateSampleResult(
  sample: SampleReview,
  reviewRules: ReviewRule
): SampleReview {
  const newSample = { ...sample };

  if (newSample.blank) {
    newSample.overallResult = BLANK;
    newSample.passed = true;
    newSample.failureReason = null;
    newSample.sampleType = "";
  } else if (localFn.allSampleCqsApproved(newSample)) {
    const { overallResult, passed, failureReason } =
      localFn.computeSampleResult(newSample, reviewRules);
    newSample.overallResult = overallResult;
    newSample.passed = passed;
    newSample.failureReason = failureReason;
  } else {
    newSample.overallResult = PENDING;
  }

  return newSample;
}

/** ****************************************************
 * SAMPLE & TARGET SETTERS
 **************************************************** */

/**
 * Generalized function to find and set a value within a SampleReview object
 * without mutating the original object
 *
 * @param samples The samples list on state
 * @param sampleId The sampleId of the sample of interest
 * @param key The key of the value to be updated
 * @param value The value to update to
 * @param reviewRules An optional parameter to pass if the value being set affects a sample's computed fields (overallResult, passed, failureReason)
 *
 * @returns {Array<SampleReview> | undefined}
 */
export function findAndSetSample(
  samples: Array<SampleReview>,
  sampleName: string,
  key: string,
  value: string | boolean | null,
  reviewRules?: ReviewRule
): Array<SampleReview> | undefined {
  const sampleIndex = samples.findIndex(sample => {
    return sample.sampleName === sampleName;
  });

  let newSamples: SampleReview[] | undefined;
  if (sampleIndex > -1) {
    let newSample = { ...samples[sampleIndex] };
    newSample[key] = value;

    if (reviewRules) {
      newSample = localFn.updateSampleResult(newSample, reviewRules);
    }

    newSamples = localFn.copySamples(samples, newSample, sampleIndex);
  }

  return newSamples;
}

/**
 * Generalized function to find and set a value within a TargtReview object
 * without mutating the original object or its parent SampleReview
 *
 * @param samples The samples list on state
 * @param targets The targets list on state
 * @param sampleId The parent sampleId of the target of interest
 * @param targetId The targetId of the target of interest
 * @param key The key of the value to be updated
 * @param value The value to update to
 * @param reviewRules An optional parameter to pass if the value being set affects a sample's computed fields (overallResult, passed, failureReason)
 *
 * @returns {Array<SampleReview> | undefined}
 */
export function findAndSetTarget(
  samples: Array<SampleReview>,
  sampleName: string,
  targetId: string,
  key: string,
  value: boolean | number | string | null,
  reviewRules?: ReviewRule
): Array<SampleReview> | undefined {
  const sampleIndex = samples.findIndex(sample => {
    return sample.sampleName === sampleName;
  });

  let newSamples: SampleReview[] | undefined;
  if (sampleIndex > -1) {
    const sample = samples[sampleIndex];
    const { targets } = sample;

    const targetIndex = targets.findIndex(target => {
      return target.targetId === targetId;
    });

    if (targetIndex > -1) {
      const newTarget = { ...targets[targetIndex] };
      newTarget[key] = value;

      const newTargets = [...targets];
      newTargets[targetIndex] = newTarget;

      let newSample = { ...sample, targets: newTargets };

      if (reviewRules) {
        newSample = localFn.updateSampleResult(newSample, reviewRules);
      }

      newSamples = localFn.copySamples(samples, newSample, sampleIndex);
    }
  }

  return newSamples;
}

/**
 * Helper to set the cqOverride value for all targets in a given array of sample reviews
 *
 * @param samples The array of samples for which we want to set target.cqOverride values
 * @param cqOverride The value to set all target's cqOverride value to, false -> approved,
 * true -> overriden and null -> initial state
 *
 * @returns {Array<SampleReview>}
 */
export function setAllTargetsCqOverride(
  samples: Array<SampleReview>,
  cqOverride: boolean | null,
  reviewRules: ReviewRule
): Array<SampleReview> {
  const newSamples: Array<SampleReview> = [];
  samples.forEach(sample => {
    let newSample: SampleReview = { ...sample };

    if (!sample.blank) {
      const { targets } = sample;

      const newTargets: Array<TargetReview> = [];

      targets.forEach(target => {
        const newTarget: TargetReview = {
          ...target,
          cqOverride,
        };

        newTargets.push(newTarget);
      });

      const { failureReason, passed, overallResult } =
        localFn.computeSampleResult(sample, reviewRules);

      newSample = {
        ...sample,
        passed,
        overallResult,
        failureReason,
        targets: newTargets,
      };
    }

    newSamples.push(newSample);
  });

  return newSamples;
}

/** ****************************************************
 * RESULT COMPUTATION & RULE EVALUATION
 **************************************************** */

/**
 * Utility to compute the overall run result by searching samples for failures
 *
 * @param newlyComputedSamples The recently update array of sample reviews, that needs to be
 * checked for any potential control failure changes
 *
 */
export function computeRunResult(newlyComputedSamples: Array<SampleReview>): {
  passed: boolean;
  failureReason: Array<string>;
} {
  const result: { passed: boolean; failureReason: Array<string> } = {
    passed: true,
    failureReason: [],
  };

  let allSamplesComplete = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const sample of newlyComputedSamples) {
    if (!localFn.allSampleCqsApproved(sample)) {
      allSamplesComplete = false;
      break;
    }
  }

  if (allSamplesComplete) {
    const failingSamples = newlyComputedSamples.filter(sample => {
      return !sample.passed;
    });

    if (failingSamples.length > 0) {
      result.passed = false;
      result.failureReason = failingSamples.map(sample => {
        return sample.failureReason || "";
      });
    }
  }

  return result;
}

/** ****************************************************
 * INITIALIZERS & PARSERS
 **************************************************** */

/**
 * Utility called by getEmptySamples to generate all intial TargetReview instances
 *
 * @param targets The array of all targets with base fields (non-review fields)
 */
export function getEmptyTargets(
  targets: Array<BaseTarget>
): Array<TargetReview> {
  return targets.map(target => {
    return {
      targetId: target.targetId,
      name: target.name,
      cq: target.cq,
      cqOverride: null,
      overridenCq: target.cq,
      comments: "",
    };
  });
}

/**
 * Utility called by getEmptyReview to generate all initial SampleReview instances
 *
 * @param samples The array of all samples with base fields (non-review fields) and their array of targets
 */
export function getEmptySamples(
  samples: Array<BaseSample>,
  fullRunData: RunData
): Array<SampleReview> {
  return samples
    .map(sample => {
      const sampleReview: SampleReview = {
        sampleId: sample.sampleRecordId || "",
        sampleName: sample.sampleName,
        wellNumber: localFn.getWellNumber(sample, fullRunData),
        sampleType: "",
        passed: true,
        blank: false,
        overallResult: PENDING,
        failureReason: null,
        comments: "",
        targets: localFn.getEmptyTargets(sample.targets),
      };

      return sampleReview;
    })
    .sort((a, b) => {
      let result = -1;
      if (a.wellNumber > b.wellNumber) {
        result = 1;
      }

      return result;
    });
}

/**
 * Utility to return an initial RunReview instance if no reviews exist for an active run
 *
 * @param runId The id of the run
 * @param protocolId The id of the protocol used
 * @param reviewerId The id of the reviewer/active user
 * @param reviewerRole The role of the reviewer, user or admin
 * @param pcrEquipmentId The serial number of the device used for the run
 * @param ruleId The id of the associated review rule used
 * @param samples The array of all samples with base fields (non-review fields) and their array of targets
 */
export function getEmptyReview(
  runId: string,
  protocolId: string,
  reviewerId: string,
  reviewerRole: ReviewUserRole,
  pcrEquipmentId: string,
  rules: ReviewRule,
  samples: Array<BaseSample>,
  fullRunData: RunData
): RunReview {
  const sampleReviews = localFn.getEmptySamples(samples, fullRunData);

  const runReview: RunReview = {
    runId,
    protocolId,
    reviewerId,
    reviewerRole,
    pcrEquipmentId,
    ruleId: rules.id,
    experimentId: null,
    comments: "",
    passed: true,
    failureReason: [],
    samples: sampleReviews,
  };

  return runReview;
}

/**
 * Utility to parse backend GET /reviews response into a RunReview instance
 * for a new submission
 *
 * @param runReview The raw response from backend
 * @param fullRunData The full run data associated with the review
 * @param userId The current active user UUID
 * @param userRole The current active user's role (admin or user)
 */
export function parseRawRunReview(
  runReview: RawRunReview,
  rules: ReviewRule,
  fullRunData: RunData,
  userId: string,
  userRole: ReviewUserRole
): RunReview {
  let failureReason: string[] = [];
  if (runReview.failureReason) {
    failureReason = runReview.failureReason.split(",");
  }

  const parsedReview: RunReview = {
    runId: runReview.runId,
    ruleId: runReview.ruleId,
    protocolId: runReview.protocolId,
    reviewerId: userId,
    reviewerRole: userRole,
    pcrEquipmentId: runReview.pcrEquipmentId,
    passed: runReview.passed,
    failureReason,
    experimentId: runReview.experimentId,
    comments: runReview.comments,
    samples: localFn.parseRawSampleReviews(
      runReview.samples,
      rules,
      fullRunData
    ),
  };

  return parsedReview;
}

/**
 * Helper to format a Date object to a string in YYYY-mm-dd
 * format
 * @param date The Date instance to format
 * @returns The YYYY-mm-dd formatted date string
 */
export function formatDateString(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString();

  const values = [year];

  let formattedMonth = month.toString();
  if (month < 10) {
    formattedMonth = `0${month}`;
  }

  let formattedDay = day.toString();
  if (day < 10) {
    formattedDay = `0${day}`;
  }

  values.push(formattedMonth);
  values.push(formattedDay);

  return values.join("-");
}

/**
 * Utility to parse run data from by-target to target-by-sample
 * @param runData The complete run data
 *
 * @returns {Array<BaseSample>}
 */
export function getRunTargetsBySample(runData: RunData): Array<BaseSample> {
  const { targets } = runData;

  const targetsBySample: BaseSample[] = [];
  targets.forEach(target => {
    const { CQ, id, name, sampleId, sampleRecordId } = target;

    const parentSample = targetsBySample.find(sample => {
      return sample.sampleName === sampleId;
    });

    if (parentSample) {
      parentSample.targets.push({
        targetId: id as string,
        name,
        cq: CQ,
      });
    } else {
      targetsBySample.push({
        sampleName: sampleId,
        sampleRecordId: sampleRecordId || "",
        targets: [{ targetId: id as string, name, cq: CQ }],
      });
    }
  });

  return targetsBySample;
}

/** ****************************************************
 * API UTILITIES
 **************************************************** */

/**
 * Function to set the cq value to the overridenCq value before posting to API
 *
 * @param samples The run review samples
 * @returns {Array<SampleReview>} The samples as is, with target cq values set to the overriden value, if cqOverride is true
 */
export function setCqToOverriddenCq(
  samples: Array<SampleReview>
): Array<SampleReview> {
  const newSamples = samples.map(sample => {
    const { blank } = sample;
    let { targets } = sample;

    if (!blank) {
      targets = targets.map(target => {
        let { cq } = target;
        const { cqOverride, overridenCq } = target;

        if (cqOverride) {
          cq = overridenCq;
          const newTarget = { ...target, cq };

          return newTarget;
        }

        return target;
      });

      const newSample = { ...sample, targets };

      return newSample;
    }

    return sample;
  });

  return newSamples;
}
/**
 * Used to take a string formatted date object from something
 * like `new Date()` and gets the integer values from the
 * date instance.
 *
 * @param {Date} date
 * @return {*}  {number[]}
 */
export function convertDateInstanceForComparison(date: Date): number[] {
  const day = date.getDate();
  const month = date.getMonth(); // Remember JS Date() month is zero based
  const year = date.getFullYear();

  return [year, month, day];
}

/**
 * Used to compare the integer values taken from date objects
 * for comparison
 *
 * @param {number[]} dateNumberArray1
 * @param {number[]} dateNumberArray2
 * @return {*}  {boolean}
 */
export function compareDeconstructedDate(
  dateNumberArray1: number[],
  dateNumberArray2: number[]
): boolean {
  const [dateNumberArray1Year, dateNumberArray1Month, dateNumberArray1Day] =
    dateNumberArray1;

  const [dateNumberArray2Year, dateNumberArray2Month, dateNumberArray2Day] =
    dateNumberArray2;

  const doesDayMatch = dateNumberArray1Day === dateNumberArray2Day;
  const doesMonthMatch = dateNumberArray1Month === dateNumberArray2Month;
  const doesYearMatch = dateNumberArray1Year === dateNumberArray2Year;

  return doesDayMatch && doesMonthMatch && doesYearMatch;
}

/**
 * Utility to filter pending runs to the date given and needing admin approval
 *
 * @param runs The pending run data from the API
 * @param date The date to filter by
 */
export function processBulkReviewData(
  runs: PendingRun[],
  date: Date
): PendingRun[] {
  return runs.filter(run => {
    let filterResult = false;

    if (run.reviewDate) {
      const reviewDate = new Date(run.reviewDate);
      const reviewDateNumberArray =
        localFn.convertDateInstanceForComparison(reviewDate);

      const selectedDate = new Date(date);
      const selectedDateNumberArray =
        localFn.convertDateInstanceForComparison(selectedDate);

      filterResult = localFn.compareDeconstructedDate(
        reviewDateNumberArray,
        selectedDateNumberArray
      );
    }

    return filterResult;
  });
}

/**
 * Utility to get the date from a week ago
 */
export function getSevenDaysAgoDate(): Date {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return sevenDaysAgo;
}

/**
 *  Finds the review that is referenced in PendingRunData from the
 *  Review Dashboard
 *
 * @export
 * @param {(string | null)} topLevelRunReviewId
 * @param {ExistingReviewData} existingReviewData
 * @return {(RawRunReview | null)}  {(RawRunReview | null)}
 */
export function getMatchingReview(
  topLevelRunReviewId: string | null,
  existingReviewData: ExistingReviewData
): RawRunReview | null {
  let matchedReview: RawRunReview | null = null;

  const { reviews, latestReview } = existingReviewData;
  const { id: latestReviewId } = latestReview;

  if (topLevelRunReviewId) {
    if (topLevelRunReviewId === latestReviewId) {
      matchedReview = latestReview;
    } else {
      const matchingReview = reviews.filter(review => {
        const { id: existingReviewId } = review;

        return existingReviewId === topLevelRunReviewId;
      });

      if (matchingReview.length > 0) {
        [matchedReview] = matchingReview;
      }
    }
  }

  return matchedReview;
}

/**
 * Formats samples from a RawRunReview into Bulk Samples.
 * Bulk Samples are used in bulk reviewing.
 *
 * @export
 * @param {RawRunReview} matchedReview
 * @return {*}  {BulkSamples[]}
 */
export function formatBulkSamples(matchedReview: RawRunReview): BulkSamples[] {
  const { samples, runId, id: reviewId, run: runName } = matchedReview;

  return samples.map(sample => {
    const bulkSample = {
      ...sample,
      runId,
      reviewId,
      runName,
    };

    return bulkSample;
  });
}
/**
 * Filters Bulk Samples for Positive Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkPositiveSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.overallResult.toLowerCase() === POSITIVE;
  });
}

/**
 * Filters Bulk Samples for Negative Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkNegativeSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.overallResult.toLowerCase() === NEGATIVE;
  });
}

/**
 * Filters Bulk Samples for Inconclusive Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkInconclusiveSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.overallResult.toLowerCase() === INCONCLUSIVE;
  });
}

/**
 * Filters Bulk Samples for Positive Control Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkControlPositiveSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.sampleType === POSITIVE_CONTROL;
  });
}

/**
 * Filters Bulk Samples for Negative Control Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkControlNegativeSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.sampleType === NEGATIVE_CONTROL;
  });
}

/**
 * Filters Bulk Samples for NTC Bulk Samples
 *
 * @export
 * @param {BulkSamples[]} samples
 * @return {BulkSamples[]}  {BulkSamples[]}
 */
export function filterBulkNoTemplateControlSamples(
  samples: BulkSamples[]
): BulkSamples[] {
  return samples.filter(sample => {
    return sample.sampleType === NO_TEMPLATE_CONTROL;
  });
}

/**
 * Maps Bulk Sample Targets from data provided in BulkSamples
 *
 * @export
 * @param {BulkSamples[]} filteredBulkSamples
 * @return {MapBulkSampleTargetsReturn}  {MapBulkSampleTargetsReturn}
 */
export const mapBulkSampleTargets = (
  filteredBulkSamples: BulkSamples[]
): MapBulkSampleTargetsReturn => {
  let mappedBulkSamples: MappedBulkSampleTarget[] = [];
  const failedRunIds: string[] = [];

  filteredBulkSamples.forEach(sample => {
    const {
      runName,
      runId,
      targets,
      sampleName,
      sampleType,
      overallResult,
      comments,
    } = sample;

    let mappedTargets: MappedBulkSampleTarget[] = [];
    try {
      mappedTargets = targets.map(target => {
        const {
          targetId,
          cqOverride: sampleTargetCqOverride,
          cq: sampleTargetCq,
          sampleReviewId,
        } = target;

        return {
          runName,
          runId,
          sampleName,
          targetId,
          sampleTargetCqOverride,
          sampleTargetCq,
          sampleReviewId,
          sampleType,
          overallResult,
          comments,
        };
      });
    } catch (e) {
      failedRunIds.push(runId);
      CloudLog.error(`Error Mapping Bulk Sample Targets for run ${runId}`);
    }

    mappedBulkSamples = [...mappedBulkSamples, ...mappedTargets];
  });

  const dedupedFailedRunIds = [...new Set(failedRunIds)];

  const failedFilteredMappedBulkSampleTargets = mappedBulkSamples.filter(
    sampleTarget => {
      return !dedupedFailedRunIds.includes(sampleTarget.runId);
    }
  );

  return {
    mappedBulkSampleTargets: failedFilteredMappedBulkSampleTargets,
    failedRunIds: dedupedFailedRunIds,
  };
};

/**
 * Takes data from various Details Versions and creates a universal object for
 * use in bulk review
 *
 * @export
 * @param {BulkFullRunDataTypes} bulkFullRunData
 * @return {MapBulkFullRunDataReturn}  {MapBulkFullRunDataReturn}
 */
export const mapBulkFullRunData = (
  bulkFullRunData: BulkFullRunDataTypes
): MapBulkFullRunDataReturn => {
  let mappedData: MappedBulkFullRunDataTarget[] = [];
  const failedRunIds: string[] = [];

  bulkFullRunData.forEach(run => {
    const { detailsVersion, targets, id: runId } = run;

    let mappedTargets: MappedBulkFullRunDataTarget[] = [];
    try {
      mappedTargets = targets.map(target => {
        const { emissionColor, id: targetId } = target;

        let bulkFullRunDataTargetCQ = null;
        if ((target as RunDataTarget).CQ) {
          bulkFullRunDataTargetCQ = (target as RunDataTarget).CQ;
        } else if ((target as BulkFullRunDataTargetTypesRunDataExcluded).cq) {
          bulkFullRunDataTargetCQ = (
            target as BulkFullRunDataTargetTypesRunDataExcluded
          ).cq;
        }

        let bulkFullRunDataTargetName = "";
        if ((target as RunDataTarget).name) {
          bulkFullRunDataTargetName = (target as RunDataTarget).name;
        }

        let newWell = "";
        if ((target as BulkFullRunDataTargetTypesRunDataExcluded).well) {
          newWell = (target as BulkFullRunDataTargetTypesRunDataExcluded).well;
        }

        let newSq: number | null = null;
        if ((target as BulkFullRunDataTargetTypesRunDataExcluded).sq) {
          newSq = (target as BulkFullRunDataTargetTypesRunDataExcluded)
            .sq as number;
        }

        let abbreviation = "";
        if (
          (target as BulkFullRunDataTargetTypesRunDataExcluded).details
            ?.abbreviation
        ) {
          abbreviation = (target as BulkFullRunDataTargetTypesRunDataExcluded)
            .details.abbreviation;
        }

        return {
          detailsVersion,
          bulkFullRunDataTargetCQ,
          bulkFullRunDataTargetName,
          well: newWell,
          emissionColor,
          targetId,
          sq: newSq,
          abbreviation,
          runId,
        };
      });
    } catch (e) {
      failedRunIds.push(runId);
      CloudLog.error(`Error Mapping Bulk Full Run Data for run ${runId}`);
    }

    mappedData = [...mappedData, ...mappedTargets];
  });

  const dedupedFailedRunIds = [...new Set(failedRunIds)];

  const failedFilteredMappedData = mappedData.filter(target => {
    return !failedRunIds.includes(target.runId);
  });

  return {
    mappedBulkFullRunData: failedFilteredMappedData,
    failedRunIds: dedupedFailedRunIds,
  };
};

/**
 * Flips off active on BulkRunBlocks. Utility for BulkRun selection
 *
 * @export
 * @param {BulkRunBlock[]} runBlocks
 * @param {string} runId
 * @return {BulkRunBlock[]}  {BulkRunBlock[]}
 */
export const filterAndDeactivateRunBlocks = (
  runBlocks: BulkRunBlock[],
  runId: string
): BulkRunBlock[] => {
  return runBlocks.map(runBlock => {
    if (runBlock.runId === runId) {
      return {
        ...runBlock,
        active: false,
      };
    }

    return {
      ...runBlock,
    };
  });
};

/**
 * Flips on active on BulkRunBlocks. Utility for BulkRun selection
 *
 * @export
 * @param {BulkRunBlock[]} runBlocks
 * @param {string} runId
 * @return {BulkRunBlock[]}  {BulkRunBlock[]}
 */
export const filterAndActivateRunBlocks = (
  runBlocks: BulkRunBlock[],
  runId: string
): BulkRunBlock[] => {
  return runBlocks.map(runBlock => {
    if (runBlock.runId === runId) {
      return {
        ...runBlock,
        active: true,
      };
    }

    return {
      ...runBlock,
    };
  });
};

/**
 * Combines Bulk Sample Targets with Bulk Full Run Data to have
 * one set of targets.
 *
 * @export
 * @param {MappedBulkSampleTarget[]} mappedBulkSampleTargets
 * @param {MappedBulkFullRunDataTarget[]} mappedBulkFullRunDataTargets
 * @return {*}  {CombinedBulkTarget[]}
 */
export const combineSampleTargetsWithBulkFullRunData = (
  mappedBulkSampleTargets: MappedBulkSampleTarget[],
  mappedBulkFullRunDataTargets: MappedBulkFullRunDataTarget[]
): CombinedBulkTargetsReturn => {
  const failedRunIds: string[] = [];

  const rawCombinedBulkTargets: Array<CombinedBulkTarget | null> =
    mappedBulkSampleTargets.map(sampleTarget => {
      const { targetId, runId } = sampleTarget;

      let combinedTarget: CombinedBulkTarget | null = null;

      try {
        const [matchedTarget] = mappedBulkFullRunDataTargets.filter(target => {
          return target.targetId === targetId;
        });

        if (!matchedTarget) {
          failedRunIds.push(runId);
          CloudLog.error(
            `Error CombineSampleTargetsWithBulkFullRunData for run ${runId}: !matchedTarget`
          );
        } else {
          combinedTarget = {
            ...sampleTarget,
            ...matchedTarget,
          };
        }
      } catch {
        failedRunIds.push(runId);
        CloudLog.error(
          `Error CombineSampleTargetsWithBulkFullRunData for run ${runId}`
        );
      }

      return combinedTarget;
    });

  const dedupedFailedRunIds = [...new Set(failedRunIds)];

  const nullFilteredTargets: CombinedBulkTarget[] =
    rawCombinedBulkTargets.filter(target => {
      return target !== null;
    }) as CombinedBulkTarget[];

  const failedFilteredTargets = nullFilteredTargets.filter(target => {
    return !failedRunIds.includes(target.runId);
  });

  return {
    combinedBulkTargets: failedFilteredTargets,
    failedRunIds: dedupedFailedRunIds,
  };
};

/**
 * Formats and creates CombinedBulkTargets for Bulk Tabular Data
 *
 * @param {CombinedBulkTarget[]} combinedTargets
 * @return {*}  {BulkTabularDataTarget[]}
 */
export const createBulkTabularDataTargets = (
  combinedTargets: CombinedBulkTarget[]
): CreateBulkTabularDataTargetsReturn => {
  const failedRunIds: string[] = [];

  const rawBulkTabularDataTargets = combinedTargets.map(
    (target: CombinedBulkTarget) => {
      const { runId } = target;
      let formatted = null;

      try {
        const {
          emissionColor: targetChannel,
          sampleTargetCq: targetCQ,
          sampleName: targetSampleId,
          runName,
          targetId,
          sampleType: targetSampleType,
          sq,
          abbreviation,
          overallResult,
          comments,
        } = target;

        const channel =
          targetChannel.charAt(0).toUpperCase() + targetChannel.slice(1);

        let sampleType = "";
        if (targetSampleType.length > 0) {
          sampleType = targetSampleType;
        }

        let sampleId = "";
        if (targetSampleId) {
          sampleId = targetSampleId;
        }

        let parsedWell;
        const wellContainsLetters = checkIfWellContainsLetters(target.well);
        if (wellContainsLetters) {
          parsedWell = `${channel} ${target.well}`;
        } else {
          parsedWell = `${channel} ${target.well + 1}`;
        }

        let CQ = "";
        if (targetCQ) {
          CQ = targetCQ.toFixed(2);
        }

        let SQ = null;
        if (sq) {
          SQ = sq;
        }

        let newAbbreviation = "";
        if (abbreviation) {
          newAbbreviation = abbreviation;
        }

        formatted = {
          run: runName,
          CQ,
          channel: targetChannel,
          well: parsedWell,
          target: newAbbreviation,
          targetId,
          SQ,
          sampleId,
          leftVal: null,
          rightVal: null,
          sampleType,
          runId,
          overallResult,
          comments,
        };
      } catch (e) {
        failedRunIds.push(runId);
        CloudLog.error(`Error Mapping Bulk Sample Targets for run ${runId}`);
      }

      return formatted;
    }
  );

  const nullFilteredBulkTabularDataTargets: BulkTabularDataTarget[] =
    rawBulkTabularDataTargets.filter(target => {
      return target !== null;
    }) as BulkTabularDataTarget[];

  const dedupedFailedRunIds = [...new Set(failedRunIds)];

  const failedFilteredBulkTabularDataTargets =
    nullFilteredBulkTabularDataTargets.filter(target => {
      return !dedupedFailedRunIds.includes(target.runId);
    });

  return {
    tabularDataTargets: failedFilteredBulkTabularDataTargets,
    failedRunIds,
  };
};

/**
 * Helper function to pull out a lot of verbose logic and array manipulation for
 * BulkFullRunDataTargets
 *
 * @export
 * @param {BulkFullRunDataByDetailsVersion} bulkFullRunData
 * @return {ConstructMappedBulkFullRunDataTargetsReturn}  {ConstructMappedBulkFullRunDataTargetsReturn}
 */
export const constructMappedBulkFullRunDataTargets = (
  bulkFullRunData: BulkFullRunDataByDetailsVersion
): ConstructMappedBulkFullRunDataTargetsReturn => {
  const {
    mappedBulkFullRunData: biomeme1BulkFullRunData,
    failedRunIds: biomeme1FailedRunIds,
  } = localFn.mapBulkFullRunData(bulkFullRunData["biomeme-1"]);
  const {
    mappedBulkFullRunData: biomeme2BulkFullRunData,
    failedRunIds: biomeme2FailedRunIds,
  } = localFn.mapBulkFullRunData(bulkFullRunData["biomeme-2"]);
  const {
    mappedBulkFullRunData: ispBulkFullRunData,
    failedRunIds: ispFailedRunIds,
  } = localFn.mapBulkFullRunData(bulkFullRunData.isp);
  const {
    mappedBulkFullRunData: abiBulkFullRunData,
    failedRunIds: abiFailedRunIds,
  } = localFn.mapBulkFullRunData(bulkFullRunData.abi);
  const {
    mappedBulkFullRunData: bioradBulkFullRunData,
    failedRunIds: bioradFailedRunIds,
  } = localFn.mapBulkFullRunData(bulkFullRunData.biorad);

  const failedRunIds = [
    ...biomeme1FailedRunIds,
    ...biomeme2FailedRunIds,
    ...ispFailedRunIds,
    ...abiFailedRunIds,
    ...bioradFailedRunIds,
  ];

  const dedupedFailedRunIds = [...new Set(failedRunIds)];

  const mappedBulkFullRunDataTargets = [
    ...biomeme1BulkFullRunData,
    ...biomeme2BulkFullRunData,
    ...ispBulkFullRunData,
    ...abiBulkFullRunData,
    ...bioradBulkFullRunData,
  ];

  return { mappedBulkFullRunDataTargets, failedRunIds: dedupedFailedRunIds };
};

/**
 * Utility for returning an array of upper bound values from a non-recursive
 * target rule collection
 * @export
 * @param {TargetRuleCollection} logicalTargetRuleCollection
 * @return {*}  {number[]}
 */
export const collectUpperBoundValuesFromRuleCollection = (
  logicalTargetRuleCollection: TargetRuleCollection
): number[] => {
  const { targetRules } = logicalTargetRuleCollection;
  const arrayOfUpperBoundValues: number[] = [];

  targetRules.forEach((targetRule: TargetRule) => {
    const constraints = targetRule.constraints || { upper: null };

    if (
      constraints.upper &&
      typeof constraints.upper === "number" &&
      constraints.upper !== 0
    ) {
      arrayOfUpperBoundValues.push(constraints.upper);
    }
  });

  return arrayOfUpperBoundValues;
};

/**
 * Utility for returning an array of upper bound values from a generic
 * target rule collection
 *
 * @export
 * @param {LogicalTargetRuleCollection} logicalTargetRuleCollection
 * @return {*}  {number[]}
 */
export const collectUpperBoundValuesFromSampleRule = (
  logicalTargetRuleCollection: LogicalTargetRuleCollection
): number[] => {
  const arrayOfUpperBoundValues: number[] = [];

  if (isTargetRuleCollection(logicalTargetRuleCollection)) {
    arrayOfUpperBoundValues.push(
      ...localFn.collectUpperBoundValuesFromRuleCollection(
        logicalTargetRuleCollection
      )
    );
  }

  if (isRecursiveTargetRuleCollection(logicalTargetRuleCollection)) {
    const { logicalTargetRuleCollections } = logicalTargetRuleCollection;

    logicalTargetRuleCollections.forEach(ruleCollection => {
      arrayOfUpperBoundValues.push(
        ...localFn.collectUpperBoundValuesFromSampleRule(ruleCollection)
      );
    });
  }

  return arrayOfUpperBoundValues;
};
