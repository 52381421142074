import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import noUiSlider from "nouislider";

import {
  cyclesDisplayedSelectorContainer,
  cyclesDisplayedAxisContainer,
  cyclesXAxisStyle,
} from "./styles";

interface Props extends RouteComponentProps<any> {
  xAxis: any;
  lowerBound: number;
  upperBound: number;
}

const CyclesDisplayedSlider: React.FunctionComponent<any> = (props: Props) => {
  const { xAxis, lowerBound, upperBound } = props;
  const createCyclesDisplayedSlider = () => {
    const { length } = xAxis;
    const roundedXAxis = Math.ceil(length / 5) * 5;
    let localLowerBound = 0;
    let localUpperBound = length;
    if (lowerBound !== -1) {
      localLowerBound = lowerBound;
    }
    if (upperBound !== -1) {
      localUpperBound = upperBound;
    }
    const cyclesDisplayedSlider = document.getElementById(
      "cyclesDisplayedSlider"
    );
    if (cyclesDisplayedSlider && length !== 0) {
      noUiSlider.create(cyclesDisplayedSlider, {
        start: [localLowerBound, localUpperBound],
        connect: true,
        step: 5,
        tooltips: false,
        margin: 5,
        range: {
          min: 0,
          max: roundedXAxis,
        },
      });
    }
  };

  useEffect(() => {
    createCyclesDisplayedSlider();
  }, []);

  const _renderCycleAxis = () => {
    const { length } = xAxis;
    const roundedXAxis = Math.ceil(length / 5) * 5;
    const axisArray = [];
    for (let i = 0; i <= roundedXAxis; i += 5) {
      axisArray.push(i);
    }

    return (
      <div style={cyclesDisplayedAxisContainer}>
        {axisArray.map(cycle => (
          <span style={cyclesXAxisStyle} key={cycle}>{`${cycle}`}</span>
        ))}
      </div>
    );
  };

  return (
    <div style={cyclesDisplayedSelectorContainer}>
      {_renderCycleAxis()}
      <div id="cyclesDisplayedSlider" className="cycles-displayed" />
    </div>
  );
};

export default CyclesDisplayedSlider;
