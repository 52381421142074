import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Modal from "../../../Layout/Modal";
import { setReviewModeError } from "../../actions";
import { getReviewModeError } from "../../selectors";

class ReviewModeErrorModal extends Component {
  renderReviewModeErrorModal() {
    const { setNewReviewModeError, reviewModeError, history } = this.props;

    setTimeout(() => {
      setNewReviewModeError("");
      history.push("/data");
    }, 10000);

    return (
      <Modal
        open={Boolean(reviewModeError)}
        heading="An Error Has Occurred"
        onClose={() => {
          setNewReviewModeError("");
          history.push("/data");
        }}
        className="modal--run_detail"
        centered
      >
        <div
          style={{ maxHeight: "70vh", overflowY: "auto", marginBottom: "17px" }}
          className="review_error_modal__text"
        >
          {reviewModeError}
        </div>
      </Modal>
    );
  }

  render() {
    const { reviewModeError } = this.props;

    if (reviewModeError) {
      return this.renderReviewModeErrorModal();
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    reviewModeError: getReviewModeError(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewReviewModeError: () => {
      dispatch(setReviewModeError());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReviewModeErrorModal)
);
