export const BIORAD_DETAILS_VERSION = "biorad";
export const ABI_DETAILS_VERSION = "abi";

export const EXTERNAL_DETAILS_VERSIONS = [
  BIORAD_DETAILS_VERSION,
  ABI_DETAILS_VERSION,
];

export const EXTERNAL_DETAILS_VERSION_OPTIONS = [
  {
    value: BIORAD_DETAILS_VERSION,
    label: "BioRad",
  },
  {
    value: ABI_DETAILS_VERSION,
    label: "ABI",
  },
];
