import React, { Fragment } from "react";

import SampleNavigation from "./components/SampleNavigation";
import SampleHubTutorial from "./subcomponents/Tutorial";

const SampleHub = () => {
  return (
    <Fragment>
      <SampleHubTutorial />
      <SampleNavigation />
    </Fragment>
  );
};

export default SampleHub;
