export const UPDATE_USER_DETAILS_REQ = "settings/UPDATE_USER_DETAILS_REQ";
export type T_UPDATE_USER_DETAILS_REQ = typeof UPDATE_USER_DETAILS_REQ;

export const UPDATE_USER_DETAILS_ERROR = "settings/UPDATE_USER_DETAILS_ERROR";
export type T_UPDATE_USER_DETAILS_ERROR = typeof UPDATE_USER_DETAILS_ERROR;

export const UPDATE_USER_DETAILS_SUCCESS =
  "settings/UPDATE_USER_DETAILS_SUCCESS";
export type T_UPDATE_USER_DETAILS_SUCCESS = typeof UPDATE_USER_DETAILS_SUCCESS;

export const RESET_USER_DETAILS = "settings/RESET";
export type T_RESET_USER_DETAILS = typeof RESET_USER_DETAILS;

export const USER_SUBSCRIPTION_DETAILS_REQ =
  "settings/USER_SUBSCRIPTION_DETAILS_REQ";
export type T_USER_SUBSCRIPTION_DETAILS_REQ =
  typeof USER_SUBSCRIPTION_DETAILS_REQ;

export const USER_SUBSCRIPTION_DETAILS_SUCCESS =
  "settings/USER_SUBSCRIPTION_DETAILS_SUCCESS";
export type T_USER_SUBSCRIPTION_DETAILS_SUCCESS =
  typeof USER_SUBSCRIPTION_DETAILS_SUCCESS;

export const SUBMIT_BUG_REPORT_REQUEST = "settings/SUBMIT_BUG_REPORT_REQUEST";
export type T_SUBMIT_BUG_REPORT_REQUEST = typeof SUBMIT_BUG_REPORT_REQUEST;
