import React from "react";
import { connect } from "react-redux";

import { applyMasterFilters } from "../../../actions";
import { getActiveTeamVersion } from "../../../../Auth/selectors";
import { getTargetMatrix, getTargetData } from "../../../selectors";
import { getFullRunData } from "../../../../FullRunData/selectors";
import { extractSampleNames } from "../../../helpers";
import DropdownBox from "../../../../Layout/DropdownBox";
import { CHANNELS } from "../../../../Hub/constants";
import { UNSPECIFIED_SAMPLE_TEXT } from "../../../constants";
import { getSampleStylingParamters } from "./helpers";

const SampleSelector = props => {
  const { runTargets } = props;

  const deactivatedTargets = runTargets.filter(target => {
    return !target.hideTarget && !target.showLine;
  });

  const allWellsAreActive = deactivatedTargets.length < 1;

  const handleSampleSelection = (wellNumber, previousActiveState) => {
    const { updateMasterFilters } = props;
    const newTargets = { ...props.targets };

    CHANNELS.values.forEach(channel => {
      newTargets[channel].forEach((well, index) => {
        if (previousActiveState && allWellsAreActive) {
          if (well.well === wellNumber) {
            newTargets[channel][index].active = true;
          } else {
            newTargets[channel][index].active = false;
          }
        } else if (previousActiveState && !allWellsAreActive) {
          newTargets[channel][index].active = true;
        } else if (well.well === wellNumber) {
          newTargets[channel][index].active = true;
        } else {
          newTargets[channel][index].active = false;
        }
      });
    });

    updateMasterFilters(newTargets);
  };

  const formatSampleNames = () => {
    const { runs, detailsVersion } = props;
    const [run] = runs;

    return extractSampleNames(run, detailsVersion).map(sample => {
      const { wellNumber } = sample;
      let { sampleId } = sample;

      if (sampleId === UNSPECIFIED_SAMPLE_TEXT) {
        sampleId = `Well ${wellNumber + 1}`;
      }

      const deactivatedWell = deactivatedTargets.find(target => {
        return target.well === wellNumber;
      });

      const { wellActive, fontWeight, className } =
        getSampleStylingParamters(deactivatedWell);

      return (
        <div
          key={`${run.id}-${wellNumber}-${sampleId}-div`}
          className="sample_selector_grid_cell"
        >
          <span
            key={`${run.id} ${sampleId}-span`}
            id={`sample_selector-${sampleId}`}
            className={className}
            style={{ fontWeight }}
            onClick={() => {
              return handleSampleSelection(wellNumber, wellActive);
            }}
          >
            {sampleId}
          </span>
        </div>
      );
    });
  };

  const renderHeading = () => {
    return (
      <span
        className="sample_selector_block"
        data-tip="Select a sample to view in the chart."
      >
        Sample Selector
      </span>
    );
  };

  return (
    <DropdownBox heading={renderHeading()} borderOpened>
      <div style={{ columnCount: 3 }}>{formatSampleNames()}</div>
    </DropdownBox>
  );
};

const mapStateToProps = state => {
  return {
    runs: getFullRunData(state),
    detailsVersion: getActiveTeamVersion(state),
    targets: getTargetMatrix(state),
    runTargets: getTargetData(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMasterFilters: targets => {
      dispatch(applyMasterFilters(targets));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleSelector);
