import React from "react";

import { RadioButton } from "bio-web-components";

import { ProtocolModeProps } from "../types";

interface Props {
  protocolModeProps: ProtocolModeProps;
}

const ProtocolModeSelection = (props: Props) => {
  const { protocolModeProps } = props;
  const { protocolMode, setProtocolMode } = protocolModeProps;

  return (
    <div className="archive_form-protocol_selection">
      <RadioButton
        label="Create a Protocol"
        onClick={() => setProtocolMode("create")}
        checked={protocolMode === "create"}
      />
      <RadioButton
        label="Select a Protocol"
        onClick={() => setProtocolMode("select")}
        checked={protocolMode === "select"}
      />
    </div>
  );
};

export default ProtocolModeSelection;
