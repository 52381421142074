import React from "react";
import { Input, Row } from "bio-web-components";

const InitialDenature = props => {
  const { setValue, values, errors } = props;

  return (
    <div className="protocol_creation-section">
      <h3 className="protocol_creation-label">Initial Denature</h3>
      <div className="protocol_creation-box">
        <Row>
          <Input
            value={`${values.firstDenatureTemp}`}
            onChange={value => setValue("firstDenatureTemp", value)}
            error={errors.firstDenatureTemp}
            label="Set Temperature (°C)"
          />
          <Input
            value={`${values.firstDenatureTime}`}
            onChange={value => setValue("firstDenatureTime", value)}
            error={errors.firstDenatureTime}
            label="Set Time (secs)"
          />
        </Row>
      </div>
    </div>
  );
};

export default InitialDenature;
