import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRootFolders } from "../../../../Folders/actions";
import { updatePath } from "../../../../Hub/actions";
import { REVIEW_DASHBOARD_PATH } from "../../../../RunReview/constants";
import { resetSidebar } from "../../../../Sidebar/actions";

interface DispatchProps {
  resetPath: () => void;
  setDashboardPath: () => void;
}
type Props = DispatchProps;

export const BulkReviewBreadCrumbs: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { resetPath, setDashboardPath } = props;

  const _renderHomeCrumb = () => {
    return (
      <React.Fragment key="home">
        <Link
          to="/data"
          className="back"
          data-tip="Exit the chart and return to the homescreen."
          onClick={() => {
            return resetPath();
          }}
        >
          Home
        </Link>
      </React.Fragment>
    );
  };

  const _renderReviewDashboardCrumb = () => {
    return (
      <React.Fragment key="review-dashboard">
        {">"}
        <li>
          <Link
            to="/review-dashboard"
            data-place="bottom"
            onClick={() => {
              return setDashboardPath();
            }}
          >
            Review Dashboard
          </Link>
        </li>
      </React.Fragment>
    );
  };

  const _renderBulkReviewCrumb = () => {
    return (
      <React.Fragment key="bulk-review">
        {">"}
        <li>
          <Link to="/runs-page" data-place="bottom">
            Bulk Review
          </Link>
        </li>
      </React.Fragment>
    );
  };

  return (
    <div className="breadcrumbs">
      {_renderHomeCrumb()}
      <ul>
        {_renderReviewDashboardCrumb()}
        {_renderBulkReviewCrumb()}
      </ul>
    </div>
  );
};
function mapDispatchToProps(dispatch: any) {
  return {
    resetPath: () => {
      dispatch(resetSidebar());
      dispatch(getRootFolders());
    },
    setDashboardPath: () => {
      return dispatch(updatePath(REVIEW_DASHBOARD_PATH));
    },
  };
}

export default connect(null, mapDispatchToProps)(BulkReviewBreadCrumbs);
