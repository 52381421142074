import * as t from "./actionTypes";

export function loginUser(credentials, resolve, reject) {
  return {
    payload: credentials,
    resolve,
    reject,
    type: t.USER_LOGIN_REQ,
  };
}

export function loginUserError(err) {
  return {
    error: err,
    type: t.USER_LOGIN_ERR,
  };
}

export function loginUserSuccess(payload) {
  return {
    type: t.USER_LOGIN_SUCCESS,
    payload,
  };
}

// Logout Actions
export function logoutReq() {
  return {
    type: t.USER_LOGOUT_REQ,
  };
}

export function logoutUserError(err) {
  return {
    error: err,
    type: t.USER_LOGOUT_ERROR,
  };
}

export function logoutUserSuccess() {
  return {
    type: t.USER_LOGOUT_SUCCESS,
  };
}

// SAML Actions

export function authenticateSamlUser(resolve, reject) {
  return {
    type: t.AUTHENTICATE_SAML_USER,
    resolve,
    reject,
  };
}

// Register Actions
export function registerUserReq(user, profile) {
  return {
    type: t.USER_REGISTER_REQ,
    userInfo: user,
    profile,
  };
}

export function registerUserSuccess(data) {
  return {
    type: t.USER_REGISTER_SUCCESS,
    data,
  };
}

export function registerUserError(err) {
  return {
    type: t.USER_REGISTER_ERROR,
    error: err,
  };
}

// New Backend Integration testing
export function setToken(jwt) {
  return {
    type: t.SET_TOKEN,
    token: jwt,
  };
}

export function setCogUser(user) {
  return {
    type: t.SET_COG_USER,
    user,
  };
}

export function setActiveTeam(team) {
  return {
    type: t.SET_ACTIVE_TEAM_ID,
    teamId: team.id,
    name: team.name,
    version: team.type || "1",
    flags: team.flags,
  };
}

export function setTeams(teams) {
  return {
    type: t.SET_TEAMS,
    teams,
  };
}

export function setTokenTime(time) {
  return {
    type: t.SET_TOKEN_TIME,
    time,
  };
}

export function requestVerificationCode(user) {
  return {
    type: t.REQUEST_VERIFICATION_CODE,
    user,
  };
}

export function resetPassword(user) {
  return {
    type: t.RESET_PASSWORD,
    user,
  };
}

export function setGroup(group) {
  return {
    type: t.SET_USER_GROUP,
    group,
  };
}

export function setProvider(provider) {
  return {
    type: t.SET_PROVIDER,
    provider,
  };
}

export function userProviderLoginReq(provider, resolve, reject) {
  return {
    type: t.USER_PROVIDER_LOGIN_REQ,
    provider,
    resolve,
    reject,
  };
}

export function setSamlProcessing(isProcessing) {
  return {
    type: t.SET_SAML_PROCESSING,
    isProcessing,
  };
}

// Update Actions
export function userRefreshSessionSuccess(payload) {
  return {
    type: t.USER_REFRESH_SESSION_SUCCESS,
    payload,
  };
}
