import { BulkFullRunData } from "./types";

export const defaultFullRunDataBucket = {
  data: [],
  fetched: false,
};

export const defaultBulkFullRunDataObject: BulkFullRunData = {
  "biomeme-1": defaultFullRunDataBucket,
  "biomeme-2": defaultFullRunDataBucket,
  isp: defaultFullRunDataBucket,
  abi: defaultFullRunDataBucket,
  biorad: defaultFullRunDataBucket,
};
