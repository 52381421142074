import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ReactTooltip from "react-tooltip";

import Modal from "../../../Layout/Modal";
import { toggleExportReviewModal as toggleExportReviewModalAction } from "../../actions";
import { exportReviewSummary as exportReviewSummaryAction } from "../../../RunReview/actions";
import { getShowExportReviewModal } from "../../selectors";
import { tooltipDelay } from "../../constants";

interface StateProps {
  show: boolean;
}

interface DispatchProps {
  toggleExportReviewModal: () => void;
  postFeature: (feature: string) => void;
  exportReviewSummary: () => void;
}

interface Props {}

const ExportReviewModal = (props: Props & StateProps & DispatchProps) => {
  const { show, toggleExportReviewModal, exportReviewSummary } = props;

  return (
    <Modal
      open={show}
      heading="Download Review"
      onClose={toggleExportReviewModal}
      className="modal--export"
    >
      <p>
        Review exports will reflect the most recent submitted data. Review data
        will be exported in XLSX format.
      </p>
      <div className="modal__buttons">
        <button
          className="btn-modal btn-modal--download"
          data-tip="Summary review data is a results overview of review outcomes."
          onClick={exportReviewSummary}
        >
          Summary Review
        </button>
      </div>
      <ReactTooltip delayShow={tooltipDelay} />
    </Modal>
  );
};

function mapStateToProps(state: any) {
  return {
    show: getShowExportReviewModal(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleExportReviewModal: () => {
      dispatch(toggleExportReviewModalAction());
    },
    exportReviewSummary: () => dispatch(exportReviewSummaryAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportReviewModal);
