import React from "react";

const PCRIcon = props => {
  const { opacity } = props;

  return (
    <svg
      width={30}
      height={15}
      style={{
        marginRight: "10px",
        marginTop: "1px",
        opacity,
      }}
      {...props}
      className="pcr-run-icon run-icon"
      data-tip="PCR"
    >
      <g fillRule="nonzero" fill="none">
        <path fill="#2AD2C9" d="M0 0h28v14H0z" />
        <path
          d="M8.46 3.93c1.356 0 1.956 1.008 1.956 2.025s-.6 2.007-1.955 2.007H7.227v2.17H6.019V3.93H8.46zM8.4 6.864c.582 0 .8-.468.8-.909 0-.45-.218-.918-.783-.918h-1.19v1.827H8.4zm5.666 3.348c-1.695 0-3.042-1.422-3.042-3.186 0-1.746 1.347-3.168 3.042-3.168 1.016 0 1.903.504 2.441 1.287l-.92.693c-.34-.495-.844-.8-1.478-.8-1.086 0-1.877.881-1.877 1.988s.782 2.007 1.868 2.007c.643 0 1.147-.306 1.486-.81l.921.702a2.968 2.968 0 0 1-2.441 1.287zm6.447-.08l-1.425-2.224h-.408v2.223h-1.208v-6.2h2.39c1.373 0 1.963 1.007 1.963 2.024 0 .882-.443 1.674-1.45 1.89l1.555 2.286h-1.417zM18.68 5.036V6.9h.964c.678 0 .965-.423.965-.918 0-.495-.304-.945-.87-.945h-1.06z"
          fill="#012639"
        />
      </g>
    </svg>
  );
};

export default PCRIcon;
