import React from "react";
import { classNamesParser } from "../Utils/layoutUtils";

interface Props {
  show: boolean;
  onClick?: () => void;
}

class Loader extends React.Component<Props> {
  onClick() {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  }

  getClassName() {
    const { onClick, show } = this.props;

    return classNamesParser({
      loader: true,
      "loader--show": show,
      "loader--clickable": onClick,
    });
  }

  render() {
    return (
      <div className={this.getClassName()} onClick={() => this.onClick()}>
        <img src="/images/loader.gif" alt="" />
      </div>
    );
  }
}

export default Loader;
