import React from "react";
import { connect } from "react-redux";

import * as a from "../../../actions";
import {
  getRunBlocks,
  areSingleThresholdsEnabled,
  isBaselineChartActive,
  getTutorialRunsBlocksOpenState,
} from "../../../selectors";
import { isSelectedRunExternalUpload } from "../../../../Hub/selectors";
import RunBlockTable from "./runBlockTable";
import BioRadBlock from "./BioRad/BioRadBlock";

const RunBlockControls = props => {
  const {
    runBlocks,
    runsAreExternalUploads,
    toggleRunBlock,
    toggleRunThreshold,
    openDetailedModal,
    singleThresholdsEnabled,
  } = props;

  const isSingleRun = runBlocks.length < 2;

  const renderOriginalRunBlockSection = () => {
    return runBlocks.map((runBlock, index) => {
      const { runId } = runBlock;

      let className = "";
      if (index === 0) {
        className = "runblocks_block";
      }

      const determineIfShouldBeOpen = () => {
        const { tutorialRunBlockOpenState } = props;

        let shouldRunBlockBeOpen = null;

        if (tutorialRunBlockOpenState !== null) {
          const isOnlyBlockAndShouldBeOpen =
            isSingleRun && tutorialRunBlockOpenState;
          const isFirstBlockAndShouldBeOpen =
            index === 0 && tutorialRunBlockOpenState;

          if (isOnlyBlockAndShouldBeOpen || isFirstBlockAndShouldBeOpen) {
            shouldRunBlockBeOpen = true;
          }
        }

        return shouldRunBlockBeOpen;
      };

      return (
        <div key={runId} className={className}>
          <RunBlockTable
            runBlock={runBlock}
            runId={runId}
            isSingleRun={isSingleRun}
            runTableIndex={index}
            key={`runTable-${runId}`}
            toggleRunBlock={toggleRunBlock}
            toggleRunThreshold={toggleRunThreshold}
            openDetailedModal={openDetailedModal}
            singleThresholdsEnabled={singleThresholdsEnabled}
            opened={determineIfShouldBeOpen()}
          />
        </div>
      );
    });
  };

  const renderRunBlock = () => {
    if (runsAreExternalUploads) {
      return (
        <BioRadBlock
          isSingleRun={isSingleRun}
          runBlocks={runBlocks}
          toggleRunBlock={toggleRunBlock}
          toggleRunThreshold={toggleRunThreshold}
          openDetailedModal={openDetailedModal}
          singleThresholdsEnabled={singleThresholdsEnabled}
        />
      );
    }

    return renderOriginalRunBlockSection();
  };

  return <>{renderRunBlock()}</>;
};

function mapStateToProps(state) {
  return {
    runBlocks: getRunBlocks(state),
    runsAreExternalUploads: isSelectedRunExternalUpload(state),
    singleThresholdsEnabled: areSingleThresholdsEnabled(state),
    isBaselineActive: isBaselineChartActive(state),
    tutorialRunBlockOpenState: getTutorialRunsBlocksOpenState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleRunBlock: (active, runId) => {
      if (active) {
        dispatch(a.deactivateRunBlock(runId));
      } else {
        dispatch(a.activateRunBlock(runId));
      }
    },
    toggleRunThreshold: (active, runId) => {
      if (active) {
        dispatch(a.hideRunThresholds(runId));
      } else {
        dispatch(a.showRunThresholds(runId));
      }
    },
    openDetailedModal: () => {
      dispatch(a.openDetailedModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RunBlockControls);
