import {
  FOLDER_SELECTED,
  RESET_SIDEBAR,
  SHOW_APP_UPDATE_MODAL,
  HIDE_APP_UPDATE_MODAL,
} from "./actionTypes";

const initState = {
  selectedFolder: null,
  parentFolder: null,
  depth: 0,
  openedFolder: null,
  displayAppUpdateModal: false,
};

function sidebarReducer(state = initState, action) {
  switch (action.type) {
    case FOLDER_SELECTED: {
      const { selectedFolder, depth } = action;

      return { ...state, selectedFolder, depth, openedFolder: null };
    }
    case RESET_SIDEBAR: {
      return initState;
    }
    case SHOW_APP_UPDATE_MODAL: {
      return {
        ...state,
        displayAppUpdateModal: true,
      };
    }
    case HIDE_APP_UPDATE_MODAL: {
      return {
        ...state,
        displayAppUpdateModal: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default sidebarReducer;
