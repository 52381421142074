import React, { Component } from "react";

import { classNamesParser } from "../Utils/layoutUtils";

interface Props {
  onClose: () => void;
  open: boolean;
  heading: string | object;
  className?: string;
  centered?: boolean;
  modalBodyInnerStyle?: any;
  bodyClass?: string;
  children: any;
  style?: any;
}

export class Modal extends Component<Props> {
  getClassName() {
    const { open, centered } = this.props;

    return (
      // eslint-disable-next-line prefer-template
      classNamesParser({
        modal: true,
        "modal--open": open,
        "modal--centered": centered,
      }) +
      " " +
      this.getAdditionalClassName()
    );
  }

  getAdditionalClassName() {
    const { className } = this.props;

    if (className) {
      return className;
    }

    return null;
  }

  closeModal() {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  clickInner(e: any) {
    if (e.target.classList.contains("modal__inner")) {
      this.closeModal();
    }
  }

  renderModalHeading() {
    const { heading } = this.props;

    if (!heading) {
      return null;
    }

    return <span className="modal__heading">{heading}</span>;
  }

  renderModalBody() {
    const { modalBodyInnerStyle, bodyClass, children } = this.props;

    return (
      <div className={bodyClass || "modal__body"}>
        <div className="modal__body_inner" style={modalBodyInnerStyle}>
          <div className="modal__close" onClick={() => this.closeModal()}>
            close
          </div>
          {this.renderModalHeading()}
          {children}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="modal__overlay" onClick={() => this.closeModal()} />
        <div className="modal__inner" onClick={(e: any) => this.clickInner(e)}>
          {this.renderModalBody()}
        </div>
      </div>
    );
  }
}

export default Modal;
