import React from "react";

const BugReportHeader: React.FunctionComponent = () => {
  const biomemeContactInfo = `
  Biomeme, Inc.\n
  401 North Broad St, Suite 222\n
  Philadelphia, PA 19108\n
  support@biomeme.com\n
`;

  return (
    <>
      <div className="bug_report__header">Biomeme Cloud Bug Report</div>
      <p className="bug_report__header-text">
        Report a bug you encountered to our development team.
      </p>
      <div className="bug_report__header-text bug_report__multiline-text">
        {biomemeContactInfo}
      </div>
      <p className="bug_report__header-text bug_report__required_text">
        *Denotes a required field
      </p>
    </>
  );
};

export default BugReportHeader;
