import React, { useState, useEffect } from "react";

import { Row, ToggleSwitch } from "bio-web-components";
import { protocolCreationHelpers } from "js-common/lib/helpers";

import { ProtocolValidationErrors } from "../types";

interface ActiveChannelState {
  [key: string]: boolean;
  green: boolean;
  amber: boolean;
  red: boolean;
}

const initialState: ActiveChannelState = {
  green: true,
  amber: true,
  red: true,
};

interface Props {
  setColorMask: (colorMask: number) => void;
  errors: ProtocolValidationErrors;
}

const ActiveChannelSelector = (props: Props) => {
  const AVAILABLE_CHANNELS = ["green", "amber", "red"];

  const [activeChannels, setActiveChannels] = useState(initialState);

  useEffect(() => {
    const { computeColorMask } = protocolCreationHelpers;
    const { setColorMask } = props;

    const { green, amber, red } = activeChannels;

    setColorMask(computeColorMask(green, amber, red));
  }, [activeChannels]);

  const handleToggleChannel = (channel: string, active: boolean) => {
    setActiveChannels({ ...activeChannels, [channel]: active });
  };

  const _renderError = () => {
    const { errors } = props;
    const { colorMask: colorMaskError } = errors;

    if (colorMaskError) {
      return <div className="app_error">{colorMaskError}</div>;
    }

    return null;
  };

  return (
    <div className="protocol_creation-section">
      <h3 className="protocol_creation-label">Active Channels</h3>
      <div className="protocol_creation-box">
        <Row>
          {AVAILABLE_CHANNELS.map(channel => (
            <ToggleSwitch
              key={`${channel}-channel-toggle`}
              className="toggle_slider_position"
              label={channel.toUpperCase()}
              checked={activeChannels[channel]}
              onClick={() =>
                handleToggleChannel(channel, !activeChannels[channel])
              }
            />
          ))}
        </Row>
      </div>
      {_renderError()}
    </div>
  );
};

export default ActiveChannelSelector;
