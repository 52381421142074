const margin = { top: 20, right: 20, bottom: 50, left: 50 };

const constants = {
  margin: { top: 20, right: 20, bottom: 50, left: 50 },
  padding: { left: "60px", top: "10px" },
  width: 1200 - margin.left - margin.right,
  height: 600 - margin.top - margin.bottom,
  normalOpacity: "0.75",
  sliderMargin: 1,
  minHeight: 300,
  chartHeightAdjustment: 175,
  reviewFormHeight: 200,
  fallbackChartHeight: 480,
  tabularDataCqColumnIndex: 4,
};

export default constants;
