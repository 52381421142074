import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { getResetSuccess } from "../selectors";
import { resetPassword } from "../actions";
import Icon from "../../Utils/Layout/Icon";

class ForgotPasswordStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      email: "",
      verificationCode: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }

  componentDidMount() {
    this.setState({ errorMessage: "" });
  }

  handleVerificationSubmit = () => {
    const { email, verificationCode, newPassword, confirmNewPassword } =
      this.state;
    const { requestPasswordReset } = this.props;

    if (!verificationCode) {
      return this.setState({
        errorMessage: "Please enter the verification code found in your email.",
      });
    }

    if (!email) {
      return this.setState({
        errorMessage: "Please enter your email address.",
      });
    }

    if (!newPassword || !confirmNewPassword) {
      return this.setState({
        errorMessage: "Please complete all password fields.",
      });
    }

    if (newPassword !== confirmNewPassword) {
      return this.setState({
        errorMessage: "Please ensure that your passwords match.",
      });
    }

    const passwordValidator =
      /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\][{}()_+\-=|'])[\w!@#$%^&*\][{}()_+\-=|']{8,}$/;

    if (newPassword && !passwordValidator.test(newPassword)) {
      return this.setState({
        errorMessage: `Your password does not meet the requirements. Please ensure that it consists of at least eight characters, including one number and one uppercase, one lowercase, and one special character.`,
      });
    }

    const user = {
      email: email.trim(),
      code: verificationCode.trim(),
      password: newPassword.trim(),
    };

    this.setState({ errorMessage: "" });

    return requestPasswordReset(user);
  };

  renderVerificationForm = () => {
    return (
      <React.Fragment>
        <div className="password_form__verification">
          <p className="password__instructions">
            Please check your email for a verification code then enter that
            code, and your new password, below.
          </p>
          <p className="password_form__label">Verification Code</p>
          <div className="password_form__input_wraper">
            <Icon name="user" className="password_form__icon" />
            <input
              key="forgot_password_verification-code"
              className="password_form__input"
              type="text"
              placeholder="Verification Code"
              onChange={e =>
                this.setState({ verificationCode: e.target.value })
              }
            />
          </div>
        </div>
        <div className="password_form__verification">
          <p className="password_form__label">Email</p>
          <div className="password_form__input_wraper">
            <Icon name="user" className="password_form__icon" />
            <input
              key="forgot_password_email"
              className="password_form__input"
              type="text"
              placeholder="Email"
              onChange={e => this.setState({ email: e.target.value })}
            />
          </div>
        </div>
        <div className="password_form__verification">
          <p className="password_form__label">New Password</p>
          <div className="password_form__input_wraper">
            <Icon name="user" className="password_form__icon" />
            <input
              key="forgot_password_new-password"
              className="password_form__input"
              type="password"
              placeholder="New Password"
              onChange={e => this.setState({ newPassword: e.target.value })}
            />
          </div>
        </div>
        <div className="password_form__verification">
          <p className="password_form__label">Confirm New Password</p>
          <div className="password_form__input_wraper">
            <Icon name="user" className="password_form__icon" />
            <input
              key="forgot_password_confirm-new-password"
              className="password_form__input"
              type="password"
              placeholder="Confirm New Password"
              onChange={e =>
                this.setState({ confirmNewPassword: e.target.value })
              }
            />
          </div>
          {`Must contain at least eight characters, including one number and
              one uppercase, one lowercase, and one of the following characters: ! @ # $ % ^ & * ( ) _ + - = [ ] { } | '`}
          {this.renderErrorMessages()}
        </div>
        <div className="password_form__submit_row">
          <button
            className="password_form__submit_button"
            onClick={() => this.handleVerificationSubmit()}
          >
            Reset Password
          </button>
        </div>
      </React.Fragment>
    );
  };

  renderErrorMessages() {
    const { errorMessage } = this.state;
    const { resetSuccess } = this.props;

    let errorMessaging = null;
    if (errorMessage) {
      errorMessaging = (
        <p className="password__validation-warning">{errorMessage}</p>
      );
    }

    let successMessaging = null;
    if (resetSuccess) {
      successMessaging = (
        <p className="password__success-notification">
          Your password has been reset. <Link to="/">Click here</Link> to return
          to the login page.
        </p>
      );
    }

    return (
      <div style={{ marginTop: "10px" }}>
        {errorMessaging}
        {successMessaging}
      </div>
    );
  }

  render() {
    return (
      <div className="password">
        <div className="password__title">
          <h1>Reset Password</h1>
        </div>
        {this.renderVerificationForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetSuccess: getResetSuccess(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    requestPasswordReset: user => dispatch(resetPassword(user)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordStep2)
);
