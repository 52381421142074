/* 
All ReCAPTCHA keys are public
We use one for development and one for production to avoid training
the production key on potentially weird data, which would result in
increased friction for the user.
*/
export const RECAPTCHA_KEY_PRODUCTION = "6LcTpNEcAAAAACF5wBOZk6giv9AcIZo1J-It9tC9";
export const RECAPTCHA_KEY_DEVELOPMENT = "6LfTJtEcAAAAAJ4IsZIsaDoE-LqHAcqhJySYdF30";

export const BIO_MODE_DEV = "DEV";
export const BIO_MODE_RC = "RC";

export const OAUTH_DOMAIN_DEV = "dev-biomeme.auth.us-east-1.amazoncognito.com";
export const OAUTH_DOMAIN_RC = "biomeme.auth.us-east-1.amazoncognito.com";

export const AWS_REDIRECT_DOMAIN_LOCAL = "http://localhost:4444/";
export const AWS_REDIRECT_DOMAIN_DEV = "https://devportal.biomeme.com/";
export const AWS_REDIRECT_DOMAIN_RC = "https://rc-portal.biomeme.com/";
export const AWS_REDIRECT_DOMAIN_PROD = "https://cloud.biomeme.com/";

export const AWS_QUERY_BYTE_LIMIT = 2048;