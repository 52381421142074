import React from "react";

import { Select } from "bio-web-components";

import { CHANNELS } from "../../Hub/constants";
import { IBioradChannelSelection } from "../types";

interface Props {
  files: File[];
  channelSelections: IBioradChannelSelection;

  setChannel: (value: string, channel: string) => void;
}

const BioradChannelSelectors = (props: Props) => {
  const RESULTS_FILE_TEXT = "Amplification Results";

  const { setChannel, channelSelections, files } = props;

  const selectedFiles = Object.values(channelSelections);

  const availableFileOptions = files
    .filter(
      file =>
        file.name.includes(RESULTS_FILE_TEXT) &&
        !selectedFiles.includes(file.name)
    )
    .map(file => file.name);

  const _renderChannels = () =>
    CHANNELS.values.map(channel => {
      const fileSelection = channelSelections[channel];

      let options = [...availableFileOptions];

      if (fileSelection) {
        options.push(fileSelection);
      }

      return (
        <Select
          key={`Select-${channel}`}
          label={channel.toUpperCase()}
          options={options}
          value={fileSelection}
          onChange={(value: string) => setChannel(value, channel)}
        />
      );
    });

  return <>{_renderChannels()}</>;
};

export default BioradChannelSelectors;
