import * as t from "./actionTypes";

export function getRunData() {
  return {
    type: t.GET_RUN_REQ,
  };
}

export function getRunError(e) {
  return {
    type: t.GET_RUN_ERROR,
    error: e,
  };
}

export function getRunSuccess(payload) {
  return {
    type: t.GET_RUN_SUCCESS,
    payload,
  };
}

export function reset() {
  return {
    type: t.RESET,
  };
}

export function setActiveFolderData(data) {
  return {
    type: t.SET_ACTIVE_FOLDER_DATA,
    data,
  };
}
