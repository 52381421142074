export const tableHeaders = [
  "Field Name",
  "Type",
  "Default Value",
  "Optional?",
  "Delete",
];

export const desiredFieldOrder = [
  "parameterName",
  "type",
  "defaultValue",
  "optional",
  "delete",
];

export const sampleNavigationTemplateHeaders = ["LAYOUT NAME", "DATE CREATED"];
export const sampleNavigationRecordHeaders = [
  "SAMPLE NAME",
  "LAYOUT USED",
  "DATE CREATED",
];

export const headerKeyMap = {
  "SAMPLE NAME": "name",
  "LAYOUT NAME": "name",
  "DATE CREATED": "date",
  "LAYOUT USED": "templateName",
};

export const rowData = {
  parameterName: "",
  type: "text",
  defaultValue: "",
  optional: false,
  delete: null,
};

export const metadataTypeMap = {
  location: "location",
  date: "date",
  number: "number",
  text: "text",
};

export const placeholderTypeMap = {
  text: "Enter text here.",
  date: "YYYY-MM-DD",
  number: "1234",
  location: "Philadelphia, PA",
};

export const MAX_PARAMETERS = 20;
export const dateValidationRegex =
  /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/;
