import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { classNamesParser } from "../Utils/layoutUtils";
import { postFeature as postFeatureAction } from "../App/actions";

interface DispatchProps {
  postFeature: (feature: string) => void;
}

interface Props extends DispatchProps {
  heading?: any;
  preHeading?: string;
  firstOpened?: boolean;
  opened?: boolean | string;
  icons?: boolean | number | string | object;
  borderOpened?: boolean;
  icon?: boolean | number | string | object;
  toggle?: any;
  children?: any;
}

interface State {
  open: boolean;
}

export class DropdownBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.firstOpened || false,
    };
  }

  handleCqMetricsToggle() {
    const { heading, postFeature } = this.props;

    if (heading.props) {
      const { children } = heading.props;
      const { open } = this.state;
      if (open && children.includes("Cq")) {
        postFeature("Cq metrics");
      }
      if (open && children.includes("End")) {
        postFeature("end RFU metrics");
      }
    }
  }

  getClassNames() {
    const { borderOpened } = this.props;

    return classNamesParser({
      "dropdown-box": true,
      "dropdown-box--open": this.isDropDownOpen(),
      "dropdown-box--bordered": this.isDropDownOpen() && borderOpened,
    });
  }

  isDropDownOpen() {
    const { opened } = this.props;
    const { open } = this.state;
    if (typeof opened === "boolean") {
      return opened;
    }

    return open;
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState(
      {
        open: !open,
      },
      this.handleCqMetricsToggle
    );
  }

  renderHeader() {
    const { icon, toggle, heading } = this.props;

    return (
      <div
        className="dropdown-box__header"
        onClick={() => {
          return this.toggleOpen();
        }}
      >
        {this.renderPreHeading()}
        <h3
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          className="dropdown-box__heading"
        >
          {toggle} {this.renderIcons()} {icon} {heading}
        </h3>
      </div>
    );
  }

  renderBody() {
    const { children } = this.props;

    return <div className="dropdown-box__body">{children}</div>;
  }

  renderPreHeading() {
    const { preHeading } = this.props;
    if (!preHeading) {
      return null;
    }

    return <h4 className="dropdown-box__preHeading">{preHeading}</h4>;
  }

  renderIcons() {
    const { icons } = this.props;
    if (!icons) {
      return null;
    }

    return icons;
  }

  render() {
    return (
      <div className={this.getClassNames()}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    postFeature: (feature: string) => {
      return dispatch(postFeatureAction(feature));
    },
  };
}
export default connect(null, mapDispatchToProps)(DropdownBox);
