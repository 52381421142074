import { all, takeLatest, call, put, select } from "redux-saga/effects";
import Auth from "@aws-amplify/auth";
import { uniqBy } from "lodash";

import { backendApi } from "../Utils";
import {
  GET_ROOT_FOLDERS,
  GET_PAGINATED_CHILDREN_REQ,
  POST_FOLDER,
  GET_FOLDERS_ERR,
} from "./actionTypes";
import * as actions from "./actions";
import { FOLDER_SELECTED, RESET_SIDEBAR } from "../Sidebar/actionTypes";
import { superReset } from "../App/actions";
import { USER_LOGOUT_SUCCESS } from "../Auth/actionTypes";
import { getActiveTeamId, getActiveTeamVersion } from "../Auth/selectors";
import { selectFolder } from "../Sidebar/actions";
import { isDetailsVersionTwoOrGreater } from "../FullRunData/helpers";
import { ABI_DETAILS_VERSION, BIORAD_DETAILS_VERSION } from "../Hub/constants";
import history from "../store/history";
import CloudLog from "../Utils/CloudLog";

export function* getRootFolders() {
  try {
    const teamId = yield select(getActiveTeamId);
    const session = yield call([Auth, Auth.currentSession]);
    const jwt = session.idToken.jwtToken;
    backendApi.setHeader("Authorization", jwt);
    const apiCall = `/folders`;
    const apiRes = yield call(backendApi.get, apiCall, {
      teamId,
    });

    let { data } = apiRes;
    data = data || [];

    yield put(actions.getRootSuccess(data));
  } catch (e) {
    CloudLog.error(`Error in getRootFolders: ${e}`);
    yield put(actions.getFolderError("/settings"));
  }
}

export function* watchGetRootFolders() {
  yield takeLatest(GET_ROOT_FOLDERS, getRootFolders);
}

function parseBiomemeTwoChildren(data) {
  const { results = [] } = data || {};
  const { links } = data || {};

  return [{ results, links }];
}

function parseBiomemeOneChildren(data) {
  return [{ results: data, links: undefined }];
}

function* retrieveChildrenForDetailsVersion(
  apiEndpoint,
  teamId,
  detailsVersion
) {
  const { ok, data, status, problem } = yield call(
    backendApi.get,
    apiEndpoint,
    {
      teamId,
      detailsVersion,
    }
  );

  if (ok) {
    let results;
    if (isDetailsVersionTwoOrGreater(detailsVersion)) {
      results = parseBiomemeTwoChildren(data);
    } else {
      results = parseBiomemeOneChildren(data);
    }

    return results;
  }

  throw new Error(
    `Failed to retrieve ${detailsVersion} children with ${status}/${problem}`
  );
}

function* retrieveExternalChildren(apiEndpoint, teamId) {
  const [bioradChildren] = yield call(
    retrieveChildrenForDetailsVersion,
    apiEndpoint,
    teamId,
    BIORAD_DETAILS_VERSION
  );
  const [abiChildren] = yield call(
    retrieveChildrenForDetailsVersion,
    apiEndpoint,
    teamId,
    ABI_DETAILS_VERSION
  );
  const results = [...bioradChildren.results, ...abiChildren.results];
  const nextLinks = [];

  if (bioradChildren.links?.next) {
    nextLinks.push(bioradChildren.links.next);
  }
  if (abiChildren.links?.next) {
    nextLinks.push(abiChildren.links.next);
  }
  return { results, nextLinks };
}

export function* getFolderSelected(action) {
  try {
    const detailsVersion = yield select(getActiveTeamVersion);
    const { selectedFolder } = action;
    yield put(actions.getSelectedFolderReq(selectedFolder.id));
    const teamId = yield select(getActiveTeamId);
    const session = yield call([Auth, Auth.currentSession]);
    const jwt = session.idToken.jwtToken;
    backendApi.setHeader("Authorization", jwt);
    const apiEndpoint = `/folders/${selectedFolder.id}/children`;

    const [data] = yield call(
      retrieveChildrenForDetailsVersion,
      apiEndpoint,
      teamId,
      detailsVersion
    );

    let runData = [];
    if (data.results) {
      runData = [...data.results];
    }

    let nextLinks = [];
    if (data.links?.next) {
      nextLinks.push(data.links.next);
    }

    if (isDetailsVersionTwoOrGreater(detailsVersion)) {
      const externalChildren = yield call(
        retrieveExternalChildren,
        apiEndpoint,
        teamId
      );
      const { results: externalResults, nextLinks: externalNextLinks } =
        externalChildren;

      runData = uniqBy([...runData, ...externalResults], "id");

      if (externalNextLinks.length > 0) {
        nextLinks = [...nextLinks, ...externalNextLinks];
      }
    }

    if (runData.length > 0) {
      runData = runData.map(run => {
        if (detailsVersion === "biomeme-1") {
          if (run.kind === "run") {
            return {
              ...run,
              bleName: run.device,
            };
          }

          return { ...run };
        }

        return { ...run };
      });
    }

    yield put(actions.getSelectedSuccess(selectedFolder.id, runData));

    if (nextLinks.length > 0) {
      yield put(actions.getPaginatedChildrenReq(selectedFolder.id, nextLinks));
    }
  } catch (e) {
    CloudLog.error(`Error in getFolderSelected: ${e}`);
    yield put(actions.getFolderError());
  }
}

export function* watchFolderSelected() {
  yield takeLatest(FOLDER_SELECTED, getFolderSelected);
}

export function* getPaginatedChildren(action) {
  const { id, urlList } = action;

  try {
    let runData = [];
    let nextLinks = [];

    // Intentionally ignoring JS/TS Code Standard:
    // using for-of vice higher order function
    // because you cannot 'yield' in a 'forEach'
    for (const url of urlList) {
      const paginationURL = `/folders${url}`;
      const apiCall = yield call(backendApi.get, paginationURL);
      const { data } = apiCall;

      if (data) {
        const { results, links } = data;
        if (results) {
          runData = uniqBy([...runData, ...data.results], "id");
        }
        if (links?.next) {
          nextLinks.push(links.next);
        }
      }
    }

    yield put(actions.getSelectedSuccess(id, runData));

    if (nextLinks.length > 0) {
      yield put(actions.getPaginatedChildrenReq(id, nextLinks));
    }
  } catch (e) {
    CloudLog.error(`Error in getPaginatedChildren: ${e}`);
    yield put(actions.getFolderError());
  }
}

export function* watchPaginatedChildrenReq() {
  yield takeLatest(GET_PAGINATED_CHILDREN_REQ, getPaginatedChildren);
}

export function* resetFolders() {
  yield put(actions.reset());
}

export function* watchNavigationReset() {
  yield takeLatest(RESET_SIDEBAR, resetFolders);
}
export function* watchUserLogout() {
  yield takeLatest(USER_LOGOUT_SUCCESS, resetFolders);
}

function* addFolder(action) {
  const { teamId, folderId, folderName, parentFolder } = action;
  try {
    const { status } = yield call(
      backendApi.post,
      `/folders?teamId=${teamId}`,
      {
        id: folderId,
        name: folderName,
        parentId: parentFolder.id,
      }
    );
    if (status === 200) {
      yield put(selectFolder(parentFolder));
    } else {
      CloudLog.error(`Status ${status} in addFolder for folder id ${folderId}`);
    }
  } catch (e) {
    CloudLog.error(`Error in addFolder: ${e}`);
  }
}

function* watchAddFolder() {
  yield takeLatest(POST_FOLDER, addFolder);
}

function* handleGetFolderError(action) {
  yield put(superReset());

  const { recoveryRoute } = action;

  let state = {};
  if (recoveryRoute) {
    state = { route: recoveryRoute };
  }

  history.push("/error", state);
}

function* watchGetFolderError() {
  yield takeLatest(GET_FOLDERS_ERR, handleGetFolderError);
}

export default function* rootSaga() {
  yield all([
    watchGetRootFolders(),
    watchUserLogout(),
    watchFolderSelected(),
    watchPaginatedChildrenReq(),
    watchNavigationReset(),
    watchAddFolder(),
    watchGetFolderError(),
  ]);
}
