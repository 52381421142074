import React from "react";
import { connect } from "react-redux";

import { getShowChart } from "../Hub/selectors";
import { Content } from "./components/index";
import RunsContainer from "../RunsPage/components";
import ContentHeader from "../Hub/components/contentHeader";
import Loader from "../Layout/Loader";
import { getAnalysisMode } from "../RunsPage/selectors";
import BulkReviewContainer from "../RunsPage/components/BulkReview/BulkReviewContainer";
import { analysisModes } from "../RunsPage/constants";
import BulkContentHeader from "../RunsPage/components/BulkReview/BulkContentHeader";

interface StateProps {
  showChart: boolean;
  analysisMode: any;
}

interface Props extends StateProps {
  children: any;
}

const ChartRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { children, showChart, analysisMode } = props;

  const renderChartComponent = () => {
    let renderChart = <Loader show />;
    if (showChart) {
      renderChart = <RunsContainer />;
    }

    return renderChart;
  };

  const renderChartRoute = () => {
    let chartRoute = (
      <>
        <ContentHeader />
        {renderChartComponent()}
        {children}
      </>
    );

    if (analysisMode[analysisModes.bulkReview]) {
      chartRoute = (
        <>
          <BulkContentHeader />
          <BulkReviewContainer />
        </>
      );
    }

    return chartRoute;
  };

  return (
    <div className="fluidContainers">
      <Content>{renderChartRoute()}</Content>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    showChart: getShowChart(state),
    analysisMode: getAnalysisMode(state),
  };
}

export default connect(mapStateToProps)(ChartRoute);
