import React from "react";
import { connect } from "react-redux";

import { openAnalysisModal } from "../../../actions";
import { isAnalysisModalOpen } from "../../../selectors";
import { isRunTypeMelt } from "../../../../Hub/selectors";

const AnalysisModeButton = props => {
  const { runTypeIsMelt, openAnalysisModalBox, analysisModalIsOpen } = props;

  if (runTypeIsMelt) {
    return null;
  }

  let analysisModalIconStyle = {};

  if (analysisModalIsOpen) {
    analysisModalIconStyle = { transform: "rotate(180deg) scaleX(-1)" };
  }

  return (
    <button
      className="btn-inline analysis_mode_selector"
      data-tip="Select a different analysis mode for your chart. This will reload the page and eliminate any changes you've made on this screen."
      style={{
        marginLeft: "20px",
      }}
      onClick={openAnalysisModalBox}
    >
      <img
        className="analysis-modal-icon"
        src="../../../../images/BackArrowNavy.svg"
        style={analysisModalIconStyle}
        alt=""
        height="20"
        width="13"
      />
    </button>
  );
};

const mapStateToProps = state => ({
  analysisModalIsOpen: isAnalysisModalOpen(state),
  runTypeIsMelt: isRunTypeMelt(state),
});

const mapDispatchToProps = dispatch => ({
  openAnalysisModalBox: () => {
    dispatch(openAnalysisModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisModeButton);
