/**
 * Fork of https://raw.githubusercontent.com/JedWatson/classnames/master/index.js
 * take strings or objects
 * @returns {string} concatenated string from argument and object keys with true value
 */

export function classNamesParser(...args) {
  const classes = [];

  for (let i = 0; i < args.length; i += 1) {
    const arg = args[i];
    if (arg) {
      const argType = typeof arg;

      if (argType === "string" || argType === "number") {
        classes.push(arg);
      } else if (Array.isArray(arg)) {
        classes.push(...arg);
      } else if (argType === "object") {
        Object.keys(arg).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(arg, key) && arg[key]) {
            classes.push(key);
          }
        });
      }
    }
  }

  return classes.join(" ");
}
