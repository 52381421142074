import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { getAwsUser } from "../../Auth/selectors";

interface Props
  extends RouteComponentProps<any, any, ErrorScreenLocationState> {
  customError?: string;
}

interface StateProps {
  user: any;
}

interface ErrorScreenLocationState {
  route?: string;
}

const ErrorScreen = (props: Props & StateProps) => {
  const RECOVERY_TIMEOUT = 2000;

  useEffect(() => {
    setTimeout(() => {
      const { history, user } = props;
      const { location } = history;
      const { state = {} } = location;
      const { route } = state;

      let defaultRoute = "/";
      if (user) {
        defaultRoute = "/data";
      }

      history.push(route || defaultRoute);
    }, RECOVERY_TIMEOUT);
  });

  const { customError } = props;

  return (
    <div className="password">
      <div className="password__title">
        <h1>Biomeme Cloud</h1>
        <h3>{customError || "An error has occurred."}</h3>
      </div>
      <p className="password_form">You will now be redirected.</p>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: getAwsUser(state),
});

export default withRouter(
  connect<StateProps, void, Props, any>(mapStateToProps)(ErrorScreen)
);
