import React from "react";

interface Props {
  successMessage: string;
  errorMessage: string;
}

const BugReportFormStatusMessages: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { errorMessage, successMessage } = props;

  let errorMessageJSX = null;
  if (errorMessage) {
    errorMessageJSX = (
      <div className="settings__validation-warning">{errorMessage}</div>
    );
  }

  let submissionSuccessJSX = null;
  if (successMessage) {
    submissionSuccessJSX = (
      <div className="settings__success-notification">{successMessage}</div>
    );
  }

  return (
    <div className="bug_report__status-container">
      {errorMessageJSX}
      {submissionSuccessJSX}
    </div>
  );
};

export default BugReportFormStatusMessages;
