import React, { Fragment } from "react";

const MeltProtocolTable = props => {
  const { data } = props;

  return (
    <Fragment>
      <tr className="detailed-dropdown__row-darkened">
        <th>Name</th>
        <td>{data.protocolName}</td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Melt Increment</th>
        <td>{data.meltIncrement} C</td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Melt Start Temperature</th>
        <td>{data.meltTempStart} C</td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Melt End Temperature</th>
        <td>{data.meltTempFinal} C</td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Steps</th>
        <td>{data.steps}</td>
      </tr>
      <tr className="detailed-dropdown__row">
        <th>Step Duration</th>
        <td>{data.meltDuration}</td>
      </tr>
      <tr className="detailed-dropdown__row-darkened">
        <th>Duration</th>
        <td>{data.duration}</td>
      </tr>
    </Fragment>
  );
};

export default MeltProtocolTable;
