export const USER_REGISTER = "user/REGISTER";
export const USER_LOGIN_REQ = "user/LOGIN_REQ";
export const USER_LOGIN_SUCCESS = "user/LOGIN_SUCCESS";

export const USER_LOGIN_ERR = "user/LOGIN_ERROR";
export const USER_LOGOUT_REQ = "user/LOGOUT_REQ";
export const USER_LOGOUT_ERROR = "user/LOGOUT_ERROR";
export const USER_LOGOUT_SUCCESS = "user/LOGOUT_SUCCESS";

export const AUTHENTICATE_SAML_USER = "user/AUTHENTICATE_SAML_USER";

export const USER_REGISTER_REQ = "user/REGISTER_REQ";
export const USER_REGISTER_ERROR = "user/REGISTER_ERROR";
export const USER_REGISTER_SUCCESS = "user/REGISTER_SUCCESS";

export const SET_ORG = "auth/ORG_SET";
export const SET_ACTIVE_TEAM_ID = "auth/ACTIVE_TEAM_SET";
export const SET_TEAMS = "auth/TEAMS_SET";
export const SET_TOKEN = "auth/SET_TOKEN";
export const SET_COG_USER = "auth/SET_COG_USER";
export const SET_TOKEN_TIME = "auth/SET_TOKEN_TIME";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "auth/RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";

export const REQUEST_VERIFICATION_CODE = "auth/REQUEST_VERIFICATION_CODE";
export const REQUEST_VERIFICATION_CODE_ERROR =
  "auth/REQUEST_VERIFICATION_CODE_ERROR";
export const REQUEST_VERIFICATION_CODE_SUCCESS =
  "auth/REQUEST_VERIFICATION_CODE_SUCCESS";

export const SET_USER_GROUP = "auth/SET_USER_GROUP";

export const SET_PROVIDER = "auth/SET_PROVIDER";

export const USER_PROVIDER_LOGIN_REQ = "auth/USER_PROVIDER_LOGIN_REQ";

export const SET_SAML_PROCESSING = "auth/SET_SAML_PROCESSING";

export const USER_REFRESH_SESSION_SUCCESS = "user/REFRESH_SESSION_SUCCESS";
