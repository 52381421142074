import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Button } from "bio-web-components";
import { DetailsVersion } from "js-common";

import { IBioradChannelSelection, T_ArchiveFormStep } from "../types";
import {
  externalUploadRequest,
  IExternalUploadRequestOptions,
} from "../actions";

import ArchiveFormFileSelector from "./ArchiveFormFileSelector";
import ArchiveFormProtocolSelector from "./ArchiveFormProtocolSelector";
import Loader from "../../Layout/Loader";
import { debounce } from "lodash";

interface Props {
  onUploadSuccess?: (callback: Function) => void;
}

interface DispatchProps {
  uploadRequest: (
    files: File[],
    resolve: any,
    reject: any,
    options: IExternalUploadRequestOptions
  ) => void;
}

const ArchiveForm = (props: Props & DispatchProps) => {
  const UPLOAD_DELAY = 250;

  const [archiveFormStep, setArchiveFormStep] =
    useState<T_ArchiveFormStep>("files");
  const [files, setFiles] = useState<Array<File>>([]);
  const [channelSelection, setChannelSelection] =
    useState<IBioradChannelSelection>({});
  const [detailsVersion, setDetailsVersion] = useState<DetailsVersion | "">("");
  const [protocolId, setProtocolId] = useState("");
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<
    string | null
  >(null);
  const [fileValidationError, setFileValidationError] = useState<string | null>(
    null
  );
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | null>(
    null
  );
  const [processing, setProcessing] = useState(false);

  const resetState = () => {
    setFiles([]);
    setDetailsVersion("");
    setProtocolId("");
    setUploadErrorMessage(null);
    setUploadSuccessMessage(null);
    setArchiveFormStep("files");
  };

  const _handleSuccess = () => {
    const { onUploadSuccess } = props;
    if (onUploadSuccess) {
      onUploadSuccess(resetState);
    } else {
      resetState();
    }
  };

  const objectFlip = (obj: IBioradChannelSelection) => {
    const ret: IBioradChannelSelection = {};
    Object.keys(obj).forEach(key => {
      ret[obj[key]] = key;
    });
    return ret;
  };

  const handleSubmit = debounce(async () => {
    setUploadErrorMessage(null);
    setUploadSuccessMessage(null);
    setProcessing(true);

    let success = true;
    await new Promise<string>((resolve, reject) => {
      const { uploadRequest } = props;

      const options: IExternalUploadRequestOptions = {
        protocolId,
        colorMap: objectFlip(channelSelection),
        detailsVersion: detailsVersion || "biorad",
      };

      uploadRequest(files, resolve, reject, options);
    })
      .then(successMessage => setUploadSuccessMessage(successMessage))
      .catch(errorMessage => {
        setUploadErrorMessage(errorMessage);
        success = false;
      });

    setProcessing(false);
    if (success) {
      _handleSuccess();
    }
  }, UPLOAD_DELAY);

  const _renderButtonSection = () => {
    if (!detailsVersion) {
      return null;
    }

    let title = "Continue";
    let onClick = () => setArchiveFormStep("protocol");
    let disabled = fileValidationError !== null;
    if (archiveFormStep === "protocol") {
      title = "Upload Run";
      onClick = handleSubmit;
      disabled = !protocolId;
    }

    return (
      <div className="archive_form-button-section">
        <Button title={title} onClick={onClick} disabled={disabled} inverting />
      </div>
    );
  };

  const _renderMessageSection = () => {
    return (
      <div className="archive_form-message-section">
        {uploadErrorMessage && (
          <span className="app_error centeredContainer">
            {uploadErrorMessage}
          </span>
        )}
        {uploadSuccessMessage && (
          <span className="app_success centeredContainer">
            {uploadSuccessMessage}
          </span>
        )}
      </div>
    );
  };

  const _renderArchiveFormContent = () => {
    if (archiveFormStep === "files") {
      return (
        <ArchiveFormFileSelector
          fileProps={{ files, setFiles }}
          detailsVersionProps={{ detailsVersion, setDetailsVersion }}
          channelSelectionProps={{ channelSelection, setChannelSelection }}
          fileValidationProps={{ fileValidationError, setFileValidationError }}
        />
      );
    }

    return (
      <ArchiveFormProtocolSelector
        protocolProps={{ protocolId, setProtocolId }}
        channelSelectionProps={{ channelSelection, setChannelSelection }}
        detailsVersion={detailsVersion}
        onBack={() => resetState()}
        setProcessing={setProcessing}
      />
    );
  };

  return (
    <>
      {_renderArchiveFormContent()}
      {_renderButtonSection()}
      {_renderMessageSection()}
      <Loader show={processing} />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  uploadRequest: (
    files: File[],
    resolve: any,
    reject: any,
    options: IExternalUploadRequestOptions
  ) => dispatch(externalUploadRequest(files, resolve, reject, options)),
});

export default connect<void, DispatchProps, Props, any>(
  undefined,
  mapDispatchToProps
)(ArchiveForm);
