// Bug Report Form Error Constants
export const PROBLEM_DESCRIPTION_ERROR = "Problem Description is required";
export const OPERATING_SYSTEM_ERROR = "Operating System is required";
export const BROWSER_USED_ERROR = "Browser Used is required";

// Bug Report Operating System Options
export const OPERATING_SYSTEM_WINDOWS = "Windows";
export const OPERATING_SYSTEM_MAC = "Mac";

// Bug Report Browser Options
export const BROWSER_CHROME = "Chrome";
export const BROWSER_FIREFOX = "Firefox";
export const BROWSER_SAFARI = "Safari";
export const BROWSER_EDGE = "Edge";

// Bug Report Shared Radio Options
export const OTHER = "Other";
