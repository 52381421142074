import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import {
  updateLineThreshold as updateLineThresholdAction,
  updateCQ as updateCQAction,
  updateTabularDataOptions as updateTabularDataOptionsAction,
} from "../../../actions";
import { postFeature as postFeatureAction } from "../../../../App/actions";
import {
  getTabularData,
  getTabularDataFilter,
  getTabularDataOrder,
  isReprocessingModeActive,
  getAnalysisMode,
} from "../../../selectors";
import { tooltipDelay, analysisModes } from "../../../constants";
import { round } from "../../../../Utils/helpers";
import { isRunTypeMelt } from "../../../../Hub/selectors";
import constants from "../../chartConstants";

export class TabularData extends Component {
  constructor(props) {
    super(props);

    const sqData = props.tabularData.filter(tabData => {
      return tabData.SQ;
    });
    const showSqData = sqData.length > 0;
    const showSampleType =
      props.tabularData.filter(tabData => {
        return tabData.sampleType;
      }).length > 0;

    let fields = [
      "RUN NAME",
      "CHANNEL & WELL",
      "END RFU",
      "Cq",
      "SAMPLE ID",
      "TARGET",
      "THRESHOLD",
    ];

    if (props.reprocessingModeIsActive) {
      fields.push("LEFT VALUE", "RIGHT VALUE");
    }

    if (showSqData) {
      fields = [
        ...fields.slice(0, constants.tabularDataCqColumnIndex),
        "SQ",
        ...fields.slice(constants.tabularDataCqColumnIndex),
      ];
    }

    if (showSampleType) {
      let sliceIndex = constants.tabularDataCqColumnIndex;
      if (showSqData) {
        sliceIndex = constants.tabularDataCqColumnIndex + 1;
      }

      fields = [
        ...fields.slice(0, sliceIndex),
        "SAMPLE TYPE",
        "SAMPLE AMOUNT",
        ...fields.slice(sliceIndex),
      ];
    }

    this.state = {
      fields,
      showSqData,
      activeTarget: null,
      threshold: null,
      showSampleType,
    };
  }

  handleFocus = (event, targetId) => {
    this.setState({
      activeTarget: targetId,
      threshold: event.target.value,
    });
    event.target.select();
  };

  sortTabularData = filterType => {
    const { filter, order, updateTabularDataOptions } = this.props;

    let newOrder = 0;
    if (filterType === filter && order === 1) {
      newOrder = 0;
    } else if (filterType === filter && order !== 1) {
      newOrder = 1;
    }

    updateTabularDataOptions({
      filter: filterType,
      order: newOrder,
    });
  };

  renderSqDataElement = (data, styles) => {
    const { showSqData } = this.state;

    let sqDataElement = null;
    if (showSqData) {
      sqDataElement = <td style={styles.data}>{data.SQ || "N/A"}</td>;
    }

    return sqDataElement;
  };

  renderSampleTypeElement = (data, styles) => {
    const { showSampleType } = this.state;

    let sampleTypeElement = null;
    if (showSampleType) {
      sampleTypeElement = (
        <>
          <td style={styles.data}>{data.sampleType || "N/A"}</td>
          <td style={styles.data}>{data.sampleAmount}</td>
        </>
      );
    }

    return sampleTypeElement;
  };

  renderReprocessingInputElements = (data, backgroundColor) => {
    const { updateCQ, updateLineThreshold, analysisMode, postFeature } =
      this.props;
    const { activeTarget, threshold } = this.state;

    let inputValue = round(data.threshold, 2);
    if (activeTarget === data.targetId) {
      inputValue = +threshold;
    }

    let element = (
      <input
        type="number"
        step="any"
        value={inputValue}
        style={{
          backgroundColor,
          border: "1.5px solid #D9D9D9",
          height: "30px",
          width: "100px",
          paddingLeft: "5px",
        }}
        onFocus={e => {
          return this.handleFocus(e, data.targetId);
        }}
        onChange={e => {
          this.setState({ threshold: +e.target.value });
          postFeature("threshold editing");
        }}
        onBlur={() => {
          updateCQ({
            target: data.targetId,
            threshold: round(threshold, 2),
          });
          updateLineThreshold([
            {
              target: data.targetId,
              threshold: round(threshold, 2),
            },
          ]);
          this.setState({
            activeTarget: null,
            threshold: null,
          });
        }}
      />
    );

    if (
      analysisMode[analysisModes.singleThreshold] ||
      analysisMode[analysisModes.reprocessing]
    ) {
      element = round(data.threshold, 2);
    }

    return element;
  };

  renderReprocessingVals = (data, styles) => {
    const { reprocessingModeIsActive } = this.props;

    let element = null;
    if (reprocessingModeIsActive) {
      let dataLeftVal = "N/A";
      if (data.leftVal || data.leftVal === 0) {
        dataLeftVal = data.leftVal + 1;
      }

      let dataRightVal = "N/A";
      if (data.rightVal || data.rightVal === 0) {
        dataRightVal = data.rightVal + 1;
      }

      element = (
        <>
          <td style={styles.data}>{dataLeftVal}</td>
          <td style={styles.data}>{dataRightVal}</td>
        </>
      );
    }

    return element;
  };

  computeStyles = () => {
    const { order } = this.props;

    let height = "60vh";
    const pageLeft = document.getElementById("runs_page__left");
    if (pageLeft) {
      height = pageLeft.clientHeight - 175;
    }

    let arrow = { marginLeft: "5px" };
    if (order === 1) {
      arrow = {
        transform: "rotate(180deg) scaleX(-1)",
        marginLeft: "5px",
      };
    }

    return {
      heading: {
        fontSize: "12px",
        color: "#375464",
      },
      container: {
        maxHeight: height,
        overflow: "scroll",
        width: "100%",
      },
      data: { fontSize: "16px", color: "#012639" },
      arrow,
    };
  };

  computeHeadings = styles => {
    const { runTypeIsMelt } = this.props;
    const { fields } = this.state;

    return fields.map(field => {
      let fieldToRender = field;
      const { filter } = this.props;

      if (field === "SQ") {
        fieldToRender = (
          <span
            data-multiline
            data-tip="Note: Starting Quantity (SQ) values are computed from the original Cq values at a fixed threshold.<br /> Changing the threshold or reprocessing the curve will NOT update the computed SQ value."
            style={{ color: "red" }}
          >
            SQ
          </span>
        );
      } else if (field === "Cq" && runTypeIsMelt) {
        fieldToRender = "PEAK";
      }

      let visibility = "hidden";
      if (filter === field) {
        visibility = "visible";
      }

      return (
        <Fragment key={field}>
          <td style={styles.heading}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                return this.sortTabularData(field);
              }}
            >
              {fieldToRender}
              <img
                src="../../../../../images/BackArrowNavy.svg"
                alt=""
                style={{
                  ...styles.arrow,
                  visibility,
                }}
                height="20"
                width="13"
              />
            </div>
          </td>
        </Fragment>
      );
    });
  };

  render() {
    const { tabularData } = this.props;

    const styles = this.computeStyles();

    const headings = this.computeHeadings(styles);

    return (
      <div className="tabular_data" style={styles.container}>
        <ReactTooltip delayShow={tooltipDelay} />
        <table style={{ width: "100%" }}>
          <tbody>
            <tr style={{ lineHeight: "40px" }}>{headings}</tr>
            {tabularData.map((data, index) => {
              const even = index % 2 === 0;

              let backgroundColor = "";
              if (even) {
                backgroundColor = "#F9F9F9";
              }

              return (
                <tr
                  style={{
                    backgroundColor,
                    lineHeight: "40px",
                  }}
                  key={data.targetId}
                >
                  <td style={styles.data}>{data.run}</td>
                  <td style={styles.data}>{data.well}</td>
                  <td style={styles.data}>{data.endRfu}</td>
                  <td style={styles.data}>{data.CQ || 0}</td>
                  {this.renderSqDataElement(data, styles)}
                  {this.renderSampleTypeElement(data, styles)}
                  <td style={styles.data}>{data.sampleId || "N/A"}</td>
                  <td style={styles.data}>{data.target}</td>
                  <td style={styles.data}>
                    {this.renderReprocessingInputElements(
                      data,
                      backgroundColor
                    )}
                  </td>
                  {this.renderReprocessingVals(data, styles)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tabularData: getTabularData(state),
    analysisMode: getAnalysisMode(state),
    filter: getTabularDataFilter(state),
    order: getTabularDataOrder(state),
    reprocessingModeIsActive: isReprocessingModeActive(state),
    runTypeIsMelt: isRunTypeMelt(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLineThreshold: data => {
      return dispatch(updateLineThresholdAction(data));
    },
    updateCQ: data => {
      return dispatch(updateCQAction(data));
    },
    updateTabularDataOptions: options => {
      return dispatch(updateTabularDataOptionsAction(options));
    },
    postFeature: feature => {
      return dispatch(postFeatureAction(feature));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabularData);
