import * as t from "./actionTypes";
import {
  determineMaxNumberOfSelectedRuns,
  canRunTypesBeCombined,
} from "./helpers";

export const initState = {
  showedFilterBar: false,
  showChart: false,
  activeTab: "Runs",
  activeFilters: [],
  preSelectedFilters: [],
  runSelectionType: "",
  runSelectionDetailsVersion: "",
  selectedRuns: [],
  paths: [],
  showSearch: false,
  loading: false,
  searchQuery: "",
};

export function updatePath(state, action) {
  const { paths } = state;
  const { folder } = action;

  const newDepth = folder.depth;

  let newPaths = paths.filter(f => f.depth < newDepth);
  newPaths = [...newPaths, folder];

  return newPaths;
}

export function hubReducer(state = initState, action) {
  switch (action.type) {
    case t.SHOW_SEARCH: {
      return { ...state, showSearch: true };
    }

    case t.HIDE_SEARCH: {
      return { ...state, showSearch: false };
    }

    case t.HANDLE_SEARCH: {
      return {
        ...state,
        searchQuery: action.query,
      };
    }

    case t.HUB_FILTER_BAR_SHOW:
      return { ...state, showedFilterBar: true };

    case t.HUB_FILTER_BAR_HIDE:
      return { ...state, showedFilterBar: false };

    case t.HUB_FILTER_BAR_CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };

    case t.HUB_FILTER_BAR_ACTIVE_FILTER_ADD: {
      const newActiveFilters = [...state.activeFilters, action.filter];

      return {
        ...state,
        activeFilters: newActiveFilters,
      };
    }

    case t.HUB_FILTER_BAR_ACTIVE_FILTER_REMOVE: {
      const remainingActiveFilters = state.activeFilters.filter(
        f => f.value !== action.filter.value
      );

      let { showedFilterBar } = state;
      if (remainingActiveFilters.length <= 0) {
        showedFilterBar = false;
      }

      return {
        ...state,
        activeFilters: remainingActiveFilters,
        showedFilterBar,
      };
    }

    case t.HUB_FILTER_BAR_PRESELECTED_FILTER_ADD: {
      const newPreselectedFilters = [
        ...state.preSelectedFilters,
        action.filter,
      ];

      return { ...state, preSelectedFilters: newPreselectedFilters };
    }

    case t.HUB_FILTER_BAR_PRESELECTED_FILTER_REMOVE: {
      const remainingPreselectedFilters = state.preSelectedFilters.filter(
        f => f.value !== action.filter.value
      );

      return { ...state, preSelectedFilters: remainingPreselectedFilters };
    }

    case t.HUB_RESET: {
      const { selectedRuns, runSelectionType, runSelectionDetailsVersion } =
        state;

      const newSelectedRuns = [...selectedRuns];

      let newRunSelectionType = "";
      if (runSelectionType) {
        newRunSelectionType = runSelectionType;
      }

      let newRunSelectionDetailsVersion = "";
      if (runSelectionDetailsVersion) {
        newRunSelectionDetailsVersion = runSelectionDetailsVersion;
      }

      return {
        ...initState,
        runSelectionType: newRunSelectionType,
        runSelectionDetailsVersion: newRunSelectionDetailsVersion,
        selectedRuns: newSelectedRuns,
      };
    }

    case t.RESET_SELECTED_RUNS: {
      return {
        ...state,
        runSelectionType: "",
        selectedRuns: [],
      };
    }

    case t.UPDATE_PATHS: {
      return { ...state, searchQuery: "", paths: updatePath(state, action) };
    }

    case t.APPLY_FILTERS: {
      return {
        ...state,
        activeFilters: state.preSelectedFilters,
        preSelectedFilters: [],
        showedFilterBar: true,
      };
    }
    case t.CANCEL_FILTER: {
      return { ...state, showedFilterBar: false };
    }
    case t.HUB_SELECT_SINGLE_RUN: {
      const { runType, detailsVersion } = action.run;

      let runSelectionDetailsVersion = "";
      if (detailsVersion) {
        runSelectionDetailsVersion = detailsVersion.toLowerCase();
      }

      return {
        ...state,
        runSelectionType: runType,
        runSelectionDetailsVersion,
        selectedRuns: [].concat(action.run),
      };
    }

    case t.HUB_SELECT_MULTIPLE_RUNS: {
      return { ...state };
    }
    case t.HUB_SELECTED_RUN_REMOVE: {
      let { runSelectionType, runSelectionDetailsVersion } = state;

      const remainingSelectedRuns = state.selectedRuns.filter(
        r => r.id !== action.run.id
      );

      if (remainingSelectedRuns.length < 1) {
        runSelectionType = "";
        runSelectionDetailsVersion = "";
      }

      return {
        ...state,
        runSelectionType,
        runSelectionDetailsVersion,
        selectedRuns: remainingSelectedRuns,
      };
    }

    case t.HUB_SELECTED_RUN_ADD: {
      const { run } = action;
      const { runType, protocolId, detailsVersion } = run;

      const newState = {
        ...state,
      };

      const { selectedRuns, runSelectionType, runSelectionDetailsVersion } =
        newState;

      if (selectedRuns.length < 1) {
        newState.runSelectionType = runType || "";

        if (detailsVersion) {
          newState.runSelectionDetailsVersion = detailsVersion.toLowerCase();
        } else {
          newState.runSelectionDetailsVersion = "";
        }

        newState.selectedRuns = [run];

        return newState;
      }

      const maxSelectedRunCount = determineMaxNumberOfSelectedRuns(
        runSelectionDetailsVersion,
        runSelectionType
      );

      if (selectedRuns.length >= maxSelectedRunCount) {
        return newState;
      }

      if (runSelectionDetailsVersion !== detailsVersion) {
        return newState;
      }

      const runTypesCanBeCombined = canRunTypesBeCombined(
        runSelectionType,
        runType,
        protocolId,
        selectedRuns[0]
      );

      if (!runTypesCanBeCombined) {
        return newState;
      }

      newState.selectedRuns = [...newState.selectedRuns, run];

      return newState;
    }

    case t.BULK_REVIEW_SELECTED_RUN_ADD: {
      const { run } = action;

      return { ...state, selectedRuns: [...state.selectedRuns, run] };
    }

    case t.SHOW_CHART: {
      return { ...state, showChart: true };
    }

    case t.HIDE_CHART: {
      return { ...state, showChart: false };
    }

    case t.START_LOADING:
      return { ...state, loading: true };

    case t.STOP_LOADING:
      return { ...state, loading: false };

    default: {
      return state;
    }
  }
}

export default hubReducer;
