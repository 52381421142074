import { createSelector } from "reselect";
import { getActiveFolderData } from "../Folders/selectors";

export const isProtocolsFetched = state => state.protocolData.fetched;
export const getProtocolData = state => state.protocolData.data;
export const getProtocolValues = state => state.protocolData.protocols;

export const getProtocolDataForLevel = createSelector(
  [getProtocolValues, getActiveFolderData],
  (protocols, activeFolder) => {
    const { protocolIds } = activeFolder;

    if (!protocolIds) {
      return [];
    }

    const pickedProtocols = protocols.filter(
      p => protocolIds.indexOf(p.id) > -1
    );

    return pickedProtocols;
  }
);

export const getProtocolListLength = createSelector(
  [getProtocolDataForLevel],
  protocols => protocols.length
);

export const getExternalUploadProtocols = state =>
  state.protocolData.externalUploadProtocols;
