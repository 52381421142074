import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Modal from "../../../Layout/Modal";
import { setReprocessingError as actionSetReprocessingError } from "../../actions";
import { getReprocessingError } from "../../selectors";

class ErrorModal extends Component {
  renderReprocessingErrorModal() {
    const { setReprocessingError, reprocessingError, history } = this.props;

    setTimeout(() => {
      setReprocessingError("");
      history.push("/data");
    }, 10000);

    return (
      <Modal
        open={reprocessingError}
        heading="An Error Has Occurred"
        onClose={() => {
          setReprocessingError("");
          history.push("/data");
        }}
        className="modal--run_detail"
        centered
      >
        <div
          style={{ maxHeight: "70vh", overflowY: "auto", marginBottom: "17px" }}
        >
          {reprocessingError}
        </div>
      </Modal>
    );
  }

  render() {
    const { reprocessingError } = this.props;

    let returnValue = null;
    if (reprocessingError) {
      returnValue = this.renderReprocessingErrorModal();
    }

    return returnValue;
  }
}

const mapStateToProps = state => {
  return {
    reprocessingError: getReprocessingError(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setReprocessingError: () => {
      dispatch(actionSetReprocessingError());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorModal)
);
