import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { getBMRunsLength } from "../../../RunData/selectors";
import { getPreSelectedFilters, getSelectedRuns } from "../../selectors";
import Listings from "./Listings";

interface Props {}

interface StateProps {
  runCount: number;
  selectedRuns: Array<any>;
  selectedFilters: Array<any>;
}

interface DispatchProps {}

export const Table = (props: Props & StateProps & DispatchProps) => {
  const { runCount, selectedRuns, selectedFilters } = props;
  const hasMargin = selectedRuns.length > 0 || selectedFilters.length > 0;

  return (
    <div
      className="tableContainer"
      style={{ marginBottom: hasMargin ? "50px" : "0px" }}
    >
      <div className="inner" style={{ marginLeft: "35px" }}>
        {runCount > 0 ? (
          <Listings />
        ) : (
          <p
            style={{
              marginTop: 20,
              textAlign: "center",
              fontFamily: "Source Sans Pro",
            }}
          >
            This folder does not contain any runs.
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  runCount: getBMRunsLength(state),
  selectedRuns: getSelectedRuns(state),
  selectedFilters: getPreSelectedFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
