import React from "react";

import { Text } from "bio-web-components";

import { BugReportFormState, BugReportErrorState } from "../types";

interface Props {
  handleOnChange: (formBundle: BugReportFormState) => void;
  form: BugReportFormState;
  errors: BugReportErrorState;
}

const BugReportInputFields: React.FunctionComponent<Props> = (props: Props) => {
  const { handleOnChange, form, errors } = props;
  const { problemDescription, expectedResult, stepsToReproduce } = form;
  const { problemDescriptionError } = errors;

  const TEXT_COLS = 60;
  const TEXT_ROWS = 5;

  const _renderProblemDescription = () => {
    return (
      <div className="bug_report__input_container">
        <h4>Describe the Problem*</h4>
        <p className="bug_report__input_sublabel">
          Be as detailed as possible in your description.
        </p>
        <Text
          value={problemDescription}
          onChange={(value: string) =>
            handleOnChange({ ...form, problemDescription: value })
          }
          error={problemDescriptionError}
          cols={TEXT_COLS}
          rows={TEXT_ROWS}
        />
      </div>
    );
  };

  const _renderExpectedResult = () => {
    return (
      <div className="bug_report__input_container">
        <h4>Expected Result</h4>
        <p className="bug_report__input_sublabel">
          What were you expecting to see?
        </p>
        <Text
          value={expectedResult}
          onChange={(value: string) =>
            handleOnChange({ ...form, expectedResult: value })
          }
          cols={TEXT_COLS}
          rows={TEXT_ROWS}
        />
      </div>
    );
  };

  const _renderStepsToReproduce = () => {
    return (
      <div className="bug_report__input_container">
        <h4> Steps to Reproduce</h4>
        <p className="bug_report__input_sublabel">
          If we can reproduce the issue ourselves, the offs of a fix are much
          better.
        </p>
        <Text
          value={stepsToReproduce}
          onChange={(value: string) =>
            handleOnChange({ ...form, stepsToReproduce: value })
          }
          cols={TEXT_COLS}
          rows={TEXT_ROWS}
        />
      </div>
    );
  };

  return (
    <>
      {_renderProblemDescription()}
      {_renderExpectedResult()}
      {_renderStepsToReproduce()}
    </>
  );
};

export default BugReportInputFields;
