import { select } from "d3";
import noUiSlider from "nouislider";

import constants from "./chartConstants";

export function drawReviewCutoffLines(
  cutoffStart,
  cutoffEnd,
  paddedYMin,
  paddedYMax,
  x,
  y
) {
  if (
    typeof x !== "function" ||
    typeof y !== "function" ||
    typeof cutoffStart !== "number" ||
    typeof cutoffEnd !== "number"
  ) {
    return;
  }

  const groupName = "reviewBackgroundElements";
  const startLineName = "cutoffStartLine";
  const endLineName = "cutoffEndLine";
  const lineColor = "#858585";
  const backgroundColor = "#bababa";

  // REMOVE EXISTING BACKGROUND ELEMENTS
  select(this.node).select(`#${groupName}`).remove();

  // DRAW NEW BACKGROUND LINES
  select(this.node).append("g").attr("id", groupName);

  // DRAW CUTOFFSTART LINE
  select(this.node)
    .select(`#${groupName}`)
    .append("line")
    .attr("x1", x(cutoffStart - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(cutoffStart - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", startLineName)
    .style("stroke-width", 1)
    .style("stroke", lineColor)
    .style("opacity", 0.8);

  // DRAW CUTOFFEND LINE
  select(this.node)
    .select(`#${groupName}`)
    .append("line")
    .attr("x1", x(cutoffEnd - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(cutoffEnd - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", endLineName)
    .style("stroke-width", 1)
    .style("stroke", lineColor)
    .style("opacity", 0.8);

  // DRAW SHADED BACKGROUND
  const cutoffStartLine = document.getElementById(startLineName) || null;
  const cutoffEndLine = document.getElementById(endLineName) || null;

  if (cutoffStartLine && cutoffEndLine) {
    const cutoffStartValues = {
      x1: cutoffStartLine.x1.baseVal.value,
      y1: cutoffStartLine.y1.baseVal.value,
      y2: cutoffStartLine.y2.baseVal.value,
    };

    const cutoffEndValues = {
      x1: cutoffEndLine.x1.baseVal.value,
      y1: cutoffEndLine.y1.baseVal.value,
      y2: cutoffEndLine.y2.baseVal.value,
    };

    select(this.node)
      .select(`#${groupName}`)
      .append("rect")
      .attr("id", "reviewBackgroundRect")
      .attr("x", cutoffStartValues.x1)
      .attr("y", cutoffStartValues.y2)
      .attr("width", cutoffEndValues.x1 - cutoffStartValues.x1)
      .attr("height", cutoffStartValues.y1 - cutoffStartValues.y2)
      .style("fill", backgroundColor)
      .style("opacity", 0.1);
  }
}

export function redrawBackgroundElements(values, paddedYMin, paddedYMax, x, y) {
  if (typeof x !== "function" || typeof y !== "function") {
    return;
  }

  const leftVal = values[0];
  const rightVal = values[1];

  // REMOVE EXISTING BACKGROUND ELEMENTS
  select(this.node).select("#backgroundElements").remove();

  // DRAW NEW BACKGROUND LINES
  select(this.node).append("g").attr("id", "backgroundElements");

  select(this.node)
    .select("#backgroundElements")
    .append("line")
    .attr("x1", x(leftVal - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(leftVal - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", "lineLV")
    .style("stroke-width", 1)
    .style("stroke", "#2ad2c9")
    .style("opacity", 0.8);

  select(this.node)
    .select("#backgroundElements")
    .append("line")
    .attr("x1", x(rightVal - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(rightVal - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", "lineRV")
    .style("stroke-width", 1)
    .style("stroke", "#2ad2c9")
    .style("opacity", 0.8);

  // DRAW SHADED BACKGROUND
  const backgroundLeftLine = document.getElementById("lineLV") || null;
  const backgroundRightLine = document.getElementById("lineRV") || null;

  if (backgroundLeftLine && backgroundRightLine) {
    const backgroundLeftValues = {
      x1: backgroundLeftLine.x1.baseVal.value,
      y1: backgroundLeftLine.y1.baseVal.value,
      y2: backgroundLeftLine.y2.baseVal.value,
    };

    const backgroundRightValues = {
      x1: backgroundRightLine.x1.baseVal.value,
      y1: backgroundRightLine.y1.baseVal.value,
      y2: backgroundRightLine.y2.baseVal.value,
    };

    select(this.node)
      .select("#backgroundElements")
      .append("rect")
      .attr("id", "backgroundRect")
      .attr("x", backgroundLeftValues.x1)
      .attr("y", backgroundLeftValues.y2)
      .attr("width", backgroundRightValues.x1 - backgroundLeftValues.x1)
      .attr("height", backgroundLeftValues.y1 - backgroundLeftValues.y2)
      .style("fill", "#2ad2c9")
      .style("opacity", 0.1);
  }
}

export function drawReprocessingElements(
  paddedYMin,
  paddedYMax,
  paddedXMin,
  paddedXMax,
  xAxisLength,
  x,
  y
) {
  const { sliderMargin } = constants;
  const { leftVal, rightVal } = this.props.backgroundValues;

  let slider;

  if (document.getElementById("slider").noUiSlider) {
    slider = document.getElementById("slider").noUiSlider;
  } else {
    slider = null;
  }

  const numberOfValuesIsGreaterThanMargin = xAxisLength > sliderMargin;

  // eslint-disable-next-line
  if (!isFinite(paddedXMax)) return;

  let padding = 0;
  if (paddedXMax > 1) {
    padding = 1;
  }

  if (slider) {
    if (numberOfValuesIsGreaterThanMargin) {
      slider.updateOptions({
        start: [leftVal, rightVal],
        margin: sliderMargin,
        range: {
          min: paddedXMin + 1,
          max: paddedXMax + 1,
        },
        padding,
      });
    } else {
      return;
    }
  } else if (numberOfValuesIsGreaterThanMargin) {
    noUiSlider.create(document.getElementById("slider"), {
      start: [leftVal, rightVal],
      connect: true,
      step: 1,
      margin: sliderMargin,
      tooltips: true,
      range: {
        min: paddedXMin + 1,
        max: paddedXMax + 1,
      },
      padding,
      width: "100%",
    });
  }

  // REMOVE EXISTING BACKGROUND ELEMENTS
  select(this.node).select("#backgroundElements").remove();

  // DRAW BACKGROUND VALUE LINES
  select(this.node).append("g").attr("id", "backgroundElements");

  select(this.node)
    .select("#backgroundElements")
    .append("line")
    .attr("x1", x(leftVal - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(leftVal - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", "lineLV")
    .style("stroke-width", 1)
    .style("stroke", "#2ad2c9")
    .style("opacity", 0.8);

  select(this.node)
    .select("#backgroundElements")
    .append("line")
    .attr("x1", x(rightVal - 1))
    .attr("y1", y(paddedYMin))
    .attr("x2", x(rightVal - 1))
    .attr("y2", y(paddedYMax))
    .attr("id", "lineRV")
    .style("stroke-width", 1)
    .style("stroke", "#2ad2c9")
    .style("opacity", 0.8);

  // DRAW SHADED BACKGROUND
  const backgroundLeftLine = document.getElementById("lineLV") || null;
  const backgroundRightLine = document.getElementById("lineRV") || null;

  if (backgroundLeftLine && backgroundRightLine) {
    const backgroundLeftValues = {
      x1: backgroundLeftLine.x1.baseVal.value,
      y1: backgroundLeftLine.y1.baseVal.value,
      y2: backgroundLeftLine.y2.baseVal.value,
    };

    const backgroundRightValues = {
      x1: backgroundRightLine.x1.baseVal.value,
      y1: backgroundRightLine.y1.baseVal.value,
      y2: backgroundRightLine.y2.baseVal.value,
    };

    select(this.node)
      .select("#backgroundElements")
      .append("rect")
      .attr("id", "backgroundRect")
      .attr("x", backgroundLeftValues.x1)
      .attr("y", backgroundLeftValues.y2)
      .attr("width", backgroundRightValues.x1 - backgroundLeftValues.x1)
      .attr("height", backgroundLeftValues.y1 - backgroundLeftValues.y2)
      .style("fill", "#2ad2c9")
      .style("opacity", 0.1);
  }
}

export function clearChart() {
  select(this.node).selectAll("g").remove();

  select(this.node).selectAll("text").remove();

  select(this.node).selectAll("path").remove();

  select(this.node).selectAll("line").remove();

  select(this.node).selectAll("rect").remove();
}

export const calculateChartHeightAdjustment = reviewModeIsActive => {
  const { chartHeightAdjustment, reviewFormHeight } = constants;

  if (reviewModeIsActive) {
    return chartHeightAdjustment + reviewFormHeight;
  }

  return chartHeightAdjustment;
};
