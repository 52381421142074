import React from "react";

const Button = props => {
  const {
    onClick,
    title,
    icon,
    buttonClass,
    buttonStyle,
    contentClass,
    contentStyle,
    disabled,
    id,
  } = props;
  const Icon = icon;

  let opacityDisabled = 1;
  if (disabled) {
    opacityDisabled = 0.5;
  }

  let pointerEventsDisabled = "all";
  if (disabled) {
    pointerEventsDisabled = "none";
  }

  let buttonClassName = "";
  if (buttonClass) {
    buttonClassName = buttonClass;
  }

  let contentClassName = "";
  if (contentClass) {
    contentClassName = contentClass;
  }

  return (
    <div
      style={{
        ...buttonStyle,
        opacity: opacityDisabled,
        pointerEvents: pointerEventsDisabled,
      }}
      className={`sample_hub__button sample-hub__button-colors sample-hub__table-button ${buttonClassName}`}
      id={id}
      onClick={onClick}
    >
      <div
        className={`sample-hub__button-contents ${contentClassName}`}
        style={contentStyle}
      >
        {icon && <Icon />}
        <span>{title}</span>
      </div>
    </div>
  );
};

export default Button;
