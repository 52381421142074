import React from "react";
import { connect } from "react-redux";

import { showBaseline } from "../../../actions";
import { isRawChartActive, isTabularDataActive } from "../../../selectors";
import { isRunTypeMelt } from "../../../../Hub/selectors";
import D1MeltIcon from "../../D1MeltIcon";
import Icon from "../../../../Utils/Layout/Icon";

const BaselineButton = props => {
  const {
    rawChartIsActive,
    tabularDataIsActive,
    showBaselineChart,
    runTypeIsMelt,
  } = props;

  const renderBaselineIcon = () => {
    if (runTypeIsMelt) {
      return (
        <React.Fragment>
          <D1MeltIcon />
          Melt Peak
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Icon name="baseline" />
        Baseline
      </React.Fragment>
    );
  };

  let baselineButtonClassName = "btn-inline baseline_mode";

  if (!rawChartIsActive && !tabularDataIsActive) {
    baselineButtonClassName = "btn-inline active baseline_mode";
  }

  return (
    <button
      className={baselineButtonClassName}
      data-tip="Populate the chart with processed data."
      onClick={showBaselineChart}
    >
      {renderBaselineIcon()}
    </button>
  );
};

const mapStateToProps = state => ({
  runTypeIsMelt: isRunTypeMelt(state),
  rawChartIsActive: isRawChartActive(state),
  tabularDataIsActive: isTabularDataActive(state),
});

const mapDispatchToProps = dispatch => ({
  showBaselineChart: () => {
    dispatch(showBaseline());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BaselineButton);
