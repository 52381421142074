import React from "react";

const AddIconButton = props => (
  <svg width={10} height={10} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-122-333h800V69h-800z" />
      <g transform="translate(-22 -14)">
        <text
          fontFamily="RobotoMedium, Roboto"
          fontSize={14}
          fontWeight={500}
          fill="#012639"
          transform="translate(21 9)"
        >
          <tspan x={24.804} y={10}>
            "Create QR Code"
          </tspan>
        </text>
        <path
          className="sample-hub__invertable-button"
          d="M28.18 20.158V24h-2.342v-3.842H22v-2.316h3.82V14h2.342v3.842H32v2.316z"
        />
        <rect
          stroke="#012639"
          strokeWidth={2}
          x={1}
          y={1}
          width={178}
          height={38}
          rx={10}
        />
      </g>
    </g>
  </svg>
);

export default AddIconButton;
