import { SummaryTotals, SummaryTotalFailures, SummaryAverages } from "./types";

export const INITIAL_TOTALS: SummaryTotals = {
  samples: 0,
  positiveControls: 0,
  negativeControls: 0,
  noTemplateControls: 0,
};
export const INITIAL_TOTAL_FAILURES: SummaryTotalFailures = {
  inconclusive: 0,
  positiveControlFailures: 0,
  negativeControlFailures: 0,
  noTemplateControlFailures: 0,
};
export const INITIAL_AVERAGES: SummaryAverages = {
  averageMS2: 0,
  averagePositiveControlOrf1ab: 0,
  averagePositiveControlMS2: 0,
  averagePositiveControlS: 0,
};
