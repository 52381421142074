import { CHANNELS } from "../Hub/constants";

export const colors = ["#008000", "#FF0000", "#f5a623"];
export const desiredChannelOrder = CHANNELS.values;
export const tooltipDelay = 1000;

export const analysisModes = {
  baseline: "baseline",
  singleThreshold: "single-threshold",
  reprocessing: "reprocessing",
  review: "review",
  bulkReview: "bulk-review",
};

export const hiddenForExternalRuns = ["baseline", "reprocessing"];

export const initialAnalysisModeSettings = {
  [analysisModes.baseline]: false,
  [analysisModes.singleThreshold]: false,
  [analysisModes.reprocessing]: false,
  [analysisModes.review]: false,
  [analysisModes.bulkReview]: false,
};

export const chartTypes = {
  baseline: "baseline",
  raw: "raw",
  singleThreshold: "single-threshold",
};

export const initialChartTypeSettings = {
  [chartTypes.baseline]: false,
  [chartTypes.raw]: false,
  [chartTypes.singleThreshold]: false,
};

export const tutorialOptions = {
  showProgress: true,
  showStepNumbers: false,
  skipLabel: "Exit",
  showBullets: false,
  disableInteraction: true,
};

export const CHANNEL_MASKS = {
  GREEN: 1 << 0,
  AMBER: 1 << 1,
  RED: 1 << 2,
};

export const BIORAD_WELL_IDENTIFIERS = {
  numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  letters: ["A", "B", "C", "D", "E", "F", "G", "H"],
};

export const UNSPECIFIED_SAMPLE_TEXT = "Unspecified";

export const formattedZero = "0.00";

export const LARGE_NAME_THRESHOLD = 31;
