import React, { Fragment } from "react";
import NewWindow from "react-new-window";

import { generateTimeString } from "js-common/lib/helpers";
import { createQRText } from "../helpers";
import QRCodeGenerator from "./QRCodeGenerator";

const PrintListView = props => {
  const { record, closePrintModal } = props;

  if (!record) {
    throw new Error(
      "[Biomeme]Resource could not be generated. Record is undefined."
    );
  }

  const { id, shortName, metadata = [] } = record;

  const getFieldBoxArray = () => {
    const fieldBoxArray = [];
    let placeholder = 0;

    const fieldBoxesToPrint = Math.ceil(metadata.length / 12);
    const metaDataLength = metadata.length;

    for (let i = 0; i < fieldBoxesToPrint; i += 1) {
      const remainingFields = metaDataLength - i * 12;

      let fieldsPerColumn = Math.ceil(remainingFields / 2);
      if (remainingFields > 11) {
        fieldsPerColumn = 6;
      }

      const fieldColumnsArray = [];

      fieldColumnsArray.push(
        metadata.slice(placeholder, placeholder + fieldsPerColumn)
      );
      placeholder += fieldsPerColumn;

      let metaDataSliceEnd = metaDataLength;
      if (placeholder + fieldsPerColumn < metaDataLength) {
        metaDataSliceEnd = placeholder + fieldsPerColumn;
      }

      fieldColumnsArray.push(metadata.slice(placeholder, metaDataSliceEnd));
      placeholder += fieldsPerColumn;

      fieldBoxArray.push(fieldColumnsArray);
    }

    return fieldBoxArray;
  };

  const renderColumns = column => {
    return column.map(field => (
      <div>
        <span className="sample-hub__column-field-name">
          {field.parameterName}
        </span>
        <p>{field.value}</p>
      </div>
    ));
  };

  const renderFieldBoxes = () => {
    const fieldBoxes = getFieldBoxArray();

    return (
      <Fragment>
        {fieldBoxes.map(fieldBox => (
          <div className="sample-hub__print-view__box">
            <div className="sample-hub__print-view__field-container">
              <div className="sample-hub__print-view__column">
                {renderColumns(fieldBox[0])}
              </div>
              <div className="sample-hub__print-view__column">
                {fieldBox[1] && renderColumns(fieldBox[1])}
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  const renderTable = () => {
    const { date, name, templateName } = record;

    return (
      <table>
        <tbody>
          <tr className="sample-hub__print-view__field-header">
            <td>SAMPLE NAME</td>
          </tr>
          <tr>
            <td className="sample-hub__print-view__sample-name">{name}</td>
          </tr>
          <tr>
            <td>
              <div className="sample-hub__print-view__hr" />
            </td>
          </tr>
          <tr className="sample-hub__print-view__field-header">
            <td>DATE CREATED</td>
            <td>LAYOUT USED</td>
          </tr>
          <tr style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
            <td>{generateTimeString(date)}</td>
            <td>{templateName}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <NewWindow
      onUnload={closePrintModal}
      onBlock={closePrintModal}
      features={{ width: "675px", height: "675px" }}
      copyStyles
    >
      <div className="sample-hub__print-view">
        <div
          className="sample-hub__record"
          style={{ justifyContent: "center" }}
        >
          <div
            className="sample-hub__record-left"
            style={{ marginRight: "20px" }}
          >
            <QRCodeGenerator
              qrText={createQRText(id, shortName)}
              width={143}
              height={143}
            />
            <span style={{ marginBottom: 30, fontWeight: 600 }}>
              {shortName}
            </span>
          </div>
          <div
            className="sample-hub__record-right"
            style={{
              justifyContent: "space-between",
              width: "260px",
              marginLeft: "15px",
            }}
          >
            {renderTable()}
          </div>
        </div>
        {renderFieldBoxes()}
      </div>
    </NewWindow>
  );
};

export default PrintListView;
