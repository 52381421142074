import { ReviewRule, RawRunReview } from "js-common";
import { BulkReviewFilter, BulkRunBlock } from "../RunsPage/types";

import * as t from "./actionTypes";
import {
  RunReview,
  PreviousReviewUser,
  PendingRun,
  ReviewDashboardFilter,
  BulkReviewRun,
  BulkReviewSubmissionStatus,
} from "./types";

export interface IInitializeRunReviewRequest {
  type: t.T_INITIALIZE_RUN_REVIEW_REQUEST;
  runId: string;
}

export const initializeRunReviewRequest = (
  runId: string
): IInitializeRunReviewRequest => {
  return {
    type: t.INITIALIZE_RUN_REVIEW_REQUEST,
    runId,
  };
};

export interface IInitializeRunReviewSuccess {
  type: t.T_INITIALIZE_RUN_REVIEW_SUCCESS;
  runReview: RunReview;
  reviewLog?: Array<RawRunReview>;
}

export const initializeRunReviewSuccess = (
  runReview: RunReview,
  reviewLog?: Array<RawRunReview>
): IInitializeRunReviewSuccess => {
  return {
    type: t.INITIALIZE_RUN_REVIEW_SUCCESS,
    runReview,
    reviewLog,
  };
};

export interface ISetPreviousReviewUser {
  type: t.T_SET_PREVIOUS_REVIEW_USER;
  user: PreviousReviewUser;
}

export const setPreviousReviewUser = (
  user: PreviousReviewUser
): ISetPreviousReviewUser => {
  return {
    type: t.SET_PREVIOUS_REVIEW_USER,
    user,
  };
};

export interface ISetRunComments {
  type: t.T_SET_RUN_COMMENTS;
  comments: string;
}

export const setRunComments = (comments: string): ISetRunComments => {
  return {
    type: t.SET_RUN_COMMENTS,
    comments,
  };
};

export interface ISetSampleType {
  type: t.T_SET_SAMPLE_TYPE;
  sampleId: string;
  sampleType: string | null;
}

export const setSampleType = (
  sampleId: string,
  sampleType: string | null
): ISetSampleType => {
  return {
    type: t.SET_SAMPLE_TYPE,
    sampleId,
    sampleType,
  };
};

export interface ISetSampleBlank {
  type: t.T_SET_SAMPLE_BLANK;
  sampleId: string;
  blank: boolean;
}

export const setSampleBlank = (
  sampleId: string,
  blank: boolean
): ISetSampleBlank => {
  return {
    type: t.SET_SAMPLE_BLANK,
    sampleId,
    blank,
  };
};

export interface ISetSampleComments {
  type: t.T_SET_SAMPLE_COMMENTS;
  sampleId: string;
  comments: string;
}

export const setSampleComments = (
  sampleId: string,
  comments: string
): ISetSampleComments => {
  return {
    type: t.SET_SAMPLE_COMMENTS,
    sampleId,
    comments,
  };
};

export interface ISetTargetCq {
  type: t.T_SET_TARGET_CQ;
  sampleId: string;
  targetId: string;
  cq: number;
}

export const setTargetCq = (
  sampleId: string,
  targetId: string,
  cq: number
): ISetTargetCq => {
  return {
    type: t.SET_TARGET_CQ,
    sampleId,
    targetId,
    cq,
  };
};

export interface ISetTargetOverride {
  type: t.T_SET_TARGET_OVERRIDE;
  sampleId: string;
  targetId: string;
  overrideCq: boolean | null;
}

export const setTargetOverride = (
  sampleId: string,
  targetId: string,
  overrideCq: boolean | null
): ISetTargetOverride => {
  return {
    type: t.SET_TARGET_OVERRIDE,
    sampleId,
    targetId,
    overrideCq,
  };
};

export interface ISetTargetComments {
  type: t.T_SET_TARGET_COMMENTS;
  sampleId: string;
  targetId: string;
  comments: string;
}

export const setTargetComments = (
  sampleId: string,
  targetId: string,
  comments: string
): ISetTargetComments => {
  return {
    type: t.SET_TARGET_COMMENTS,
    sampleId,
    targetId,
    comments,
  };
};
export interface ISubmitRunReviewRequest {
  type: t.T_SUBMIT_RUN_REVIEW_REQUEST;
  resolve: any;
  reject: any;
}

export const submitRunReviewRequest = (
  resolve: any,
  reject: any
): ISubmitRunReviewRequest => {
  return {
    type: t.SUBMIT_RUN_REVIEW_REQUEST,
    resolve,
    reject,
  };
};

export interface ISetReviewRules {
  type: t.T_SET_REVIEW_RULES;
  rules: ReviewRule;
}

export const setReviewRules = (rules: ReviewRule): ISetReviewRules => {
  return {
    type: t.SET_REVIEW_RULES,
    rules,
  };
};

export interface IApproveAllTargets {
  type: t.T_APPROVE_ALL_TARGETS;
}

export const approveAllTargets = (): IApproveAllTargets => {
  return {
    type: t.APPROVE_ALL_TARGETS,
  };
};

export interface IExportQALog {
  type: t.T_EXPORT_QA_LOG;
}

export const exportQALog = (): IExportQALog => {
  return {
    type: t.EXPORT_QA_LOG,
  };
};

export interface IExportAllQALog {
  type: t.T_EXPORT_ALL_QA_LOG;
  resolve: any;
  reject: any;
}

export const exportAllQALog = (resolve: any, reject: any): IExportAllQALog => {
  return {
    type: t.EXPORT_ALL_QA_LOG,
    resolve,
    reject,
  };
};

export interface IInitializeReviewDashboardRequest {
  type: t.T_INITIALIZE_REVIEW_DASHBOARD_REQUEST;
}

export const initializeReviewDashboardRequest =
  (): IInitializeReviewDashboardRequest => {
    return {
      type: t.INITIALIZE_REVIEW_DASHBOARD_REQUEST,
    };
  };

export interface ISetReviewDashboardLoading {
  type: t.T_SET_REVIEW_DASHBOARD_LOADING;
  isLoading: boolean;
}

export const setReviewDashboardLoading = (
  isLoading: boolean
): ISetReviewDashboardLoading => {
  return {
    type: t.SET_REVIEW_DASHBOARD_LOADING,
    isLoading,
  };
};

export interface IInitializeReviewDashboardSuccess {
  type: t.T_INITIALIZE_REVIEW_DASHBOARD_SUCCESS;
  runs: Array<PendingRun>;
}

export const initializeReviewDashboardSuccess = (
  runs: Array<PendingRun>
): IInitializeReviewDashboardSuccess => {
  return {
    type: t.INITIALIZE_REVIEW_DASHBOARD_SUCCESS,
    runs,
  };
};

export interface ISetActiveRunFilter {
  type: t.T_SET_ACTIVE_RUN_FILTER;
  filter: ReviewDashboardFilter;
}

export const setActiveRunFilter = (
  filter: ReviewDashboardFilter
): ISetActiveRunFilter => {
  return {
    type: t.SET_ACTIVE_RUN_FILTER,
    filter,
  };
};

export interface IExportReviewSummary {
  type: t.T_EXPORT_REVIEW_SUMMARY;
}

export const exportReviewSummary = (): IExportReviewSummary => {
  return {
    type: t.EXPORT_REVIEW_SUMMARY,
  };
};

export interface IToggleBulkReviewModal {
  type: t.T_TOGGLE_BULK_REVIEW_MODAL;
}

export const toggleBulkReviewModal = (): IToggleBulkReviewModal => {
  return {
    type: t.TOGGLE_BULK_REVIEW_MODAL,
  };
};
export interface IInitializeBulkReviewRequest {
  type: t.T_INITIALIZE_BULK_REVIEW_REQUEST;
  date: Date;
  resolve: () => true;
  reject: () => false;
}

export const initializeBulkReviewRequest = (
  date: Date,
  resolve: any,
  reject: any
): IInitializeBulkReviewRequest => {
  return {
    type: t.INITIALIZE_BULK_REVIEW_REQUEST,
    date,
    resolve,
    reject,
  };
};

export interface IInitializeBulkReviewSuccess {
  type: t.T_INITIALIZE_BULK_REVIEW_SUCCESS;
  runs: Array<BulkReviewRun>;
}

export const initializeBulkReviewSuccess = (
  runs: Array<BulkReviewRun>
): IInitializeBulkReviewSuccess => {
  return {
    type: t.INITIALIZE_BULK_REVIEW_SUCCESS,
    runs,
  };
};

export interface IUpdateBulkReviewFilter {
  type: t.T_BULK_REVIEW_UPDATE_FILTER;
  filter: BulkReviewFilter;
}

export const updateBulkReviewFilter = (
  filter: BulkReviewFilter
): IUpdateBulkReviewFilter => {
  return {
    type: t.BULK_REVIEW_UPDATE_FILTER,
    filter,
  };
};

export interface ISetBulkReviewRunBlocks {
  type: t.T_BULK_REVIEW_SET_RUN_BLOCKS;
  bulkRunBlocks: BulkRunBlock[];
}

export const setBulkReviewRunBlocks = (
  bulkRunBlocks: BulkRunBlock[]
): ISetBulkReviewRunBlocks => {
  return {
    type: t.BULK_REVIEW_SET_RUN_BLOCKS,
    bulkRunBlocks,
  };
};

export interface IDeactivateBulkRunBlock {
  type: t.T_BULK_REVIEW_DEACTIVATE_RUN_BLOCK;
  runId: string;
}

export const deactivateBulkRunBlock = (
  runId: string
): IDeactivateBulkRunBlock => {
  return {
    type: t.BULK_REVIEW_DEACTIVATE_RUN_BLOCK,
    runId,
  };
};

export interface IActivateBulkRunBlock {
  type: t.T_BULK_REVIEW_ACTIVATE_RUN_BLOCK;
  runId: string;
}

export const activateBulkRunBlock = (runId: string): IActivateBulkRunBlock => {
  return {
    type: t.BULK_REVIEW_ACTIVATE_RUN_BLOCK,
    runId,
  };
};

export interface IBulkReviewApproveAllRequest {
  type: t.T_BULK_REVIEW_APPROVE_ALL_REQUEST;
  resolve: (message: string) => void;
  reject: (message: string) => void;
}

export const bulkReviewApproveAllRequest = (
  resolve: (message: string) => void,
  reject: (message: string) => void
): IBulkReviewApproveAllRequest => {
  return {
    type: t.BULK_REVIEW_APPROVE_ALL_REQUEST,
    resolve,
    reject,
  };
};

export interface IBulkReviewApproveAllSuccess {
  type: t.T_BULK_REVIEW_APPROVE_ALL_SUCCESS;
}

export const bulkReviewApproveAllSuccess = (): IBulkReviewApproveAllSuccess => {
  return {
    type: t.BULK_REVIEW_APPROVE_ALL_SUCCESS,
  };
};

export interface IBulkReviewSetReviewSubmissionStatus {
  type: t.T_BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS;
  bulkApprovedRun: BulkReviewSubmissionStatus;
}

export const bulkReviewSetReviewSubmissionStatus = (
  bulkApprovedRun: BulkReviewSubmissionStatus
): IBulkReviewSetReviewSubmissionStatus => {
  return {
    type: t.BULK_REVIEW_SET_REVIEW_SUBMISSION_STATUS,
    bulkApprovedRun,
  };
};

export interface IBulkReviewReset {
  type: t.T_BULK_REVIEW_RESET;
}

export const bulkReviewReset = (): IBulkReviewReset => {
  return {
    type: t.BULK_REVIEW_RESET,
  };
};

export interface ISetBulkTabularFailedRunIds {
  type: t.T_BULK_REVIEW_SET_FAILED_RUN_IDS;
  failedRunIds: string[];
}

export const setBulkTabularFailedRunIds = (
  failedRunIds: string[]
): ISetBulkTabularFailedRunIds => {
  return {
    type: t.BULK_REVIEW_SET_FAILED_RUN_IDS,
    failedRunIds,
  };
};

export type Action =
  | IInitializeRunReviewRequest
  | IInitializeRunReviewSuccess
  | ISetPreviousReviewUser
  | ISetRunComments
  | ISetSampleComments
  | ISetSampleType
  | ISetSampleBlank
  | ISetTargetCq
  | ISetTargetOverride
  | ISetTargetComments
  | ISubmitRunReviewRequest
  | ISetReviewRules
  | IApproveAllTargets
  | IExportQALog
  | IExportAllQALog
  | IInitializeReviewDashboardRequest
  | ISetReviewDashboardLoading
  | IInitializeReviewDashboardSuccess
  | ISetActiveRunFilter
  | IExportReviewSummary
  | IToggleBulkReviewModal
  | IInitializeBulkReviewRequest
  | IInitializeBulkReviewSuccess
  | IUpdateBulkReviewFilter
  | ISetBulkReviewRunBlocks
  | IDeactivateBulkRunBlock
  | IActivateBulkRunBlock
  | IBulkReviewApproveAllRequest
  | IBulkReviewApproveAllSuccess
  | IBulkReviewSetReviewSubmissionStatus
  | IBulkReviewReset
  | ISetBulkTabularFailedRunIds;
