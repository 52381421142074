const styles = {
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "15px",
  },
  channelCircle: {
    border: "none",
    pointerEvents: "none",
    marginTop: 5,
  },
  incompleteIconMargins: { marginRight: "10px" },
  runTableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  activeTable: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  modalNavigation: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "20px",
    paddingRight: "20px",
    borderRight: "1px solid #E1E1E1",
  },
  helpText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    marginRight: "15px",
  },
  modalStyle: {
    width: "400px !important",
    height: "640px !important",
  },
};

export default styles;
