import React from "react";
import ReactTooltip from "react-tooltip";

import { tooltipDelay } from "../../RunsPage/constants";
import AddIconButton from "../../Utils/Layout/AddIconButton";
import EditIconButton from "../../Utils/Layout/EditIconButton";
import DeleteIconButton from "../../Utils/Layout/DeleteIconButton";
import Button from "../../Layout/Button";

const Template = props => {
  const { template } = props;
  const { metadata = [], id } = template;

  const columnOneSliceIndex = Math.ceil(metadata.length / 3);
  const columnTwoSliceIndex = Math.ceil((2 * metadata.length) / 3);
  const column1Fields = metadata.slice(0, columnOneSliceIndex);
  const column2Fields = metadata.slice(
    columnOneSliceIndex,
    columnTwoSliceIndex
  );
  const column3Fields = metadata.slice(columnTwoSliceIndex, metadata.length);

  const renderColumnFields = (column, columnNumber) => {
    return (
      <div className="sample-hub__template-column">
        {column.map(field => {
          const { parameterName, type, defaultValue } = field;

          let fieldDisplayValue = type;
          if (defaultValue.length > 0) {
            fieldDisplayValue = defaultValue;
          }

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 28,
                width: "100%",
              }}
              key={`${field}-column`}
            >
              <div
                data-tip={parameterName}
                style={{
                  fontWeight: 600,
                  marginBottom: 10,
                }}
              >
                <span className="sample-hub__column-field-name">
                  {parameterName}
                </span>
              </div>
              <div
                key={`${field}-${columnNumber}`}
                data-tip={parameterName}
                style={{
                  width: "80%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <span>{fieldDisplayValue}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderButtons = () => {
    const { handleDelete, handleEdit, handleCreateRecord } = props;
    const wrappedAddIcon = () => <AddIconButton style={{ marginRight: 10 }} />;
    const wrappedEditIcon = () => (
      <EditIconButton style={{ marginRight: 10 }} />
    );
    const wrappedDeleteIcon = () => (
      <DeleteIconButton style={{ marginRight: 10 }} />
    );

    return (
      <div className="sample-hub__template-button-container">
        <Button
          title="Create QR Code"
          onClick={() => handleCreateRecord(id)}
          buttonClass="sample-hub__table-button"
          id="sample-hub__create-qr-button"
          icon={wrappedAddIcon}
        />
        <Button
          title="Edit Layout"
          onClick={() => handleEdit(id)}
          buttonClass="edit_sample_layout_button"
          icon={wrappedEditIcon}
        />
        <Button
          title="Delete"
          onClick={() => handleDelete(id, "template")}
          buttonClass="delete_sample_button"
          icon={wrappedDeleteIcon}
        />
      </div>
    );
  };

  return (
    <div className="sample-hub__table-view">
      <ReactTooltip delayShow={tooltipDelay} />
      <div className="sample-hub__template-container">
        {renderColumnFields(column1Fields, 0)}
        {renderColumnFields(column2Fields, 1)}
        {renderColumnFields(column3Fields, 2)}
      </div>

      {renderButtons()}
    </div>
  );
};

export default Template;
