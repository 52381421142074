import { createSelector } from "reselect";

import {
  buildBaselineChart,
  buildRawChart,
  buildPrintChart,
  buildSingleThresholdChart,
  checkActiveChannels,
  computeTabularData,
  sortTabularDataByWell,
  sortTabularData,
} from "./helpers";
import { getActiveTeamVersion } from "../Auth/selectors";
import { getRunSelectionType } from "../Hub/selectors";
import { getFullRunData } from "../FullRunData/selectors";
import { desiredChannelOrder, chartTypes, analysisModes } from "./constants";
import { runTypes, EXTERNAL_DETAILS_VERSIONS } from "../Hub/constants";

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// STATE SELECTORS
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

export const getPrintIndex = state => {
  return state.runsPage.printIndex;
};
export const getMetrics = state => {
  return state.runsPage.metrics;
};
export const getEndRfuMetrics = state => {
  return state.runsPage.endRfuMetrics;
};

export const getRunBlocks = state => {
  return state.runsPage.runBlocks;
};

export const getChartType = state => {
  return state.runsPage.chartTypeSettings;
};
export const getAnalysisMode = state => {
  return state.runsPage.analysisModeSettings;
};
export const isBaselineChartActive = state => {
  return state.runsPage.chartTypeSettings[chartTypes.baseline];
};
export const isRawChartActive = state => {
  return state.runsPage.chartTypeSettings[chartTypes.raw];
};
export const isTabularDataActive = state => {
  return state.runsPage.showTabularData;
};
export const areSingleThresholdsEnabled = state => {
  return state.runsPage.chartTypeSettings[chartTypes.singleThreshold];
};
export const isReprocessingModeActive = state => {
  return state.runsPage.analysisModeSettings[analysisModes.reprocessing];
};
export const isReviewModeActive = state => {
  return state.runsPage.analysisModeSettings[analysisModes.review];
};
export const isBulkReviewModeActive = state => {
  return state.runsPage.analysisModeSettings[analysisModes.bulkReview];
};

export const getTargetData = state => {
  return state.runsPage.data;
};
export const getActiveTargetData = state => {
  return state.runsPage.data.filter(line => {
    return line.showLine && !line.hideTarget;
  });
};
export const getInactiveTargetData = state => {
  return state.runsPage.data.filter(line => {
    return !line.showLine;
  });
};
export const getXAxis = state => {
  return state.runsPage.xAxis;
};
export const isLogScale = state => {
  return state.runsPage.isLogScale;
};
export const isNonindexedXAxis = state => {
  return state.runsPage.xAxis.data && state.runsPage.xAxis.data.length > 0;
};
export const getYAxis = state => {
  return state.runsPage.yAxis;
};

export const isPrintModalActive = state => {
  return state.runsPage.showPrintModal;
};
export const getShowDetailedModal = state => {
  return state.runsPage.showDetailedModal;
};
export const getShowExportModal = state => {
  return state.runsPage.showExportModal;
};
export const getShowExportReviewModal = state => {
  return state.runsPage.showExportReviewModal;
};
export const isAnalysisModalOpen = state => {
  return state.runsPage.showAnalysisModal;
};
export const getTutorialState = state => {
  return state.runsPage.showTutorial;
};

export const getWellFilters = state => {
  return state.runsPage.wellFilters;
};
export const getAllChannels = state => {
  return state.runsPage.channelFilters;
};

export const getTabularDataOrder = state => {
  return state.runsPage.tabularData.order;
};
export const getTabularDataFilter = state => {
  return state.runsPage.tabularData.filter;
};

export const getBackgroundValues = state => {
  return state.runsPage.backgroundValues;
};

export const getReprocessingError = state => {
  return state.runsPage.reprocessingError;
};

export const getBackgroundReprocessingError = state => {
  return state.runsPage.chartBackgroundValuesUpdateError;
};

export const getReviewModeError = state => {
  return state.runsPage.reviewModeError;
};

export const isCyclesDisplayedOpen = state => {
  return state.runsPage.showCyclesDisplayedModal;
};
export const getCyclesDisplayedLowerBound = state => {
  return state.runsPage.lowerBound;
};
export const getCyclesDisplayedUpperBound = state => {
  return state.runsPage.upperBound;
};

export const getTutorialRunsBlocksOpenState = state => {
  return state.runsPage.tutorialRunBlockOpenState;
};

export const bulkReviewSummaryModalIsOpen = state => {
  return state.runsPage.showBulkReviewSummaryModal;
};
export const getBulkReviewFilter = state => {
  return state.runsPage.bulkReviewFilter;
};

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
// DERIVED STATE SELECTORS
// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

export const getCurrentAnalysisMode = createSelector(
  [getAnalysisMode],
  analysisModeSettings => {
    if (analysisModeSettings[analysisModes.singleThreshold]) {
      return "Single Threshold";
    }

    if (analysisModeSettings[analysisModes.baseline]) {
      return "Multi Threshold";
    }

    if (analysisModeSettings[analysisModes.reprocessing]) {
      return "Reprocessing";
    }

    if (analysisModeSettings[analysisModes.review]) {
      return "Review";
    }

    return "Multi Threshold";
  }
);

export const getChannelNames = createSelector([getAllChannels], allChannels => {
  return allChannels.map(channel => {
    return channel.label.toLowerCase();
  });
});

export const getTargetsByRun = (state, props) => {
  return state.runsPage.data.filter(target => {
    return target.runId === props.runId;
  });
};

export const getTargetMatrix = createSelector(
  [getWellFilters, getAllChannels, getFullRunData],
  (wells, channels, runData) => {
    const matrix = {};

    channels.forEach(channel => {
      matrix[channel.label.toLowerCase()] = wells.map(well => {
        let hidden = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const run of runData) {
          const associatedTarget = run.targets.find(target => {
            return (
              target.wellNumber === well.label &&
              target.emissionColor === channel.label.toLowerCase()
            );
          });
          if (associatedTarget && !associatedTarget.hidden) {
            hidden = false;
            break;
          }
        }

        return {
          well: well.label,
          active: well.checked && channel.checked,
          hidden,
        };
      });
    });

    return matrix;
  }
);

export const getBioRadTargetMatrix = createSelector(
  [getWellFilters, getFullRunData],
  (wells, runData) => {
    const [selectedRun] = runData;
    const { targets } = selectedRun;

    const matrix = wells.map(well => {
      const { label, checked } = well;

      let hidden = true;

      const targetWithMatchingWellThatIsntHidden = targets.find(target => {
        return target.wellNumber === label && !target.hidden;
      });

      // Since our master table covers all three channels, we won't mark a well as hidden
      // so long as at least one target in that well is not hidden
      if (targetWithMatchingWellThatIsntHidden) {
        hidden = false;
      }

      return {
        well: label,
        active: checked,
        hidden,
      };
    });

    return matrix;
  }
);

export const getShortestLengthOfActiveRuns = createSelector(
  [getRunBlocks],
  runBlocks => {
    const maxCycles = [];

    runBlocks.forEach(run => {
      if (run.active) {
        maxCycles.push(run.shortestTargetLength);
      }
    });

    return Math.min(...maxCycles);
  }
);

export const getTabularData = createSelector(
  [
    getRunBlocks,
    getTargetData,
    getAnalysisMode,
    getTabularDataFilter,
    getTabularDataOrder,
    getActiveTeamVersion,
    getFullRunData,
  ],
  (
    runBlocks,
    targetData,
    analysisModeSettings,
    filter,
    order,
    version,
    fullRunData
  ) => {
    const activeRunBlocks = {};

    runBlocks.forEach(runBlock => {
      if (runBlock.active) {
        activeRunBlocks[runBlock.runId] = runBlock.name;
      }
    });

    const activeTargets = targetData.filter(target => {
      return activeRunBlocks[target.runId] && target.showLine;
    });

    const tabularData = computeTabularData(
      activeTargets,
      analysisModeSettings,
      version,
      fullRunData,
      activeRunBlocks
    );

    const wellSortedTabularData = sortTabularDataByWell(tabularData);

    let sortedTabularData = sortTabularData(wellSortedTabularData, filter);
    if (order === 1) {
      sortedTabularData = sortedTabularData.reverse();
    }

    return sortedTabularData;
  }
);

export const getSelectedTestDetailsForPrinting = createSelector(
  [getFullRunData, getPrintIndex],
  (fullRunData, printIndex) => {
    const { protocol, details, name, date } = fullRunData.find(run => {
      return run.id === printIndex;
    });

    return {
      protocolName: protocol,
      notes: details.notes,
      locationString: details.locationString,
      runName: name,
      date,
      isQuant: details.isQuant,
      BMStandardCurveId: details.BMStandardCurveId,
    };
  }
);

export const areLineThresholdsEnabled = createSelector(
  [getRunBlocks],
  runsBlock => {
    const activeThresholds = runsBlock.filter(runBlock => {
      return runBlock.threshold && runBlock.active;
    });

    return activeThresholds.length > 0;
  }
);

export const areLineThresholdsAndTargetsEnabled = createSelector(
  [getRunBlocks, getActiveTargetData],
  (runsBlock, activeTargetData) => {
    const activeRuns = runsBlock.filter(runBlock => {
      return runBlock.threshold && runBlock.active;
    });

    const activeRunIds = activeRuns.map(activeRun => {
      return activeRun.runId;
    });
    const activeTargets = activeTargetData.filter(target => {
      return activeRunIds.includes(target.runId);
    });

    return activeTargets.length > 0;
  }
);

export const getSingleThresholds = createSelector(
  [getActiveTargetData],
  activeTargetData => {
    const greenST = { threshold: 0, channel: "green" };
    const amberST = { threshold: 0, channel: "amber" };
    const redST = { threshold: 0, channel: "red" };
    const thresholds = [];

    const green = activeTargetData.find(line => {
      return line.channel.toLowerCase() === "green" && line.showLine;
    });
    const amber = activeTargetData.find(line => {
      return line.channel.toLowerCase() === "amber" && line.showLine;
    });
    const red = activeTargetData.find(line => {
      return line.channel.toLowerCase() === "red" && line.showLine;
    });

    if (green && typeof green.singleThreshold === "number") {
      greenST.threshold = green.singleThreshold.toFixed(6);
      thresholds.push(greenST);
    }
    if (amber && typeof amber.singleThreshold === "number") {
      amberST.threshold = amber.singleThreshold.toFixed(6);
      thresholds.push(amberST);
    }
    if (red && typeof red.singleThreshold === "number") {
      redST.threshold = red.singleThreshold.toFixed(6);
      thresholds.push(redST);
    }

    return thresholds;
  }
);

export const getLineThresholds = createSelector(
  [getActiveTargetData, getRunBlocks],
  (activeTargetData, runBlocks) => {
    const thresholds = [];
    activeTargetData.forEach(target => {
      const runBlock = runBlocks.find(block => {
        return block.runId === target.runId;
      });

      if (runBlock.threshold) {
        thresholds.push({
          color: target.color,
          channel: target.channel,
          well: +target.well + 1,
          name: target.targetName,
          CQ: target.CQ.toFixed(2),
          threshold: target.threshold.toFixed(6),
          id: target.id,
        });
      }
    });

    thresholds.sort((x, y) => {
      const { channel: xChannel, well: xWell } = x;
      const { channel: yChannel, well: yWell } = y;

      let sortResult = 1;

      if (xChannel === yChannel) {
        sortResult = xWell - yWell;
      } else if (
        desiredChannelOrder.indexOf(xChannel.toLowerCase()) <
        desiredChannelOrder.indexOf(yChannel.toLowerCase())
      ) {
        sortResult = -1;
      }

      return sortResult;
    });

    return thresholds;
  }
);

export const getChartData = createSelector(
  [
    getActiveTargetData,
    getChartType,
    getXAxis,
    getRunBlocks,
    getFullRunData,
    getCyclesDisplayedLowerBound,
    getCyclesDisplayedUpperBound,
  ],
  (
    activeTargetData,
    chartTypeSettings,
    xAxis,
    runBlocks,
    fullRunData,
    lowerBound,
    upperBound
  ) => {
    const [firstRun] = runBlocks;
    const { type, runId } = firstRun;

    let newActiveTargetData = activeTargetData;

    const activeChartType = Object.keys(chartTypeSettings).find(key => {
      return chartTypeSettings[key] === true;
    });

    if (type === "Melt Run") {
      const fullRun =
        fullRunData.find(run => {
          return run.id === runId;
        }) || {};
      const { protocol = {} } = fullRun;
      const { meltTempStart, meltTempFinal } = protocol;

      newActiveTargetData = activeTargetData.map(target => {
        const { meltTemperatures } = target;

        const newTarget = {
          ...target,
        };

        if (meltTempStart > meltTempFinal && !Array.isArray(meltTemperatures)) {
          newTarget.meltTemperatures = meltTemperatures.sort((a, b) => {
            return a - b;
          });
          newTarget.baselineValues = [...target.baselineValues];
          newTarget.rawValues = [...target.rawValues];
          newTarget.values = [...target.values];

          newTarget.baselineValues.reverse();
          newTarget.rawValues.reverse();
          newTarget.values.reverse();

          return newTarget;
        }

        return newTarget;
      });
    }

    switch (activeChartType) {
      case chartTypes.baseline:
        return buildBaselineChart(
          newActiveTargetData,
          runBlocks,
          lowerBound,
          upperBound
        );
      case chartTypes.singleThreshold:
        return buildSingleThresholdChart(
          newActiveTargetData,
          lowerBound,
          upperBound
        );
      case chartTypes.raw:
        return buildRawChart(newActiveTargetData, lowerBound, upperBound);
      default:
        return buildBaselineChart(
          newActiveTargetData,
          runBlocks,
          lowerBound,
          upperBound
        );
    }
  }
);

export const getZoomedYAxis = createSelector(
  [
    getActiveTargetData,
    getCyclesDisplayedLowerBound,
    getCyclesDisplayedUpperBound,
  ],
  (activeTargetData, lowerBound, upperBound) => {
    const yMax = activeTargetData.map(target => {
      return Math.max(...target.values.slice(lowerBound, upperBound));
    });
    const yMin = activeTargetData.map(target => {
      return Math.min(...target.values.slice(lowerBound, upperBound));
    });

    return { min: Math.min(...yMin), max: Math.max(...yMax) };
  }
);

export const getAxisLabels = createSelector(
  getRunSelectionType,
  runSelectionType => {
    const axisLabels = {
      x: "Cycles",
      y: "RFU",
    };

    if (runSelectionType === runTypes.melt) {
      axisLabels.x = "°C";
    }

    return axisLabels;
  }
);

export const getPrintChartData = createSelector(
  [getTargetData, getXAxis, getRunBlocks, getPrintIndex],
  (targetData, xAxis, runBlocks, printIndex) => {
    const targetsToDisplay = targetData.filter(target => {
      return target.runId === printIndex;
    });

    // Get the list of chart type keys. Find the one that is true. Return that key, pull it out of the returned array from filter.
    return buildPrintChart(targetsToDisplay, xAxis.length, runBlocks);
  }
);

export const getPrintViewYAxis = createSelector(
  [getTargetData, getPrintIndex],
  (targetData, printIndex) => {
    const runTargets = targetData.filter(target => {
      return target.runId === printIndex;
    });
    const yMax = runTargets.map(target => {
      return Math.max(...target.values);
    });
    const yMin = runTargets.map(target => {
      return Math.min(...target.values);
    });

    return { yMin: Math.min(...yMin), yMax: Math.max(...yMax) };
  }
);

export const getPrintViewXAxis = createSelector(
  [getTargetData, getPrintIndex],
  (targetData, printIndex) => {
    const runTargets = targetData.filter(target => {
      return target.runId === printIndex;
    });

    return runTargets[0].values.length - 1;
  }
);
export function downselectTargets(parsedRunData, colorMask) {
  const channelActiveStatus = checkActiveChannels(colorMask);

  const filteredTargets = [];
  const filteredTargetResults = [];

  let runHasResultsObject = false;

  if (parsedRunData.result && parsedRunData.result.targetResults) {
    runHasResultsObject = true;
  }

  if (channelActiveStatus.green) {
    parsedRunData.targets.forEach(runTarget => {
      if (runTarget.emissionColor.toLowerCase() === "green") {
        filteredTargets.push(runTarget);
      }
    });

    if (runHasResultsObject) {
      parsedRunData.result.targetResults.forEach(resultTarget => {
        if (resultTarget.emissionColor.toLowerCase() === "green") {
          filteredTargetResults.push(resultTarget);
        }
      });
    }
  }

  if (channelActiveStatus.amber) {
    parsedRunData.targets.forEach(runTarget => {
      if (runTarget.emissionColor.toLowerCase() === "amber") {
        filteredTargets.push(runTarget);
      }
    });

    if (runHasResultsObject) {
      parsedRunData.result.targetResults.forEach(resultTarget => {
        if (resultTarget.emissionColor.toLowerCase() === "amber") {
          filteredTargetResults.push(resultTarget);
        }
      });
    }
  }

  if (channelActiveStatus.red) {
    parsedRunData.targets.forEach(runTarget => {
      if (runTarget.emissionColor.toLowerCase() === "red") {
        filteredTargets.push(runTarget);
      }
    });

    if (runHasResultsObject) {
      parsedRunData.result.targetResults.forEach(resultTarget => {
        if (resultTarget.emissionColor.toLowerCase() === "red") {
          filteredTargetResults.push(resultTarget);
        }
      });
    }
  }

  if (runHasResultsObject) {
    const result = { ...parsedRunData.result };
    result.targetResults = filteredTargetResults;

    return {
      ...parsedRunData,
      targets: filteredTargets,
      result,
    };
  }

  return {
    ...parsedRunData,
    targets: filteredTargets,
  };
}

export const getRunDataForExport = createSelector(
  [getFullRunData, getChartType, getTargetData, getActiveTeamVersion],
  (runs, chartTypeSettings, targetData, activeTeamVersion) => {
    const activeChartType = Object.keys(chartTypeSettings).find(key => {
      return chartTypeSettings[key] === true;
    });

    if (
      runs[0] &&
      runs[0].detailsVersion &&
      EXTERNAL_DETAILS_VERSIONS.values.includes(runs[0].detailsVersion)
    ) {
      return [];
    }

    let updatedRuns = [];

    if (activeTeamVersion === "biomeme-2") {
      updatedRuns = runs.map(run => {
        const newTargets = run.targets.map(runTarget => {
          const chartTarget = targetData.find(target => {
            return (
              target.runId === run.id &&
              target.well === runTarget.wellNumber &&
              target.channel === runTarget.emissionColor.toLowerCase()
            );
          });

          let { threshold } = chartTarget;
          const { SQ: chartTargetSQ } = chartTarget;

          if (activeChartType === chartTypes.singleThreshold) {
            threshold = chartTarget.singleThreshold;
          }

          const endRfu = +chartTarget.endRfu ?? +runTarget.endRfu;

          let SQ = null;
          if (typeof chartTarget.SQ === "number") {
            SQ = chartTarget.SQ.toFixed(2);
          } else if (typeof chartTarget.SQ === "string") {
            SQ = chartTargetSQ;
          } else {
            SQ = null;
          }

          const updatedRunTarget = {
            ...runTarget,
            threshold,
            cq: chartTarget.CQ || 0,
            startingQuantity: SQ,
            endRfu,
          };

          delete updatedRunTarget.CQ;

          return updatedRunTarget;
        });

        const updatedRun = {
          ...run,
          targets: newTargets,
        };

        return downselectTargets(updatedRun, run.protocol.colorMask);
      });
    } else {
      updatedRuns = runs.map(run => {
        const { details = {} } = run;
        const { wellData = [] } = details;

        wellData.forEach((well, wellIndex) => {
          const runTargets = well.targets;
          runTargets.map(runTarget => {
            const chartTarget = targetData.find(target => {
              return (
                target.runId === run.id &&
                target.well === wellIndex &&
                target.channel === runTarget.fluorophoreName.toLowerCase()
              );
            });
            let { threshold } = chartTarget;
            const { CQ } = chartTarget;
            if (activeChartType === chartTypes.singleThreshold) {
              threshold = chartTarget.singleThreshold;
            }

            return {
              ...runTarget,
              threshold,
              CQ,
            };
          });
        });

        return run;
      });
    }

    return updatedRuns;
  }
);
