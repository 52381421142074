import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";

import Sidebar from "../../Sidebar";
import { Content } from "../../DataRoute/components/content";
import AddIconButton from "../../Utils/Layout/AddIconButton";
import { tooltipDelay } from "../../RunsPage/constants";
import AppUpdateModal from "../../DataRoute/components/AppUpdateModal";

import SampleView from "./SampleView";
import Button from "../../Layout/Button";
import {
  sampleNavigationTemplateHeaders,
  sampleNavigationRecordHeaders,
} from "../constants";
import { getTemplatesDateFormatted, getTableDisplay } from "../selectors";
import { setTableDisplay } from "../actions";

const SampleNavigation = props => {
  const handleControlClick = display => {
    const { putTableDisplay } = props;

    putTableDisplay(display);
  };

  const renderHeader = () => {
    return (
      <div className="page__left">
        <p
          style={{
            fontSize: "48px",
            fontWeight: "600",
            fontFamily: "Roboto",
          }}
        >
          Sample Management
        </p>
        <h4
          className="sample-hub__header-text"
          style={{ width: 480, marginTop: 50 }}
        >
          Select sample layouts or sample QR codes here. Sample layouts define
          sample metadata parameters and default values, which can be used to
          create a sample metadata record. Sample QR codes and their
          corresponding QR codes can be used to track samples in greater detail
          and generate reports.
        </h4>
      </div>
    );
  };

  const renderControls = () => {
    const { history, templates, tableDisplay } = props;

    const displayTemplate = tableDisplay === "template";
    const disableCreateSample = !displayTemplate && templates.length === 0;
    const wrappedIcon = () => <AddIconButton style={{ marginRight: 10 }} />;

    let route = "/create-sample-qr-code";
    let callToAction = "New QR Code";
    let templateControlOpacity = 0.5;
    let recordControlOpacity = 1.0;
    if (displayTemplate) {
      route = "/create-sample-layout";
      callToAction = "New Layout";
      templateControlOpacity = 1.0;
      recordControlOpacity = 0.5;
    }

    let createSampleDataTip = "";
    if (disableCreateSample) {
      createSampleDataTip = "Create a layout first.";
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: 740,
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{ width: 200 }}>
            <p>
              <span
                className="sample-hub__controls"
                onClick={() => handleControlClick("template")}
                style={{
                  opacity: templateControlOpacity,
                }}
                id="sample-hub__sample-templates-button"
              >
                Sample Layouts
              </span>
            </p>
          </div>
          <div style={{ width: 200 }}>
            <p>
              <span
                className="sample-hub__controls"
                onClick={() => handleControlClick("record")}
                style={{ opacity: recordControlOpacity }}
                id="sample-hub__sample-records-button"
              >
                Sample QR Codes
              </span>
            </p>
          </div>
        </div>
        <div id="sample-hub__call-to-action" style={{ width: 200 }}>
          <ReactTooltip delayShow={tooltipDelay} />
          <span data-tip={createSampleDataTip}>
            <Button
              title={callToAction}
              onClick={() => history.push(route)}
              icon={wrappedIcon}
              buttonStyle={{
                width: "200px",
              }}
              disabled={disableCreateSample}
            />
          </span>
        </div>
      </div>
    );
  };

  const { tableDisplay } = props;

  let sampleViewFields = sampleNavigationTemplateHeaders;
  if (tableDisplay === "record") {
    sampleViewFields = sampleNavigationRecordHeaders;
  }

  return (
    <div className="fluidContainers">
      <AppUpdateModal />
      <Sidebar />
      <Content>
        <div className="page">
          <div
            className="page__container"
            style={{ minWidth: "1000px", height: "100%" }}
          >
            {renderHeader()}
            {renderControls()}
            <SampleView fields={sampleViewFields} />
          </div>
        </div>
      </Content>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    putTableDisplay: display => {
      dispatch(setTableDisplay(display));
    },
  };
}

function mapStateToProps(state) {
  return {
    templates: getTemplatesDateFormatted(state),
    tableDisplay: getTableDisplay(state),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SampleNavigation)
);
