import React from "react";
import { getCrossingPointIndex } from "bm-algorithm-js/lib/calculate";

import {
  AWS_REDIRECT_DOMAIN_DEV,
  AWS_REDIRECT_DOMAIN_LOCAL,
  AWS_REDIRECT_DOMAIN_PROD,
  AWS_REDIRECT_DOMAIN_RC,
  BIO_MODE_DEV,
  BIO_MODE_RC,
  OAUTH_DOMAIN_DEV,
  OAUTH_DOMAIN_RC,
  RECAPTCHA_KEY_DEVELOPMENT,
  RECAPTCHA_KEY_PRODUCTION,
} from "./constants";
import IsothermalIcon from "./Layout/IsothermalIcon";
import QuantitativeIcon from "./Layout/QuantitativeIcon";
import MeltIcon from "./Layout/MeltIcon";
import PCRIcon from "./Layout/PCRIcon";
import IncompleteIcon from "./Layout/IncompleteIcon";

export const getRecaptchaSiteKey = () => {
  const { NODE_ENV, BIO_MODE } = process.env;

  if (NODE_ENV === "production") {
    if (BIO_MODE === BIO_MODE_DEV) {
      return RECAPTCHA_KEY_DEVELOPMENT;
    }

    if (BIO_MODE === BIO_MODE_RC) {
      return RECAPTCHA_KEY_PRODUCTION;
    }

    return RECAPTCHA_KEY_PRODUCTION;
  }

  return RECAPTCHA_KEY_DEVELOPMENT;
};

export const getAwsRedirectDomain = () => {
  const { NODE_ENV, BIO_MODE } = process.env;

  if (NODE_ENV === "production") {
    if (BIO_MODE === BIO_MODE_DEV) {
      return AWS_REDIRECT_DOMAIN_DEV;
    }

    if (BIO_MODE === BIO_MODE_RC) {
      return AWS_REDIRECT_DOMAIN_RC;
    }

    return AWS_REDIRECT_DOMAIN_PROD;
  }

  return AWS_REDIRECT_DOMAIN_LOCAL;
};

export const getOauthDomain = () => {
  const { BIO_MODE } = process.env;

  switch (BIO_MODE) {
    case BIO_MODE_DEV: {
      return OAUTH_DOMAIN_DEV;
    }
    case BIO_MODE_RC: {
      return OAUTH_DOMAIN_RC;
    }
    default:
      return OAUTH_DOMAIN_RC;
  }
};

export function genQueryByIds(key, ids) {
  const query = ids
    .map(id => `${key}="${id}"`)
    .reduce((q, id, index) => {
      if (index !== 0) {
        return `${q} or ${id}`;
      }

      return `${id}`;
    }, "");

  return query;
}

export function genAllWellsGet(runObjs) {
  const get = [];
  runObjs.forEach(obj => get.push(obj.BMAllWellsId));

  return get;
}

export function genStdCurveGet(runObjs) {
  const get = [];
  runObjs.forEach(obj => {
    if (obj.BMStandardCurveId && get.indexOf(obj.BMStandardCurveId) === -1) {
      get.push(obj.BMStandardCurveId);
    }
  });

  return get;
}

export function addStdCurveAndAllWellsData(
  selectedRuns,
  stdCurveData,
  wellsData
) {
  const updatedRunObjects = selectedRuns.map(run => {
    let updatedRun = { ...run };
    if (wellsData && wellsData[run.BMAllWellsId]) {
      updatedRun = { ...updatedRun, allWellsData: wellsData[run.BMAllWellsId] };
    }
    if (stdCurveData && [run.BMStandardCurveId]) {
      updatedRun = {
        ...updatedRun,
        stdCurveData: stdCurveData[run.BMStandardCurveId],
      };
    }

    return updatedRun;
  });

  return updatedRunObjects;
}

export function hasTargetName(targetName) {
  return val => {
    let re;
    if (targetName === "+") {
      re = new RegExp(`test[0-9]well[0-9]\\${targetName}[0-9]`);
    } else {
      re = new RegExp(`test[0-9]well[0-9]${targetName}[0-9]`);
    }

    return re.test(val);
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function determineSampleType(sampleTypeNum) {
  switch (sampleTypeNum) {
    case 0:
      return "POS";
    case 1:
      return "UNK";
    case 2:
      return "NEG";
    case 3:
      return "NTC";
    case 4:
      return "STD";
    default:
      return "UNK";
  }
}

export function average(data) {
  if (data.length === 0) {
    return 0;
  }
  const sum = data.reduce((total, value) => total + value, 0);

  const avg = sum / data.length;

  return avg;
}

export function standardDeviation(values) {
  if (values.length === 0) {
    return 0;
  }
  const avg = average(values);

  const squareDiffs = values.map(function (value) {
    const diff = value - avg;
    const sqrDiff = diff * diff;

    return sqrDiff;
  });

  const avgSquareDiff = average(squareDiffs);

  const stdDev = Math.sqrt(avgSquareDiff);

  return stdDev;
}

export function median(data) {
  const sorted = data.sort((a, b) => {
    return a - b;
  });
  const half = Math.floor(sorted.length / 2);

  if (sorted.length % 2) {
    return sorted[half];
  }

  return (sorted[half - 1] + sorted[half]) / 2.0;
}

export function getCrossingPoint(data, threshold, lowBound, highBound) {
  let cq = getCrossingPointIndex(data, threshold, lowBound, highBound);
  if (cq > 0) {
    cq += 1;
  }

  return cq;
}

export function round(number, sigFig) {
  const exponentiation = 10 ** sigFig;

  return Math.round(number * exponentiation) / exponentiation;
}

export const isNumber = n => {
  let result = false;
  if (Number(n) === n) {
    result = true;
  }

  return result;
};

export function sortByKey(inputArray, key) {
  const sortedData = inputArray.sort((a, b) => {
    let value = 0;
    if (a[key].toUpperCase() < b[key].toUpperCase()) {
      value = -1;
    }
    if (a[key].toUpperCase() > b[key].toUpperCase()) {
      value = 1;
    }

    return value;
  });

  return sortedData;
}

export function sortByDate(inputArray) {
  const sortedData = inputArray.sort((a, b) => {
    const x = new Date(a.date);
    const y = new Date(b.date);

    return y - x;
  });

  return sortedData;
}

/**
 * Utility to be composed with the getActiveTeamFlags selector in mapStateToProps to
 * determine if a user has permission to perform a certain action defined in teams_users
 *
 * @param {object} flags The object containing all user-team permissions, activeTeamFlags on state
 * @param {string} key The top level key for accessing the permission of interest in the flags object
 * @param {string | undefined} crudKey An optional second level key if the permission of interest is nested
 *  (e.g. CRUD permissions, {key: {create: true, read: true, ...}})
 *
 * @returns {boolean} Boolean representing if the user has permission for the defined action
 */
export function hasPermission(flags, key, crudKey = undefined) {
  const flag = flags[key];
  if (crudKey) {
    return flag[key];
  }

  return Boolean(flag);
}

/**
 * Helper to format a Date object to a string in YYYY-mm-dd
 * format
 * @param {Date} date The Date instance to format
 * @returns {string} The YYYY-mm-dd formatted date string
 */
export function getFormattedDateString(date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString();

  const values = [year];

  const convertTime = timeMeasurement => {
    let measurement = timeMeasurement.toString();
    if (timeMeasurement < 10) {
      measurement = `0${timeMeasurement}`;
    }

    return measurement;
  };

  values.push(convertTime(month));
  values.push(convertTime(day));

  return values.join("-");
}

const getIcon = (item, isProtocol = false) => {
  const icons = {
    isothermal: <IsothermalIcon />,
    quantitative: <QuantitativeIcon />,
    melt: <MeltIcon />,
    pcr: <PCRIcon />,
  };

  let key = item.runType;
  if (isProtocol) {
    key = item.protocolType;
  }

  let icon = icons.pcr;
  if (icons[key]) {
    icon = icons[key];
  }

  return icon;
};

export const getRunsWithIcons = runs => {
  const statusCheck = run => {
    let runStatus = "";
    if (run.status === "incomplete") {
      runStatus = { value: "", icon: <IncompleteIcon /> };
    }

    return runStatus;
  };

  return runs.map(run => ({
    ...run,
    displayName: {
      value: run.name,
      icon: getIcon(run),
    },
    status: statusCheck(run),
  }));
};

export const getProtocolsWithIcons = protocols => {
  return protocols.map(protocol => ({
    ...protocol,
    name: {
      value: protocol.name,
      icon: getIcon(protocol, true),
    },
  }));
};
