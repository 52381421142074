import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

const middlewares = applyMiddleware(sagaMiddleware);

export default function configureStore(initialState) {
  let devToolsExtension = f => f;
  if (
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
  }

  const str = createStore(
    rootReducer,
    initialState,
    compose(middlewares, devToolsExtension)
  );

  sagaMiddleware.run(rootSaga);

  return str;
}
