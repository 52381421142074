import * as t from "./actionTypes";

const initialState = {
  isMobile: false,
  activeAnnouncements: "",
  hasSeenAnnouncements: false,
  showAnnouncementsModal: false,
};

export default function (state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    case t.SET_APP_IS_MOBILE: {
      const { isMobile } = action;

      return {
        ...state,
        isMobile,
      };
    }

    case t.SHOW_ANNOUNCEMENTS_MODAL: {
      return {
        ...state,
        showAnnouncementsModal: true,
        hasSeenAnnouncements: true,
        activeAnnouncements: data,
      };
    }
    case t.HIDE_ANNOUNCEMENTS_MODAL: {
      return {
        ...state,
        showAnnouncementsModal: false,
        activeAnnouncements: "",
      };
    }

    default: {
      return state;
    }
  }
}
