import { Subscription } from "js-common";

import { UserDetails, BugReportFormState } from "./types";
import * as t from "./actionTypes";

export interface IUpdateUserDetails {
  type: t.T_UPDATE_USER_DETAILS_REQ;
  userDetails: UserDetails;
}

export function updateUserDetails(
  userDetails: UserDetails
): IUpdateUserDetails {
  return {
    type: t.UPDATE_USER_DETAILS_REQ,
    userDetails,
  };
}

export interface IUpdateUserDetailsSuccess {
  type: t.T_UPDATE_USER_DETAILS_SUCCESS;
  user: any;
}

export function updateUserDetailsSuccess(user: any): IUpdateUserDetailsSuccess {
  return {
    type: t.UPDATE_USER_DETAILS_SUCCESS,
    user,
  };
}

export interface IUpdateUserDetailsError {
  type: t.T_UPDATE_USER_DETAILS_ERROR;
  error: string;
}

export function updateUserDetailsError(error: string): IUpdateUserDetailsError {
  return {
    type: t.UPDATE_USER_DETAILS_ERROR,
    error,
  };
}

export interface IResetUserDetails {
  type: t.T_RESET_USER_DETAILS;
}

export function resetUserDetails(): IResetUserDetails {
  return {
    type: t.RESET_USER_DETAILS,
  };
}

export interface IUserSubscriptionDetailsReq {
  type: t.T_USER_SUBSCRIPTION_DETAILS_REQ;
}

export function userSubscriptionDetailsReq(): IUserSubscriptionDetailsReq {
  return {
    type: t.USER_SUBSCRIPTION_DETAILS_REQ,
  };
}

export interface IUserSubscriptionDetailsSuccess {
  type: t.T_USER_SUBSCRIPTION_DETAILS_SUCCESS;
  subscriptions: Subscription[];
}

export function userSubscriptionDetailsSuccess(
  subscriptions: Subscription[]
): IUserSubscriptionDetailsSuccess {
  return {
    type: t.USER_SUBSCRIPTION_DETAILS_SUCCESS,
    subscriptions,
  };
}

export interface ISubmitBugReportRequest {
  type: t.T_SUBMIT_BUG_REPORT_REQUEST;
  form: BugReportFormState;
  resolve: any;
  reject: any;
}

export function submitBugReportRequest(
  form: BugReportFormState,
  resolve: any,
  reject: any
): ISubmitBugReportRequest {
  return {
    type: t.SUBMIT_BUG_REPORT_REQUEST,
    form,
    resolve,
    reject,
  };
}

export type Action =
  | IUpdateUserDetails
  | IUpdateUserDetailsSuccess
  | IUpdateUserDetailsError
  | IResetUserDetails
  | IUserSubscriptionDetailsReq
  | IUserSubscriptionDetailsSuccess
  | ISubmitBugReportRequest;
