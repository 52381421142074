import { BulkTabularDataStyles, TabularDataOptions } from "./types";

/**
 * Used to compute the styles of the bulk tabular data table.
 *
 * @export
 * @param {number} order
 * @return {void}  {BulkTabularDataStyles}
 */
export const computeStyles = (order: number): BulkTabularDataStyles => {
  let height: number | string = "55vh";
  const pageLeft = document.getElementById("bulk_review__left");
  if (pageLeft) {
    height = pageLeft.clientHeight - 175;
  }

  let arrow: React.CSSProperties = { marginLeft: "5px" };
  if (order === 1) {
    arrow = {
      transform: "rotate(180deg) scaleX(-1)",
      marginLeft: "5px",
    };
  }

  return {
    heading: {
      fontSize: "12px",
      color: "#375464",
    },
    container: {
      maxHeight: height,
      overflowX: "auto",
      overflowY: "scroll",
      width: "100%",
    },
    data: { fontSize: "16px", color: "#012639" },
    arrow,
  };
};

/**
 * Returns the proper order to sort bulk tabular data by
 *
 * @export
 * @param {string} filterType
 * @param {string} filter
 * @param {(options: TabularDataOptions) => void} updateTabularDataOptions
 * @param {number} order
 */
export const sortTabularData = (
  filterType: string,
  filter: string,
  updateTabularDataOptions: (options: TabularDataOptions) => void,
  order: number
): void => {
  let newOrder = 0;

  if (filterType === filter && order === 1) {
    newOrder = 0;
  } else if (filterType === filter && order !== 1) {
    newOrder = 1;
  }

  updateTabularDataOptions({
    filter: filterType,
    order: newOrder,
  });
};
