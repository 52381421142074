import {
  FOLDER_SELECTED,
  RESET_SIDEBAR,
  SHOW_APP_UPDATE_MODAL,
  HIDE_APP_UPDATE_MODAL,
} from "./actionTypes";

export function selectFolder(folder) {
  return {
    type: FOLDER_SELECTED,
    selectedFolder: folder,
    depth: folder.depth + 1, // Because the sidebar
  };
}

export function resetSidebar() {
  return {
    type: RESET_SIDEBAR,
  };
}

export function showAppUpdateModal() {
  return {
    type: SHOW_APP_UPDATE_MODAL,
  };
}

export function hideAppUpdateModal() {
  return {
    type: HIDE_APP_UPDATE_MODAL,
  };
}
