import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Error, Button } from "bio-web-components";

import { getAuthError } from "../selectors";

const LoginError = props => {
  const { loginError } = props;
  const { message, contact, contactName } = loginError;

  return (
    <div className="login_error__container">
      <Error />
      <h1 className="login_error__title">Contact your Administrator</h1>
      <p className="login_error__message">{message}</p>
      <Button
        title={`Contact ${contactName}`}
        onClick={() => window.open(`mailto:${contact}`)}
        inverting
      />
      <Link className="login_error__link" to="/">
        Logout
      </Link>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loginError: getAuthError(state),
  };
};

export default connect(mapStateToProps, null)(LoginError);
