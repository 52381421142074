import React from "react";

import Checkbox from "../../../../../Layout/Checkbox";
import { CHANNELS } from "../../../../../Hub/constants";
import { ChannelFilter } from "../../../../types";

interface Props {
  colorFilter: ChannelFilter;
  setColorFilter: (channelFilter: ChannelFilter) => void;
}

const BioRadFilterPickers = (props: Props) => {
  const { colorFilter, setColorFilter } = props;
  const { values: channelValues, display: channelDisplays } = CHANNELS;
  const ALL = "all";

  const _handleSetAll = (tempFilterObject: ChannelFilter) => {
    tempFilterObject[ALL] = !tempFilterObject[ALL];
    channelValues.forEach(
      channelValue => (tempFilterObject[channelValue] = tempFilterObject[ALL])
    );

    return tempFilterObject;
  };

  const _handleSingleChannels = (
    tempFilterObject: ChannelFilter,
    channel: string
  ) => {
    tempFilterObject[channel] = !tempFilterObject[channel];
    tempFilterObject[ALL] = false;

    const everySingleChannelTrue = Object.values(tempFilterObject)
      .slice(1)
      .every(value => value);

    // check 'all' if every color channel is true
    if (everySingleChannelTrue) {
      channelValues.forEach(
        channelValue => (tempFilterObject[channelValue] = true)
      );

      tempFilterObject[ALL] = true;
    }

    return tempFilterObject;
  };

  const _handleColorFilterClick = (channel: string) => {
    const tempFilterObject = { ...colorFilter };

    let newFilterObject = _handleSingleChannels(tempFilterObject, channel);
    if (channel === ALL) {
      newFilterObject = _handleSetAll(tempFilterObject);
    }

    setColorFilter(newFilterObject);
  };
  const _renderColorCheckboxes = () =>
    channelValues.map((channel, index) => {
      return (
        <Checkbox
          key={`${channel}-Checkbox`}
          label={channelDisplays[index]}
          checked={colorFilter[channel]}
          onClick={() => _handleColorFilterClick(channel)}
        />
      );
    });

  return (
    <div className="checkbox-container">
      <Checkbox
        key={"allCheckbox"}
        label={"All"}
        checked={colorFilter.all}
        onClick={() => _handleColorFilterClick(ALL)}
      />
      {_renderColorCheckboxes()}
    </div>
  );
};
export default BioRadFilterPickers;
