import { sortByDate, sortByKey } from "../Utils/helpers";

export function createQRText(id, shortName) {
  return "Biomeme-".concat(id.toLowerCase(), "; ", shortName);
}

export function shortenText(input, maxCharacters) {
  if (input) {
    let stringifiedInput = input.toString();

    if (stringifiedInput.length > maxCharacters) {
      stringifiedInput = stringifiedInput.slice(0, maxCharacters);

      return `${stringifiedInput}...`;
    }

    return stringifiedInput;
  }

  return input;
}

export function sortSampleTabularData(inputArray, sortField, sortDirection) {
  let sortedArray;
  if (sortField === "date") {
    sortedArray = sortByDate(inputArray);
  } else {
    sortedArray = sortByKey(inputArray, sortField);
  }

  // sorts in place, need to trigger re render w/ new array
  let newArray = [...sortedArray];
  if (sortDirection === 0) {
    newArray = newArray.reverse();
  }

  return newArray;
}

export function trimLeadingWhitespace(originalString) {
  return originalString.replace(/^\s+/g, "");
}
