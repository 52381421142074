import React from "react";

import ErrorBoundary from "../ErrorBoundary";
import RootContainer from "./RootContainer";

const App = () => {
  return (
    <div className="AppLevel">
      <ErrorBoundary>
        <RootContainer />
      </ErrorBoundary>
    </div>
  );
};

export default App;
