import React from "react";
import { Divider } from "bio-web-components";

interface Props {
  isMobile: boolean;
}

const RegistrationFields: React.FunctionComponent<Props> = (props: Props) => {
  const { isMobile } = props;

  const _renderForm = () => {
    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div id="hubspotForm" className="mobile_row" />
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          id="hubspotForm"
          style={{ height: "100vh!important", maxHeight: "unset!important" }}
        />
      </div>
    );
  };

  return (
    <>
      {_renderForm()}
      <Divider classes="register_divider" />
    </>
  );
};

export default RegistrationFields;
