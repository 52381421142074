import React from "react";

import { BugReportErrorState, BugReportFormState } from "../types";
import RadioGroup from "./RadioGroup";
import {
  BROWSER_CHROME,
  BROWSER_EDGE,
  BROWSER_FIREFOX,
  BROWSER_SAFARI,
  OTHER,
} from "../constants";

interface Props {
  handleOnChange: (formBundle: BugReportFormState) => void;
  form: BugReportFormState;
  errors: BugReportErrorState;
}

const BugReportBrowserField: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { form, handleOnChange, errors } = props;
  const { browserUsed } = form;
  const { browserUsedError } = errors;

  const _renderError = () => {
    let errorElement = null;

    if (browserUsedError) {
      errorElement = (
        <div className="bug_report__input_error">{browserUsedError}</div>
      );
    }

    return errorElement;
  };

  const _renderRadios = () => {
    const radioProps = [
      {
        label: BROWSER_CHROME,
        onClick: () =>
          handleOnChange({
            ...form,
            browserUsed: BROWSER_CHROME,
          }),
        checked: browserUsed === BROWSER_CHROME,
      },
      {
        label: BROWSER_FIREFOX,
        onClick: () =>
          handleOnChange({
            ...form,
            browserUsed: BROWSER_FIREFOX,
          }),
        checked: browserUsed === BROWSER_FIREFOX,
      },
      {
        label: BROWSER_SAFARI,
        onClick: () =>
          handleOnChange({
            ...form,
            browserUsed: BROWSER_SAFARI,
          }),
        checked: browserUsed === BROWSER_SAFARI,
      },
      {
        label: BROWSER_EDGE,
        onClick: () =>
          handleOnChange({
            ...form,
            browserUsed: BROWSER_EDGE,
          }),
        checked: browserUsed === BROWSER_EDGE,
      },
      {
        label: OTHER,
        onClick: () =>
          handleOnChange({
            ...form,
            browserUsed: OTHER,
          }),
        checked: browserUsed === OTHER,
      },
    ];

    return <RadioGroup radioButtonProps={radioProps} />;
  };

  return (
    <div className="bug_report__input_container">
      <h4>Browser Used*</h4>
      {_renderRadios()}
      {_renderError()}
    </div>
  );
};

export default BugReportBrowserField;
