import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { isEqual, debounce } from "lodash";

import { Button, Divider } from "bio-web-components";

import { BugReportFormState, BugReportErrorState } from "../types";
import { validateBugReportForm } from "../helpers";
import BugReportBlockerField from "./BugReportBlockerField";
import BugReportInputFields from "./BugReportInputFields";
import BugReportHeader from "./BugReportHeader";
import { submitBugReportRequest as actionSubmitBugReport } from "../actions";
import BugReportFormStatusMessages from "./BugReportFormStatusMessages";
import BugReportOperatingSystemField from "./BugReportOperatingSystemField";
import BugReportBrowserField from "./BugReportBrowserField";

interface DispatchProps {
  submitBugReport: (
    bugReport: BugReportFormState,
    resolve: any,
    reject: any
  ) => void;
}

interface Props extends DispatchProps {}

const BugReportForm: React.FunctionComponent<Props> = (props: Props) => {
  const initialFormState: BugReportFormState = {
    problemDescription: "",
    operatingSystem: "",
    browserUsed: "",
    expectedResult: "",
    stepsToReproduce: "",
    blocking: "",
  };

  const initialErrorState: BugReportErrorState = {
    problemDescriptionError: "",
    operatingSystemError: "",
    browserUsedError: "",
  };

  const [form, setForm] = useState<BugReportFormState>(initialFormState);
  const [errors, setErrors] = useState<BugReportErrorState>(initialErrorState);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = debounce(async (): Promise<void> => {
    const { submitBugReport } = props;

    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    const validationBundle = validateBugReportForm(form);

    setErrors(validationBundle);

    if (isEqual(validationBundle, initialErrorState)) {
      await new Promise<string>((resolve, reject) => {
        submitBugReport(form, resolve, reject);
      })
        .then(message => {
          setSuccessMessage(message);
          setForm(initialFormState);
        })
        .catch(error => setErrorMessage(error));
    }

    setIsSubmitting(false);
  }, 500);

  const _renderRadioFields = () => {
    return (
      <>
        <BugReportOperatingSystemField
          handleOnChange={(formBundle: BugReportFormState) =>
            setForm(formBundle)
          }
          form={form}
          errors={errors}
        />
        <BugReportBrowserField
          handleOnChange={(formBundle: BugReportFormState) =>
            setForm(formBundle)
          }
          form={form}
          errors={errors}
        />
        <Divider classes="bug_report__horizontal-rule" />
        <BugReportBlockerField
          handleOnChange={(formBundle: BugReportFormState) =>
            setForm(formBundle)
          }
          form={form}
        />
      </>
    );
  };

  const _renderFields = () => {
    return (
      <>
        <Divider classes="bug_report__horizontal-rule" />
        <BugReportInputFields
          handleOnChange={(formBundle: BugReportFormState) =>
            setForm(formBundle)
          }
          form={form}
          errors={errors}
        />
        <Divider classes="bug_report__horizontal-rule" />
        {_renderRadioFields()}
        <Divider classes="bug_report__horizontal-rule" />
      </>
    );
  };

  return (
    <>
      <BugReportHeader />
      {_renderFields()}
      <BugReportFormStatusMessages
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <Button
        onClick={handleSubmit}
        title="Submit"
        inverting
        disabled={isSubmitting}
      />
    </>
  );
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    submitBugReport: (
      bugReport: BugReportFormState,
      resolve: any,
      reject: any
    ) => dispatch(actionSubmitBugReport(bugReport, resolve, reject)),
  };
}

export default connect(null, mapDispatchToProps)(BugReportForm);
