import * as v1 from "./chartGeneratorVersions/chartGeneratorsV1";
import * as v2 from "./chartGeneratorVersions/chartGeneratorsV2";
import {
  desiredChannelOrder,
  initialChartTypeSettings,
  chartTypes,
} from "./constants";
import { calculateMetrics, calculateEndRfuMetrics } from "./helpers";
import { isDetailsVersionTwoOrGreater } from "../FullRunData/helpers";

const versionTwoInitialStateGenerator = (data, reprocessing) => {
  const { createChartData, createFilterObjects, createRunBlocks } = v2;

  const { data: chartData, xAxis, yAxis } = createChartData(data);
  const { channels, wells } = createFilterObjects(data);

  channels.sort(
    (x, y) =>
      desiredChannelOrder.indexOf(x.label.toLowerCase()) -
      desiredChannelOrder.indexOf(y.label.toLowerCase())
  );

  const chartTypeSettings = {
    ...initialChartTypeSettings,
  };

  if (reprocessing) {
    chartTypeSettings[chartTypes.singleThreshold] = true;
  } else {
    chartTypeSettings[chartTypes.baseline] = true;
  }

  return {
    data: chartData,
    xAxis,
    yAxis,
    chartTypeSettings,
    runBlocks: createRunBlocks(data),
    metrics: calculateMetrics(chartData),
    endRfuMetrics: calculateEndRfuMetrics(chartData),
    channelFilters: channels,
    wellFilters: wells,
  };
};

const versionOneInitialStateGenerator = data => {
  const { createChartData, createFilterObjects, createRunBlocks } = v1;

  const { data: chartData, xAxis, yAxis } = createChartData(data);
  const { channels, wells } = createFilterObjects(data);

  channels.sort(
    (x, y) =>
      desiredChannelOrder.indexOf(x.label.toLowerCase()) -
      desiredChannelOrder.indexOf(y.label.toLowerCase())
  );

  return {
    data: chartData,
    xAxis,
    yAxis,
    chartTypeSettings: {
      ...initialChartTypeSettings,
      [chartTypes.baseline]: true,
    },
    runBlocks: createRunBlocks(data),
    metrics: calculateMetrics(chartData),
    channelFilters: channels,
    wellFilters: wells,
  };
};

// eslint-disable-next-line
export function generateInitialState(
  data,
  detailsVersion,
  reprocessing = false
) {
  if (isDetailsVersionTwoOrGreater(detailsVersion)) {
    return versionTwoInitialStateGenerator(data, reprocessing);
  }

  return versionOneInitialStateGenerator(data);
}
