import Auth from "@aws-amplify/auth";
import { create } from "apisauce";

import config from "./aws-config";
import * as helpers from "./helpers";

const corsAnywhere = "";
const awsBaseURL = process.env.BIO_BASE_URL;
const backendApi = create({
  baseURL: corsAnywhere + awsBaseURL,
});

const recaptchaKey = helpers.getRecaptchaSiteKey();

const API_NAME = process.env.BIO_WEB_API_NAME;

Auth.configure(config);

const announcementsClient = create({
  baseUrl: window.location.origin,
});

export { helpers, backendApi, API_NAME, announcementsClient, recaptchaKey };
