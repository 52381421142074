import React from "react";
import { connect } from "react-redux";
import { getSidebarDepth } from "../../../../Sidebar/selectors";
import SummaryButton from "../BulkReviewSummary/Subcomponents/SummaryButton";
import BulkReviewTitle from "../BulkReviewTitle";
import BulkReviewBreadCrumbs from "./BreadCrumbs";

interface StateProps {
  depth: number;
}

type Props = StateProps;

export const BulkContentHeader: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { depth } = props;

  let contentHeaderStyleValue = {};
  let breadCrumbsClass = "inner";

  if (depth > 0) {
    contentHeaderStyleValue = {
      width: "100vw",
      background: "linear-gradient(180deg, #FEFEFE 0%, #FBFBFB 100%)",
    };
    breadCrumbsClass = "inner centered";
  }

  return (
    <div className="content-header" style={contentHeaderStyleValue}>
      <div className={breadCrumbsClass}>
        <BulkReviewBreadCrumbs />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "-26px",
          }}
        >
          <BulkReviewTitle />
          <SummaryButton />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    depth: getSidebarDepth(state),
  };
}

export default connect(mapStateToProps)(BulkContentHeader);
