import React from "react";
import { connect } from "react-redux";

import { showTabularData } from "../../../actions";
import { isTabularDataActive, isReviewModeActive } from "../../../selectors";
import Icon from "../../../../Utils/Layout/Icon";
import { getMetricsLabel } from "../../../../Hub/selectors";

const TabularDataButton = props => {
  const {
    reviewAnalysisModeIsActive,
    metricsLabel,
    tabularDataIsActive,
    displayTabularData,
  } = props;

  if (reviewAnalysisModeIsActive) {
    return null;
  }

  let buttonClass = "btn-inline tabular_mode";
  if (tabularDataIsActive) {
    buttonClass = "btn-inline active tabular_mode";
  }

  return (
    <button
      className={buttonClass}
      data-tip={`View essential run data, including ${metricsLabel}s and thresholds, in a tabular format.`}
      onClick={() => {
        displayTabularData();
      }}
    >
      <Icon name="tabular" />
      Tabular Data
    </button>
  );
};

const mapStateToProps = state => ({
  reviewAnalysisModeIsActive: isReviewModeActive(state),
  tabularDataIsActive: isTabularDataActive(state),
  metricsLabel: getMetricsLabel(state),
});

const mapDispatchToProps = dispatch => ({
  displayTabularData: () => {
    dispatch(showTabularData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabularDataButton);
