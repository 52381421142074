import { createSelector } from "reselect";

import { hasPermission } from "../Utils/helpers";

export const getAuthError = state => {
  return state.auth.error;
};
export const getLoggedIn = state => {
  return state.auth.loggedIn;
};
export const getTeams = state => {
  return state.auth.teams;
};

export const getActiveTeamId = state => {
  return state.auth.activeTeamId;
};
export const getActiveTeamName = state => {
  return state.auth.activeTeamName;
};
export const getActiveTeamFlags = state => {
  return state.auth.activeTeamFlags;
};

export const getAwsUser = state => {
  return state.auth.user;
};
export const getCognitoToken = state => {
  return state.auth.token;
};

export const getUserId = state => {
  if (
    state.auth.user &&
    state.auth.user.attributes &&
    state.auth.user.attributes.sub
  ) {
    return state.auth.user.attributes.sub;
  }

  return "";
};

export const getVerificationCode = state => {
  return state.auth.requestVerificationCode;
};

export const getProvider = state => {
  return state.auth.provider;
};

export const getJWTToken = state => {
  if (
    state.auth.user &&
    state.auth.user.signInUserSession &&
    state.auth.user.signInUserSession.accessToken &&
    state.auth.user.signInUserSession.accessToken.jwtToken
  ) {
    return state.auth.user.signInUserSession.accessToken.jwtToken;
  }

  return "";
};

export const getIDToken = state => {
  if (
    state.auth.user &&
    state.auth.user.signInUserSession &&
    state.auth.user.signInUserSession.idToken &&
    state.auth.user.signInUserSession.idToken.payload
  ) {
    return state.auth.user.signInUserSession.idToken.payload;
  }

  return {};
};

export const getCurrentUserEmail = state => {
  if (state.auth.user) {
    return state.auth.user.attributes.email;
  }

  return "N/A";
};

export const getActiveTeamVersion = state => {
  if (state.auth.activeTeamVersion === "2") {
    return "biomeme-2";
  }

  return "biomeme-1";
};

export const getTokenTime = state => {
  return state.auth.tokenTime;
};
export const getResetSuccess = state => {
  return state.auth.resetPasswordSuccess;
};

export const getSamlProcessing = state => {
  return state.auth.samlProcessing;
};

export const canUserAccessReview = createSelector(
  [getActiveTeamFlags],
  activeTeamFlags => {
    return hasPermission(activeTeamFlags, "accessReview");
  }
);

export const isUserReviewAdmin = createSelector(
  [getActiveTeamFlags],
  activeTeamFlags => {
    return hasPermission(activeTeamFlags, "accessReviewAdmin");
  }
);

export const canUserAccessRunUploading = createSelector(
  [getActiveTeamFlags],
  activeTeamFlags => {
    return hasPermission(activeTeamFlags, "accessExternalUpload");
  }
);

export const canUserAccessExternalProtocolCreation = createSelector(
  [getActiveTeamFlags],
  activeTeamFlags => {
    return hasPermission(activeTeamFlags, "accessExternalProtocolCreation");
  }
);
