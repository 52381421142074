import React, { useState } from "react";
import { connect } from "react-redux";

import { Modal } from "../../../../../Layout/Modal";
import BioRadTable from "./BioRadTable";
import BioRadMasterTable from "./BioRadMasterTable";
import { capitalizeFirstLetter } from "../../../../../Utils/helpers";
import { getBioRadTargetMatrix, getWellFilters } from "../../../../selectors";
import { applyMasterFilters } from "../../../../actions";
import styles from "../styles";
import BioRadFilterPickers from "./BioRadFilterPickers";
import { ChannelFilter } from "../../../../types";

interface Props {
  runBlock: any;
  index: number;
  isSingleRun: boolean;
  runId: string;
  wellFilters: any;
  bioRadTargetMatrix: any;
  masterFiltersApply: (targets: any, runId: string) => void;
  setShowDownselectModal: (runId: string | null) => void;
}

const BioRadFilterModal = (props: Props) => {
  const {
    runBlock,
    isSingleRun,
    runId,
    index,
    wellFilters,
    bioRadTargetMatrix,
    masterFiltersApply,
    setShowDownselectModal,
  } = props;
  const [targetMatrix, setTargetMatrix] = useState(bioRadTargetMatrix);
  const initialChannelFilterState: ChannelFilter = {
    all: true,
    green: true,
    amber: true,
    red: true,
  };
  const [colorFilter, setColorFilter] = useState<ChannelFilter>(
    initialChannelFilterState
  );
  const [activeTable, setActiveTable] = useState("all");

  const renderColoredCircles = (channel: string) => {
    let fill = channel;
    if (channel === "amber") {
      fill = "#f5a623";
    }

    return (
      <svg height="20" width="20">
        <circle cx="6" cy="10" r="4" fill={fill} />
      </svg>
    );
  };

  const renderActiveTable = () => {
    if (activeTable === "all") {
      return (
        <div>
          <BioRadMasterTable
            colorFilter={colorFilter}
            wellFilters={wellFilters}
            targetMatrix={targetMatrix}
            setTargetMatrix={setTargetMatrix}
            applyMasterFilters={masterFiltersApply}
            runId={runId}
            tableKey={`masterRunTable-${runId}`}
          />
        </div>
      );
    }

    return (
      <div>
        {/* @ts-ignore */}
        <div style={styles.activeTable}>
          {renderColoredCircles(activeTable)}
          {capitalizeFirstLetter(activeTable)}
        </div>
        <BioRadTable
          channel={activeTable}
          runBlock={runBlock}
          runId={runId}
          isSingleRun={isSingleRun}
          runTableIndex={index}
          key={`runTable-${activeTable}-${runId}`}
        />
      </div>
    );
  };

  const renderChannelFilterButtons = () => {
    return (
      <div className="channel-filter-container">
        <h2>Channels</h2>
        <BioRadFilterPickers
          colorFilter={colorFilter}
          setColorFilter={setColorFilter}
        />
      </div>
    );
  };

  return (
    <Modal
      open
      heading="Filter Data"
      onClose={() => setShowDownselectModal(null)}
      style={styles.modalStyle}
      bodyClass="modal__analysis"
    >
      <p>
        Use the grid below to toggle your wells and to filter your data by
        channel.
      </p>
      <div className="biorad_filter_modal-container">
        {renderChannelFilterButtons()}
        {renderActiveTable()}
      </div>
      <div
        className="biorad-filter-cancel"
        onClick={() => setShowDownselectModal(null)}
      >
        Cancel
      </div>
    </Modal>
  );
};

function mapStateToProps(state: any) {
  return {
    wellFilters: getWellFilters(state),
    bioRadTargetMatrix: getBioRadTargetMatrix(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    masterFiltersApply: (targets: any, runId: any) => {
      dispatch(applyMasterFilters(targets, runId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BioRadFilterModal);
