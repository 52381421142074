import { analysisModes } from "../RunsPage/constants";
import {
  runTypes,
  EXTERNAL_DETAILS_VERSIONS,
  MAX_EXTERNAL_RUNS,
  MAX_RUNS,
  MAX_ISOTHERMAL_RUNS,
} from "./constants";

export const determineSingleRunDefaultAnalysisMode = (
  runType,
  runIsExternalUpload
) => {
  if (runIsExternalUpload) {
    return analysisModes.singleThreshold;
  }

  if (runType === runTypes.melt) {
    return analysisModes.singleThreshold;
  }

  return analysisModes.baseline;
};

export const checkIfDetailsVersionIsExternalRun =
  runSelectionDetailsVersion => {
    return EXTERNAL_DETAILS_VERSIONS.values.includes(
      runSelectionDetailsVersion
    );
  };

export const determineMaxNumberOfSelectedRuns = (detailsVersion, runType) => {
  const isExternalRun = checkIfDetailsVersionIsExternalRun(detailsVersion);

  if (isExternalRun) {
    return MAX_EXTERNAL_RUNS;
  }

  if (runType === runTypes.isothermal) {
    return MAX_ISOTHERMAL_RUNS;
  }

  return MAX_RUNS;
};

export const canRunTypesBeCombined = (
  existingRunType,
  incomingRunType,
  protocolId,
  firstSelectedRun
) => {
  const { isothermal: isothermalType, melt: meltType } = runTypes;

  if (existingRunType === isothermalType) {
    if (incomingRunType !== isothermalType) {
      return false;
    }
  } else if (existingRunType === meltType) {
    if (
      firstSelectedRun &&
      firstSelectedRun.protocolId.toLowerCase() !== protocolId.toLowerCase()
    ) {
      return false;
    }
  } else {
    if (incomingRunType === isothermalType) {
      return false;
    }
    if (incomingRunType === meltType) {
      return false;
    }
  }

  return true;
};
