import React from "react";
import { classNamesParser } from "../Utils/layoutUtils";

interface Props {
  checked: boolean;
  onClick: () => void;
  label: string | number;
  preLabel?: string;
  bold?: boolean;
  large?: boolean;
  border?: boolean;
  disabled?: boolean;
}

class Checkbox extends React.Component<Props> {
  getClassName() {
    const { bold, large, border, disabled } = this.props;

    return classNamesParser({
      "checkbox-label": true,
      "checkbox-label--bold": bold,
      "checkbox-label--large": large,
      "checkbox-label--border": border,
      "checkbox-label--disabled": disabled,
    });
  }

  renderPreLabel() {
    const { preLabel } = this.props;
    if (!preLabel) {
      return null;
    }

    return (
      <h4 key={preLabel} className="checkbox-label__prelabel">
        {preLabel}
      </h4>
    );
  }

  render() {
    const { label, checked, onClick } = this.props;

    let labelThresholdWeight = 400;
    if (label === "Threshold") {
      labelThresholdWeight = 600;
    }

    return (
      <label
        className={this.getClassName()}
        style={{ fontWeight: labelThresholdWeight }}
      >
        {this.renderPreLabel()}
        <input
          type="checkbox"
          checked={checked}
          onChange={() => {
            return onClick();
          }}
        />
        <span />
        {label}
      </label>
    );
  }
}

export default Checkbox;
