import React from "react";
import BulkFilterSection from "../BulkFilterSection";
import BulkSidebar from "../BulkSidebar";
import BulkTabularData from "../BulkTabularData";
import { bulkReviewContainer, bulkReviewLeft, bulkReviewRight } from "./styles";
import BulkReviewSummaryModal from "../BulkReviewSummary/BulkReviewSummaryModal";

const BulkReviewContainer: React.FunctionComponent = () => {
  return (
    <div className="bulk_review_page">
      <div className="bulk_review__container" style={bulkReviewContainer}>
        <BulkReviewSummaryModal />
        <div className="bulk_review__left" style={bulkReviewLeft}>
          <BulkSidebar />
        </div>

        <div className="bulk_review__right" style={bulkReviewRight}>
          <BulkFilterSection />
          <div className="bulk_review__tabular_data_overlay">
            <BulkTabularData />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkReviewContainer;
