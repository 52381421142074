export const GET_FOLDERS_REQ = "folders/GET_ALL";
export const GET_FOLDERS_SUCCESS = "folders/GET_SUCCESS";
export const GET_FOLDERS_ERR = "folders/GET_ERR";

export const RESET_FOLDERS = "folders/reset";

export const GET_ROOT_FOLDERS = "folders/GET_ROOT_REQ";
export const GET_ROOT_SUCCESS = "folders/GET_ROOT_SUCCESS";

export const GET_SELECTED_REQ = "folder/GET_SELECTED_REQ";
export const GET_SELECTED_SUCCESS = "folders/GET_SELECTED_SUCCESS";

export const GET_PAGINATED_CHILDREN_REQ = "folders/GET_PAGINATED_CHILDREN_REQ";
export const GET_PAGINATED_CHILDREN_SUCCESS =
  "folders/GET_PAGINATED_CHILDREN_SUCCESS";

export const SHOW_ADD_FOLDER_MODAL = "folders/SHOW_ADD_FOLDER_MODAL";
export const HIDE_ADD_FOLDER_MODAL = "folders/HIDE_ADD_FOLDER_MODAL";

export const POST_FOLDER = "folders/POST_FOLDER";
