import React, { useState } from "react";
import { connect } from "react-redux";
import { Steps } from "intro.js-react";

import { toggleChartTutorial } from "../../actions";
import {
  getTutorialState,
  getChartType,
  getRunBlocks,
  getAnalysisMode,
} from "../../selectors";
import { isRunTypeMelt } from "../../../Hub/selectors";
import { organizeTutorialSteps } from "../../helpers";
import { tutorialOptions } from "../../constants";

const Tutorial = props => {
  const {
    isTutorialEnabled,
    chartType,
    runBlocks,
    runTypeIsMelt,
    analysisMode,
    chartTutorialToggle,
  } = props;
  const [initialStep] = useState(0);

  const disableTutorial = () => {
    chartTutorialToggle(false);
  };

  const steps = organizeTutorialSteps(
    runTypeIsMelt,
    chartType,
    runBlocks,
    analysisMode
  );

  return (
    <Steps
      enabled={isTutorialEnabled}
      steps={steps}
      initialStep={initialStep}
      onExit={disableTutorial}
      options={tutorialOptions}
    />
  );
};

const mapStateToProps = state => ({
  isTutorialEnabled: getTutorialState(state),
  chartType: getChartType(state),
  runBlocks: getRunBlocks(state),
  runTypeIsMelt: isRunTypeMelt(state),
  analysisMode: getAnalysisMode(state),
});

const mapDispatchToProps = dispatch => ({
  chartTutorialToggle: status => {
    dispatch(toggleChartTutorial(status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
