import { CSSProperties } from "react";
import {
  bulkReviewRunBlocksContainer,
  bulkReviewSidebarApproveContainer,
} from "../../styles";
import { bulkRunBlocksBlock } from "../BulkRunBlocks/styles";

export const submissionStatuses: CSSProperties = {
  ...bulkReviewRunBlocksContainer,
};

export const submissionOverallContainer: CSSProperties = {
  ...bulkReviewSidebarApproveContainer,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const failureStatus: CSSProperties = {
  color: "red",
};

export const successStatus: CSSProperties = {
  color: "green",
};

export const overallResult: CSSProperties = {
  fontWeight: 600,
  marginBottom: "10px",
};

export const overallFailure: CSSProperties = {
  ...overallResult,
  color: "red",
};

export const overallSuccess: CSSProperties = {
  ...overallResult,
  color: "green",
};

export const bulkSubmissionBlock: CSSProperties = {
  ...bulkRunBlocksBlock,
};

export const submissionHeader: CSSProperties = {
  fontFamily: "'Roboto'",
  fontWeight: 600,
  fontSize: "21px",
  color: "#012639",
  lineHeight: "28px",
  letterSpacing: 0,
};
