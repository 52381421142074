import { combineReducers } from "redux";
import appReducer from "../App/reducer";
import auth from "../Auth";
import runsPageReducer from "../RunsPage/reducer";
import sidebarReducer from "../Sidebar/reducer";
import folderReducer from "../Folders/reducer";
import hubReducer from "../Hub/reducer";
import runDataReducer from "../RunData/reducer";
import protocolDataReducer from "../ProtocolData/reducers";
import fullRunDataReducer from "../FullRunData/reducer";
import sampleHubReducer from "../SampleHub/reducer";
import settingsReducer from "../Settings/reducer";
import runReviewReducer from "../RunReview/reducer";

export default combineReducers({
  app: appReducer,
  auth: auth.reducer,
  runsPage: runsPageReducer,
  sidebar: sidebarReducer,
  folders: folderReducer,
  hub: hubReducer,
  runData: runDataReducer,
  fullRunData: fullRunDataReducer,
  protocolData: protocolDataReducer,
  sampleHub: sampleHubReducer,
  settings: settingsReducer,
  runReview: runReviewReducer,
});
