import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  Input,
  Select,
  Row,
  Divider,
  Container,
  Table,
  PositiveResult,
  NegativeResult,
  ToggleSwitch,
} from "bio-web-components";

import { setSampleType, setSampleComments, setSampleBlank } from "../actions";
import { getControlNames, getSampleResultIcons } from "../selectors";
import ReviewTarget from "./ReviewTarget";
import { SampleReview, ResultIcon } from "../types";
import { UNSPECIFIED_SAMPLE_TEXT } from "../../RunsPage/constants";

interface StateProps {
  controls: Array<string>;
  resultIcons: Array<ResultIcon>;
}

interface DispatchProps {
  setType: (sampleId: string, sampleType: string) => void;
  setBlank: (sampleId: string, blank: boolean) => void;
  setComments: (sampleId: string, comments: string) => void;
}

interface Props {
  sample: SampleReview;
}

const ReviewSample = (props: Props & StateProps & DispatchProps) => {
  const _getParsedWells = (wells: Array<string | number>): string => {
    return wells
      .map(well => {
        let parsedWell = well;
        if (typeof well === "number") {
          parsedWell = well + 1;
        }

        return parsedWell;
      })
      .join(", ");
  };

  const _renderHeader = (): React.ReactNode => {
    const { sample, setBlank } = props;
    const { sampleName, blank, wellNumber } = sample;

    return (
      <React.Fragment>
        <Row version="space-between">
          <div className="review_sample_header">
            <h3>WELL {_getParsedWells(wellNumber)} SAMPLE</h3>
            <p>{sampleName || UNSPECIFIED_SAMPLE_TEXT}</p>
          </div>
          <div className="review_sample_header">
            <ToggleSwitch
              label="Blank Sample"
              onClick={() => setBlank(sampleName, !blank)}
              checked={blank}
              className="toggle_slider_position"
            />
          </div>
        </Row>
        <Divider />
      </React.Fragment>
    );
  };

  const _renderResultHeader = (): React.ReactNode => {
    const { sample, controls, setType, setComments, resultIcons } = props;
    const { sampleName, blank, sampleType, comments, overallResult } = sample;

    let icon: React.ReactNode | undefined;
    if (!sampleType) {
      const resultIcon = resultIcons.find(i => i.result === overallResult);

      if (resultIcon) {
        if (resultIcon.icon === "positive") {
          icon = <PositiveResult width={18} height={18} />;
        }
        if (resultIcon.icon === "negative") {
          icon = <NegativeResult width={18} height={18} />;
        }
      }
    }

    let sampleResultLabel = "RESULT";
    let controlType = "-";
    if (sampleType) {
      sampleResultLabel = "CONTROL RESULT";
      controlType = sampleType;
    }

    return (
      <Row>
        <Select
          containerClasses="review_small_input"
          selectClasses="review_control_select"
          label="CONTROL TYPE"
          options={controls}
          value={controlType}
          onChange={(value: string) => setType(sampleName, value)}
          disabled={blank}
        />
        <Input
          containerClasses="review_small_input"
          inputClasses="review_list_item"
          label={sampleResultLabel}
          value={overallResult}
          iconLeft={icon}
          disabled
        />
        <Input
          label="COMMENTS"
          defaultValue={comments}
          onBlur={(value: string) => setComments(sampleName, value)}
        />
      </Row>
    );
  };

  const _renderTargetsHeader = (): Array<React.ReactNode> => {
    const headers = [
      "TARGET",
      "CQ (APP)",
      "APPROVE*",
      "OVERRIDDEN CQ",
      "COMMENTS",
    ];

    return headers.map(header => (
      <div key={header} className="target_header">
        {header}
      </div>
    ));
  };

  const _renderTargets = (): React.ReactNode => {
    const { sample } = props;
    const { targets, blank, sampleName } = sample;

    return (
      <Container>
        <Table
          headers={_renderTargetsHeader()}
          className="review_targets_table"
        >
          {targets.map((target, i) => (
            <ReviewTarget
              key={`${sampleName}_${target.targetId}`}
              sampleId={sampleName}
              sampleBlank={blank}
              target={target}
              darkened={i % 2 === 0}
            />
          ))}
        </Table>
      </Container>
    );
  };

  return (
    <React.Fragment>
      <div className="review_sample_container">
        {_renderHeader()}
        {_renderResultHeader()}
        {_renderTargets()}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    controls: getControlNames(state),
    resultIcons: getSampleResultIcons(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setType: (sampleId: string, sampleType: string) =>
      dispatch(setSampleType(sampleId, sampleType)),
    setBlank: (sampleId: string, blank: boolean) =>
      dispatch(setSampleBlank(sampleId, blank)),
    setComments: (sampleId: string, comments: string) =>
      dispatch(setSampleComments(sampleId, comments)),
  };
};

export default connect<StateProps, DispatchProps, Props, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewSample);
