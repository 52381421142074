import React from "react";
import { connect } from "react-redux";
import { Steps } from "intro.js-react";

import { getShowTutorial } from "../selectors";

const steps = [
  {
    element: ".page__container",
    intro: "Welcome to Sample Management!",
  },
  {
    element: "#sample-hub__sample-templates-button",
    intro:
      "Clicking here will update the table to display all of your sample layouts.",
  },
  {
    element: "#sample-hub__sample-records-button",
    intro:
      "Clicking here will update the table to display all of your sample QR codes.",
  },
  {
    element: "#sample-hub__call-to-action",
    intro:
      "This button will take you to the creation screen for a new layout or QR code, depending on which data type you're viewing.",
  },
  {
    element: ".tabular_data",
    intro:
      "Once you have data, it will be displayed here. Clicking on a row will expand it, making more details and options, including creation, deletion, and editing, available.",
  },
];

const SampleHubTutorial = props => {
  const { showSampleHubTutorial } = props;

  return (
    <Steps
      enabled={showSampleHubTutorial}
      steps={steps}
      initialStep={0}
      onExit={() => null}
      options={{
        showProgress: true,
        showStepNumbers: false,
        skipLabel: "Exit",
        showBullets: false,
        disableInteraction: true,
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    showSampleHubTutorial: getShowTutorial(state),
  };
}

export default connect(mapStateToProps, null)(SampleHubTutorial);
