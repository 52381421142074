import * as t from "./actionTypes";

const initialState = {
  tableDisplay: "template",
  sortBy: {
    template: { date: 1 },
    record: { date: 1 },
  },
  templates: [],
  activeTemplate: {},
  activeRecord: {},
  toPrint: {},
  toDelete: {},
  templateToEdit: "",
  showSampleHubTutorial: false,
  // ERROR HANDLING:
  templateDeleteErrors: [],
  templatePostErrors: [],
  recordDeleteErrors: [],
  recordPostErrors: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case t.SET_TABLE_DISPLAY: {
      const { display } = action;

      return {
        ...state,
        tableDisplay: display,
      };
    }

    case t.SET_SORT_BY: {
      const { field } = action;
      const { tableDisplay, sortBy } = state;
      const {
        [tableDisplay]: { [field]: sortOrder = 0 },
      } = sortBy;

      let newFieldValue = 0;
      if (sortOrder === 0) {
        newFieldValue = 1;
      }

      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          [tableDisplay]: {
            [field]: newFieldValue,
          },
        },
      };
    }

    case t.RESET_TABULAR_DATA: {
      return {
        ...state,
        tableDisplay: initialState.tableDisplay,
        sortBy: initialState.sortBy,
      };
    }

    case t.SET_ACTIVE_SAMPLE_TEMPLATE: {
      const { templates } = state;
      const { id } = action;

      const selectedTemplate = templates.find(
        template => template.id.toUpperCase() === id.toUpperCase()
      );

      return {
        ...state,
        activeTemplate: selectedTemplate,
        templateDeleteErrors: [],
        recordDeleteErrors: [],
        templatePostErrors: [],
        recordPostErrors: [],
      };
    }

    case t.SET_ACTIVE_SAMPLE_RECORD: {
      const { record } = action;

      return {
        ...state,
        activeRecord: record,
      };
    }

    case t.SET_TO_PRINT: {
      const { id } = action;
      const { records } = state;

      const selectedRecord = records.find(
        record => record.id.toUpperCase() === id.toUpperCase()
      );

      return {
        ...state,
        toPrint: selectedRecord,
      };
    }

    case t.SET_TO_DELETE: {
      const { id, itemType } = action;
      let selectedItem;
      if (itemType === "record") {
        const { records } = state;
        selectedItem = records.find(
          record => record.id.toUpperCase() === id.toUpperCase()
        );
      } else {
        const { templates } = state;
        selectedItem = templates.find(
          template => template.id.toUpperCase() === id.toUpperCase()
        );
      }

      return {
        ...state,
        toDelete: { ...selectedItem, itemType },
      };
    }

    case t.UNSET_ACTIVE_SAMPLE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: {},
        templateDeleteErrors: [],
        recordDeleteErrors: [],
        templatePostErrors: [],
        recordPostErrors: [],
      };
    }

    case t.SET_TEMPLATE_TO_EDIT: {
      const { id } = action;

      return {
        ...state,
        templateToEdit: id,
        templateDeleteErrors: [],
        recordDeleteErrors: [],
        templatePostErrors: [],
        recordPostErrors: [],
      };
    }

    case t.INITIALIZE_SAMPLE_TEMPLATES_SUCCESS: {
      const { templates = [] } = action;
      let showSampleHubTutorial = false;

      if (!templates || templates.length < 1) {
        showSampleHubTutorial = true;
      }

      return {
        ...state,
        templates: templates || [],
        showSampleHubTutorial,
      };
    }

    case t.UPLOAD_TEMPLATE_SUCCESS: {
      const { template } = action;

      return {
        ...state,
        templates: [...state.templates, template],
        showSampleHubTutorial: false,
      };
    }

    case t.RETRIEVE_SAMPLE_TEMPLATES_SUCCESS: {
      const { templates = [] } = action;

      return {
        ...state,
        templates: templates || [],
      };
    }

    case t.RETRIEVE_SAMPLE_RECORDS_SUCCESS: {
      const { records } = action;

      return {
        ...state,
        records: records || [],
      };
    }

    case t.TEMPLATE_DELETE_UNSUCCESSFUL: {
      const { name } = action;

      const templateDeleteErrors = [...state.templateDeleteErrors];
      const errorMessage = `Unable to delete record ${name}`;
      templateDeleteErrors.push(errorMessage);

      return {
        ...state,
        templateDeleteErrors,
      };
    }

    case t.CLEAR_TEMPLATE_DELETE_ERRORS: {
      return {
        ...state,
        templateDeleteErrors: [],
      };
    }

    case t.RECORD_DELETE_UNSUCCESSFUL: {
      const { name } = action;

      const recordDeleteErrors = [...state.recordDeleteErrors];
      const errorMessage = `Unable to delete template ${name}`;
      recordDeleteErrors.push(errorMessage);

      return {
        ...state,
        recordDeleteErrors,
      };
    }

    case t.CLEAR_RECORD_DELETE_ERRORS: {
      return {
        ...state,
        recordDeleteErrors: [],
      };
    }

    case t.TEMPLATE_POST_UNSUCCESSFUL: {
      const { name } = action;

      const errorMessage = `Unable to save template ${name}`;

      return {
        ...state,
        templatePostErrors: errorMessage,
      };
    }

    case t.CLEAR_TEMPLATE_POST_ERRORS: {
      return {
        ...state,
        templatePostErrors: [],
      };
    }

    case t.RECORD_POST_UNSUCCESSFUL: {
      const { name } = action;

      const errorMessage = `Unable to save record ${name}`;

      return {
        ...state,
        recordPostErrors: errorMessage,
      };
    }

    case t.CLEAR_RECORD_POST_ERRORS: {
      return {
        ...state,
        recordPostErrors: [],
      };
    }

    case t.CLEAR_ERRORS: {
      return {
        ...state,
        templateDeleteErrors: [],
        recordDeleteErrors: [],
        templatePostErrors: [],
        recordPostErrors: [],
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
