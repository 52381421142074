import React from "react";
import { connect } from "react-redux";

import { getShowedFilterBar, getSelectedRuns } from "../../selectors";
import FilterBarHeader from "./filterBarHeader";
import FilterDropDown from "./filterDropDown";
import AnalysisModeSelector from "../../../RunsPage/components/ChartControls/AnalysisModeSelector";

export const FilterBar = ({ showDropDown, selectedRuns }) => {
  let dropDown = null;
  let analysisModeModal = null;

  if (showDropDown) {
    dropDown = <FilterDropDown />;
  }

  if (selectedRuns && selectedRuns.length > 0) {
    analysisModeModal = <AnalysisModeSelector />;
  }

  return (
    <div className="filterBar">
      <FilterBarHeader />
      {dropDown}
      {analysisModeModal}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    showDropDown: getShowedFilterBar(state),
    selectedRuns: getSelectedRuns(state),
  };
}

export default connect(mapStateToProps)(FilterBar);
