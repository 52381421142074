import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from "reselect";
import { isEqual, values } from "lodash";
import { generateTimeString } from "js-common/lib/helpers";

import { getProtocolDataForLevel } from "../ProtocolData/selectors";
import { filteredRunData, getDeviceData } from "../RunData/selectors";
import {
  getRunSelectionDetailsVersion,
  getRunSelectionType,
  getSelectedRuns,
} from "./selectors";
import {
  canRunTypesBeCombined,
  determineMaxNumberOfSelectedRuns,
} from "./helpers";

export const getFilterBarData = createSelector(
  [getProtocolDataForLevel, getDeviceData],
  (protocolData, deviceData) => {
    const protoArray = values(protocolData);
    const protocolFilterItems = protoArray.map(p => {
      const type = "protocol";
      const display = p.name;
      const value = p.id;

      return {
        type,
        display,
        value,
      };
    });

    const columnDisplay = columnData => {
      const COLUMN_LENGTH_MAX = 8;

      let numberOfColumns = 1;
      if (columnData.length > COLUMN_LENGTH_MAX) {
        numberOfColumns = 2;
      }

      return numberOfColumns;
    };

    const protocolFilters = {
      name: "Protocols",
      filters: protocolFilterItems,
      cols: columnDisplay(protocolData),
    };

    const deviceFilterItems = deviceData.map(d => {
      const type = "device";
      const hwNumber = d[0];
      const display = hwNumber;
      const value = hwNumber;

      return {
        type,
        display,
        value,
      };
    });

    const deviceFilters = {
      name: "Thermocyclers",
      filters: deviceFilterItems,
      cols: columnDisplay(deviceData),
    };

    return [protocolFilters, deviceFilters];
  }
);

const deepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getTableFilteredRunCount = createSelector(
  [filteredRunData],
  runData => runData.length
);

export const getTableRunData = createSelector([filteredRunData], runData => {
  return runData.map(run => {
    const { date } = run;
    const bleName = run.bleName || "N/A";
    const timeOfRun = generateTimeString(date);

    return {
      ...run,
      bleName,
      timeOfRun,
    };
  });
});

export const getTableDisabledRunIds = createSelector(
  [
    getTableRunData,
    getRunSelectionDetailsVersion,
    getRunSelectionType,
    getSelectedRuns,
  ],
  (allRuns, activeDetailsVersion, activeRunType, selectedRuns) => {
    const maximumRunCount = determineMaxNumberOfSelectedRuns(
      activeDetailsVersion,
      activeRunType
    );
    const selectedRunCount = selectedRuns.length;

    return allRuns
      .filter(run => {
        if (
          maximumRunCount === selectedRunCount &&
          !selectedRuns.find(selectedRun => selectedRun.id === run.id)
        ) {
          return true;
        }

        if (
          activeDetailsVersion &&
          activeDetailsVersion !== run.detailsVersion
        ) {
          return true;
        }

        if (selectedRuns.length > 0) {
          const [firstSelectedRun] = selectedRuns;
          const { protocolId, runType } = run;

          return !canRunTypesBeCombined(
            activeRunType,
            runType,
            protocolId,
            firstSelectedRun
          );
        }

        return false;
      })
      .map(run => run.id);
  }
);

export const getTableDeviceData = deepEqualSelector(
  [getDeviceData],
  deviceData => {
    return deviceData.map(deviceInfo => {
      const hwNumber = deviceInfo[0];
      const runCount = deviceInfo[1];

      return {
        id: hwNumber,
        type: "device",
        display: hwNumber,
        value: hwNumber,
        hwNumber,
        runCount,
      };
    });
  }
);

export const getTableProtocolData = deepEqualSelector(
  [getProtocolDataForLevel],
  protocolData => {
    return protocolData.map(protocol => {
      const {
        id,
        name,
        numberOfCycles,
        cycleAnneal,
        firstDenature,
        rtTemp,
        protocolType,
        extension,
        cycleDenature,
      } = protocol;

      return {
        id,
        type: "protocol",
        display: name,
        value: id,
        name,
        rtTemp,
        firstDenature,
        numberOfCycles,
        cycleAnneal,
        cycleDenature,
        extension,
        protocolType,
      };
    });
  }
);
