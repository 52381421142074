import React from "react";

const D1MeltIcon = props => {
  const { width = 18, height = 14 } = props;

  return (
    <svg
      width={width}
      height={height}
      {...props}
      style={{ marginBottom: "4px", marginRight: "7px" }}
    >
      <g fill="#012639" fillRule="nonzero">
        <path d="M17.21 12.545v.75h-3.08c-3.12 0-4.455-2.03-4.455-4.915V7.078A1.305 1.305 0 008.34 5.765h-.16c-.726 0-1.315.589-1.315 1.315V8.3c0 2.805-1.66 4.995-3.995 4.995H.07v-.75h2.8c1.868 0 3.245-1.816 3.245-4.245V7.08c0-1.14.925-2.065 2.065-2.065h.153a2.055 2.055 0 012.092 2.065v1.3c0 2.52 1.08 4.165 3.705 4.165h3.08z" />
        <path d="M17.25 10.725v.75h-1.64c-3.279 0-4.465-1.08-4.465-3.475V6.251a2.625 2.625 0 00-.879-1.884c-.54-.48-1.254-.716-2.039-.653A2.625 2.625 0 005.375 6.24v1.68c0 2.546-1.336 3.555-3.985 3.555H0v-.75h1.39c2.273 0 3.235-.726 3.235-2.805V6.229a3.375 3.375 0 011.13-2.422c.695-.617 1.612-.921 2.472-.84a3.375 3.375 0 013.668 3.273V8c0 1.965.836 2.725 3.715 2.725h1.64z" />
        <path d="M17.25 8.855v.75c-2.327 0-3.404-.174-4.017-.729-.575-.519-.648-1.023-.631-2.884l.003-.612v-.223c.017-2.003-1.844-3.442-4.245-3.442-2.39 0-4.355 1.563-4.355 3.625v.22c0 3.552-.444 4.095-4.005 4.095v-.75c3.11 0 3.255-.177 3.255-3.345v-.22C3.255 2.821 5.589.965 8.36.965c2.78 0 5.016 1.728 4.995 4.195v.22c0 .184-.001.34-.004.62-.014 1.604.045 2.013.384 2.32.42.379 1.384.535 3.515.535z" />
      </g>
    </svg>
  );
};

export default D1MeltIcon;
