import React, { useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";

import Button from "../../Layout/Button";
import Modal from "../../Layout/Modal";
import {
  initializeBulkReviewRequest as initializeBulkReviewRequestAction,
  toggleBulkReviewModal as toggleBulkReviewModalAction,
} from "../actions";
import { bulkReviewModalIsOpen as bulkReviewModalIsOpenSelector } from "../selectors";
import {
  cancelButtonStyle,
  dateInputStyle,
  dateSelectionError,
  modalButtonContainerStyle,
  modalStyle,
} from "./styles";
import { getFormattedDateString } from "../../Utils/helpers";
import { updateAnalysisMode } from "../../RunsPage/actions";
import { analysisModes } from "../../RunsPage/constants";

interface StateProps {
  bulkReviewModalIsOpen: boolean;
}

interface DispatchProps {
  toggleBulkReviewModal: () => void;
  initializeBulkReviewRequest: (date: Date, resolve: any, reject: any) => void;
  setBulkReviewMode: () => void;
}

interface Props extends RouteComponentProps, DispatchProps, StateProps {}

export const BulkReviewModal: React.FunctionComponent<Props> = (props: Props) => {
  const {
    bulkReviewModalIsOpen,
    toggleBulkReviewModal,
    initializeBulkReviewRequest,
    setBulkReviewMode,
  } = props;

  const today = new Date();
  const currentDate = getFormattedDateString(today);

  const [bulkReviewDateString, setBulkReviewDateString] = useState(currentDate);
  const [dateHasReviews, setDateHasReviews] = useState<boolean>(true);

  const closeBulkReviewModal = () => {
    setBulkReviewDateString(currentDate);
    setDateHasReviews(true);
    
    toggleBulkReviewModal();
  }

  const handleDateSubmit = async () => {
    const [year, month, day] = bulkReviewDateString.split("-");

    const yearInt = parseInt(year, 10);
    const monthInt = parseInt(month, 10) - 1; // Months are zero based
    const dayInt = parseInt(day, 10);

    const bulkReviewDate = new Date(yearInt, monthInt, dayInt);

    await new Promise<boolean>((resolve, reject) => {
      initializeBulkReviewRequest(bulkReviewDate, resolve, reject);
    })
      .then(() => {
        setBulkReviewMode();
        closeBulkReviewModal();
      })
      .catch(() => {
        setDateHasReviews(false);
      });
  };

  const _renderDateInput = () => {
    return (
      <div className="date_input" style={dateInputStyle}>
        <h3>Select a date to bulk review:</h3>

        <input
          type="date"
          id="date"
          name="bulk-review-date"
          value={bulkReviewDateString}
          min="2012-01-01"
          max={currentDate}
          onChange={e => {
            return setBulkReviewDateString(e.target.value);
          }}
        />
      </div>
    );
  };

  const _renderNoReviews = () => {
    let reviewStatus = null;

    if (!dateHasReviews) {
      reviewStatus = (
        <div style={dateSelectionError}>
          There are no reviews for the selected date.
        </div>
      );
    }

    return reviewStatus;
  };

  const _renderButtons = () => {
    return (
      <div style={modalButtonContainerStyle}>
        <Button
          title="Review"
          buttonClass="btn-modal"
          onClick={handleDateSubmit}
        />
        <div style={cancelButtonStyle} onClick={closeBulkReviewModal}>
          Cancel
        </div>
        {_renderNoReviews()}
      </div>
    );
  };

  const _renderBulkReviewModal = () => {
    let modal = null;
    if (bulkReviewModalIsOpen) {
      modal = (
        <Modal
          open={bulkReviewModalIsOpen}
          heading="Start Bulk Review"
          onClose={closeBulkReviewModal}
          className="modal--run_detail"
          style={modalStyle}
          bodyClass="modal__analysis"
        >
          {_renderDateInput()}
          {_renderButtons()}
        </Modal>
      );
    }

    return modal;
  };

  return _renderBulkReviewModal();
};

const mapStateToProps = (state: any) => {
  return {
    bulkReviewModalIsOpen: bulkReviewModalIsOpenSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleBulkReviewModal: () => {
      dispatch(toggleBulkReviewModalAction());
    },
    initializeBulkReviewRequest: (date: Date, resolve: any, reject: any) => {
      dispatch(initializeBulkReviewRequestAction(date, resolve, reject));
    },
    setBulkReviewMode: () => {
      return dispatch(updateAnalysisMode(analysisModes.bulkReview));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkReviewModal)
);
