import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ReactToolTip from "react-tooltip";

import { postFeature } from "../../../../App/actions";
import { setPrintIndex, openPrintModal } from "../../../actions";
import { tooltipDelay } from "../../../constants";
import { parseClassName } from "../../../helpers";
import Checkbox from "../../../../Layout/Checkbox";

interface Props {
  runBlock: any;
  isBaselineActive: boolean;
  singleThresholdsEnabled: boolean;
  runTypeIsMelt: boolean;

  toggleRunThreshold: (threshold: string, runId: string) => void;
  openDetailedModal: () => void;
}

interface DispatchProps {
  openPrintModal: () => void;
  setPrintIndex: (runId: string) => void;
  postFeature: (feature: string) => void;
}

const RunBlockButtons = (props: Props & DispatchProps) => {
  const _handleOpenPrintModal = () => {
    // eslint-disable-next-line no-shadow
    const { setPrintIndex, openPrintModal, postFeature, runBlock } = props;

    const { runId } = runBlock;
    const featureName = "print view";

    setPrintIndex(runId);
    openPrintModal();
    postFeature(featureName);
  };

  const _renderPrintButton = () => {
    const { runTypeIsMelt } = props;

    if (runTypeIsMelt) {
      return null;
    }

    return (
      <div
        className={parseClassName(
          "btn-inline btn-detail runtable-form_button",
          ["print_mode_runblock"]
        )}
        data-tip="Print a copy of your unaltered chart."
        onClick={_handleOpenPrintModal}
      >
        <img
          height="14"
          width="14"
          style={{ marginRight: "9px" }}
          alt="Print Icon"
          src="../../../../images/print.svg"
        />
        Print Run
      </div>
    );
  };

  const _renderThresholdCheckbox = () => {
    const {
      runBlock,
      toggleRunThreshold,
      isBaselineActive,
      singleThresholdsEnabled,
    } = props;

    if (singleThresholdsEnabled) {
      return null;
    }

    const checked = runBlock.threshold && isBaselineActive;

    return (
      <div
        className={parseClassName(
          "btn-inline btn-detail",
          ["toggle_threshold_runblock"],
          0
        )}
        data-tip="Display multi-line thresholds for this run."
      >
        <Checkbox
          label="Threshold"
          checked={checked}
          disabled={!isBaselineActive}
          onClick={() => toggleRunThreshold(runBlock.threshold, runBlock.runId)}
        />
      </div>
    );
  };

  const { openDetailedModal } = props;

  return (
    <form className="runtable__buttons runtable-form">
      {_renderThresholdCheckbox()}
      <div
        className={parseClassName(
          "btn-inline btn-detail runtable-form_button",
          ["detailed_data_runblock"],
          0
        )}
        data-tip="Access detailed information about your run(s)."
        onClick={openDetailedModal}
      >
        <img
          src="../../../../images/detailed.svg"
          height="16"
          width="16"
          alt="Detailed Data Icon"
          style={{ marginRight: "9px" }}
        />
        Detailed Data
      </div>
      {_renderPrintButton()}
      <ReactToolTip delayShow={tooltipDelay / 2} />
    </form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openPrintModal: () => {
    dispatch(openPrintModal());
  },
  setPrintIndex: (runId: string) => {
    dispatch(setPrintIndex(runId));
  },
  postFeature: (feature: string) => {
    dispatch(postFeature(feature));
  },
});

export default connect<{}, DispatchProps, {}, Props>(
  null,
  mapDispatchToProps
)(RunBlockButtons);
