import React from "react";

const PrintIconButton = props => (
  <svg width={19} height={19} {...props}>
    <title>Print Icon Button</title>
    <path
      className="sample-hub__invertable-button"
      d="M1.6 15.425h3.35V17.4c0 .337.263.6.6.6h7.9c.337 0 .6-.263.6-.6v-1.975h3.35c.337 0 .6-.263.6-.6V4.175c0-.337-.263-.6-.6-.6h-3.35V1.6c0-.337-.263-.6-.6-.6h-7.9c-.337 0-.6.263-.6.6v1.975H1.6c-.337 0-.6.263-.6.6v10.65c0 .322.278.6.6.6zM12.85 16.8h-6.7v-4.945h6.7V16.8zM6.15 2.2h6.7v1.375h-6.7V2.2zM2.2 4.775h14.6v9.45h-2.75v-2.37h1.068c.336 0 .6-.263.6-.6 0-.336-.264-.6-.6-.6H3.897c-.337 0-.6.264-.6.6 0 .337.263.6.6.6h1.068v2.37H2.2v-9.45z"
      fillRule="nonzero"
      stroke="#02273A"
      strokeWidth={0.25}
    />
  </svg>
);

export default PrintIconButton;
