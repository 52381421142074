import { all, takeLatest, call, select } from "redux-saga/effects";
import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { generateUuid } from "js-common/lib/helpers";
import { DetailsVersion } from "js-common";

import { IExternalUploadRequest } from "./actions";
import { EXTERNAL_UPLOAD_REQUEST } from "./actionTypes";
import {
  IPresignedUrlResponse,
  IRunArchivePayload,
  IS3UploadParameters,
} from "./types";

import { getActiveFolderId } from "../Folders/selectors";
import { getActiveTeamId, getUserId } from "../Auth/selectors";
import { encodeDataAsForm, handleFileZipping } from "./helpers";
import { backendApi } from "../Utils";

function getS3ApiInstance(url: string): ApisauceInstance {
  return create({
    baseURL: url,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

function* handleRunArchiveRequest(
  teamId: string,
  detailsVersion: DetailsVersion,
  payload: IRunArchivePayload
) {
  let success = true;

  try {
    const { ok } = yield call(
      backendApi.post,
      `/runs/archive?teamId=${teamId}&detailsVersion=${detailsVersion}`,
      payload
    );

    if (!ok) {
      success = false;
    }
  } catch (err) {
    success = false;
  }

  if (!success) {
    throw new Error("Failed to upload run to API");
  }
}

function* handleUploadToS3(
  files: Array<File>,
  s3UploadParameters: IS3UploadParameters
) {
  const { url, fields } = s3UploadParameters;

  const s3UploadApi = getS3ApiInstance(url);

  const zippedFiles: Blob = yield call(handleFileZipping, files);
  const form = encodeDataAsForm({ ...fields, file: zippedFiles });

  const { ok: s3UploadOk }: ApiResponse<void> = yield call(
    s3UploadApi.post,
    "",
    form
  );

  if (!s3UploadOk) {
    throw new Error("Failed to upload files to s3");
  }
}

function* handleGetPresignedUrl(runId: string, detailsVersion: DetailsVersion) {
  const teamId: string = yield select(getActiveTeamId);
  const folderId: string = yield select(getActiveFolderId);

  const {
    ok: presignedUrlOk,
    data: presignedUrlData,
  }: ApiResponse<IPresignedUrlResponse> = yield call(
    backendApi.get,
    `/runs/archive?folderId=${folderId}&detailsVersion=${detailsVersion}&runId=${runId}&teamId=${teamId}`
  );

  if (!presignedUrlOk || !presignedUrlData || !presignedUrlData.postData) {
    throw new Error("Failed to retrieve S3 presigned URL");
  }

  const { postData } = presignedUrlData;

  return postData;
}

function* handleExternalUploadRequest(action: IExternalUploadRequest) {
  const { files, options, resolve, reject } = action;
  const { detailsVersion, protocolId, colorMap } = options;

  const runId = generateUuid();

  const userId: string = yield select(getUserId);
  const teamId: string = yield select(getActiveTeamId);
  const parentId: string = yield select(getActiveFolderId);

  try {
    const s3UploadParameters: IS3UploadParameters = yield call(
      handleGetPresignedUrl,
      runId,
      detailsVersion
    );

    yield call(handleUploadToS3, files, s3UploadParameters);

    const { fields } = s3UploadParameters;
    const { key: s3Key } = fields;

    const payload: IRunArchivePayload = {
      runId,
      protocolId,
      parentId,
      userId,
      details: { s3url: s3Key, colorMapping: colorMap },
    };

    yield call(handleRunArchiveRequest, teamId, detailsVersion, payload);

    resolve("Successfully uploaded run! Please wait...");
  } catch (error) {
    reject("An error has occurred while uploading your files. Try again?");
  }
}

export function* watchExternalUploadRequest() {
  yield takeLatest(EXTERNAL_UPLOAD_REQUEST, handleExternalUploadRequest);
}

export default function* rootSaga() {
  yield all([watchExternalUploadRequest()]);
}
