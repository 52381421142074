import React from "react";

import { BugReportFormState, BugReportErrorState } from "../types";
import RadioGroup from "./RadioGroup";
import {
  OPERATING_SYSTEM_WINDOWS,
  OPERATING_SYSTEM_MAC,
  OTHER,
} from "../constants";

interface Props {
  handleOnChange: (formBundle: BugReportFormState) => void;
  form: BugReportFormState;
  errors: BugReportErrorState;
}

const BugReportOperatingSystemField: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { form, handleOnChange, errors } = props;
  const { operatingSystem } = form;
  const { operatingSystemError } = errors;

  const _renderError = () => {
    let errorElement = null;

    if (operatingSystemError) {
      errorElement = (
        <div className="bug_report__input_error">{operatingSystemError}</div>
      );
    }

    return errorElement;
  };

  const _renderRadios = () => {
    const radioProps = [
      {
        label: OPERATING_SYSTEM_WINDOWS,
        onClick: () =>
          handleOnChange({
            ...form,
            operatingSystem: OPERATING_SYSTEM_WINDOWS,
          }),
        checked: operatingSystem === OPERATING_SYSTEM_WINDOWS,
      },
      {
        label: OPERATING_SYSTEM_MAC,
        onClick: () =>
          handleOnChange({
            ...form,
            operatingSystem: OPERATING_SYSTEM_MAC,
          }),
        checked: operatingSystem === OPERATING_SYSTEM_MAC,
      },
      {
        label: OTHER,
        onClick: () =>
          handleOnChange({
            ...form,
            operatingSystem: OTHER,
          }),
        checked: operatingSystem === OTHER,
      },
    ];

    return <RadioGroup radioButtonProps={radioProps} />;
  };

  return (
    <div className="bug_report__input_container">
      <h4>Operating System Used*</h4>
      {_renderRadios()}
      {_renderError()}
    </div>
  );
};

export default BugReportOperatingSystemField;
