import React, { useEffect } from "react";
import { connect } from "react-redux";

import Button from "../../Layout/Button";

import LoginForm from "./loginForm";
import AnnouncementsModal from "../../App/components/AnnouncementsModal";
import {
  isAnnouncementsModalOpen,
  getActiveAnnouncements,
} from "../../App/selectors";
import {
  closeAnnouncementsModal,
  checkForActiveAnnouncements,
} from "../../App/actions";

export const LoginFormWrapper = props => {
  const {
    closeModal,
    announcementsModalIsOpen,
    activeAnnouncements,
    checkAnnouncements,
  } = props;

  useEffect(() => {
    checkAnnouncements();
  }, []);

  const _renderAnnouncementsModal = () => {
    if (!announcementsModalIsOpen) {
      return null;
    }

    return (
      <AnnouncementsModal
        closeModal={closeModal}
        announcementsModalIsOpen={announcementsModalIsOpen}
        activeAnnouncements={activeAnnouncements}
      />
    );
  };

  return (
    <div className="grid-container">
      {_renderAnnouncementsModal()}
      <div className="login">
        <div className="login__title">
          <h1 style={{ fontWeight: "600" }}>Cloud Login</h1>
        </div>
        <LoginForm />
      </div>
      <div className="login__right interior-grid-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ paddingLeft: "55px", marginBottom: "130px" }}>
            <div className="login__right-header">Your PCR Test Data.</div>
            <div className="login__right-subheader">Your Cloud.</div>
            <div className="login__right-text" style={{ marginTop: "10px" }}>
              Manage data from multiple Biomeme devices
            </div>
            <div className="login__right-text">
              and experiments in one convenient place.
            </div>
            <Button
              title="Request a Demo"
              buttonClass="demo-button"
              onClick={() =>
                window.open("https://biomeme.typeform.com/to/y34qlp", "_self")
              }
            />
          </div>
        </div>
        <img
          className="login__hero"
          src="../../../images/CloudLandingHero.png"
          alt=""
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    announcementsModalIsOpen: isAnnouncementsModalOpen(state),
    activeAnnouncements: getActiveAnnouncements(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeAnnouncementsModal());
    },
    checkAnnouncements: () => {
      dispatch(checkForActiveAnnouncements());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormWrapper);
