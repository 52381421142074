import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { saveAs } from "file-saver";

import {
  ReviewRule,
  Run,
  RawRunReview,
  ReviewUserRole,
  User,
  RawRunReviewSummary,
} from "js-common";
import {
  createQALogWorkbook,
  createReviewSummaryWorkbook,
} from "excel-sheet-creator";

import * as t from "./actionTypes";
import {
  initializeRunReviewRequest,
  initializeRunReviewSuccess,
  setPreviousReviewUser,
  setReviewDashboardLoading,
  initializeReviewDashboardSuccess,
  setTargetOverride,
  ISubmitRunReviewRequest,
  IInitializeRunReviewRequest,
  ISetSampleBlank,
  IExportAllQALog,
  initializeBulkReviewSuccess,
  IInitializeBulkReviewRequest,
  IBulkReviewApproveAllRequest,
  bulkReviewSetReviewSubmissionStatus,
} from "./actions";
import {
  getReviewRules,
  getRunReview,
  getReviewLog,
  getReviewExportData,
  getPendingRuns,
  getActiveBulkReviewRuns,
  getActiveBulkFullRunData,
} from "./selectors";
import {
  getActiveTeamId,
  getAwsUser,
  getActiveTeamFlags,
  getActiveTeamName,
  getUserId,
} from "../Auth/selectors";
import {
  getRunProtocolId,
  getRunDevice,
  getFullRunData,
} from "../FullRunData/selectors";

import {
  parseRawRunReview,
  getEmptyReview,
  setCqToOverriddenCq,
  getRunTargetsBySample,
  processBulkReviewData,
  getSevenDaysAgoDate,
  parseRawBulkSampleReviews as parseRawBulkSampleReview,
} from "./helpers";
import { backendApi } from "../Utils/index";
import {
  BaseSample,
  BulkReviewRun,
  ExistingReviewData,
  PendingRun,
  PreviousReviewUser,
  RunReview,
  RunReviewPayload,
} from "./types";
import { hasPermission, getFormattedDateString } from "../Utils/helpers";
import {
  addSelectedRunBulkReview,
  resetSelectedRuns,
  showChart,
} from "../Hub/actions";
import { getSelectedRuns } from "../Hub/selectors";
import { generateFilename } from "../RunsPage/components/ExportReviewModal/helpers";
import { BLOB_TYPE } from "./constants";
import CloudLog from "../Utils/CloudLog";
import { getFullRunDataBulkReview } from "../FullRunData/actions";
import { BulkFullRunDataByDetailsVersion, RunData } from "../FullRunData/types";

export function* handleSubmitRunReviewRequest(
  action: ISubmitRunReviewRequest
): unknown {
  const { resolve, reject } = action;

  const teamId: string = yield select(getActiveTeamId);
  const review: RunReview = yield select(getRunReview);
  const fullRunData: RunData[] = yield select(getFullRunData);
  const detailsVersion = fullRunData[0]?.detailsVersion || "biomeme-2";

  const { runId } = review;
  let { samples } = review;

  // This deals with the backend not accepting overridenCq
  // see Jira ticket WEB-1636 for details
  samples = setCqToOverriddenCq(samples);

  let failureReason: string | null = null;
  if (review.failureReason.length > 0) {
    failureReason = review.failureReason.join(", ");
  }

  const payload: RunReviewPayload = {
    ...review,
    samples,
    failureReason,
  };

  let success = false;
  try {
    const { ok } = yield call(
      backendApi.post,
      `/review/run/${runId}?teamId=${teamId}&detailsVersion=${detailsVersion}`,
      JSON.stringify(payload)
    );
    if (ok) {
      success = true;
    }
  } catch (e) {
    CloudLog.error(`Error processing run review post request ${e}`);
  } finally {
    if (success) {
      yield put(initializeRunReviewRequest(runId));
      resolve("Successfully submitted run review!");
    } else {
      reject(
        "An error occurred and your review could not be processed. Try again?"
      );
    }
  }
}

export function* watchSubmitRunReviewRequest(): any {
  yield takeLatest(t.SUBMIT_RUN_REVIEW_REQUEST, handleSubmitRunReviewRequest);
}

export function* getExistingReviewData(runId: string): unknown {
  const teamId: string = yield select(getActiveTeamId);

  const { data, ok, problem, status } = yield call(
    backendApi.get,
    `/review/run/${runId}?teamId=${teamId}`
  );

  if (!ok) {
    throw Error(`Review data retrieval call failed with ${status}/${problem}`);
  }

  let reviewData: ExistingReviewData | null = null;

  if (status === 200 && data) {
    const latestReview = data.reduce((a: RawRunReview, b: RawRunReview) => {
      if (a.date > b.date) {
        return a;
      }

      return b;
    });

    reviewData = {
      reviews: data,
      latestReview,
    };
  }

  return reviewData;
}

export function* handleInitializeRunReviewRequest(
  action: IInitializeRunReviewRequest
): unknown {
  const { runId } = action;

  try {
    const user: User = yield select(getAwsUser);
    const userFlags: Array<string> = yield select(getActiveTeamFlags);

    let userRole: ReviewUserRole = "user";
    if (hasPermission(userFlags, "accessReviewAdmin")) {
      userRole = "admin";
    }

    let previousReviewUser: PreviousReviewUser = {
      name: "Incomplete",
      isAdmin: false,
    };

    const [runData]: Array<RunData> = yield select(getFullRunData);
    const existingReviewData: ExistingReviewData = yield call(
      getExistingReviewData,
      runId
    );

    let existingReviews: RawRunReview[] | undefined;
    let initializedReview: RunReview;
    if (existingReviewData) {
      const { latestReview, reviews } = existingReviewData;

      const rules: ReviewRule = yield select(getReviewRules);
      initializedReview = yield call(
        parseRawRunReview,
        latestReview,
        rules,
        runData,
        user.username,
        userRole
      );
      existingReviews = reviews;

      previousReviewUser = {
        name: latestReview.reviewerName,
        isAdmin: latestReview.reviewerRole === "admin",
      };
    } else {
      const samples: Array<BaseSample> = getRunTargetsBySample(runData);
      const rules: ReviewRule = yield select(getReviewRules);
      const protocolId: string = yield select(getRunProtocolId);
      const deviceSerial: string = yield select(getRunDevice);

      initializedReview = yield call(
        getEmptyReview,
        runId,
        protocolId,
        user.username,
        userRole,
        deviceSerial,
        rules,
        samples,
        runData
      );
    }

    yield put(setPreviousReviewUser(previousReviewUser));
    yield put(initializeRunReviewSuccess(initializedReview, existingReviews));
  } catch (e) {
    CloudLog.error(`Error processing initialize run review request ${e}`);
  } finally {
    yield put(showChart());
  }
}

export function* watchInitializeRunReviewRequest(): any {
  yield takeLatest(
    t.INITIALIZE_RUN_REVIEW_REQUEST,
    handleInitializeRunReviewRequest
  );
}

export function* handleExportQALog(): unknown {
  const runs: Array<Run> = yield select(getSelectedRuns);
  const [activeRun] = runs;
  const filename = `${activeRun.id}_${activeRun.name}_QA_log.xlsx`;

  const reviews: Array<RawRunReview> = yield select(getReviewLog);
  const workbook: ArrayBuffer = yield call(createQALogWorkbook, reviews);

  yield call(
    saveAs,
    new Blob([workbook], {
      type: BLOB_TYPE,
    }),
    filename
  );
}

export function* watchExportQALog(): unknown {
  yield takeLatest(t.EXPORT_QA_LOG, handleExportQALog);
}

export function* handleExportAllQALog(action: IExportAllQALog): unknown {
  const { resolve, reject } = action;

  yield put(setReviewDashboardLoading(true));

  const teamId: string = yield select(getActiveTeamId);
  const team: string = yield select(getActiveTeamName);

  const filename = `${team}_QA_log.xlsx`;
  const reviews: RawRunReview[] = [];

  let error = null;
  try {
    let url: any = `team?teamId=${teamId}&page=1`;
    while (url) {
      const { status, data } = yield call(backendApi.get, `review/${url}`);

      if (status === 200) {
        reviews.push(...data.results);
        url = data.next;
      } else if (status === 204) {
        url = undefined;
        error = "This team has no review activity.";
      } else {
        url = undefined;
        error = "An unexpected error occurred. Try again?";
      }
    }

    if (reviews.length > 0) {
      const workbook: ArrayBuffer = yield call(createQALogWorkbook, reviews);

      yield call(
        saveAs,
        new Blob([workbook], {
          type: BLOB_TYPE,
        }),
        filename
      );
    }
  } catch (e) {
    CloudLog.error(`Failed to complete QA log export ${e}`);
    error = "An unexpected error occurred. Try again?";
  } finally {
    yield put(setReviewDashboardLoading(false));

    if (error) {
      reject(error);
    } else {
      resolve();
    }
  }
}

export function* watchExportAllQALog(): unknown {
  yield takeLatest(t.EXPORT_ALL_QA_LOG, handleExportAllQALog);
}

export function* handleInitializeReviewDashboardRequest(): unknown {
  yield put(setReviewDashboardLoading(true));

  const teamId: string = yield select(getActiveTeamId);

  const afterDate: Date = yield call(getSevenDaysAgoDate);
  const formattedAfterDate: Date = yield call(
    getFormattedDateString,
    afterDate
  );

  try {
    const { ok, problem, data } = yield call(
      backendApi.get,
      `review/run?teamId=${teamId}&after=${formattedAfterDate}`
    );

    if (ok) {
      const newData = data || [];
      yield put(initializeReviewDashboardSuccess(newData));
    } else {
      CloudLog.error(
        `Failed to initialize review dashboard, server responded with ${problem}`
      );
    }
  } catch (e) {
    CloudLog.error(`An error occurred initializing the review dashboard ${e}`);
  } finally {
    yield put(setReviewDashboardLoading(false));
  }
}

export function* watchInitializeReviewDashboardRequest(): unknown {
  yield takeLatest(
    t.INITIALIZE_REVIEW_DASHBOARD_REQUEST,
    handleInitializeReviewDashboardRequest
  );
}

export function* handleSetSampleBlank(action: ISetSampleBlank): unknown {
  const { sampleId, blank } = action;
  const activeReview: RunReview = yield select(getRunReview);
  const { samples } = activeReview;

  const sampleIndex = samples.findIndex(sample => {
    return sample.sampleName === sampleId;
  });

  if (sampleIndex > -1) {
    const sample = samples[sampleIndex];

    // eslint-disable-next-line no-restricted-syntax
    for (const target of sample.targets) {
      let overrideCq: boolean | null = null;
      if (blank) {
        overrideCq = false;
      }

      yield put(setTargetOverride(sampleId, target.targetId, overrideCq));
    }
  }
}

export function* watchSetSampleBlank(): unknown {
  yield takeEvery(t.SET_SAMPLE_BLANK, handleSetSampleBlank);
}

export function* handleExportReviewSummary(): unknown {
  const reviewData: Array<RawRunReviewSummary> = yield select(
    getReviewExportData
  );

  let runName = "";
  if (reviewData && reviewData.length !== 0) {
    runName = reviewData[reviewData.length - 1].run;
  }
  const summaryFileName: string = generateFilename(
    runName,
    "Review summary of"
  );
  try {
    const summaryWorkbook: ArrayBuffer =
      createReviewSummaryWorkbook(reviewData);

    yield call(
      saveAs,
      new Blob([summaryWorkbook], {
        type: BLOB_TYPE,
      }),
      summaryFileName
    );
  } catch (e) {
    CloudLog.error(`Error in Export Review Modal (Summary Download): ${e}`);
  }
}

export function* watchExportReviewSummary(): unknown {
  yield takeLatest(t.EXPORT_REVIEW_SUMMARY, handleExportReviewSummary);
}

/**
 * This generator will iterate through Bulk Pending Runs and get
 * the existing run data for each Pending Run
 *
 * @export
 * @param {PendingRun[]} pendingRuns
 * @return {BulkReviewRun[]}  BulkReviewRun[]
 */
export function* getFullBulkReviewRunData(pendingRuns: PendingRun[]): unknown {
  const bulkReviewsWithReviewData: BulkReviewRun[] = [];

  if (pendingRuns) {
    for (let i = 0; i < pendingRuns.length; i += 1) {
      const pendingRun = pendingRuns[i];
      const { id: runId, protocolId } = pendingRun;

      yield put(addSelectedRunBulkReview(pendingRun));

      const existingReviewData: ExistingReviewData = yield call(
        getExistingReviewData,
        runId
      );

      const teamId = yield select(getActiveTeamId);
      const reviewRulesCall = yield call(
        backendApi.get,
        `/review/rule?teamId=${teamId}&protocolId=${protocolId}`
      );

      if (!reviewRulesCall.data || !reviewRulesCall.data.rules) {
        const errorString =
          "Bulk Review: Only runs associated with protocols that contain valid rule objects are eligible for review.";

        CloudLog.error(errorString);
        throw new Error(errorString);
      }

      const ruleData = reviewRulesCall.data;
      const parsedRules = JSON.parse(ruleData.rules);
      const reviewRules: ReviewRule = {
        id: parsedRules.id,
        version: parsedRules.version,
        controlRules: parsedRules.controlRules,
        sampleRules: parsedRules.sampleRules,
      };

      const bulkReviewWithReviewData: BulkReviewRun = {
        ...pendingRun,
        existingReviewData,
        reviewRules,
      } as BulkReviewRun;

      bulkReviewsWithReviewData.push(bulkReviewWithReviewData);
    }
  }

  return bulkReviewsWithReviewData;
}

/**
 * Saga to capture requests to use the bulk review feature
 * This will ultimately provide us with Reviews needing admin
 * approval and the related data needed to do that
 *
 * @export
 * @param {IInitializeBulkReviewRequest} action
 */
export function* initializeBulkReviewRequest(
  action: IInitializeBulkReviewRequest
): unknown {
  const { date, resolve, reject } = action;

  yield put(resetSelectedRuns());

  const teamId: string = yield select(getActiveTeamId);

  const currentAfterDate: Date = yield call(getSevenDaysAgoDate);

  const formattedDate: Date = yield call(getFormattedDateString, date);

  const isIncludedInInitialPendingReviewRunsCall: boolean =
    date >= currentAfterDate;

  if (isIncludedInInitialPendingReviewRunsCall) {
    const pendingRuns: PendingRun[] = yield select(getPendingRuns);

    let newPendingRuns = [];
    if (pendingRuns.length > 0) {
      newPendingRuns = yield call(processBulkReviewData, pendingRuns, date);

      if (newPendingRuns.length > 0) {
        resolve();
        yield put(setReviewDashboardLoading(true));

        const bulkReviewsWithReviewData: BulkReviewRun[] = yield call(
          getFullBulkReviewRunData,
          newPendingRuns
        );

        yield put(getFullRunDataBulkReview());
        yield put(initializeBulkReviewSuccess(bulkReviewsWithReviewData));
      } else {
        reject();
      }
    } else {
      reject();
    }
  } else {
    try {
      const { ok, problem, data } = yield call(
        backendApi.get,
        `review/run?teamId=${teamId}&date=${formattedDate}`
      );

      if (ok) {
        let newData: PendingRun[] = [];
        if (data.length > 0) {
          newData = yield call(processBulkReviewData, data, date);

          if (newData.length > 0) {
            resolve();
            yield put(setReviewDashboardLoading(true));

            const bulkReviewsWithReviewData: BulkReviewRun[] = yield call(
              getFullBulkReviewRunData,
              newData
            );

            yield put(getFullRunDataBulkReview());

            yield put(initializeBulkReviewSuccess(bulkReviewsWithReviewData));
          } else {
            reject();
          }
        } else {
          reject();
        }
      } else {
        CloudLog.error(
          `Failed to initialize bulk review, server responded with ${problem}`
        );
        reject();
      }
    } catch (e) {
      CloudLog.error(`An error occurred initializing bulk review ${e}`);
      reject();
    }
  }
}

/**
 * Watches for the Bulk Review feature to be requested
 *
 * @export
 */
export function* watchInitializeBulkReviewRequest(): unknown {
  yield takeLatest(
    t.INITIALIZE_BULK_REVIEW_REQUEST,
    initializeBulkReviewRequest
  );
}

export function* bulkReviewApproveAllRequest(
  action: IBulkReviewApproveAllRequest
): unknown {
  const { resolve, reject } = action;

  const activeBulkReviewRuns: BulkReviewRun[] = yield select(
    getActiveBulkReviewRuns
  );
  const activeBulkFullRunData: BulkFullRunDataByDetailsVersion = yield select(
    getActiveBulkFullRunData
  );

  const teamId: string = yield select(getActiveTeamId);
  const reviewerId: string = yield select(getUserId);

  let allSuccess = true;

  for (let i = 0; i < activeBulkReviewRuns.length; i += 1) {
    const approvedReview = activeBulkReviewRuns[i];
    const {
      id: runId,
      protocolId,
      detailsVersion,
      existingReviewData,
      reviewRules,
    } = approvedReview;
    const currentBulkFullRunDataBucket: any =
      activeBulkFullRunData[detailsVersion];

    const [currentBulkFullRun] = currentBulkFullRunDataBucket.filter(
      (bulkFullRun: any) => {
        return runId === bulkFullRun.id;
      }
    );

    if (currentBulkFullRun) {
      const { latestReview } = existingReviewData;
      const {
        pcrEquipmentId,
        experimentId,
        ruleId,
        passed,
        failureReason,
        comments,
        samples,
      } = latestReview;

      const parsedReviewSamples = parseRawBulkSampleReview(
        samples,
        reviewRules,
        currentBulkFullRun,
        detailsVersion
      );

      const payload: RunReviewPayload = {
        runId,
        protocolId,
        pcrEquipmentId,
        experimentId,
        ruleId,
        passed,
        failureReason,
        reviewerId,
        reviewerRole: "admin",
        comments,
        samples: parsedReviewSamples,
      };

      let success = false;
      try {
        const { ok } = yield call(
          backendApi.post,
          `/review/run/${runId}?teamId=${teamId}&detailsVersion=${detailsVersion}`,
          JSON.stringify(payload)
        );
        if (ok) {
          success = true;
        }
      } catch (e) {
        CloudLog.error(`Error processing run review post request ${e}`);
      } finally {
        if (success) {
          yield put(
            bulkReviewSetReviewSubmissionStatus({
              ...approvedReview,
              submissionSuccessful: true,
            })
          );
        } else {
          yield put(
            bulkReviewSetReviewSubmissionStatus({
              ...approvedReview,
              submissionSuccessful: false,
            })
          );

          allSuccess = false;
        }
      }
    } else {
      allSuccess = false;
    }
  }

  yield put(resetSelectedRuns());

  if (allSuccess) {
    resolve("Successfully submitted all run reviews!");
  } else {
    reject("Some reviews had errors, check them out above!");
  }
}

export function* watchBulkReviewApproveAllRequest(): unknown {
  yield takeLatest(
    t.BULK_REVIEW_APPROVE_ALL_REQUEST,
    bulkReviewApproveAllRequest
  );
}

export default function* (): any {
  yield all([
    watchSubmitRunReviewRequest(),
    watchInitializeRunReviewRequest(),
    watchExportQALog(),
    watchExportAllQALog(),
    watchInitializeReviewDashboardRequest(),
    watchSetSampleBlank(),
    watchExportReviewSummary(),
    watchInitializeBulkReviewRequest(),
    watchBulkReviewApproveAllRequest(),
  ]);
}
