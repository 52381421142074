import { Subscription } from "js-common";

import { UserDetails } from "./types";

export const getSettingsError = (state: any): string => state.settings.error;
export const getUserUpdatedStatus = (state: any): boolean =>
  state.settings.updated;
export const getUserDetails = (state: any): UserDetails => state.settings.user;

export const getUserSubscriptionDetails = (state: any): Subscription[] =>
  state.settings.subscriptions;
