import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { PaginatedSearchableTable, Sorting } from "bio-web-components";

import {
  selectMultipleRuns,
  addSelectedRun,
  resetSelectedRuns,
  updatePath,
} from "../../Hub/actions";

import { getFilteredPendingRuns } from "../selectors";
import { PendingRun } from "../types";
import { analysisModes } from "../../RunsPage/constants";
import { FilterBar } from "../../Hub/components/filterBar";
import { getRunsWithIcons } from "../../Utils/helpers";
import { RUNS_PAGE_COUNT } from "../../Hub/constants";
import { REVIEW_DASHBOARD_PATH } from "../constants";

interface StateProps {
  pendingRuns: Array<PendingRun>;
}

interface DispatchProps {
  selectRun: () => void;
  setSelectedRun: (run?: PendingRun) => void;
  resetSelection: () => void;
  setHubBackPath: () => void;
}

interface Props {}

const PendingRunTable = (props: Props & StateProps & DispatchProps) => {
  const { pendingRuns } = props;

  const _handleRunSelection = (id: string) => {
    const { resetSelection, setHubBackPath, setSelectedRun, selectRun } = props;
    const selectedRun = pendingRuns.find(run => run.id === id);

    resetSelection();
    setHubBackPath();

    setSelectedRun(selectedRun);
    selectRun();
  };

  const dataKeyMap = {
    name: "displayName",
    status: "status",
    protocol: "protocol",
    user: "user",
    "time of run": "date",
    "time of review": "reviewDate",
  };

  const defaultSort: Sorting = {
    sortBy: "date",
    direction: -1,
  };

  return (
    <>
      <FilterBar selectedRuns={undefined} showDropDown={false} />
      <div className="tableContainer">
        <div className="inner">
          <PaginatedSearchableTable
            searchableTableProps={{
              cellClasses: "searchable_table-font",
              labelKeyMap: dataKeyMap,
              data: getRunsWithIcons(pendingRuns),
              onRowSelection: _handleRunSelection,
              defaultSort,
            }}
            pageSize={RUNS_PAGE_COUNT}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  pendingRuns: getFilteredPendingRuns(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectRun: () => dispatch(selectMultipleRuns(analysisModes.review)),
  setSelectedRun: (run?: PendingRun) => dispatch(addSelectedRun(run)),
  resetSelection: () => dispatch(resetSelectedRuns()),
  setHubBackPath: () => dispatch(updatePath(REVIEW_DASHBOARD_PATH)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingRunTable);
