import { determinePeakTemperature } from "bm-algorithm-js/lib/calculate";

import * as t from "./actionTypes";
import { generateInitialState } from "./chartGenerators";
import {
  singleThresholdCalculator,
  calculateBackgroundValues,
  calculateMetrics,
  calculateEndRfuMetrics,
  hasMultipleCrossings,
} from "./helpers";
import { getCrossingPoint } from "../Utils/helpers";
import {
  analysisModes,
  initialAnalysisModeSettings,
  chartTypes,
  initialChartTypeSettings,
} from "./constants";
import {
  filterAndDeactivateRunBlocks,
  filterAndActivateRunBlocks,
} from "../RunReview/helpers";

export const initState = {
  data: [],
  yAxis: {
    max: null,
    min: null,
  },
  xAxis: {
    length: null,
    data: null,
  },
  backgroundValues: {
    leftVal: 5,
    rightVal: 20,
    originalLeftVal: 5,
    originalRightVal: 20,
  },
  showDetailedModal: false,
  showAnalysisModal: false,
  showExportModal: false,
  showExportReviewModal: false,
  showCyclesDisplayedModal: false,
  lowerBound: -1,
  upperBound: -1,
  printIndex: -1,
  metrics: {
    green: {},
    red: {},
    amber: {},
  },
  endRfuMetrics: {
    green: {},
    red: {},
    amber: {},
  },
  channelFilters: [],
  wellFilters: [],
  showTabularData: false,
  chartTypeSettings: initialChartTypeSettings,
  analysisModeSettings: initialAnalysisModeSettings,
  tabularData: {
    filter: "RUN NAME",
    order: 0,
  },
  showTutorial: false,
  tutorialHasBeenViewed: null,
  isLogScale: false,
  tutorialRunBlockOpenState: false,
  chartBackgroundValuesUpdateError: false,
  showBulkReviewSummaryModal: false,
  bulkReviewFilter: "all",
};

function runsPageReducer(state = initState, action) {
  switch (action.type) {
    case t.CHART_INITIALIZE: {
      const { analysisModeSettings } = state;
      let { tutorialHasBeenViewed } = state;
      const { isLogScale } = state;

      const chartTypeSettings = {
        ...initialChartTypeSettings,
        [chartTypes.baseline]: analysisModeSettings[analysisModes.baseline],
        [chartTypes.singleThreshold]:
          analysisModeSettings[analysisModes.singleThreshold] ||
          analysisModeSettings[analysisModes.reprocessing],
      };

      let showTutorial = false;

      if (!tutorialHasBeenViewed) {
        const tutorialStringFromLocalStorage = localStorage.getItem(
          "tutorialHasBeenViewed"
        );

        if (tutorialStringFromLocalStorage) {
          tutorialHasBeenViewed = tutorialStringFromLocalStorage === "true";
        } else {
          showTutorial = true;
          tutorialHasBeenViewed = true;
          localStorage.setItem("tutorialHasBeenViewed", JSON.stringify(true));
        }
      }

      let backgroundValues = {
        ...state.backgroundValues,
      };

      if (analysisModeSettings[analysisModes.reprocessing]) {
        backgroundValues = { ...calculateBackgroundValues(action.runs) };
      }

      return {
        ...initState,
        ...generateInitialState(action.runs, action.version),
        analysisModeSettings,
        chartTypeSettings,
        showTutorial,
        tutorialHasBeenViewed,
        backgroundValues,
        reprocessingError: state.reprocessingError || "",
        reviewModeError: state.reviewModeError || "",
        isLogScale,
      };
    }

    case t.CHART_RESET: {
      return {
        ...initState,
      };
    }

    case t.CHART_RESET_BACKGROUND_VALUES: {
      const { fullRunData } = action;

      const newBackgroundValues = {
        ...state.backgroundValues,
      };

      newBackgroundValues.leftVal = newBackgroundValues.originalLeftVal;
      newBackgroundValues.rightVal = newBackgroundValues.originalRightVal;

      return {
        ...state,
        ...generateInitialState(fullRunData, "biomeme-2", true),
        backgroundValues: { ...newBackgroundValues },
      };
    }

    case t.CHART_UPDATE_BACKGROUND_VALUES: {
      const { values } = action;

      const newBackgroundValues = {
        ...state.backgroundValues,
        leftVal: values[0],
        rightVal: values[1],
      };

      return {
        ...state,
        backgroundValues: { ...newBackgroundValues },
      };
    }

    case t.CHART_STORE_REPROCESSED_DATA: {
      const { reprocessedData } = action;
      const prevState = { ...state };

      let initialState = generateInitialState(
        reprocessedData,
        "biomeme-2",
        true
      );

      initialState.runBlocks = [...prevState.runBlocks];
      initialState.channelFilters = [...prevState.channelFilters];
      initialState.wellFilters = [...prevState.wellFilters];

      initialState = {
        ...initialState,
        data: initialState.data.map(target => {
          const previousTarget = prevState.data.find(x => target.id === x.id);

          return {
            ...target,
            singleThreshold: previousTarget.singleThreshold,
            showLine: previousTarget.showLine,
            SQ: previousTarget.SQ,
          };
        }),
      };

      return {
        ...state,
        ...initialState,
      };
    }

    case t.UPDATE_ANALYSIS_MODE: {
      const { mode } = action;

      const analysisModeSettings = { ...initialAnalysisModeSettings };

      if (mode === analysisModes.singleThreshold) {
        analysisModeSettings[analysisModes.singleThreshold] = true;
      } else if (mode === analysisModes.baseline) {
        analysisModeSettings[analysisModes.baseline] = true;
      } else if (mode === analysisModes.reprocessing) {
        analysisModeSettings[analysisModes.reprocessing] = true;
      } else if (mode === analysisModes.review) {
        analysisModeSettings[analysisModes.review] = true;
      } else if (mode === analysisModes.bulkReview) {
        analysisModeSettings[analysisModes.bulkReview] = true;
      }

      return {
        ...state,
        analysisModeSettings,
      };
    }

    case t.SHOW_BASELINE: {
      const data = [...state.data];
      const { analysisModeSettings } = state;

      const chartTypeSettings = {
        ...initialChartTypeSettings,
        [chartTypes.baseline]: analysisModeSettings[analysisModes.baseline],
        [chartTypes.singleThreshold]:
          analysisModeSettings[analysisModes.singleThreshold] ||
          analysisModeSettings[analysisModes.reprocessing],
      };

      data.forEach((d, i) => {
        const obj = { ...data[i] };
        obj.values = [...data[i].baselineValues];

        const { threshold: objThreshold } = obj;

        let threshold = objThreshold;
        if (chartTypeSettings[chartTypes.singleThreshold]) {
          threshold = obj.singleThreshold;
        }

        if (obj.meltTemperatures && obj.meltTemperatures.length > 1) {
          obj.CQ = determinePeakTemperature(
            obj.baselineValues,
            obj.meltTemperatures
          );
        } else if (!hasMultipleCrossings(d)) {
          obj.CQ = getCrossingPoint(
            obj.baselineValues,
            threshold,
            0,
            obj.baselineValues.length
          );
        }

        data.splice(i, 1, obj);
      });

      return {
        ...state,
        chartTypeSettings,
        data,
        showTabularData: false,
      };
    }

    case t.SHOW_RAW: {
      const data = [...state.data];
      let newRunBlocks = [...state.runBlocks];

      const chartTypeSettings = {
        ...initialChartTypeSettings,
        [chartTypes.raw]: true,
      };

      data.forEach((d, i) => {
        const obj = { ...data[i] };
        obj.values = [...data[i].rawValues];
        data.splice(i, 1, obj);
      });

      newRunBlocks = newRunBlocks.map(runBlock => ({
        ...runBlock,
        threshold: false,
      }));

      return {
        ...state,
        chartTypeSettings,
        data,
        runBlocks: newRunBlocks,
        showTabularData: false,
      };
    }

    case t.TOGGLE_LOG: {
      const { isLogScale } = state;

      if (isLogScale) {
        return {
          ...state,
          isLogScale: false,
        };
      }

      return {
        ...state,
        isLogScale: true,
      };
    }

    case t.SHOW_TABULAR_DATA: {
      return {
        ...state,
        showTabularData: true,
      };
    }

    case t.CHART_UPDATE_TABULAR_DATA_OPTIONS: {
      const newTabularData = { ...state.tabularData };
      const { order, filter } = action;

      newTabularData.order = order;
      newTabularData.filter = filter;

      return {
        ...state,
        tabularData: newTabularData,
      };
    }

    case t.SHOW_SINGLE_THRESHOLDS: {
      const newData = singleThresholdCalculator(state.data);
      let newRunBlocks = [...state.runBlocks];

      const chartTypeSettings = {
        ...initialChartTypeSettings,
        [chartTypes.singleThreshold]: true,
      };

      newRunBlocks = newRunBlocks.map(runBlock => ({
        ...runBlock,
        threshold: false,
      }));

      newData.forEach((d, i) => {
        const obj = { ...newData[i] };

        if (obj.meltTemperatures && obj.meltTemperatures.length > 1) {
          obj.CQ = determinePeakTemperature(
            obj.baselineValues,
            obj.meltTemperatures
          );
        } else {
          obj.CQ = getCrossingPoint(
            obj.baselineValues,
            obj.singleThreshold,
            0,
            obj.baselineValues.length
          );
        }

        newData.splice(i, 1, obj);
      });

      return {
        ...state,
        chartTypeSettings,
        data: newData,
        runBlocks: newRunBlocks,
      };
    }

    case t.CHART_HIDE_SINGLE_TARGET: {
      const newState = { ...state };
      const { runId, well, channelNum } = action;

      let newRunsPageData = [...newState.data];

      const indexToUpdate = newRunsPageData
        .map(target => target.id)
        .indexOf(`${runId}-${well}-${channelNum}`);

      const newTarget = { ...newRunsPageData[indexToUpdate] };

      newTarget.showLine = false;

      newRunsPageData = [
        ...newRunsPageData.slice(0, indexToUpdate),
        newTarget,
        ...newRunsPageData.slice(indexToUpdate + 1, newRunsPageData.length),
      ];

      return {
        ...newState,
        data: newRunsPageData,
      };
    }

    case t.CHART_SHOW_SINGLE_TARGET: {
      const newState = { ...state };
      const { runId, well, channelNum } = action;
      const runBlockWithTarget = state.runBlocks.find(
        runBlock => runBlock.runId === runId
      );

      let newRunsPageData = [...newState.data];

      const indexToUpdate = newRunsPageData
        .map(target => target.id)
        .indexOf(`${runId}-${well}-${channelNum}`);

      const newTarget = { ...newRunsPageData[indexToUpdate] };

      if (runBlockWithTarget.active === true) {
        newTarget.showLine = true;
      }

      newRunsPageData = [
        ...newRunsPageData.slice(0, indexToUpdate),
        newTarget,
        ...newRunsPageData.slice(indexToUpdate + 1, newRunsPageData.length),
      ];

      return {
        ...state,
        data: newRunsPageData,
      };
    }
    case t.CHART_TOGGLE_ALL_RUN_TARGETS: {
      const { active, runId, channel } = action;

      const newState = { ...state };
      const runBlockWithTarget = state.runBlocks.find(
        runBlock => runBlock.runId === runId
      );

      let newRunsPageData = [...newState.data];

      newRunsPageData = newRunsPageData.map(target => {
        if (channel) {
          if (channel && target.runId === runId && target.channel === channel) {
            let newRunsPageShowLine = target.showLine;
            if (runBlockWithTarget && runBlockWithTarget.active) {
              newRunsPageShowLine = active;
            }

            return {
              ...target,
              showLine: newRunsPageShowLine,
            };
          }

          return target;
        }

        if (target.runId === runId) {
          let newShowLine = target.showLine;
          if (runBlockWithTarget && runBlockWithTarget.active) {
            newShowLine = active;
          }

          return {
            ...target,
            showLine: newShowLine,
          };
        }

        return target;
      });

      return {
        ...newState,
        data: newRunsPageData,
      };
    }

    case t.CHART_DEACTIVATE_RUN_BLOCK: {
      const newState = { ...state };
      const { runId } = action;

      let newRunsPageData = [...newState.data];
      let newRunBlocks = [...newState.runBlocks];

      newRunsPageData = newRunsPageData.map(target => {
        if (target.runId === runId) {
          return {
            ...target,
            showLine: false,
          };
        }

        return {
          ...target,
        };
      });

      newRunBlocks = filterAndDeactivateRunBlocks(newRunBlocks, runId);

      return {
        ...newState,
        data: newRunsPageData,
        runBlocks: newRunBlocks,
      };
    }

    case t.CHART_ACTIVATE_RUN_BLOCK: {
      const newState = { ...state };
      const { runId } = action;

      let newRunsPageData = [...newState.data];
      let newRunBlocks = [...newState.runBlocks];

      newRunsPageData = newRunsPageData.map(target => {
        if (target.runId === runId) {
          return {
            ...target,
            showLine: target.isControlEnabled,
          };
        }

        return {
          ...target,
        };
      });

      newRunBlocks = filterAndActivateRunBlocks(newRunBlocks, runId);

      return {
        ...newState,
        data: newRunsPageData,
        runBlocks: newRunBlocks,
      };
    }

    case t.CHART_HIDE_RUN_THRESHOLDS: {
      const newState = { ...state };
      const { runId } = action;

      let newRunBlocks = [...newState.runBlocks];

      newRunBlocks = newRunBlocks.map(runBlock => {
        if (runBlock.runId === runId) {
          return {
            ...runBlock,
            threshold: false,
          };
        }

        return {
          ...runBlock,
        };
      });

      return {
        ...newState,
        runBlocks: newRunBlocks,
      };
    }

    case t.CHART_SHOW_RUN_THRESHOLDS: {
      const newState = { ...state };
      const { runId } = action;

      let newRunBlocks = [...newState.runBlocks];

      newRunBlocks = newRunBlocks.map(runBlock => {
        if (runBlock.runId === runId) {
          return {
            ...runBlock,
            threshold: true,
          };
        }

        return {
          ...runBlock,
        };
      });

      return {
        ...newState,
        runBlocks: newRunBlocks,
      };
    }

    case t.CHART_APPLY_FILTERS_MASTER: {
      const { targets, runId } = action;
      const newState = { ...state };

      let newRunsPageData = [...newState.data];

      newRunsPageData = newRunsPageData.map(target => {
        if (runId) {
          if (target.runId !== runId) {
            return target;
          }

          const runBlockWithTarget = newState.runBlocks.find(
            runBlock => runBlock.runId === target.runId
          );

          if (!runBlockWithTarget) {
            return target;
          }

          const associatedTarget = targets[target.channel].find(
            x => x.well === target.well
          );

          if (!associatedTarget) {
            return target;
          }

          let newRunsIsControlEnabled = target.isControlEnabled;
          if (runBlockWithTarget.active) {
            newRunsIsControlEnabled = associatedTarget.active;
          }

          let newRunsShowLine = target.showLine;
          if (runBlockWithTarget.active) {
            newRunsShowLine = associatedTarget.active;
          }

          return {
            ...target,
            isControlEnabled: newRunsIsControlEnabled,
            showLine: newRunsShowLine,
          };
        }

        const runBlockWithTarget = newState.runBlocks.find(
          runBlock => runBlock.runId === target.runId
        );

        let newIsControlEnabled = target.isControlEnabled;
        if (runBlockWithTarget.active) {
          newIsControlEnabled = targets[target.channel][target.well].active;
        }

        let newShowLine = target.showLine;
        if (runBlockWithTarget.active) {
          newShowLine = targets[target.channel][target.well].active;
        }

        return {
          ...target,
          isControlEnabled: newIsControlEnabled,
          showLine: newShowLine,
        };
      });

      return {
        ...newState,
        data: newRunsPageData,
      };
    }

    case t.CHART_APPLY_FILTERS_RUN: {
      const newState = { ...state };
      const { runId, channels, wells, wellChannel } = action;

      const runBlockActive = newState.runBlocks.find(
        runBlock => runBlock.runId === runId
      ).active;

      let channel = null;
      let inactiveTargetsPresent = null;

      if (channels.length > 0) {
        [channel] = channels;
        inactiveTargetsPresent = newState.data.find(
          target =>
            target.runId === runId &&
            target.channel.toLowerCase() === channels[0].label.toLowerCase() &&
            !target.showLine &&
            !target.hideTarget
        );
      } else if (wells.length > 0) {
        inactiveTargetsPresent = newState.data.find(
          target =>
            target.runId === runId &&
            target.well === wells[0].label &&
            !target.showLine &&
            !target.hideTarget
        );
      }

      const newRunsPageData = newState.data.map(target => {
        if (target.runId === runId) {
          if (channel) {
            if (target.channel.toLowerCase() === channel.label.toLowerCase()) {
              let newShowLine = target.showLine;
              if (runBlockActive) {
                newShowLine = inactiveTargetsPresent;
              }

              return {
                ...target,
                showLine: newShowLine,
              };
            }

            return target;
          }

          if (wells.length > 0) {
            const associatedWell = wells.find(
              well => well.label === target.well
            );

            if (wellChannel) {
              if (associatedWell && target.channel === wellChannel) {
                return {
                  ...target,
                  showLine: associatedWell.checked,
                };
              }

              return target;
            }

            if (associatedWell) {
              let newShowLine = target.showLine;
              if (runBlockActive) {
                newShowLine = inactiveTargetsPresent;
              }

              return {
                ...target,
                showLine: newShowLine,
              };
            }
          }

          return target;
        }

        return target;
      });

      return {
        ...newState,
        data: newRunsPageData,
      };
    }

    case t.CHART_SHOW_ANALYSIS_MODAL: {
      return {
        ...state,
        showAnalysisModal: true,
      };
    }

    case t.CHART_HIDE_ANALYSIS_MODAL:
      return {
        ...state,
        showAnalysisModal: false,
      };

    case t.CHART_TOGGLE_TUTORIAL:
      return {
        ...state,
        showTutorial: action.status,
      };

    case t.CHART_SHOW_DETAILED_MODAL: {
      return {
        ...state,
        showDetailedModal: true,
      };
    }

    case t.CHART_HIDE_DETAILED_MODAL:
      return {
        ...state,
        showDetailedModal: false,
      };

    case t.CHART_SHOW_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: true,
      };

    case t.CHART_HIDE_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: false,
      };

    case t.CHART_TOGGLE_EXPORT_REVIEW_MODAL: {
      const { showExportReviewModal } = state;

      if (showExportReviewModal) {
        return {
          ...state,
          showExportReviewModal: false,
        };
      }

      return {
        ...state,
        showExportReviewModal: true,
      };
    }

    case t.CHART_SHOW_PRINT_MODAL:
      return {
        ...state,
        showPrintModal: true,
      };

    case t.CHART_HIDE_PRINT_MODAL:
      return {
        ...state,
        showPrintModal: false,
      };

    case t.SET_PRINT_INDEX:
      return {
        ...state,
        printIndex: action.runId,
      };

    case t.UPDATE_SINGLE_THRESHOLD_SUCCESS: {
      const { updatedData } = action;

      return {
        ...state,
        data: updatedData,
      };
    }

    case t.UPDATE_LINE_THRESHOLD: {
      const { lines } = action;
      const data = [...state.data];

      lines.forEach(line => {
        const ind = data.findIndex(element => element.id === line.target);
        const obj = { ...data[ind] };
        obj.threshold = +line.threshold;
        data.splice(ind, 1, obj);
      });

      return {
        ...state,
        data,
      };
    }

    case t.UPDATE_CQ_VALUES: {
      const { target, threshold } = action.data;
      const data = [...state.data];

      const ind = data.findIndex(element => element.id === target);
      const obj = { ...data[ind] };
      obj.CQ = getCrossingPoint(
        obj.baselineValues,
        threshold,
        0,
        obj.baselineValues.length
      );
      data.splice(ind, 1, obj);

      return {
        ...state,
        data,
      };
    }

    case t.CHART_UPDATE_METRICS: {
      const newState = { ...state };

      return {
        ...newState,
        metrics: calculateMetrics(newState.data),
      };
    }

    case t.CHART_UPDATE_END_RFU_METRICS: {
      const newState = { ...state };

      return {
        ...newState,
        endRfuMetrics: calculateEndRfuMetrics(newState.data),
      };
    }

    case t.CHART_UPDATE_AXES: {
      const targets = [...state.data];
      const newState = { ...state };

      const activeChartType = Object.keys(newState.chartTypeSettings).find(
        key => newState.chartTypeSettings[key] === true
      );

      let xAxisData = [];
      const xAxisLengths = [];
      const yAxisMaximums = [];
      const yAxisMinimums = [];

      let greenTargets = null;
      let redTargets = null;
      let amberTargets = null;

      switch (activeChartType) {
        case chartTypes.baseline:
        case chartTypes.review: {
          newState.data.forEach(target => {
            if (target.showLine) {
              xAxisLengths.push(target.values.length);
              yAxisMaximums.push(Math.max(...target.values));
              yAxisMinimums.push(Math.min(...target.values));

              const runBlockWithTarget = newState.runBlocks.find(
                runBlock => runBlock.runId === target.runId
              );

              if (runBlockWithTarget.threshold) {
                const { meltTemperatures } = target;

                yAxisMaximums.push(target.threshold);
                yAxisMinimums.push(target.threshold);

                if (
                  meltTemperatures &&
                  meltTemperatures.length > 1 &&
                  meltTemperatures.length > xAxisData.length
                ) {
                  xAxisData = meltTemperatures;
                }
              }
            }
          });

          break;
        }

        case chartTypes.raw: {
          newState.data.forEach(target => {
            const { showLine, meltTemperatures } = target;

            if (showLine) {
              xAxisLengths.push(target.values.length);
              yAxisMaximums.push(Math.max(...target.values));
              yAxisMinimums.push(Math.min(...target.values));

              if (
                meltTemperatures &&
                meltTemperatures.length > 1 &&
                meltTemperatures.length > xAxisData.length
              ) {
                xAxisData = meltTemperatures;
              }
            }
          });

          break;
        }

        case chartTypes.singleThreshold: {
          newState.data.forEach(target => {
            const { showLine, meltTemperatures } = target;

            if (showLine) {
              xAxisLengths.push(target.values.length);
              yAxisMaximums.push(Math.max(...target.values));
              yAxisMinimums.push(Math.min(...target.values));

              if (
                meltTemperatures &&
                meltTemperatures.length > 1 &&
                meltTemperatures.length > xAxisData.length
              ) {
                xAxisData = meltTemperatures;
              }
            }
          });

          greenTargets = targets.filter(
            target => target.channel === "green" && target.showLine
          );

          if (greenTargets.length > 0) {
            const greenThresholdValue = greenTargets[0].singleThreshold;

            yAxisMinimums.push(greenThresholdValue);
            yAxisMaximums.push(greenThresholdValue);
          }

          redTargets = targets.filter(
            target => target.channel === "red" && target.showLine
          );

          if (redTargets.length > 0) {
            const redThresholdValue = redTargets[0].singleThreshold;

            yAxisMinimums.push(redThresholdValue);
            yAxisMaximums.push(redThresholdValue);
          }

          amberTargets = targets.filter(
            target => target.channel === "amber" && target.showLine
          );

          if (amberTargets.length > 0) {
            const amberThresholdValue = amberTargets[0].singleThreshold;

            yAxisMinimums.push(amberThresholdValue);
            yAxisMaximums.push(amberThresholdValue);
          }

          break;
        }

        default: {
          break;
        }
      }

      return {
        ...newState,
        xAxis: {
          length: Math.max(...xAxisLengths),
          data: xAxisData,
        },
        yAxis: {
          max: Math.max(...yAxisMaximums),
          min: Math.min(...yAxisMinimums),
        },
      };
    }

    case t.CHART_SET_REPROCESSING_ERROR: {
      return {
        ...state,
        reprocessingError: action.message,
      };
    }

    case t.CHART_SET_REVIEW_MODE_ERROR: {
      return {
        ...state,
        reviewModeError: action.message,
      };
    }

    case t.UPDATE_WELL_FILTERS: {
      const { wellFilters } = action;

      return {
        ...state,
        wellFilters,
      };
    }

    case t.UPDATE_CHANNEL_FILTERS: {
      const { channelFilters } = action;

      return {
        ...state,
        channelFilters,
      };
    }

    case t.CHART_SHOW_CYCLES_DISPLAYED_MODAL: {
      return {
        ...state,
        showCyclesDisplayedModal: true,
      };
    }

    case t.CHART_HIDE_CYCLES_DISPLAYED_MODAL:
      return {
        ...state,
        showCyclesDisplayedModal: false,
      };

    case t.CHART_UPDATE_CYCLES_DISPLAYED_LOWER_BOUND: {
      const { lowerBound } = action;

      return {
        ...state,
        lowerBound,
      };
    }

    case t.CHART_UPDATE_CYCLES_DISPLAYED_UPPER_BOUND: {
      const { upperBound } = action;

      return {
        ...state,
        upperBound,
      };
    }

    case t.TUTORIAL_SET_RUN_BLOCK_OPEN_STATE: {
      const { open } = action;

      return {
        ...state,
        tutorialRunBlockOpenState: open,
      };
    }

    case t.CHART_UPDATE_BACKGROUND_VALUES_ERROR: {
      const { value } = action;

      return {
        ...state,
        chartBackgroundValuesUpdateError: value,
      };
    }

    case t.TOGGLE_BULK_REVIEW_SUMMARY_MODAL: {
      const { showBulkReviewSummaryModal } = state;

      const newModalState = !showBulkReviewSummaryModal;

      return {
        ...state,
        showBulkReviewSummaryModal: newModalState,
      };
    }

    default: {
      return state;
    }
  }
}

export default runsPageReducer;
